import React, { useState } from "react";
import { Button, Div, Modal, ModalContainer, Text } from "../StyledComponents";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { useSelector } from "react-redux";
import moment from "moment";

const PlanExpires = ({ show, onHide }) => {
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  return (
    <>
      <Modal
        show="flex"
        justifyContent="center"
        width="100%"
        height="100%"
        zIndex="9999"
      >
        <ModalContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="static"
          borderRadius="8px"
          backgroundColor=" rgba(255, 255, 255, 1)"
          border="1px solid transparent"
          borderColor=" rgba(255, 255, 255, 1)"
        >
          <Div padding="22px 18px  21px 22px">
            <Text
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="19.07px"
              color="rgba(46, 46, 46, 1)"
              marginBottom="8px"
              textAlign="left"
            >
              Your plan expires on &nbsp;
              {moment(LoginResponse.expires_on).format("MMM DD, YYYY")}
            </Text>
            <Text
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="12px"
              lineHeight="16.34px"
              color="rgba(46, 46, 46, 0.75)"
              marginBottom="24px"
              textAlign="left"
            >
              Hey buddy don’t lose out on all great benefits that vozo has to
              offer you
            </Text>
            <Div display="flex" alignItems="center" justifyContent="center">
              <Button
                height="36px"
                width="120px"
                cursor="pointer"
                backgroundColor="rgba(44, 123, 229, 1)"
                borderRadius="4px"
                color="rgba(255, 255, 255, 1)"
                fontFamily="'Open Sans', sans-serif"
                fontSize="12px"
                fontWeight="600"
                border="1px solid #2C7BE5"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                hoverBackColor="#005FB2"
                lineHeight="16.34px"
                onClick={onHide}
              >
                Continue
              </Button>
            </Div>
          </Div>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default PlanExpires;
