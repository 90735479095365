import React, { useState } from "react";
import { Container1, TabPanel, Tabs } from "./styles";
import { Div } from "../../StyledComponents/index";
import Problem from "./Problems/Problem";
import Allergy from "./Allergy/Allergy";
import Medication from "./Medication/Medication";
import Laborder from "./Laborder/laborder";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TreatmenPlan from "./TreatmentPlan/TreatmenPlanIndex";

const PatientDemographicsSheet = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = useState("Treatment Plan");
  const [value1, setValue1] = useState(false);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const params = useParams();
  const patId = params.pid;
  const handleTabs = (tabIndex, value) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setValue(value);
      setActiveTab(tabIndex);
      setValue1(true);
    }
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div overflow="auto" padding="26px 31px 28px 31px">
            <TreatmenPlan pid={patId} />
          </Div>
        );
      case 2:
        return (
          <Div overflow="auto" padding="26px 31px 28px 31px">
            <Problem pid={patId} value={value} value1={value1} />
          </Div>
        );
      case 3:
        return (
          <Div overflow="auto" padding="26px 31px 28px 31px">
            <Allergy pid={patId} value={value} value1={value1} />
          </Div>
        );
      case 4:
        return (
          <Div overflow="auto" padding="26px 31px 28px 31px">
            <Medication pid={patId} value={value} value1={value1} />
          </Div>
        );
      case 5:
        return (
          <Div overflow="auto" padding="26px 31px 28px 31px">
            <Laborder pid={patId} value={value} value1={value1} />
          </Div>
        );
      default:
        return null;
    }
  };
  return (
    <Container1>
      <TabPanel
        width="100%"
        background="#ffffff"
        borderRadius="8px 8px 0px 0"
        borderBottom="1px solid rgba(46, 46, 46, 0.25);"
        display="flex"
        justifyContent="flex-start"
        padding="25px 25px 0 0px"
      >
        <Tabs
          width="168px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 31px"
          isActive={activeTab === 1}
          disabled={SingPatDetails.enrollment_status === "4"}
          onClick={() => handleTabs(1, "Treatment Plan")}
          line={activeTab}
        >
          Treatment Plan
        </Tabs>
        <Tabs
          width="130px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 31px"
          isActive={activeTab === 2}
          disabled={SingPatDetails.enrollment_status === "4"}
          onClick={() => handleTabs(2, "medical_problem")}
        >
          Problems
        </Tabs>
        <Tabs
          width="98px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 23px"
          isActive={activeTab === 3}
          disabled={SingPatDetails.enrollment_status === "4"}
          onClick={() => handleTabs(3, "allergy")}
        >
          Allergies
        </Tabs>
        <Tabs
          width="133px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 40px"
          isActive={activeTab === 4}
          disabled={SingPatDetails.enrollment_status === "4"}
          onClick={() => handleTabs(4, "medication")}
        >
          Medication
        </Tabs>
        <Tabs
          width="150px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 40px"
          isActive={activeTab === 5}
          disabled={SingPatDetails.enrollment_status === "4"}
          onClick={() => handleTabs(5, "laborder")}
        >
          Lab Orders
        </Tabs>
      </TabPanel>
      {tabComponent()}
    </Container1>
  );
};
export default PatientDemographicsSheet;
