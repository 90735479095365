import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Div,
  ModalBody,
  Li,
  Ul,
  SpanTag,
  TextArea,
  Table,
  TableHead,
  TableBody,
  TableBodyCell,
  TableHeadCell,
  TableRow,
  ImageContainer,
  Span,
  ModalFooter,
  Button,
  Image,
} from "../../StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  ToggleButton,
  RadioInput,
  RadioLabel,
  ModalHeader1,
} from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import {
  GetUnenrollmentupdate,
  GetSinglePatDetail,
} from "../../../StateManagement/Reducers/PatientState";

const UnenrollPatient = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const dispatch = useDispatch();
  const params = useParams();
  const patId = params.pid;
  const devicesList = useSelector((state) => state.PatientDemo.DeviceList);
  const FilteredDevices = useSelector(
    (state) => state.PatientDemo.FilteredDeviceList
  );
  const [selectedOption, setSelectedOption] = useState({});
  const [radioErr, setRadioErr] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [notes, setNotes] = useState("");
  const [checkErr, setCheckErr] = useState(false);
  const [buttonvalue, setButtonvalue] = useState(0);
  const [buttonlabel, setButtonlabel] = useState("");
  const [reasonErr, setreasonErr] = useState(false);
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [buttons, setButtons] = useState([
    {
      id: 1,
      label: "Program Completed",
      active: false,
      padding: "9px 14px 8px 14px ",
    },
    {
      id: 2,
      label: "Not Interested",
      active: false,
      padding: "9px 12px 8px 13px ",
    },
    {
      id: 3,
      label: "No Improvement Noted",
      active: false,
      padding: "9px 8px 8px 8px ",
    },
    { id: 4, label: "Others", active: false, padding: "9px 17px 8px 18px " },
  ]);
  const [dis, setDis] = useState(false);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  //--------------------------------------------Function Start------------------------------------------------- //

  const closeModal = () => {
    setCheckErr(false);
    setIsChecked(false);
    setRadioErr(false);
    setreasonErr(false);
    setSelectedOption({});
    setNotes("");
    setButtonvalue(0);
    buttons.forEach((b) => {
      b.active = false;
    });
    props.onHide();
  };

  const handleButtonClick = (id, label) => {
    setButtons((prevButtons) =>
      prevButtons.map((button) => ({
        ...button,
        active: button.id === id,
      }))
    );
    setButtonvalue(id);
    setButtonlabel(label);
    setreasonErr(false);
  };
  const handleOptionChange = (event, index) => {
    setSelectedOption((prevOptions) => ({
      ...prevOptions,
      [index]: event.target.value,
    }));
    setRadioErr(false);
  };
  const handlenotes = (e) => {
    setNotes(e.target.value);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setCheckErr(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let err = 0;

    if (FilteredDevices["Device_model"] !== undefined) {
      for (let i = 0; i < FilteredDevices["Device_model"].length; i++) {
        if (!selectedOption[i]) {
          setRadioErr(true);
          err = 2;
          break;
        }
      }
    }

    if (isChecked === false) {
      setCheckErr(true);
      err = 2;
    }
    if (buttonvalue === 0) {
      setreasonErr(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    }
    setDis(true);
    dispatch(
      GetUnenrollmentupdate({
        enrollment_status: "4",
        pid: patId,
        notes: notes,
        reason: buttonlabel,
        devices: FilteredDevices["Device_model"],
        status: selectedOption,
      })
    );
    setTimeout(() => {
      setDis(false);
    }, 1500);
    closeModal();
  };

  //-----------------------------Function End------------------------------------------------- //

  //-----------------------------UseEffect Start---------------------------------------------- //
  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  useEffect(() => {
    dispatch(GetSinglePatDetail(patId));
  }, []);

  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        onClick={closeModal}
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainer
          justifyContent="center"
          overflowX="auto"
          onClick={(e) => e.stopPropagation()}
          width="700px"
          height="650px"
        >
          <ModalHeader1
            padding=" 16px 14px 17px 24px"
            justifyContent="space-between"
            display="flex"
            alignItems="center"
            borderBottom="1px solid rgba(46, 46, 46, 0.25)"
          >
            <ModalTitle
              fontFamily="'Open Sans',sans-serif"
              fontWeight="600"
              fontSize="18px"
              lineHight="normal"
              color="#2E2E2E"
              fontStyle="SemiBold"
            >
              Unenroll Patient from Program
            </ModalTitle>
            <AiOutlineClose
              onClick={closeModal}
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </ModalHeader1>
          <ModalBody padding="16px 20px 20px 24px">
            <Div
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400"
              fontSize="14px"
              color="#2E2E2E"
            >
              Are you sure you want to unenroll the Patient from the program?
              <Div
                fontFamily="'Open Sans',sans-serif"
                fontSize="14px"
                color="#2E2E2E"
              >
                <Div padding="24px 0px 16px 0px" fontWeight="600 ">
                  Note:
                </Div>
                <Div fontWeight="400 ">
                  <Ul>
                    <Li paddingBottom="8px">
                      1. Patient will be unenrolled from the program once the
                      devices are returned by the patients.
                    </Li>
                    <Li paddingBottom="8px">
                      2. Patient will be removed from the registry and from the
                      “Active Patients” of the “Patient list”.
                    </Li>
                    <Li paddingBottom="15px">
                      3. You can track patients unenrollment status from
                      “Patient list” under “Enrolled Patients” filter.
                    </Li>
                    <Li>
                      <Div display="flex" alignItems="center">
                        <Checkbox
                          height="16px"
                          width="16px"
                          cursor="pointer"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          required
                        ></Checkbox>
                        <SpanTag paddingLeft="8px">
                          Got approval from the patient to unenroll from this
                          program
                          <SpanTag color="#B00020" fontSize="18px">
                            *
                          </SpanTag>
                        </SpanTag>
                      </Div>
                      {checkErr && (
                        <ErrorMessage> Please check the box.</ErrorMessage>
                      )}
                    </Li>
                  </Ul>
                </Div>
                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="600"
                  fontSize="16px"
                  color="#2E2E2E"
                  padding="15px 0 8px 0"
                >
                  Reason for Unenrollment
                  <SpanTag color="#B00020" fontSize="16px">
                    *
                  </SpanTag>
                  <Div padding="16px 0 16px 0">
                    {buttons.map((button) => (
                      <ToggleButton
                        borderRadius="4px"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        cursor="pointer"
                        padding={button.padding}
                        margin="0 10px 0 0px"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="400"
                        key={button.id}
                        label={button.label}
                        active={button.active}
                        onClick={() =>
                          handleButtonClick(button.id, button.label)
                        }
                      >
                        {button.label}
                      </ToggleButton>
                    ))}
                    {reasonErr && (
                      <ErrorMessage fontSize="12px" fontWeight="400">
                        Please Select anyone.
                      </ErrorMessage>
                    )}
                  </Div>
                </Div>
                <Div>
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    color="#2E2E2E"
                  >
                    Notes:
                  </Div>
                  <TextArea
                    outline="none"
                    margin="8px 0px 8px 0px"
                    padding="10px"
                    width="100%"
                    height="100px"
                    borderRadius="4px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    value={notes}
                    onChange={(e) => handlenotes(e)}
                    resize="none"
                  ></TextArea>
                </Div>
                <>
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="16px"
                    color="#2E2E2E"
                    padding="13px 0 7px 0"
                  >
                    Device Return
                    <SpanTag color="#B00020" fontSize="16px">
                      *
                    </SpanTag>
                  </Div>
                  <Table
                    width="100%"
                    height="223px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderTop="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                  >
                    <TableHead>
                      <TableRow
                        borderBottom="1px solid rgba(46, 46, 46, 0.25)"
                        fontFamily="'Open Sans',sans-serif"
                        fontWeight="600"
                        fontSize="14px"
                        color="#2E2E2E"
                      >
                        <TableHeadCell
                          textAlign="center"
                          width="34%"
                          paddingLeft="0px"
                        >
                          Device
                        </TableHeadCell>
                        <TableHeadCell
                          width="33%"
                          paddingLeft="0px"
                          textAlign="center"
                        >
                          Serial No
                        </TableHeadCell>
                        <TableHeadCell
                          width="33%"
                          textAlign="center"
                          paddingLeft="0px"
                        >
                          Action
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {FilteredDevices &&
                      FilteredDevices?.length !== 0 &&
                      FilteredDevices["Device_model"]?.length !== 0 ? (
                        FilteredDevices["Device_model"]?.map(
                          (device, index) => (
                            <TableRow
                              key={index}
                              fontFamily="'Open Sans',sans-serif"
                              fontWeight="400"
                              fontSize="14px"
                              color="#2E2E2E"
                              borderBottom="1px solid #cbd5e0"
                            >
                              <TableBodyCell width="34% !important">
                                <Div display="flex">
                                  <ImageContainer
                                    height="50px"
                                    width="50px"
                                    backgroundColor=" rgba(46, 46, 46, 0.1)"
                                    borderRadius="8px"
                                    marginBottom="8px"
                                  ></ImageContainer>
                                  <SpanTag margin=" 7px 0 0 8px" width="64%">
                                    {device.device_model}
                                  </SpanTag>
                                </Div>
                              </TableBodyCell>
                              <TableBodyCell
                                textAlign="center"
                                paddingTop="3px"
                                width="33% !important"
                              >
                                {device.serialNo}
                              </TableBodyCell>
                              <TableBodyCell
                                textAlign="center"
                                paddingTop="4px"
                                width="33% !important"
                              >
                                <Div display="flex" justifyContent="center">
                                  <RadioLabel
                                    htmlFor={"radioOption1" + index}
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <RadioInput
                                      id={"radioOption1" + index}
                                      name={"radioOption" + index}
                                      value="Return"
                                      checked={
                                        selectedOption[index] === "Return"
                                      }
                                      onChange={(event) =>
                                        handleOptionChange(event, index)
                                      }
                                      cursor="pointer"
                                    />
                                    <SpanTag paddingLeft="8px">Return</SpanTag>
                                  </RadioLabel>
                                  <RadioLabel
                                    htmlFor={"radioOption2" + index}
                                    paddingLeft="16px"
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <RadioInput
                                      padding="0 0 0 8px"
                                      id={"radioOption2" + index}
                                      name={"radioOption" + index}
                                      value="Lost"
                                      checked={selectedOption[index] === "Lost"}
                                      onChange={(event) =>
                                        handleOptionChange(event, index)
                                      }
                                      cursor="pointer"
                                    />

                                    <SpanTag paddingLeft="8px">Lost</SpanTag>
                                  </RadioLabel>
                                </Div>

                                {radioErr && !selectedOption[index] && (
                                  <ErrorMessage>
                                    Please Select anyone for Device {index + 1}.
                                  </ErrorMessage>
                                )}
                              </TableBodyCell>
                            </TableRow>
                          )
                        )
                      ) : (
                        <TableRow height="307px">
                          <TableBodyCell textAlign="center" colSpan={5}>
                            <Div margin="auto">
                              <Image
                                height="150px"
                                width="150px"
                                src={Emp_data_pic}
                              ></Image>
                              <Span
                                fontSize="14px"
                                color="#2E2E2E"
                                fontFamily="'Open Sans',sans-serif"
                                lineHeight="20px"
                                fontWeight="400"
                                textAlign="center"
                              >
                                No Devices to be shown,Please add some.
                              </Span>
                            </Div>
                          </TableBodyCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </>
              </Div>
            </Div>
          </ModalBody>
          <ModalFooter noBorderTop padding="4px 20px 23px 19px" float="right">
            <Button
              fontSize="14px"
              fontWeight="600"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 37px 8px 38px"
              width="122px"
              height="36px"
              margin="0 24px 0 0px"
              background="#FFF"
              color="#2C7BE5"
              cursor="pointer"
              onClick={closeModal}
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              activeBackgroundColor="rgba(44, 123, 229, 0.10)"
            >
              <Span fontSize="14px" fontWeight="600">
                Cancel
              </Span>
            </Button>
            <Button
              type="submit"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 33px 8px 32px"
              width="120px"
              height="36px"
              background="#2C7BE5"
              color="#FFFFFF"
              cursor="pointer"
              disabled={dis}
              isDisabled={dis}
              onClick={handleSubmit}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              <Span
                fontSize="14px"
                fontWeight="600"
                fontFamily="'Open Sans', sans-serif"
              >
                Confirm
              </Span>
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default UnenrollPatient;
