import React, { useState, memo, useEffect } from "react";
import {
  Div,
  Span,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  SpanTag,
  Button,
  Image,
  SpinnerWrapper,
  Spinner,
} from "../../../StyledComponents";
import { FiTrash2 } from "react-icons/fi";
import { RiEdit2Line } from "react-icons/ri";
import AddRoles from "./AddRoles";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  GetGroupId,
  GetProvWithRole,
  GetRoleDelete,
  GetRoles,
  GetRolesEdit,
  SetInsertRole,
  SetRoleDelete,
} from "../../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import { PaginDiv } from "../../../Patient/PatientList/styles";
import Pagination from "../../../StyledComponents/Pagination/Pagination";
import Logo from "../../../../Assets/images/Logo.png";

const Roles = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const dispatch = useDispatch();
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const [show, setShow] = useState(false);
  const [roles_id, setroles_id] = useState(0);
  const [roleGroup, setRoleGroup] = useState(0);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const RolesList = useSelector((state) => state.Setting.Roles);
  const InsertRole = useSelector((state) => state.Setting.InsertRole);
  const EditRole = useSelector((state) => state.Setting.EditRoleInsert);
  const Deleterole = useSelector((state) => state.Setting.Deleterole);

  const howManyPages = useSelector((state) => state.Setting.rolenoofpages);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [items, setItems] = useState();
  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start------------------------------------------------- //
  const handleopen = () => {
    setShow(true);
    setroles_id(0);
    dispatch(GetGroupId(user_id));
  };

  const handleopen1 = (acl_id, grp_id, item) => {
    dispatch(GetRolesEdit(acl_id));
    setroles_id(acl_id);
    setRoleGroup(grp_id);
    dispatch(GetGroupId(user_id));
    setTimeout(() => {
      setShow(true);
    }, 500);
    setItems(item);
  };

  const handleclose = () => {
    setShow(false);
  };



  let articles =
    "trial=" +
    true +
    "&account=" +
    3 +
    "&uid=" +
    user_id +
    "&owner_id=" +
    null +
    "&page=" +
    currentPage +
    "&pageSize=10";

  useEffect(() => {
    dispatch(GetRoles(articles));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [currentPage]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);
  useEffect(() => {
    dispatch(GetRoles(articles));
    if (Deleterole === "success") {
      setModalAlerShow(true);
      setModalMsg("Role Deleted Successfully");
      setStatus("success");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        dispatch(SetRoleDelete(""));
        setCurrentPage(1);
      }, 1500);
    }
    dispatch(GetProvWithRole());
  }, [InsertRole, EditRole, Deleterole]);

  //-----------------------------Function End------------------------------------------------- //

  return loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div
        background="#FFFFFF"
        margin="10px 26px 21px 26px"
        borderRadius="6px"
        boxShadow=" 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Div padding="17px 36px 15px 24px">
          <Div display="flex">
            <Span
              style={{ marginTop: "10px" }}
              fontFamily="'Open Sans'sans-serif"
              fontWeight="600"
              fontSize="16px"
            >
              Roles List
            </Span>

            <Button
              display="flex"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 31px 8px 31px"
              background="#2C7BE5"
              color="#FFFFFF"
              marginLeft="auto"
              cursor="pointer"
              onClick={handleopen}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              <Span
                fontSize="14px"
                fontWeight="600"
                fontFamily="'Open Sans', sans-serif"
              >
                Add Roles
              </Span>
            </Button>
          </Div>
        </Div>

        <Div>
          <Table>
            <TableHead background="#F1F5F8">
              <TableRow
                color="#2C7BE5"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                colSpan="3"
                paddingTop="18px"
                paddingBottom="19px"
                fontFamily="'Open Sans'sans-serif"
              >
                <TableHeadCell
                  width="33%"
                  textAlign="left"
                  paddingRight="10px"
                  paddingLeft="9%"
                >
                  Name
                </TableHeadCell>
                <TableHeadCell width="33%" paddingRight="5%" textAlign="left">
                  Description
                </TableHeadCell>
                <TableHeadCell textAlign="right" width="34%" paddingRight="9%">
                  Action
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {RolesList.data?.length !== 0 ? (
                  RolesList.data?.map((item) => (
                    <TableRow
                      key={item.id}
                      fontSize="14px"
                      lineHeight="20px"
                      fontFamily="'Open Sans'sans-serif"
                      borderBottom="1px solid #cbd5e0"
                      paddingTop="15px"
                      paddingBottom="17px"
                    >
                      <TableBodyCell
                        textAlign="left"
                        paddingRight="10px"
                        fontWeight="600"
                        color="#2E2E2E"
                        paddingLeft="9%"
                        maxWidth="40px"
                        wordWrap='break-word'
                      >
                        {item.name}
                      </TableBodyCell>
                      <TableBodyCell
                        textAlign="left"
                        paddingRight="5%"
                        fontWeight="400"
                        color="#2E2E2E"
                        maxWidth="40px"
                        wordWrap='break-word'
                      >
                        {item.note}
                      </TableBodyCell>
                      <TableBodyCell
                        textAlign="right"
                        minWidth="12%"
                        paddingRight="12%"
                        width="14%"
                      >
                        <SpanTag
                          height="20px"
                          width="20px"
                          color=" #2C7BE5"
                          cursor="pointer"
                          onClick={() =>
                            item.group_id === "11"
                              ? ""
                              : handleopen1(item.acl_id_m, item.group_id, item)
                          }
                        >
                          <RiEdit2Line
                            style={{
                              cursor:
                                item.group_id === "11"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          />
                        </SpanTag>
                      </TableBodyCell>
                    </TableRow>
                  ))
                ) : dataLoaded && RolesList.data?.length === 0 ? (
                  <TableRow height="309px">
                    <TableBodyCell textAlign="center" colSpan={8}>
                      <Div>
                        <Image
                          height="150px"
                          width="150px"
                          src={Emp_data_pic}
                        ></Image>
                      </Div>
                      <Div
                        fontSize="14px"
                        color="#2E2E2E"
                        fontFamily="'Open Sans',sans-serif"
                        lineHeight="20px"
                        fontWeight="400"
                      >
                        No lab orders to be shown,Please add some.
                      </Div>
                    </TableBodyCell>
                  </TableRow>
                ) : (
                  <SpinnerWrapper>
                    <Spinner></Spinner>{" "}
                    <Image
                      width="40px"
                      height="40px"
                      position="absolute"
                      src={Logo}
                    />
                  </SpinnerWrapper>
                )}
              </>
            </TableBody>
          </Table>
          <PaginDiv
            hidePagination={RolesList.data?.length === 0 || howManyPages === 1}
          >
            {RolesList.data?.length === 0 || howManyPages === 1 ? (
              ""
            ) : (
              <Pagination
                current={currentPage}
                pages={howManyPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </PaginDiv>
        </Div>
      </Div>
      <AddRoles
        show={show}
        group_id={roleGroup}
        acl_id={roles_id}
        close={handleclose}
        items={items}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(Roles);
