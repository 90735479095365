import styled from "styled-components";
import { Div, PageSubTitle } from "../StyledComponents";

export const FormGroup = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  position: ${(p) => p.position && p.position};
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
`;

export const InputContainer = styled.div`
  position: relative;
`;
export const IconContainer = styled.div`
  position: absolute;
  top: 55%;
  left: 18px;
  transform: translateY(-50%);
  color: #718096;
`;

export const EyeIcon = styled.i`
  position: absolute;
  top: ${(p) => (p.top ? p.top : "55%")};
  right: ${(p) => (p.right ? p.right : "16px")};
  bottom: ${(p) => p.bottom && p.bottom};
  transform: translateY(-50%);
  cursor: pointer;
  color: ${(p) => (p.color ? p.color : "#7d8592")};
  font-size: 18px;
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 2px solid #0a1629;
  border-radius: 3px;
  background-color: ${(props) => (props.checked ? "#FFFFFF" : "transparent")};
`;

export const CheckIcon = styled.svg`
  fill: none;
  stroke: #3f8cff;
  stroke-width: 2px;
`;
export const KeyIcon = styled.div`
  width: 80px;
  height: 80px;
  text-align: center;
  background: ${(props) => (props.green ? "#D1F9DD" : "#c9deff")};
  border: ${(props) =>
    props.green ? "6.5px solid #ECFDF3" : "6.5px solid #ecf3ff"};
  color: ${(props) => (props.green ? "#198C55" : "#3f8cff")};
  margin: 0 auto;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;

  & svg {
    height: 33px;
    width: 31px;
  }
`;
export const EmailSVG = styled.svg`
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 1.2em;
  color: gray;
`;

export const ErrorText = styled.p`
  width: 100%;
  ${(props) => !props.block && "position:  absolute"};
  bottom: 120px;
  font-size: 74%;
  color: #dc3545;
  margin-left: 40px;
  display: ${(props) => (props.error ? "block" : "none")};
`;
export const ForgotDiv = styled(Div)`
  &.adjust-forgot {
    @media screen and (max-width: 500px) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: unset;
      margin: 40px 40px 45px 40px;
    }
  }
`;
export const ExpPageSubtitle = styled(PageSubTitle)`
  svg.expire_svg {
    font-size: 20px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;
