import axiosInst from "../../Config";
import { Encrypt_Value } from "../EncryptDecrypt";
import DBHost from "../../Config/DBHost";
import axios from "axios";

const FacilityName = DBHost.DBHost;
const finalurl = DBHost.finalurl;

export const CardDetails_Api = async (credential) =>
  await axiosInst.get("/vozorpm/patientcard/details?pid=" + credential);

export const RemoveCardDetail_Api = async (Data) => {
  const article = Encrypt_Value(
    { pid: Data.pid, site: FacilityName, row_id: Data.card_id },
    "vozo"
  );
  return await axios.get(finalurl + "/stripe-custom/delete_card.php", {
    params: article,
  });
};

export const AddCardDetail_Api = async (data) => {
  const article = Encrypt_Value(
    {
      pid: data.patId,
      site: FacilityName,
      card_no: data.CardNumber,
      card_holder: data.CardHolderName,
      expireddate: data.ExpDate,
      cvv: data.CVV,
      def: data.DefaultCardVal,
      month: data.month,
      year: data.year,
      token: data.token,
      paymentMethod: data.paymentMethod,
    },
    "vozo"
  );

  return await axios.get(
    finalurl + "/stripe-custom/new_cus_add_first_pay.php",
    {
      params: article,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }
  );
};

export const EditCardDetail_Api = async (data) => {
  const article = Encrypt_Value(
    {
      pid: data.pid,
      site: FacilityName,
      row_id: data.row_id,
      card_no: data.card_no,
      card_holder: data.card_holder,
      cvv: data.cvv,
      def: data.DefaultCardVal,
      month: data.month,
      year: data.year,
    },
    "vozo"
  );
  return await axios.get(finalurl + "/stripe-custom/pat_update_card.php", {
    params: article,
  });
};

export const GetPatientDetails_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/patientlist`, credential);

export const GetSinglePatientDetail_Api = async (pid) =>
  await axiosInst.get(`/vozorpm/getsinglepatient/details?pid=` + pid);

export const PatientApi = async (credential) =>
  await axiosInst.post(`vozorpm/patientsearch`, credential);

export const PatientEnrollemt_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/patientenrollment`, credential);

export const PatientEnrollemtUpdate_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/patientenrollment/update`, credential);

export const PatientStatusList_Api = async (credential) =>
  await axiosInst.get(`/vozorpm/status/list?list_name=` + credential);
export const Image_Api = async (data) =>
  await axiosInst.post(`/vozorpm/image/Upload?pid=${data[0]}`, data[1]);
export const MailVerify_Api = async (credential) =>
  await axiosInst.get(`/vozorpm/patmailcheck?articles=` + credential);
export const FeeSheet_Api = async (pid) =>
  await axiosInst.get(`/vozorpm/feesheet/list?pid=` + pid);

export const FeeSheetCollapse_Api = async (data) =>
  await axiosInst.get(
    `/vozorpm/feesheet/list/collapse?pid=` + data.pid + `&eid=` + data.eid
  );

export const FeeSheetDelete_Api = async (data) =>
  await axiosInst.delete(
    `/vozorpm/feesheet/list/delete?pid=` + data.pid + `&eid=` + data.eid
  );

export const PatientEmailApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/patientemail`, credential);

export const FeeSheetCollapseDelete_Api = async (val) =>
  await axiosInst.delete(
    `/vozorpm/feesheet/list/collapse/delete?bill=` +
      val.bill +
      `&rpm=` +
      val.rpm
  );
export const UnenrollUpdateApi = async (credential) =>
  await axiosInst.put(`/vozorpm/patient/unenroll/update`, credential);

export const ProviderApi = async (credential) =>
  await axiosInst.post(`vozorpm/providersearch`, credential);

export const VitalsNotificationDetails_Api = async (credential) =>
  await axiosInst.get("/vozorpm/getVitals/notification?uid=" + credential);

export const VitalsNotificationDetailsUpdate_Api = async (credential) =>
  await axiosInst.get(
    "/vozorpm/getVitals/notification/update?uid=" + credential
  );
export const exportDataApi = async (val) =>
  await axiosInst.get(
    `/vozorpm/exportData_Patient?type=` + val.active + `&uid=` + val.uid
  );

export const PatientImportApi = async (data) =>
  await axiosInst.post(`vozorpm/importpatientcsv`, data);
