import styled from "styled-components";

export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "18px")};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 68px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 80px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 68px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 57px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(92px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(92px);
                          width: 57px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(92px);
                          width: 57px;
                        }
                         }
               `;
    }
  }}
`;
