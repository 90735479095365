import React, { useEffect, useState, useRef } from "react";

import {
  Div,
  Table,
  TableHead,
  TableHeadCell,
  TableBodyCell,
  Tablerow,
  Image,
  TextContent,
  SpanTag,
  Spinner,
  SpinnerWrapper,
} from "../../StyledComponents";
import { useSelector, useDispatch } from "react-redux";
import { GetSinglePatDetail } from "../../../StateManagement/Reducers/PatientState";
import { SetpatientDemotimer } from "../../../StateManagement/Reducers/PatientDemoState";
import { useParams, useLocation } from "react-router-dom";
import { DivTab } from "./styles";
import DBHost from "../../../Config/DBHost";
import Logo from "../../../Assets/images/Logo.png";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import {
  GetChartviewId,
  GetNotesId,
  GetRpmNotes,
  GetRpmNotes_timer,
  SetChartviewIdempty,
} from "../../../StateManagement/Reducers/EncounterState";
function PatientDemographics() {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(0);
  const pageVisibilityRef = useRef(true);
  const [timerRunning, setTimerRunning] = useState(true);

  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const patId = params.pid;
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  let imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const ChartviewId = useSelector((state) => state.Encounter.ChartviewId);
  const patientDemotimer = useSelector(
    (state) => state.PatientDemo.patientDemotimer
  );

  const displayMinutes = Math.floor(timer / 60);
  const displaySeconds = (timer % 60).toString().padStart(2, "0");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //
  //-------------------------------------------Function Start-------------------------------------------------------- //
  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  //-------------------------------------------Function End-------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    dispatch(GetSinglePatDetail(patId));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [patId]);

  useEffect(() => {
    setDetails(SingPatDetails);
  }, [SingPatDetails]);

  const handleVisibilityChange = () => {
    pageVisibilityRef.current = document.visibilityState === "visible";
  };

  useEffect(() => {
    dispatch(GetChartviewId(patId));
    document.addEventListener("visibilitychange", handleVisibilityChange);

    window.addEventListener("focus", () => {
      pageVisibilityRef.current = true;
    });
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", () => {});
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timerRunning) {
        setTimer((prevTimer) => prevTimer + 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timerRunning]);
  useEffect(() => {
    if (location.pathname.includes("PatientDemo")) {
      dispatch(
        SetpatientDemotimer({
          patId: patId,
          timer: timer,
        })
      );
    }
  }, [timer]);

  useEffect(() => {
    if (location.pathname?.includes("PatientDemo")) {
      setTimerRunning(true);
    } else {
      setTimerRunning(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      dispatch(
        GetNotesId({
          id: 0,
          pid: ChartviewId.pid,
          encounter_type: "Chart View",
          activity: "Chart View",
          timespent: patientDemotimer.timer,
          eid: ChartviewId.encounter,
          uid: user_id,
          notes: "",
        })
      );
      dispatch(SetChartviewIdempty());
      dispatch(
        SetpatientDemotimer({
          patId: "",
          timer: "",
        })
      );

      dispatch(GetRpmNotes({ id: ChartviewId.encounter, type: "eid" }));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [ChartviewId, patientDemotimer]);

  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return isLoading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <Div display="flex" marginRight="23px">
      <Div>
        <Div
          height="75px"
          width="75px"
          borderRadius="50%"
          display="flex"
          marginLeft="22px"
          marginRight="23px"
          fontSize="60px"
          fontWeight="600"
        >
          {details.img_url !== null ? (
            <Image
              height="75px"
              width="75px"
              borderRadius="50%"
              src={imageUrl + details.img_url}
            ></Image>
          ) : (
            <Div
              width="200px"
              borderRadius="50%"
              background="rgb(168, 174, 190)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              fontFamily="Open Sans, sans-serif"
              color="#FFFFFF"
              position="relative"
            >
              <SpanTag position="absolute" top="18px" fontSize="40px">
                {details.fname !== undefined &&
                  details.fname.charAt(0).toUpperCase()}
              </SpanTag>
            </Div>
          )}
        </Div>
        {details.enrollment_status === "4" ? (
          <Div
            borderRadius="4px"
            background="rgba(242, 72, 34, 0.08)"
            width="97.5%"
            padding="8px 10px 9px 10px"
            margin="16px 0 0 0"
            color="#F24822"
            fontFamily="Open Sans, sans-serif"
            fontWeight="400"
            fontSize="14px"
            lineHeight="15px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            Unenrolled
          </Div>
        ) : (
          ""
        )}
        {details.enrollment_status === "3" &&
        location.pathname.includes("PatientDemo") ? (
          <Div
            marginLeft="48px"
            marginTop="24px"
            textAlign="left"
            fontSize="15px"
            color="#2E2E2E"
            fontWeight="400"
            lineHeight="16.34px"
            fontFamily="opensans,sans-serif"
          >
            {`${displayMinutes}:${displaySeconds}`}
          </Div>
        ) : (
          ""
        )}
      </Div>
      <DivTab marginLeft="20px" overflowX="auto">
        <Table border="none">
          <TableHead>
            <Tablerow width="99%">
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 5px 16px"
                width="220px !important"
                marginRight="-28px"
              >
                Name
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 5px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                DOB
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 5px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                SSN Number
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 5px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Patient ID
              </TableHeadCell>
            </Tablerow>
            <Tablerow width="99%">
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                width="220px !important"
                padding="4px 16px 20px 16px"
                marginRight="-28px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.patname}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 1px 20px 16px"
                width="220px !important"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.DOB}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 1px 20px 16px"
                width="220px !important"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.ss}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 1px 20px 16px"
                width="220px !important"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.patid}</TextContent>
              </TableBodyCell>
            </Tablerow>

            <Tablerow width="99%">
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 16px 4px 16px"
                width="220px !important"
                marginRight="-28px"
              >
                Age
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 4px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Gender
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 4px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Email
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 4px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Phone Number
              </TableHeadCell>
            </Tablerow>
            <Tablerow width="99%">
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 16px 20px 16px"
                width="220px !important"
                marginRight="-28px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.age}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 1px 20px 16px"
                width="220px !important"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.sex}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                width="220px !important"
                padding="4px 1px 20px 16px"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.email}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 1px 20px 16px"
                width="220px !important"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">
                  {details.phone_cell}
                </TextContent>
              </TableBodyCell>
            </Tablerow>

            <Tablerow width="99%">
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 16px 4px 16px"
                width="220px !important"
                marginRight="-28px"
              >
                Program
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 4px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Address
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 4px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Ethnicity
              </TableHeadCell>
              <TableHeadCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                color="#2E2E2E"
                padding="4px 1px 4px 16px"
                width="220px !important"
                marginLeft="32px"
              >
                Race
              </TableHeadCell>
            </Tablerow>
            <Tablerow width="99%">
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                padding="4px 16px 4px 16px"
                width="220px !important"
                marginRight="-28px"
                verticalAlign="top"
              >
                <TextContent> RPM</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                width="220px !important"
                padding="4px 1px 4px 16px"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent
                  lineHeight="24px"
                  width="250px"
                  title={details.address}
                >
                  {truncateText(details.address, 90)}
                </TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                width="220px !important"
                padding="4px 1px 4px 16px"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.ethnicity}</TextContent>
              </TableBodyCell>
              <TableBodyCell
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#2E2E2E"
                width="220px !important"
                padding="4px 1px 4px 16px"
                marginLeft="32px"
                verticalAlign="top"
              >
                <TextContent lineHeight="24px">{details.race}</TextContent>
              </TableBodyCell>
            </Tablerow>
          </TableHead>
        </Table>
      </DivTab>
    </Div>
  );
}

export default PatientDemographics;
