import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  ButtonGroup,
  Div,
  Input,
  Span,
  SpanTag,
  ModalBody,
  ModalFooter,
  SelectInput,
  DropDownDiv,
  DropdownOptions,
  RedStar,
} from "../../StyledComponents";
import { AiOutlineClose } from "react-icons/ai";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { useSelector, useDispatch } from "react-redux";
import { TextArea, DropdownImage, ModalHeader1 } from "./styles";
import { FiTrash2 } from "react-icons/fi";
import {
  GetCodeVerify,
  GetServiceDeleteList,
  GetServiceInsertList,
  GetServiceUpdateList,
  setEmptyCodeMatchResponse,
} from "../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const AddServices = ({ modalRef, show, id, setOpen, serviceData }) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const dispatch = useDispatch();
  const ServiceOneList = useSelector((state) => state.Setting.ServiceOneList);
  const currency = useSelector((state) => state.Setting.Currency);
  const CodeVerify = useSelector((state) => state.Setting.CodeVerify);
  const provider =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [codeTypeVal, setCodeTypeVal] = useState([]);
  const [codeVal, setCodeVal] = useState("");
  const [rateVal, setRateVal] = useState("");
  const [comments, setComments] = useState("");
  const [conditionVal, setConditionVal] = useState("");
  const [ShowOptions, setShowOptions] = useState(false);
  const [disable, setdisable] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [initialCode, setInitialCode] = useState(false);
  const [dis, setDis] = useState(false);

  const [Error, setError] = useState({
    codeTypeErr: false,
    codeErr: false,
    rateErr: false,
    conditionErr: false,
    codeMatchErr: false,
    commentErr: false,
  });
  const Servicedropdown = [
    {
      label: "CPT4",
      Key: "CPT4",
      id: "1",
    },
    {
      label: "Custom",
      Key: "Custom",
      id: "2",
    },
  ];

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const handledelete = () => {
    if (
      ServiceOneList.code !== "CPT99453" &&
      ServiceOneList.code !== "CPT99454" &&
      ServiceOneList.code !== "CPT99457" &&
      ServiceOneList.code !== "CPT99458" &&
      ServiceOneList.code !== "CPT99091" &&
      ServiceOneList.code !== "99453" &&
      ServiceOneList.code !== "99454" &&
      ServiceOneList.code !== "99457" &&
      ServiceOneList.code !== "99458" &&
      ServiceOneList.code !== "99091"
    ) {
      if (window.confirm("Are you sure you want to Delete?")) {
        const articles = {
          rid: id,
          uid: provider,
        };
        dispatch(
          GetServiceDeleteList({
            data: articles,
            datas: serviceData,
          })
        );
      }
      setOpen(false);
    }
  };

  const changeRate = (e) => {
    const newValue = e.target.value;

    if (/^(\$)?\d*(\.\d{0,2})?$/.test(newValue)) {
      setRateVal(newValue);
    }
    setError((prevError) => ({ ...prevError, rateErr: false }));
  };
  const setCodeTypeSelect = (option) => {
    setCodeTypeVal(option.label);
    setCodeVal("");
    setConditionVal("");
    setComments("");
    setRateVal("");
    setShowOptions(false);
    setError((prevError) => ({
      ...prevError,
      codeTypeErr: false,
      codeMatchErr: false,
    }));
  };
  const changeCode = (e) => {
    if (!e.target.value.includes(" ")) {
      const newCodeVal = e.target.value;
      if (codeTypeVal === "Custom") {
        setCodeVal(e.target.value);
        setInitialCode(true);
        setError((prevError) => ({
          ...prevError,
          codeErr: false,
          codeMatchErr: false,
        }));
      } else if (!isNaN(e.target.value)) {
        setInitialCode(true);
        setCodeVal(e.target.value);
        setError((prevError) => ({
          ...prevError,
          codeErr: false,
          codeMatchErr: false,
        }));
      }

      updateCondition(newCodeVal, comments);
    }
  };
  const changeComments = (e) => {
    const newCommentVal = e.target.value;
    setComments(e.target.value);
    setError((prevError) => ({ ...prevError, commentErr: false }));
    updateCondition(codeVal, newCommentVal);
  };

  const updateCondition = (newCodeVal, newCommentVal) => {
    if (newCodeVal !== "" || newCommentVal !== "") {
      setConditionVal(`code.cpt4-${newCodeVal}=="${newCommentVal}"`);
    } else {
      setConditionVal("");
    }
  };

  const changeCondition = (e) => {
    setConditionVal(e.target.value);
    setError((prevError) => ({ ...prevError, conditionErr: false }));
  };
  const handleContainerClick = (e) => {
    if (ShowOptions) {
      setShowOptions(false);
    }
    e.stopPropagation();
  };
  const handleClose = () => {
    dispatch(setEmptyCodeMatchResponse());
    setOpen(false);
    setbtnDisabled(false);
  };
  const HandleSaveService = () => {
    let err = "";

    if (codeTypeVal.length === 0) {
      setError((prevError) => ({ ...prevError, codeTypeErr: true }));
      err = "1";
    }
    if (codeVal === "") {
      setError((prevError) => ({
        ...prevError,
        codeErr: true,
        codeMatchErr: false,
      }));
      err = "1";
    }
    if (rateVal === "" || rateVal === `${currency}`) {
      setError((prevError) => ({ ...prevError, rateErr: true }));
      err = "1";
    }
    if (comments === "") {
      setError((prevError) => ({ ...prevError, commentErr: true }));
      err = "1";
    }
    if (Error.codeMatchErr === true) {
      err = "1";
    }
    if (err > 0) {
      return false;
    } else {
      setDis(true);
      var ssfees = `{"standard":"${rateVal}"}`;
      if (id === "0") {
        setbtnDisabled(true);
        const articles = {
          code: codeVal,
          codetype: codeTypeVal,
          codetext: comments,
          fees: rateVal,
          actiontype: "new",
          updateby: provider,
          sfees: ssfees,
        };
        dispatch(GetServiceInsertList({ data: articles, datas: serviceData }));
      } else {
        setbtnDisabled(true);
        if (
          ServiceOneList.code !== "CPT99453" &&
          ServiceOneList.code !== "CPT99454" &&
          ServiceOneList.code !== "CPT99457" &&
          ServiceOneList.code !== "CPT99458" &&
          ServiceOneList.code !== "CPT99091" &&
          ServiceOneList.code !== "99453" &&
          ServiceOneList.code !== "99454" &&
          ServiceOneList.code !== "99457" &&
          ServiceOneList.code !== "99458" &&
          ServiceOneList.code !== "99091"
        ) {
          const articles = {
            id: id,
            code: codeVal,
            codetype: codeTypeVal,
            codetext: comments,
            fees: rateVal,
            updateby: provider,
            sfees: ssfees,
          };
          dispatch(
            GetServiceUpdateList({ data: articles, datas: serviceData })
          );
        }
      }
      setOpen(false);
      setTimeout(() => {
        setDis(false);
      }, 1500);
    }
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(ServiceOneList).length > 0 && id !== "0") {
      setdisable(false);
      setCodeTypeVal(ServiceOneList.code_type);
      setCodeVal(ServiceOneList.code);
      setRateVal(ServiceOneList.fee);
      setComments(ServiceOneList.code_text);
      if (ServiceOneList.code && ServiceOneList.code_text) {
        setConditionVal(
          `code.${ServiceOneList.code}=="${ServiceOneList.code_text}"`
        );
      } else {
        setConditionVal("");
      }
      if (
        (ServiceOneList.code === "CPT99453" ||
          ServiceOneList.code === "CPT99454" ||
          ServiceOneList.code === "CPT99457" ||
          ServiceOneList.code === "CPT99458" ||
          ServiceOneList.code === "CPT99091" ||
          ServiceOneList.code === "99453" ||
          ServiceOneList.code === "99454" ||
          ServiceOneList.code === "99457" ||
          ServiceOneList.code === "99458" ||
          ServiceOneList.code === "99091") &&
        ServiceOneList.code_type === "CPT4"
      ) {
        setdisable(true);
      } else {
        setdisable(false);
      }
    }
  }, [ServiceOneList]);
  useEffect(() => {}, [conditionVal]);

  useEffect(() => {
    if (initialCode) {
      if (CodeVerify === "Code not found") {
        setError((prevError) => ({ ...prevError, codeMatchErr: false }));
      } else {
        setError((prevError) => ({ ...prevError, codeMatchErr: true }));
      }
    }
    if (codeVal === "") {
      setError((prevError) => ({ ...prevError, codeMatchErr: false }));
    }
  }, [CodeVerify]);
  useEffect(() => {
    let isCancelled = false;

    if (codeVal !== "") {
      const handlechange = async () => {
        await timeout(1100);
        if (!isCancelled) {
          dispatch(
            GetCodeVerify({
              code: codeVal,
              type: codeTypeVal,
              id: id,
              val: "code",
            })
          );
        }
      };

      handlechange();
      return () => {
        isCancelled = true;
      };
    }
    setError((prevError) => ({
      ...prevError,
      codeErr: false,
      codeMatchErr: false,
    }));
  }, [codeVal]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <Div>
      <Modal
        show={show}
        onClick={() => setOpen(false)}
        justifyContent="center"
        overflowY="auto"
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainer
          width="50%"
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          overflowX="revert"
          overflowY="revert"
          onClick={handleContainerClick}
        >
          <ModalDialog width="100%">
            <ModalHeader1
              padding="10px 18px 6px 19px"
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
            >
              <ModalTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600px"
                fontSize="16px"
                lineHight="22px"
                color="#2E2E2E"
                fontStyle="SemiBold"
              >
                {id === "0" ? "Add Services" : "Edit Services"}
              </ModalTitle>

              <AiOutlineClose
                onClick={() => {
                  handleClose();
                }}
                style={{
                  marginLeft: "auto",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </ModalHeader1>

            <ModalBody
              overflow=" auto"
              height="384px"
              padding="16px 18px 16px 19px"
              ref={modalRef}
            >
              <Div
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400px"
                fontSize="14px"
                marginBottom="8px !important"
                position="relative"
              >
                Code Type
                <RedStar top="-2px">*</RedStar>
              </Div>
              <Div alignItems="flex-start" position="relative">
                <Div
                  onClick={() => {
                    if (!disable) {
                      setShowOptions(!ShowOptions);
                    }
                  }}
                >
                  <SelectInput
                    disabled={disable}
                    placeholder="'Select Code'"
                    width="100%"
                    height="38px"
                    background={
                      disable ? "rgba(239, 239, 239, 0.3)" : "#FFFFFF"
                    }
                    fontSize="14px"
                    name="icd_type"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    cursor={disable ? "not-allowed " : "pointer "}
                    pl="10px"
                    margin="8px 0px 0px 0px"
                    padding="9px 12px"
                    value={codeTypeVal}
                    style={
                      Error.codeTypeErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    {codeTypeVal}
                  </SelectInput>
                  <DropdownImage
                    right="15px"
                    top="16px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </Div>
                {ShowOptions && Servicedropdown.length > 0 ? (
                  <DropDownDiv
                    margin="5px 0 0 0"
                    width="100%"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                  >
                    {Servicedropdown.map((option, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={option.value}
                          padding="10px 12px 8px 12px"
                          onClick={() => {
                            if (!disable) {
                              setCodeTypeSelect(option);
                            }
                          }}
                        >
                          {option.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}

                {Error.codeTypeErr && (
                  <ErrorMessage>Please select the Code Type</ErrorMessage>
                )}
              </Div>

              <Div
                padding="16px 0px 0px 0px"
                display="flex"
                flexDirection="column"
              >
                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400px"
                  fontSize="14px"
                  marginBottom="8px !important"
                  position="relative"
                >
                  Code <RedStar top="-2px">*</RedStar>
                </Div>
                <Input
                  style={
                    Error.codeErr || Error.codeMatchErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  disabled={disable}
                  cursor={disable ? "not-allowed" : ""}
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  fontSize="14px"
                  color="#2E2E2E"
                  borderRadius="4px"
                  padding="10px 10px 10px 12px"
                  value={codeVal}
                  onChange={changeCode}
                ></Input>
                {Error.codeErr && (
                  <ErrorMessage>Please enter the Code</ErrorMessage>
                )}

                {Error.codeMatchErr && (
                  <ErrorMessage>
                    Code Already Exist. Please enter the New Code.
                  </ErrorMessage>
                )}
              </Div>

              <Div
                padding="16px 0px 0px 0px"
                display="flex"
                flexDirection="column"
              >
                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400px"
                  fontSize="14px"
                  marginBottom="8px !important"
                  position="relative"
                >
                  Rate <RedStar top="-2px">*</RedStar>
                </Div>
                <Div position="relative">
                  <Input
                    style={
                      Error.rateErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    disabled={disable}
                    width="26.5%"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    color="#2E2E2E"
                    borderRadius="4px"
                    padding="10px 10px 10px 22px"
                    value={rateVal}
                    onChange={changeRate}
                    cursor={disable ? "not-allowed" : ""}
                  ></Input>
                  <SpanTag
                    position="absolute"
                    left="10px"
                    top="10px"
                  >{`${currency} `}</SpanTag>
                </Div>
                {Error.rateErr && (
                  <ErrorMessage>Please enter the rate</ErrorMessage>
                )}
              </Div>

              <Div
                padding=" 16px 0px 8px 0px"
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400px"
                fontSize="14px"
                position="relative"
              >
                Description <RedStar top="14px">*</RedStar>
              </Div>
              <TextArea
                disabled={disable}
                border="1px solid rgba(46, 46, 46, 0.25)"
                padding=" 0px 10px 16px 0px"
                cursor={disable ? "not-allowed" : ""}
                value={comments}
                onChange={changeComments}
                style={
                  Error.commentErr
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
              ></TextArea>
              {Error.commentErr && (
                <ErrorMessage>Please enter the Description</ErrorMessage>
              )}
              {codeTypeVal === "CPT4" && (
                <Div padding="12px 0px 0px 0px" display="flex">
                  <Div width="55%">
                    <Div
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="400px"
                      fontSize="14px"
                      marginBottom="8px !important"
                    >
                      Condition
                    </Div>

                    <TextArea
                      width="95.8%"
                      height="209px"
                      fontSize="14px"
                      cursor={disable ? "not-allowed" : ""}
                      disabled={disable}
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      padding=" 0px 0px 16px 0px"
                      value={conditionVal}
                      onChange={changeCondition}
                    ></TextArea>
                    {Error.conditionErr && (
                      <ErrorMessage>Please enter the condition</ErrorMessage>
                    )}
                  </Div>
                  <Div width="45%">
                    <Div
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      marginBottom="8px !important"
                      padding="0px 0px 0px 6px"
                    >
                      Condition Examples:
                    </Div>
                    <Div
                      padding="13px 0px 0px 6px"
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="400px"
                      fontSize="12px"
                      lineHeight="normal"
                    >
                      <Div marginBottom="16px !important" display="flex">
                        <Div>code.cpt4-99453==</Div>
                        <Div width="224px">
                          ”16th day from first reading after device assigned”
                        </Div>
                      </Div>
                      <Div marginBottom="16px !important" display="flex">
                        <Div>code.cpt4-99454==</Div>
                        <Div width="224px">
                          “16 readings received from devices in the encounter”
                        </Div>
                      </Div>
                      <Div marginBottom="16px !important" display="flex">
                        <Div>code.cpt4-99457==</Div>
                        <Div width="224px">“20 minutes call”</Div>
                      </Div>
                      <Div marginBottom="16px !important" display="flex">
                        <Div> code.cpt4-99458==</Div>
                        <Div width="224px">
                          “Extra 20 minutes from initial 20 minutes call”
                        </Div>
                      </Div>
                      <Div marginBottom="16px !important" display="flex">
                        <Div> code.cpt4-99091==</Div>
                        <Div width="224px">“Chart View”</Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              )}
            </ModalBody>
            <ModalFooter
              borderTop="1px solid rgba(46, 46, 46, 0.25)"
              padding="12px 20px 15px 19px"
            >
              <ButtonGroup
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                margin="0 16px 0 0px"
                background="#FFF"
                color="#2C7BE5"
                cursor="pointer"
                onClick={() => {
                  handleClose();
                }}
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                <Span fontSize="14px" fontWeight="600">
                  Close
                </Span>
              </ButtonGroup>
              <ButtonGroup
                type="submit"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 34px 8px 34px"
                background="#2C7BE5"
                color="#FFFFFF"
                disabled={btnDisabled || dis}
                onClick={HandleSaveService}
                cursor={disable ? "not-allowed " : "pointer "}
                isDisabled={dis}
                hoverBackColor="#005FB2"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="'Open Sans', sans-serif"
                >
                  {id === "0" ? "Save" : "Update"}
                </Span>
              </ButtonGroup>
              {id !== "0" && (
                <SpanTag
                  float="right"
                  paddingTop="4px"
                  marginTop="2px"
                  color=" #B00020"
                  cursor={disable ? "not-allowed " : "pointer "}
                  onClick={() => handledelete()}
                >
                  <FiTrash2
                    style={{
                      fill: "#ffffff",
                      stroke: "#f24822",
                      height: "22px",
                      width: "22px",
                    }}
                  />
                </SpanTag>
              )}
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </Div>
  );
};

export default AddServices;
