import { takeLatest } from "redux-saga/effects";

import {
  GetReEmailRes,
  GetNewPswd,
  GetResetVerify,
  setEmptyForgetResponse,
  PostUserData,
} from "../../StateManagement/Reducers/PreLoginState";

import {
  PasswordResetEmailCheckApi,
  NewPasswordEmailCheckApi,
  ResetVerifyCheckApi,
  setForgetEmpty,
  userSignUpWorker,
} from "./PreLoginWorkers";

export function* PasswordResetEmailCheck() {
  yield takeLatest(GetReEmailRes.type, PasswordResetEmailCheckApi);
}

export function* NewPasswordEmailCheck() {
  yield takeLatest(GetNewPswd.type, NewPasswordEmailCheckApi);
}

export function* ResetVerifyCheck() {
  yield takeLatest(GetResetVerify.type, ResetVerifyCheckApi);
}

export function* EmptyForgetSaga() {
  yield takeLatest(setEmptyForgetResponse.type, setForgetEmpty);
}

export function* SignupApiSaga() {
  yield takeLatest(PostUserData.type, userSignUpWorker);
}
