import { createSlice } from "@reduxjs/toolkit";

export const EncounterSlice = createSlice({
  name: "Encounter",
  initialState: {
    NotesTake: "",
    NotesGet: [],
    SingleNote: {},
    GmeetData: {},
    SingleDelete: "",
    AddEncounterList: [],
    EncounterOptions: [],
    VisitEncountersList: [],
    EncounterAlertMsg: "",
    EncounterId: "",
    EncounterSummary: [],
    LockResponse: "",
    encounterDetails: [],
    FeeSheetDetails: [],
    CSVData: "",
    EditEncounter: {},
    NoteId: "",
    ChartviewId: {},
  },
  reducers: {
    GetAddEncounterInsert: (Data) => {
      return Data;
    },
    setAddEncounterInsert: (state, action) => {
      state.AddEncounterList = action.payload;
    },
    setEmptyEncounterdResponse: (state) => {
      return state;
    },

    setEncounterAlertMsg: (state, action) => {
      state.EncounterAlertMsg = action.payload;
    },
    GetEncounterOpitons: (data) => {
      return data;
    },

    setEncounterOptions: (state, action) => {
      state.EncounterOptions = action.payload;
    },
    GetVisitEncounterList: (state) => {
      return state;
    },
    SetVisitEncounterList: (state, action) => {
      state.VisitEncountersList = action.payload;
    },
    setEncounterId: (state, action) => {
      state.EncounterId = action.payload;
    },
    GetEncounterId: (data) => {
      return data;
    },
    GetEncounterSummary: (data) => {
      return data;
    },
    setEncounterSummary: (state, action) => {
      state.EncounterSummary = action.payload;
    },

    GetAddNotesTake: (state) => {
      state.Loading = true;
    },
    SetAddNotesTake: (state, action) => {
      state.NotesTake = action.payload;
      state.Loading = false;
    },

    GetNotesId: (state) => {
      return state;
    },

    SetNotesId: (state, action) => {
      state.NoteId = action.payload;
    },

    GetRpmNotes: (state) => {
      state.Loading = true;
    },
    SetRpmNotes: (state, action) => {
      state.NotesGet = action.payload;
      state.Loading = false;
    },
    GetRpmSingleNote: (state) => {
      state.Loading = true;
    },
    SetRpmSingleNote: (state, action) => {
      state.SingleNote = action.payload;
      state.Loading = false;
    },
    GetRpmGmeetData: (state) => {
      state.Loading = true;
    },
    SetRpmGmeetData: (state, action) => {
      state.GmeetData = action.payload;
      state.Loading = false;
    },
    RpmSingleDelete: (data) => {
      return data;
    },
    SetRpmSingleDelete: (state, action) => {
      state.SingleDelete = action.payload;
      state.Loading = false;
    },
    setEmptyAddNoteResponse: (state) => {
      return state;
    },
    setEmptyDelNoteResponse: (state) => {
      return state;
    },

    SetEmptySingle: (state) => {
      return state;
    },
    setIsLocked: (data) => {
      return data;
    },
    setLockResponse: (state, action) => {
      state.LockResponse = action.payload;
    },
    getEncounterDetails: (data) => {
      return data;
    },
    setEncounterDetails: (state, action) => {
      state.encounterDetails = action.payload;
    },
    getFeeSheetDetails: (data) => {
      return data;
    },
    setFeeSheetDetails: (state, action) => {
      state.FeeSheetDetails = action.payload;
    },
    setCSVData: (state, action) => {
      state.CSVData = action.payload;
    },
    setEditEncounter: (state, action) => {
      state.EditEncounter = action.payload;
    },
    EditEncounterId: (data) => {
      return data;
    },
    GetChartviewId: (state) => {
      return state;
    },
    SetChartviewId: (state, action) => {
      state.ChartviewId = action.payload;
    },
    SetChartviewIdempty: (state) => {
      state.ChartviewId = {};
    },
  },
});

export const {
  GetAddEncounterInsert,
  setAddEncounterInsert,
  setEncounterAlertMsg,
  setEmptyEncounterdResponse,
  GetEncounterOpitons,
  setEncounterOptions,
  GetVisitEncounterList,
  SetVisitEncounterList,
  setEncounterId,
  GetEncounterId,
  GetEncounterSummary,
  setEncounterSummary,
  GetAddNotesTake,
  SetAddNotesTake,
  GetRpmNotes,
  SetRpmNotes,
  GetRpmSingleNote,
  GetRpmGmeetData,
  SetRpmGmeetData,
  SetRpmSingleNote,
  RpmSingleDelete,
  SetRpmSingleDelete,
  setEmptyAddNoteResponse,
  setEmptyDelNoteResponse,
  SetEmptySingle,
  setIsLocked,
  setLockResponse,
  getEncounterDetails,
  setEncounterDetails,
  setFeeSheetDetails,
  getFeeSheetDetails,
  setCSVData,
  setEditEncounter,
  EditEncounterId,
  GetNotesId,
  SetNotesId,
  GetChartviewId,
  SetChartviewId,
  SetChartviewIdempty,
  Getcharttimer,
} = EncounterSlice.actions;

export default EncounterSlice.reducer;
