import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import moment from "moment";
import {
  MessageListApi,
  MessagePatientSearchApi,
  SendMessageApi,
  SendImageApi,
  getSingleMessageApi,
  previewDownloadApi,
  UnreadCountApi,
} from "../Apis/MessengerApi";
import {
  setMessageList,
  setMsgPatSearch,
  setSingleMessage,
  setPreviewFile,
  setNewMsgCount,
  setUnreadCount,
} from "../../StateManagement/Reducers/MessengerState";

export function* MessengerList({ payload }) {
  try {
    const res = yield call(MessageListApi, payload);

    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setMessageList(Data));

      if (Data.length > 0) {
        var active = 0;
        active = Data.filter(
          (item) =>
            moment(item.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD") &&
            item.Sender !== "Provider" &&
            parseInt(item.unread) !== 0
        );
        yield put(setNewMsgCount(active.length));
      }
    }
  } catch (e) {
    yield put(setMessageList([]));
    console.log(e.message);
  }
}

export function* MessengerPatientList({ payload }) {
  const credential = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MessagePatientSearchApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setMsgPatSearch(Data));
    }
  } catch (e) {
    yield put(setMsgPatSearch([]));
    console.log(e.message);
  }
}

export function* startSendMessage({ payload }) {
  let data = payload[0];

  const credential = Encrypt_Value(data.article, "vozorpm");
  const data2 = Encrypt_Value(data.body, "vozorpm");

  try {
    const res = yield call(SendMessageApi, credential);
    if (res.status === 200) {
      if (payload[1] && payload[1] !== "" && payload[1] !== null) {
        yield call(SendImageApi, data.file);
      } else if (payload[2] && payload[2] !== "" && payload[2] !== null) {
        yield call(SendImageApi, data.file);
      }
      try {
        const res2 = yield call(getSingleMessageApi, data2);
        if (res2.status === 200) {
          const Data = Decrypt_Value(res2.data, "vozorpm");
          yield put(setSingleMessage(Data));
        }
      } catch (e) {
        yield put(setSingleMessage([]));
        console.log(e.message);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* startSendImage({ payload }) {
  try {
    const res = yield call(SendImageApi, payload);
    if (res.status === 200) {
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* getSingle({ payload }) {
  const credential = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(getSingleMessageApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setSingleMessage(Data));
    }
  } catch (e) {
    yield put(setSingleMessage([]));
    console.log(e.message);
  }
}

export function* previewDownloadWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(previewDownloadApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setPreviewFile(Data));
    }
  } catch (e) {
    yield put(setPreviewFile({}));
    console.log(e.message);
  }
}

export function* UnreadCount({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozorpm");
  try {
    const res = yield call(UnreadCountApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setUnreadCount(Data));
      const res1 = yield call(MessageListApi, payload.Provider_Id);

      if (res1.status === 200) {
        const Data1 = Decrypt_Value(res1.data, "vozorpm");
        yield put(setMessageList(Data1));

        if (Data1.length > 0) {
          var active = 0;
          active = Data1.filter(
            (item) =>
              moment(item.date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD") &&
              item.Sender !== "Provider" &&
              parseInt(item.unread) !== 0
          );
          yield put(setNewMsgCount(active.length));
        }
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
