import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Div,
  Input,
  Image,
  Text,
  SearchIconSVG,
  DropdownOptions,
  DropDownDiv,
  Table,
  TableBody,
  TableBodyCell,
  TableRow,
  ImageContainer,
} from "../StyledComponents";
import { BiBell } from "react-icons/bi";
import { BsChatLeftText } from "react-icons/bs";
import { FiLock, FiLogOut } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import SecureMessage from "./SecureMessages";
import Notifications from "./Notifications";
import ChangePassword from "../Popup/ChangePassword/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getPatientSearchList,
  GetVitalsNotification,
  GetVitalsNotificationUpdate,
} from "../../StateManagement/Reducers/PatientState";
import { getMessageList } from "../../StateManagement/Reducers/MessengerState";
import { GetproviderDetail } from "../../StateManagement/Reducers/LoginState";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import axiosInst from "../../Config";
import styled from "styled-components";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import DBHost from "../../Config/DBHost";
import {
  GetNotesId,
  GetChartviewId,
  SetChartviewIdempty,
  GetRpmNotes,
} from "../../StateManagement/Reducers/EncounterState";
import { SetpatientDemotimer } from "../../StateManagement/Reducers/PatientDemoState";

const TopNav = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const searchDropdown = useRef(null);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const wrapperNotifiRef = useRef(null);
  const popupDiv = useRef(null);
  const popupRef = useRef(null);
  const popupNotifiRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  const [propopup, setProPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNotifi, setIsOpenNotifi] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [list, setList] = useState([]);
  const [VitalsNotificationList, setVitalsNotificationList] = useState([]);
  const [CheckNotify, setCheckNotify] = useState(false);

  const [loading, setLoading] = useState(true);
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [showValue, setShowValue] = useState(false);
  const patientList = useSelector((state) => state.Patient.patientlist);
  const vitalsNOtifiList = useSelector(
    (state) => state.Patient.VitalsNotification
  );
  const userDetails = useSelector((state) => state.Login.ProviderDetails);
  const NewMsgs = useSelector((state) => state.Messenger.NewMsgs);
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const patientDemotimer = useSelector(
    (state) => state.PatientDemo.patientDemotimer
  );
  const ChartviewId = useSelector((state) => state.Encounter.ChartviewId);
  const Prompt = styled.div`
    background: red;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    position: absolute;
    right: 24px;
    top: 0px;
  `;
  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const handleProfileBtn = (e) => {
    if (propopup === false) {
      setProPopup(true);
    } else {
      setProPopup(false);
    }
  };

  const handleButtonClick = () => {
    if (!permissions.includes("message_access")) {
      setShowAlert(true);
      setModalMsg("Permission denied");
      setStatus("fail");
      setTimeout(() => {
        setShowAlert(false);
        setModalMsg("");
        setStatus("");
      }, 1500);
    } else {
      setIsOpen((prevState) => !prevState);
    }
  };
  const handleNotificationClick = () => {
    if (!permissions.includes("notifics")) {
      setShowAlert(true);
      setModalMsg("Permission denied");
      setStatus("fail");
      setTimeout(() => {
        setShowAlert(false);
        setModalMsg("");
        setStatus("");
      }, 1500);
    } else {
      setCheckNotify(false);
      dispatch(GetVitalsNotificationUpdate(user_id));
      setIsOpenNotifi((prevState) => !prevState);
    }
  };

  const Logout = () => {
    const data = {
      userid: user_id,
    };
    axiosInst
      .post(`/vozorpm/updatelogout`, data)
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        window.location.href = "/";
      });
  };

  const lockClose = () => {
    setShow(false);
  };

  const handleopen = () => {
    setShow(true);
    setProPopup(false);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    setLoading(true);
    setSearchTerm(value);
    if (value !== "") {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  };

  const handleOptionClick = (pid, fname, lname, status) => {
    setSearchTerm(fname + " " + lname);
    setDropdownVisible(false);
    switch (status) {
      case "0":
        navigate(`/PatientEligibility/${pid}`);
        break;
      case "1":
        navigate(`/PatientConsent/${pid}`);
        break;
      case "2":
        navigate(`/PatientConsent/${pid}`);
        break;
      case "3":
        navigate(`/PatientDemo/${pid}`);
        break;

      default:
        break;
    }
  };

  const handleMouseMovement = (event) => {
    if (popupRef.current !== null) {
      if (isOpen && !popupRef.current.contains(event.target)) {
        setTimeout(() => {
          document.body.style.overflow = "";
        }, 200);
      } else if (isOpen) {
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 200);
      }
    }
    if (popupNotifiRef.current !== null) {
      if (isOpenNotifi && !popupNotifiRef.current.contains(event.target)) {
        setTimeout(() => {
          document.body.style.overflow = "";
        }, 200);
      } else if (isOpenNotifi) {
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 200);
      }
    }
    event.preventDefault();
  };

  // ---------------------------------------- Functions end----------------------------------------------------------- //

  // ---------------------------------------- UseEffect Start----------------------------------------------------------- //
  useEffect(() => {
    dispatch(getMessageList(user_id));
  }, []);
  useEffect(() => {
    const handleOutsideClick = async (event) => {
      if (showValue) {
        setIsOpen(true);
        setShowValue(false);
      }
      const container = popupDiv.current;
      if (container && !container.contains(event.target)) {
        if (propopup === true) {
          setProPopup(false);
          event.stopPropagation();
        }
      }
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        wrapperNotifiRef.current &&
        !wrapperNotifiRef.current.contains(event.target)
      ) {
        setIsOpenNotifi(false);
      }
      if (
        searchDropdown.current &&
        !searchDropdown.current.contains(event.target)
      ) {
        if (isDropdownVisible === true) {
          setDropdownVisible(false);
        }
      }
    };

    document.addEventListener("click", handleOutsideClick, true);

    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [propopup, isOpen, isOpenNotifi, isDropdownVisible]);

  useEffect(() => {
    setList(patientList);
  }, [patientList]);

  useEffect(() => {
    if (patientList.length >= 0) {
      setLoading(false);
    }
  }, [patientList]);

  useEffect(() => {
    if (vitalsNOtifiList.length > 0) {
      let checkNotification = vitalsNOtifiList.filter(
        (pat) => pat.notification_seen === 0
      );
      setCheckNotify(checkNotification.length > 0 ? true : false);
      setVitalsNotificationList(vitalsNOtifiList);
    }
  }, [vitalsNOtifiList]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (searchTerm !== "") {
          dispatch(getPatientSearchList({ search_key: searchTerm }));
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [searchTerm]);

  useEffect(() => {
    if (userDetails.providerName === "") {
      dispatch(GetproviderDetail({ id: user_id }));
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMovement, {
      passive: false,
    });
    return () => {
      document.removeEventListener("mousemove", handleMouseMovement);
    };
  }, [setIsOpen, setIsOpenNotifi]);
  useEffect(() => {
    dispatch(GetVitalsNotification(user_id));
  }, []);
  useEffect(() => {
    if (!location.pathname?.includes("PatientDemo")) {
      dispatch(GetChartviewId(patientDemotimer?.patId));
    }
  }, [location.pathname]);
  useEffect(() => {
    if (!location.pathname?.includes("PatientDemo")) {
      if (
        patientDemotimer?.patId !== "" &&
        ChartviewId?.encounter > 0 &&
        patientDemotimer?.timer > 0
      ) {
        dispatch(
          GetNotesId({
            id: 0,
            pid: ChartviewId.pid,
            encounter_type: "Chart View",
            activity: "Chart View",
            timespent: patientDemotimer.timer,
            eid: ChartviewId.encounter,
            uid: user_id,
            notes: "",
          })
        );
        dispatch(GetRpmNotes({ id: ChartviewId.encounter, type: "eid" }));

        dispatch(SetChartviewIdempty());
        dispatch(
          SetpatientDemotimer({
            patId: "",
            timer: "",
          })
        );
      }
    }
  }, [ChartviewId]);

  // ---------------------------------------- UseEffect End----------------------------------------------------------- //
  return (
    <Div
      padding=" 10px 23px"
      background="#FFFFFF"
      display="flex"
      justifyContent="space-between"
      position="sticky"
      top="0px"
      zIndex="1101"
      paddingLeft="80px"
    >
      <Div width="100%" position="relative">
        <SearchIconSVG>
          <AiOutlineSearch />
        </SearchIconSVG>
        <Input
          placeholder={searchTerm ? searchTerm : "Patient Search"}
          fontSize="15px"
          paddingLeft="28px"
          border="1px solid rgba(46, 46, 46, 0.25)"
          height="36px"
          width="50%"
          borderRadius="4px"
          value={searchTerm}
          onChange={handleInputChange}
        />
        {isDropdownVisible && (loading || list.length >= 0) ? (
          <div ref={searchDropdown}>
            <DropDownDiv
              width="50%"
              background="#FFFFFF"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              top="35px"
              maxHeight="256px"
              overflowY="hidden"
            >
              {loading ? (
                <Text padding="10px 12px 8px 12px" fontSize="15px">
                  Loading...
                </Text>
              ) : patientList.length > 0 ? (
                patientList.map((option) => (
                  <DropdownOptions
                    key={option.id}
                    padding="10px 12px 8px 12px"
                    fontSize="15px"
                    fontFamily="sans seriff"
                    onClick={() =>
                      handleOptionClick(
                        option.pid,
                        option.fname,
                        option.lname,
                        option.enrollment_status
                      )
                    }
                  >
                    {option.fname} {option.lname}
                  </DropdownOptions>
                ))
              ) : (
                <Text padding="10px 12px 8px 12px" fontSize="15px">
                  No results found
                </Text>
              )}
            </DropDownDiv>
          </div>
        ) : null}
      </Div>
      <Div
        display="flex"
        justifyContent="flex-end"
        width="100%"
        alignItems="center"
      >
        <div ref={wrapperNotifiRef}>
          <Button
            border="1px solid rgba(46, 46, 46, 0.25)"
            borderRadius="4px"
            padding=" 8.5px 10px"
            marginRight="24px"
            marginLeft="24px"
            background="#ffff"
            color="rgba(46, 46, 46, 1)"
            cursor="pointer"
            onClick={handleNotificationClick}
          >
            <BiBell style={{ width: "14px", height: "16px" }} />
            <Div
              height="10px"
              width="10px"
              backgroundColor="#ff4d4d"
              top="8px"
              right="146px"
              borderRadius="50%"
              display={CheckNotify ? "block" : "none"}
              position="absolute"
            ></Div>
          </Button>
          {isOpenNotifi && (
            <Notifications
              popupNotifiRef={popupNotifiRef}
              VitalsData={VitalsNotificationList}
            />
          )}
        </div>

        <div ref={wrapperRef} style={{ position: "relative" }}>
          {NewMsgs !== 0 && <Prompt />}
          <Button
            border="1px solid rgba(46, 46, 46, 0.25)"
            borderRadius="4px"
            padding=" 8.5px 10px"
            marginRight="24px"
            background="#ffff"
            color="rgba(46, 46, 46, 1)"
            cursor="pointer"
            onClick={handleButtonClick}
          >
            <BsChatLeftText style={{ width: "16", height: "16px" }} />
          </Button>
          {(isOpen || showValue) && (
            <SecureMessage popupRef={popupRef} setShowValue={setShowValue} />
          )}
        </div>

        <Div
          display="flex"
          justifyContent="center"
          // alignItems="center"
          borderRadius="50%"
          width="40px"
          height="40px"
          alignItems="center"
          backgroundColor="rgb(192, 192, 192)"
          onClick={(e) => {
            handleProfileBtn(e);
          }}
          cursor="pointer"
        >
          {userDetails.img_url ? (
            <Image
              src={imageUrl + userDetails.img_url}
              alt="avatar_1"
              width="40px"
              height="40px"
              borderRadius="50%"
              objectFit="cover"
            />
          ) : (
            <Text padding="0" fontWeight="600" fontSize="1.5em" color="#ffff">
              {userDetails.providerName.charAt(0).toUpperCase()}
            </Text>
          )}
        </Div>
      </Div>

      {propopup ? (
        <Div
          ref={popupDiv}
          position="absolute"
          top="60px"
          right="54px"
          backgroundColor=" #ffff"
          borderRadius="4px"
          boxShadow="0px 0px 10px -5px rgba(66, 68, 90, 1)"
        >
          <Table padding="15px 15px">
            <TableBody>
              <TableRow>
                <TableBodyCell paddingLeft={!userDetails.img_url && "10px"}>
                  {userDetails.img_url ? (
                    <Image
                      src={imageUrl + userDetails.img_url}
                      alt="avatar_2"
                      width="30px"
                      height="30px"
                      borderRadius="50%"
                      objectFit="cover"
                    />
                  ) : (
                    <Div
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      padding="8px 12px"
                      width="30px"
                      height="30px"
                      backgroundColor="rgb(192, 192, 192)"
                    >
                      <Text
                        padding="0"
                        fontWeight="600"
                        fontSize="1em"
                        color="#fff"
                      >
                        {userDetails.providerName.charAt(0).toUpperCase()}
                      </Text>
                    </Div>
                  )}
                </TableBodyCell>
                <TableBodyCell className="pro_lo_popup_text">
                  {userDetails.providerName}
                </TableBodyCell>
              </TableRow>

              <TableBodyCell cursor="pointer">
                <FiLock />
              </TableBodyCell>
              <TableBodyCell cursor="pointer" onClick={handleopen}>
                {" "}
                Change Password
              </TableBodyCell>

              <TableRow onClick={Logout}>
                <TableBodyCell cursor="pointer">
                  <FiLogOut color="red" />
                </TableBodyCell>
                <TableBodyCell cursor="pointer">Log Out</TableBodyCell>
              </TableRow>
            </TableBody>
          </Table>
        </Div>
      ) : (
        ""
      )}
      <ChangePassword show={show} onHide={lockClose} />
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </Div>
  );
};

export default TopNav;
