import React, { useState, useRef } from "react";

import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
  Image,
  Button,
} from "../index";
import { ModalDialog, DragDrop, FileInput } from "../UploadDocument/Styles";

import { BsXLg } from "react-icons/bs";
import UploadFile from "../../../Assets/images/uploadDoc.png";

const UploadProfilePicture = ({
  show,
  close,
  setImageText,
  setpreviewimg1,
  setImageFile,
}) => {
  // ------------------------------ State And Variables Start------------------------ //

  // const dispatch = useDispatch();

  const hiddenFileInput = useRef(null);
  const [ModalAnimat] = useState(true);
  const [previewimg, setpreviewimg] = useState("");
  // const Refresh = useSelector((state) => state.Document.Refresh);
  const [thumbnailErr, setThumbnailErr] = useState(false);
  const [thumbErrMsg, setThumbErrMsg] = useState("");
  const [dis, setDis] = useState(false);

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const HandleChange = (event) => {
    let iSize = event.target.files[0].size / 1024 / 1024;
    iSize = Math.round(iSize);
    if (iSize > 2) {
      alert("select the file less than 2 MB");
      event.target.value = "";
      return false;
    }
    var fileName = event.target.value.split("\\").pop();
    var extension = fileName.split(".").pop();
    if (fileName) {
      if (event.target.files && event.target.files.length > 40) {
        event.preventDefault();
        event.stopPropagation();
        setImageText(fileName.slice(0, 4) + "..." + extension);
      } else {
        setImageText(fileName);
      }
    } else {
      setImageText("add file");
    }
    let index = fileName.lastIndexOf(".");
    let format = fileName.slice(index + 1, fileName.length);

    setThumbnailErr(false);
    const formats = ["png", "jpeg", "jpg", "bmp", "gif", "jfif", "PNG"];
    if (formats.includes(format)) {
      if (iSize > 10) {
        setThumbnailErr(true);
        setThumbErrMsg("please upload  file less than 10mb");
      } else {
        setThumbnailErr(false);
        setImageFile(event.target.files[0]);
      }
    } else {
      setThumbnailErr(true);
      setThumbErrMsg("unsupported profile picture format");
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => setpreviewimg(reader.result));
    reader.readAsDataURL(event.target.files[0]);
  };

  const HandleClose = () => {
    setpreviewimg("");
    close();
  };

  const UploadProfile = () => {
    if (!thumbnailErr) {
      setDis(true);
      setpreviewimg1(previewimg);
      setpreviewimg("");
      close();
      setTimeout(() => {
        setDis(false);
      }, 1500);
    }
  };

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  // ------------------------------Functions End---------------------------- //

  return (
    <Modal
      show={show}
      display="flex"
      justifyContent="center"
      width="100%"
      height="100%"
      onClick={() => {
        close();
        setpreviewimg("");
      }}
    >
      <ModalContainer
        justifyContent="center"
        borderRadius="12px"
        onClick={handleContainerClick}
      >
        <ModalDialog animation={ModalAnimat} padding="20px">
          <Div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ModalTitle
              lineHight="27px"
              fontSize="20px"
              fontWeight="600"
              color="#0a1629"
            >
              Upload Picture
            </ModalTitle>

            <BsXLg
              style={{
                color: "#407BFF",
                cursor: "pointer",
                height: "14px",
                width: "14px",
              }}
              onClick={HandleClose}
            />
          </Div>
          {previewimg ? (
            <Div padding="20px 0px 0px 0px" position="relative">
              {thumbnailErr ? (
                ""
              ) : (
                <Image
                  src={previewimg}
                  height="100%"
                  width="100%"
                  maxHeight="201px"
                  objectFit="contain"
                />
              )}
              <FileInput
                position="absolute"
                left="0px"
                width="100%"
                height="100%"
                top="0px"
                type="file"
                ref={hiddenFileInput}
                onChange={HandleChange}
                style={{
                  opacity: 0,
                }}
                multiple={false}
                accept=".png, .jpg, .jpeg,.PNG"
              />
              <DragDrop
                padding="10px"
                id="dropArea"
                border={thumbnailErr ? "1px dashed red" : "1px dashed #e5e5e5"}
              >
                <Div display="flex">
                  <Image
                    loading="lazy"
                    width="16px"
                    height="16px"
                    src={UploadFile}
                  />
                  <Div display="flex" flexWrap="wrap" alignItems="center">
                    <Text
                      fontSize="14px"
                      lineHeight=" 19px"
                      color="#263446"
                      marginLeft="10px"
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                    >
                      Drag &amp; Drop
                    </Text>

                    <Text
                      fontSize="12px"
                      color="#000000"
                      marginLeft="10px"
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                    >
                      (or)
                    </Text>
                    <Text
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                      fontSize="14px"
                      color="#2C7BE5"
                      lineHeight=" 19px"
                      marginLeft="10px"
                    >
                      Choose Image
                    </Text>
                    <Text
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                      fontSize="14px"
                      lineHeight=" 19px"
                      color="#263446"
                      marginLeft="5px"
                    >
                      to &nbsp; upload
                    </Text>
                    <Text
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                      fontSize="12px"
                      lineHeight=" 16px"
                      color="#7d8592"
                      marginLeft="10px"
                    >
                      JPG,PNG or JPEG Smaller than 2mb
                    </Text>
                  </Div>
                </Div>
              </DragDrop>
              {thumbnailErr && (
                <Text
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                  fontSize="10px"
                  lineHeight=" 16px"
                  color="red"
                  marginLeft="10px"
                >
                  {thumbErrMsg}
                </Text>
              )}
            </Div>
          ) : (
            <Div position="relative">
              <FileInput
                position="absolute"
                left="0px"
                width="100%"
                height="100%"
                top="0px"
                type="file"
                ref={hiddenFileInput}
                onChange={HandleChange}
                style={{
                  opacity: 0,
                }}
                multiple={false}
                accept=".png, .jpg, .jpeg,.PNG"
              />
              <DragDrop>
                <Text
                  fontSize="16px"
                  lineHeight=" 19px"
                  color="#263446"
                  margin="20px 0 20px 0"
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Drag &amp; Drop
                </Text>
                <Image
                  loading="lazy"
                  width="45px"
                  margin="0px auto 25px auto"
                  src={UploadFile}
                />
                <Text
                  fontSize="12px"
                  lineHeight=" 16px"
                  color="#000000"
                  marginBottom="20px"
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                >
                  (or)
                </Text>
                <Text
                  fontSize="16px"
                  lineHeight=" 19px"
                  color="#2C7BE5"
                  marginBottom="20px"
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Choose Image
                </Text>
                <Text
                  fontSize="12px"
                  lineHeight=" 16px"
                  color="#7d8592"
                  marginBottom="20px"
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                >
                  JPG,PNG or JPEG Smaller than 2mb
                </Text>
              </DragDrop>
            </Div>
          )}

          <Div display="flex" justifyContent="end" marginTop="18px">
            <Button
              backgroundColor="#fff"
              color="#2c7be5"
              padding="10px 27px"
              border="1px solid #2c7eb5"
              borderRadius="8px"
              cursor="pointer"
              marginRight="24px"
              onClick={() => {
                setpreviewimg("");
                setpreviewimg1("");
                setImageFile("");
                close();
              }}
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              activeBackgroundColor="rgba(44, 123, 229, 0.10)"
            >
              Cancel
            </Button>
            <Button
              backgroundColor="#2c7be5"
              padding="10px 32px"
              border="none"
              borderRadius="8px"
              color="#fff"
              cursor="pointer"
              onClick={UploadProfile}
              disabled={dis}
              isDisabled={dis}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              Done
            </Button>
          </Div>
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default UploadProfilePicture;
