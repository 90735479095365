import { createSlice } from "@reduxjs/toolkit";

export const LiveSlice = createSlice({
  name: "Live",
  initialState: {
    VitalsDetails: [],
  },
  reducers: {
    GetVitalDetails: (state) => {
      state.Loading = true;
    },

    SetAllVitalsDetails: (state, action) => {
      state.VitalsDetails = action.payload;
      state.Loading = false;
    },
  },
});

export const { GetVitalDetails, SetAllVitalsDetails } = LiveSlice.actions;

export default LiveSlice.reducer;
