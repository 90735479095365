import styled, { css } from "styled-components";
import {
  Container,
  PageSubTitle,
  Label,
  SearchIconSVG,
  Ul,
  Li,
  Div,
} from "../../StyledComponents";

import { FileInput } from "../BillandInsurance/styles";

export const PatientSubTitle = styled(PageSubTitle)`
  margin: ${(p) => p.margin && p.margin};
`;

export const LabelVal = styled(Label)`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  color: ${({ color }) => (color ? color : "#2e2e2e")};
  padding: ${({ padding }) => padding && padding};
  display: ${({ display }) => display && display};
  margin: ${({ margin }) => margin && margin};
`;

export const CheckBoxLabelN = styled.label`
  position: ${(p) => p.position && p.position};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffff;
  border: 0.5px solid #d8e0f0;
  margin-top: 13px;
  margin-left: -16px;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
    // background: #ffffff;
    // background: rgba(46, 46, 46, 0.25);
    background: ${({ check }) =>
      check === true ? "#ffffff" : "rgba(46, 46, 46, 0.25)"};
  }
`;

export const TogggleCheck = styled.input`
  position: ${(p) => p.position && p.position};
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  height: 16px;
  width: 16px;
  position: relative;
  left: 4px;
  background: #ffff;
  &:checked + ${CheckBoxLabelN} {
    background: #407bff;
    // background: #ffffff;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
`;

export const RadioContainer = styled(Container)`
  display: flex;
  margin-left: 175px;
  padding-top: 20px;
  justify-content: space-between;
  margin: auto 170px;
  border-bottom: 1px solid lightgrey;
`;

export const Image = styled.img`
  border-radius: 50%;
  border: 1px solid lightgrey;
  margin-top: 23px;
`;

export const SearchIconIns = styled(SearchIconSVG)`
  position: relative;
  left: 9px;
  top: 34px;
  color: #2e2e2e;
`;

export const Button = styled.button`
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "#2c7be5")};
  /* background-color: ${(p) => p.backgroundColor || "initial"}; */

  &:hover {
    background-color: ${(p) => p.hoverBackColor || "initial"};
  }

  &:active {
    background-color: ${(p) =>
      p.activeBackgroundColor || p.hoverBackColor || "initial"};
  }

  color: ${({ color }) => (color ? color : "#fff")};
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : "9px 22px 9px 22px")};
  border: ${({ border }) => (border ? border : "none")};
  margin: ${({ margin }) => margin && margin};
  font-family: "'Open Sans'", sans-serif;
  height: ${({ height }) => height && height};
  cursor: pointer;
  ${(p) =>
    p.disabled &&
    `
    background-color: rgba(44, 123, 229, 0.50);
    title:"hello";

      `}

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      background-color: rgba(44, 123, 229, 0.5);

      &:hover {
        background-color: rgba(44, 123, 229, 0.5);
      }

      &:active {
        background-color: rgba(44, 123, 229, 0.5);
      }
    `}

  &.btn-responsive {
    @media screen and (max-width: 1013px) {
      padding: 0 10px 0 10px;
      height: 36px;
      font-size: 12px;
    }
  }

  /* &:hover {
    &.bgFFF {
      background-color: #f4f6f9;
    }
    &.bg-2c7be5 {
      background-color: #005fb2;
    }
  } */
`;

export const UL = styled(Ul)`
  margin-left: 28px;
  margin-top: 10px;
`;
export const LI = styled(Li)`
  list-style-type: disc;
  font-size: 14px;
  line-height: 18px;
`;

export const ProgressRadio = styled.input.attrs({ type: "radio" })``;
export const CheckBox = styled.input.attrs({ type: "checkbox" })`
  float: left;
  margin: ${({ margin }) => margin && margin};
`;

export const SpanVal = styled.span`
  border-bottom: 1px solid #2e2e2e;
  width: 80px;
  display: block;
  margin-left: 8px;
  &.flexWrapSpan {
    @media screen and (max-width: 1071px) {
      margin-left: 68px;
    }
  }
`;

export const InputFile = styled(FileInput)`
  cursor: pointer !important;
  position: absolute;
  opacity: 0;
  left: 447px;
  width: 127px;
  height: 36px;
  top: 401px;
  @media screen and (max-width: 1071px) {
    left: 208px;
  }
`;

export const DropDown = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0px;
  svg {
    font-size: 14px;
    float: right;
    margin-right: 5px;
    margin-top: 7px;
    color: rgba(46, 46, 46, 0.5);
  }
`;

export const DropDownDiv = styled(Div)`
  border: 1px solid #cbd5e0;
  font-size: 14px;
  color: #718096;
  padding: 0 0 0 12px;
  border-radius: 4px;
`;

export const DropDownListContainer = styled.div`
  width: 232px;
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  min-width: ${(p) => p.minWidth && p.minWidth};
  ${({ overflowY }) =>
    overflowY &&
    `
  overflow-y : auto;
  max-height : 45%;
  `}
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  option {
    padding: 6px 8px;
    font-family: "Manrope";
    font-size: 14px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  font-size: 14px;
  line-height: 16px;
  color: #414141;
  padding: 10px 16px;
  cursor: poniter;
  &:hover {
    background: #2c7be5 !important;
    cursor: pointer;
    color: #fff;
  }
`;

export const Div1 = styled(Div)`
  &.resipad {
    @media screen and (min-width: 800px) and (max-width: 1067px) {
      height: 1050px !important;
    }
  }
`;
