import React, { useEffect, useState } from "react";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { EmailSVG, ForgotDiv } from "../Styles";
import key from "../../../Assets/images/key.png";
import { ArrowBack } from "../../TopNav/Chat/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  GetReEmailRes,
  setEmptyForgetResponse,
} from "../../../StateManagement/Reducers/PreLoginState";
import { Text } from "../../StyledComponents";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";

import {
  Container,
  PageTitle,
  PageSubTitle,
  Input,
  Button,
  FormGroup,
  Div,
  Image,
  ImageContainer,
} from "../../StyledComponents";

const ForgetPassword = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [mailError, setMailError] = useState(false);
  const [alertMail, setAlertMail] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const emailResponse = useSelector((state) => state.PreLogin.ReEmailResp);
  const fullLink = window.location.href;
  let linkArray = fullLink.split("/");
  linkArray.splice(-1, 1, "setnewpassword");

  let DBHost = "";
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  const rpm_image = finalurl + "/sites/" + DBHost + "/images/rpm_logo.png";
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//

  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const handleEmail = (e) => {
    setMail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailError(false);
    } else {
      setMailError(true);
    }
  };
  const EmailValidation = (e) => {
    e.preventDefault();
    setDisBtn(true);
    if (mail !== "" && !mailError) {
      dispatch(
        GetReEmailRes({ type: { email: mail, link: linkArray.join("/") } })
      );
      setAlertMail(true);
    } else {
      setDisBtn(false);
      setMailError(true);
      setAlertMail(false);
    }
  };
  const handleBacktoLogin = () => {
    Navigate("/");
  };

  useEffect(() => {
    if (alertMail) {
      if (emailResponse.status === "success") {
        setModalAlerShow(true);
        setModalMsg("Mail sent successfully to your mail address");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          Navigate("/EmailCheck", {
            state: { type: { mail }, reset: true },
          });
        }, 2000);
      } else if (emailResponse === "email does not exist") {
        setTimeout(() => {
          setModalAlerShow(true);
          setModalMsg("email does not exist");
          setStatus("fail");
          setDisBtn(false);
          setTimeout(() => {
            setModalMsg("");
            setModalAlerShow(false);
            setStatus("");
          }, 3000);
        }, 1000);
      }
      dispatch(setEmptyForgetResponse());
    }
  }, [emailResponse]);
  // ---------------------------------------------- Functions end-----------------------------------------------------------//

  return (
    <>
      <Container backgroundColor="#F5F5F5">
        <ImageContainer textAlign="center" backgroundColor="#ffff">
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            marginTop="72px"
            marginBottom="10px"
            textAlign="center"
          />
        </ImageContainer>
        <ForgotDiv
          className="adjust-forgot"
          position="absolute"
          maxWidth="483px"
          top="0"
          right="0"
          bottom="0"
          left="0"
          height="max-content"
          backgroundColor="#ffff"
          borderRadius="16px"
          boxShadow="0px 6px 58px rgba(196, 203, 214, 0.103611)"
          padding="40px 40px 45px 40px"
          margin="150px auto"
        >
          <Div
            width="100%"
            display="flex"
            justifyContent="center"
            padding="0px 0px 16px 0px"
          >
            <Div
              backgroundColor="#c9deff"
              border=" 6.5px solid #ecf3ff"
              width="80px"
              height="80px"
              display="flex"
              justifyContent="center"
              borderRadius="50%"
              alignItems="center"
            >
              <Image src={key} alt="key" />
            </Div>
          </Div>
          <PageTitle
            textAlign="center"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="500"
            fontSize="24px"
            color="#2D3748"
          >
            Forgot Password ?
          </PageTitle>
          <PageSubTitle
            textAlign="center"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="400"
            fontSize="16px"
            lineHeight="19px"
            color="#718096"
            marginTop="8px"
            marginBottom="32px"
            align="center"
          >
            No worries,we"ll send you reset instructions.
          </PageSubTitle>

          <PageSubTitle
            marginBottom="10px"
            color="#7D8592"
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            fontFamily="'Open Sans',sans-serif"
          >
            Email Address
          </PageSubTitle>

          <FormGroup position="relative">
            <EmailSVG width="20px" height="20px">
              {" "}
              <FiMail />
            </EmailSVG>

            <Input
              align="left"
              color="#7D8592 !important"
              background="#ffffff"
              border="1px solid #d8e0f0"
              boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
              borderRadius="4px"
              height="44px"
              fontSize="14px"
              fontFamily="'Open Sans',sans-serif"
              lineHeight="17px"
              width="100%"
              error={mailError}
              placeholder="Enter your email"
              marginBottom="10px"
              paddingLeft="38px"
              value={mail}
              onChange={handleEmail}
            />
            {mailError && (
              <Text marginBottom="10px" color="red">
                Enter a valid mail Address
              </Text>
            )}
          </FormGroup>
          <Button
            background="#2C7BE5"
            width="100%"
            fontWeight="400"
            fontSize="16px"
            lineHeight="22px"
            height="48px"
            cursor="pointer"
            color="#FFFFFF"
            borderRadius="4px"
            border="none"
            marginBottom="24px"
            fontFamily="'Open Sans',sans-serif"
            onClick={(e) => EmailValidation(e)}
            disabled={disBtn}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Reset Password
          </Button>

          <Div
            textAlign="center"
            align="center"
            fontFamily="'Open Sans',sans-serif"
            color="#3F8CFF"
            border="none"
            fontWeight="400"
            fontSize="16px"
            lineHeight="22px"
            backgroundColor="#FFFFFF"
            height="48px"
            cursor="pointer"
            onClick={handleBacktoLogin}
          >
            <ArrowBack
              marginRight="7px !important"
              marginBottom="-4px !important"
            />
            Back to login
          </Div>
        </ForgotDiv>
      </Container>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default ForgetPassword;
