import React, { useEffect } from "react";
import { Div, Text } from "../../StyledComponents";
import {
  Refresh,
  HeartRate,
  BloodPressure,
  RespiratoryRate,
  Glucometer,
  Oxygenmeter,
  Weightmeter,
  VitalsText,
  VitalsDiv,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { GetPatId } from "../../../StateManagement/Reducers/PatientDemoState";
import { useParams } from "react-router-dom";
import axiosInst from "../../../Config";
import AccessDenied from "../../AccessDenied";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import DBHost from "../../../Config/DBHost";

const Vitals = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const params = useParams();
  const patId = params.pid;
  const vitalsData1 = useSelector((state) => state.PatientDemo.vitalsData);
  const devicesList = useSelector((state) => state.PatientDemo.DeviceList);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  const parsedPulse = parseInt(vitalsData1.pulse);
  const pulse = !isNaN(parsedPulse) ? parsedPulse : null;

  const parsedBps1 = parseInt(vitalsData1.bps_1);
  const parsedBps2 = parseInt(vitalsData1.bps_2);

  const bps1 = !isNaN(parsedBps1) ? parsedBps1 : null;
  const bps2 = !isNaN(parsedBps2) ? parsedBps2 : null;

  const showBp = bps1 !== null && bps2 !== null;

  const parsedRespiratoryRate = parseInt(vitalsData1.respiration);

  const respiratoryRate = !isNaN(parsedRespiratoryRate)
    ? parsedRespiratoryRate
    : null;

  const parsedOxygenSaturation = parseFloat(vitalsData1.oxygen_saturation);
  const oxygenSaturation = !isNaN(parsedOxygenSaturation)
    ? parsedOxygenSaturation
    : null;
  const parsedWeight = parseFloat(vitalsData1.weight);
  const weight = !isNaN(parsedWeight) ? parsedWeight : null;

  const parsebloodGlucoseLevel = parseInt(vitalsData1.BloodGlucoseLevel);
  const bloodGlucoseLevel = !isNaN(parsebloodGlucoseLevel)
    ? parsebloodGlucoseLevel
    : null;
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  // ----------------------------State and Variables ---------------------------------------------------///
  // -------------------------- Functions Start-------------------------------------------------------------//
  const dispatch = useDispatch();
  const refreshVitals = async () => {
    const deviceModelArray = devicesList.Device_model;
    if (deviceModelArray) {
      const device_mod_obj = deviceModelArray.map(
        (details) => details.device_model
      );

      const wordsToCheckforSmartMeter = {
        words: [
          "iScale",
          "iScale plus",
          "iPlulse Ox",
          "iBlood Pressue",
          "iGlucose",
          "iBlood Pressure plus",
          "iBlood Pressure 2.0",
          "iBlood Pressure Classic",
        ],
        endpoint: "smart_meter_device/iglucose_data.php",
      };

      const wordsToCheckforBodyTrace = {
        words: ["Bodytrace Blood Pressure Monitor", "Bodytrace Weight Scale"],
        endpoint: "body_trace_api/get_data.php",
      };

      const wordsToCheckforTenovi = {
        words: [
          "Tenovi Blood Glucose Meter",
          "Tenovi Pulse Oximeter",
          "Tenovi Weigh Scale",
          "Tenovi Digital Blood Pressure Monitor",
        ],
        endpoint: "tenovi/get_tenovi_data.php",
      };

      if (
        wordsToCheckforSmartMeter.words.some((word) =>
          device_mod_obj.includes(word)
        )
      ) {
        axiosInst.get(
          `${DBHost.finalurl}/interface/customized/custom_api_device_data/${wordsToCheckforSmartMeter.endpoint}?pid=${patId}`
        );
      }
      if (
        wordsToCheckforBodyTrace.words.some((word) =>
          device_mod_obj.includes(word)
        )
      ) {
        axiosInst.get(
          `${DBHost.finalurl}/interface/customized/custom_api_device_data/${wordsToCheckforBodyTrace.endpoint}?pid=${patId}`
        );
      }
      if (
        wordsToCheckforTenovi.words.some((word) =>
          device_mod_obj.includes(word)
        )
      ) {
        axiosInst.get(
          `${DBHost.finalurl}/interface/customized/custom_api_device_data/${wordsToCheckforTenovi.endpoint}?pid=${patId}`
        );
      }
    }
  };

  const getVitals = async () => {
    dispatch(GetPatId(patId));
  };

  const refresh = async () => {
    if (SingPatDetails.enrollment_status !== "4") {
      try {
        await refreshVitals();
        await getVitals();
      } catch (error) {
        console.error(error);
      }
    }
  };
  // -------------------------- Functions End-------------------------------------------------------------//
  // -------------------------- useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    dispatch(GetPatId(patId));
  }, []);
  // -------------------------- useEffect End-------------------------------------------------------------//
  return (
    <Div>
      <Text
        fontFamily="'Open Sans', sans-serif"
        fontWeight="600"
        fontSize="18px"
        color=" #2E2E2E"
        lineHeight="25px"
        fontStyle="normal"
        marginBottom="8px"
        marginLeft="2px"
        padding="20px 0px 0px 0px"
      >
        Vitals
      </Text>
      {permissions.includes("vitals_access") ? (
        <>
          <Div display="flex" alignItems="center" margin="0px 0px 8px 0px">
            <Text
              marginLeft="2px"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="14px"
            >
              {`Most recent vitals from : ${vitalsData1.currentTime}`}
            </Text>
            <Refresh
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
              onClick={refresh}
            />
          </Div>

          <Div
            paddingTop="8px"
            display="flex"
            paddingBottom="18px"
            justifyContent="space-between"
          >
            <Div
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              background=" #FFFFFF"
              width="15.5%"
              height="130px"
              borderRadius="8px"
              padding="0px 15px"
              position="relative"
            >
              <Div display="flex" padding="16px 0px 20px 0px">
                <Div>
                  <HeartRate />
                </Div>
                <VitalsText
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  className="vitalsfont"
                  color=" #2E2E2E"
                  fontStyle="normal"
                  paddingLeft="4px"
                >
                  Heart Rate
                </VitalsText>
              </Div>
              {pulse !== null ? (
                <Div
                  padding="15px 0px 0px 0px"
                  display="flex"
                  alignItems="baseline"
                  position="absolute"
                  bottom="18px"
                >
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="700"
                    fontSize="24px"
                    color={pulse < 60 || pulse > 100 ? "#F24822" : "#2C7BE5"}
                    lineHeight="33px"
                    fontStyle="normal"
                  >
                    {pulse}
                  </Text>

                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    color={pulse < 60 || pulse > 100 ? "#F24822" : "#2C7BE5"}
                    fontStyle="normal"
                    paddingLeft="4px"
                  >
                    BPM
                  </Text>
                </Div>
              ) : (
                <Div> - </Div>
              )}
            </Div>
            <VitalsDiv
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              background=" #FFFFFF"
              width="15.5%"
              height="130px"
              borderRadius="8px"
              className="bloodpressurediv"
              position="relative"
            >
              <Div display="flex" padding="16px 0px 16px 0px">
                <Div>
                  <BloodPressure />
                </Div>
                <VitalsText
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  className="vitalsfont"
                  color=" #2E2E2E"
                  fontStyle="normal"
                  // paddingLeft="0px"
                  marginLeft="-5px"
                >
                  Blood Pressure
                  <span style={{ visibility: "hidden" }}> level </span>
                </VitalsText>
              </Div>
              {showBp ? (
                <Div
                  display="flex"
                  alignItems="baseline"
                  overflow="hidden"
                  position="absolute"
                  bottom="18px"
                >
                  <VitalsText
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="700"
                    color={
                      bps1 >= 90 && bps1 <= 120 && bps2 >= 60 && bps2 <= 80
                        ? "#2C7BE5" // Normal
                        : "#F24822" // All other conditions
                    }
                    lineHeight="33px"
                    fontStyle="normal"
                    className="bloodpressure"
                  >
                    {bps1}/{bps2}
                  </VitalsText>
                  <VitalsText
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    color={
                      bps1 >= 90 && bps1 <= 120 && bps2 >= 60 && bps2 <= 80
                        ? "#2C7BE5" // Normal
                        : "#F24822" // All other conditions
                    }
                    fontStyle="normal"
                    paddingLeft="4px"
                    className="bloodpressuremg"
                  >
                    mm/Hg
                  </VitalsText>
                </Div>
              ) : (
                <Div> - </Div>
              )}
            </VitalsDiv>
            <VitalsDiv
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              background=" #FFFFFF"
              width="15.5%"
              height="130px"
              borderRadius="8px"
              className="respiratorydiv"
              position="relative"
            >
              <Div display="flex" padding="16px 0px 16px 0px">
                <Div>
                  <RespiratoryRate />
                </Div>
                <VitalsText
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  color=" #2E2E2E"
                  fontStyle="normal"
                  paddingLeft="4px"
                  className="respiratory"
                >
                  Respiratory Rate
                  <span style={{ visibility: "hidden" }}> level </span>
                </VitalsText>
              </Div>
              {respiratoryRate !== null ? (
                <Div
                  display="flex"
                  alignItems="baseline"
                  position="absolute"
                  bottom="18px"
                >
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="700"
                    fontSize="24px"
                    color={
                      respiratoryRate >= 12 && respiratoryRate <= 20
                        ? "#2C7BE5" // Normal
                        : "#F24822" // Abnormal
                    }
                    lineHeight="33px"
                    fontStyle="normal"
                  >
                    {respiratoryRate}
                  </Text>
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    color={
                      respiratoryRate >= 12 && respiratoryRate <= 20
                        ? "#2C7BE5" // Normal
                        : "#F24822" // Abnormal
                    }
                    fontStyle="normal"
                    paddingLeft="4px"
                  >
                    RPM
                  </Text>
                </Div>
              ) : (
                <Div marginTop="-10px"> - </Div>
              )}
            </VitalsDiv>
            <VitalsDiv
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              background=" #FFFFFF"
              width="15.5%"
              height="130px"
              borderRadius="8px"
              className="bloodpressurediv"
              position="relative"
            >
              <Div
                display="flex"
                // alignItems="flex-start"
                padding="16px 0px 16px 0px"
              >
                <Div>
                  <Glucometer />
                </Div>
                <VitalsText
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  color=" #2E2E2E"
                  fontStyle="normal"
                  paddingLeft="4px"
                  className="respiratory"
                >
                  Blood Glucose level
                </VitalsText>
              </Div>
              {bloodGlucoseLevel !== null ? (
                <Div
                  display="flex"
                  alignItems="baseline"
                  position="absolute"
                  bottom="18px"
                >
                  <VitalsText
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="700"
                    className="glucose"
                    color={
                      bloodGlucoseLevel >= 120 && bloodGlucoseLevel <= 140
                        ? "#2C7BE5" // Normal
                        : "#F24822" // Abnormal
                    }
                    lineHeight="33px"
                    fontStyle="normal"
                  >
                    {bloodGlucoseLevel}
                  </VitalsText>
                  <VitalsText
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    className="glucosemg"
                    color={
                      bloodGlucoseLevel >= 120 && bloodGlucoseLevel <= 140
                        ? "#2C7BE5" // Normal
                        : "#F24822" // Elevated and High
                    }
                    fontStyle="normal"
                    paddingLeft="4px"
                  >
                    mg/dL
                  </VitalsText>
                </Div>
              ) : (
                <Div> - </Div>
              )}
            </VitalsDiv>
            <VitalsDiv
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              background=" #FFFFFF"
              width="15.5%"
              height="130px"
              borderRadius="8px"
              className="bloodpressurediv"
              position="relative"
            >
              <Div
                display="flex"
                // alignItems="flex-start"
                padding="16px 0px 0px 0px"
              >
                <Div>
                  <Oxygenmeter />
                </Div>
                <VitalsText
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="600"
                  color=" #2E2E2E"
                  fontStyle="normal"
                  paddingLeft="4px"
                  className="respiratory"
                >
                  Oxygen Saturation
                  <span style={{ visibility: "hidden" }}>level</span>
                </VitalsText>
              </Div>
              {oxygenSaturation !== null ? (
                <Div
                  display="flex"
                  alignItems="baseline"
                  padding="16px 0px 0px 0px"
                  position="absolute"
                  bottom="18px"
                >
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="700"
                    fontSize="24px"
                    color={
                      oxygenSaturation >= 95 && oxygenSaturation <= 100
                        ? "#2C7BE5" // Normal
                        : "#F24822" // Abnormal
                    }
                    lineHeight="33px"
                    fontStyle="normal"
                  >
                    {oxygenSaturation}
                  </Text>
                  <Text
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    color={
                      oxygenSaturation >= 95 && oxygenSaturation <= 100
                        ? "#2C7BE5" // Normal
                        : "#F24822" // Abnormal
                    }
                    fontStyle="normal"
                    paddingLeft="4px"
                  >
                    %
                  </Text>
                </Div>
              ) : (
                <Div marginTop="15px"> - </Div>
              )}
            </VitalsDiv>
            <Div
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              background=" #FFFFFF"
              width="15.5%"
              height="130px"
              borderRadius="8px"
              position="relative"
            >
              <Div padding="0px 15px">
                <Div display="flex" padding="16px 0px 20px 0px">
                  <Div>
                    <Weightmeter />
                  </Div>
                  <VitalsText
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="600"
                    className="vitalsfont"
                    color=" #2E2E2E"
                    fontStyle="normal"
                    paddingLeft="4px"
                  >
                    Weight
                  </VitalsText>
                </Div>
                {weight !== null ? (
                  <Div
                    padding="15px 0px 0px 0px"
                    display="flex"
                    alignItems="baseline"
                    position="absolute"
                    bottom="18px"
                  >
                    <Text
                      fontFamily="'Open Sans', sans-serif"
                      fontWeight="700"
                      fontSize="24px"
                      color="  #2C7BE5"
                      lineHeight="33px"
                      fontStyle="normal"
                    >
                      {weight}
                    </Text>
                    <Text
                      fontFamily="'Open Sans', sans-serif"
                      fontWeight="600"
                      fontSize="14px"
                      color=" #2C7BE5"
                      fontStyle="normal"
                      paddingLeft="4px"
                    >
                      Kg
                    </Text>
                  </Div>
                ) : (
                  <Div> - </Div>
                )}
              </Div>
            </Div>
          </Div>
        </>
      ) : (
        <AccessDenied width="400px" height="200px" />
      )}
    </Div>
  );
};

export default Vitals;
