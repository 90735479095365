import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ExpiredPassword from "./ExpiredPassword";
import LeftNav from "../LeftNav";
import TopNav from "../TopNav";
import Overview from "./Overview";
import LiveDashboard from "./LiveDashboard";
import VitalSummary from "./VitalSummary";
import TreatmentPlan from "./TreatmentPlan";
import MyPatients from "./MyPatients";
import AccesDenied from "../AccessDenied";
import Welcome from "../Popup/Welcome";
import PlanExpires from "./PlanExpires";
import SubscriberFeature from "./SubScriberFeature";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import {
  GetLoginTime,
  GetchartData,
} from "../../StateManagement/Reducers/DashboardState";
import { GetPlanDetails } from "../../StateManagement/Reducers/TreatmentPlanState";
import {
  Container,
  Div,
  GridContainer,
  GridContainer1,
  GridContainer3,
  Image,
  Modal,
  ModalContainer,
  Spinner,
  SpinnerWrapper,
  Text,
} from "../StyledComponents";
import { OverViewDiv, OverViewDivBlock } from "./Styles";
import Logo from "../../Assets/images/Logo.png";

const Dashboard = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const userDetails = useSelector((state) => state.Login.ProviderDetails);
  const [showWelcome, setShowWelcome] = useState(true);
  const [expTrue, setExpTrue] = useState(false);
  const provider_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const dispatch = useDispatch();
  const ChartLoading = useSelector((state) => state.Dashboard.ChartLoading);
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  const LoginTime = useSelector((state) => state.Dashboard.LoginTime);
  const expires_on =
    localStorage.getItem("expires_on") &&
    Decrypt_Value(localStorage.getItem("expires_on"), "vozorpm");
  const today = new Date();
  const expired_on = new Date(expires_on);
  const today1 = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const expired_on1 = new Date(
    expired_on.getFullYear(),
    expired_on.getMonth(),
    expired_on.getDate()
  );

  // ----------------------------------------- State and Variables End--------------------------------------------------------- //
  const closeWelcomeModal = () => {
    dispatch(GetLoginTime({ uid: provider_id, text: "put" }));
    setShowWelcome(false);
    setExpTrue(true);
  };

  const closePlanExpiry = () => {
    setExpTrue(false);
  };
  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    dispatch(GetLoginTime({ uid: provider_id, text: "get" }));
    dispatch(GetchartData({ userid: provider_id }));
    dispatch(
      GetPlanDetails({
        pid: 0,
      })
    );
  }, []);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //
  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" background="#F1F5F8">
        {permissions.includes("dashboard_access") ? (
          <>
            <Text
              background="#F1F5F8"
              paddingTop="16px"
              paddingLeft="20px"
              fontSize="18px"
              fontWeight="600"
              color="#000"
            >
              Welcome back, {userDetails.providerName}
            </Text>
            <Div margin="0px 20px 20px 20px">
              <>
                <ExpiredPassword />
                {ChartLoading ? (
                  <>
                    <SpinnerWrapper>
                      <Spinner> </Spinner>
                      <Image
                        width="40px"
                        height="40px"
                        position="absolute"
                        src={Logo}
                      />
                    </SpinnerWrapper>
                  </>
                ) : (
                  <>
                    {/* <GridContainer>
                      <Overview />
                      <LiveDashboard />
                    </GridContainer> */}
                    <OverViewDivBlock
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      gap="20px"
                    >
                      <OverViewDiv width="40%" float="left">
                        <Overview />
                      </OverViewDiv>
                      <OverViewDiv width="60%" float="right">
                        <LiveDashboard />
                      </OverViewDiv>
                    </OverViewDivBlock>

                    <GridContainer1>
                      <VitalSummary />
                      <TreatmentPlan />
                    </GridContainer1>

                    <GridContainer3>
                      <MyPatients />
                    </GridContainer3>
                  </>
                )}
              </>
            </Div>
          </>
        ) : (
          <AccesDenied />
        )}
      </Div>
      {LoginTime.first_login === "0" && today1 <= expired_on1 && (
        <Modal justifyContent="center" show={showWelcome}>
          <ModalContainer
            position="relative"
            borderRadius="16px"
            border="1px solid rgba(46, 46, 46, 0.25)"
            maxWidth="80%"
            minWidth="max-content"
            padding="23px 59px 28px 59px"
          >
            <Welcome
              onHide={closeWelcomeModal}
              show={LoginTime.first_login === "1"}
            />
          </ModalContainer>
        </Modal>
      )}

      {expTrue && <PlanExpires show={expTrue} onHide={closePlanExpiry} />}
      {today1 > expired_on1 && <SubscriberFeature />}
    </Container>
  );
};

export default Dashboard;
