import { takeLatest } from "redux-saga/effects";
import {
  GetList,
  GetEditList,
  GetInsertList,
  GetDeleteList,
  GetUpdateList,
  GetAllerUpdateList,
  GetAllerInsertList,
  LabCredentials,
  MedDeleteLists,
  GetMedPost,
  GetMedPut,
  GetMedUpdate,
  GetLaborder,
  GetLaborderone,
  GetLaborderinsert,
  GetLaborderdelete,
  GetLaborderupdate,
  GetLabList,
  GetProviderList,
  setEmptyLabResponse,
} from "../../StateManagement/Reducers/HealthState";
import {
  DeleteList,
  InsertList,
  Lists,
  UpdateList,
  UpdatedList,
  AllerUpdateList,
  AllerInsertList,
  DeleteLists,
  LabGet,
  medicationpostlist,
  medicationputlist,
  medicationupdatelist,
  Laborder,
  Laborderone,
  LaborderInsert,
  LaborderDelete,
  LaborderUpdate,
  Labs,
  Providers,
  setLabEmptyResponse,
} from "./HealthSagaWorkers";

export function* ListSaga() {
  yield takeLatest(GetList.type, Lists);
}

export function* UpdateListSaga() {
  yield takeLatest(GetEditList.type, UpdateList);
}
export function* InsertListSaga() {
  yield takeLatest(GetInsertList.type, InsertList);
}
export function* DeleteListSaga() {
  yield takeLatest(GetDeleteList.type, DeleteList);
}
export function* UpdatedListSaga() {
  yield takeLatest(GetUpdateList.type, UpdatedList);
}
export function* AllerUpdateListSaga() {
  yield takeLatest(GetAllerUpdateList.type, AllerUpdateList);
}
export function* AllerInsertListSaga() {
  yield takeLatest(GetAllerInsertList.type, AllerInsertList);
}
export function* Medtable() {
  yield takeLatest(LabCredentials.type, LabGet);
}
export function* DeleteListsSaga() {
  yield takeLatest(MedDeleteLists.type, DeleteLists);
}
export function* medpostsaga() {
  yield takeLatest(GetMedPost.type, medicationpostlist);
}

export function* medputsaga() {
  yield takeLatest(GetMedPut.type, medicationputlist);
}

export function* medupdatesaga() {
  yield takeLatest(GetMedUpdate.type, medicationupdatelist);
}

export function* LaborderSaga() {
  yield takeLatest(GetLaborder.type, Laborder);
}

export function* LaborderoneSaga() {
  yield takeLatest(GetLaborderone.type, Laborderone);
}
export function* LabInsertSaga() {
  yield takeLatest(GetLaborderinsert.type, LaborderInsert);
}
export function* LabDeleteSaga() {
  yield takeLatest(GetLaborderdelete.type, LaborderDelete);
}
export function* LabUpdateSaga() {
  yield takeLatest(GetLaborderupdate.type, LaborderUpdate);
}

export function* ProviderListSaga() {
  yield takeLatest(GetLabList.type, Providers);
}
export function* LabListSaga() {
  yield takeLatest(GetProviderList.type, Labs);
}
export function* EmptyingLabResponseSaga() {
  yield takeLatest(setEmptyLabResponse.type, setLabEmptyResponse);
}
