import styled from "styled-components";

export const DialpadContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  max-width: 300px;
  margin: 20px auto;
`;

export const DialpadButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "lightslategrey")};
  color: ${({ color }) => (color ? color : "black")};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ bgcolor }) =>
      bgcolor ? bgcolor : "lightslategrey"};
  }
`;
