import axiosInst from "../../Config";
import axios from "axios";
import DBHost from "../../Config/DBHost";

export const ChartDataApi = async (credential) =>
  await axiosInst.get(`/vozorpm/chartData?uid=${credential.userid}`);
export const GetLoginTime_Api = async (credential) =>
  await axiosInst.get(
    `/vozorpm/provider/login/times?uid=${credential.uid}&text=${credential.text}`
  );
export const InitialLogCheck_Api = async (credential) => {
  const site = credential.payload.site;
  return await axios.get(
    DBHost.finalurl + "/rpmdashboard/index.php?site=" + site + "&text=forone",
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }
  );
};
