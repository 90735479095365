import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Div,
  FormLabel,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
  ModalBody,
  Modal,
  ModalContainer,
  DropDownDiv,
  DropdownOptions,
} from "../../StyledComponents";
import { getFeeSheetDetails } from "../../../StateManagement/Reducers/EncounterState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import {
  GetFeeCollapseDelete,
  GetFeeDelete,
} from "../../../StateManagement/Reducers/PatientState";
import {
  DetailDiv,
  SelectDiv,
  DetailBtn,
  DetailDiv1,
  SpanTag1,
} from "./styles";
import { Imagecalendar } from "../../Popup/styles";
import { DropdownImage } from "./styles";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { BsPlayCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { ErrorMessage } from "../BillandInsurance/styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import moment from "moment";
import calendar_icon_custom from "../../../Assets/images/calendar_icon_custom.png";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { setCSVData } from "../../../StateManagement/Reducers/EncounterState";

const ViewDetails = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const eid = useSelector((state) => state.Encounter.EncounterId);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const params = useParams();

  const pid = params.pid;
  const enc_id =
    localStorage.getItem("encounter") &&
    Decrypt_Value(localStorage.getItem("encounter"), "vozorpm");
  const dispatch = useDispatch();
  const [DropTable, setDropTable] = useState(false);
  const [collapsedRows, setCollapsedRows] = useState([]);
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ActivityType, setActivityType] = useState("");
  const [CodeType, setCodeType] = useState("");
  const [ActivityTypeErr, setActivityTypeErr] = useState(false);
  const [FDateErr, setFDateErr] = useState(false);
  const [FToDateErr, setFToDateErr] = useState(false);
  const [FDateErrCheck, setFDateErrCheck] = useState(false);
  const [ToDateErr, setToDateErr] = useState(false);
  const [ToDateErrCheck, setToDateErrCheck] = useState(false);
  const [FDateCheck, setFDateCheck] = useState("");
  const [ToDateCheck, setToDateCheck] = useState("");
  const [showpicker, setShowPicker] = useState(false);
  const [showpicker1, setShowPicker1] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const [validDate1, setValidDate1] = useState("");
  const [validDate, setValidDate] = useState("");
  const [FDate, setFDate] = useState("");
  const [ToDate, setToDate] = useState("");

  const FSheetDetails = useSelector((state) => state.Encounter.FeeSheetDetails);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const ExportCSV = () => {
    dispatch(setCSVData({ source: "viewDetails", pid: pid }));
  };
  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker1();
      closePicker();
    }
  };

  const DropdownOptionList = [
    { label: "Device Assigned", id: "1", Key: "Device Assigned" },
    { label: "Return Call", id: "2", Key: "Return Call" },
    { label: "Review PGHD", id: "3", Key: "Review PGHD" },
    { label: "Medication Refill", id: "4", Key: "Medication Refill" },
    { label: "Lab Orders", id: "5", Key: "Lab Orders" },
    { label: "Careplan Update", id: "6", Key: "Careplan Update" },
  ];
  const DropdownOptionList1 = [
    { label: "CPT4-99453", id: "1", Key: "99453" },
    { label: "CPT4-99454", id: "2", Key: "99454" },
    { label: "CPT4-99457", id: "3", Key: "99457" },
    { label: "CPT4-99458", id: "4", Key: "99458" },
    { label: "CPT4-99091", id: "5", Key: "99091" },
  ];

  const setcollapseTable = (index) => {
    setDropTable(true);
    if (collapsedRows.includes(index)) {
      setCollapsedRows(collapsedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setCollapsedRows([...collapsedRows, index]);
    }
  };

  const deleteCollapse = (rpm, bill, pid, eid, code, status) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (code === "0") {
        return false;
      } else if (status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(
                GetFeeCollapseDelete({
                  rpm: rpm,
                  bill: bill,
                  pid: pid,
                  eid: eid,
                })
              );
            })
            .then(() => {
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                dispatch(
                  getFeeSheetDetails({
                    pid: pid,
                    eid: eid !== "" ? eid : enc_id,
                    ActivityType: "",
                    CodeType: "",
                    from_date: "",
                    to_date: "",
                  })
                );
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  const feesSheetDelete = (pid, eid, lock, status) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (lock === "lock") {
        return false;
      } else if (status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(GetFeeDelete({ pid: pid, eid: eid }));
            })
            .then(() => {
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                dispatch(
                  getFeeSheetDetails({
                    pid: pid,
                    eid: eid !== "" ? eid : enc_id,
                    ActivityType: "",
                    CodeType: "",
                    from_date: "",
                    to_date: "",
                  })
                );
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setFDate(moment(value).format("YYYY-MM-DD"));
      setFDateCheck(moment(value).format("YYYY-MM-DD"));
      setValidDate(new Date(value));
      setFDateErr(false);
      setToDateErr(false);
      setFToDateErr(false);
      setActivityTypeErr(false);
      setFDateErrCheck(false);
    } else {
      setFDate("");
      setFDateCheck("");
      setValidDate("");
    }
    closePicker();
  };
  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setToDate(moment(value).format("YYYY-MM-DD"));
      setToDateCheck(moment(value).format("YYYY-MM-DD"));
      setValidDate1(new Date(value));
      setFDateErr(false);
      setToDateErr(false);
      setFToDateErr(false);
      setActivityTypeErr(false);
      setToDateErrCheck(false);
    } else {
      setToDate("");
      setValidDate1("");
      setToDateCheck("");
    }
    closePicker1();
  };
  const closePicker = () => {
    setShowPicker(false);
  };
  const handlepicker = (e) => {
    e.preventDefault();
    if (showpicker === false) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
    setShowPicker1(false);
  };
  const closePicker1 = () => {
    setShowPicker1(false);
  };

  const handlepicker1 = (e) => {
    e.preventDefault();
    if (showpicker1 === false) {
      setShowPicker1(true);
    } else {
      setShowPicker1(false);
    }
    setShowPicker(false);
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    var err = 0;

    if (
      CodeType === "" &&
      ActivityType === "" &&
      FDate === "" &&
      ToDate === ""
    ) {
      setActivityTypeErr(true);
      setFDateErr(true);
      setToDateErr(true);
      dispatch(
        getFeeSheetDetails({
          pid: pid,
          eid: eid !== "" ? eid : enc_id,
          ActivityType: ActivityType,
          CodeType: CodeType,
          from_date: FDate,
          to_date: ToDate,
        })
      );
      err = 2;
    }
    if (FDate !== "" || ToDate !== "") {
      if (FDate === "" || ToDate === "") {
        setFToDateErr(true);

        err = 2;
      }
    }
    if (FDate !== FDateCheck) {
      setFDateErrCheck(true);
      err = 2;
    }

    if (ToDate !== ToDateCheck) {
      setToDateErrCheck(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    }

    dispatch(
      getFeeSheetDetails({
        pid: pid,
        eid: eid !== "" ? eid : enc_id,
        ActivityType: ActivityType,
        CodeType: CodeType,
        from_date: FDate,
        to_date: ToDate,
      })
    );
  };
  const setActivityTypeSelect = (r) => {
    if (r === 0) {
      setActivityType("");
    } else {
      setActivityType(r.label);
    }

    setShowOptions(false);
    if (r.label !== "") {
      setActivityTypeErr(false);
      setFDateErr(false);
      setToDateErr(false);
    }
  };

  const setCodeTypeSelect = (r) => {
    if (r === 0) {
      setCodeType("");
    } else {
      setCodeType(r.label);
    }
    setShowOptions1(false);
    if (r.label !== "") {
      setActivityTypeErr(false);
      setFDateErr(false);
      setToDateErr(false);
    }
  };
  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "insured_type") {
      setShowOptions(false);
    }
  };
  const handleClick1 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "insured_type1") {
      setShowOptions1(false);
    }
  };

  const handleClose = () => {
    navigate(`/FeeSheet/${pid}`);
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);

    dispatch(
      getFeeSheetDetails({
        pid: pid,
        eid: eid !== "" ? eid : enc_id,
        ActivityType: "",
        CodeType: "",
        from_date: "",
        to_date: "",
      })
    );

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, [pid]);

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleClick1);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleClick1);
    };
  }, []);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <Div className="responsive-right">
      <Modal justifyContent="space-between" show={true}>
        <ModalContainer
          width="100%"
          height="100%"
          justifyContent="space-between"
        >
          <Div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Div
              color="#2E2E2E"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="24px"
              lineHeight="33px"
              marginLeft="30px"
              marginTop="8px"
            >
              View Details
            </Div>
            <AiOutlineClose
              onClick={handleClose}
              style={{
                marginLeft: "auto",
                marginRight: "26.13px",
                marginTop: "18.14px",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Div>
          <Div border="1px solid #E2E8ED" margin=" 8px -20px 0px -20px" />
          <ModalBody padding="8px 30px 133px 30px">
            <DetailDiv
              display="flex"
              padding="8px 0px 16px 0px"
              className="detailinputmargin"
            >
              <Div width="220px" paddingRight="16px">
                <Div position="relative">
                  <SelectDiv
                    className="detailinput"
                    placeholder="'Select Activity'"
                    width="100%"
                    height="38px"
                    background="#FFFFFF"
                    fontSize="14px"
                    name="insured_type"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    padding="9px 12px"
                    onClick={() => setShowOptions(!ShowOptions)}
                    style={
                      ActivityTypeErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    {ActivityType}
                  </SelectDiv>
                  <DropdownImage
                    right="10px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </Div>
                {ShowOptions && DropdownOptionList.length > 0 ? (
                  <DropDownDiv
                    width="200px"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                  >
                    <DropdownOptions
                      padding="10px 12px 8px 12px"
                      onClick={() => setActivityTypeSelect(0)}
                    >
                      Select Activity
                    </DropdownOptions>
                    {DropdownOptionList.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.value}
                          padding="10px 12px 8px 12px"
                          onClick={() => setActivityTypeSelect(r)}
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}

                {ActivityTypeErr && (
                  <ErrorMessage margin="0.25rem 0px 0px 0px">
                    Please Select Activity
                  </ErrorMessage>
                )}
              </Div>
              <Div width="200px" paddingRight="16px">
                <DetailDiv position="relative" className="detailinputdiv">
                  <SelectDiv
                    className="detailinput"
                    placeholder="'Select Codes'"
                    width="100%"
                    height="38px"
                    background="#FFFFFF"
                    fontSize="14px"
                    name="insured_type1"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    padding="9px 12px"
                    onClick={() => setShowOptions1(!ShowOptions1)}
                    style={
                      ActivityTypeErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    {CodeType}
                  </SelectDiv>
                  <DropdownImage
                    right="10px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </DetailDiv>
                {ShowOptions1 && DropdownOptionList1.length > 0 ? (
                  <DropDownDiv
                    width="183px"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                  >
                    <DropdownOptions
                      padding="10px 12px 8px 12px"
                      onClick={() => setCodeTypeSelect(0)}
                    >
                      Select Code
                    </DropdownOptions>
                    {DropdownOptionList1.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.value}
                          padding="10px 12px 8px 12px"
                          onClick={() => setCodeTypeSelect(r)}
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}

                {ActivityTypeErr && (
                  <ErrorMessage margin="0.25rem 0px 0px 27px">
                    Please Select codes
                  </ErrorMessage>
                )}
              </Div>

              <DetailDiv1 className="custompicker">
                <Div
                  position="relative"
                  className="viewdatepicker"
                  padding="0 0 0 0px"
                >
                  <CustomDatePicker
                    id="CustomDatePicker"
                    className="viewcustompicker"
                    fontFamily="'Open Sans', sans-serif"
                    width="140px"
                    height="38px"
                    right="0px"
                    backgroundColour="#f7f7f7 !important"
                    border={
                      FDateErr || FDateErrCheck
                        ? "1px solid red !important"
                        : "1px solid rgba(34,36,38,.15)"
                    }
                    borderRadius="4px"
                    placeholder="YY-MM-DD"
                    value={FDate}
                    onChange={(e) => {
                      let d = moment(
                        e.target.value,
                        "YYYY-MM-DD",
                        true
                      ).isValid();
                      setFDate(e.target.value);
                      setFDateErrCheck(false);
                      if (e.target.value === "") {
                        setValidDate("");
                      } else if (d) {
                        setFDateCheck(e.target.value);
                        let check = false;
                        check =
                          ToDate !== ""
                            ? moment(e.target.value).isAfter(ToDate)
                              ? true
                              : false
                            : false;
                        if (check) {
                          if (validDate !== "") {
                            setFDate(moment(validDate).format("YYYY-MM-DD"));
                          }
                        } else {
                          setValidDate(e.target.value);
                        }
                      }
                    }}
                    onClick={(e) => handlepicker(e)}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        event.target.value.length > 9
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => keyDownExpiry(e)}
                    onResultSelect={dateSelect}
                    showPicker={showpicker}
                    validDate={validDate}
                    closePicker={closePicker}
                    max={moment(ToDate, "YYYY-MM-DD")}
                  />
                  {FDateErr && <ErrorMessage>Please Select Date</ErrorMessage>}
                  {FToDateErr && (
                    <ErrorMessage>Please Select Both Date</ErrorMessage>
                  )}
                  {FDateErrCheck && (
                    <ErrorMessage>Please Select Proper Date</ErrorMessage>
                  )}
                  <Imagecalendar
                    top="7px"
                    right="10px"
                    id="CustomDatePicker"
                    onClick={(e) => handlepicker(e)}
                    src={calendar_icon_custom}
                  ></Imagecalendar>
                </Div>
              </DetailDiv1>
              <SpanTag1 className="toviewmargin">to</SpanTag1>
              <DetailDiv1 className="custompicker1">
                <Div
                  padding="0px 0px"
                  margin="0 16px 0 0px"
                  position="relative"
                  className="viewdatepicker"
                >
                  <CustomDatePicker
                    id="CustomDatePicker"
                    className="viewcustompicker"
                    width="140px"
                    height="38px"
                    right="0px"
                    backgroundColour="#f7f7f7"
                    border={
                      ToDateErr || ToDateErrCheck
                        ? "1px solid red !important"
                        : "1px solid rgba(34,36,38,.15)"
                    }
                    placeholder="YY-MM-DD"
                    value={ToDate}
                    onChange={(e) => {
                      let d = moment(
                        e.target.value,
                        "YYYY-MM-DD",
                        true
                      ).isValid();
                      setToDate(e.target.value);
                      setToDateErrCheck(false);
                      if (e.target.value === "") {
                        setValidDate1("");
                      } else if (d) {
                        setToDateCheck(e.target.value);
                        let check = false;
                        check = FDate
                          ? moment(e.target.value).isBefore(FDate)
                            ? true
                            : false
                          : false;
                        if (check) {
                          if (validDate1 !== "") {
                            setToDate(moment(validDate).format("YYYY-MM-DD"));
                          }
                        } else {
                          setValidDate1(e.target.value);
                        }
                      }
                    }}
                    onClick={(e) => handlepicker1(e)}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        event.target.value.length > 9
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => keyDownExpiry(e)}
                    onResultSelect={dateSelect1}
                    showPicker={showpicker1}
                    validDate={validDate1}
                    closePicker={closePicker1}
                    min={moment(FDate, "YYYY-MM-DD")}
                  />
                  {ToDateErr && <ErrorMessage>Please Select Date</ErrorMessage>}
                  {FToDateErr && (
                    <ErrorMessage>Please Select Both Date</ErrorMessage>
                  )}
                  {ToDateErrCheck && (
                    <ErrorMessage>Please Select Proper Date</ErrorMessage>
                  )}
                  <Imagecalendar
                    top="7px"
                    right="10px"
                    id="CustomDatePicker"
                    onClick={(e) => handlepicker1(e)}
                    src={calendar_icon_custom}
                  ></Imagecalendar>
                </Div>
              </DetailDiv1>

              <DetailBtn
                className="detailupdatebtn"
                marginTop="0px"
                cursor="pointer"
                textAlign="center"
                color="#FFFFFF"
                border="1px solid #2C7BE5"
                borderRadius="4px"
                background="#2C7BE5"
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="14px"
                padding="8px 25px 8px 27px"
                height="36px"
                display="flex"
                alignItems="center"
                onClick={handleUpdate}
              >
                Update
              </DetailBtn>
            </DetailDiv>
            <Div
              border="1px solid #E2E8ED "
              borderRadius="8px"
              height="max-content"
            >
              <Div
                display="flex"
                padding="20px 15px 20px 30px"
                alignItems="center"
              >
                <Div>
                  <FormLabel
                    fontSize="16px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans', sans-serif"
                    lineHeight="20px"
                    fontWeight="600"
                  >
                    Codes and Charges for {SingPatDetails.patname}
                  </FormLabel>
                </Div>
                <Div marginLeft="auto" display="flex" alignItems="center">
                  <Button
                    cursor="pointer"
                    width="96%"
                    textAlign="center"
                    color="#2C7BE5"
                    border="1px solid #2C7BE5"
                    borderRadius="4px"
                    background="#FFF"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    padding="8px 22px 9px 21px"
                    height="36px"
                    marginRight="9px"
                    display="flex"
                    alignItems="center"
                    onClick={ExportCSV}
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                  >
                    Export csv
                  </Button>
                </Div>
              </Div>
              <Div>
                <Table border="none !important" tableLayout="fixed">
                  <TableHead background="#F1F5F8">
                    <Tablerow
                      color="#2C7BE5"
                      fontFamily="Open Sans, sans-serif"
                      fontWeight="600"
                      fontSize="14px"
                    >
                      <TableHeadCell textAlign="center">
                        Encounter
                      </TableHeadCell>
                      <TableHeadCell textAlign="center">
                        Encounter ID
                      </TableHeadCell>
                      <TableHeadCell textAlign="center">Provider</TableHeadCell>
                      <TableHeadCell textAlign="center">Duration</TableHeadCell>
                      <TableHeadCell textAlign="center">Action</TableHeadCell>
                    </Tablerow>
                  </TableHead>

                  <TableBody overflow="auto" maxHeight="450px">
                    {FSheetDetails.length !== 0 ? (
                      FSheetDetails.map((details, index) => (
                        <React.Fragment key={index}>
                          <Tablerow
                            borderBottom={
                              collapsedRows.includes(index)
                                ? "none !important"
                                : "1px solid #E2E8ED"
                            }
                            fontFamily="Open Sans, sans-serif"
                            fontWeight="400"
                            fontSize="14px"
                            bbtom={true}
                          >
                            <TableBodyCell
                              paddingTop="14px"
                              paddingBottom="15px"
                              textAlign="center"
                              paddingRight="0px"
                            >
                              {details.date_range}
                            </TableBodyCell>
                            <TableBodyCell
                              paddingTop="14px"
                              paddingBottom="15px"
                              textAlign="center"
                              paddingRight="0px"
                            >
                              {details.encounter}
                            </TableBodyCell>
                            <TableBodyCell
                              paddingTop="14px"
                              paddingBottom="15px"
                              textAlign="center"
                              paddingRight="0px"
                            >
                              {details.uname}
                            </TableBodyCell>
                            <TableBodyCell
                              paddingTop="14px"
                              paddingBottom="15px"
                              textAlign="center"
                              paddingRight="0px"
                            >
                              {details.month_difference}
                            </TableBodyCell>
                            <TableBodyCell
                              paddingTop="14px"
                              paddingBottom="15px"
                              textAlign="center"
                              paddingRight="0px"
                            >
                              <Div>
                                <BsPlayCircle
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#2C7BE5",
                                    rotate: collapsedRows.includes(index)
                                      ? "270deg"
                                      : "90deg",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setcollapseTable(index)}
                                />
                                <FiTrash2
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    fill: "#ffffff",
                                    stroke: "#f24822",
                                    marginLeft: "20px",
                                    cursor:
                                      details.lock_check === "unlock" &&
                                      details.status_check === "open" &&
                                      SingPatDetails.enrollment_status !== "4"
                                        ? "pointer"
                                        : "not-allowed",
                                  }}
                                  onClick={() =>
                                    feesSheetDelete(
                                      details.pid,
                                      details.encounter,
                                      details.lock_check,
                                      details.status_check
                                    )
                                  }
                                />
                              </Div>
                            </TableBodyCell>
                          </Tablerow>
                          {/* //////////////////////////////////////// */}
                          {DropTable
                            ? collapsedRows.includes(index) && (
                                <Tablerow bbtom={true}>
                                  <TableBodyCell
                                    colSpan={5}
                                    padding="3px 24px 15px 22px "
                                    borderBottom={
                                      collapsedRows.includes(index) &&
                                      "1px solid #E2E8ED"
                                    }
                                  >
                                    <Div
                                      background="#FFFFFF"
                                      boxShadow=" 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
                                      borderRadius="8px"
                                    >
                                      <Div padding="17px 0px 24px 29px">
                                        <FormLabel
                                          fontSize="16px"
                                          color="#2C7BE5"
                                          fontFamily="'Open Sans', sans-serif"
                                          lineHeight="20px"
                                          fontWeight="600"
                                        >
                                          Fee Summary
                                        </FormLabel>
                                      </Div>
                                      <Table border="none !important">
                                        <TableHead background="#F1F5F8">
                                          <Tablerow
                                            color="#2C7BE5"
                                            fontFamily="Open Sans, sans-serif"
                                            fontWeight="600"
                                            fontSize="14px"
                                          >
                                            <TableHeadCell
                                              width="15%"
                                              textAlign="center"
                                            >
                                              Date
                                            </TableHeadCell>
                                            <TableHeadCell
                                              width="22%"
                                              textAlign="center"
                                            >
                                              Activity
                                            </TableHeadCell>
                                            <TableHeadCell
                                              width="10%"
                                              textAlign="center"
                                            >
                                              Duration
                                            </TableHeadCell>
                                            <TableHeadCell
                                              width="12%"
                                              textAlign="center"
                                            >
                                              Code
                                            </TableHeadCell>

                                            <TableHeadCell
                                              width="12%"
                                              textAlign="center"
                                            >
                                              Price
                                            </TableHeadCell>
                                            <TableHeadCell
                                              width="14%"
                                              textAlign="center"
                                            >
                                              Quantity
                                            </TableHeadCell>
                                            <TableHeadCell
                                              width="15%"
                                              textAlign="center"
                                              paddingLeft="14px"
                                            >
                                              Action
                                            </TableHeadCell>
                                          </Tablerow>
                                        </TableHead>
                                        <TableBody>
                                          {FSheetDetails[index]["billing_array"]
                                            ?.length !== 0 ? (
                                            FSheetDetails[index][
                                              "billing_array"
                                            ].map((details1, index) => (
                                              <Tablerow
                                                borderBottom="1px solid #E2E8ED"
                                                fontFamily="Open Sans, sans-serif"
                                                fontWeight="400"
                                                fontSize="14px"
                                              >
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  {details1.fee_date}
                                                </TableBodyCell>
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  {details1.code_text}
                                                </TableBodyCell>
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  {details1.timespent
                                                    ? details1.timespent > 1
                                                      ? details1.timespent +
                                                        " Mins"
                                                      : details1.timespent +
                                                        " Min"
                                                    : "-"}
                                                </TableBodyCell>
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  {details1.code
                                                    ? details1.code
                                                    : "-"}
                                                </TableBodyCell>
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  {details1.fee
                                                    ? details1.fee
                                                    : "-"}
                                                </TableBodyCell>
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  {details1.units
                                                    ? details1.units
                                                    : "-"}
                                                </TableBodyCell>
                                                <TableBodyCell
                                                  paddingTop="14px"
                                                  paddingBottom="15px"
                                                  textAlign="center"
                                                >
                                                  <Div>
                                                    <FiTrash2
                                                      style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        fill: "#ffffff",
                                                        stroke: "#f24822",
                                                        marginLeft: "20px",
                                                        cursor:
                                                          details1.code_disabled ===
                                                            "1" &&
                                                          details.status_check ===
                                                            "open" &&
                                                          SingPatDetails.enrollment_status !==
                                                            "4"
                                                            ? "pointer"
                                                            : "not-allowed",
                                                      }}
                                                      onClick={() =>
                                                        deleteCollapse(
                                                          details1.rpm_id,
                                                          details1.bill_id,
                                                          details1.pid
                                                            ? details1.pid
                                                            : details1.rpm_pid,
                                                          details1.encounter
                                                            ? details1.encounter
                                                            : details1.rpm_eid,

                                                          details1.code_disabled,
                                                          details.status_check
                                                        )
                                                      }
                                                    />
                                                  </Div>
                                                </TableBodyCell>
                                              </Tablerow>
                                            ))
                                          ) : (
                                            <Tablerow height="168px">
                                              <TableBodyCell
                                                textAlign="center"
                                                colSpan={7}
                                              >
                                                <Div>
                                                  <Image
                                                    height="100px"
                                                    width="100px"
                                                    src={Emp_data_pic}
                                                  ></Image>
                                                </Div>
                                                <FormLabel
                                                  fontSize="14px"
                                                  color="#2E2E2E"
                                                  fontFamily="'Open Sans', sans-serif"
                                                  lineHeight="20px"
                                                  fontWeight="400"
                                                >
                                                  No notes has been created to
                                                  view fee summary
                                                </FormLabel>
                                              </TableBodyCell>
                                            </Tablerow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Div>
                                  </TableBodyCell>
                                </Tablerow>
                              )
                            : ""}
                        </React.Fragment>
                      ))
                    ) : (
                      <Tablerow height="409px">
                        <TableBodyCell textAlign="center" colSpan={5}>
                          <Div>
                            <Image
                              height="150px"
                              width="150px"
                              src={Emp_data_pic}
                            ></Image>
                          </Div>
                          <FormLabel
                            fontSize="14px"
                            color="#2E2E2E"
                            fontFamily="'Open Sans', sans-serif"
                            lineHeight="20px"
                            fontWeight="400"
                          >
                            No notes has been created to view it’s summary
                          </FormLabel>
                        </TableBodyCell>
                      </Tablerow>
                    )}

                    {/* ////////////////////////// */}
                  </TableBody>
                </Table>
                {showAlert && (
                  <AlertModal show={showAlert} msg={modalMsg} status={status} />
                )}
              </Div>
            </Div>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </Div>
  );
};

export default memo(ViewDetails);
