import { takeLatest } from "redux-saga/effects";

import {
  ICD10Lists,
  ICD9Lists,
  DrugLists,
  ProcedureLists,
} from "./MedicalSagaWorkers";
import {
  GetDrugList,
  GetICD10List,
  GetICD9List,
  GetProcedure,
} from "../../StateManagement/Reducers/MedicalState";
export function* DrugListSaga() {
  yield takeLatest(GetDrugList.type, DrugLists);
}

export function* ICD10ListSaga() {
  yield takeLatest(GetICD10List.type, ICD10Lists);
}
export function* ICD9ListSaga() {
  yield takeLatest(GetICD9List.type, ICD9Lists);
}
export function* ProcedureSaga() {
  yield takeLatest(GetProcedure.type, ProcedureLists);
}
