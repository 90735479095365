import styled from "styled-components";
import { BiRefresh } from "react-icons/bi";
import { ReactComponent as Heart } from "../../../Assets/images/svg/Vector.svg";
import { ReactComponent as Blood } from "../../../Assets/images/svg/Blood.svg";
import { ReactComponent as Respiratory } from "../../../Assets/images/svg/respiratory.svg";
import { ReactComponent as Gluco } from "../../../Assets/images/svg/Glucometer.svg";
import { ReactComponent as Oxygen } from "../../../Assets/images/svg/oxygen.svg";
import { ReactComponent as Weight } from "../../../Assets/images/svg/weight.svg";
import { Div, TableBodyCell, Text } from "../../StyledComponents";
import { ModalHeader } from "../../StyledComponents";

export const Refresh = styled(BiRefresh)`
  color: rgba(44, 123, 229, 1);
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: -3px;
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const HeartRate = styled(Heart)`
  height: 22px;
  width: 22px;
`;
export const BloodPressure = styled(Blood)`
  height: 24px;
  width: 24px;
`;
export const RespiratoryRate = styled(Respiratory)`
  height: 24px;
  width: 24px;
  margin-bottom: 18px;
`;
export const Glucometer = styled(Gluco)`
  height: 24px;
  width: 24px;
`;
export const Oxygenmeter = styled(Oxygen)`
  height: 26px;
  width: 26px;
`;
export const Weightmeter = styled(Weight)`
  height: 22px;
  width: 22px;
`;
export const AllergyDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
`;

export const AllergyOption = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`;
export const Container1 = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  padding: ${(p) => p.padding && p.padding};
  cursor: ${(props) => (props.disabled ? "not allowed" : "")};
`;
export const TabPanel = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
`;
export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: 18px;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 128px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 125px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 125px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 82px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(169px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 80px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(169px);
                          width: 80px;
                        }
                         }
               `;
    }

    if (props.line === 3) {
      return `
              &::after {
                        content: "";
                        width: 80px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(290px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(290px);
                          width: 76px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(290px);
                          width: 76px;
                        }
                         }
               `;
    }
    if (props.line === 4) {
      return `
              &::after {
                        content: "";
                        width: 98px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(405px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(405px);
                          width: 95px;
                        }
                         }
               `;
    }
    if (props.line === 5) {
      return `
              &::after {
                        content: "";
                        width: 96px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 16px;
                        transform : translateX(538px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(538px);
                          width: 95px;
                        }
                        
                        @media (max-width: 750px) {
                          transform : translateX(538px);
                          width: 95px;
                        }
                         }
               `;
    }
  }}
`;
export const CustomSelectOptions = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: ${(p) => (p.left ? p.left : "-41px")};
  width: ${(p) => (p.width ? p.width : "233px")};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "4px")};
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  // line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  max-height: ${(p) => (p.maxHeight ? p.maxHeight : "200px")};
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
`;

export const CustomSelectOption = styled.div`
  padding: 8px;
  padding-left: 16px;
  cursor: pointer;
  &:hover {
    background-color: #2c7be5 !important;
    color: #ffffff !important;
  }
`;

export const CustomSelectWrapper = styled.div`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  position: relative;
`;
export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: absolute;
  right: ${(p) => p.right && p.right};
  top: ${(p) => p.top && p.top};
`;
export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const ToggleButton = styled.button`
  background-color: ${(props) => (props.active ? "#2C7BE5" : "white")};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
  color: ${(props) => (props.active ? "white" : "black")};
  &:hover {
    background-color: ${(props) => (props.active ? "#2C7BE5" : "white")};
  }
`;
export const RadioInput = styled.input.attrs({ type: "radio" })`
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const RadioLabel = styled.label`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
`;
export const EnrollModal = styled.div`
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: 1255;
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
`;

export const ModalHeader1 = styled(ModalHeader)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
`;

export const DivTab = styled(Div)`
  @media (min-width: 1400px) {
    width: 100% !important;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: lightgray;
    height: 2px;
  }
`;

export const TableBodyCellDev = styled(TableBodyCell)`
  overflow-x: auto;
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: lightgray;
    height: 2px;
  }
`;
export const VitalsText = styled(Text)`
  &.bloodpressure {
    font-size: 24px;
    @media screen and (max-width: 820px) {
      font-size: 18px;
    }
  }
  &.bloodpressuremg {
    font-size: 14px;
    @media screen and (max-width: 820px) {
      font-size: 11px;
    }
  }
  &.vitalsfont {
    font-size: 14px;
    @media screen and (max-width: 820px) {
      font-size: 12px;
    }
  }
  &.respiratory {
    font-size: 14px;
    @media screen and (max-width: 820px) {
      font-size: 10.5px;
    }
  }
  &.glucose {
    font-size: 24px;
    @media screen and (max-width: 820px) {
      font-size: 21px;
    }
  }
  &.glucosemg {
    font-size: 14px;
    @media screen and (max-width: 820px) {
      font-size: 13px;
    }
  }
`;
export const VitalsDiv = styled(Div)`
  &.bloodpressurediv {
    padding: 0px 15px;
    @media screen and (max-width: 820px) {
      padding: 0px 6px;
    }
  }
  &.respiratorydiv {
    padding: 0px 12px;
    @media screen and (max-width: 820px) {
      padding: 0px 5px;
    }
  }
`;
