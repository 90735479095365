import axiosInst from "../../Config";

export const LoginApi = async (credential) =>
  await axiosInst.post(`/vozorpm/login`, credential);
export const LogoExtension_Api = async () =>
  await axiosInst.get("/vozorpm/getlogoext");
export const ChangePassWordApi = async (data) =>
  await axiosInst.put("/vozorpm/update/provider/password", data);
export const ProviderDetailsApi = async (data) =>
  await axiosInst.get("/vozorpm/provider/detail?id=" + data);
export const LoginEmailCheck_Api = async (data) =>
  await axiosInst.post(`/vozorpm/sendverificationemail`, data);

export const CheckSignupDataApi = async (data) =>
  await axiosInst.post(`/vozorpm/check/signup/data`, data);
