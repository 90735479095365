import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import Routes from "../src/Router";
import GlobalStyle from "../src/Modules/GlobalStyle";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./StateManagement/store";

const root = ReactDOM.createRoot(document.getElementById("vozo_rpm"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <Routes />
      </Router>
    </Provider>
  </StrictMode>
);
