import styled from "styled-components";
import { BsXLg } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { Container } from "../../StyledComponents";

export const ArrowIcon = styled(FiChevronDown)`
  color: rgba(46, 46, 46, 0.5);
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
export const DeleteIcon = styled(FiTrash2)`
  fill: #ffffff;
  stroke: #f24822;
  position: relative;
  top: 17px;
  right: 10px;
  transform: translateY(-50%);
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const CloseIcon = styled(BsXLg)`
  color: #2e2e2e;
  cursor: pointer;
  font-size: 10px;
  margin-left: 8px;
  margin-right: 8px;
`;
export const SelectBox = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.JustifyContent && p.JustifyContent};
  border: ${(p) => p.border && p.border};
  padding: ${(p) => p.padding && p.padding};
  cursor: ${(p) => p.cursor && p.cursor};
  width: ${(p) => p.width && p.width};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  position: relative;
  @media (max-width: 780px) {
    width: 100%;
  }
  @media (min-width: 781px) and (max-width: 870px) {
    &.ipadadddiv {
      width: 300px;
    }
  }
`;
export const Input = styled.input.attrs((props) => ({
  readOnly: props.readOnly && "readonly",
  autoComplete: props.autoComplete && "off",
}))`
  color: ${(p) => p.color && p.color};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  &:focus {
    outline: none;
  }
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};

  @media (max-width: 780px) {
    width: 100%;
  }
  @media (min-width: 781px) and (max-width: 870px) {
    &.ipadadddiv {
      width: 300px;
    }
  }
`;
export const OptionsContainer = styled.ul`
  position: absolute;
  top: 38px;
  left: 0;
  width: 378px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0;
  list-style: none;
  z-index: 2;
  box-shadow: 0px 3px 7px -2px rgba(66, 68, 90, 1);
  border-radius: 4px;
  @media (max-width: 780px) {
    width: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: lightgray;
  }
  @media (min-width: 781px) and (max-width: 870px) {
    &.ipadadddiv {
      width: 300px;
    }
  }
`;

export const OptionItem = styled.li`
  padding: 8px;
  cursor: pointer;
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  &:hover {
    background-color: ${(p) =>
      p.backgroundColor ? p.backgroundColor : "#f2f2f2"};
  }
  font-size: 10px;
`;
export const DeviceContainer = styled.div`
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};

  @media (max-width: 780px) {
    display: block;
    width: 100%;
  }
`;
export const MainContainer = styled(Container)`
  @media (min-width: 781px) {
    width: fit-content;
  }
`;
