import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: "login",
  initialState: {
    LoginRes: {},
    logo: [],
    ProviderDetails: {
      img_url: "",
      providerName: "",
    },

    PasswordChangeResponse: "",
    EmailResp: {},
    otp: {},

    verified: false,
    saveLoading: false,
  },
  reducers: {
    LoginCredentials: (user) => {
      return user;
    },

    LoginResponse: (state, action) => {
      state.LoginRes = action.payload;
    },
    GetproviderDetail: (state) => {
      return state;
    },
    setProviderDetails: (state, action) => {
      state.ProviderDetails = action.payload;
    },
    GetLogo: (state) => {
      return state;
    },
    SetLogo: (state, action) => {
      if (action.payload && action.payload !== null) {
        state.logo = action.payload;
      }
    },
    GetPasswordChange: (state) => {
      return state;
    },
    setEmptyPassResponse: (state) => {
      return state;
    },
    setPasswordChange: (state, action) => {
      state.PasswordChangeResponse = action.payload;
    },
    GetEmailRes: (state) => {
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    verifyUser: (state, action) => {
      state.verified = action.payload;
    },
    checkSignUpData: (state) => {
      return state;
    },

    setSaveLoading: (state, action) => {
      state.saveLoading = action.payload;
    },
  },
});

export const {
  LoginResponse,
  LoginCredentials,
  GetLogo,
  SetLogo,
  GetproviderDetail,
  setProviderDetails,
  GetPasswordChange,
  setPasswordChange,
  setEmptyPassResponse,
  GetEmailRes,
  setEmailRes,
  setOtp,
  checkSignUpData,
  setSaveLoading,
  verifyUser,
} = LoginSlice.actions;

export default LoginSlice.reducer;
