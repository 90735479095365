import React, { useState, useEffect } from "react";
import {
  Div,
  FormLabel,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
  Modal,
  ModalContainer,
  SelectInput,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
  SpanTag,
} from "../../StyledComponents";
import { GetFeeDelete } from "../../../StateManagement/Reducers/PatientState";
import { GetSinglePatDetail } from "../../../StateManagement/Reducers/PatientState";
import {
  ViewTableBodyCell,
  ViewModalBody,
  ViewDiv,
  ViewButton,
} from "./styles";
import {
  getEncounterDetails,
  RpmSingleDelete,
  setIsLocked,
  setEmptyDelNoteResponse,
  setEditEncounter,
  GetEncounterSummary,
  GetEncounterId,
} from "../../../StateManagement/Reducers/EncounterState";
import { MdLockOpen, MdLockOutline } from "react-icons/md";
import { Imagecalendar } from "../../Popup/styles";
import calendar_icon_custom from "../../../Assets/images/calendar_icon_custom.png";
import { AiOutlineFileAdd } from "react-icons/ai";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { BsPlayCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineEdit } from "react-icons/ai";
import { ErrorMessage } from "../BillandInsurance/styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import moment from "moment";
import { BsCalendar } from "react-icons/bs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import Notes from "./Notes";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const ViewDetails = (props) => {
  //-------------------------------------------useState and Variables Start--------------------------------------------------------//

  const params = useParams();
  const pid = params.pid;
  const source = params.source;
  const enc_id =
    localStorage.getItem("encounter") &&
    Decrypt_Value(localStorage.getItem("encounter"), "vozorpm");

  const provider_name =
    localStorage.getItem("ProviderName") &&
    Decrypt_Value(localStorage.getItem("ProviderName"), "vozorpm");

  const dispatch = useDispatch();
  const eid = useSelector((state) => state.Encounter.EncounterId);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const RemoveOneNote = useSelector((state) => state.Encounter.SingleDelete);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ActivityType, setActivityType] = useState("");
  const [CodeType, setCodeType] = useState("");
  const [dateError, setDateError] = useState(false);
  const [ActivityTypeErr, setActivityTypeErr] = useState(false);
  const [showpicker, setShowPicker] = useState(false);
  const [showpicker1, setShowPicker1] = useState(false);
  const navigate = useNavigate();
  const [validDate1, setValidDate1] = useState(
    moment(new Date(), "DD/MM/YYYY")
  );
  const [validDate, setValidDate] = useState(moment(new Date(), "DD/MM/YYYY"));
  const [FDate, setFDate] = useState("");
  const [ToDate, setToDate] = useState("");

  const [expandedRows, setExpandedRows] = useState([]);
  const [activeState, setActiveState] = useState([
    {
      note: "",
      billing: "",
      changeNoteSummary: false,
      changeBillSummary: false,
    },
  ]);
  const [showAlert, setShowAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    setCodeType("");
    setActivityType("");
    setActivityTypeErr(false);
    if (source === "encounter") {
      navigate("/Encounter/" + pid);
    } else {
      navigate("/Encounter/VisitEncounter/" + pid);
    }
  };
  const [currentEid, setCurrentEid] = useState("");
  const [encountDetails, setEncounterDetails] = useState([]);
  const [initialShow, setInitialShow] = useState(false);
  const TakeNote = useSelector((state) => state.Encounter.NotesTake);
  //-------------------------------------------useState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const handleCollapseShow = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
    setActiveState((prevState) => {
      const updatedState = [...prevState]; // Create a copy of the previous state array
      updatedState[index] = {
        ...updatedState[index],
        billing: "",
        note: "activeColor",
        changeNoteSummary: true,
        changeBillSummary: false,
      };
      return updatedState;
    });
  };

  const handleDelete = (id, eid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (window.confirm("Are you sure want to delete") === true) {
        Promise.resolve().then(() => {
          dispatch(RpmSingleDelete({ id, type: "view_details" }));
        });
      }
    }
  };

  const feesSheetDelete = (pid, eid, lock, status) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (lock === 1) {
        setShowAlert(true);
        setModalMsg("Encounter is locked");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
        return false;
      } else if (status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(GetFeeDelete({ pid: pid, eid: eid }));
            })
            .then(() => {
              if (source === "visit") {
                dispatch(
                  getEncounterDetails({
                    pid,
                    ActivityType,
                    CodeType,
                    from_date: FDate,
                    to_date: ToDate,
                  })
                );
              } else {
                // if (eid !== "") {
                dispatch(
                  getEncounterDetails({
                    pid,
                    eid: eid !== "" ? eid : enc_id,
                    ActivityType: "",
                    CodeType: "",
                    from_date: "",
                    to_date: "",
                  })
                );
                // }
              }
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  const handleNoteClick = (index) => {
    setActiveState((prevState) => {
      const updatedState = [...prevState]; // Create a copy of the previous state array
      updatedState[index] = {
        ...updatedState[index],
        billing: "",
        note: "activeColor",
        changeNoteSummary: true,
        changeBillSummary: false,
      };
      return updatedState;
    });
  };

  const handleBillingClick = (index) => {
    setActiveState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        billing: "activeColor",
        note: "",
        changeNoteSummary: false,
        changeBillSummary: true,
      };
      return updatedState;
    });
  };

  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker();
      closePicker1();
    }
  };

  const patDetails = useSelector((state) => state.Encounter.encounterDetails);

  const DropdownOptionList = [
    { label: "Device Assigned", id: "1", Key: "Device Assigned" },
    { label: "Return Call", id: "2", Key: "Return Call" },
    { label: "Review PGHD", id: "3", Key: "Review PGHD" },
    { label: "Medication Refill", id: "4", Key: "Medication Refill" },
    { label: "Lab Orders", id: "5", Key: "Lab Orders" },
    { label: "Careplan Update", id: "6", Key: "Careplan Update" },
    {
      label: "Number of Readings completed",
      id: "7",
      Key: "Number of Readings completed",
    },
  ];
  const DropdownOptionList1 = [
    { label: "CPT4-99453", id: "1", Key: "99453" },
    { label: "CPT4-99454", id: "2", Key: "99454" },
    { label: "CPT4-99457", id: "3", Key: "99457" },
    { label: "CPT4-99458", id: "4", Key: "99458" },
    { label: "CPT4-99091", id: "5", Key: "99091" },
  ];

  const currentPosts = patDetails.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(patDetails.length / postsPerPage);

  const setActivityTypeSelect = (e) => {
    let ele = e.target;
    setActivityType(ele.getAttribute("data-value"));
    setShowOptions(false);
    if (e.target.value !== "") {
      setActivityTypeErr(false);
    }
  };
  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "insured_type") {
      setShowOptions(false);
    }
  };
  const setCodeTypeSelect = (e) => {
    let ele = e.target;
    setCodeType(ele.getAttribute("data-value"));
    setShowOptions1(false);
    if (e.target.value !== "") {
      setActivityTypeErr(false);
    }
  };
  const handleClick1 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "insured_type") {
      setShowOptions1(false);
    }
  };
  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setFDate(moment(value).format("YYYY-MM-DD"));
      setValidDate(new Date(value));
      setDateError(false);
      setActivityTypeErr(false);
    } else {
      setFDate("");
      setValidDate("");
    }
    closePicker();
  };
  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setToDate(moment(value).format("YYYY-MM-DD"));
      setValidDate1(new Date(value));
      setActivityTypeErr(false);
      setDateError(false);
    } else {
      setToDate("");
      setValidDate1("");
    }
    closePicker1();
  };
  const closePicker = () => {
    setShowPicker(false);
  };
  const handlepicker = (e) => {
    e.preventDefault();
    setShowPicker(true);
    setShowPicker1(false);
  };
  const closePicker1 = () => {
    setShowPicker1(false);
  };
  const handlepicker1 = (e) => {
    e.preventDefault();
    setShowPicker1(true);
    setShowPicker(false);
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    var err = 0;

    if (
      ActivityType === "" &&
      CodeType === "" &&
      FDate === "" &&
      ToDate === ""
    ) {
      setActivityTypeErr(true);
      dispatch(
        getEncounterDetails({
          pid,
          ActivityType,
          CodeType,
          from_date: FDate,
          to_date: ToDate,
        })
      );
      err = 2;
    }
    if (FDate !== "" || ToDate !== "") {
      if (FDate === "" || ToDate === "") {
        setDateError(true);
        err = 2;
      }
    }

    if (err > 0) {
      return false;
    } else {
      dispatch(
        getEncounterDetails({
          pid,
          ActivityType,
          CodeType,
          from_date: FDate,
          to_date: ToDate,
        })
      );
    }
  };

  const handleNoteAdd = (details, eid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setCurrentEid(eid);
      setInitialShow(true);
      setEncounterDetails(details);
    }
  };
  const handleNoteLock = (details) => {
    if (SingPatDetails.enrollment_status !== "4") {
      const encounterID = details.encounter;
      let article = {
        pid: pid,
        eid: eid !== "" ? eid : enc_id,
        ActivityType,
        CodeType,
        from_date: FDate,
        to_date: ToDate,
      };
      if (details.is_locked === 1) {
        const data = {
          encounter: encounterID,
          locked: 0,
          viewdetails: article,
          src: source,
        };

        dispatch(setIsLocked(data));
        setShowAlert(true);
        setModalMsg("unlocked Successfully");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
      } else {
        const data = {
          encounter: encounterID,
          locked: 1,
          viewdetails: article,
          src: source,
        };

        dispatch(setIsLocked(data));
        setShowAlert(true);
        setModalMsg("locked Successfully");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
      }
    }
  };

  const handleClose = () => {
    setEncounterDetails([]);
    setShow(false);
    if (source === "visit") {
      dispatch(
        getEncounterDetails({
          pid,
          ActivityType,
          CodeType,
          from_date: FDate,
          to_date: ToDate,
        })
      );
    } else {
      // if (eid !== "") {
      dispatch(
        getEncounterDetails({
          pid,
          eid: eid !== "" ? eid : enc_id,
          ActivityType: "",
          CodeType: "",
          from_date: "",
          to_date: "",
        })
      );
      // }
    }
  };

  const handleEdit = (details) => {
    if (SingPatDetails.enrollment_status !== "4") {
      navigate("/Encounter/Encounterfrom/" + pid, {
        state: { locate_from: "view_details" },
      });
      dispatch(
        setEditEncounter({
          encounterId: eid,
          provider: details.id,
          notes: details.notes,
        })
      );
    }
  };
  // ---------------------------------------------- Functions end-----------------------------------------------------------//
  // ---------------------------------------------- useEffect starts-----------------------------------------------------------//
  useEffect(() => {
    dispatch(GetSinglePatDetail(pid));
  }, [pid]);
  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);
    if (source === "visit") {
      dispatch(
        getEncounterDetails({
          pid,
          ActivityType,
          CodeType,
          from_date: FDate,
          to_date: ToDate,
        })
      );
    } else {
      // if (eid !== "") {
      dispatch(
        getEncounterDetails({
          pid,
          eid: eid !== "" ? eid : enc_id,
          ActivityType: "",
          CodeType: "",
          from_date: "",
          to_date: "",
        })
      );
      // }
    }

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, [eid]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleClick1);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleClick1);
    };
  }, []);

  useEffect(() => {
    if (initialShow) {
      if (encountDetails.length != 0) setShow(true);
      else setShow(false);
    }
  }, [encountDetails]);
  useEffect(() => {
    if (RemoveOneNote === "success") {
      if (source === "visit") {
        dispatch(
          getEncounterDetails({
            pid,
            ActivityType,
            CodeType,
            from_date: FDate,
            to_date: ToDate,
          })
        );
      } else {
        // if (eid !== "") {
        dispatch(
          getEncounterDetails({
            pid,
            eid: eid !== "" ? eid : enc_id,
            ActivityType: "",
            CodeType: "",
            from_date: "",
            to_date: "",
          })
        );
      }
      setShowAlert(true);
      setModalMsg("note deleted successfully");
      setStatus("success");
      setTimeout(() => {
        setShowAlert(false);
        setModalMsg("");
        setStatus("");
        dispatch(setEmptyDelNoteResponse());
      }, 2000);
    }
  }, [RemoveOneNote]);

  useEffect(() => {
    if (TakeNote === "success") {
      dispatch(GetEncounterSummary(currentEid));
      dispatch(GetEncounterId(currentEid));
      setTimeout(() => {
        navigate("/Encounter/" + params.pid, {
          state: {
            eid: currentEid,
          },
        });
      }, 2000);
    }
  }, [TakeNote]);
  // ---------------------------------------------- useEffect ends-----------------------------------------------------------//

  return (
    <>
      <Modal justifyContent="space-between" show={true}>
        <ModalContainer
          width="100%"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Div
            display="flex"
            justifyContent="space-between"
            marginTop="20px"
            marginBottom="8px"
          >
            <Div
              color="#2E2E2E"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="24px"
              lineHeight="33px"
              marginLeft="24px"
            >
              View Details
            </Div>
            <AiOutlineClose
              onClick={toggleModal}
              style={{
                marginLeft: "auto",
                marginRight: "52.13px",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Div>
          <Div border="1px solid #E2E8ED" />
          <ViewModalBody>
            {source === "visit" && (
              <ViewDiv display="flex" flexWrap="wrap">
                <Div className="detailinputmargin" margin="2px 16px 0 0">
                  <Div position="relative">
                    <SelectInput
                      className="detailinput"
                      placeholder="'Select Activity'"
                      width="200px"
                      height="38px"
                      background="#FFFFFF"
                      fontSize="14px"
                      name="insured_type"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      whiteSpace="nowrap"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      margin="14px 0px 0px 0px"
                      padding="9px 12px"
                      textOverflow="ellipsis"
                      overFlow="hidden"
                      onClick={() => {
                        setShowOptions(!ShowOptions);
                        setShowOptions1(false);
                      }}
                      style={
                        ActivityTypeErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                    >
                      {ActivityType}
                    </SelectInput>
                    <DropdownImage
                      top="40px"
                      right="10px"
                      src={dropdownDownicon}
                    ></DropdownImage>
                  </Div>
                  {ShowOptions && DropdownOptionList.length > 0 ? (
                    <DropDownDiv
                      width="200px"
                      background="#FFFFFF"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      // marginTop="64px"
                    >
                      <DropdownOptions
                        data-value={""}
                        padding="10px 12px 8px 12px"
                        onClick={(e) => setActivityTypeSelect(e)}
                      >
                        Select Activity
                      </DropdownOptions>
                      {DropdownOptionList.map((r, i) => {
                        return (
                          <DropdownOptions
                            key={`ab${i}`}
                            data-value={r.label}
                            padding="10px 12px 8px 12px"
                            onClick={(e) => setActivityTypeSelect(e)}
                          >
                            {r.label}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  ) : (
                    ""
                  )}

                  {ActivityTypeErr && (
                    <ErrorMessage>Select any value</ErrorMessage>
                  )}
                </Div>
                <Div margin="2px 16px 0 0px">
                  <Div position="relative">
                    <SelectInput
                      className="detailinput"
                      placeholder="'Select Codes'"
                      width="200px"
                      height="38px"
                      background="#FFFFFF"
                      fontSize="14px"
                      name="insured_type"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      margin="14px 0px 0px 0"
                      padding="9px 12px"
                      onClick={() => {
                        setShowOptions1(!ShowOptions1);
                        setShowOptions(false);
                      }}
                      style={
                        ActivityTypeErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                    >
                      {CodeType}
                    </SelectInput>
                    <DropdownImage
                      top="40px"
                      right="10px"
                      src={dropdownDownicon}
                    ></DropdownImage>
                  </Div>
                  {ShowOptions1 && DropdownOptionList1.length > 0 ? (
                    <DropDownDiv
                      width="200px"
                      background="#FFFFFF"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      // marginLeft="24px"
                    >
                      <DropdownOptions
                        data-value={""}
                        padding="10px 12px 8px 12px"
                        onClick={(e) => setCodeTypeSelect(e)}
                      >
                        Select Code
                      </DropdownOptions>
                      {DropdownOptionList1.map((r, i) => {
                        return (
                          <DropdownOptions
                            key={`ca${i}`}
                            data-value={r.label}
                            padding="10px 12px 8px 12px"
                            onClick={(e) => setCodeTypeSelect(e)}
                          >
                            {r.label}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  ) : (
                    ""
                  )}

                  {ActivityTypeErr && (
                    <ErrorMessage margin="4px 0 0 0px">
                      Select any value
                    </ErrorMessage>
                  )}
                </Div>
                {/* <Div padding="2px 40px 5px 0" display="flex" marginTop="14px"> */}
                <Div marginTop="15px">
                  <Div position="relative">
                    <CustomDatePicker
                      padding="0 0 0 10px"
                      id="CustomDatePicker"
                      width="140px"
                      height="38px"
                      backgroundColour="#f7f7f7 !important"
                      borderRadius="4px"
                      placeholder="YY-MM-DD"
                      right="0px"
                      value={FDate} //used in application
                      onChange={(e) => {
                        let d = moment(
                          e.target.value,
                          "YYYY-MM-DD",
                          true
                        ).isValid();
                        setFDate(e.target.value);
                        setActivityTypeErr(false);
                        setDateError(false);
                        if (e.target.value === "") {
                          setValidDate("");
                        } else if (d) {
                          let check = false;
                          check =
                            ToDate !== ""
                              ? moment(e.target.value).isAfter(ToDate)
                                ? true
                                : false
                              : false;
                          if (check) {
                            if (validDate !== "") {
                              setFDate(moment(validDate).format("YYYY-MM-DD"));
                            }
                          } else {
                            setValidDate(e.target.value);
                          }
                        }
                      }}
                      onClick={(e) => handlepicker(e)}
                      border={
                        ActivityTypeErr || dateError
                          ? " 1px solid red"
                          : "1px solid rgba(34,36,38,.15)"
                      }
                      onKeyPress={(event) => {
                        if (
                          !/[0-9]/.test(event.key) ||
                          event.target.value.length > 9
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyDown={(e) => keyDownExpiry(e)}
                      onResultSelect={dateSelect} // state updation for picking calendar dates
                      showPicker={showpicker}
                      validDate={validDate} // value in date object for calendar manipulation
                      closePicker={closePicker}
                      max={moment(ToDate, "YYYY-MM-DD")}
                    />
                    <Imagecalendar
                      top="6px"
                      right="9px"
                      width="20px"
                      height="22px"
                      id="CustomDatePicker"
                      onClick={(e) => handlepicker(e)}
                      src={calendar_icon_custom}
                    ></Imagecalendar>
                  </Div>
                  {ActivityTypeErr && (
                    <ErrorMessage>Select any date</ErrorMessage>
                  )}
                  {dateError && <ErrorMessage>Select both dates</ErrorMessage>}
                </Div>
                <SpanTag margin="24px 8px 0px 8px">to</SpanTag>
                <Div margin="15px 20px 5px 0">
                  <Div
                    padding="0px 0px"
                    margin="0 0 0 0px"
                    position="relative"
                    className="DatePic_input"
                  >
                    <CustomDatePicker
                      id="CustomDatePicker"
                      width="140px"
                      height="38px"
                      fontFamily="'Open Sans',sans-serif"
                      backgroundColour="#f7f7f7"
                      placeholder="YY-MM-DD"
                      right="0px"
                      value={ToDate} //used in application
                      min={moment(FDate, "YYYY-MM-DD")}
                      onChange={(e) => {
                        let d = moment(
                          e.target.value,
                          "YYYY-MM-DD",
                          true
                        ).isValid();
                        setToDate(e.target.value);

                        if (e.target.value === "") {
                          setValidDate1("");
                        } else if (d) {
                          let check = false;
                          check = FDate
                            ? moment(e.target.value).isBefore(FDate)
                              ? true
                              : false
                            : false;
                          if (check) {
                            if (validDate1 !== "") {
                              setActivityTypeErr(false);
                              setDateError(false);
                              setToDate(moment(validDate).format("YYYY-MM-DD"));
                            }
                          } else {
                            setValidDate1(e.target.value);
                          }
                        }
                      }}
                      onClick={(e) => handlepicker1(e)}
                      border={
                        ActivityTypeErr || dateError
                          ? " 1px solid red"
                          : "1px solid rgba(34,36,38,.15)"
                      }
                      onKeyPress={(event) => {
                        if (
                          !/[0-9]/.test(event.key) ||
                          event.target.value.length > 9
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyDown={(e) => keyDownExpiry(e)}
                      onResultSelect={dateSelect1} // state updation for picking calendar dates
                      showPicker={showpicker1}
                      validDate={validDate1} // value in date object for calendar manipulation
                      closePicker={closePicker1}
                    />
                    <Imagecalendar
                      top="6px"
                      right="9px"
                      width="20px"
                      height="22px"
                      id="CustomDatePicker"
                      onClick={(e) => handlepicker1(e)}
                      src={calendar_icon_custom}
                    ></Imagecalendar>
                  </Div>
                  {ActivityTypeErr && (
                    <ErrorMessage>Select any date</ErrorMessage>
                  )}
                  {dateError && <ErrorMessage>Select both dates</ErrorMessage>}
                </Div>
                {/* </Div> */}
                <ViewButton
                  marginTop="16px"
                  cursor="pointer"
                  // width="120px"
                  textAlign="center"
                  color="#FFFFFF"
                  border="1px solid #2C7BE5"
                  borderRadius="4px"
                  background="#2C7BE5"
                  fontFamily="Open Sans, sans-serif"
                  fontWeight="600"
                  fontSize="14px"
                  padding="8px 25px 8px 27px"
                  height="36px"
                  display="flex"
                  alignItems="center"
                  onClick={handleUpdate}
                >
                  Update
                </ViewButton>
              </ViewDiv>
            )}
            <Div
              border="1px solid #E2E8ED "
              // boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="8px"
              marginTop={source === "visit" ? "11px" : "16px"}
              // height="700px"
            >
              <Div display="flex" padding="20px" alignItems="center">
                <FormLabel
                  fontSize="16px"
                  color="#2E2E2E"
                  fontFamily="'Open Sans', sans-serif"
                  lineHeight="20px"
                  fontWeight="600"
                >
                  Visit History for {SingPatDetails.patname}
                </FormLabel>
              </Div>

              <Div overflowX="auto">
                <Table border="none !important">
                  <TableHead background="#F1F5F8">
                    <Tablerow
                      color="#2C7BE5"
                      fontFamily="Open Sans, sans-serif"
                      fontWeight="600"
                      fontSize="14px"
                      colSpan="5"
                      textAlign="center"
                    >
                      <TableHeadCell paddingLeft="0px" paddingRight="0px">
                        <SpanTag>Encounter</SpanTag>
                      </TableHeadCell>
                      <TableHeadCell paddingLeft="0px" paddingRight="0px">
                        <SpanTag>Encounter ID</SpanTag>
                      </TableHeadCell>
                      <TableHeadCell paddingLeft="0px" paddingRight="0px">
                        <SpanTag>Provider</SpanTag>
                      </TableHeadCell>
                      <TableHeadCell paddingLeft="0px" paddingRight="0px">
                        <SpanTag>Duration</SpanTag>
                      </TableHeadCell>
                      <TableHeadCell paddingLeft="0px" paddingRight="0px">
                        <SpanTag>Action</SpanTag>
                      </TableHeadCell>
                    </Tablerow>
                  </TableHead>
                  <TableBody>
                    {currentPosts.length !== 0 ? (
                      currentPosts.map((details, index) => (
                        <React.Fragment key={`a1${index}`}>
                          <Tablerow
                            color="#2E2E2E"
                            fontFamily="Open Sans, sans-serif"
                            fontWeight="400"
                            fontSize="14px"
                            borderBottom={
                              expandedRows.includes(index)
                                ? "none !important"
                                : "1px solid #E2E8ED"
                            }
                            colSpan="5"
                            textAlign="center"
                          >
                            <TableBodyCell
                              borderBottom="1px solid #E2E8ED"
                              maxWidth="100px"
                              padding="19px 0px 20px 0px"
                            >
                              {details.date_end === null
                                ? details.single_date
                                : details.encounter_date}
                            </TableBodyCell>
                            <TableBodyCell padding="19px 0px 20px 0px">
                              {details.encounter}
                            </TableBodyCell>
                            <TableBodyCell padding="19px 0px 20px 0px">
                              {details.provider}
                            </TableBodyCell>
                            <TableBodyCell padding="19px 0px 20px 0px">
                              {details.duration}
                            </TableBodyCell>
                            <TableBodyCell padding="19px 0px 20px 0px">
                              {
                                <SpanTag
                                  gap="14px"
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <BsPlayCircle
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "#2C7BE5",
                                      rotate: expandedRows.includes(index)
                                        ? "270deg"
                                        : "90deg",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleCollapseShow(index)}
                                  />
                                  {details.diff !== "1" && (
                                    <AiOutlineFileAdd
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#2E2E2E",
                                        cursor:
                                          details.encounter_status === "open" &&
                                          SingPatDetails.enrollment_status !==
                                            "4"
                                            ? "pointer"
                                            : "not-allowed",
                                      }}
                                      onClick={() =>
                                        details.encounter_status === "open" &&
                                        SingPatDetails.enrollment_status !== "4"
                                          ? handleNoteAdd(
                                              details,
                                              details.encounter
                                            )
                                          : ""
                                      }
                                    />
                                  )}
                                  {details.is_locked === 1 ? (
                                    <MdLockOutline
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#2E2E2E",
                                        cursor:
                                          SingPatDetails.enrollment_status !==
                                          "4"
                                            ? "pointer"
                                            : "not-allowed",
                                      }}
                                      onClick={() => handleNoteLock(details)}
                                    />
                                  ) : (
                                    <MdLockOpen
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#2E2E2E",
                                        cursor:
                                          SingPatDetails.enrollment_status !==
                                          "4"
                                            ? "pointer"
                                            : "not-allowed",
                                      }}
                                      onClick={() => handleNoteLock(details)}
                                    />
                                  )}

                                  <FiTrash2
                                    onClick={() =>
                                      feesSheetDelete(
                                        details.pid,
                                        details.encounter,
                                        details.is_locked,
                                        details.encounter_status
                                      )
                                    }
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "#B00020",
                                      cursor:
                                        details.is_locked === 0 &&
                                        SingPatDetails.enrollment_status !==
                                          "4" &&
                                        details.encounter_status === "open"
                                          ? "pointer"
                                          : "not-allowed",
                                      fill: "#ffffff",
                                      stroke: "#f24822",
                                    }}
                                  />
                                </SpanTag>
                              }
                            </TableBodyCell>
                          </Tablerow>

                          {expandedRows.includes(index) ? (
                            <>
                              {details.date_end !== null ? (
                                <Tablerow>
                                  <ViewTableBodyCell
                                    colSpan={
                                      activeState[index]?.changeBillSummary
                                        ? "9"
                                        : "6"
                                    }
                                    borderBottom={
                                      expandedRows.includes(index) &&
                                      "1px solid #E2E8ED"
                                    }
                                  >
                                    <Table
                                      borderTop="1px solid #E2E8ED"
                                      // borderTopLeftRadius="8px"
                                      // borderTopRightRadius="8px"
                                      borderRadius="8px"
                                      className="getlast"
                                    >
                                      <TableHead>
                                        <Tablerow
                                          color="rgba(46, 46, 46, 0.5)"
                                          fontFamily="Open Sans, sans-serif"
                                          fontWeight="600"
                                          fontSize="16px"
                                        >
                                          <TableHeadCell
                                            cursor="pointer"
                                            onClick={() =>
                                              handleNoteClick(index)
                                            }
                                            className={activeState[index]?.note}
                                            paddingLeft="30px !important"
                                            whiteSpace="nowrap"
                                            width="155px"
                                          >
                                            Note Summary
                                          </TableHeadCell>
                                          <TableHeadCell
                                            cursor="pointer"
                                            onClick={() =>
                                              handleBillingClick(index)
                                            }
                                            whiteSpace="nowrap"
                                            className={
                                              activeState[index]?.billing
                                            }
                                          >
                                            Billing Summary
                                          </TableHeadCell>
                                        </Tablerow>
                                        <Tablerow
                                          backgroundColor="#F1F5F8"
                                          color="#2C7BE5"
                                          fontFamily="Open Sans, sans-serif"
                                          fontWeight="600"
                                          fontSize="14px"
                                          className="tbody_padding"
                                          textAlign="center"
                                        >
                                          <TableHeadCell>Date</TableHeadCell>
                                          <TableHeadCell>
                                            Activity
                                          </TableHeadCell>
                                          <TableHeadCell>
                                            {activeState[index]
                                              ?.changeBillSummary
                                              ? "Duration"
                                              : "Duration"}
                                          </TableHeadCell>
                                          <TableHeadCell>
                                            {activeState[index]
                                              ?.changeBillSummary
                                              ? "Code"
                                              : "Provider"}
                                          </TableHeadCell>
                                          <TableHeadCell>
                                            {activeState[index]
                                              ?.changeBillSummary
                                              ? "Charges"
                                              : "Billing"}
                                          </TableHeadCell>

                                          <TableHeadCell
                                            paddingRight={
                                              !activeState[index]
                                                ?.changeBillSummary
                                                ? "20px"
                                                : "0px"
                                            }
                                          >
                                            {activeState[index]
                                              ?.changeBillSummary
                                              ? "Paid"
                                              : "Insurance"}
                                          </TableHeadCell>
                                          {activeState[index]
                                            ?.changeBillSummary && (
                                            <TableHeadCell>
                                              Adjustment
                                            </TableHeadCell>
                                          )}
                                          {activeState[index]
                                            ?.changeBillSummary && (
                                            <TableHeadCell>
                                              Balance
                                            </TableHeadCell>
                                          )}
                                          {activeState[index]
                                            ?.changeBillSummary && (
                                            <TableHeadCell paddingRight="20px">
                                              Insurance
                                            </TableHeadCell>
                                          )}
                                        </Tablerow>
                                      </TableHead>
                                      {!activeState[index]
                                        ?.changeBillSummary ? (
                                        <>
                                          {details["note_summary"].length !==
                                          0 ? (
                                            details["note_summary"].map(
                                              (x, ind) => (
                                                <TableBody key={`2a${ind}`}>
                                                  <Tablerow
                                                    bbtom
                                                    borderBottom="1px solid #E2E8ED"
                                                    color="#2E2E2E"
                                                    fontFamily="Open Sans, sans-serif"
                                                    fontWeight="400"
                                                    fontSize="14px"
                                                    textAlign="center"
                                                    className="collapse_lastChild"
                                                  >
                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.date}
                                                    </TableBodyCell>
                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.code ===
                                                        "CPT4-99454" ||
                                                      x.code === "CPT4-99453"
                                                        ? x.billing_activity
                                                        : x.activity}
                                                    </TableBodyCell>
                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {`${
                                                        x.timespent !== "0" &&
                                                        x.timespent !== null &&
                                                        x.timespent !== ""
                                                          ? x.timespent +
                                                            "   mins"
                                                          : "-"
                                                      }`}
                                                    </TableBodyCell>

                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.provider}
                                                    </TableBodyCell>

                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.code === null
                                                        ? "-"
                                                        : x.code}
                                                    </TableBodyCell>

                                                    <TableBodyCell></TableBodyCell>
                                                  </Tablerow>
                                                </TableBody>
                                              )
                                            )
                                          ) : (
                                            <TableBody>
                                              <TableBodyCell
                                                textAlign="center"
                                                colSpan={6}
                                              >
                                                <Div>
                                                  <Image
                                                    height="133px"
                                                    width="133px"
                                                    src={Emp_data_pic}
                                                  ></Image>
                                                </Div>
                                                <Div
                                                  fontSize="14px"
                                                  color="#2E2E2E"
                                                  fontFamily="'Open Sans',sans-serif"
                                                  lineHeight="20px"
                                                  fontWeight="400"
                                                >
                                                  No notes has been created to
                                                  view it’s summary
                                                </Div>
                                              </TableBodyCell>
                                            </TableBody>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {details["billing_summary"] !==
                                            null &&
                                          details["billing_summary"].length !==
                                            0 ? (
                                            details["billing_summary"].map(
                                              (x, ind) => (
                                                <TableBody key={`3a${ind}`}>
                                                  <Tablerow
                                                    bbtom
                                                    borderBottom="1px solid #E2E8ED"
                                                    color="#2E2E2E"
                                                    fontFamily="Open Sans, sans-serif"
                                                    fontWeight="400"
                                                    textAlign="center"
                                                    fontSize="14px"
                                                    className="collapse_lastChild"
                                                  >
                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.date}
                                                    </TableBodyCell>
                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.code ===
                                                        "CPT4-99454" ||
                                                      x.code === "CPT4-99453"
                                                        ? x.billing_activity
                                                        : x.activity}
                                                    </TableBodyCell>
                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.timespent !== "0" &&
                                                      x.timespent !== null &&
                                                      x.timespent !== ""
                                                        ? x.timespent +
                                                          "   mins"
                                                        : "-"}
                                                    </TableBodyCell>

                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.code !== null
                                                        ? x.code
                                                        : "-"}
                                                    </TableBodyCell>

                                                    <TableBodyCell
                                                      paddingTop="19px"
                                                      paddingBottom="19px"
                                                    >
                                                      {x.fee !== "0.00" &&
                                                      x.fee !== null
                                                        ? x.fee
                                                        : "-"}
                                                    </TableBodyCell>
                                                    <TableBodyCell>
                                                      -
                                                    </TableBodyCell>
                                                    <TableBodyCell>
                                                      -
                                                    </TableBodyCell>
                                                    <TableBodyCell>
                                                      -
                                                    </TableBodyCell>
                                                    <TableBodyCell>
                                                      -
                                                    </TableBodyCell>
                                                  </Tablerow>
                                                </TableBody>
                                              )
                                            )
                                          ) : (
                                            <TableBody>
                                              <TableBodyCell
                                                textAlign="center"
                                                colSpan={8}
                                              >
                                                <Div>
                                                  <Image
                                                    height="133px"
                                                    width="133px"
                                                    src={Emp_data_pic}
                                                  ></Image>

                                                  <Div
                                                    fontSize="14px"
                                                    color="#2E2E2E"
                                                    fontFamily="'Open Sans',sans-serif"
                                                    lineHeight="20px"
                                                    fontWeight="400"
                                                  >
                                                    No Encounter has been
                                                    created to view it’s summary
                                                  </Div>
                                                </Div>
                                              </TableBodyCell>
                                            </TableBody>
                                          )}
                                        </>
                                      )}
                                    </Table>
                                  </ViewTableBodyCell>
                                </Tablerow>
                              ) : (
                                <Tablerow>
                                  <ViewTableBodyCell
                                    colSpan={
                                      activeState[index]?.changeBillSummary
                                        ? "9"
                                        : "6"
                                    }
                                    borderBottom={
                                      expandedRows.includes(index) &&
                                      "1px solid #E2E8ED"
                                    }
                                  >
                                    <Table
                                      borderTop="1px solid #E2E8ED"
                                      borderRadius="8px"
                                      className="getlast"
                                    >
                                      <TableHead>
                                        <Tablerow
                                          color="rgba(46, 46, 46, 0.5)"
                                          fontFamily="Open Sans, sans-serif"
                                          fontWeight="600"
                                          fontSize="16px"
                                        >
                                          <TableHeadCell
                                            cursor="pointer"
                                            onClick={() =>
                                              handleNoteClick(index)
                                            }
                                            className={activeState[index]?.note}
                                            paddingLeft="30px !important"
                                            whiteSpace="nowrap"
                                          >
                                            Summary
                                          </TableHeadCell>
                                        </Tablerow>
                                        <Tablerow
                                          backgroundColor="#F1F5F8"
                                          color="#2C7BE5"
                                          fontFamily="Open Sans, sans-serif"
                                          fontWeight="600"
                                          fontSize="14px"
                                          textAlign="center"
                                        >
                                          <TableHeadCell paddingLeft="0px">
                                            Date
                                          </TableHeadCell>
                                          <TableHeadCell paddingLeft="0px">
                                            Description
                                          </TableHeadCell>
                                          <TableHeadCell
                                            width="20%"
                                            paddingLeft="0px"
                                          >
                                            Action
                                          </TableHeadCell>
                                        </Tablerow>
                                      </TableHead>
                                      {details["note_summary"].length !== 0 ? (
                                        <TableBody>
                                          <Tablerow
                                            bbtom
                                            borderBottom="1px solid #E2E8ED"
                                            color="#2E2E2E"
                                            fontFamily="Open Sans, sans-serif"
                                            fontWeight="400"
                                            fontSize="14px"
                                            textAlign="center"
                                            className="collapse_lastChild"
                                          >
                                            <TableBodyCell
                                              paddingTop="19px"
                                              paddingBottom="19px"
                                              width="194px !important"
                                            >
                                              {details.single_date}
                                            </TableBodyCell>
                                            <TableBodyCell
                                              paddingTop="19px"
                                              paddingBottom="19px"
                                            >
                                              {details.note_summary[0].notes}
                                            </TableBodyCell>
                                            <TableBodyCell
                                              paddingTop="19px"
                                              paddingBottom="19px"
                                            >
                                              <SpanTag
                                                gap="14px"
                                                display="flex"
                                                justifyContent="center"
                                              >
                                                <AiOutlineEdit
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    color: "#2C7BE5",
                                                    cursor:
                                                      SingPatDetails.enrollment_status !==
                                                      "4"
                                                        ? "pointer"
                                                        : "not-allowed",
                                                  }}
                                                  onClick={() =>
                                                    handleEdit(details)
                                                  }
                                                />
                                                <FiTrash2
                                                  onClick={() =>
                                                    handleDelete(
                                                      details.note_id,
                                                      details.eid
                                                    )
                                                  }
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    color: "#B00020",
                                                    cursor:
                                                      SingPatDetails.enrollment_status !==
                                                      "4"
                                                        ? "pointer"
                                                        : "not-allowed",
                                                    fill: "#ffffff",
                                                    stroke: "#f24822",
                                                  }}
                                                />
                                              </SpanTag>
                                            </TableBodyCell>
                                          </Tablerow>
                                        </TableBody>
                                      ) : (
                                        <TableBody>
                                          <TableBodyCell
                                            textAlign="center"
                                            colSpan={6}
                                          >
                                            <Div>
                                              <Image
                                                height="133px"
                                                width="133px"
                                                src={Emp_data_pic}
                                              ></Image>

                                              <Div
                                                fontSize="14px"
                                                color="#2E2E2E"
                                                fontFamily="'Open Sans',sans-serif"
                                                lineHeight="20px"
                                                fontWeight="400"
                                              >
                                                No notes has been created to
                                                view it’s summary
                                              </Div>
                                            </Div>
                                          </TableBodyCell>
                                        </TableBody>
                                      )}
                                    </Table>
                                  </ViewTableBodyCell>
                                </Tablerow>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <Tablerow>
                        <TableBodyCell textAlign="center" colSpan={5}>
                          <Div>
                            <Image
                              height="133px"
                              width="133px"
                              src={Emp_data_pic}
                            ></Image>

                            <Div
                              fontSize="14px"
                              color="#2E2E2E"
                              fontFamily="'Open Sans',sans-serif"
                              lineHeight="20px"
                              fontWeight="400"
                            >
                              No notes has been created to view it’s summary
                            </Div>
                          </Div>
                        </TableBodyCell>
                      </Tablerow>
                    )}
                  </TableBody>
                </Table>
              </Div>
            </Div>
          </ViewModalBody>
        </ModalContainer>
      </Modal>
      {show && (
        <>
          <Notes
            encountDetails={encountDetails}
            show={show}
            close={handleClose}
            clickFrom={"visitEncounter"}
          ></Notes>
        </>
      )}
      {showAlert && (
        <AlertModal show={showAlert} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default ViewDetails;
