import React, { useState } from "react";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  SpanTag,
  Span,
  Image,
} from "../../../StyledComponents";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import { RiEdit2Line } from "react-icons/ri";
import AddProblem from "../../../Popup/AddProblem/AddProblem";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import {
  GetList,
  GetEditList,
  GetDeleteList,
  clearAllergyData,
} from "../../../../StateManagement/Reducers/HealthState";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";

const Problem = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const ProblemLists = useSelector((state) => state.Health.List);
  const problemalert = useSelector((state) => state.Health.HealthAlertMsg);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  const pidvalue = props.pid;
  const prblmvalue = props.value;

  const lockClose = () => {
    setShow(false);
    dispatch(clearAllergyData(""));
  };
  const handleopen = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShow(true);
      setId("0");
      dispatch(clearAllergyData(""));
    }
  };
  const handleopen1 = (id, pid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShow(true);
      setId(id);
      dispatch(GetEditList({ pid: pid, id: id, value: props.value }));
    }
  };
  const handledelete = (id, pid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (window.confirm("Are you sure you want to Delete?")) {
        dispatch(GetDeleteList({ pid: pid, id: id, value: props.value }));
      }
    }
  };
  const Status = (St) => {
    if (St === "0") {
      return "Inactive";
    } else if (St === "1") {
      return "Active";
    } else {
      return "Resolved";
    }
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    if (problemalert !== "") {
      if (problemalert.includes("Deleted")) {
        setModalAlerShow(true);
        setModalMsg("Deleted Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetList({ pid: props.pid, value: props.value }));
      }
      if (problemalert.includes("Inserted")) {
        setModalAlerShow(true);
        setModalMsg("Saved Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetList({ pid: props.pid, value: props.value }));
      }
      if (problemalert.includes("Updated")) {
        setModalAlerShow(true);
        setModalMsg("Updated Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetList({ pid: props.pid, value: props.value }));
      }
    }
  }, [problemalert]);

  useEffect(() => {
    dispatch(GetList({ pid: props.pid, value: props.value }));
    setModalAlerShow(false);
  }, [props.value1]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <Div
      background=" #FFFFFF"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="8px"
      height="100%"
    >
      <Div padding="15px 25px 15px 30px">
        <Div
          borderRadius="8px"
          display="flex"
          alignItems="center"
          fontFamily="'Open Sans',sans-serif"
          fontStyle="normal"
          fontWeight="600"
          fontSize="18px"
          lineHeight="20px"
        >
          <Div> Problems List</Div>

          <Button
            borderRadius="4px"
            border="1px solid #2C7BE5"
            padding="9px 36px 8px 36px"
            color="#FFFFFF"
            marginLeft="auto"
            cursor={
              SingPatDetails.enrollment_status !== "4"
                ? "pointer"
                : "not-allowed"
            }
            backgroundColor="#2C7BE5"
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            onClick={handleopen}
          >
            <Span
              fontSize="14px"
              fontWeight="600"
              fontFamily="'Open Sans', sans-serif"
            >
              Add Problem
            </Span>
          </Button>
        </Div>
      </Div>
      <Div overflowY="auto" maxHeight="350px">
        <Table border="none">
          <TableHead
            background="#F1F5F8"
            hight="56px"
            style={{ position: "sticky", top: "0" }}
            zIndex="1"
          >
            <TableRow
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              color="#2C7BE5"
              colSpan="5"
              paddingTop="18px"
              paddingBottom="18px"
            >
              <TableHeadCell textAlign="center" width="250px !important">
                Problem
              </TableHeadCell>
              <TableHeadCell textAlign="center">Diagnosed Date</TableHeadCell>
              <TableHeadCell textAlign="center">Status</TableHeadCell>
              <TableHeadCell textAlign="center">SNOMED</TableHeadCell>
              <TableHeadCell textAlign="center">Action</TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {ProblemLists.length !== 0 ? (
              ProblemLists.map((x, ind) => (
                <React.Fragment key={ind}>
                  <TableRow
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    color="#000000"
                    lineHeight="19px"
                    borderBottom="1px solid #cbd5e0"
                    paddingTop="16px"
                    paddingBottom="18px"
                    key={ind}
                  >
                    <TableBodyCell
                      textAlign="center"
                      width="250px !important"
                      paddingRight="0px"
                    >
                      {x.diagnosis}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {x.begdate}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {Status(x.activity)}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {x.snoomed_code}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      <SpanTag
                        height="18px"
                        width="18px"
                        color=" #2C7BE5"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                        onClick={() => handleopen1(x.id, x.pid)}
                      >
                        <RiEdit2Line />
                      </SpanTag>
                      <SpanTag
                        height="18px"
                        width="16px"
                        color=" #B00020"
                        marginLeft="31px"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                        onClick={() => handledelete(x.id, x.pid)}
                      >
                        <FiTrash2
                          style={{ fill: "#ffffff", stroke: "#f24822" }}
                        />
                      </SpanTag>
                    </TableBodyCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow height="307px">
                <TableBodyCell textAlign="center" colSpan={5}>
                  <Div>
                    <Image
                      height="150px"
                      width="150px"
                      src={Emp_data_pic}
                    ></Image>
                  </Div>
                  <Div
                    fontSize="14px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans',sans-serif"
                    lineHeight="20px"
                    fontWeight="400"
                  >
                    No problems to be shown,Please add some.
                  </Div>
                </TableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Div>

      <AddProblem
        pidvalue={pidvalue}
        prblmvalue={prblmvalue}
        show={show}
        id={id}
        close={lockClose}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Div>
  );
};

export default Problem;
