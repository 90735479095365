import styled from "styled-components";
import { MdCall } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { GrAttachment } from "react-icons/gr";
import { AiOutlineSend } from "react-icons/ai";
import { ModalContainer } from "../../StyledComponents";

export const Call = styled(MdCall)`
  color: #2c7be5;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 92px;
  top: 26px;
  cursor: pointer;
`;

export const Video = styled(FaVideo)`
  color: #2c7be5;
  position: absolute;
  width: 20px !important;
  height: 16px !important;
  top: 29px;
  right: 44px;
  cursor: pointer;
`;
export const At = styled(HiOutlineAtSymbol)`
  color: #2e2e2e;
  width: 21px !important;
  height: 23px !important;
  margin-right: 22px;
  cursor: pointer;
`;
export const Attach = styled(GrAttachment)`
  color: #2e2e2e;
  width: 20px !important;
  height: 20px !important;
  margin-right: 24px;
  cursor: pointer;
`;

export const Send = styled(AiOutlineSend)`
  color: #ffffff;
  width: 20px !important;
  height: 20px !important;
  margin-left: 3px !important;
`;

export const ChatBoxMsg = styled.div`
  background: ${({ user, day }) =>
    day ? "#ffff" : user ? "#407BFF" : "rgb(196,196,196) "};
  max-width: 391px;
  color: ${({ user, day }) =>
    day ? "#BAC0C6" : user ? " #FFFFFF" : "#000000"};
  border-radius: ${({ type }) => (type === "date" ? "4px" : "8px")};
  padding: ${({ type, padding }) =>
    padding !== undefined ? padding : type === "date" ? "7px 20px" : "4px 8px"};
  padding: ${({ type }) => type === "image" && "8px 10px 7px 10px"};

  margin-right: ${({ user }) => user && "12px"};
  display: flex;
  border: ${({ day }) => day && "none"};
  position: relative;
  min-height: 20px;

  ${({ user }) =>
    user &&
    `
&::after {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-left-color: #407BFF;
  border-right: 0;
  right: -6px;
  top: 26px;
  margin-top: -19px;
}
`};
  ${({ user, type }) =>
    !user &&
    type !== "date" &&
    `
&::before {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-right-color:  rgb(196, 196, 196);
  border-left: 0;
  left: 11px;
  top: 1px;
  margin-left: -16px;
  
}
`};
`;

export const ModalContainerChat = styled(ModalContainer)`
  @media screen and (max-width: 820px) {
    width: unset;
  }
`;
