import axiosInst from "../../Config";

export const AddEncounterApi = async (data) =>
  await axiosInst.post(`/vozorpm/AddEncounter/Insert`, data);

export const EditEncounterApi = async (data) =>
  await axiosInst.post(`/vozorpm/AddEncounter/update`, data);

export const GetEncounterOptions_Api = async (credential) =>
  await axiosInst.get("/vozorpm/VisitEncounter/Options?pid=" + credential);

export const GetVisitEncountersApi = async (credential) =>
  await axiosInst.get("/vozorpm/visitencounter?pid=" + credential);

export const GetEncounterSummary_Api = async (credential) =>
  await axiosInst.get("/vozorpm/encountersummary?eid=" + credential);

export const LockApi = async (data) =>
  await axiosInst.post(`/vozorpm/encounter/lock`, data);
export const encounterViewDetails = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/encounter/list`, credential);
export const FeeSheetViewDetails = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/FeeSheet/list`, credential);
export const AddNotesTake_Api = async (data) =>
  await axiosInst.post(`/vozorpm/rpm_notes/insert`, data);
export const InsNoteId_Api = async (data) =>
  await axiosInst.post(`/vozorpm/rpm_notes/insert/newid`, data);

export const GetRpmNotes_Api = async (data) =>
  await axiosInst.get(`/vozorpm/rpm_notes/get?` + data.type + `=` + data.id);

export const DeleteRpmSingleNotes_Api = async (data) =>
  await axiosInst.get(`/vozorpm/rpm_notes/singledelete?id=` + data.id);
export const exportCSV_Api = async (data) =>
  await axiosInst.get(
    `/vozorpm/feesheet/exportCsv?pid=` + data.pid + `&source=` + data.source
  );

export const GetRpmGmeetData_Api = async (data) =>
  await axiosInst.get(
    `/vozorpm/gmeet_data/get?uid=` + data.uid + `&pid=` + data.pid
  );
export const ChartviewIdApi = async (credential) =>
  await axiosInst.get("/vozorpm/patient/chartview?pid=" + credential);
