import styled from "styled-components";
import { BiArrowBack } from "react-icons/bi";

export const BodyDiv = styled.div`
  width: 379px;
  max-height: 418px;
  margin-bottom: 22px;
  background: #ffffff;
  border: 1px solid #cbd5e0;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15);
  border-radius: 8px;
  margin-top: 8px;
  margin-left: 8px;
  overflow: auto;
`;

export const ChatBody = styled(BodyDiv)`
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const ChatBox = styled.div`
  align-self: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  justify-content: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  margin-bottom: 18px;
  max-width: ${({ type }) =>
    type === "date" ? "100%" : type === "text" ? "100%" : "fit-content"};
  text-align: ${({ type }) => (type === "date" ? "center" : "left")};
  display: flex;
  flex-direction: row;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;

export const ChatBoxMsg = styled.div`
  background: ${({ user, day }) =>
    day ? "#ffff" : user ? "#2C7BE5" : " #C4C4C4"};
  max-width: ${({ user }) => user && "93%"};
  color: ${({ user, day }) =>
    day
      ? day === "Today"
        ? "#2E2E2E"
        : "#BAC0C6"
      : user
      ? " #FFFFFF"
      : "#000000"};
  border-radius: ${({ type }) => (type === "date" ? "90px" : "4px")};
  padding: ${({ type, padding }) =>
    padding !== undefined ? padding : type === "date" ? "4px 16px" : "4px 8px"};
  margin-right: ${({ user }) => user && "12px"};
  display: flex;
  border: ${({ day }) => day && "1px solid rgba(46, 46, 46, 0.25)"};
  position: relative;
  min-height: 13px;
  padding: 8px;

  ${({ user }) =>
    user &&
    `
&::after {
  content: " ";
  border: 10px solid transparent;
  position: absolute;
  border-left-color: #2C7BE5;
  border-right: 0;
  right: -6px;
  top: 26px;
  margin-top: -19px;
}
`};
  ${({ user, type }) =>
    !user &&
    type !== "date" &&
    `
&::before {
  content: " ";
  border: 10px solid transparent;
  position: absolute;
  border-right-color: #C4C4C4;
  border-left: 0;
  left: 9px;
  top: 9px;
  margin-left: -16px;
}
`};
`;

export const ArrowBack = styled(BiArrowBack)`
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 0px;
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;
