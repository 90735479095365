import { createSlice } from "@reduxjs/toolkit";

export const SettingSlice = createSlice({
  name: "Setting",
  initialState: {
    SecurityData: [],
    SecurityUpdateResponse: "",
    DocumentMailResp: [],
    SettingDocResp: [],
    UpdateSetDocResp: "",
    UpdateDocMailResp: "",
    userReport: [],
    ProviderList: [],
    Roles: [],
    SaveProviders: "",
    SingleProvider: {},
    EmptyList: "",
    UpdateProviders: "",
    UserResponse: "",
    UpdateUserResponse: "",
    SingleProviderResponse: [],
    SecurtiyData: "",
    DeleteUser: "",
    CheckUserEmail: "",
    CheckProvName: "",
    CheckHomePhone: "",
    CheckCellPhone: "",
    ServiceList: [],
    GetServiceInsertList: [],
    ServiceOneList: {},
    Refresh: "",
    ServiceAlertMsg: "",
    Currency: localStorage.getItem("currency_symbol")
      ? localStorage.getItem("currency_symbol")
      : "$",

    CodeVerify: "",
    profileDetail: {},
    clinicalDetail: {},
    AddProfileResponse: "",
    MailVerify: "",
    PhoneVerify: "",
    ProviderRolesList: [],
    InsertRole: "",
    RolesEdit: [],
    EditRoleInsert: "",
    ProvWithRole: [],
    EditProvRoles: [],
    Deleterole: "",
    rolenoofpages: 0,
    noofpagesProvRoleList: 0,
    CountryCode: [],
  },
  reducers: {
    AddProfileData: (data) => {
      return data;
    },

    setAddProfileDetailRes: (state, action) => {
      state.AddProfileResponse = action.payload;
    },
    setEmptyAddProfileResponse: (state) => {
      return state;
    },

    AddClinicalData: (data) => {
      return data;
    },

    GetProfileDetails: (data) => {
      return data;
    },
    SetProfileDetails: (state, action) => {
      state.profileDetail = action.payload;
    },

    GetClinicalDetails: (data) => {
      return data;
    },
    SetClinicalDetails: (state, action) => {
      state.clinicalDetail = action.payload;
    },
    userImageUpload: (data) => {
      return data;
    },
    GetSecurityData: (state) => {
      return state;
    },
    SetSecurityData: (state, action) => {
      state.SecurityData = action.payload;
    },
    setEmptySecurityResponse: (state) => {
      return state;
    },
    SetSecurityUpdate: (state, action) => {
      state.SecurityUpdateResponse = action.payload;
    },
    GetSecurityUpdate: (state) => {
      return state;
    },

    GetDocumentMailRes: (state) => {
      return state;
    },
    SetDocumentMailRes: (state, action) => {
      state.DocumentMailResp = action.payload;
    },
    GetSettingDocRes: (state) => {
      return state;
    },
    SetSettingDocRes: (state, action) => {
      state.SettingDocResp = action.payload;
    },
    GetUpdateSetDocRes: (state) => {
      return state;
    },
    SetUpdateSetDocRes: (state, action) => {
      state.UpdateSetDocResp = action.payload;
    },
    EmptyUpdateSetDocRes: (state, action) => {
      return state;
    },
    GetUpdateDocMailRes: (state, action) => {
      return state;
    },
    SetUpdateDocMailRes: (state, action) => {
      state.UpdateDocMailResp = action.payload;
    },
    GetUserList: (state) => {
      state.Loading = true;
    },

    SetUserList: (state, action) => {
      state.userReport = action.payload;
    },

    GetProviderList: (state) => {
      return state;
    },

    SetProviderList: (state, action) => {
      state.ProviderList = action.payload;
    },

    GetRoles: (state) => {
      return state;
    },

    SetRoles: (state, action) => {
      state.Roles = action.payload;
    },

    GetSaveProv: (state) => {
      return state;
    },

    GetSingProv: (state) => {
      return state;
    },
    SetSingProv: (state, action) => {
      state.SingleProvider = action.payload;
    },

    GetEmppty: (state) => {
      return state;
    },

    GetUpdateProv: (state) => {
      return state;
    },

    SetEmptyUserListResponse: (state) => {
      return state;
    },
    SetUserListResponse: (state, action) => {
      state.UserResponse = action.payload;
    },

    SetEmptyUpdateResponse: (state) => {
      return state;
    },
    SetUpdateUserListResponse: (state, action) => {
      state.UpdateUserResponse = action.payload;
    },

    setSingleProviderResponse: (state) => {
      state.SingleProvider = state;
    },

    GetSettingSecurity: (state) => {
      return state;
    },

    SetSettingSecurity: (state, action) => {
      state.SecurtiyData = action.payload;
    },

    GetUserDelete: (state) => {
      return state;
    },

    SetUserDelete: (state, action) => {
      state.DeleteUser = action.payload;
    },
    GetAllServiceList: (state) => {
      return state;
    },

    SetAllServiceList: (state, action) => {
      state.ServiceList = action.payload;
    },
    GetServiceDeleteList: (state) => {
      return state;
    },
    GetServiceInsertList: (state) => {
      return state;
    },
    GetOneServiceList: (state) => {
      return state;
    },
    SetOneServiceList: (state, action) => {
      state.ServiceOneList = action.payload;
    },
    GetServiceUpdateList: (state) => {
      return state;
    },
    GetCurrency: (state) => {
      state.Refresh = "";
    },

    SetCurrency: (state, action) => {
      state.Currency = action.payload;
    },
    setServiceAlert: (state, action) => {
      state.ServiceAlertMsg = action.payload;
    },
    setEmptyServiceResponse: (state) => {
      state.ServiceOneList = state;
    },
    setEmptyCodeMatchResponse: (state) => {
      state.CodeVerify = state;
    },
    GetCodeVerify: (state) => {
      return state;
    },

    SetCodeVerify: (state, action) => {
      state.CodeVerify = action.payload;
    },
    GetCheckUser: (state) => {
      state.Loading = true;
    },
    SetCheckUser: (state, action) => {
      state.CheckProvName = action.payload;
    },
    EmptyingCheckUser: (state) => {
      state.CheckProvName = state;
    },

    // ==========================
    GetCheckEmail: (state) => {
      state.Loading = true;
    },
    SetCheckEmail: (state, action) => {
      state.CheckUserEmail = action.payload;
    },
    EmptyingCheckEmail: (state) => {
      state.CheckUserEmail = state;
    },

    // ===================================
    GetCheckPhone: (state) => {
      state.Loading = true;
    },
    SetCheckPhone: (state, action) => {
      state.CheckHomePhone = action.payload;
    },
    EmptyingCheckPhone: (state) => {
      state.CheckHomePhone = state;
    },
    // =====================================

    GetCheckCell: (state) => {
      state.Loading = true;
    },
    SetCheckCell: (state, action) => {
      state.CheckCellPhone = action.payload;
    },
    EmptyingCheckCell: (state) => {
      state.CheckCellPhone = state;
    },
    GetCheckMailVerify: (state) => {
      return state;
    },
    SetCheckMailVerify: (state, action) => {
      state.MailVerify = action.payload;
    },
    GetCheckPhoneVerify: (state) => {
      return state;
    },
    SetCheckPhoneVerify: (state, action) => {
      state.PhoneVerify = action.payload;
    },

    GetProviderRolesList: (state) => {
      return state;
    },
    SetProviderRolesList: (state, action) => {
      state.ProviderRolesList = action.payload;
    },

    GetCustomizeRole: (state) => {
      return state;
    },
    GetInsertRole: (state) => {
      return state;
    },
    SetInsertRole: (state, action) => {
      state.InsertRole = action.payload;
    },
    GetRolesEdit: (state) => {
      return state;
    },
    SetRolesEdit: (state, action) => {
      state.RolesEdit = action.payload;
    },
    GetGroupId: (state) => {
      return state;
    },
    SetGroupId: (state, action) => {
      state.GroupId = action.payload;
    },
    GetEditRoleInsert: (state) => {
      return state;
    },
    SetEditRoleInsert: (state, action) => {
      state.EditRoleInsert = action.payload;
    },

    GetProvWithRole: (state) => {
      return state;
    },
    SetProvWithRole: (state, action) => {
      state.ProvWithRole = action.payload;
    },

    GetViewProvRole: (state) => {
      return state;
    },

    SetEditProvRoles: (state, action) => {
      state.EditProvRoles = action.payload;
    },
    GetProvSaveRole: (state) => {
      return state;
    },
    GetRoleDelete: (state) => {
      return state;
    },
    SetRoleDelete: (state, action) => {
      state.Deleterole = action.payload;
    },
    SetNoOfPages: (state, action) => {
      state.rolenoofpages = action.payload;
    },
    SetNoOfPagesProvRoleList: (state, action) => {
      state.noofpagesProvRoleList = action.payload;
    },

    GetCountryCode: (state) => {
      return state;
    },
    SetCountryCode: (state, action) => {
      state.CountryCode = action.payload;
    },
  },
});

export const {
  AddProfileData,
  setAddProfileDetailRes,
  setEmptyAddProfileResponse,
  GetProfileDetails,
  SetProfileDetails,
  GetClinicalDetails,
  SetClinicalDetails,
  AddClinicalData,
  userImageUpload,
  GetSecurityData,
  SetSecurityData,
  GetSecurityUpdate,
  SetSecurityUpdate,
  setEmptySecurityResponse,
  GetDocumentMailRes,
  SetDocumentMailRes,
  GetSettingDocRes,
  SetSettingDocRes,
  GetUpdateSetDocRes,
  SetUpdateSetDocRes,
  EmptyUpdateSetDocRes,
  GetUpdateDocMailRes,
  SetUpdateDocMailRes,
  GetUserList,
  SetUserList,
  GetProviderList,
  SetProviderList,
  GetRoles,
  SetRoles,
  GetSaveProv,
  GetSingProv,
  SetSingProv,
  GetEmppty,
  SetEmppty,
  GetUpdateProv,
  SetEmptyUserListResponse,
  SetUserListResponse,
  setSingleProviderResponse,
  GetSettingSecurity,
  SetSettingSecurity,
  GetUserDelete,
  SetUserDelete,
  SetEmptyUpdateResponse,
  SetUpdateUserListResponse,
  GetAllServiceList,
  SetAllServiceList,
  GetServiceDeleteList,
  setServiceAlert,
  GetServiceInsertList,
  GetOneServiceList,
  GetServiceUpdateList,
  SetOneServiceList,
  GetCurrency,
  SetCurrency,
  setEmptyServiceResponse,
  GetCodeVerify,
  SetCodeVerify,
  setEmptyCodeMatchResponse,
  SetCheckUser,
  GetCheckUser,
  GetCheckEmail,
  SetCheckEmail,
  GetCheckPhone,
  SetCheckPhone,
  GetCheckCell,
  SetCheckCell,
  EmptyingCheckUser,
  EmptyingCheckEmail,
  EmptyingCheckPhone,
  EmptyingCheckCell,
  GetCheckMailVerify,
  SetCheckMailVerify,
  GetCheckPhoneVerify,
  SetCheckPhoneVerify,
  GetProviderRolesList,
  SetProviderRolesList,
  GetInsertRole,
  SetInsertRole,
  GetRolesEdit,
  SetRolesEdit,
  GetGroupId,
  SetGroupId,
  GetEditRoleInsert,
  SetEditRoleInsert,
  GetProvWithRole,
  SetProvWithRole,
  GetCustomizeRole,
  GetViewProvRole,
  SetEditProvRoles,
  GetProvSaveRole,
  GetRoleDelete,
  SetRoleDelete,
  SetNoOfPages,
  SetNoOfPagesProvRoleList,
  GetCountryCode,
  SetCountryCode,
} = SettingSlice.actions;

export default SettingSlice.reducer;
