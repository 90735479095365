import React, { memo } from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import PatientDemoHead from "../PatientDemo/PatientDemoHead";
import PatientDemographics from "../PatientDemo/PatientDemographics";
import VisitEncounter from "./VisitEncounter";
import AccessDenied from "../../AccessDenied";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
const VisitEncounterIndex = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#F5F5F5">
      <TopNav />
      <LeftNav />

      <Div marginLeft="56px" className="responsive-right">
        <Div background="#F5F5F5" padding="20px">
          {permissions.includes("encounter_visit") ? (
            <>
              <Div
                padding="20px"
                backgroundColor="#FFFFFF"
                marginBottom="20px"
                borderRadius="8px"
              >
                <PatientDemoHead PatinetInfo="Patient Info" />
                <PatientDemographics />
              </Div>
              <Div
                backgroundColor="#FFFFFF"
                border="1px solid #E2E8ED"
                borderRadius="8px"
              >
                <VisitEncounter />
              </Div>
            </>
          ) : (
            <AccessDenied />
          )}
        </Div>
      </Div>
    </Container>
  );
};
export default memo(VisitEncounterIndex);
