import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetSinglePatDetail } from "../../StateManagement/Reducers/PatientState";
import Emp_data_pic from "../../Assets/images/No_Data_Img.png";
import {
  Button,
  Div,
  Table,
  TableHead,
  Tablerow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  SpanTag,
  Image,
  Span,
  PageSubTitle,
} from "../StyledComponents";
import { Arrow } from "./Styles";

const MyPatients = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientList = useSelector((state) => state.Dashboard.chartData);
  const [patients, setPatients] = useState([]);
  const EmptyRowStyle = {
    height: "42px",
  };
  const emptyRows = Math.max(0, 5 - patients.length);

  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      patname: "",
      patid: "",
      DOB: "",
      phone_cell: "",
      Program: "",
      e_status: "",
    })
  );
  const rowsToShow = [...patients, ...emptyRowPlaceholders];
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const openEnrollment = () => {
    navigate(`/PatientEnrollment/${"0"}`);
    dispatch(SetSinglePatDetail({}));
  };
  const NavigateToPatients = () => {
    localStorage.setItem("fromNavigation", "true");
    navigate(`/patient`);
  };
  const handleButtonClick = (pid, status) => {
    switch (status) {
      case "New":
        navigate(`/PatientEligibility/${pid}`);
        break;
      case "Verification Pending":
        navigate(`/PatientConsent/${pid}`);
        break;
      case "Enrolled":
        navigate(`/PatientDemo/${pid}`);
        break;
      case "Newportal":
        navigate(`/PatientEnrollment/${pid}`);
        break;
      case "Unenrolled":
        navigate(`/PatientDemo/${pid}`);
        break;
      default:
        break;
    }
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    if (patientList) {
      setPatients(patientList.patients ?? []);
    }
  }, [patientList]);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //
  return (
    <>
      <Div
        gridArea="MyPatients"
        width="100%"
        borderRadius="8px"
        border="1px solid rgba(46, 46, 46, 0.25)"
        backgroundColor="#fff"
      >
        <Div
          display="flex"
          padding="11px 18px 10px 18px"
          fontWeight="600"
          fontSize="18px"
          alignItems="center"
        >
          <PageSubTitle
            color="#000"
            fontSize="18px"
            fontWeight="600"
            lineHeight="normal"
          >
            My Patients
          </PageSubTitle>

          <Button
            cursor="pointer"
            marginLeft="auto"
            padding="6px 16px"
            border="1px solid #2C7BE5"
            borderRadius="4px"
            color="#FFFFFF"
            background="#2C7BE5"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14px"
            onClick={openEnrollment}
            height="32px"
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Add Patient
          </Button>
          <Button
            border="none"
            fontWeight="400"
            fontSize="14px"
            color=" #2C7BE5"
            lineHeight="normal"
            cursor="pointer"
            onClick={NavigateToPatients}
            display="flex"
            alignItems="center"
            paddingLeft="20px"
            backgroundColor="transparent"
          >
            <Span> View All</Span>
            <Arrow cursor="pointer"></Arrow>
          </Button>
        </Div>
        <Div
          backgroundColor="#FFFFFF"
          borderRadius="8px"
          borderTopLeftRadius="0px"
          borderTopRightRadius="0px"
          overflowX="auto"
        >
          <Table border="none" className="table_last_child">
            <TableHead background="#F1F5F8">
              <Tablerow
                color="#2C7BE5"
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                fontSize="14px"
                paddingTop="13px"
                paddingBottom="13px"
              >
                <TableHeadCell paddingLeft="18px">Patient Name</TableHeadCell>
                <TableHeadCell paddingLeft="5px">Patient ID</TableHeadCell>
                <TableHeadCell paddingLeft="5px">DOB</TableHeadCell>
                <TableHeadCell paddingLeft="5px">Phone Number</TableHeadCell>
                <TableHeadCell paddingLeft="5px">Email</TableHeadCell>
                <TableHeadCell paddingLeft="2px">Program</TableHeadCell>
                <TableHeadCell paddingLeft="2px">
                  Enrollment Status
                </TableHeadCell>
              </Tablerow>
            </TableHead>
            <TableBody>
              {patients.length !== 0 ? (
                rowsToShow.map((details, index) => (
                  <Tablerow
                    borderBottom="1px solid #E2E8ED"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    isHovered={true}
                    key={index}
                  >
                    <TableBodyCell
                      color="#2C7BE5"
                      onClick={() =>
                        handleButtonClick(details.pid, details.e_status)
                      }
                      padding="16px 2px 16px 18px"
                    >
                      {details.patname}
                    </TableBodyCell>
                    <TableBodyCell padding="16px 2px 16px 5px">
                      {details.patid}
                    </TableBodyCell>
                    <TableBodyCell padding="16px 2px 16px 5px">
                      {details.DOB}
                    </TableBodyCell>
                    <TableBodyCell padding="16px 2px 16px 5px">
                      {details.phone_cell}
                    </TableBodyCell>
                    <TableBodyCell
                      padding="16px 2px 16px 2px"
                      wordWrap="break-word"
                      className="maxWidthChange"
                    >
                      {details.email}
                    </TableBodyCell>
                    <TableBodyCell
                      padding="16px 2px 16px 2px"
                      style={details.Program ? null : EmptyRowStyle}
                    >
                      {details.Program}
                    </TableBodyCell>
                    <TableBodyCell padding="16px 2px 16px 2px">
                      <SpanTag
                        color={
                          details.e_status === "Unenrolled"
                            ? "rgba(46, 46, 46, 0.75)"
                            : details.e_status === "Enrolled"
                            ? "#198C55"
                            : details.e_status === "Verification Pending"
                            ? "#FFC700"
                            : details.e_status === "New" ||
                              details.e_status === "Newportal"
                            ? "#F24822"
                            : "-"
                        }
                      >
                        {details.e_status === "Newportal"
                          ? "New"
                          : details.e_status}
                      </SpanTag>
                    </TableBodyCell>
                  </Tablerow>
                ))
              ) : (
                <>
                  <Tablerow>
                    <TableBodyCell textAlign="center" colSpan="8">
                      <Div>
                        <Image
                          height="133px"
                          width="133px"
                          src={Emp_data_pic}
                        ></Image>
                      </Div>
                    </TableBodyCell>
                  </Tablerow>
                  <Tablerow>
                    <TableBodyCell
                      textAlign="center"
                      colSpan="8"
                      paddingBottom="40px"
                    >
                      No patients has been added to show in the patient list
                    </TableBodyCell>
                  </Tablerow>
                </>
              )}
            </TableBody>
          </Table>
        </Div>
      </Div>
    </>
  );
};

export default MyPatients;
