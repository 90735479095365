import React from "react";
import {
  Button,
  Div,
  Image,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PageSubTitle,
  Text,
} from "../../StyledComponents";
import { BsXLg } from "react-icons/bs";
import clapIcon from "../../../Assets/images/Vector.png";
import devices from "../../../Assets/images/devices.png";
import treatPlan from "../../../Assets/images/treate_plan.png";
import { MdOutlineVideocam } from "react-icons/md";
import { TbHeartRateMonitor } from "react-icons/tb";
import { DivPop } from "./styles";
import { useDispatch } from "react-redux";
import { GetInitial } from "../../../StateManagement/Reducers/DashboardState";
const Welcome = (props) => {
  const dispatch = useDispatch();
  let DBHost = "";

  let firstDot = window.location.hostname.split(".");

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }
  const handleModalClick = () => {
    dispatch(GetInitial({ site: DBHost }));
    props.onHide();
  };
  return (
    <Div>
      <ModalHeader padding="0 0 19px 0" justifyContent="center" borderLess>
        <ModalTitle
          color="#2C7BE5"
          fontWeight="600"
          fontSize="24px"
          fontFamily="'Open Sans', sans-serif"
          marginRight="8px"
        >
          Welcome to Vozo!
        </ModalTitle>
        <Image src={clapIcon} alt="clapIcon"></Image>
      </ModalHeader>
      <ModalBody>
        <Div flexCenterAll>
          <Div borderRight="1px solid rgba(46, 46, 46, 0.25)">
            <DivPop textAlign="center" margin="0 10px 24px 0">
              <MdOutlineVideocam size={24} color="#2C7BE5" />
              <PageSubTitle marginBottom="8px" fontSize="14px" color="#2E2E2E">
                Telehealth
              </PageSubTitle>
              <Text
                color="rgba(46, 46, 46, 0.75)"
                width="314px"
                fontSize="12px"
              >
                Engage with your clients from anywhere with secure HIPAA
                compliant video encounters.
              </Text>
            </DivPop>

            <Div textAlign="center" margin="0 0 24px 0">
              <Image src={treatPlan} alt="treatementplan" />
              <PageSubTitle
                marginBottom="8px"
                marginTop="4px"
                fontSize="14px"
                color="#2E2E2E"
              >
                Treatment Plan
              </PageSubTitle>
              <Text
                color="rgba(46, 46, 46, 0.75)"
                width="314px"
                fontSize="12px"
              >
                Make a tailored care plan for your clients meeting their needs
                and goals for their recovery.
              </Text>
            </Div>

            <Div textAlign="center">
              <Image src={treatPlan} alt="treatementplan" />
              <PageSubTitle
                marginBottom="8px"
                marginTop="4px"
                fontSize="14px"
                color="#2E2E2E"
              >
                Communication
              </PageSubTitle>
              <Text
                color="rgba(46, 46, 46, 0.75)"
                width="314px"
                fontSize="12px"
              >
                Make contact with clients for timely exchange of patient data
                and for treatment updates
              </Text>
            </Div>
          </Div>

          <Div margin="0 0 0 20px">
            <Div textAlign="center" margin="0 0 24px 0">
              <TbHeartRateMonitor size={24} color="#2C7BE5" />
              <PageSubTitle
                marginBottom="8px"
                marginTop="4px"
                fontSize="14px"
                color="#2E2E2E"
              >
                Live
              </PageSubTitle>
              <Text
                color="rgba(46, 46, 46, 0.75)"
                width="314px"
                fontSize="12px"
              >
                View all your clients vitals reading data anytime, anywhere with
                ease in a single screen.
              </Text>
            </Div>
            <Div textAlign="center" margin="0 0 24px 0">
              <Image src={devices} alt="devices" />
              <PageSubTitle
                marginBottom="8px"
                marginTop="4px"
                fontSize="14px"
                color="#2E2E2E"
              >
                Devices
              </PageSubTitle>
              <Text
                color="rgba(46, 46, 46, 0.75)"
                width="314px"
                fontSize="12px"
              >
                Assign RPM devices to collect and transmit patient health data
                remotely for continuous monitoring.
              </Text>
            </Div>
            <Div textAlign="center">
              <Image src={devices} alt="devices" />
              <PageSubTitle
                marginBottom="8px"
                marginTop="4px"
                fontSize="14px"
                color="#2E2E2E"
              >
                Billing
              </PageSubTitle>
              <Text
                color="rgba(46, 46, 46, 0.75)"
                width="314px"
                fontSize="12px"
              >
                Get paid on time with accurate seamless reimbursement process
              </Text>
            </Div>
          </Div>
        </Div>
      </ModalBody>
      <ModalFooter
        padding="40px 0 0 0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        noBorderTop
      >
        <Button
          background="#2c7be5"
          border="none"
          borderRadius="4px"
          color="#fff"
          padding="10px 33px"
          cursor="pointer"
          fontWeight="600"
          fontSize="12px"
          onClick={handleModalClick}
        >
          Lets Get Started
        </Button>
      </ModalFooter>
    </Div>
  );
};

export default Welcome;
