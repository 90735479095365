import { takeLatest } from "redux-saga/effects";
import {
  AddEncounterInsert,
  EncounterIdWorker,
  EncounterOptionsApi,
  EncounterSummaryApi,
  VisitEncounterWorker,
  setEnconterEmptyResponse,
  LockSet,
  encounterViewDetailsWorker,
  FeeSheetViewDetailsWorker,
  AddNotesTakeApi,
  GetRpmNotesApi,
  GetRpmSingleNoteApi,
  GetRpmGmeetDataApi,
  RpmSingleDeleteApi,
  setAddNoteEmpty,
  setDelNoteEmpty,
  setSingleEmpty,
  ExportCSVFile,
  EditEncounterUpdate,
  InsertNotesId,
  Chartviewworker,
  setchartviewEmpty,
} from "./EncounterSagaWorkers";
import {
  GetAddEncounterInsert,
  GetEncounterId,
  GetEncounterOpitons,
  GetEncounterSummary,
  GetVisitEncounterList,
  setEmptyEncounterdResponse,
  setIsLocked,
  getEncounterDetails,
  getFeeSheetDetails,
  GetAddNotesTake,
  GetRpmNotes,
  GetRpmSingleNote,
  GetRpmGmeetData,
  RpmSingleDelete,
  setEmptyAddNoteResponse,
  setEmptyDelNoteResponse,
  setCSVData,
  EditEncounterId,
  GetNotesId,
  SetEmptySingle,
  GetChartviewId,
  SetChartviewIdempty,
} from "../../StateManagement/Reducers/EncounterState";

export function* AddEncounterSaga() {
  yield takeLatest(GetAddEncounterInsert, AddEncounterInsert);
}
export function* EditEncounterSaga() {
  yield takeLatest(EditEncounterId.type, EditEncounterUpdate);
}

export function* EncounterEmptyingResponseSaga() {
  yield takeLatest(setEmptyEncounterdResponse, setEnconterEmptyResponse);
}

export function* EncounterOptionsSaga() {
  yield takeLatest(GetEncounterOpitons, EncounterOptionsApi);
}

export function* VisitEncounterSaga() {
  yield takeLatest(GetVisitEncounterList, VisitEncounterWorker);
}
export function* EncounterSummarySaga() {
  yield takeLatest(GetEncounterSummary.type, EncounterSummaryApi);
}

export function* EncounterIdSaga() {
  yield takeLatest(GetEncounterId.type, EncounterIdWorker);
}
export function* EncounterLockSaga() {
  yield takeLatest(setIsLocked.type, LockSet);
}
export function* EncounterViewDetails() {
  yield takeLatest(getEncounterDetails.type, encounterViewDetailsWorker);
}
export function* FeeSheetViewDetails() {
  yield takeLatest(getFeeSheetDetails.type, FeeSheetViewDetailsWorker);
}
export function* GetAddNotes() {
  yield takeLatest(GetAddNotesTake.type, AddNotesTakeApi);
}
export function* GetAddNoteId() {
  yield takeLatest(GetNotesId.type, InsertNotesId);
}
export function* GetRpmNotesSaga() {
  yield takeLatest(GetRpmNotes.type, GetRpmNotesApi);
}

export function* GetRpmSingleNoteSaga() {
  yield takeLatest(GetRpmSingleNote.type, GetRpmSingleNoteApi);
}
export function* GetRpmGmeetDataSaga() {
  yield takeLatest(GetRpmGmeetData.type, GetRpmGmeetDataApi);
}

export function* RpmSingleNoteDeleteSaga() {
  yield takeLatest(RpmSingleDelete.type, RpmSingleDeleteApi);
}

export function* EmptyAddNotesSaga() {
  yield takeLatest(setEmptyAddNoteResponse.type, setAddNoteEmpty);
}

export function* EmptyDelNotesSaga() {
  yield takeLatest(setEmptyDelNoteResponse.type, setDelNoteEmpty);
}
export function* ExportCSVSaga() {
  yield takeLatest(setCSVData.type, ExportCSVFile);
}

export function* EmptySingleEncount() {
  yield takeLatest(SetEmptySingle.type, setSingleEmpty);
}

export function* ChartviewIdSaga() {
  yield takeLatest(GetChartviewId.type, Chartviewworker);
}
export function* EmptyChartviewIdSaga() {
  yield takeLatest(SetChartviewIdempty.type, setchartviewEmpty);
}
