import { takeLatest } from "redux-saga/effects";
import {
  GetDeletePlan,
  GetIcd10Codes,
  GetPlanDetails,
  GetPlanDetailsforDashB,
  GetPlanNameValidation,
  GetPlanforPat,
  GetTreatPlanDetails,
  InsertTreatmentPlan,
} from "../../StateManagement/Reducers/TreatmentPlanState";
import {
  DeletePlan,
  Icd10Worker,
  PlanDetailWorker,
  PostTreatmentPlan,
  SetPlantoPatient,
  getTreatPlan,
  planNameValidation,
} from "./TreatmentPlanSagaWorkers";

export function* TreatPlanList() {
  yield takeLatest(GetPlanDetails.type, getTreatPlan);
}
export function* SetTreatPlanToPatient() {
  yield takeLatest(GetPlanforPat.type, SetPlantoPatient);
}
export function* TreatPlanListDashB() {
  yield takeLatest(GetPlanDetailsforDashB.type, getTreatPlan);
}

export function* DeletePlanName() {
  yield takeLatest(GetDeletePlan.type, DeletePlan);
}

export function* CheckPlanNameSaga() {
  yield takeLatest(GetPlanNameValidation.type, planNameValidation);
}

export function* ICD10Saga() {
  yield takeLatest(GetIcd10Codes.type, Icd10Worker);
}
export function* TreatmentPlanInsertSaga() {
  yield takeLatest(InsertTreatmentPlan.type, PostTreatmentPlan);
}
export function* PlanDetailSaga() {
  yield takeLatest(GetTreatPlanDetails.type, PlanDetailWorker);
}
