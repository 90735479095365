import React from "react";
import {
  Button,
  Div,
  DropDownDiv,
  DropdownImage,
  DropdownOptions,
  FormLabel,
  Input,
  PageTitle,
  SearchIconSVG,
  SelectInput,
  Text,
  SpanTag,
} from "../../StyledComponents";
import { RiEdit2Fill } from "react-icons/ri";
import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import { Divresponsive, TextArea, MonitorDiv, TreatInput } from "../styles";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProviderSearchList } from "../../../StateManagement/Reducers/PatientState";
import {
  GetIcd10Codes,
  GetTreatPlanDetails,
  GetPlanNameValidation,
  InsertTreatmentPlan,
  SetPlanNameResponse,
  SetTreatPlanDetails,
} from "../../../StateManagement/Reducers/TreatmentPlanState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import DropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { FiTrash2 } from "react-icons/fi";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { GetDrugList } from "../../../StateManagement/Reducers/MedicalState";
import { useNavigate, useParams } from "react-router";

const AddTreatmentPlan = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const searchDropdown = useRef(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const providerList = useSelector((state) => state.Patient.providerlist);
  const params = useParams();
  const plan_id = params.planid;
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const ICD10 = useSelector((state) => state.TreatmentPlan.Icd10List);
  const Drugdetails = useSelector((state) => state.Medical.DrugList);
  const planNameResponse = useSelector(
    (state) => state.TreatmentPlan.PlanNameResponse
  );
  const PlanDetails = useSelector((state) => state.TreatmentPlan.planDetails);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  //---------------------Details Start------------------------//

  const [Details, setDetails] = useState({
    title: "",
    goal: "",
    problem_statement: "",
    diagnosis: "",
  });

  const [DetailsErr, setDetailsErr] = useState({
    titleErr: false,
    goalErr: false,
    problem_statementErr: false,
    diagnosisErr: false,
  });
  const [search, setSearch] = useState("");
  const [planErr, SetPlanErr] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  //---------------------Details End------------------------//

  //---------------------Vitals Section Start------------------------//

  const [ShowOptions, setShowOptions] = useState(false);
  const [vitalinputList, setVitalinputList] = useState([
    {
      vital: "",
      frequency: "",
      notes: "",
      device_type: "",
    },
  ]);
  const [vitalinputListErr, setVitalinputListErr] = useState([
    {
      vitalErr: false,
      device_typeErr: false,
      frequencyErr: false,
      notesErr: false,
    },
  ]);
  const [vitalDropdownOptionList, setVitalDropdownOptionList] = useState([
    {
      label: "Blood pressure",
      id: 1,
      value: "BP",
      device_type: "Blood Pressure Monitor Device",
    },
    {
      label: "Heart rate",
      id: 2,
      value: "HR",
      device_type: "Heart rate Device",
    },
    {
      label: "Glucose level",
      id: 3,
      value: "CG",
      device_type: "Blood Glucose Device",
    },
    { label: "Weight ", id: 4, value: "WS", device_type: "Weight Scale " },
    {
      label: "Pulse Rate",
      id: 5,
      value: "PO",
      device_type: "Pulse Rate Device",
    },
  ]);
  //---------------------Vitals Section End------------------------//
  //---------------------Medication Section Start------------------------//
  const [medicationinputList, setMedicationinputList] = useState([
    {
      medicine: "",
      frequency: "",
      notes: "",
      selectedValue: "",
    },
  ]);
  const [medicationinputListErr, setMedicationinputListErr] = useState([
    {
      medicineErr: false,
      frequencyErr: false,
      notesErr: false,
    },
  ]);
  const [ShowOption3, setShowOptions3] = useState(false);
  const [medicinelist, setMedicineList] = useState([]);
  const [searchMedic, setSearchMedic] = useState("");
  const [dis, setDis] = useState(false);
  //---------------------Medication Section End------------------------//
  //---------------------Care Team Section Start------------------------//
  const [careTeamList, setCareTeamList] = useState([
    {
      provider: "",
      specialty: "",
      phoneNumber: "",
      Email: "",
      provider_id: "",
      selectedValue: "",
    },
  ]);
  const [careTeamListErr, setCareTeamListErr] = useState([
    {
      providerErr: false,
      specialtyErr: false,
      phoneNumberErr: false,
      EmailErr: false,
    },
  ]);

  const [specDropdownOptionList, setSpecDropdownOptionList] = useState([
    { label: "Primary physician", id: "1" },
    { label: "Nurse practitioner", id: "2" },
    { label: "Careteam coordinator", id: "3" },
    { label: "Careteam member", id: "4" },
  ]);
  const [ShowOptions2, setShowOptions2] = useState(false);
  const [list1, setList1] = useState([]);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [isDropdownVisible2, setDropdownVisible2] = useState(false);
  //---------------------Care Team Section End------------------------//
  //-------------------------------------------State and Variables End-------------------------------------------------------- //
  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  //---------------------Vitals Section Start------------------------//
  const handleAddClick = () => {
    if (vitalinputList.length > 4) {
      return;
    }
    setVitalinputList([
      ...vitalinputList,
      {
        vital: "",

        frequency: "",
        notes: "",
        device_type: "",
      },
    ]);

    setVitalinputListErr([
      ...vitalinputListErr,
      {
        vitalErr: false,
        device_typeErr: false,
        frequencyErr: false,
        notesErr: false,
      },
    ]);
  };
  const handleRemoveClick = (index, vital) => {
    const list = [...vitalinputList];
    list.splice(index, 1);
    setVitalinputList(list);
    const newList = vitalDropdownOptionList.map((option) =>
      option.value === vital ? { ...option, disabled: false } : option
    );
    setVitalDropdownOptionList(newList);
    const listErr = [...vitalinputListErr];
    listErr.splice(index, 1);
    setVitalinputListErr(listErr);
  };
  const vitalDropDownChange = (e, index, row) => {
    const name = e.target.getAttribute("name");

    if (name === "vital") {
      setShowOptions(false);
      const list = [...vitalinputList];
      list[index]["vital"] = row.value;
      list[index]["device_type"] = row.device_type;
      setVitalinputList(list);

      const listErr = [...vitalinputListErr];
      listErr[index]["device_typeErr"] = false;
      listErr[index]["vitalErr"] = false;
      setVitalinputListErr(listErr);
      const selectedValues = vitalinputList.map((item) => item[name]);

      const newList = vitalDropdownOptionList.map((option) => {
        return {
          ...option,
          disabled: selectedValues.includes(option.value),
        };
      });

      setVitalDropdownOptionList(newList);
    }
  };
  const vitalInChange = (e, index) => {
    const { name, value } = e.target;

    if (value !== "") {
      const list = [...vitalinputList];

      list[index][name] = value;
      setVitalinputList(list);

      const listErr = [...vitalinputListErr];
      listErr[index][name + "Err"] = false;
      setVitalinputListErr(listErr);
    } else {
      const list = [...vitalinputList];

      list[index][name] = value;
      setVitalinputList(list);

      const listErr = [...vitalinputListErr];
      listErr[index][name + "Err"] = true;
      setVitalinputListErr(listErr);
    }
  };
  //---------------------Vitals Section End------------------------//
  //---------------------Medication Section Start------------------------//
  const handleMedicationClick = () => {
    setMedicationinputList([
      ...medicationinputList,
      {
        medicine: "",
        frequency: "",
        notes: "",
        selectedValue: "",
      },
    ]);

    setMedicationinputListErr([
      ...medicationinputListErr,
      {
        medicineErr: false,
        frequencyErr: false,
        notesErr: false,
      },
    ]);
  };
  const handlemedicationRemClick = (index) => {
    const list = [...medicationinputList];
    list.splice(index, 1);
    setMedicationinputList(list);

    const listErr = [...medicationinputListErr];
    listErr.splice(index, 1);
    setMedicationinputListErr(listErr);
  };
  const medicationChange = (e, index) => {
    const { name, value } = e.target;
    if (value !== "") {
      const list = [...medicationinputList];

      list[index][name] = value;
      setMedicationinputList(list);

      const listErr = [...medicationinputListErr];
      listErr[index][name + "Err"] = false;
      setMedicationinputListErr(listErr);
    } else {
      const list = [...medicationinputList];

      list[index][name] = value;
      setMedicationinputList(list);

      const listErr = [...medicationinputListErr];
      listErr[index][name + "Err"] = true;
      setMedicationinputListErr(listErr);
    }
  };
  const handleInputChange1 = (e, i) => {
    const { value } = e.target;
    const name = e.target.getAttribute("name");

    const newList = [...medicationinputList];
    const newListErr = [...medicationinputListErr];

    newListErr[i] = { ...newListErr[i], medicineErr: false };
    newList[i] = { ...newList[i], [name]: value };

    setMedicationinputListErr(newListErr);
    setMedicationinputList(newList);

    setLoading(true);
    setSearchMedic(value);

    if (value !== "") {
      if (ShowOption3 === i && value === searchMedic) {
        setShowOptions3();
      } else {
        setShowOptions3(i);
      }
    } else {
      setShowOptions3();
    }
  };
  const MedicationSelected = (e, row, index) => {
    const list = [...medicationinputList];

    list[index]["medicine"] = row;
    list[index]["selectedValue"] = row;
    setMedicationinputList(list);
    setShowOptions3();
    setSearchMedic("");
  };
  //---------------------Medication Section End------------------------//
  //---------------------Care Team Section Start------------------------//
  const handleCareTeamClick = () => {
    if (careTeamList.length > 3) {
      return;
    }
    setCareTeamList([
      ...careTeamList,
      {
        provider: "",
        specialty: "",
        phoneNumber: "",
        Email: "",
        provider_id: "",
        selectedValue: "",
      },
    ]);
    setCareTeamListErr([
      ...careTeamListErr,
      {
        providerErr: false,
        specialtyErr: false,
        phoneNumberErr: false,
        EmailErr: false,
      },
    ]);
  };
  const handleCareTeamRemClick = (index, spec) => {
    const list = [...careTeamList];
    list.splice(index, 1);
    setCareTeamList(list);
    const newList = specDropdownOptionList.map((option) =>
      option.id === spec ? { ...option, disabled: false } : option
    );
    setSpecDropdownOptionList(newList);

    const listErr = [...careTeamListErr];
    listErr.splice(index, 1);
    setCareTeamListErr(listErr);
  };
  const specDropDownChange = (e, index) => {
    const name = e.target.getAttribute("name");
    const value = e.target.getAttribute("value");

    if (name === "specialty") {
      setShowOptions2(false);
    }

    if (value !== "") {
      // const list = [...careTeamList];
      // list[index][name] = value;

      // setCareTeamList(list);

      // const listErr = [...careTeamListErr];
      // listErr[index][name + "Err"] = false;
      // setCareTeamListErr(listErr);

      const newList1 = [...careTeamList];
      const newList1Err = [...careTeamListErr];

      newList1Err[index] = { ...newList1Err[index], [name + "Err"]: false };
      newList1[index] = { ...newList1[index], [name]: value };
      setCareTeamList(newList1);
      setCareTeamListErr(newList1Err);

      const selectedValues = newList1.map((item) => item[name]);

      const newList = specDropdownOptionList.map((option) => {
        return {
          ...option,
          disabled: selectedValues.includes(option.id),
        };
      });

      setSpecDropdownOptionList(newList);
    } else {
      // const list = [...careTeamList];
      // list[index][name] = value;

      // setCareTeamList(list);

      // const listErr = [...careTeamListErr];
      // listErr[index][name + "Err"] = true;
      // setCareTeamListErr(listErr);
      const newList1 = [...careTeamList];
      const newList1Err = [...careTeamListErr];

      newList1Err[index] = { ...newList1Err[index], [name + "Err"]: true };
      newList1[index] = { ...newList1[index], [name]: value };

      setCareTeamList(newList1);

      setCareTeamListErr(newList1Err);

      const selectedValues = newList1.map((item) => item[name]);

      const newList = specDropdownOptionList.map((option) => {
        return {
          ...option,
          disabled: selectedValues.includes(option.id),
        };
      });
      setSpecDropdownOptionList(newList);
    }
  };
  const handleProviderChange = (e, i) => {
    const { value } = e.target;

    const updatedCareTeamList = [...careTeamList];
    const updatedCareTeamListErr = [...careTeamListErr];

    updatedCareTeamListErr[i].providerErr = false;

    updatedCareTeamList[i] = { ...updatedCareTeamList[i], provider: value };

    setCareTeamListErr(updatedCareTeamListErr);
    setCareTeamList(updatedCareTeamList);

    setLoading(true);
    setSearchTerm2(value);

    if (value !== "") {
      if (isDropdownVisible2 === i && value === searchTerm2) {
        setDropdownVisible2();
      } else {
        setDropdownVisible2(i);
      }
    } else {
      setDropdownVisible2();
    }
  };
  const providerSelect = (e, row, index) => {
    const list = [...careTeamList];

    list[index]["provider"] = row.provname;
    list[index]["phoneNumber"] = row.phone;
    list[index]["Email"] = row.email;
    list[index]["provider_id"] = row.id;
    list[index]["selectedValue"] = row.provname;

    const list1 = [...careTeamListErr];

    list1[index]["providerErr"] = false;
    list1[index]["phoneNumberErr"] = row.phone ? false : true;
    list1[index]["EmailErr"] = row.email ? false : true;
    setCareTeamListErr(list1);
    setCareTeamList(list);
    setDropdownVisible2();
    setSearchTerm2("");
  };
  //---------------------Care Team Section End------------------------//
  //---------------------Details Section Start------------------------//
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split the input into words
    const wordCount = words.length;
    if (wordCount > 100) {
      setModalAlerShow(true);
      setModalMsg("problem statement exceeded more than 100 words");
      setStatus("retry");
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
      }, 1500);
      return;
    }

    setDetails({ ...Details, problem_statement: e.target.value });
    setDetailsErr({ ...DetailsErr, problem_statementErr: false });
    e.target.style.height = "auto";
    e.target.style.height = `${Math.min(e.target.scrollHeight + 1, 150)}px`;
    e.target.style.overflowY =
      e.target.scrollHeight + 1 > 150 ? "auto" : "hidden";
  };

  const handleChange1 = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/); // Split the input into words
    const wordCount = words.length;
    if (wordCount > 100) {
      setModalAlerShow(true);
      setModalMsg("Goal statement exceeded more than 100 words");
      setStatus("retry");
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
      }, 1500);
      return;
    }
    setDetails({ ...Details, goal: e.target.value });
    setDetailsErr({ ...DetailsErr, goalErr: false });
    e.target.style.height = "auto";
    e.target.style.height = `${Math.min(e.target.scrollHeight + 1, 150)}px`;
    e.target.style.overflowY =
      e.target.scrollHeight + 1 > 150 ? "auto" : "hidden";
  };

  const CareTeamChange = (e, index) => {
    if (!e.target.value.includes(" ")) {
      const { name, value } = e.target;

      if (name === "phoneNumber") {
        if (value !== "" && !isNaN(value) && !value.includes(".")) {
          const list = [...careTeamList];
          list[index][name] = value;
          setCareTeamList(list);

          const listErr = [...careTeamListErr];
          listErr[index][name + "Err"] = false;
          setCareTeamListErr(listErr);
        } else {
          if (!isNaN(value) && !value.includes(".")) {
            const list = [...careTeamList];

            list[index][name] = value;
            setCareTeamList(list);
          }

          const listErr = [...careTeamListErr];
          listErr[index][name + "Err"] = true;
          setCareTeamListErr(listErr);
        }
      }
      if (name === "Email") {
        if (value !== "") {
          const list = [...careTeamList];

          list[index][name] = value;
          setCareTeamList(list);

          const listErr = [...careTeamListErr];
          listErr[index][name + "Err"] = false;
          setCareTeamListErr(listErr);
        } else {
          const list = [...careTeamList];

          list[index][name] = value;
          setCareTeamList(list);

          const listErr = [...careTeamListErr];
          listErr[index][name + "Err"] = true;
          setCareTeamListErr(listErr);
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setDetails({ ...Details, diagnosis: "" });
    setDetailsErr({ ...DetailsErr, diagnosisErr: false });
    setLoading(true);
    setSearchTerm(value);
    if (value !== "") {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  };
  const handlePlanTitle = () => {
    if (planErr) {
      setModalAlerShow(true);
      setModalMsg("Plan Name Already Exist");
      setStatus("failure");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
      }, 2000);
      dispatch(SetPlanNameResponse(""));
      SetPlanErr(false);
    } else {
      setDetails({ ...Details, title: search });
      setEditTitle(false);
    }
  };
  //---------------------Details Section End------------------------//
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const handleCancel = () => {
    if (params.pid != 0 && params.pid != undefined) {
      Navigate(`/PatientDemo/${params.pid}`);
    } else {
      Navigate("/TreatmentPlan");
    }
  };
  const validateData = (data, propertiesToValidate) => {
    return data.map((item) => {
      const errors = {};
      let error = 0;
      propertiesToValidate.forEach((property) => {
        if (property !== "medicine" && property !== "selectedValue") {
          if (item[property] === "") {
            errors[`${property}Err`] = true;

            error = 1;
          }
        } else if (property === "medicine") {
          if (
            item[property] === "" ||
            item[property] !== item["selectedValue"]
          ) {
            errors[`${property}Err`] = true;

            error = 1;
          }
        }
      });
      return { ...errors, error };
    });
  };
  const validateData1 = (data, propertiesToValidate) => {
    return data.map((item) => {
      const errors = {};
      let error = 0;
      propertiesToValidate.forEach((property) => {
        if (property !== "provider" && property !== "selectedValue") {
          if ((item[property] === "") | !item[property]) {
            errors[`${property}Err`] = true;

            error = 1;
          }
        } else if (property === "provider") {
          if (
            item[property] === "" ||
            item[property] !== item["selectedValue"]
          ) {
            errors[`${property}Err`] = true;

            error = 1;
          }
        }
      });
      return { ...errors, error };
    });
  };
  const handleSave = (e) => {
    e.stopPropagation();
    let err = 0;

    if (planErr) {
      setModalAlerShow(true);
      setModalMsg("Plan Name Already Exist");
      setStatus("failure");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
      }, 2000);
      dispatch(SetPlanNameResponse(""));
      SetPlanErr(false);
      return;
    }

    for (const key in Details) {
      if (Details.hasOwnProperty(key)) {
        const value = Details[key];
        if (value === null || value === undefined || value === "") {
          setDetailsErr((prevDetailsErr) => ({
            ...prevDetailsErr,
            [`${key}Err`]: true,
          }));
          err++;
        }
      }
    }

    if (
      Details.title === null ||
      Details.title === undefined ||
      Details.title === ""
    ) {
      setModalAlerShow(true);
      setModalMsg("Please Choose a  Name for your plan");
      setStatus("failure");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
      }, 2000);
    }
    const vitalPropertiesToValidate = [
      "vital",
      "device_type",
      "frequency",
      "notes",
    ];
    const medicationPropertiesToValidate = [
      "medicine",
      "frequency",
      "notes",
      "selectedValue",
    ];
    const careTeamPropertiesToValidate = [
      "provider",
      "specialty",
      "phoneNumber",
      "Email",
      "selectedValue",
    ];

    const vitalinputs = validateData(vitalinputList, vitalPropertiesToValidate);
    const medicationinputs = validateData(
      medicationinputList,
      medicationPropertiesToValidate
    );
    const providerinputs = validateData1(
      careTeamList,
      careTeamPropertiesToValidate
    );

    const isVitalDataValid = vitalinputs.every((item) => item.error === 0);
    const isCareDataValid = providerinputs.every((item) => item.error === 0);

    const isMedicationDataValid = medicationinputs.every(
      (item) => item.error === 0
    );

    if (!isVitalDataValid) {
      err++;
    }

    if (!isMedicationDataValid) {
      err++;
    }

    if (!isCareDataValid) {
      err++;
    }
    setVitalinputListErr(vitalinputs);
    setMedicationinputListErr(medicationinputs);
    setCareTeamListErr(providerinputs);

    if (err > 0) {
      return;
    }
    var phys = null,
      carelead = null,
      nurse = null,
      carecord = null;
    let arr = careTeamList.map((row) => {
      if (row.specialty == 1) {
        phys = row.provider_id;
      } else if (row.specialty == 2) {
        nurse = row.provider_id;
      } else if (row.specialty == 3) {
        carelead = row.provider_id;
      } else if (row.specialty == 4) {
        carecord = row.provider_id;
      }
    });
    setDis(true);
    const article = {
      plan_name: Details.title,
      goal: Details.goal,
      problem_statement: Details.problem_statement,
      user_id: user_id,

      diagnosis: Details.diagnosis,
      code_type: "ICD10",
      physician: phys,

      Nurse: nurse,

      Care_coordinator: carelead,

      Care_member: carecord,

      pid: params.pid ? params.pid : "0",

      vitals: vitalinputList,
      medications: medicationinputList,
    };
    if (params.planid) {
      article.id = params.planid;
    }
    dispatch(InsertTreatmentPlan(article));
    setTimeout(() => {
      setDis(false);
    }, 1500);
  };

  const handleClear = () => {
    const newvitalinputListErr = vitalinputListErr.map((item) => ({
      ...item,
      vitalErr: false,
      device_typeErr: false,
      frequencyErr: false,
      notesErr: false,
    }));
    setVitalinputListErr(newvitalinputListErr);

    const newMedicationinputListErr = medicationinputListErr.map((item) => ({
      ...item,
      medicineErr: false,
      frequencyErr: false,
      notesErr: false,
    }));
    setMedicationinputListErr(newMedicationinputListErr);
    const newcareTeamListErr = careTeamListErr.map((item) => ({
      ...item,
      providerErr: false,
      specialtyErr: false,
      phoneNumberErr: false,
      EmailErr: false,
    }));
    setCareTeamListErr(newcareTeamListErr);
    const newVitalinputList = vitalinputList.map((item) => ({
      ...item,
      vital: "",
      frequency: "",
      notes: "",
      device_type: "",
    }));

    let obj1 = {
      goalErr: false,
      problem_statementErr: false,
      diagnosisErr: false,
    };

    setDetailsErr(obj1);
    setVitalinputList(newVitalinputList);

    const newMedicationinputList = medicationinputList.map((item) => ({
      ...item,
      medicine: "",
      frequency: "",
      notes: "",
    }));

    setMedicationinputList(newMedicationinputList);

    const newCareTeamList = careTeamList.map((item) => ({
      ...item,
      provider: "",
      specialty: "",
      phoneNumber: "",
      Email: "",
      provider_id: "",
    }));

    setCareTeamList(newCareTeamList);

    setVitalDropdownOptionList([
      {
        label: "Blood pressure",
        id: 1,
        value: "BP",
        device_type: "Blood Pressure Monitor Device",
      },
      {
        label: "Hear rate",
        id: 2,
        value: "HR",
        device_type: "Hear rate Device",
      },
      {
        label: "Glucose level",
        id: 3,
        value: "CG",
        device_type: "Blood Glucose Device",
      },
      { label: "Weight ", id: 4, value: "WS", device_type: "Weight Scale " },
      {
        label: "Pulse Rate",
        id: 5,
        value: "PO",
        device_type: "Pulse Rate Device",
      },
    ]);

    let obj = {
      title: Details.title,
      goal: "",
      problem_statement: "",
      diagnosis: "",
    };

    setDetails(obj);

    setSpecDropdownOptionList([
      { label: "Primary physician", id: "1" },
      { label: "Nurse practitioner", id: "2" },
      { label: "Careteam coordinator", id: "3" },
      { label: "Careteam member", id: "4" },
    ]);
  };
  const DisableVitalDropDown = (array, a, array1, b) => {
    const newList = [...array1];

    const vitalSet = new Set(array.map((item) => item[a]));

    const updatedOptions = newList.map((item) => ({
      ...item,
      disabled: vitalSet.has(item[b]),
    }));
    return updatedOptions;
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //
  // ------------------------------ useEffect Start----------------------------------------------------------- //
  useEffect(() => {
    const handleOutsideClick = async (event) => {
      const exampleAttr = event.target.getAttribute("name");
      if (
        (exampleAttr !== "Medic" && exampleAttr !== "medicine") ||
        !exampleAttr
      ) {
        setShowOptions3(false);
      }
      const exampleAttr2 = event.target.getAttribute("name");
      if (
        (exampleAttr2 !== "provider" && exampleAttr !== "provider") ||
        !exampleAttr2
      ) {
        setDropdownVisible2(false);
      }

      if (
        searchDropdown.current &&
        !searchDropdown.current.contains(event.target)
      ) {
        if (isDropdownVisible === true) {
          setDropdownVisible(false);
        }
      }
      if (!event.target.id || event.target.id != "vitality") {
        setShowOptions();
      }
      if (!event.target.id || event.target.id != "specility") {
        setShowOptions2();
      }
    };

    document.addEventListener("click", handleOutsideClick, true);

    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    setList(ICD10);
  }, [ICD10]);

  useEffect(() => {
    if (ICD10.length >= 0) {
      setLoading(false);
    }
  }, [ICD10]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (searchTerm !== "") {
          dispatch(GetIcd10Codes({ search_key: searchTerm }));
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [searchTerm]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (searchMedic !== "") {
          dispatch(GetDrugList(searchMedic));
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [searchMedic]);

  useEffect(() => {
    if (Drugdetails[1] !== null) {
      setLoading(false);
      setMedicineList(Drugdetails[1]);
    }
  }, [Drugdetails[1]]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (searchTerm2 !== "") {
          dispatch(getProviderSearchList({ search_key: searchTerm2 }));
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [searchTerm2]);

  useEffect(() => {
    if (providerList.length >= 0) {
      setLoading(false);
      const selectedProviders = careTeamList.map((item) => item["provider"]);

      const filteredSelectedProviders = selectedProviders.filter(
        (name) => name !== ""
      );
      const filteredList1WithoutMatches = providerList.filter((obj) => {
        return !filteredSelectedProviders.includes(obj.provname);
      });
      setList1(filteredList1WithoutMatches);
    }
  }, [providerList]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (search !== "") {
          dispatch(
            GetPlanNameValidation({
              plan: search,
              id: params.planid ? params.planid : "0",
            })
          );
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [search]);

  useEffect(() => {
    if (planNameResponse !== "") {
      if (planNameResponse === "Plan Name Already Found") {
        SetPlanErr(true);
      } else if (planNameResponse === "Plan Name Not Found") {
        SetPlanErr(false);
      } else if (planNameResponse === "inserted") {
        setModalAlerShow(true);
        setStatus("success");
        setModalMsg("Treatment Plan Inserted Successfully");
        setTimeout(() => {
          setModalAlerShow(false);
          Navigate("/TreatmentPlan");
        }, [2000]);

        dispatch(SetPlanNameResponse(""));
      } else if (planNameResponse === "updated") {
        setModalAlerShow(true);
        setStatus("success");
        setModalMsg("Treatment Plan Updated Successfully");
        setTimeout(() => {
          setModalAlerShow(false);
          if (params.pid != 0) {
            Navigate(`/PatientDemo/${params.pid}`);
          } else {
            Navigate("/TreatmentPlan");
          }
        }, [2000]);

        dispatch(SetPlanNameResponse(""));
      }
    }
  }, [planNameResponse]);

  useEffect(() => {
    if (params.planid) {
      dispatch(
        GetTreatPlanDetails({ id: plan_id, pid: params.pid ? params.pid : "0" })
      );
    } else {
      dispatch(SetTreatPlanDetails({}));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(PlanDetails).length > 0) {
      setSearch(PlanDetails.plan_name);
      let a = {
        title: PlanDetails.plan_name,
        goal: PlanDetails.goal,
        diagnosis: PlanDetails.diagnosis,
        problem_statement: PlanDetails.problem_statement,
      };
      setSearch(PlanDetails.plan_name);
      setDetails(a);
      if (PlanDetails.vitals.length > 0) {
        let opt = DisableVitalDropDown(
          PlanDetails.vitals,
          "vital",
          vitalDropdownOptionList,
          "value"
        );
        setVitalDropdownOptionList(opt);

        let b = [];
        let c = [];
        PlanDetails.vitals.map((row) => {
          b.push(row);
          let obj = {
            vitalErr: false,
            device_typeErr: false,
            frequencyErr: false,
            notesErr: false,
          };
          c.push(obj);
        });
        setVitalinputList(b);
        setVitalinputListErr(c);
      }
      if (PlanDetails.medication.length > 0) {
        let d = [];
        let e = [];
        PlanDetails.medication.map((row) => {
          d.push(row);
          let obj = {
            medicineErr: false,
            frequencyErr: false,
            notesErr: false,
          };
          e.push(obj);
        });

        setMedicationinputList(d);
        setMedicationinputListErr(e);
      }
      if (PlanDetails.careTeam.length > 0) {
        let opt1 = DisableVitalDropDown(
          PlanDetails.careTeam,
          "specialty",
          specDropdownOptionList,
          "id"
        );
        setSpecDropdownOptionList(opt1);
        let f = [];
        let g = [];
        PlanDetails.careTeam.map((row) => {
          f.push(row);
          let obj = {
            providerErr: false,
            specialtyErr: false,
            phoneNumberErr: false,
            EmailErr: false,
          };
          g.push(obj);
        });

        setCareTeamList(f);
        setCareTeamListErr(g);
      }
    }
  }, [PlanDetails]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Div padding="0px 0px 20px 0px">
        <PageTitle
          textAlign="left"
          fontFamily="'Open Sans', sans-serif"
          fontWeight="600"
          fontSize="18px"
          lineHeight="24px"
          color="#2E2E2E"
        >
          Treatment Plan
        </PageTitle>
      </Div>
      <Div background="#FFFFFF">
        <Div
          padding="12px 20px 12px 20px"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
          // width="100%"
          backgroundColor="#2C7BE5"
          color="#ffffff"
          textAlign="center"
        >
          {!editTitle ? (
            <Div display="flex" justifyContent="center" padding="5px 0px">
              <SpanTag
                maxWidth="500px"
                overFlow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {Details.title !== "" ? Details.title : `New Treatment plan`}
              </SpanTag>
              &nbsp;
              <RiEdit2Fill
                style={{ cursor: "pointer" }}
                onClick={() => setEditTitle(true)}
              />
            </Div>
          ) : (
            <Div display="flex">
              <Input
                // type="search"
                name="search"
                placeholder="Treatment Plan"
                padding="10px"
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="4px"
                width="90%"
                height="30px"
                paddingLeft="28px"
                color="#2E2E2E"
                fontFamily="'Open Sans', sans-serif"
                fontWeight="400"
                fontSize="12px"
                position="absolute"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoComplete
              />
              <Button
                padding="3px 26px"
                background="rgb(87, 191, 127)"
                color="#ffffff"
                border="none"
                borderRadius="8px"
                cursor="pointer"
                marginLeft="32px"
                marginRight="32px"
                onClick={handlePlanTitle}
                hoverBackColor="rgb(87, 191, 127)"
                activeBackgroundColor="rgb(87, 191, 127)"
              >
                Save
              </Button>
              <Button
                padding="5px 10px 0 10px"
                border="none"
                borderRadius="8px"
                cursor="pointer"
                onClick={(e) => {
                  setEditTitle(false);
                  e.stopPropagation();
                }}
              >
                <BsXLg style={{ color: "#407BFF" }} />
              </Button>
            </Div>
          )}
        </Div>
        <Div display="inline-block" padding="16px" width="100%">
          <Div width="100%">
            <Divresponsive width="50%" float="left">
              <Div>
                <FormLabel
                  fontSize="14px"
                  color="#2E2E2E"
                  fontFamily="'Open Sans', sans-serif"
                  lineHeight="16px"
                  fontWeight="600"
                  marginTop="2px"
                  padding="0 0 4px 0"
                >
                  Problem Statement
                </FormLabel>
                <TextArea
                  placeholder="Write patient problem"
                  className="planwidth"
                  border={
                    DetailsErr.problem_statementErr
                      ? "1px solid red"
                      : "1px solid rgba(46, 46, 46, 0.25)"
                  }
                  value={Details.problem_statement}
                  onChange={(e) => handleChange(e)}
                  pfontFamily="Open sans, sans-serif"
                  pColor="rgba(46, 46, 46, 0.50)"
                  pfontSize="12px"
                  height="30px"
                ></TextArea>
                {DetailsErr.problem_statementErr && (
                  <FormLabel
                    display="flex"
                    marginBottom="11px"
                    textAlign="left"
                    fontSize="10px"
                    color="red"
                    fontWeight="400"
                    fontFamily="Open sans, sans-serif"
                    marginTop=" 2px"
                  >
                    Problem Statement cannot be empty
                  </FormLabel>
                )}
              </Div>
            </Divresponsive>
            <Divresponsive width="50%" float="right">
              <Div>
                <Div>
                  <FormLabel
                    fontSize="14px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans', sans-serif"
                    lineHeight="16px"
                    fontWeight="600"
                    marginTop="2px"
                  >
                    Diagnosis
                  </FormLabel>
                  <Div width="100%" position="relative" margin="4px 0 16px 0">
                    <SearchIconSVG top="7px">
                      <AiOutlineSearch />
                    </SearchIconSVG>
                    <TreatInput
                      placeholder={
                        searchTerm ? searchTerm : "Mention Diagnosis"
                      }
                      pColor="rgba(46, 46, 46, 0.5)"
                      fontSize="12px"
                      color="rgba(46, 46, 46, 1)"
                      paddingLeft="28px"
                      paddingRight="10px"
                      border={
                        DetailsErr.diagnosisErr
                          ? "1px solid red"
                          : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      height="30px"
                      className="diaginput"
                      borderRadius="4px"
                      value={
                        Details.diagnosis !== ""
                          ? Details.diagnosis
                          : searchTerm
                      }
                      onChange={handleInputChange}
                    />
                    {isDropdownVisible && (loading || list.length >= 0) ? (
                      <div ref={searchDropdown}>
                        <DropDownDiv
                          width="88%"
                          background="#FFFFFF"
                          border="1px solid rgba(46, 46, 46, 0.25)"
                          borderRadius="4px"
                          top="35px"
                          maxHeight="256px"
                          overflowY="hidden"
                        >
                          {loading ? (
                            <Text padding="10px 12px 8px 12px" fontSize="15px">
                              Loading...
                            </Text>
                          ) : ICD10.length > 0 ? (
                            ICD10.map((option) => (
                              <DropdownOptions
                                key={option.id}
                                padding="10px 12px 8px 12px"
                                fontSize="12px"
                                fontFamily="sans seriff"
                                color="rgba(46, 46, 46, 1)"
                                onClick={() => {
                                  setDetails({
                                    ...Details,
                                    diagnosis: option.title,
                                  });
                                  setSearchTerm("");
                                  setDropdownVisible(false);
                                }}
                              >
                                {option.title}
                              </DropdownOptions>
                            ))
                          ) : (
                            <Text padding="10px 12px 8px 12px" fontSize="15px">
                              No results found
                            </Text>
                          )}
                        </DropDownDiv>
                      </div>
                    ) : null}
                    {DetailsErr.diagnosisErr && (
                      <FormLabel
                        display="flex"
                        marginBottom="11px"
                        textAlign="left"
                        fontSize="10px"
                        color="red"
                        fontWeight="400"
                        fontFamily="'Open sans', sans-serif"
                        marginTop=" 4px"
                      >
                        Invalid diagnosis
                      </FormLabel>
                    )}
                  </Div>
                </Div>

                <FormLabel
                  fontSize="14px"
                  color="#2E2E2E"
                  fontFamily="'Open Sans', sans-serif"
                  lineHeight="16px"
                  fontWeight="600"
                  // marginTop="2px"
                  display="block"
                >
                  Goal
                </FormLabel>

                <TextArea
                  placeholder="Mention patient goal"
                  className="planwidth"
                  border={
                    DetailsErr.goalErr
                      ? "1px solid red"
                      : "1px solid rgba(46, 46, 46, 0.25)"
                  }
                  value={Details.goal}
                  onChange={(e) => handleChange1(e)}
                  pfontFamily="Open sans, sans-serif"
                  pColor="rgba(46, 46, 46, 0.50)"
                  pfontSize="12px"
                ></TextArea>
                {DetailsErr.goalErr && (
                  <FormLabel
                    display="flex"
                    marginBottom="11px"
                    textAlign="left"
                    fontSize="10px"
                    color="red"
                    fontWeight="400"
                    fontFamily="'Open sans', sans-serif"
                    marginTop="2px"
                  >
                    Goal Cannot be empty
                  </FormLabel>
                )}
              </Div>
            </Divresponsive>
          </Div>
          <Div width="100%">
            <Divresponsive width="50%" float="left">
              <Div>
                <Div width="88.1%" margin="12px 0 8px 0">
                  <FormLabel
                    fontSize="14px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans', sans-serif"
                    lineHeight="16px"
                    fontWeight="600"
                    padding="0 0 4px 0"
                  >
                    Monitoring Parameter
                  </FormLabel>
                  <Text
                    color="#2E2E2E"
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="'Open Sans', sans-serif"
                    padding="4px 0 0 0"
                    width="81%"
                  >
                    Based on the problem statement and diagnosis we recommend
                    you to measure some vital signs which monitors the
                    conditions of your health
                  </Text>
                </Div>
                {vitalinputList.map((x, i) => (
                  <Div display="flex" margin="0 0 16px 0">
                    <MonitorDiv className="monitordiv">
                      <Div
                        display="flex"
                        justifyContent="space-between"
                        padding="0"
                        alignItems="center"
                      >
                        <Div position="relative" id="vitality" width="48%">
                          <Div
                            borderRadius="4px"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            style={
                              vitalinputListErr[i].vitalErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "4px",
                                  }
                                : {}
                            }
                            onClick={(e) => {
                              if (ShowOptions === i) {
                                setShowOptions();
                              } else {
                                setShowOptions(i);
                              }
                            }}
                          >
                            <SelectInput
                              className="detailinput"
                              placeholder="'Vital'"
                              borderRadius="4px"
                              height="28px"
                              background="#FFFFFF"
                              fontSize="12px"
                              name="vital"
                              fontFamily="'Open Sans',sans-serif"
                              padding="6px 12px 0px 10px"
                              color="rgba(46, 46, 46, 1)"
                              overFlow="hidden"
                              maxWidth="72%"
                            >
                              {x?.vital !== "" &&
                                vitalDropdownOptionList.map((row) => {
                                  if (x.vital === row.value) {
                                    return (
                                      <span
                                        style={{
                                          maxWidth: "50px",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {row.label}
                                      </span>
                                    );
                                  }
                                })}
                            </SelectInput>
                            <DropdownImage
                              height="8px"
                              width="12px"
                              position="absolute"
                              top="42px"
                              right="10px"
                              marginTop="-28px"
                              src={DropdownDownicon}
                            ></DropdownImage>

                            {ShowOptions === i &&
                            vitalDropdownOptionList.length > 0 ? (
                              <DropDownDiv
                                margin="0 0 0 0"
                                width="100%"
                                background="#FFFFFF"
                                border="1px solid rgba(46, 46, 46, 0.25)"
                                borderRadius="3.13433px"
                                maxHeight="146px"
                                overflowY="auto"
                                overflowX="hidden"
                                // marginTop="64px"
                              >
                                {vitalDropdownOptionList.map((r, ind) => {
                                  return (
                                    <DropdownOptions
                                      key={ind}
                                      name="vital"
                                      id="vitality"
                                      value={r.label}
                                      padding="7px 12px 7px 12px"
                                      onClick={(e) =>
                                        vitalDropDownChange(e, i, r)
                                      }
                                      fontSize="12px"
                                      color="rgba(46, 46, 46, 1)"
                                      disabled={r.disabled}
                                    >
                                      {r.label}
                                    </DropdownOptions>
                                  );
                                })}
                              </DropDownDiv>
                            ) : (
                              ""
                            )}
                          </Div>
                          {vitalinputListErr[i].vitalErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop=" 4px"
                            >
                              Please Enter Vital Type
                            </FormLabel>
                          )}
                        </Div>
                        <Div
                          position="relative"
                          // ref={vitalsRef}
                          width="48%"
                        >
                          <SelectInput
                            className="detailinput"
                            placeholder="'Device Type'"
                            borderRadius="4px"
                            height="30px"
                            background="#FFFFFF"
                            fontSize="12px"
                            // name="device"
                            fontFamily="'Open Sans',sans-serif"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            padding="6px 12px 0px 10px"
                            color="rgba(46, 46, 46, 1)"
                            overFlow="hidden"
                            style={
                              vitalinputListErr[i].device_typeErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "4px",
                                  }
                                : {}
                            }
                            cursor="not-allowed"
                          >
                            {vitalDropdownOptionList.map((row) => {
                              if (x.vital === row.value) {
                                return (
                                  <span
                                    style={{
                                      maxWidth: "50px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {row.device_type}
                                  </span>
                                );
                              }
                            })}
                          </SelectInput>

                          {vitalinputListErr[i].device_typeErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop=" 4px"
                            >
                              Please Enter Device Type
                            </FormLabel>
                          )}
                        </Div>
                      </Div>

                      <Input
                        placeholder={x.frequency ? x.frequency : "Frequency"}
                        pColor="rgba(46, 46, 46, 0.5)"
                        fontSize="12px"
                        paddingLeft="12px"
                        paddingRight="8px"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        height="30px"
                        width="100%"
                        borderRadius="4px"
                        color="rgba(46, 46, 46, 1)"
                        name="frequency"
                        value={x.frequency}
                        onChange={(e) => vitalInChange(e, i)}
                        marginTop="8px"
                        style={
                          vitalinputListErr[i].frequencyErr
                            ? { border: "1px solid red", borderRadius: "4px" }
                            : {}
                        }
                      />
                      {vitalinputListErr[i].frequencyErr && (
                        <FormLabel
                          display="flex"
                          marginBottom="11px"
                          textAlign="left"
                          fontSize="10px"
                          color="red"
                          fontWeight="400"
                          fontFamily="'Open sans', sans-serif"
                          marginTop=" 4px"
                        >
                          Please Enter Frequency
                        </FormLabel>
                      )}
                      <Input
                        placeholder={x.notes ? x.notes : "Instructions"}
                        pColor="rgba(46, 46, 46, 0.5)"
                        fontSize="12px"
                        paddingLeft="12px"
                        paddingRight="8px"
                        color="rgba(46, 46, 46, 1)"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        height="31px"
                        width="100%"
                        borderRadius="4px"
                        name="notes"
                        value={x.notes}
                        onChange={(e) => vitalInChange(e, i)}
                        marginTop="8px"
                        style={
                          vitalinputListErr[i].notesErr
                            ? { border: "1px solid red", borderRadius: "4px" }
                            : {}
                        }
                      />
                      {vitalinputListErr[i].notesErr && (
                        <FormLabel
                          display="flex"
                          marginBottom="11px"
                          textAlign="left"
                          fontSize="10px"
                          color="red"
                          fontWeight="400"
                          fontFamily="'Open sans', sans-serif"
                          marginTop=" 4px"
                        >
                          Please Enter Instructions
                        </FormLabel>
                      )}
                    </MonitorDiv>
                    <Div margin="6px 0 0 8px">
                      <AiOutlinePlus
                        onClick={handleAddClick}
                        style={{ cursor: "pointer", color: "#2C7BE5" }}
                      />
                      {vitalinputList.length !== 1 && (
                        <FiTrash2
                          style={{
                            fill: "#ffffff",
                            stroke: "#f24822",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveClick(i, x.vital)}
                        />
                      )}
                    </Div>
                  </Div>
                ))}
              </Div>
            </Divresponsive>
            <Divresponsive width="50%" float="right">
              <Div>
                <FormLabel
                  fontSize="14px"
                  color="#2E2E2E"
                  fontFamily="'Open Sans', sans-serif"
                  lineHeight="16px"
                  fontWeight="600"
                  display="block"
                  marginBottom="4px"
                  marginTop="12px"
                >
                  Medication
                </FormLabel>
                {medicationinputList.map((x, i) => (
                  <Div display="flex" margin="0 0 16px 0">
                    <MonitorDiv className="meddiv">
                      <Div
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <Div width="50%" position="relative">
                          <SearchIconSVG top="7px">
                            <AiOutlineSearch />
                          </SearchIconSVG>
                          <Input
                            placeholder="Medication"
                            name="medicine"
                            pColor="rgba(46, 46, 46, 0.5)"
                            fontSize="12px"
                            color="rgba(46, 46, 46, 1)"
                            paddingLeft="28px"
                            paddingRight="10px"
                            border={
                              medicationinputListErr[i].medicineErr
                                ? "1px solid red"
                                : "1px solid rgba(46, 46, 46, 0.25)"
                            }
                            height="30px"
                            width="100%"
                            borderRadius="4px"
                            value={
                              x.medicine !== ""
                                ? x.medicine
                                : ShowOption3 === i
                                ? searchMedic
                                : ""
                            }
                            onChange={(e) => handleInputChange1(e, i)}
                          />
                          {medicationinputListErr[i].medicineErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop=" 4px"
                            >
                              Invalid Medicine
                            </FormLabel>
                          )}
                          {ShowOption3 === i &&
                          (loading || medicinelist.length >= 0) ? (
                            <div>
                              <DropDownDiv
                                width="100%"
                                background="#FFFFFF"
                                border="1px solid rgba(46, 46, 46, 0.25)"
                                borderRadius="4px"
                                top="35px"
                                maxHeight="256px"
                                overflowY="hidden"
                              >
                                {loading ? (
                                  <Text
                                    padding="10px 12px 8px 12px"
                                    fontSize="15px"
                                  >
                                    Loading...
                                  </Text>
                                ) : medicinelist.length > 0 ? (
                                  medicinelist.map((option) => (
                                    <DropdownOptions
                                      key={option.id}
                                      name="medicine"
                                      padding="10px 12px 8px 12px"
                                      fontSize="15px"
                                      fontFamily="sans seriff"
                                      onClick={(e) =>
                                        MedicationSelected(e, option, i)
                                      }
                                    >
                                      {option}
                                    </DropdownOptions>
                                  ))
                                ) : (
                                  <Text
                                    padding="10px 12px 8px 12px"
                                    fontSize="15px"
                                  >
                                    No results found
                                  </Text>
                                )}
                              </DropDownDiv>
                            </div>
                          ) : null}
                        </Div>
                        <Div width="50%">
                          <Input
                            placeholder={
                              x.frequency ? x.frequency : "Frequency"
                            }
                            pColor="rgba(46, 46, 46, 0.5)"
                            fontSize="12px"
                            color="rgba(46, 46, 46, 1)"
                            paddingLeft="12px"
                            paddingRight="10px"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            height="30px"
                            width="100%"
                            borderRadius="4px"
                            name="frequency"
                            value={x.frequency}
                            onChange={(e) => medicationChange(e, i)}
                            style={
                              medicationinputListErr[i].frequencyErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "4px",
                                  }
                                : {}
                            }
                            // marginTop="8px"
                          />
                          {medicationinputListErr[i].frequencyErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop=" 4px"
                            >
                              Please Enter Frequency
                            </FormLabel>
                          )}
                        </Div>
                      </Div>
                      <Input
                        placeholder={x.notes ? x.notes : "Instructions"}
                        pColor="rgba(46, 46, 46, 0.5)"
                        fontSize="12px"
                        paddingLeft="12px"
                        paddingRight="10px"
                        color="rgba(46, 46, 46, 1)"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        height="31px"
                        width="100%"
                        borderRadius="4px"
                        name="notes"
                        value={x.notes}
                        onChange={(e) => medicationChange(e, i)}
                        marginTop="8px"
                        style={
                          medicationinputListErr[i].notesErr
                            ? { border: "1px solid red", borderRadius: "4px" }
                            : {}
                        }
                      />
                      {medicationinputListErr[i].notesErr && (
                        <FormLabel
                          display="flex"
                          marginBottom="11px"
                          textAlign="left"
                          fontSize="10px"
                          color="red"
                          fontWeight="400"
                          fontFamily="'Open sans', sans-serif"
                          marginTop=" 4px"
                        >
                          Please Enter Instructions
                        </FormLabel>
                      )}
                    </MonitorDiv>
                    <Div margin="6px 0 0 8px">
                      <AiOutlinePlus
                        onClick={handleMedicationClick}
                        style={{ cursor: "pointer", color: "#2C7BE5" }}
                      />
                      {medicationinputList.length !== 1 && (
                        <FiTrash2
                          style={{
                            fill: "#ffffff",
                            stroke: "#f24822",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => handlemedicationRemClick(i)}
                        />
                      )}
                    </Div>
                  </Div>
                ))}
                <FormLabel
                  fontSize="14px"
                  color="#2E2E2E"
                  fontFamily="'Open Sans', sans-serif"
                  lineHeight="16px"
                  fontWeight="600"
                  marginBottom="4px"
                  display="block"
                >
                  Careteam Information
                </FormLabel>
                {careTeamList.map((x, i) => (
                  <Div display="flex" margin="0 0 16px 0">
                    <MonitorDiv className="meddiv">
                      <Div
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <Div width="50%" position="relative">
                          <SearchIconSVG top="7px">
                            <AiOutlineSearch />
                          </SearchIconSVG>
                          <Input
                            placeholder={x.provider ? x.provider : "Provider"}
                            pColor="rgba(46, 46, 46, 0.5)"
                            fontSize="12px"
                            color="rgba(46, 46, 46, 1)"
                            paddingLeft="28px"
                            paddingRight="10px"
                            height="31px"
                            width="100%"
                            borderRadius="4px"
                            name="Provider"
                            border={
                              careTeamListErr[i].providerErr
                                ? "1px solid red"
                                : "1px solid rgba(46, 46, 46, 0.25)"
                            }
                            // value={x.provider}
                            value={
                              x.provider !== ""
                                ? x.provider
                                : isDropdownVisible2 === i
                                ? searchTerm2
                                : ""
                            }
                            onChange={(e) => handleProviderChange(e, i)}
                          />
                          {careTeamListErr[i].providerErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop=" 4px"
                            >
                              Select Provider
                            </FormLabel>
                          )}
                          {isDropdownVisible2 === i &&
                          (loading || list1.length >= 0) ? (
                            <div ref={searchDropdown}>
                              <DropDownDiv
                                width="100%"
                                background="#FFFFFF"
                                border="1px solid rgba(46, 46, 46, 0.25)"
                                borderRadius="4px"
                                top="35px"
                                maxHeight="256px"
                                overflowY="hidden"
                              >
                                {loading ? (
                                  <Text
                                    padding="10px 12px 8px 12px"
                                    fontSize="15px"
                                  >
                                    Loading...
                                  </Text>
                                ) : list1.length > 0 ? (
                                  list1.map((option) => (
                                    <DropdownOptions
                                      key={option.id}
                                      padding="10px 12px 8px 12px"
                                      fontSize="12px"
                                      fontFamily="sans seriff"
                                      name="provider"
                                      onClick={(e) =>
                                        providerSelect(e, option, i)
                                      }
                                    >
                                      {option.fname} {option.lname}
                                    </DropdownOptions>
                                  ))
                                ) : (
                                  <Text
                                    padding="10px 12px 8px 12px"
                                    fontSize="15px"
                                  >
                                    No results found
                                  </Text>
                                )}
                              </DropDownDiv>
                            </div>
                          ) : null}
                        </Div>

                        <Div
                          position="relative"
                          // ref={vitalsRef}
                          id="specility"
                          width="50%"
                        >
                          <Div
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            borderRadius="4px"
                            style={
                              careTeamListErr[i].specialtyErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "4px",
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (ShowOptions2 === i) {
                                setShowOptions2();
                              } else {
                                setShowOptions2(i);
                              }
                            }}
                          >
                            <SelectInput
                              className="detailinput"
                              borderRadius="4px"
                              placeholder="'Speciality'"
                              height="29px"
                              background="#FFFFFF"
                              fontSize="12px"
                              name="specialty"
                              fontFamily="'Open Sans',sans-serif"
                              padding="6px 12px 0px 10px"
                              color="rgba(46, 46, 46, 1)"
                              maxWidth="72%"
                              overFlow="hidden"
                            >
                              {specDropdownOptionList.map((row) => {
                                if (x.specialty == row.id) {
                                  return (
                                    <span
                                      style={{
                                        maxWidth: "50px",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {row.label}
                                    </span>
                                  );
                                }
                              })}
                            </SelectInput>
                            <DropdownImage
                              height="8px"
                              width="12px"
                              position="absolute"
                              top="42px"
                              right="10px"
                              marginTop="-28px"
                              src={DropdownDownicon}
                            ></DropdownImage>

                            {ShowOptions2 === i &&
                            specDropdownOptionList.length > 0 ? (
                              <DropDownDiv
                                margin="0 0 0 0"
                                id="specility"
                                width="100%"
                                background="#FFFFFF"
                                border="1px solid rgba(46, 46, 46, 0.25)"
                                borderRadius="3.13433px"
                                maxHeight="146px"
                                overflowY="auto"
                                overflowX="hidden"
                                // marginTop="64px"
                              >
                                {specDropdownOptionList.map((r, ind) => {
                                  return (
                                    <DropdownOptions
                                      key={ind}
                                      id="specility"
                                      value={r.id}
                                      name="specialty"
                                      padding="7px 12px 7px 12px"
                                      onClick={(e) => specDropDownChange(e, i)}
                                      fontSize="12px"
                                      color="rgba(46, 46, 46, 1)"
                                      disabled={r.disabled}
                                    >
                                      {r.label}
                                    </DropdownOptions>
                                  );
                                })}
                              </DropDownDiv>
                            ) : (
                              ""
                            )}
                          </Div>
                          {careTeamListErr[i].specialtyErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop=" 4px"
                            >
                              Please Enter Speciality
                            </FormLabel>
                          )}
                        </Div>
                      </Div>
                      <Div
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <Div width="50%">
                          <Input
                            placeholder={
                              x.phoneNumber ? x.phoneNumber : "Phone number"
                            }
                            pColor="rgba(46, 46, 46, 0.5)"
                            fontSize="12px"
                            paddingLeft="12px"
                            paddingRight="8px"
                            color="rgba(46, 46, 46, 1)"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            height="30px"
                            width="100%"
                            borderRadius="4px"
                            name="phoneNumber"
                            value={x.phoneNumber}
                            cursor="not-allowed"
                            background="#FFFFFF"
                            disabled={true}
                            // onChange={(e) => CareTeamChange(e, i)}
                            marginTop="8px"
                            style={
                              careTeamListErr[i].phoneNumberErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "4px",
                                  }
                                : {}
                            }
                          />
                          {careTeamListErr[i].phoneNumberErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop="4px"
                            >
                              Please Enter PhoneNumber
                            </FormLabel>
                          )}
                        </Div>
                        <Div width="50%">
                          <Input
                            placeholder={x.Email ? x.Email : "Email"}
                            pColor="rgba(46, 46, 46, 0.5)"
                            fontSize="12px"
                            color="rgba(46, 46, 46, 1)"
                            paddingLeft="12px"
                            paddingRight="8px"
                            border="1px solid rgba(46, 46, 46, 0.25)"
                            height="30px"
                            width="100%"
                            borderRadius="4px"
                            name="Email"
                            value={x.Email}
                            cursor="not-allowed"
                            disabled={true}
                            background="#FFFFFF"
                            // onChange={(e) => CareTeamChange(e, i)}
                            marginTop="8px"
                            style={
                              careTeamListErr[i].EmailErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "4px",
                                  }
                                : {}
                            }
                          />
                          {careTeamListErr[i].EmailErr && (
                            <FormLabel
                              display="flex"
                              marginBottom="11px"
                              textAlign="left"
                              fontSize="10px"
                              color="red"
                              fontWeight="400"
                              fontFamily="'Open sans', sans-serif"
                              marginTop="4px"
                            >
                              Please Enter Email
                            </FormLabel>
                          )}
                        </Div>
                      </Div>
                    </MonitorDiv>
                    <Div margin="6px 0 0 8px">
                      <AiOutlinePlus
                        onClick={handleCareTeamClick}
                        style={{ cursor: "pointer", color: "#2C7BE5" }}
                      />
                      {careTeamList.length !== 1 && (
                        <FiTrash2
                          style={{
                            fill: "#ffffff",
                            stroke: "#f24822",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCareTeamRemClick(i, x.specialty)}
                        />
                      )}
                    </Div>
                  </Div>
                ))}
              </Div>
            </Divresponsive>
          </Div>
        </Div>
      </Div>
      <Div display="flex" justifyContent="space-between" marginTop="40px">
        <Button
          cursor="pointer"
          color="#2C7BE5"
          borderRadius="4px"
          background="#FFF"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="14px"
          padding="9px 42px 8px 42px"
          height="36px"
          marginRight="19px"
          display="flex"
          alignItems="center"
          border=" 1px solid  #2C7BE5"
          onClick={handleCancel}
          hoverBackColor="rgba(244, 246, 249, 0.75)"
          activeBackgroundColor="rgba(44, 123, 229, 0.10)"
        >
          Cancel
        </Button>
        <Div display="flex">
          <Button
            cursor="pointer"
            color="#2C7BE5"
            borderRadius="4px"
            background="#FFF"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14px"
            padding="9px 42px 8px 42px"
            height="36px"
            marginRight="19px"
            display="flex"
            alignItems="center"
            border=" 1px solid #2C7BE5"
            onClick={handleClear}
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            activeBackgroundColor="rgba(44, 123, 229, 0.10)"
          >
            Clear
          </Button>
          <Button
            cursor="pointer"
            color="#FFFFFF"
            border="none"
            title={Details.title === "" ? "please choose plan name" : ""}
            borderRadius="4px"
            background="#2C7BE5"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14px"
            padding="9px 42px 8px 42px"
            height="36px"
            display="flex"
            alignItems="center"
            disabled={dis}
            isDisabled={dis}
            onClick={handleSave}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Save
          </Button>
        </Div>
      </Div>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default AddTreatmentPlan;
