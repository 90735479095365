import React, { useState, useEffect, useRef, memo } from "react";
import { AiFillEdit, AiOutlinePlus } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import Threedots from "../../../Assets/images/Threedots.png";
import usercancel from "../../../Assets/images/usercancel.png";
import useradd from "../../../Assets/images/useradd.png";
import customDropdownImage from "../../../Assets/images/customDropdownImage.png";
import {
  SpanTag,
  Div,
  Button,
  CustomDropdownImage,
  Modal,
  Text,
  Image,
} from "../../StyledComponents";
import {
  CustomSelectWrapper,
  CustomSelectOptions,
  CustomSelectOption,
  EnrollModal,
} from "./styles";

import EditPatDemo from "../../Popup/AddPatient";
import { useDispatch, useSelector } from "react-redux";
import {
  GetEncounterId,
  GetEncounterOpitons,
  SetChartviewIdempty,
  setEditEncounter,
} from "../../../StateManagement/Reducers/EncounterState";
import UnenrollPatient from "./UnenrollPatient";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { SetUnenrollresponse } from "../../../StateManagement/Reducers/PatientState";
import { SetpatientDemotimer } from "../../../StateManagement/Reducers/PatientDemoState";

const PatientDemoHead = (props) => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const patId = params.pid;
  const selectRef = useRef();
  const enrollRef = useRef();
  const unenrollRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [details, setDetails] = useState([]);
  const [showEditPatDemo, setShowEditPatDemo] = useState(false);
  const [unenroll, setUnEnroll] = useState(false);
  const [enroll, setEnroll] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ----------------------------------------- Functions Start--------------------------------------------------------- //

  const toggleOptions = () => {
    if (permissions.includes("encounter_visit")) {
      setLoading(true);
      dispatch(GetEncounterOpitons(patId));
      setShowOptions(!showOptions);
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  const chartTimerEmpty = () => {
    setTimeout(() => {
      dispatch(SetChartviewIdempty());
      dispatch(
        SetpatientDemotimer({
          patId: "",
          timer: "",
        })
      );
    }, 1000);
  };

  const handleShowEncounter = () => {
    chartTimerEmpty();
    navigate(`/Encounter/VisitEncounter/${patId}`);
  };
  const handleShowEncounter1 = () => {
    if (permissions.includes("encounter_add")) {
      chartTimerEmpty();
      navigate(`/Encounter/Encounterfrom/${patId}`);
      dispatch(setEditEncounter({}));
    } else {
      denied_access();
    }
  };
  const openEditPatDemo = () => {
    if (permissions.includes("pat_update")) {
      if (SingPatDetails.enrollment_status !== "4") {
        setShowEditPatDemo(true);
      }
    } else {
      denied_access();
    }
  };

  const closeEditPatDemo = () => {
    setShowEditPatDemo(false);
  };

  const EncounterOptions = useSelector(
    (state) => state.Encounter.EncounterOptions
  );
  const Unenrollresponse = useSelector(
    (state) => state.Patient.unenrollmentupdate
  );

  const handleOptionClick = (patId, encounterId) => {
    localStorage.setItem("encounter", Encrypt_Value(encounterId, "vozorpm"));

    dispatch(GetEncounterId(encounterId));
    setShowOptions(false);
    chartTimerEmpty();
    navigate(`/Encounter/${patId}`);
  };
  const handleEncounter = (value) => {
    if (permissions.includes("pat_enroll")) {
      if (value === "3") {
        if (unenroll === false) {
          setUnEnroll(true);
        } else {
          setUnEnroll(false);
        }
      } else {
        if (enroll === false) {
          setEnroll(true);
        } else {
          setEnroll(false);
        }
      }
    }
  };

  const handleopen = () => {
    setShow(true);
    setUnEnroll(false);
  };
  const handleEnroll = () => {
    navigate(`/PatientConsent/${patId}`);
    setEnroll(false);
  };

  const lockClose = () => {
    setShow(false);
  };

  const denied_access = () => {
    setModalMsg("Permission Denied");
    setStatus("Failed");
    setModalAlerShow(true);
    setTimeout(() => {
      setModalAlerShow(false);
      setStatus("");
      setModalMsg("");
    }, 1500);
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // useEffect(() => {
  //   dispatch(GetEncounterOpitons(patId));
  // }, [patId]);

  useEffect(() => {
    if (EncounterOptions.length >= 0) {
      setLoading(false);
    }
  }, [EncounterOptions]);
  useEffect(() => {
    setDetails(SingPatDetails);
  }, [SingPatDetails, unenroll, enroll]);
  useEffect(() => {
    const handleOutsideClick = async (event) => {
      const container = unenrollRef.current;
      if (container && !container.contains(event.target)) {
        if (unenroll === true) {
          setUnEnroll(false);
          event.stopPropagation();
        }
      }
      const container1 = enrollRef.current;
      if (container1 && !container1.contains(event.target)) {
        if (enroll === true) {
          setEnroll(false);
          event.stopPropagation();
        }
      }
    };

    document.addEventListener("click", handleOutsideClick, true);

    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [unenroll, enroll]);
  useEffect(() => {
    if (Unenrollresponse !== "") {
      if (Unenrollresponse === "1") {
        setModalMsg("Patient has been unenrolled");
        setModalAlerShow(true);
        setStatus("success");
        setTimeout(() => {
          setModalAlerShow(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
      }
    }
    dispatch(SetUnenrollresponse(""));
  }, [Unenrollresponse]);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <Div display="flex" alignItems="center" marginBottom="43px">
      <Div display="flex" alignItems="center">
        <SpanTag
          paddingRight="11px"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="18px"
          color="#2E2E2E"
        >
          {props.PatinetInfo ? props.PatinetInfo : "Patient Demographics"}
        </SpanTag>

        <AiFillEdit
          style={{
            width: "1em",
            height: "1em",
            fill: "blue",
            cursor:
              SingPatDetails.enrollment_status !== "4"
                ? "pointer"
                : "not-allowed",
          }}
          onClick={openEditPatDemo}
        />
      </Div>
      <Div marginLeft="auto" display="flex">
        <Button
          padding="8px"
          background="#2C7BE5"
          color="#FFFFFF"
          border="none"
          borderRadius="4px"
          marginRight="2px"
          display="flex"
          alignItems="center"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="14px"
          onClick={handleShowEncounter}
          cursor="pointer"
          backgroundColor="#2C7BE5"
          hoverBackColor="#005FB2"
          activeBackgroundColor="rgba(44, 123, 229, 0.75)"
        >
          Visit Encounter
        </Button>

        <CustomSelectWrapper marginRight="24px" ref={selectRef}>
          <Button
            onClick={toggleOptions}
            background="#2C7BE5"
            border="none"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            hoverborderColor="#005FB2"
            padding="8px"
            width="36px"
            height="36px"
            cursor={
              permissions.includes("encounter_visit")
                ? "pointer"
                : "not-allowed"
            }
            backgroundColor="#2C7BE5"
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            <CustomDropdownImage
              src={customDropdownImage}
              alt="Custom Dropdown Image"
            />
          </Button>
          <CustomSelectOptions
            show={showOptions}
            maxHeight="200px"
            overflowY="auto"
          >
            {loading ? (
              <Text padding="10px 12px 8px 12px" fontSize="15px">
                Loading...
              </Text>
            ) : EncounterOptions.length > 0 ? (
              EncounterOptions.map((option, index) => (
                <CustomSelectOption
                  key={index}
                  onClick={() => handleOptionClick(patId, option.encounterId)}
                >
                  {option.date}
                </CustomSelectOption>
              ))
            ) : (
              <Text padding="10px 12px 8px 12px" fontSize="15px">
                No results found
              </Text>
            )}
          </CustomSelectOptions>
        </CustomSelectWrapper>

        <Button
          padding="8px"
          background="#2C7BE5"
          color="#FFFFFF"
          border="none"
          borderRadius="4px"
          display="flex"
          alignItems="center"
          className="plus_symbol"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="14px"
          cursor={
            details?.enrollment_status === "4" ? "not-allowed" : "pointer"
          }
          onClick={details?.enrollment_status !== "4" && handleShowEncounter1}
          backgroundColor="#2C7BE5"
          hoverBackColor="#005FB2"
          activeBackgroundColor="rgba(44, 123, 229, 0.75)"
        >
          <AiOutlinePlus fontSize="15px" />
          Add Encounter
        </Button>
        <Button
          background="#FFFFFF"
          border="#FFFFFF"
          margin="0px 6px 0 16px"
          onClick={(e) => handleEncounter(details.enrollment_status)}
          cursor={
            permissions.includes("pat_enroll") ? "pointer" : "not-allowed"
          }
        >
          <Image width="34px" src={Threedots} alt="threedots image"></Image>
        </Button>
      </Div>
      {showEditPatDemo && (
        <Modal
          show={true}
          id="modal1"
          justifyContent="center"
          backgroundColor=" rgba(0, 0, 0, 0.5)"
          width="100%"
          height="100%"
        >
          <div id="patdemomodal">
            <EditPatDemo patId={patId} onClose={closeEditPatDemo} />
          </div>
        </Modal>
      )}
      {unenroll && details.enrollment_status === "3" && (
        <EnrollModal
          ref={unenrollRef}
          show={true}
          right="36px"
          top="143px"
          borderRadius="8px"
          border="1px solid rgba(46, 46, 46, 0.25)"
          backgroundColor="#ffffff"
          position="absolute"
        >
          <Button
            padding="14px 23px 18px 14px"
            width="176px"
            backgroundColor="#ffffff"
            border="none"
            borderRadius="8px"
            onClick={handleopen}
            cursor="pointer"
          >
            <Div
              color="#2E2E2E"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={usercancel} alt="usercancel Image" />
              <SpanTag
                marginLeft="8px"
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                fontSize="14px"
              >
                Unenroll Patient
              </SpanTag>
            </Div>
          </Button>
        </EnrollModal>
      )}
      {enroll && details.enrollment_status === "4" && (
        <EnrollModal
          ref={enrollRef}
          show={true}
          right="36px"
          top="143px"
          borderRadius="8px"
          border="1px solid rgba(46, 46, 46, 0.25)"
          backgroundColor="#ffffff"
          position="absolute"
        >
          <Button
            padding="15px 21px 17px 14px"
            width="176px"
            backgroundColor="#ffffff"
            border="none"
            borderRadius="8px"
            cursor="pointer"
            onClick={handleEnroll}
          >
            <Div
              color="#2E2E2E"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={useradd} alt="useradd Image" />
              <SpanTag
                marginLeft="8px"
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                fontSize="14px"
              >
                Re-Enroll Patient
              </SpanTag>
            </Div>
          </Button>
        </EnrollModal>
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
      <UnenrollPatient show={show} onHide={lockClose} />
    </Div>
  );
};

export default memo(PatientDemoHead);
