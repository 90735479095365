import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { KeyIcon, ForgotDiv } from "../Styles";
import { ArrowBack } from "../../TopNav/Chat/styles";
import { GetReEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import {
  Container,
  PageTitle,
  PageSubTitle,
  FormGroup,
  Div,
  Span,
  Image,
  ImageContainer,
} from "../../StyledComponents";
import { FiMail } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";

const Emailcheck = () => {
  // ------------------------------ UseState and Variables Start-----------------------------------------------------------//
  const { state } = useLocation();
  const [mail] = useState(state ? state.type.mail : "");
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [disBtn, setDisBtn] = useState(false);
  const resendResponse = useSelector((state) => state.PreLogin.ReEmailResp);

  const fullLink = window.location.href;
  let linkArray = fullLink.split("/");
  linkArray.splice(-1, 1, "setnewpassword");

  let DBHost = "";
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  const rpm_image = finalurl + "/sites/" + DBHost + "/images/rpm_logo.png";
  // ------------------------------ UseState and Variables Start-----------------------------------------------------------//
  const habdleBacktoLogin = () => {
    Navigate("/");
  };

  const EmailValidation = (e) => {
    e.preventDefault();
    setDisBtn(true);

    dispatch(
      GetReEmailRes({ type: { email: mail, link: linkArray.join("/") } })
    );
  };

  useEffect(() => {
    if (resendResponse.status === "success") {
      setModalAlerShow(true);
      setModalMsg("Mail resend successfully to your mail address");
      setStatus("success");

      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
        setDisBtn(false);
      }, 3000);
    }
  }, [resendResponse]);
  return (
    <>
      <Container backgroundColor="gray">
        <ImageContainer textAlign="center" backgroundColor="#ffff">
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            marginTop="72px"
            marginBottom="10px"
            textAlign="center"
          />
        </ImageContainer>
        <ForgotDiv
          className="adjust-forgot"
          position="absolute"
          maxWidth="483px"
          top="0"
          right="0"
          bottom="0"
          left="0"
          height="max-content"
          backgroundColor="#ffff"
          borderRadius="16px"
          boxShadow="0px 6px 58px rgba(196, 203, 214, 0.103611)"
          padding="40px 40px 45px 40px"
          margin="150px auto"
        >
          <KeyIcon marginLeft="148px">
            <FiMail />
          </KeyIcon>
          <FormGroup position="relative">
            <PageTitle
              textAlign="center"
              fontFamily="'Open Sans',sans-serif"
              fontWeight="500"
              fontSize="24px"
              color="#2D3748"
            >
              Check Your Email
            </PageTitle>
            <PageSubTitle
              textAlign="center"
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400"
              fontSize="16px"
              lineHeight="19px"
              color="#718096"
              marginTop="8px"
              marginBottom="13px"
              align="center"
            >
              We sent a password reset link to
            </PageSubTitle>

            <Div
              display="flex"
              justifyContent={mail === "" ? "end" : "center"}
              alignItems="center"
              background=" #F9F8F8"
              border="none"
              borderRadius="4px"
            >
              <Div color="#7D8592 !important" fontSize="17px">
                <FaRegUser />
              </Div>
              <Div
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontFamily="'Open Sans',sans-serif"
                align="left"
                color="#7D8592 !important"
                fontSize="14px"
                lineHeight="17px"
                background=" #F9F8F8"
                padding="11px !important"
                border="none"
              >
                {mail}
              </Div>
            </Div>
            <Div
              display="flex"
              justifyContent="center"
              textAlign="center"
              fontFamily="'Open Sans',sans-serif"
              marginTop="31px"
              fontWeight="600"
              fontSize="16px"
              color="#667085"
              marginBottom="10px"
            >
              Didn't receiver the email?
              <Span
                textAlign="center"
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="#3F8CFF"
                marginLeft="5px"
                cursor={disBtn ? "not-allowed" : "pointer"}
                onClick={disBtn ? "" : EmailValidation}
              >
                Click to resend
              </Span>
            </Div>

            <PageSubTitle
              textAlign="center"
              align="center"
              fontFamily="'Open Sans',sans-serif"
              color="#3F8CFF"
              fontWeight="400"
              fontSize="16px"
              lineHeight="22px"
              backgroundColor="#FFFFFF"
              height="48px"
              marginTop="23px"
              onClick={habdleBacktoLogin}
              cursor="pointer"
            >
              <ArrowBack
                marginRight="7px !important"
                marginBottom="-4px !important"
              />
              Back to login
            </PageSubTitle>
          </FormGroup>
        </ForgotDiv>
      </Container>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default Emailcheck;
