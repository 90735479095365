import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import LoginReducer from "./Reducers/LoginState";
import DashboardReducer from "./Reducers/DashboardState";
import PatientReducer from "./Reducers/PatientState";
import HealthReducer from "./Reducers/HealthState";
import MedicalReducer from "./Reducers/MedicalState";
import MessengerReducer from "./Reducers/MessengerState";
import PatientDemoReducer from "./Reducers/PatientDemoState";
import DropdownListReducer from "./Reducers/DropDownList";
import PreLoginReducer from "./Reducers/PreLoginState";
import LiveReducer from "./Reducers/LiveState";
import SettingReducer from "./Reducers/SettingState";
import EncounterReducer from "./Reducers/EncounterState";
import DeviceReducer from "./Reducers/DevicesState";
import RootSaga from "../MiddleWare";
import TreatmentPlanReducer from "./Reducers/TreatmentPlanState";
const Saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    Login: LoginReducer,
    Dashboard: DashboardReducer,
    Patient: PatientReducer,
    Health: HealthReducer,
    Medical: MedicalReducer,
    Messenger: MessengerReducer,
    PatientDemo: PatientDemoReducer,
    DropdownList: DropdownListReducer,
    PreLogin: PreLoginReducer,
    Setting: SettingReducer,
    Encounter: EncounterReducer,
    Live: LiveReducer,
    Device: DeviceReducer,
    TreatmentPlan: TreatmentPlanReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(Saga),
});

Saga.run(RootSaga);

export default store;
