import React, { useState } from "react";

import {
  Header,
  Title,
  Data,
  Row,
  Avatar,
  AvatarLetter,
  Image,
  Text,
  DropdownContainer,
} from "./styles";
import { SpanTag } from "../../StyledComponents";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import moment from "moment";
import DBHost from "../../../Config/DBHost";

const Messages = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------///

  const NotifiList = props.VitalsData;

  var Test_URL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  // ----------------------------State and Variables ---------------------------------------------------///

  // -------------------------- Functions Start-------------------------------------------------------------//
  const VitalsTypeShow = (row) => {
    if (row.VitalsType === "BloodPressure") {
      return row.bloodpressure + " mm Hg (Blood Pressure)";
    }
    if (row.VitalsType === "RPM") {
      return row.RPM + " RPM (Respiration)";
    }
    if (row.VitalsType === "HeartRate") {
      return row.pulse + " BPM (Heart rate)";
    }
    if (row.VitalsType === "oxygen") {
      return row.oxygen_saturation + "% (Oxygen)";
    }
    if (row.VitalsType === "BloodGlucoseLevel") {
      return row.BloodGlucoseLevel + " mg/dL (Glucose)";
    }
  };
  //-------------------------- Functions End-------------------------------------------------------------//

  return (
    <>
      <DropdownContainer ref={props.popupNotifiRef}>
        <Header>
          <Title> Notifications</Title>
        </Header>
        <Data height="457px" width="100%">
          {NotifiList.length > 0 ? (
            <>
              {NotifiList.map((row, ind) => (
                <Row
                  key={ind}
                  borderBottom="1px solid #cbd5e0"
                  dis="flex"
                  padding="8px 20px 8px 15px"
                  cursor="pointer"
                >
                  {row.img_url === null || row.img_url === undefined ? (
                    <Avatar
                      flex
                      width="32px"
                      height="32px"
                      border="1px solid #ffffff"
                    >
                      <AvatarLetter>
                        {row.PatName?.charAt(0).toUpperCase()}
                      </AvatarLetter>
                    </Avatar>
                  ) : (
                    <Image
                      src={Test_URL + row.img_url}
                      alt="image"
                      borderRadius="40px"
                      height="32px"
                      width="32px"
                      mr="12px"
                    />
                  )}
                  <Row
                    dis="flex"
                    flexDirection="column"
                    width="87%"
                    padding="0px 0px 0px 0px"
                  >
                    <Row dis="flex" justifyContent="space-between" width="100%">
                      <Text
                        fontFamily="'Open Sans', sans-serif"
                        fontSize="14px"
                        color="#2E2E2E"
                        lineHeight="19px"
                      >
                        {row.PatName}
                      </Text>
                      {row.currentTime && row.currentTime !== "" && (
                        <SpanTag
                          fontFamily="'Open Sans', sans-serif"
                          fontSize="10px"
                          lineHeight="16px"
                          fontWeight="400"
                          color="rgba(46, 46, 46, 0.75)"
                        >
                          {/* {moment(row.currentTime).format("YYYY-MM-DD hh:mm a")} */}
                          {moment(
                            row.currentTime,
                            "DD/MM/YYYY hh:mm:ss"
                          ).format("YYYY-MM-DD hh:mm a")}
                        </SpanTag>
                      )}
                    </Row>
                    <Row width="100%" dis="flex" marginTop="4px">
                      <Text
                        fontFamily="'Open Sans', sans-serif"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="16px"
                        color="#2E2E2E"
                        whiteSpace="nowrap"
                      >
                        Alert {row.type} Vital -
                      </Text>
                      <Text
                        fontFamily="'Open Sans', sans-serif"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="16px"
                        color={row.type === "Critical" ? "#B00020" : "#FFC700"}
                        whiteSpace="nowrap"
                      >
                        {VitalsTypeShow(row)}
                      </Text>
                    </Row>
                  </Row>
                </Row>
              ))}
            </>
          ) : (
            <Row height="455px" padding="100px 0px 0px 100px ">
              <Row padding="0px 0px 0px 50px">
                <Image height="80px" width="80px" src={Emp_data_pic}></Image>
              </Row>
              <Text
                fontSize="14px"
                color="#2E2E2E"
                fontFamily="'Open Sans',sans-serif"
                lineHeight="20px"
                fontWeight="400"
              >
                No Notifications to be shown.
              </Text>
            </Row>
          )}
        </Data>
      </DropdownContainer>
    </>
  );
};

export default Messages;
