import styled, { css } from "styled-components";
import {
  Div,
  Button,
  SelectInput,
  SpanTag,
} from "../../StyledComponents/index";

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

export const DropdownButton = styled.button`
  background-color: #ffffff;
  color: #2e2e2e;
  padding: 8px;
  border: none;
  cursor: pointer;
  border: 1px solid rgba(46, 46, 46, 0.25);
  width: ${(p) => p.width && p.width};
  display: ${(p) => p.display && p.display};
`;

export const DropdownContent = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  width: ${(p) => p.width && p.width};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  border-radius: 4px;
`;

export const DropdownItem = styled.a`
  background-color: white;
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
`;
export const Image1 = styled.img`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  color: ${(p) => p.color && p.color};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
`;
export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: absolute;
  right: ${(p) => p.right && p.right};
  top: 16px;
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;
export const DetailDiv = styled(Div)`
  flex-wrap: wrap;
  display: ${(p) => p.display && p.display};
  &.detailinputdiv {
    @media screen and (max-width: 479px) {
      margin-top: 10px;
    }
  }
`;
export const DetailDiv1 = styled(Div)`
  display: ${(p) => p.display && p.display};
  &.custompicker1 {
    @media screen and (max-width: 805px) {
      margin-top: 10px;
    }
  }
  &.custompicker {
    @media screen and (max-width: 619px) {
      margin-top: 10px;
    }
  }
`;
export const SpanTag1 = styled(SpanTag)`
  margin: 10px 8px 0px 8px;
  &.toviewmargin {
    @media screen and (max-width: 649px) {
      margin: 24px 8px 0px 8px;
    }
  }
`;
export const SelectDiv = styled(SelectInput)``;
export const DetailBtn = styled(Button)`
  &.detailupdatebtn {
    @media (max-width: 906px) {
      margin-top: 10px;
    }
  }
`;
export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;
