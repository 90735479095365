import { call, put } from "redux-saga/effects";
import { DrugApi, ICD10Api, ICD9Api, ProcedureApi } from "../Apis/MedicalApi";
import {
  SetDrugList,
  SetICD9List,
  SetProcedure,
} from "../../StateManagement/Reducers/MedicalState";

export function* DrugLists({ payload }) {
  let credential = payload;

  try {
    const res = yield call(DrugApi, credential);

    if (res.status === 200) {
      yield put(SetDrugList(res.data));
    }
  } catch (e) {
    yield put(SetDrugList([]));
    console.log(e.message);
  }
}
export function* ICD10Lists({ payload }) {
  let credential = payload;
  try {
    const res = yield call(ICD10Api, credential);
    if (res.status === 200) {
      yield put(SetICD9List(res.data));
    }
  } catch (e) {
    yield put(SetICD9List([]));
    console.log(e.message);
  }
}
export function* ICD9Lists({ payload }) {
  let credential = payload;
  try {
    const res = yield call(ICD9Api, credential);
    if (res.status === 200) {
      yield put(SetICD9List(res.data));
    }
  } catch (e) {
    yield put(SetICD9List([]));
    console.log(e.message);
  }
}
export function* ProcedureLists({ payload }) {
  let credential = payload;
  try {
    const res = yield call(ProcedureApi, credential);
    if (res.status === 200) {
      yield put(SetProcedure(res.data));
    }
  } catch (e) {
    yield put(SetProcedure([]));
    console.log(e.message);
  }
}
