import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  SetPatDetails,
  SetSinglePatDetail,
  setPatientSearchList,
  SetGenderList,
  SetRaceList,
  SetLanguageList,
  SetCardDetails,
  SetVitalsNotification,
  SetVitalsNotificationUpdate,
  setAddCardDetailRes,
  setUpdateCardDetailRes,
  setDeleteCardDetailRes,
  SetMailVerify,
  SetPhoneVerify,
  SetSSNVerify,
  setPatientUpdate,
  SetFeeListCollapse,
  SetFeeList,
  SetPatientEmail,
  SetUnenrollmentupdate,
  SetPlanNameResponse,
  SetIcd10Codes,
  SetPlanDetails,
  setProviderSearchList,
  setExportStatus,
  setPatientImport,
} from "../../StateManagement/Reducers/PatientState";
import {
  GetPatientDetails_Api,
  GetSinglePatientDetail_Api,
  PatientApi,
  PatientEnrollemt_Api,
  PatientEnrollemtUpdate_Api,
  PatientStatusList_Api,
  AddCardDetail_Api,
  CardDetails_Api,
  RemoveCardDetail_Api,
  EditCardDetail_Api,
  VitalsNotificationDetails_Api,
  VitalsNotificationDetailsUpdate_Api,
  Image_Api,
  MailVerify_Api,
  FeeSheetCollapse_Api,
  FeeSheetCollapseDelete_Api,
  FeeSheet_Api,
  FeeSheetDelete_Api,
  PatientEmailApi,
  UnenrollUpdateApi,
  PlanNameCheckApi,
  TreatmentlanInsert,
  GetTreatmentlan,
  UpdateTreatment,
  ProviderApi,
  exportDataApi,
  PatientImportApi,
} from "../Apis/PatientApi";
import {
  setEncounterId,
  setEncounterSummary,
} from "../../StateManagement/Reducers/EncounterState";
import { DeviceListApi } from "../Apis/PatientDemo";
import { setDeviceList } from "../../StateManagement/Reducers/PatientDemoState";
import { ICD10Api } from "../Apis/MedicalApi";
export function* AddCardDetailApi({ payload }) {
  try {
    const res = yield call(AddCardDetail_Api, payload);
    if (res.status === 200) {
      yield put(setAddCardDetailRes(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* setAddCardEmptyResponse() {
  yield put(setAddCardDetailRes(""));
}

export function* setUpdateCardEmptyResponse() {
  yield put(setUpdateCardDetailRes(""));
}
export function* setDeleteCardEmptyResponse() {
  yield put(setDeleteCardDetailRes(""));
}

export function* CardDetailsApi({ payload }) {
  try {
    const res = yield call(CardDetails_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoRPM");
      yield put(SetCardDetails(Data[0]));
    }
  } catch (e) {
    yield put(SetCardDetails([]));
    console.log(e.message);
  }
}

export function* RemoveCardDetailApi({ payload }) {
  try {
    const res = yield call(RemoveCardDetail_Api, payload);
    if (res.status === 200) {
      if (res.data.replace(/\s+/g, "") === "success") res.data = "success";

      yield put(setDeleteCardDetailRes(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* EditCardDetailApi({ payload }) {
  try {
    const res = yield call(EditCardDetail_Api, payload);
    if (res.status === 200) {
      if (res.data.replace(/\s+/g, "") === "success") res.data = "success";
      yield put(setUpdateCardDetailRes(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* VitalsNotificationDetailsApi({ payload }) {
  try {
    const res = yield call(VitalsNotificationDetails_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoRPM");
      yield put(SetVitalsNotification(Data));
    }
  } catch (e) {
    yield put(SetVitalsNotification([]));
    console.log(e.message);
  }
}
export function* VitalsNotificationDetailsUpdateApi({ payload }) {
  try {
    const res = yield call(VitalsNotificationDetailsUpdate_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoRPM");
      yield put(SetVitalsNotificationUpdate(Data));
    }
  } catch (e) {
    yield put(SetVitalsNotificationUpdate([]));
    console.log(e.message);
  }
}

export function* getPatientList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    yield put(setEncounterId(""));
    const res = yield call(GetPatientDetails_Api, requiredData);

    if (res.status === 200) {
      // if (res.data.length !== 0) {
      const Data = Decrypt_Value(res.data, "vozorpm");

      yield put(SetPatDetails(Data));
      // } else {
      //   yield put(SetPatDetails([]));
      // }
    }
  } catch (e) {
    console.log(e.message);
    SetPatDetails([]);
  }
}

export function* getSinglePatientList({ payload }) {
  try {
    const res = yield call(GetSinglePatientDetail_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      localStorage.setItem(
        "encounter",
        Encrypt_Value(Data.encounter ? Data.encounter : 0, "vozorpm")
      );
      yield put(SetSinglePatDetail(Data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetSinglePatDetail([]));
  }
}

export function* PatientCheck({ payload }) {
  const credential = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(PatientApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setPatientSearchList(Data));
    }
  } catch (e) {
    yield put(setPatientSearchList([]));
    console.log(e.message);
  }
}
export function* patEnrollemtData({ payload }) {
  let data = payload[0];
  const credential = Encrypt_Value(data.article, "vozorpm");
  try {
    const res = yield call(PatientEnrollemt_Api, credential);
    if (res.status === 200) {
      let getId = res.data;
      let getPid = getId.length !== 0 ? getId : payload[2];
      if (payload[1] && payload[1] !== "" && payload[1] !== null) {
        yield call(Image_Api, [getPid, data.file]);
      }
      try {
        const res1 = yield call(GetSinglePatientDetail_Api, getPid);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          yield put(SetSinglePatDetail(Data));
          yield put(setPatientUpdate("1"));
        }
      } catch (e) {
        console.log(e.message);
        yield put(setPatientUpdate(""));
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(setPatientUpdate(""));
  }
}

export function* EnrollmentUpdate({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(PatientEnrollemtUpdate_Api, encryptedData);
    if (res.status === 200) {
      return "success";
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* GenderList({ payload }) {
  try {
    const res = yield call(PatientStatusList_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetGenderList(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* RaceList({ payload }) {
  try {
    const res = yield call(PatientStatusList_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetRaceList(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* LanguageList({ payload }) {
  try {
    const res = yield call(PatientStatusList_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetLanguageList(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* MailVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetMailVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PhoneVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPhoneVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* SSNVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSSNVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* setPatUpdateResEmpty() {
  yield put(setPatientUpdate(""));
}
export function* FeeSheetList({ payload }) {
  try {
    const res = yield call(FeeSheet_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetFeeList(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetFeeList([]));
  }
}

export function* FeeSheetListCollapse({ payload }) {
  try {
    const res = yield call(FeeSheetCollapse_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");

      yield put(SetFeeListCollapse(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetFeeListCollapse([]));
  }
}

export function* FeeSheetListDelete({ payload }) {
  try {
    const res = yield call(FeeSheetDelete_Api, payload);
    if (res.status === 200) {
      const res = yield call(FeeSheet_Api, payload.pid);
      if (res.status === 200) {
        const DecryptedData = Decrypt_Value(res.data, "vozorpm");
        yield put(SetFeeList(DecryptedData));
      }

      const res2 = yield call(GetSinglePatientDetail_Api, payload.pid);
      if (res2.status === 200) {
        const Data = Decrypt_Value(res2.data, "vozorpm");
        localStorage.setItem(
          "encounter",
          Encrypt_Value(Data.encounter ? Data.encounter : 0, "vozorpm")
        );
        yield put(setEncounterId(Data.encounter));
        yield put(SetSinglePatDetail(Data));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* FeeSheetDelete({ payload }) {
  console.log(payload);
  try {
    const res = yield call(FeeSheetCollapseDelete_Api, payload);
    if (res.status === 200) {
      const res = yield call(FeeSheetCollapse_Api, payload);
      if (res.status === 200) {
        const DecryptedData = Decrypt_Value(res.data, "vozorpm");
        yield put(SetFeeListCollapse(DecryptedData));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* PatientEmailDetail({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(PatientEmailApi, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPatientEmail(DecryptedData));
    }
  } catch (e) {
    yield put(SetPatientEmail([]));
    console.log(e.message);
  }
}

export function* setEmptyPtMailResponse() {
  yield put(SetPatientEmail(""));
}

export function* Unenrollupdate({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozorpm");
  try {
    const res = yield call(UnenrollUpdateApi, ecrypted);
    if (res.status === 200) {
      try {
        const res1 = yield call(GetSinglePatientDetail_Api, payload.pid);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          const response = yield call(DeviceListApi, payload.pid);
          if (response.status === 200) {
            const Data1 = Decrypt_Value(response.data, "vozorpm");
            yield put(setDeviceList(Data1));
          }
          yield put(SetSinglePatDetail(Data));
          yield put(SetUnenrollmentupdate("1"));
        }
      } catch (e) {
        console.log(e.message);
        yield put(SetUnenrollmentupdate([]));
      }
    }
  } catch (e) {
    yield put(SetSinglePatDetail([]));
  }
}

export function* setUnenrollEmptyResponse() {
  yield put(SetUnenrollmentupdate(""));
}

export function* ProviderCheck({ payload }) {
  const credential = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(ProviderApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");

      yield put(setProviderSearchList(Data));
    }
  } catch (e) {
    yield put(setProviderSearchList([]));
    console.log(e.message);
  }
}

export function* exportPatient({ payload }) {
  try {
    const res = yield call(exportDataApi, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      console.log(Data);
      if (Data) {
        // Export CSV
        const blob = new Blob([Data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "PatientList.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        yield put(setExportStatus("success"));
      }
    }
  } catch (e) {
    yield put(setExportStatus("failed"));
    console.log(e.message);
  }
}
export function* PatientImport({ payload }) {
  const encrypted = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(PatientImportApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setPatientImport(Data));
    }
  } catch (e) {
    yield put(setPatientImport(""));
    console.log(e.message);
  }
}
