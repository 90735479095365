import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatBox, ChatBoxMsg, ArrowBack } from "./styles";
import { Data, Row } from "../Messages/styles";
import { Avatar, AvatarLetter, Image, Text } from "../Messages/styles";
import DocType from "../../../Assets/images/docType.png";
import moment from "moment";
import { Div, FileInput } from "../../StyledComponents";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { getUnreadCount } from "../../../StateManagement/Reducers/MessengerState.js";
import DBHost from "../../../Config/DBHost.js";
const Chat = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------//
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const dispatch = useDispatch();

  var audioURL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/Voice_recordings/";
  var File_URL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/MessageImages/";
  var Test_URL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const User_Profile_URL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";
  const [data, setData] = useState([]);
  const [drop, setDrop] = useState(false);
  const single = useSelector((state) => state.Messenger.singleMessage);
  const messageEl = useRef(null);
  const inputEl = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [loading, setLoading] = useState(true);

  // ----------------------------State and Variables ---------------------------------------------------///
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrop(true);
    } else if (e.type === "dragleave") {
      setDrop(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const ext = file.type.split("/")[1];

      switch (ext) {
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "pdf":
          props.setSelectedFile(file);
          setDrop(false);
          break;
        default:
          setShowAlert(true);
          setModalMsg("Unsupported file format");
          setStatus("fail");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
          setDrop(false);
          props.setSelectedFile();
      }
    }
  };
  // --------------------------useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    if (single.length !== 0) {
      setData(single);
      setLoading(false);
    }
    setTimeout(() => {
      if (single.length === 0) {
        setLoading(false);
      }
    }, [1500]);
  }, [single, props.refresh]);
  useEffect(() => {
    let readchats = [];
    readchats = single.filter((item) => item.type !== "date");
    let article = {
      Provider_Id: user_id,
      Pid: props.pid,
      chats: readchats,
      Sender: "Patient",
    };
    dispatch(getUnreadCount(article));
  }, [single]);
  useEffect(() => {
    messageEl.current.scrollIntoView();
  }, [single, data]);
  // --------------------------useEffect End-------------------------------------------------------------//
  return (
    <>
      <Row
        borderBottom="1px solid #cbd5e0"
        dis="flex"
        padding="16px 30px 16px 20px"
      >
        <ArrowBack
          onClick={() => {
            props.handleChat(0);
          }}
        />
        {props.patpic === null ? (
          <Avatar flex width="32px" height="32px">
            <AvatarLetter>{props.patName.charAt(0).toUpperCase()}</AvatarLetter>
          </Avatar>
        ) : (
          <Row
            width="32px"
            height="32px"
            radius="50%"
            margin=" 0px 8px 0px 0px"
          >
            <Image
              src={Test_URL + props.patpic}
              alt="i"
              loading="lazy"
              width="32px"
              height="32px"
              borderRadius="40px"
              mr="8px"
            />
          </Row>
        )}
        <Text
          fontFamily="'Open Sans', sans-serif"
          fontWeight="500"
          fontSize="14px"
          color=" #2C7BE5"
          lineHeight="16.94px"
        >
          {props.patName}
        </Text>
      </Row>
      <Data
        height="387px"
        width="100%"
        padding="16px"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        drop={drop}
      >
        <FileInput
          ref={inputEl}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        {!loading ? (
          data.length !== 0 ? (
            <>
              {data.map((item, index) => {
                return (
                  <ChatBox
                    user={item.sender === "Provider" ? true : false}
                    type={item.type}
                    key={index}
                    marginLeft={
                      (item.type === "image" ||
                        item.type === "doc" ||
                        item.type === "audio") &&
                      item.sender === "Provider"
                        ? "auto"
                        : ""
                    }
                  >
                    {item.type !== "date" && item.sender !== "Provider" && (
                      <>
                        {props.patpic === null ? (
                          <Avatar flex width="32px" height="32px">
                            <AvatarLetter>
                              {item.pat_name.charAt(0).toUpperCase()}
                            </AvatarLetter>
                          </Avatar>
                        ) : (
                          <Image
                            src={Test_URL + props.patpic}
                            alt="image-failed"
                            loading="lazy"
                            width="32px"
                            height="32px"
                            mr="8px"
                            borderRadius="40px"
                          />
                        )}
                      </>
                    )}
                    <ChatBoxMsg
                      user={item.sender === "Provider" ? true : false}
                      type={item.type}
                      day={
                        item.type === "date" &&
                        moment(item.date).calendar(null, {
                          sameDay: "[Today]",
                          nextDay: "[Tomorrow]",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday]",
                          lastWeek: "dddd,MMMM D",
                          sameElse: "DD/MM/YYYY",
                        })
                      }
                    >
                      {item.type === "date" &&
                        moment(item.date).calendar(null, {
                          sameDay: "[Today]",
                          nextDay: "[Tomorrow]",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday]",
                          lastWeek: "dddd,MMMM D",
                          sameElse: "DD/MM/YYYY",
                        })}

                      {item.type === "text" && (
                        <>
                          <Text
                            width="unset"
                            fontFamily="'Open Sans', sans-serif"
                            fontWeight="500"
                            fontSize="13px"
                            textAlign="left"
                            MaxWidth="150px"
                            padding="text"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            wordBreak="break-word"
                          >
                            {item.message}
                          </Text>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}
                      {item.type === "image" && (
                        <>
                          <Row dis="flex" flexDirection="column">
                            <Image
                              cursor="pointer"
                              MaxWidth="205px"
                              MaxHeight="137px"
                              objectFit="contain"
                              display="block"
                              src={item.imageUrl && File_URL + item.imageUrl}
                              alt=" "
                              onClick={() => {
                                props.handlePreview(File_URL + item.imageUrl);
                                props.setViewType("image");
                              }}
                            />
                            <Text
                              fontFamily="'Open Sans', sans-serif"
                              fontWeight="500"
                              fontSize="13px"
                              lineHeight="17px"
                              textAlign="left"
                              color="unset"
                              pt="6px"
                              display="inline"
                              width="100%"
                              wordBreak="break-word"
                            >
                              {item.message}
                            </Text>
                          </Row>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}

                      {item.type === "audio" && (
                        <>
                          <Row dis="flex" flexDirection="column">
                            <Div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <audio
                                controls
                                src={audioURL + item.imageUrl}
                                style={{ height: "40px", width: "230px" }}
                              />
                              <Text
                                fontFamily="'Open Sans', sans-serif"
                                fontWeight="500"
                                fontSize="13px"
                                lineHeight="17px"
                                textAlign="left"
                                color="unset"
                                pt="6px"
                                display="inline"
                                width="100%"
                                wordBreak="break-word"
                              >
                                {item.message}
                              </Text>
                            </Div>
                          </Row>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}

                      {item.type === "doc" && (
                        <>
                          <Row dis="flex" flexDirection="column">
                            <Image
                              cursor="pointer"
                              MaxWidth="221px"
                              MaxHeight="137px"
                              objectFit="contain"
                              display="block"
                              src={DocType}
                              alt=" "
                              onClick={() => {
                                props.handlePreview(File_URL + item.imageUrl);
                                props.setViewType("doc");
                              }}
                            />
                            <Text
                              fontFamily="'Open Sans', sans-serif"
                              fontWeight="500"
                              fontSize="13px"
                              lineHeight="17px"
                              color="unset"
                              display="inline"
                              pt="6px"
                              width="100%"
                              textAlign="left"
                              wordBreak="break-word"
                            >
                              {item.message}
                            </Text>
                          </Row>
                          <Text
                            width="unset"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                            margin="auto 0 0 10px"
                          >
                            {item.date && moment(item.date).format("h:mm a")}
                          </Text>
                        </>
                      )}
                    </ChatBoxMsg>
                    {item.type !== "date" && item.sender === "Provider" && (
                      <>
                        {props.profileimage === null ||
                        props.profileimage === "" ? (
                          <Avatar
                            flex
                            width="32px"
                            height="32px"
                            minWidth="32px"
                          >
                            <AvatarLetter>
                              {item.username.charAt(0).toUpperCase()}
                            </AvatarLetter>
                          </Avatar>
                        ) : (
                          <Image
                            src={User_Profile_URL + props.profileimage}
                            alt="image-failed"
                            loading="lazy"
                            borderRadius="40px"
                            height="32px"
                            width="32px"
                            mr="8px"
                          />
                        )}
                      </>
                    )}
                  </ChatBox>
                );
              })}
            </>
          ) : (
            <Div
              display="flex"
              justifyContent="center"
              fontWeight="500"
              fontSize="14px"
              lineHeight="17px"
              color="#8A969F"
              backColor="unset"
            >
              No Messages....
            </Div>
          )
        ) : (
          <Div
            display="flex"
            justifyContent="center"
            fontWeight="500"
            fontSize="14px"
            lineHeight="17px"
            color="#8A969F"
            backColor="unset"
          >
            loading Messages....
          </Div>
        )}
        <Row ref={messageEl}></Row>
      </Data>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};

export default Chat;
