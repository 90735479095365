import React, { useEffect, useState } from "react";
import {
  Image,
  Modal,
  ModalContainer,
  ModalHeader,
  Spinner,
  SpinnerWrapper,
} from "../../../StyledComponents";
import { BsXLg } from "react-icons/bs";
import { ModalDialog } from "../../../StyledComponents/AddCard/Styles";
import "./RingCentralPhoneCall.css";
import Logo from "../../../../Assets/images/Logo.png";

const PhoneCallRingcentral = ({ closeBtn, phone }) => {
  const [load, setLoad] = useState(true);

  //====================================Function Start==========================================
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //====================================Function End==========================================

  //====================================UseEffect Start==========================================

  useEffect(() => {
    const createRingCentralIframe = async () => {
      const existingIframe = document.getElementById("store_here");

      if (!existingIframe) {
        try {
          const iframe = document.createElement("iframe");

          iframe.id = "store_here";
          iframe.src = "about:blank";
          const parentElement = document.getElementById("store_here1");

          if (parentElement) {
            parentElement.appendChild(iframe);
          }
          const iframeDocument =
            (iframe && iframe.contentDocument) ||
            (iframe && iframe.contentWindow && iframe.contentWindow.document);

          const script =
            iframeDocument && iframeDocument.createElement("script");
          if (script) {
            script.src =
              "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
          }
          iframeDocument && iframeDocument.body.appendChild(script);
        } catch (error) {
          console.error("Error loading Ring Central script:", error);
        }
      }
    };

    const initializeRingCentral = async () => {
      await delay(5000);
      const iframe = document.getElementById("store_here");
      const iframeContentWindow = iframe && iframe.contentWindow;
      const iframeDocument =
        iframeContentWindow && iframeContentWindow.document;
      const elementWithId =
        iframeDocument &&
        iframeDocument.getElementById("rc-widget-adapter-frame");

      elementWithId &&
        elementWithId.contentWindow.postMessage(
          {
            type: "rc-adapter-new-call",
            phoneNumber: phone,
            toCall: false,
          },
          "*"
        );
      setLoad(false);
    };

    const fetchData = async () => {
      await createRingCentralIframe();
      if (phone !== "undefined") {
        await initializeRingCentral();
      }
    };

    fetchData();
    // const handleMessage = (event) => {
    //   const data = event.data;
    //   if (data.path === "/messages") {
    //     console.log("User clicked on the 'Message' div");
    //     document.querySelector("#rc-widget-adapter-frame") &&
    //       document
    //         .querySelector("#rc-widget-adapter-frame")
    //         .contentWindow.postMessage(
    //           {
    //             type: "rc-adapter-new-sms",
    //             phoneNumber: phone,
    //           },
    //           "*"
    //         );
    //   }
    // };
  }, [phone]);

  //====================================UseEffect End==========================================

  return (
    <Modal
      show={true}
      justifyContent="center"
      width="100%"
      height="100%"
      id="ring_central"
    >
      <ModalContainer
        width="35%"
        justifyContent="center"
        overflowY="hidden"
        borderRadius="8px"
        height="100%"
      >
        <ModalDialog width="100%" className="dialog_fixed" height="100%">
          <ModalHeader
            padding="19px 25px 16px 25px"
            justifyContent="space-between"
            borderLess
          >
            <BsXLg
              style={{
                color: "#2E2E2E",
                cursor: "pointer",
                height: "24px",
                width: "24px",
                background: "rgba(46, 46, 46, 0.1)",
                padding: "6px",
                borderRadius: "50%",
                zIndex: "999999",
              }}
              id="ringcent_btn"
              onClick={closeBtn}
            />
          </ModalHeader>

          {/* <ModalBody> */}

          {load && (
            <SpinnerWrapper position="absolute">
              <Spinner></Spinner>
              <Image
                width="40px"
                height="40px"
                position="absolute"
                src={Logo}
              />
            </SpinnerWrapper>
          )}
          <div id="store_here1"></div>
          {/* </ModalBody> */}
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default PhoneCallRingcentral;
