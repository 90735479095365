import React, { useState, useEffect, memo } from "react";
import {
  Div,
  Span,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  Image,
  SpanTag,
} from "../../../StyledComponents/index";
import { useSelector, useDispatch } from "react-redux";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import { RiEdit2Line } from "react-icons/ri";
import AddMedication from "./AddMedication";
import { FiTrash2 } from "react-icons/fi";
import {
  GetMedPut,
  LabCredentials,
  MedDeleteLists,
} from "../../../../StateManagement/Reducers/HealthState";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";

const Medication = (props) => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const [show, setShow] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [id, setid] = useState("0");

  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");

  const pidvalue = props.pid;
  const medvalue = props.value;
  const dispatch = useDispatch();
  const medicationdetails = useSelector((state) => state.Health.LabResponse);
  const MedicationAlart = useSelector((state) => state.Health.HealthAlertMsg);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start------------------------------------------------- //
  const handleopen = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShow(true);
      setid("0");
    }
  };
  const handleclose = () => {
    setShow(false);
    setid("0");
  };

  const handleopen1 = (id) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShow(true);
      setid(() => {
        return id;
      });

      dispatch(GetMedPut({ pid: props.pid, id: id, value: props.value }));
    }
  };

  const handledelete = (id) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (window.confirm("Are you sure you want to Delete?")) {
        dispatch(
          MedDeleteLists({ pid: props.pid, id: id, value: props.value })
        );
      }
    }
  };
  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //
  useEffect(() => {
    if (MedicationAlart !== "") {
      if (MedicationAlart.includes("Deleted")) {
        setModalAlerShow(true);
        setModalMsg("Deleted Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(LabCredentials({ pid: props.pid, value: props.value }));
      }
      if (MedicationAlart.includes("Inserted")) {
        setModalAlerShow(true);
        setModalMsg("Medication Added Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(LabCredentials({ pid: props.pid, value: props.value }));
      }
      if (MedicationAlart.includes("Updated")) {
        setModalAlerShow(true);
        setModalMsg("Medication Updated Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(LabCredentials({ pid: props.pid, value: props.value }));
      }
    }
  }, [MedicationAlart]);

  useEffect(() => {
    dispatch(LabCredentials({ pid: props.pid, value: props.value }));
    setModalAlerShow(false);
  }, []);

  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <>
      <Div
        background="#fffff"
        hight="100px"
        borderRadius="8px"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      >
        <Div padding="15px 25px 15px 31px">
          <Div
            display="flex"
            fontFamily="'Open Sans'sans-serif"
            fontStyle="normal"
            fontWeight="600"
            fontSize="18px"
            lineHeight="20px"
          >
            <Span style={{ marginTop: "10px" }}> Medication List</Span>

            <Button
              display="flex"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 36px 8px 31px"
              background="#2C7BE5"
              color="#FFFFFF"
              marginLeft="auto"
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
              onClick={handleopen}
              backgroundColor="#2C7BE5"
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              <Span
                fontSize="14px"
                fontWeight="600"
                fontFamily="'Open Sans', sans-serif"
              >
                Add Medication
              </Span>
            </Button>
          </Div>
        </Div>
        <Div overflowY="auto" maxHeight="350px">
          <Table hight="56px !important">
            <TableHead
              style={{ position: "sticky", top: "0" }}
              background="#F1F5F8"
              zIndex="1"
            >
              <TableRow
                color="#2C7BE5"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                colSpan="7"
                paddingTop="18px"
                paddingBottom="19px"
                fontFamily="'Open Sans'sans-serif"
              >
                <TableHeadCell
                  width="250px !important"
                  textAlign="center"
                  resWidth="20% !important"
                >
                  Medication
                </TableHeadCell>
                <TableHeadCell textAlign="center">Dispense</TableHeadCell>
                <TableHeadCell textAlign="center">ReFill</TableHeadCell>
                <TableHeadCell textAlign="center">SIG</TableHeadCell>
                <TableHeadCell textAlign="center">Status</TableHeadCell>
                <TableHeadCell textAlign="center">
                  Medication intake Duration
                </TableHeadCell>
                <TableHeadCell
                  textAlign="center"
                  paddingLeft="6px"
                  resWidth="20% !important"
                >
                  Action
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {medicationdetails.length !== 0 ? (
                <>
                  {medicationdetails.map((details, ind) => (
                    <TableRow
                      key={ind}
                      fontSize="14px"
                      fontWeight="400"
                      color="#000000"
                      lineHeight="20px"
                      fontFamily="'Open Sans'sans-serif"
                      borderBottom="1px solid #cbd5e0"
                      paddingTop="19px"
                      paddingBottom="19px"
                    >
                      <TableBodyCell
                        width="250px !important"
                        textAlign="center"
                        paddingRight="0px"
                      >
                        {details.title}
                      </TableBodyCell>
                      <TableBodyCell textAlign="center" paddingRight="0px">
                        {details.Dispensequantity}
                      </TableBodyCell>
                      <TableBodyCell textAlign="center" paddingRight="0px">
                        {details.Refills}
                      </TableBodyCell>
                      <TableBodyCell
                        columnWidth="8%"
                        textAlign="center"
                        paddingRight="0px"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        maxWidth="200px"
                        title={details.sig_note}
                      >
                        {truncateText(details.sig_note, 30)}
                      </TableBodyCell>
                      <TableBodyCell textAlign="center" paddingRight="0px">
                        {details.Status}
                      </TableBodyCell>
                      <TableBodyCell textAlign="center" paddingRight="0px">
                        {details.begdate}{" "}
                        <p style={{ display: "inline-block" }}>to</p>{" "}
                        {details.enddate}
                      </TableBodyCell>
                      <TableBodyCell textAlign="center" paddingLeft="24px">
                        <SpanTag
                          height="18px"
                          width="18px"
                          color=" #2C7BE5"
                          cursor={
                            SingPatDetails.enrollment_status !== "4"
                              ? "pointer"
                              : "not-allowed"
                          }
                          onClick={() => handleopen1(details.id, details.pid)}
                        >
                          <RiEdit2Line />
                        </SpanTag>
                        <SpanTag
                          height="18px"
                          width="16px"
                          color=" #B00020"
                          marginLeft="25px"
                          cursor={
                            SingPatDetails.enrollment_status !== "4"
                              ? "pointer"
                              : "not-allowed"
                          }
                          onClick={(e) => handledelete(details.id)}
                        >
                          <FiTrash2
                            style={{ fill: "#ffffff", stroke: "#f24822" }}
                          />
                        </SpanTag>
                      </TableBodyCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  <TableRow height="168px">
                    <TableBodyCell textAlign="center" colSpan={6}>
                      <Div>
                        <Image
                          height="100px"
                          width="100px"
                          src={Emp_data_pic}
                        ></Image>
                      </Div>
                      <Div
                        fontSize="14px"
                        color="#2E2E2E"
                        fontFamily="'Open Sans',sans-serif"
                        lineHeight="20px"
                        fontWeight="400"
                      >
                        No problems to be shown,Please add some.
                      </Div>
                    </TableBodyCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Div>
      </Div>

      <AddMedication
        show={show}
        close={handleclose}
        Edit={Edit}
        pidvalue={pidvalue}
        medvalue={medvalue}
        id={id}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(Medication);
