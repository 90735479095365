import { all } from "redux-saga/effects";

import {
  Login,
  Logo,
  ChangePassword,
  EmptyingResponseSaga,
  GetProviderDetailSaga,
  LoginEmailCheckSaga,
} from "./Login/LoginSagaWatchers";

import {
  PasswordResetEmailCheck,
  NewPasswordEmailCheck,
  ResetVerifyCheck,
  EmptyForgetSaga,
  SignupApiSaga,
} from "./PreLogin/PreLoginWatchers";
import {
  ChartList,
  InitialLogin,
  LoginTime,
} from "./Dashboard/DashboardSagaWatchers";
import {
  AddProfileDatas,
  ProfileDetails,
  ClinicalDetails,
  AddClinicalDatas,
  AddProfileEmptyingResponseSaga,
  userImageUploadSaga,
  SettingSecuritySaga,
  SettingSecurityUpdateSaga,
  EmptyingSecurityResponseSaga,
  GetDocumentMailSaga,
  GetSettingDocSaga,
  GetUpdateSetDocSaga,
  EmptyUpdateSetDocSaga,
  UpdateDocMailSaga,
  ProviderList,
  GetProvidersList,
  ProviderRoles,
  SavedProvidersList,
  SingleProviderSaga,
  UpdateSingleProvider,
  EmptyResponseSaga,
  SingleProvEmptyResponseSaga,
  SettingSecurityData,
  DeleteUserData,
  EmptyUpdateResponseSaga,
  GetServiceData,
  GetServiceDeleteData,
  GetServiceInsertData,
  GetServiceOneData,
  GetServiceUpdateData,
  Currency,
  EmptyingServiceResponseSaga,
  ServiceCodeVerify,
  EmptyingCodeResponseSaga,
  EmptyRespofUserCheck,
  CheckUserNameSaga,
  CheckUserEmailSaga,
  CheckUserPhoneSaga,
  CheckUserCellSaga,
  EmptyRespofEmailCheck,
  EmptyRespofPhoneCheck,
  EmptyRespofCellCheck,
  SetMailVerify,
  SetPhoneVerify,
  GetProviderRolesListSaga,
  InsertRoleSaga,
  EditRoleSaga,
  GetGroupIdSaga,
  EditRoleInsertSaga,
  ProviderWithRoleSaga,
  GetCustomizeRoleSaga,
  EditProvRoleSaga,
  SaveProvRoleSaga,
  DeleteRoleSaga,
  CountryCodeDetails,
} from "./Setting/SettingSagaWatchers";
import {
  PatientList,
  SinglePatientDetail,
  searchPatient,
  PatientEnrollemt,
  PatientEnrollemtUpdate,
  RaceLists,
  LanguageLists,
  GenderLists,
  AddCardDetail,
  AddCardEmptyingResponseSaga,
  UpdateCardEmptyingResponseSaga,
  DeleteCardEmptyingResponseSaga,
  CardDetails,
  RemoveCardDetails,
  EditCardDetail,
  VitalsNotificationDetails,
  VitalsNotificationDetailsUpdate,
  MailVerify,
  PhoneVerify,
  SSNVerify,
  EmptyingPatUpdateResponse,
  FeeSheet,
  FeeSheetCollapse,
  FeeSheetsListDelete,
  FeeSheetCollapseDelete,
  PatientEmail,
  EmptyingMailResponseSaga,
  UnenrollResponseSaga,
  UnenrollupdateSaga,
  searchProvider,
  ExportPatientList,
  patientImportCsv,
} from "./Patient/PatientSagaWatchers";
import {
  ListSaga,
  UpdateListSaga,
  InsertListSaga,
  DeleteListSaga,
  UpdatedListSaga,
  AllerUpdateListSaga,
  AllerInsertListSaga,
  DeleteListsSaga,
  medpostsaga,
  medputsaga,
  medupdatesaga,
  Medtable,
  LaborderSaga,
  LaborderoneSaga,
  LabInsertSaga,
  LabDeleteSaga,
  LabUpdateSaga,
  ProviderListSaga,
  LabListSaga,
  EmptyingLabResponseSaga,
} from "./Health/HealthSagaWatchers";
import {
  DrugListSaga,
  ICD10ListSaga,
  ICD9ListSaga,
  ProcedureSaga,
} from "./Medical/MedicalSagaWatchers";
import {
  GetMessenger,
  GetMessengerPatient,
  SendMessage,
  SendImageMessage,
  SingleMessage,
  PreviewDownload,
  UnreadCountSaga,
} from "./Messenger/MessengerSagaWatcher";
import {
  MsgSaga,
  vitalsDemo,
  MessagerListSaga,
  PreviewDownloadSagaM,
  EmptyDownloadSagaM,
  StatusSaga,
  ReadingChart,
  AddDevicesSaga,
  Devices,
  SerialNoCheckWatcher,
  IntegrationWatcher,
  TokenChecked,
  DisconnectWatcher,
} from "./PatientDemo/PatientDemosagaWatchers";
import {
  stateListOptions,
  raceListOptions,
  ethListOptions,
  langListOptions,
} from "./ListOptions/ListOptionsSagaWatchers";
import { VitalsList } from "./Live/LiveSagaWatchers";
import {
  AddEncounterSaga,
  EncounterEmptyingResponseSaga,
  EncounterIdSaga,
  EncounterOptionsSaga,
  EncounterSummarySaga,
  VisitEncounterSaga,
  EncounterLockSaga,
  EncounterViewDetails,
  FeeSheetViewDetails,
  GetAddNotes,
  GetAddNoteId,
  GetRpmNotesSaga,
  GetRpmSingleNoteSaga,
  GetRpmGmeetDataSaga,
  RpmSingleNoteDeleteSaga,
  EmptyAddNotesSaga,
  EmptyDelNotesSaga,
  ExportCSVSaga,
  EditEncounterSaga,
  EmptySingleEncount,
  ChartviewIdSaga,
  EmptyChartviewIdSaga,
} from "./Encounter/EncounterSagaWatchers";
import {
  AllDevicesSaga,
  GetDevicesRecordsSaga,
} from "./Devices/DevicesSagaWatchers";
import {
  DeletePlanName,
  SetTreatPlanToPatient,
  TreatPlanList,
  TreatPlanListDashB,
  CheckPlanNameSaga,
  ICD10Saga,
  TreatmentPlanInsertSaga,
  PlanDetailSaga,
} from "./TreatmentPlan/TreatmentPlanSagaWatchers";

export default function* rootSaga() {
  return yield all([
    // Login //
    InitialLogin(),
    Logo(),
    Login(),
    LoginEmailCheckSaga(),
    // Login //
    // Patient list //
    PatientList(),
    stateListOptions(),
    raceListOptions(),
    ethListOptions(),
    langListOptions(),
    patientImportCsv(),
    // Patient list //
    //Patient Demo//
    SinglePatientDetail(),
    ListSaga(),
    UpdateListSaga(),
    InsertListSaga(),
    DeleteListSaga(),
    UpdatedListSaga(),
    AllerInsertListSaga(),
    AllerUpdateListSaga(),
    DrugListSaga(),
    ICD10ListSaga(),
    ICD9ListSaga(),
    ProcedureSaga(),
    LaborderSaga(),
    LaborderoneSaga(),
    LabInsertSaga(),
    LabDeleteSaga(),
    LabUpdateSaga(),
    ProviderListSaga(),
    LabListSaga(),
    EmptyingLabResponseSaga(),
    StatusSaga(),
    //Patient Demo//
    //TopNav//
    searchPatient(),
    ChangePassword(),
    GetProviderDetailSaga(),
    EmptyingResponseSaga(),

    //TopNav//
    //Messenger //
    GetMessenger(),
    GetMessengerPatient(),
    SendMessage(),
    SendImageMessage(),
    SingleMessage(),
    PreviewDownload(),
    UnreadCountSaga(),
    //Messenger //
    // Dashboard //
    ChartList(),
    LoginTime(),
    // Dashboard //
    // PatDemo//
    vitalsDemo(),
    MsgSaga(),
    MessagerListSaga(),
    PreviewDownloadSagaM(),
    EmptyDownloadSagaM(),
    ReadingChart(),
    AddDevicesSaga(),
    Devices(),
    SerialNoCheckWatcher(),
    EmptyingPatUpdateResponse(),
    SSNVerify(),
    // PatDemo//
    //Patient Enrollement//
    PatientEnrollemt(),
    PatientEnrollemtUpdate(),
    GenderLists(),
    RaceLists(),
    LanguageLists(),
    MailVerify(),
    PhoneVerify(),
    PatientEmail(),
    EmptyingMailResponseSaga(),
    UnenrollResponseSaga(),
    UnenrollupdateSaga(),
    ExportPatientList(),
    //Patient Enrollement//
    Medtable(),
    DeleteListsSaga(),
    medpostsaga(),
    medputsaga(),
    medupdatesaga(),
    AddCardDetail(),
    AddCardEmptyingResponseSaga(),
    UpdateCardEmptyingResponseSaga(),
    DeleteCardEmptyingResponseSaga(),
    CardDetails(),
    VitalsNotificationDetails(),
    VitalsNotificationDetailsUpdate(),
    RemoveCardDetails(),
    EditCardDetail(),
    //Forgot Password//
    PasswordResetEmailCheck(),
    NewPasswordEmailCheck(),
    ResetVerifyCheck(),
    EmptyForgetSaga(),
    SignupApiSaga(),
    //Forgot Password//
    // live//
    VitalsList(),
    // live//
    // Patient Encounter //
    AddEncounterSaga(),
    EncounterEmptyingResponseSaga(),
    GetAddNotes(),
    GetAddNoteId(),
    GetRpmNotesSaga(),
    GetRpmSingleNoteSaga(),
    GetRpmGmeetDataSaga(),
    RpmSingleNoteDeleteSaga(),
    EmptyAddNotesSaga(),
    EmptyDelNotesSaga(),
    EmptySingleEncount(),
    // Patient Encounter //
    // EnccounterSaga//
    EncounterOptionsSaga(),
    VisitEncounterSaga(),
    EncounterIdSaga(),
    EncounterSummarySaga(),
    EncounterLockSaga(),
    EncounterViewDetails(),
    FeeSheetViewDetails(),
    EditEncounterSaga(),
    ChartviewIdSaga(),
    EmptyChartviewIdSaga(),
    // EnccounterSaga//
    //FeeSheet//
    FeeSheet(),
    FeeSheetCollapse(),
    FeeSheetsListDelete(),
    FeeSheetCollapseDelete(),
    ExportCSVSaga(),
    //FeeSheet//
    //Setting
    AddProfileDatas(),
    AddClinicalDatas(),
    AddProfileEmptyingResponseSaga(),
    ProfileDetails(),
    ClinicalDetails(),
    userImageUploadSaga(),
    SettingSecuritySaga(),
    SettingSecurityUpdateSaga(),
    EmptyingSecurityResponseSaga(),
    GetDocumentMailSaga(),
    GetSettingDocSaga(),
    GetUpdateSetDocSaga(),
    EmptyUpdateSetDocSaga(),
    UpdateDocMailSaga(),
    SetMailVerify(),
    SetPhoneVerify(),
    //Users//
    ProviderList(),
    GetProvidersList(),
    ProviderRoles(),
    SavedProvidersList(),
    SingleProviderSaga(),
    UpdateSingleProvider(),
    EmptyResponseSaga(),
    SingleProvEmptyResponseSaga(),
    SettingSecurityData(),
    DeleteUserData(),
    EmptyUpdateResponseSaga(),
    CheckUserNameSaga(),
    CheckUserEmailSaga(),
    CheckUserPhoneSaga(),
    CheckUserCellSaga(),
    EmptyRespofUserCheck(),
    EmptyRespofEmailCheck(),
    EmptyRespofPhoneCheck(),
    EmptyRespofCellCheck(),
    //Users//
    //Services//
    GetServiceData(),
    GetServiceDeleteData(),
    GetServiceInsertData(),
    GetServiceOneData(),
    GetServiceUpdateData(),
    Currency(),
    EmptyingServiceResponseSaga(),
    EmptyingCodeResponseSaga(),
    ServiceCodeVerify(),
    //Services//
    //Setting
    // Devices//
    AllDevicesSaga(),
    GetDevicesRecordsSaga(),
    // Devices//
    //Tp//

    //TreatmentPlan//
    TreatPlanList(),
    SetTreatPlanToPatient(),
    TreatPlanListDashB(),
    DeletePlanName(),
    CheckPlanNameSaga(),
    ICD10Saga(),
    TreatmentPlanInsertSaga(),
    PlanDetailSaga(),
    searchProvider(),
    //TreatmentPlan//
    IntegrationWatcher(),
    TokenChecked(),
    DisconnectWatcher(),
    //Roles&Permission//
    InsertRoleSaga(),
    EditRoleSaga(),
    GetGroupIdSaga(),
    EditRoleInsertSaga(),
    ProviderWithRoleSaga(),
    GetProviderRolesListSaga(),
    GetCustomizeRoleSaga(),
    EditProvRoleSaga(),
    SaveProvRoleSaga(),
    DeleteRoleSaga(),
    CountryCodeDetails(),
  ]);
}
