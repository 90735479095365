import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import { GetAllVitals_Api } from "../Apis/LiveApi";
import { SetAllVitalsDetails } from "../../StateManagement/Reducers/LiveState";

export function* getAllVitalList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(GetAllVitals_Api, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetAllVitalsDetails(Data));
      } else {
        yield put(SetAllVitalsDetails([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetAllVitalsDetails([]);
  }
}
