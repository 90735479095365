import React, { useState, useEffect } from "react";
import {
  Div,
  FormLabel,
  Input,
  Button,
  RedStar,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
  SelectInput,
  SpinnerWrapper,
  Spinner,
  Image,
} from "../../StyledComponents";
import moment from "moment";
import { Imagecalendar, Wrapper } from "../../Popup/styles";
import calendar_icon_custom from "../../../Assets/images/calendar_icon_custom.png";
import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  AddClinicalData,
  GetClinicalDetails,
} from "../../../StateManagement/Reducers/SettingState";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import { FiTrash2 } from "react-icons/fi";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import Logo from "../../../Assets/images/Logo.png";

const Clincial = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const dispatch = useDispatch();
  const [LiceninputList, setLiceninputList] = useState([
    {
      licentype: "",
      licensenum: "",
      licenseExp: "",
      licenseState: "",
      licenseExpCheck: "",
      validate: "",
    },
  ]);

  const [LiceninputListErr, setLiceninputListErr] = useState([
    {
      licentypeErr: false,
      licensenumErr: false,
      licenseExpErr: false,
      licenseStateErr: false,
      licenseExpCheckErr: false,
    },
  ]);

  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState();
  const [ShowOptions2, setShowOptions2] = useState();
  const [ShowOptions3, setShowOptions3] = useState();

  const [validDate, setValidDate] = useState(moment(new Date(), "DD/MM/YYYY"));
  const [showpicker, setShowPicker] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMsg, setModalMsg] = useState("");

  const [Npi, setNpi] = useState("");
  const [Specialty, setSpecialty] = useState("");
  const [Taxonomy, setTaxonomy] = useState("");
  const [audioPlatform, setaudioPlatform] = useState("");
  const [audioErr, setaudioErr] = useState(false);
  const [NpiErr, setNpiErr] = useState(false);
  const [SpecialtyErr, setSpecialtyErr] = useState(false);
  const [TaxonomyErr, setTaxonomyErr] = useState(false);
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(true);

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const clinicalDetailData = useSelector(
    (state) => state.Setting.clinicalDetail
  );

  const StatusDropdownOptionList = [
    { label: "LMFT", id: 1 },
    { label: "LCSW", id: 2 },
    { label: "LPC", id: 3 },
    { label: "Psy.D", id: 4 },
    { label: "LMHC", id: 5 },
    { label: "LCPC", id: 6 },
    { label: "LICSW", id: 7 },
    { label: "LPCC", id: 8 },
    { label: "LMSW", id: 9 },
    { label: "SLP", id: 10 },
    { label: "RD", id: 11 },
  ];
  const AudioPlatformList = [
    { label: "Twilio", id: 1 },
    { label: "Ring Central", id: 2 },
  ];
  const StatusDropdownOptionList2 = [
    { label: "Behavioral health therapy ", id: 1 },
    { label: "Acupunture", id: 2 },
    { label: "Lactation Consulting", id: 3 },
    { label: "Massage therapy", id: 4 },
    { label: "Counseling", id: 5 },
    { label: "Marriage & Family therapy", id: 6 },
    { label: "Psychology", id: 7 },
    { label: "Social Work", id: 8 },
    { label: "Diabetics or Nutrition Counseling", id: 9 },
    { label: "Chiropractic", id: 10 },
    { label: "Applied behavior analysis", id: 11 },
    { label: "Occupational therapy", id: 11 },
    { label: "Physical therapy", id: 11 },
    { label: "Speech language pathology", id: 11 },
    { label: "Substance use counseling", id: 11 },
    { label: "Other", id: 11 },
  ];

  const StatusDropdownOptionList1 = [
    { label: "- US -", id: 1 },
    { label: "AL", id: 2 },
    { label: "AK", id: 3 },
    { label: "AZ", id: 4 },
    { label: "AR", id: 5 },
    { label: "CA", id: 6 },
    { label: "CO", id: 7 },
    { label: "CT", id: 8 },
    { label: "DE", id: 9 },
    { label: "DC", id: 10 },
    { label: "FL", id: 11 },
    { label: "GA", id: 12 },
    { label: "HI", id: 13 },
    { label: "ID", id: 14 },
    { label: "IL", id: 15 },
    { label: "IN", id: 16 },
    { label: "IA", id: 17 },
    { label: "KS", id: 18 },
    { label: "KY", id: 19 },
    { label: "LA", id: 20 },
    { label: "ME", id: 21 },
    { label: "MD", id: 22 },
    { label: "MA", id: 23 },
    { label: "MI", id: 24 },
    { label: "MN", id: 25 },
    { label: "MS", id: 26 },
    { label: "MO", id: 27 },
    { label: "MT", id: 28 },
    { label: "NE", id: 29 },
    { label: "NV", id: 30 },
    { label: "NJ", id: 31 },
    { label: "NM", id: 32 },
    { label: "NY", id: 33 },
    { label: "NC", id: 34 },
    { label: "ND", id: 35 },
    { label: "OH", id: 36 },
    { label: "OK", id: 37 },
    { label: "OR", id: 38 },
    { label: "PA", id: 39 },
    { label: "PR", id: 40 },
    { label: "RI", id: 41 },
    { label: "SC", id: 42 },
    { label: "SD", id: 43 },
    { label: "TN", id: 44 },
    { label: "TX", id: 45 },
    { label: "UT", id: 46 },
    { label: "VT", id: 47 },
    { label: "VA", id: 48 },
    { label: "WA", id: 49 },
    { label: "WV", id: 50 },
    { label: "WI", id: 51 },
    { label: "WY", id: 52 },
    { label: "AA", id: 53 },
    { label: "AE", id: 54 },
    { label: "AP", id: 55 },
    { label: "GU", id: 56 },
    { label: "VI", id: 57 },
    { label: "- CA -", id: 58 },
    { label: "AB", id: 59 },
    { label: "BC", id: 60 },
    { label: "MB", id: 61 },
    { label: "NB", id: 62 },
    { label: "NL", id: 63 },
    { label: "NT", id: 64 },
    { label: "NS", id: 65 },
    { label: "NU", id: 66 },
    { label: "ON", id: 67 },
    { label: "PE", id: 68 },
    { label: "QC", id: 69 },
    { label: "SK", id: 70 },
    { label: "YT", id: 71 },
  ];

  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start--------------------------------------------------------- //

  const handlepicker = (e, i) => {
    e.preventDefault();
    if (showpicker === i) {
      setShowPicker();
    } else {
      setShowPicker(i);
    }
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };

  const handleAddClick = () => {
    setLiceninputList([
      ...LiceninputList,
      {
        licentype: "",
        licensenum: "",
        licenseExp: "",
        licenseState: "",
        licenseExpCheck: "",
      },
    ]);
    setLiceninputListErr([
      ...LiceninputListErr,
      {
        licentypeErr: false,
        licensenumErr: false,
        licenseExpErr: false,
        licenseStateErr: false,
        licenseExpCheckErr: false,
        licenNumberMatchErr: false,
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...LiceninputList];
    list.splice(index, 1);
    setLiceninputList(list);

    const listErr = [...LiceninputListErr];
    listErr.splice(index, 1);
    setLiceninputListErr(listErr);
  };

  const closePicker = () => {
    setShowPicker();
  };

  const dateSelect = (value, index) => {
    var name = "licenseExp";
    if (Object.prototype.toString.call(value) === "[object Date]") {
      if (value !== "") {
        const list = [...LiceninputList];
        list[index][name] = moment(value).format("YYYY-MM-DD");
        list[index]["licenseExpCheck"] = moment(value).format("YYYY-MM-DD");
        setLiceninputList(list);
        const list1 = [...LiceninputListErr];
        list1[index].licenseExpCheckErr = false;
        setLiceninputListErr(list1);
        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = false;
        setLiceninputListErr(listErr);
      } else {
        const list = [...LiceninputList];
        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = true;
        setLiceninputListErr(listErr);
      }

      setValidDate(new Date(value));
    } else {
      setValidDate("");
    }
    closePicker();
  };

  const LicDetChange = (e, index) => {
    if (!e.target.value.includes(" ")) {
      const { name, value } = e.target;

      if (value !== "") {
        const list = [...LiceninputList];

        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = false;
        listErr[index]["licenNumberMatchErr"] = false;

        setLiceninputListErr(listErr);
      } else {
        const list = [...LiceninputList];
        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = true;
        listErr[index]["licenNumberMatchErr"] = false;
        setLiceninputListErr(listErr);
      }
    }
  };
  const LicDetDropDownChange = (e, index) => {
    const name = e.target.getAttribute("name");
    const value = e.target.getAttribute("value");

    if (value === "- US -" || value === "- CA -") {
      setShowOptions1(true);
    } else {
      if (name === "licentype") {
        setShowOptions(false);
      }
      if (name === "licenseState") {
        setShowOptions1(false);
      }
      if (value !== "") {
        const list = [...LiceninputList];
        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = false;
        setLiceninputListErr(listErr);
      } else {
        const list = [...LiceninputList];
        list[index][name] = value;
        setLiceninputList(list);

        const listErr = [...LiceninputListErr];
        listErr[index][name + "Err"] = true;
        setLiceninputListErr(listErr);
      }
    }
  };

  const setSpecialtyDropDown = (e) => {
    const value = e.target.getAttribute("value");

    setShowOptions2(false);
    if (value !== "") {
      setSpecialty(value);
      setSpecialtyErr(false);
    } else {
      setSpecialty("");
      setSpecialtyErr(true);
    }
  };
  const setAudioplatfromDropDown = (e) => {
    const value = e.target.getAttribute("value");
    setShowOptions3(false);
    setaudioErr(false);
    setaudioPlatform(value);
  };
  const Npichange = (e) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      if (e.target.value !== "") {
        setNpi(e.target.value);
        setNpiErr(false);
      } else {
        setNpi("");
        setNpiErr(true);
      }
    }
  };

  const Taxonomychange = (e) => {
    if (e.target.value !== "") {
      setTaxonomy(e.target.value);
      setTaxonomyErr(false);
    } else {
      setTaxonomy("");
      setTaxonomyErr(true);
    }
  };

  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker();
    }
  };
  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "license_type") {
      setShowOptions(false);
    }
    if (exampleAttr !== "license_state") {
      setShowOptions1(false);
    }
    if (exampleAttr !== "specialty") {
      setShowOptions2(false);
    }
    if (exampleAttr !== "audioplatform") {
      setShowOptions3(false);
    }
  };

  const findDuplicateRows = () => {
    const duplicateRows = LiceninputList.filter((item, index) => {
      return LiceninputList.some((compareItem, compareIndex) => {
        return (
          item.licensenum === compareItem.licensenum && index !== compareIndex
        );
      });
    });
    return duplicateRows;
  };

  const handlesave = () => {
    var err = 0;

    var checkDuplicate = findDuplicateRows();

    if (checkDuplicate.length > 0) {
      err = 2;
      for (var k = 0; k < checkDuplicate.length; k++) {
        const list = [...LiceninputListErr];
        list[k].licenNumberMatchErr = true;
        setLiceninputListErr(list);
      }
    }

    for (var j = 0; j < LiceninputList.length; j++) {
      if (LiceninputList[j].licentype === "") {
        const list = [...LiceninputListErr];
        list[j].licentypeErr = true;
        setLiceninputListErr(list);
        err = 2;
      } else {
        const list = [...LiceninputListErr];
        list[j].licentypeErr = false;
        setLiceninputListErr(list);
      }
      if (LiceninputList[j].licensenum === "") {
        const list = [...LiceninputListErr];
        list[j].licensenumErr = true;
        setLiceninputListErr(list);
        err = 2;
      } else {
        const list = [...LiceninputListErr];
        list[j].licensenumErr = false;
        setLiceninputListErr(list);
      }
      if (LiceninputList[j].licenseExp === "") {
        const list = [...LiceninputListErr];
        list[j].licenseExpErr = true;
        setLiceninputListErr(list);
        err = 2;
      } else if (
        LiceninputList[j].licenseExp !== LiceninputList[j].licenseExpCheck
      ) {
        const list = [...LiceninputListErr];
        list[j].licenseExpCheckErr = true;
        setLiceninputListErr(list);
        err = 2;
      } else {
        const list = [...LiceninputListErr];
        list[j].licenseExpErr = false;
        setLiceninputListErr(list);
      }
      if (LiceninputList[j].licenseState === "") {
        const list = [...LiceninputListErr];
        list[j].licenseStateErr = true;
        setLiceninputListErr(list);
        err = 2;
      } else {
        const list = [...LiceninputListErr];
        list[j].licenseStateErr = false;
        setLiceninputListErr(list);
      }
      if (
        moment(LiceninputList[j].licenseExp).format("YYYY-MM-DD") <
        moment().format("YYYY-MM-DD")
      ) {
        const list = [...LiceninputListErr];
        list[j].licenseExpErr = true;
        setLiceninputListErr(list);
        err = 2;
      }
    }

    if (Npi === "") {
      setNpiErr(true);
      err = 2;
    }

    if (Specialty === "") {
      setSpecialtyErr(true);
      err = 2;
    }

    if (Taxonomy === "") {
      setTaxonomyErr(true);
      err = 2;
    }
    if (audioPlatform === "" || audioPlatform === undefined) {
      setaudioErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }
    setDis(true);
    const article = {
      npinum: Npi,
      license: LiceninputList,
      specialty: Specialty,
      taxonomy: Taxonomy,
      uid: user_id,
      audioPlatform: audioPlatform,
    };

    Promise.resolve()
      .then(() => {
        dispatch(AddClinicalData(article));
      })
      .then(() => {
        setShowAlert(true);
        setModalMsg("Clinical Details Updated Successfully");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
          setDis(false);
        }, 1500);
      });
  };
  const LicenseMatchedErr = (currentIndex) => {
    for (let i = 0; i < currentIndex; i++) {
      if (
        LiceninputList[i].licensenum ===
          LiceninputList[currentIndex].licensenum &&
        LiceninputList[currentIndex].licensenum !== ""
      ) {
        return true;
      }
    }
    return false;
  };
  //-----------------------------Function End--------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    dispatch(GetClinicalDetails(user_id));
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (
      (clinicalDetailData !== undefined || null) &&
      clinicalDetailData.length > 0
    ) {
      setNpi(clinicalDetailData[0].npi);
      setTaxonomy(clinicalDetailData[0].taxonomy);
      setSpecialty(clinicalDetailData[0].specialty);
      setaudioPlatform(clinicalDetailData[2].gl_value);
      let lengthOfArray = clinicalDetailData[1].length;

      var tempArrayOfObjects = [];
      var tempArrayOfObjectsData = [];
      for (let i = 0; i < lengthOfArray; i++) {
        var obj = {
          licentypeErr: false,
          licensenumErr: false,
          licenseExpErr: false,
          licenseStateErr: false,
          licenseExpCheckErr: false,
          licenNumberMatchErr: false,
        };
        var obj1 = {
          licentype: clinicalDetailData[1][i].licentype,
          licensenum: clinicalDetailData[1][i].licensenum,
          licenseExp: clinicalDetailData[1][i].licenseExp,
          licenseState: clinicalDetailData[1][i].licenseState,
          licenseExpCheck: clinicalDetailData[1][i].licenseExp,
          validate: clinicalDetailData[1][i].licenseExp,
        };
        tempArrayOfObjects.push(obj);
        tempArrayOfObjectsData.push(obj1);
      }

      setLiceninputListErr(tempArrayOfObjects);
      setLiceninputList(tempArrayOfObjectsData);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [clinicalDetailData]);
  //-----------------------------UseEffect End---------------------------------------------- //
  return loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div>
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="16px"
          fontWeight="600"
          marginTop="16px"
        >
          Licenses
        </FormLabel>

        {LiceninputList.map((x, i) => (
          <>
            <Div display="flex">
              <Div>
                <Div marginTop="10px" display="flex" key={i}>
                  <Div>
                    <FormLabel
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="16px"
                      fontWeight="400"
                    >
                      License Type<RedStar margin="2px 0 0 0">*</RedStar>
                    </FormLabel>
                    <SelectInput
                      placeholder=" 'None Selected '"
                      width="200px"
                      height="36px"
                      name="license_type"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="0.50px rgba(46, 46, 46, 0.25) solid"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      paddingTop="8px !important"
                      margin="8px 0px 0px 0px"
                      style={
                        LiceninputListErr[i].licentypeErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {
                              marginTop:
                                LiceninputListErr[i].licensenumErr ||
                                LiceninputListErr[i].licenNumberMatchErr
                                  ? "8px"
                                  : "8px",
                            }
                      }
                      onClick={() => {
                        if (ShowOptions === i) {
                          setShowOptions();
                        } else {
                          setShowOptions(i);
                        }
                      }}
                    >
                      {x.licentype}
                    </SelectInput>
                    <DropdownImage
                      height="8px !important"
                      width="12px !important"
                      marginTop="-22px"
                      marginLeft="175px"
                      src={dropdownDownicon}
                      onClick={() => {
                        if (ShowOptions === i) {
                          setShowOptions();
                        } else {
                          setShowOptions(i);
                        }
                      }}
                    ></DropdownImage>
                    {LiceninputListErr[i].licentypeErr && (
                      <ErrorMessage margin="5px 0px 5px 0px">
                        Please Enter License Type
                      </ErrorMessage>
                    )}
                  </Div>

                  {ShowOptions === i && StatusDropdownOptionList.length > 0 ? (
                    <DropDownDiv
                      width="200px"
                      background="#FFFFFF"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      marginTop="63px"
                      overflow="auto"
                      height="261px"
                    >
                      {StatusDropdownOptionList.map((r, ind) => {
                        return (
                          <DropdownOptions
                            key={ind}
                            name="licentype"
                            value={r.label}
                            padding="10px 12px 8px 19px"
                            color="#2E2E2E"
                            fontSize="14px"
                            fontFamily="'Open Sans',sans-serif"
                            onClick={(e) => LicDetDropDownChange(e, i)}
                          >
                            {r.label}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  ) : (
                    ""
                  )}

                  <Div display="grid" marginLeft="30px">
                    <FormLabel
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="16px"
                      fontWeight="400"
                      marginTop="2px"
                    >
                      License number<RedStar margin="2px 0 0 0">*</RedStar>
                    </FormLabel>
                    <Input
                      border="0.50px rgba(46, 46, 46, 0.25) solid"
                      height="36px"
                      width="200px"
                      name="licensenum"
                      borderRadius="4px"
                      paddingLeft="10px"
                      paddingRight="10px"
                      marginTop="8px"
                      style={
                        LiceninputListErr[i].licensenumErr ||
                        (LicenseMatchedErr(i) && i > 0)
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {
                              marginTop: LiceninputListErr[i].licentypeErr
                                ? "-18px"
                                : "8px",
                            }
                      }
                      value={x.licensenum}
                      onChange={(e) => LicDetChange(e, i)}
                    ></Input>
                    {LiceninputListErr[i].licensenumErr && (
                      <ErrorMessage margin="5px 0px 5px 0px">
                        Please Enter License Number
                      </ErrorMessage>
                    )}

                    {LicenseMatchedErr(i) && i > 0 && (
                      <ErrorMessage margin="5px 0px 5px 0px">
                        Please Enter Different License Number
                      </ErrorMessage>
                    )}
                  </Div>
                </Div>
                <Div marginTop="10px" display="flex">
                  <Div display="grid">
                    <FormLabel
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="16px"
                      fontWeight="400"
                      marginTop="2px"
                      style={
                        LiceninputListErr[i].licenseExpErr ||
                        LiceninputListErr[i].licenseExpCheckErr
                          ? { marginBottom: "9px" }
                          : {
                              marginBottom: LiceninputListErr[i].licenseStateErr
                                ? "-16px"
                                : "9px",
                            }
                      }
                    >
                      License expires<RedStar margin="2px 0 0 0">*</RedStar>
                    </FormLabel>

                    <Div position="relative" className="viewdatepicker">
                      <CustomDatePicker
                        id="CustomDatePicker"
                        className="viewcustompicker"
                        fontFamily="'Open Sans', sans-serif"
                        width="200px"
                        height="36px"
                        style={{ marginTop: "9px" }}
                        backgroundColour="#f7f7f7 !important"
                        border={
                          LiceninputListErr[i].licenseExpErr ||
                          LiceninputListErr[i].licenseExpCheckErr
                            ? "1px solid red !important"
                            : "1px solid rgba(34,36,38,.15)"
                        }
                        borderRadius="4px"
                        placeholder="YYYY-MM-DD"
                        value={x.licenseExp}
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "YYYY-MM-DD",
                            true
                          ).isValid();
                          const name = "licenseExp";
                          const listErr = [...LiceninputListErr];
                          listErr[i][name + "Err"] = false;
                          setLiceninputListErr(listErr);
                          const list = [...LiceninputList];
                          list[i][name] = e.target.value;
                          setLiceninputList(list);
                          const list1 = [...LiceninputListErr];
                          list1[i].licenseExpCheckErr = false;
                          setLiceninputListErr(list1);
                          if (e.target.value === "") {
                            list[i]["validate"] = e.target.value;
                            setLiceninputList(list);
                            // setValidDate("");
                          } else if (d) {
                            const list = [...LiceninputList];
                            list[i]["licenseExpCheck"] = e.target.value;
                            setLiceninputList(list);

                            let check = false;
                            if (check) {
                              const name = "licenseExp";
                              const list = [...LiceninputList];
                              list[i][name] = e.target.value;
                              list[i]["licenseExpCheck"] = e.target.value;
                              setLiceninputList(list);
                            } else {
                              list[i]["validate"] = e.target.value;
                              setLiceninputList(list);
                              // setValidDate(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker(e, i)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={(e) => dateSelect(e, i)}
                        showPicker={showpicker === i ? true : false}
                        validDate={x.validate}
                        closePicker={closePicker}
                        min={moment(new Date(), "YYYY-MM-DD")}
                        // min={currentDate}
                      />
                      <Imagecalendar
                        top="7px"
                        right="10px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                    {LiceninputListErr[i].licenseExpErr && (
                      <ErrorMessage margin="5px 0px 5px 0px">
                        Please Select License Expiry Date
                      </ErrorMessage>
                    )}
                    {LiceninputListErr[i].licenseExpCheckErr && (
                      <ErrorMessage margin="5px 0px 5px 0px">
                        Please Select Proper Expiry Date
                      </ErrorMessage>
                    )}
                  </Div>

                  <Div marginLeft="30px">
                    <FormLabel
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="16px"
                      fontWeight="400"
                    >
                      License State<RedStar margin="2px 0 0 0">*</RedStar>
                    </FormLabel>
                    <SelectInput
                      placeholder=" 'None Selected '"
                      width="200px"
                      height="36px"
                      name="license_state"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="0.50px rgba(46, 46, 46, 0.25) solid"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      style={
                        LiceninputListErr[i].licenseStateErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {
                              marginTop: LiceninputListErr[i].licenseExpErr
                                ? "8px"
                                : "8px",
                            }
                      }
                      paddingTop="8px !important"
                      margin="8px 0px 0px 0px"
                      onClick={() => {
                        if (ShowOptions1 === i) {
                          setShowOptions1();
                        } else {
                          setShowOptions1(i);
                        }
                      }}
                    >
                      {x.licenseState}
                    </SelectInput>

                    <DropdownImage
                      height="8px !important"
                      width="12px !important"
                      marginTop="-22px"
                      marginLeft="175px"
                      src={dropdownDownicon}
                      onClick={() => {
                        if (ShowOptions1 === i) {
                          setShowOptions1();
                        } else {
                          setShowOptions1(i);
                        }
                      }}
                    ></DropdownImage>
                    {LiceninputListErr[i].licenseStateErr && (
                      <ErrorMessage margin="5px 0px 5px 0px">
                        Please Enter License State
                      </ErrorMessage>
                    )}
                  </Div>

                  {ShowOptions1 === i &&
                  StatusDropdownOptionList1.length > 0 ? (
                    <DropDownDiv
                      width="200px"
                      overflow="auto"
                      height="261px"
                      background="#FFFFFF"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      marginTop="63px"
                      marginLeft="230px"
                    >
                      {StatusDropdownOptionList1.map((r, ind) => {
                        return (
                          <DropdownOptions
                            key={i}
                            value={r.label}
                            padding="10px 12px 8px 19px"
                            color="#2E2E2E"
                            fontSize="14px"
                            name="licenseState"
                            fontFamily="'Open Sans',sans-serif"
                            onClick={(e) => LicDetDropDownChange(e, i)}
                          >
                            {r.label}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  ) : (
                    ""
                  )}
                </Div>
              </Div>

              <Div margin="80px 0px 0px 30px ">
                {LiceninputList.length !== 1 && (
                  <FiTrash2
                    style={{
                      width: "20px",
                      height: "20px",
                      fill: "#ffffff",
                      stroke: "#f24822",
                      marginLeft: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveClick(i)}
                  />
                )}
              </Div>
            </Div>
          </>
        ))}
        <Div>
          <Button
            width="160px"
            height="36px"
            background="#FFFFFF"
            borderRadius="4px"
            color="#2C7BE5"
            border="0.50px #2C7BE5 solid"
            cursor="pointer"
            marginTop="24px"
            onClick={handleAddClick}
          >
            + Add Licenses
          </Button>
        </Div>
        <Div
          margin="24px -30px 24px -24px"
          borderBottom="1px solid rgba(46, 46, 46, 0.25)"
        ></Div>
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="16px"
          fontWeight="600"
          marginTop="16px"
        >
          Clinician Information
        </FormLabel>

        <Div marginTop="10px" display="flex">
          <Div display="grid">
            <FormLabel
              fontSize="14px"
              color="#2E2E2E"
              fontFamily="'Open Sans', sans-serif"
              lineHeight="16px"
              fontWeight="400"
              marginTop="2px"
            >
              NPI Number<RedStar margin="2px 0 0 0">*</RedStar>
            </FormLabel>
            <Input
              border="0.50px rgba(46, 46, 46, 0.25) solid"
              height="36px"
              width="200px"
              borderRadius="4px"
              paddingLeft="10px"
              paddingRight="10px"
              marginTop="8px"
              style={
                NpiErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : { marginTop: SpecialtyErr ? "-17px" : "8px" }
              }
              value={Npi}
              onChange={(e) => Npichange(e)}
            ></Input>
            {NpiErr && (
              <ErrorMessage margin="5px 0px 5px 0px">
                Please Enter Npi Number
              </ErrorMessage>
            )}
          </Div>

          <Div marginLeft="30px">
            <FormLabel
              fontSize="14px"
              color="#2E2E2E"
              fontFamily="'Open Sans', sans-serif"
              lineHeight="16px"
              fontWeight="400"
            >
              Speciality<RedStar margin="2px 0 0 0">*</RedStar>
            </FormLabel>

            <SelectInput
              placeholder=" 'None Selected'"
              width="200px"
              height="36px"
              name="specialty"
              background="#FFFFFF"
              fontSize="14px"
              fontFamily="'Open Sans',sans-serif"
              border="0.50px rgba(46, 46, 46, 0.25) solid"
              borderRadius="4px"
              color="#2E2E2E"
              margin="8px 0px 0px 0px"
              textOverflow="ellipsis"
              overFlow="hidden"
              whiteSpace="nowrap"
              padding="8px 27px 0 10px !important"
              style={
                SpecialtyErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : { marginTop: NpiErr ? "8px" : "8px" }
              }
              onClick={() => setShowOptions2(!ShowOptions2)}
            >
              {Specialty}
            </SelectInput>

            <DropdownImage
              height="8px !important"
              width="12px !important"
              marginTop="-22px"
              marginLeft="175px"
              src={dropdownDownicon}
              onClick={() => setShowOptions2(!ShowOptions2)}
            ></DropdownImage>
            {SpecialtyErr && (
              <ErrorMessage margin="5px 0px 5px 0px">
                Please Select Speciality
              </ErrorMessage>
            )}
          </Div>

          {ShowOptions2 && StatusDropdownOptionList2.length > 0 ? (
            <DropDownDiv
              width="200px"
              overflow="auto"
              height="261px"
              background="#FFFFFF"
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              marginTop="64px"
              marginLeft="230px"
            >
              {StatusDropdownOptionList2.map((r, i) => {
                return (
                  <DropdownOptions
                    key={i}
                    value={r.label}
                    padding="10px 12px 8px 19px"
                    color="#2E2E2E"
                    fontSize="14px"
                    fontFamily="'Open Sans',sans-serif"
                    onClick={(e) => setSpecialtyDropDown(e)}
                  >
                    {r.label}
                  </DropdownOptions>
                );
              })}
            </DropDownDiv>
          ) : (
            ""
          )}
        </Div>
        <Div marginTop="16px" display="flex">
          <Div display="grid">
            <FormLabel
              fontSize="14px"
              color="#2E2E2E"
              fontFamily="'Open Sans', sans-serif"
              lineHeight="16px"
              fontWeight="400"
            >
              Taxonomy Code<RedStar margin="2px 0 0 0">*</RedStar>
            </FormLabel>
            <Input
              border="0.50px rgba(46, 46, 46, 0.25) solid"
              height="36px"
              width="200px"
              borderRadius="4px"
              paddingLeft="10px"
              paddingRight="10px"
              marginTop="8px"
              style={
                TaxonomyErr
                  ? { border: "1px solid red", borderRadius: "5px" }
                  : {}
              }
              value={Taxonomy}
              onChange={(e) => Taxonomychange(e)}
            ></Input>
          </Div>
          {TaxonomyErr && (
            <ErrorMessage margin="5px 0px 5px 0px">
              Please Select Taxonomy
            </ErrorMessage>
          )}
          <Div marginLeft="30px">
            <FormLabel
              fontSize="14px"
              color="#2E2E2E"
              fontFamily="'Open Sans', sans-serif"
              lineHeight="16px"
              fontWeight="400"
            >
              Audio Platform<RedStar margin="2px 0 0 0">*</RedStar>
            </FormLabel>

            <SelectInput
              placeholder=" 'None Selected'"
              width="200px"
              height="36px"
              name="audioplatform"
              background="#FFFFFF"
              fontSize="14px"
              fontFamily="'Open Sans',sans-serif"
              border="0.50px rgba(46, 46, 46, 0.25) solid"
              borderRadius="4px"
              color="#2E2E2E"
              margin="8px 0px 0px 0px"
              textOverflow="ellipsis"
              overFlow="hidden"
              whiteSpace="nowrap"
              padding="8px 27px 0 10px !important"
              onClick={() => setShowOptions3(!ShowOptions3)}
              style={
                audioErr ? { border: "1px solid red", borderRadius: "5px" } : {}
              }
            >
              {audioPlatform}
            </SelectInput>

            <DropdownImage
              height="8px !important"
              width="12px !important"
              marginTop="-22px"
              marginLeft="175px"
              src={dropdownDownicon}
              onClick={() => setShowOptions3(!ShowOptions3)}
            ></DropdownImage>
            {audioErr && (
              <ErrorMessage position="absolute" margin="5px 0px 5px 0px">
                Please Select audioplatform
              </ErrorMessage>
            )}
          </Div>

          {ShowOptions3 && AudioPlatformList.length > 0 ? (
            <DropDownDiv
              width="200px"
              overflow="auto"
              height="auto"
              background="#FFFFFF"
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              marginTop="64px"
              marginLeft="230px"
            >
              {AudioPlatformList.map((r, i) => {
                return (
                  <DropdownOptions
                    key={i}
                    value={r.label}
                    padding="10px 12px 8px 19px"
                    color="#2E2E2E"
                    fontSize="14px"
                    fontFamily="'Open Sans',sans-serif"
                    onClick={(e) => setAudioplatfromDropDown(e)}
                  >
                    {r.label}
                  </DropdownOptions>
                );
              })}
            </DropDownDiv>
          ) : (
            ""
          )}
        </Div>
        <Div
          margin="24px -30px 24px -24px"
          borderBottom="1px solid rgba(46, 46, 46, 0.25)"
        ></Div>
        <Div>
          <Button
            width="136px"
            height="36px"
            background="#2C7BE5"
            borderRadius="4px"
            color="#FFFFFF"
            border="none !important"
            cursor="pointer"
            onClick={handlesave}
            disabled={dis}
            isDisabled={dis}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Save
          </Button>
        </Div>
      </Div>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};
export default Clincial;
