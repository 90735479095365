import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Div,
  Span,
  PageSubTitle,
  FormGroup,
  Text,
  Button,
} from "../../../../StyledComponents";
import {
  MainContainer,
  SelectBox,
  OptionsContainer,
  OptionItem,
  ArrowIcon,
  DeleteIcon,
  Input,
  ButtonDevice,
  OptionDiv,
} from "./styles";
import { useDispatch } from "react-redux";
import {
  SetTokenCheck,
  setIntegrationResponse,
} from "../../../../../StateManagement/Reducers/PatientDemoState";

const MultiSelect = ({
  checkDel,
  options,
  width,
  selectedOptions,
  onSelectedOptionsChange,
  ExcludedSerialCheckList,
  handleConnection,
  connectionPending,
  SetDisconnect,
  setDis,
  setConVal,
  conval,
}) => {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//
  const toggleOptions = () => {
    if (selectedOptions.length > 0 && selectedOptions[0]?.id) {
      return;
    } else {
      setIsOpen(!isOpen);
    }
  };
  const handleOptionClick = (option, i) => {
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );
    if (isSelected) {
      return;
    }
    let updatedOptions = [
      {
        ...option,
        serialNo: "",
      },
    ];
    if (ExcludedSerialCheckList.includes(option.value)) {
      if (["Ambrosia App", "Tidepool"].includes(option.name)) {
        updatedOptions = [
          {
            ...option,
            email: "",
            password: "",
            connected: false,
            serialNo: "",
          },
        ];
      } else {
        updatedOptions = [
          {
            ...option,
            connected: false,
            serialNo: "",
          },
        ];
      }
    }
    onSelectedOptionsChange(updatedOptions, i);
    setIsOpen(!isOpen);
  };
  const handleSerialNoChange = (index, value) => {
    const updatedOptions = JSON.parse(JSON.stringify(selectedOptions));
    updatedOptions[index].serialNo = value.trim();
    updatedOptions[index].error = false;
    // Validate the serial number for the specific option
    if (!value || value.trim() === "") {
      updatedOptions[index].hasValidationError = true;
      // setDis(false);
    } else {
      updatedOptions[index].hasValidationError = false;
    }

    onSelectedOptionsChange(updatedOptions, index);
  };

  const handleCredentials = (option, index, value, name) => {
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const updatedOptions = [
      {
        ...option,
        [name]: value,
        error: name === "email" ? !regex.test(value) : false,
        Msg:
          name === "email" && !regex.test(value) ? "Enter a valid Email" : "",
        Status: 0,
      },
    ];

    onSelectedOptionsChange(updatedOptions, index);
  };

  const validateCredentials = (option, index) => {
    setConVal(option.value);
    let updatedOptions = [];
    if (option["email"] !== "" && option["password"] !== "") {
      handleConnection(option);
      updatedOptions = [
        {
          ...option,
          error: false,
          Msg: "",
        },
      ];
    } else {
      updatedOptions = [
        {
          ...option,
          error: true,
          Msg: "Enter Email and Password",
        },
      ];
      onSelectedOptionsChange(updatedOptions, index);
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    onSelectedOptionsChange(updatedOptions, index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const returnElements = (option, index) => {
    if (
      option?.name.includes("Terra") ||
      option?.name.includes("Dexcom") ||
      option?.name.includes("Marsonik") ||
      option?.name.includes("Marsonic")
    ) {
      return (
        <React.Fragment key={`${option.value}${index}`}>
          <OptionDiv
            color="#2E2E2E"
            lineHeight="19px"
            width="75%"
            fontWeight="600"
            fontSize="14px"
          >
            {option.name}
          </OptionDiv>
          <FormGroup display="flex">
            <ButtonDevice
              disableActive={true}
              // marginLeft="40px"
              height="36px"
              background={
                (option.connected && option.Msg === "") ||
                option?.Status === "1"
                  ? "rgba(44, 123, 229, 0.10)"
                  : option.Status == "2"
                  ? "rgba(242, 72, 34, 0.10)"
                  : "rgba(44, 123, 229, 0.10)"
              }
              borderRadius="4px"
              padding="8px 21px"
              color={
                (option.connected && option.Msg === "") ||
                option?.Status === "1"
                  ? "#34A853"
                  : option.Status == "2"
                  ? "#F24822"
                  : "#2C7BE5"
              }
              // color=""
              border="none"
              lineHeight="19px"
              fontFamily="'Open Sans',sans-serif"
              fontSize="14px"
              fontWeight="600"
              onClick={() => {
                if (option?.Status === "1") {
                  return;
                } else {
                  dispatch(SetTokenCheck(""));
                  dispatch(setIntegrationResponse(""));
                  handleConnection(option);
                  checkDel(true);
                  setConVal(option.value);
                }
              }}
              cursor={
                connectionPending || option?.Status === "1"
                  ? "not-allowed"
                  : "pointer"
              }
              disabled={connectionPending || option.connected}
              title={connectionPending ? "Please wait" : "Connect"}
            >
              {(option.connected && option.Msg === "") || option?.Status === "1"
                ? "Connected"
                : connectionPending && option.value === conval
                ? "Connecting"
                : option.Status == "2"
                ? "Disconnected"
                : "Connect"}
            </ButtonDevice>
          </FormGroup>
        </React.Fragment>
      );
    } else if (
      option?.name.includes("Ambrosia") ||
      option?.name.includes("Tidepool")
    ) {
      return (
        <SelectBox
          key={`${option.value}${index}`}
          width={width}
          border="none"
          padding="0px"
          display="block"
          alignItems="center"
          JustifyContent="space-between"
          backgroundColor="#FFFFFF"
          className="ipadadddiv"
        >
          <Div
            key={option.value}
            display={"block"}
            color="#2E2E2E"
            lineHeight="19px"
            marginBottom="8px"
            width="75%"
            fontWeight="600"
            fontSize="14px"
          >
            {option.name}
          </Div>
          <Div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="8px"
            gap="20px"
          >
            <Input
              border={
                (option.error === true &&
                  option.Msg !== "Enter Email and Password") ||
                (option.error === true && option.email === "")
                  ? "1px solid red"
                  : "1px solid #2E2E2E40"
              }
              readOnly={option.id || option.connected ? true : false}
              padding="8px"
              placeholder="Email"
              name="email"
              borderRadius="4px"
              width="calc(100% - 10px)"
              height="36px"
              id={`${option.value}${index}`}
              value={option.email}
              onChange={(e) => {
                handleCredentials(option, index, e.target.value, e.target.name);
              }}
              error={option.hasValidationError}
            />

            <Input
              border={
                (option.error === true &&
                  option.Msg !== "Enter Email and Password") ||
                (option.error === true && option.password === "")
                  ? "1px solid red"
                  : "1px solid #2E2E2E40"
              }
              readOnly={option.id || option.connected ? true : false}
              padding="8px"
              placeholder="Password"
              borderRadius="4px"
              width="calc(100% - 10px)"
              height="36px"
              name="password"
              id={`${option.value}${index}`}
              value={option.password}
              onChange={(e) => {
                handleCredentials(option, index, e.target.value, e.target.name);
              }}
              error={option.hasValidationError}
            />
          </Div>
          <ButtonDevice
            height="36px"
            background={
              option.connected
                ? "rgba(25, 140, 85, 0.10)"
                : (!option.connected &&
                    !!option?.Msg &&
                    option?.Msg === "Devices is not Connected") ||
                  option.Status == "2"
                ? "rgba(242, 72, 34, 0.10)"
                : "rgba(44, 123, 229, 0.10)"
            }
            borderRadius="4px"
            padding="8px 21px"
            color={
              option.connected
                ? "#34A853"
                : (!option.connected &&
                    !!option?.Msg &&
                    option?.Msg === "Devices is not Connected") ||
                  option.Status == "2"
                ? "#F24822"
                : "#2C7BE5"
            }
            border="none"
            lineHeight="19px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="14px"
            fontWeight="600"
            onClick={() => validateCredentials(option, index)}
            cursor={connectionPending || option.id ? "not-allowed" : "pointer"}
            disabled={connectionPending || option.connected}
            title={connectionPending ? "Please wait" : "Connect"}
          >
            {option.connected
              ? "Connected"
              : !option.connected &&
                connectionPending &&
                option.value === conval
              ? "Connecting"
              : (!option.connected &&
                  !!option?.Msg &&
                  option?.Msg === "Devices is not Connected") ||
                option.Status == "2"
              ? "Disconnected"
              : "Connect"}
          </ButtonDevice>
        </SelectBox>
      );
    } else {
      return (
        <React.Fragment key={`${option.value}${index}`}>
          <OptionDiv
            key={option.value}
            color="#2E2E2E"
            lineHeight="19px"
            // marginBottom="8px"
            width="75%"
            fontWeight="600"
            fontSize="14px"
            // paddingRight="8px"
            // marginTop="16px"
          >
            {option.name}
          </OptionDiv>
          <FormGroup display="flex">
            <Input
              readOnly={option.id ? true : false}
              border={
                option.error === true ? "1px solid red" : "1px solid #2E2E2E40"
              }
              padding="8px"
              placeholder="Serial No"
              borderRadius="4px"
              width="100px"
              height="36px"
              marginLeft="auto"
              // marginRight="20px"
              id={`${option.value}${index}`}
              value={option.serialNo}
              onChange={(e) => {
                if (!e.target.value.includes(" ")) {
                  // setDis(true);
                  handleSerialNoChange(index, e.target.value);
                }
              }}
            />
          </FormGroup>
        </React.Fragment>
      );
    }
  };

  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start----------------------------------------------------------- //
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <MainContainer
      position="relative"
      display="block"
      ref={dropdownRef}
      // key={options[0]?.value}
    >
      <SelectBox
        onClick={toggleOptions}
        title={
          selectedOptions.length > 0 && selectedOptions[0]?.id
            ? "Device already exists"
            : ""
        }
        width={width}
        border="1px solid rgba(46, 46, 46, 0.25);"
        padding="7px 14px 7px 14px"
        cursor="pointer"
        display="block"
        alignItems="center"
        JustifyContent="space-between"
        borderRadius="4px"
        backgroundColor="#FFFFFF"
        fontWeight="400"
        fontSize="14px"
        className="ipadadddiv"
      >
        <ArrowIcon></ArrowIcon>
        <Span lineHeight="22px" fontFamily="'Open Sans',sans-serif">
          Select Device
        </Span>
      </SelectBox>
      {selectedOptions.length > 0 &&
        selectedOptions.map((option, index) => (
          <React.Fragment key={`${option.value}-${index}`}>
            <Div
              display={
                option?.name.includes("Ambrosia") ||
                option?.name.includes("Tidepool")
                  ? "block"
                  : "flex"
              }
              position="relative"
            >
              <Div
                display={"flex"}
                marginTop="20px"
                marginBottom="40px "
                alignItems="center"
                // justifyContent="space-between"
                width="100%"
              >
                {returnElements(option, index)}
              </Div>
              <DeleteIcon
                cursor={"pointer"}
                onClick={() => {
                  if (option.id) {
                    SetDisconnect(true, option);
                  } else {
                    handleRemoveOption(index);
                    checkDel(false, option.value);
                  }
                }}
              />
            </Div>
            {option.error === true && (
              <Text
                id={`error_text${option.value}${index}`}
                display={option.error === true ? "block" : "none"}
                marginTop="-33px"
                color="red"
                fontSize="12px"
              >
                {option.Msg !== "" ? option.Msg : "Enter a valid Serial No"}
              </Text>
            )}
          </React.Fragment>
        ))}
      {isOpen && (
        <OptionsContainer>
          {options.map((option, i) => (
            <OptionItem
              key={option.value}
              onClick={() => handleOptionClick(option, i)}
              backgroundColor={
                selectedOptions.some(
                  (selectedOption) => selectedOption.value === option.value
                ) && "#2C7BE5"
              }
            >
              {option.name}
            </OptionItem>
          ))}
        </OptionsContainer>
      )}
    </MainContainer>
  );
};
export default MultiSelect;
