import React, { useState, useEffect, useRef } from "react";
import AddDevice from "./AddDevice";
import {
  Button,
  Container,
  ImageContainer,
  BodyContainer,
  PageTitle,
  Div,
  Span,
  Modal,
  ModalContainer,
  Image,
} from "../../../StyledComponents";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceList } from "../../../../StateManagement/Reducers/PatientDemoState";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import Blood_Pressure from "../../../../Assets/images/Blood_Pressure.png";
import Glucose_Monitor from "../../../../Assets/images/Glucose_Monitor.png";
import Heart_Rate from "../../../../Assets/images/Heart_Rate.png";
import Pulse_Oximeter from "../../../../Assets/images/Pulse_Oximeter.png";
import Weight_Scale from "../../../../Assets/images/Weight_Scale.png";

const DevicesSheet = () => {
  // ------------------------------ State and Variables Start---------------------------------------------------//

  const [show, setShow] = useState(false);
  const params = useParams();
  const pid = params.pid;
  const dispatch = useDispatch();
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const devicesList = useSelector((state) => state.PatientDemo.DeviceList);
  const modalRef = useRef(null);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const deviceModelToImage = {
    "Bodytrace Blood Pressure Monitor": Blood_Pressure,
    "iBlood Pressure": Blood_Pressure,
    "iBlood Pressure plus": Blood_Pressure,
    "iBlood Pressure 2.0": Blood_Pressure,
    "iBlood Pressure Classic": Blood_Pressure,
    'Marsonik': Blood_Pressure,
    'iGlucose': Glucose_Monitor,
    "Marsonik Blood Glucose Monitor": Glucose_Monitor,
    "Tenovi Blood Glucose Meter": Glucose_Monitor,
    'Dexcom': Glucose_Monitor,
    "Ambrosia App": Glucose_Monitor,
    'Tidepool': Glucose_Monitor,
    "Terra App - Freestyle Libre": Glucose_Monitor,
    "Bodytrace Weight Scale": Weight_Scale,
    "Tenovi Weigh Scale": Weight_Scale,
    'iScale': Weight_Scale,
    "iScale plus": Weight_Scale,
    "iPlulse Ox": Pulse_Oximeter,
    "Tenovi Pulse Oximeter": Pulse_Oximeter,
    'Marsonic': Pulse_Oximeter,
    "Terra App - Omron": Heart_Rate,
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleClick = (event) => {
    if (modalRef.current !== null) {
      if (show && modalRef.current.contains(event.target)) {
        document.body.style.overflow = "hidden";
      }
      if (!show) {
        document.body.style.overflow = "";
      }
    }
    event.preventDefault();
  };

  const showOverflow = () => {
    document.body.style.overflow = "";
  };
  const denied_call = () => {
    setModalMsg("Permission Denied");
    setStatus("Failed");
    setModalAlerShow(true);
    setTimeout(() => {
      setModalAlerShow(false);
      setStatus("");
      setModalMsg("");
    }, 1500);
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  useEffect(() => {
    dispatch(getDeviceList(pid));
  }, [pid]);

  useEffect(() => {
    document.addEventListener("click", handleClick, { passive: false });
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [show]);

  return (
    <>
      {/* {deviceLoading ? (
        <Div>loading</Div>
      ) : ( */}
      <BodyContainer padding="8px 15px 18px 12px">
        <Container display="flex">
          <PageTitle
            lineHeight="25px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="18px"
            fontWeight="600"
          >
            Device Assigned
          </PageTitle>
          <Button
            ref={modalRef}
            marginLeft="auto"
            height="36px"
            background="#2C7BE5"
            borderRadius="4px"
            padding="8px 21px"
            color="#FFFFFF"
            border="none"
            lineHeight="19px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="14px"
            fontWeight="600"
            cursor={
              SingPatDetails.enrollment_status !== "4"
                ? "pointer"
                : "not-allowed"
            }
            onClick={() => {
              if (
                SingPatDetails.enrollment_status !== "4" &&
                permissions.includes("device_assign")
              ) {
                setShow(true);
              } else {
                denied_call();
              }
            }}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Add Devices
          </Button>
        </Container>
        <Container marginTop="13px" display="flex" flexWrap="wrap">
          {devicesList &&
          devicesList.length !== 0 &&
          devicesList["Device_model"].length !== 0 ? (
            devicesList["Device_model"].map((device, index) => (
              <Div key={index} marginRight="40px">
                <Image
                  height="123px"
                  width="116px"
                  backgroundColor=" rgba(46, 46, 46, 0.1)"
                  borderRadius="8px"
                  marginBottom="8px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  src={deviceModelToImage[device.device_model] || ""}
                ></Image>
                <Span
                  color="#2E2E2E"
                  lineHeight="22px"
                  fontFamily="'Open Sans',sans-serif"
                  fontSize="14px"
                  fontWeight="400"
                  width="116px"
                  textAlign="center"
                >
                  {device.device_model}
                </Span>
              </Div>
            ))
          ) : (
            <Div margin="auto">
              <Image height="150px" width="150px" src={Emp_data_pic}></Image>
              <Span
                fontSize="14px"
                color="#2E2E2E"
                fontFamily="'Open Sans',sans-serif"
                lineHeight="20px"
                fontWeight="400"
                textAlign="center"
              >
                No Devices to be shown,Please add some.
              </Span>
            </Div>
          )}
        </Container>
      </BodyContainer>
      {/* )} */}
      {show && (
        <Modal
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          show={show}
          zIndex="1150"
        >
          <ModalContainer
            width="100%"
            height="100%"
            borderRadius="8px"
            border="1px solid rgba(46, 46, 46, 0.25)"
            maxWidth="100%"
          >
            <AddDevice
              showOverflow={showOverflow}
              onHide={closeModal}
              pid={pid}
              enrolledDevices={devicesList}
            />
          </ModalContainer>
        </Modal>
      )}

      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default DevicesSheet;
