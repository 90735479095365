import { createSlice } from "@reduxjs/toolkit";

export const preLoginSlice = createSlice({
  name: "PreLogin",
  initialState: {
    EmailResp: {},
    passWordRes: {},
    ReEmailResp: {},
    NewPswd: "",
    ResetVerify: {},
    showSuccess: false,
  },
  reducers: {
    GetEmailRes: (state) => {
      return state;
    },
    setEmailRes: (state, action) => {
      state.EmailResp = action.payload;
    },
    GetReEmailRes: (state) => {
      return state;
    },
    setReEmailRes: (state, action) => {
      state.ReEmailResp = action.payload;
    },
    GetNewPswd: (state) => {
      return state;
    },
    SetNewPswd: (state, action) => {
      state.NewPswd = action.payload;
    },
    GetResetVerify: (state, action) => {
      return state;
    },
    SetResetVerify: (state, action) => {
      state.ResetVerify = action.payload;
    },
    setEmptyForgetResponse: (state) => {
      return state;
    },
    GetPassWordLength: (state) => {
      return state;
    },
    PostUserData: (state) => {
      return state;
    },
    userSignupEnd: (state, action) => {
      if (action.payload !== "failed") {
        state.showSuccess = true;
      }
    },
  },
});

export const {
  GetEmailRes,
  setEmailRes,
  GetReEmailRes,
  setReEmailRes,
  GetNewPswd,
  SetNewPswd,
  NewPswd,
  GetResetVerify,
  SetResetVerify,
  ResetVerify,
  setEmptyForgetResponse,
  GetPassWordLength,
  PostUserData,
  userSignupEnd,
} = preLoginSlice.actions;

export default preLoginSlice.reducer;
