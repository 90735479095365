import React from "react";
import { Button, Div, Modal, ModalContainer, Text } from "../StyledComponents";

import { LockIcon } from "./Styles";
const SubScriberFeature = () => {
  const upgradPlan = () => {
    window.open("https://vozohealth.com/pricing", "_blank");
  };
  return (
    <>
      <Modal
        show="flex"
        justifyContent="center"
        width="100%"
        height="100%"
        zIndex="9999"
      >
        <ModalContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="static"
          borderRadius="2px"
          backgroundColor="#F1F5F8"
          border="1px solid transparent"
          borderColor=" rgba(255, 255, 255, 1)"
        >
          <Div
            padding="32px 127px  30px 128px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Div display="flex" alignItems="center">
              <LockIcon />
              <Text
                fontSize="18px"
                color="rgba(46, 46, 46, 1)"
                fontweight="400"
                fontFamily="'Open Sans', sans-serif"
                Line
                height=" 24.51px"
                marginBottom="16px"
              >
                Subscriber Feature
              </Text>
            </Div>
            <Text
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="18px"
              lineHeight=" 24.51px"
              color="rgba(46, 46, 46, 0.75)"
              marginBottom="24px"
            >
              Your demo account has expired, get an onboarding call from Vozo
              RPM team
            </Text>
            <Button
              height="36px"
              width="160px"
              cursor="pointer"
              backgroundColor="rgba(44, 123, 229, 1)"
              borderRadius="4px"
              color="rgba(255, 255, 255, 1)"
              fontFamily="'Open Sans', sans-serif"
              fontSize="14px"
              fontWeight="600"
              border="1px solid #2C7BE5"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
              hoverBackColor="#005FB2"
              lineHeight="19.07px"
              onClick={upgradPlan}
            >
              Schedule a Call
            </Button>
          </Div>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default SubScriberFeature;
