import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";

let DBHost =
  localStorage.getItem("DBHost") &&
  Decrypt_Value(localStorage.getItem("DBHost"), "vozorpm");
let finalurl = "";
let firstDot = window.location.hostname.split(".");
if (firstDot[0] !== "localhost") {
  finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
    "oemr",
    firstDot[0]
  );
} else {
  finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
}

if (
  firstDot[0] === "localhost" ||
  firstDot[0] === "mumbairpm" ||
  firstDot[0] === "production"
) {
  DBHost = "default";
} else {
  DBHost = firstDot[0];
}

export default { finalurl, DBHost };
