import { takeLatest } from "redux-saga/effects";
import {
  GetAllDevices,
  getRecords,
} from "../../StateManagement/Reducers/DevicesState";
import { AllDevicesApi, AllRecordsApi } from "./DevicesSagaWorkers";

export function* AllDevicesSaga() {
  yield takeLatest(GetAllDevices.type, AllDevicesApi);
}
export function* GetDevicesRecordsSaga() {
  yield takeLatest(getRecords.type, AllRecordsApi);
}
