import styled, { css } from "styled-components";
import { MdCheck } from "react-icons/md";
import { Select } from "../../StyledComponents";

export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none !important;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;

export const Container1 = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  padding: ${(p) => p.padding && p.padding};
`;
export const TabPanel = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  display: ${(p) => p.display && p.display};
  text-align: ${(p) => p.textAlign && p.textAlign};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;
export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: 18px;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 82px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                         margin-left: 22px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 80px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 80px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 84px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(117px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(123px);
                          width: 76px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(123px);
                          width: 76px;
                        }
                         }
               `;
    }
    if (props.line === 3) {
      return `
              &::after {
                        content: "";
                        width: 98px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                         margin-top: 16px;
                        transform : translateX(236px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(236px);
                          width: 95px;
                        }
                         }
               `;
    }
    if (props.line === 4) {
      return `
              &::after {
                        content: "";
                        width: 96px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 16px;
                        transform : translateX(370px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(370px);
                          width: 95px;
                        }
                        
                        @media (max-width: 750px) {
                          transform : translateX(371px);
                          width: 95px;
                        }
                         }
               `;
    }
  }}
`;

export const TextArea = styled.textarea`
  outline: none;
  width: 100%;
  min-height: 80px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
  &:hover {
    border: 1px solid rgba(46, 46, 46, 0.25);
  }
`;

export const Checkbox = styled.label`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  cursor: pointer;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const CheckboxCheckmark = styled.div`
  width: 100%;
  height: 100%;
  border: ${({ checked }) => (checked ? "4px solid #2C7BE5" : "transparent")};
  border-radius: 2px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  padding: 5px 12px;
  background-color: #ffffff;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #cbd5e0;
  position: relative;
  width: ${(p) => p.width && p.width};

  margin-top: 14px;
  height: 38px;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  max-height: 250px;
  overflow-y: auto;
  z-index: 1;
`;

export const DropdownItem = styled.li`
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
  display: block;
  font-size: 14px;

  &:hover {
    background-color: #2c7be5;
    color: #ffffff;
  }

  &.selected {
    background-color: #2c7be5;
    color: #fff;
  }
`;

export const DropdownLabel = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
  color: #2e2e2e;
`;

export const DropdownInput = styled.select`
  padding: 8px 12px;
  background-color: #ffffff;
  color: #2e2e2e;
  border: 1px solid #2c7be5;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2c7be5;
    color: #ffffff;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px !important;
  font-family: "Open Sans", sans-serif;
`;

export const Ptag = styled.p`
  color: rgba(54, 57, 59, 0.45);
  font-weight: 400;
  font-size: 12px;
  font-family: sans-serif;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  top: 0px;
  left: 0px;
  border-radius: 4px;
  background: #ffff;
  border: 0.5px solid #d8e0f0;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
    background: #ffffff;
  }
`;

export const TogggleCheck = styled.input`
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  height: 16px;
  width: 16px;
  top: 0px;
  left: 0px;
  position: relative;
  background: #ffff;
  &:checked + ${CheckBoxLabel} {
    background: #407bff;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const ModalBody = styled.div`
  padding: ${(p) => p.padding && p.padding};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  border-top: ${(p) => p.borderTop && p.borderTop};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border: ${(p) => p.border && p.border};
  overflow: ${(p) => p.overflow && p.overflow};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: lightgrey;
  }
`;
export const StyledSelect = styled(Select)`
  width: 30%;
  display: inline-block;
  /* margin-right: 10px; */
`;

export const CustomSelect = styled.div`
  position: relative;
  width: 35%;
  outline: none;
`;

export const SelectButton = styled.button`
  width: 100%;
  padding: 10px 2px 10px 2px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
`;

export const OptionList = styled.ul`
  position: absolute;
  top: -252px;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ced4da;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  z-index: 1;
  width: 192px;
  overflow-y: auto;
  height: 250px;
  
`;

export const OptionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover{
    background-color: #2c7be5;
  }
`;
