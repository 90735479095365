import React from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import AddTreatmentPlan from "./AddTreatmentPlan";

const AddTreatment = () => {
  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div
        marginLeft="56px"
        // className="responsive-right"
        backgroundColor="#F1F5F8"
      >
        <Div padding="20px">
          <AddTreatmentPlan />
        </Div>
      </Div>
    </Container>
  );
};
export default AddTreatment;
