import React, { useState, useRef } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Modclosebtn,
  ButtonGroup,
  Div,
  Input,
  Span,
  SpanTag,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SelectInput,
  DropdownImage,
  DropDownDiv,
  DropdownOptions,
  SearchIconSVG,
  Text,
} from "../../StyledComponents";
import {
  AllergyDropdown,
  AllergyOption,
} from "../../Patient/PatientDemo/styles";
import { Imagecalendar, TextArea } from "../styles";
import { FaTimes } from "react-icons/fa";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";
import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import calendar_icon_custom from "../../../Assets/images/calendar_icon_custom.png";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import moment from "moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetInsertList,
  GetUpdateList,
} from "../../../StateManagement/Reducers/HealthState";
import {
  GetICD10List,
  GetICD9List,
} from "../../../StateManagement/Reducers/MedicalState";
import { AiOutlineSearch } from "react-icons/ai";
const AddProblem = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const [ct, setCt] = useState("");
  const [comments, setComments] = useState("");
  const [ProblemType, setProblemType] = useState("");
  const [StatusType, setStatusType] = useState("");
  const [Statuslabel, setStatuslabel] = useState("");
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [showpicker, setShowPicker] = useState(false);
  const [showpicker1, setShowPicker1] = useState(false);
  const [StatusTypeErr, setStatusTypeErr] = useState(false);
  const [ProblemTypeErr, setProblemTypeErr] = useState(false);
  const [StartTypeErr, setStartTypeErr] = useState(false);
  const [EndTypeErr, setEndTypeErr] = useState(false);
  const [ctTypeErr, setCtTypeErr] = useState(false);
  const [StartTypeErrcheck, setStartTypeErrcheck] = useState(false);
  const [EndTypeErrcheck, setEndTypeErrcheck] = useState(false);
  const [DatediffErrcheck, setDatediffErrcheck] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [ICDType, setICDType] = useState("");
  const [ICDTypeErr, setICDTypeErr] = useState(false);
  const [icdoption, seticdoption] = useState("");
  const [loading, setLoading] = useState(true);
  const [icdoption1, seticdoption1] = useState("");
  const [disable, setdisable] = useState(true);
  const [identifyErr, setIdentifyErr] = useState(false);
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const dispatch = useDispatch();

  const [aptdate, setaptdate] = useState("");
  const [onDate, setOnDate] = useState("");
  const [aptdatecheck, setaptdatecheck] = useState("");
  const [onDatecheck, setOnDatecheck] = useState("");
  const [validDate, setValidDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [validDate1, setValidDate1] = useState(
    moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
  );
  const [dis, setDis] = useState(false);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const icdstyle = () => {
    return {
      cursor: disable ? "not-allowed" : "pointer",
      border: ICDTypeErr || identifyErr ? "1px solid red" : "",
      borderRadius: ICDTypeErr || identifyErr ? "5px" : "",
    };
  };
  const handlepicker = (e) => {
    e.preventDefault();
    if (showpicker === false) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
    setShowOptions1(false);
    setShowPicker1(false);
  };
  const handlepicker1 = (e) => {
    e.preventDefault();
    if (showpicker1 === false) {
      setShowPicker1(true);
    } else {
      setShowPicker1(false);
    }
    setShowPicker(false);
  };

  const ProblemLists1 = useSelector((state) => state.Health.EditList);

  const ICDList9 = useSelector((state) => state.Medical.ICD9List);
  const DropdownOptionList1 = [
    { label: "ICD9", id: "1", Key: "ICD9" },
    { label: "ICD10", id: "2", Key: "ICD10" },
  ];
  const DropdownOptionList = [
    { label: "Active", id: "1", Key: "Active" },
    { label: "Inactive", id: "2", Key: "Inactive" },
    { label: "Resolved", id: "3", Key: "Resolved" },
  ];

  const closePicker = () => {
    setShowPicker(false);
  };
  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setaptdate(moment(value).format("YYYY-MM-DD"));
      setaptdatecheck(moment(value).format("YYYY-MM-DD"));
      setStartTypeErr(false);
      setStartTypeErrcheck(false);
      setDatediffErrcheck(false);
      setValidDate(new Date(value));
    } else {
      setaptdate("");
      setaptdatecheck("");
      setValidDate("");
    }

    closePicker();
  };

  const closePicker1 = () => {
    setShowPicker1(false);
  };
  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setOnDate(moment(value).format("YYYY-MM-DD"));
      setOnDatecheck(moment(value).format("YYYY-MM-DD"));
      setEndTypeErr(false);
      setEndTypeErrcheck(false);
      setValidDate1(new Date(value));
    } else {
      setOnDate("");
      setOnDatecheck("");
      setValidDate1("");
    }
    closePicker1();
  };

  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };

  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker();
      closePicker1();
    }
  };

  const setStatusTypeSelect = (r) => {
    if (r.label === "Inactive") {
      setStatuslabel("0");
    } else if (r.label === "Active") {
      setStatuslabel("1");
    } else {
      setStatuslabel("2");
    }

    setStatusType(r.label);
    setShowOptions1(false);
    if (r.label !== "") {
      setStatusTypeErr(false);
    }
  };

  const setProblemTypeSelect = (r) => {
    setdisable(false);

    setProblemType(() => {
      return r.label;
    });
    if (r.label === "ICD9") {
      dispatch(GetICD9List(ICDType));
    } else {
      dispatch(GetICD10List(ICDType));
    }
    setShowOptions(false);
    setProblemTypeErr(false);
  };

  const HandleSave = () => {
    let err = 0;

    if (ProblemType === "") {
      setProblemTypeErr(true);
      err = 2;
    } else if (icdoption1 === "") {
      setICDTypeErr(true);
      err = 2;
    } else if (icdoption1 !== selectedValue) {
      setIdentifyErr(true);
      err = 2;
    }

    if (StatusType === "") {
      setStatusTypeErr(true);
      err = 2;
    }

    if (ct === "") {
      setCtTypeErr(true);
      err = 2;
    }
    if (aptdate === "") {
      setStartTypeErr(true);
      err = 2;
    } else if (aptdate !== aptdatecheck) {
      setStartTypeErrcheck(true);
      setDatediffErrcheck(false);
      err = 2;
    }
    if (aptdate > onDate) {
      setDatediffErrcheck(true);
      err = 2;
    }

    if (onDate === "") {
      setEndTypeErr(true);
      err = 2;
    } else if (onDate !== onDatecheck) {
      setEndTypeErrcheck(true);
      setDatediffErrcheck(false);
      err = 2;
    }

    if (err > 0) {
      return false;
    }
    setDis(true);
    if (props.id === "0") {
      setbtnDisabled(true);
      dispatch(
        GetInsertList({
          pid: props.pidvalue,
          title: ProblemType,
          diagnosis: icdoption1,
          type: props.prblmvalue,
          activity: Statuslabel,
          begdate: aptdate,
          enddate: onDate,
          snoomed_code: ct,
          comments: comments,
          value: props.prblmvalue,
        })
      );
    } else {
      setbtnDisabled(true);
      dispatch(
        GetUpdateList({
          pid: props.pidvalue,
          id: props.id,
          type: props.prblmvalue,
          title: ProblemType,
          diagnosis: icdoption1,
          activity: Statuslabel,
          begdate: aptdate,
          enddate: onDate,
          snoomed_code: ct,
          comments: comments,
          value: props.prblmvalue,
        })
      );
    }
    setTimeout(() => {
      setDis(false);
    }, 1000);
    handleClose();
  };
  const handleCt = (e) => {
    setCt(e.target.value);
    setCtTypeErr(false);
  };
  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleClose = () => {
    setdisable(true);
    setbtnDisabled(false);
    setStatusType("");
    setCt("");
    setProblemType("");
    setComments("");
    setaptdate("");
    setOnDate("");
    setICDType("");
    seticdoption("");
    seticdoption1("");
    setICDTypeErr(false);
    setProblemTypeErr(false);
    setStatusTypeErr(false);
    setCtTypeErr(false);
    setStartTypeErr(false);
    setEndTypeErr(false);
    setStartTypeErrcheck(false);
    setEndTypeErrcheck(false);
    setDatediffErrcheck(false);
    setIdentifyErr(false);
    setValidDate(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    setValidDate1(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    props.close();
  };

  const handleContainerClick = (e) => {
    if (
      e.target.id === "CustomDatePicker" ||
      e.target.id === "CustomDatePickerbuttons" ||
      e.target.id === "date" ||
      e.target.id === "date1" ||
      e.target.className === "year"
    ) {
    } else {
      closePicker();
      closePicker1();
    }
    if (ShowOptions1) {
      setShowOptions1(false);
    }
    if (ShowOptions) {
      setShowOptions(false);
    }
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
    e.stopPropagation();
  };
  const handleICD = (e) => {
    setLoading(true);
    let ICDvalue = e.target.value;
    const [code, value] = ICDvalue.split(" - ");
    setICDType(ICDvalue);
    seticdoption(code.trim());
    seticdoption1(value);
    if (ICDvalue.length > 0) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
    setICDTypeErr(false);
    setIdentifyErr(false);
  };
  const handleOptionClick = (value) => {
    setICDType(value);
    seticdoption(value[0]);
    seticdoption1(value[1]);
    setSelectedValue(value[1]);
    setDropdownOpen(false);
  };
  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");
    if (exampleAttr !== "icd_type") {
      setShowOptions(false);
    }
  };
  const handleClick1 = (e) => {
    const exampleAttr = e.target.getAttribute("name");
    if (exampleAttr !== "status_type") {
      setShowOptions1(false);
    }
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleClick1);
    document.addEventListener("click", handleCustomCalendar);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleClick1);
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    if (ICDList9[3] !== null) {
      setLoading(false);
    }
  }, [ICDList9[3]]);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);
  useEffect(() => {
    if (ProblemType === "ICD9") {
      dispatch(GetICD9List(ICDType));
    } else {
      dispatch(GetICD10List(ICDType));
    }
  }, [ICDType, ProblemType]);

  useEffect(() => {
    if (Object.keys(ProblemLists1).length > 0 && props.id !== "0") {
      setdisable(false);
      setaptdate(ProblemLists1.begdate);
      setValidDate(ProblemLists1.begdate);
      setaptdatecheck(ProblemLists1.begdate);
      setOnDate(ProblemLists1.enddate);
      setValidDate1(ProblemLists1.enddate);

      setOnDatecheck(ProblemLists1.enddate);
      setProblemType(ProblemLists1.title);
      seticdoption1(ProblemLists1.diagnosis);
      setSelectedValue(ProblemLists1.diagnosis);
      setCt(ProblemLists1.snoomed_code);
      setComments(ProblemLists1.comments);
      setStatuslabel(ProblemLists1.activity);

      if (ProblemLists1.activity === 0) {
        setStatusType("Inactive");
      } else if (ProblemLists1.activity === 1) {
        setStatusType("Active");
      } else if (ProblemLists1.activity === 2) {
        setStatusType("Resolved");
      }
    } else {
      setbtnDisabled(false);
      setStatusType("");
      setCt("");
      setProblemType("");
      setComments("");
      setaptdate("");
      setOnDate("");
      setICDType("");
      seticdoption("");
      seticdoption1("");
      setICDTypeErr(false);
      setProblemTypeErr(false);
      setStatusTypeErr(false);
      setCtTypeErr(false);
      setStartTypeErr(false);
      setEndTypeErr(false);
      setStartTypeErrcheck(false);
      setEndTypeErrcheck(false);
      setDatediffErrcheck(false);
      setIdentifyErr(false);
    }
  }, [ProblemLists1]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <Modal
      show={props.show}
      onClick={props.close}
      justifyContent="center"
      overflowY="auto"
      style={{
        width: screenSize.width * 1.0,
        height: screenSize.height * 1.0,
      }}
    >
      <ModalContainer
        width="374px"
        position="relative"
        height="auto"
        justifyContent="center"
        borderRadius="8px"
        overflowX="revert"
        overflowY="revert"
        onClick={handleContainerClick}
      >
        <ModalDialog width="374px">
          <ModalHeader padding="26px 27px 16px 29px">
            <ModalTitle
              fontFamily="'Open Sans',sans-serif"
              fontWeight="600px"
              fontSize="16px"
              lineHight="22px"
              color="#2E2E2E"
              fontStyle="SemiBold"
            >
              {props.id === "0" ? "Add Problem" : "Edit Problem"}
            </ModalTitle>
            <Modclosebtn onClick={handleClose}>
              <FaTimes />
            </Modclosebtn>
          </ModalHeader>

          <ModalBody padding="16px 27px 16px 27px">
            <Div
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400px"
              fontSize="14px"
              marginBottom="8px !important"
            >
              ICD Version
              <SpanTag color="#B00020">*</SpanTag>
            </Div>
            <Div padding=" 0px 0px 16px 0px" alignItems="flex-start">
              <Div
                onClick={() => {
                  seticdoption1("");
                  setShowOptions(!ShowOptions);
                }}
              >
                <SelectInput
                  placeholder=" 'Select '"
                  width="100%"
                  height="38px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="icd_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  color="#2E2E2E"
                  pl="10px"
                  margin="8px 0px 0px 0px"
                  padding="9px 12px"
                  value={ProblemLists1.title}
                  style={
                    ProblemTypeErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                >
                  {ProblemType}
                </SelectInput>
                <DropdownImage
                  marginLeft="294px"
                  marginTop="-21px"
                  src={dropdownDownicon}
                ></DropdownImage>
              </Div>
              {ShowOptions && DropdownOptionList1.length > 0 ? (
                <DropDownDiv
                  width="320px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                >
                  {DropdownOptionList1.map((r, i) => {
                    return (
                      <DropdownOptions
                        key={i}
                        value={r.value}
                        padding="10px 12px 8px 12px"
                        onClick={() => setProblemTypeSelect(r)}
                      >
                        {r.label}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}

              {ProblemTypeErr && (
                <ErrorMessage>Please Select ICD Version</ErrorMessage>
              )}
            </Div>
            <Div>
              <Div
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400px"
                fontSize="14px"
                marginBottom="8px !important"
              >
                Code
                <SpanTag color="#B00020">*</SpanTag>
              </Div>
              <Div paddingBottom="16px" position="relative">
                <Div display="flex" position="relative">
                  <SearchIconSVG>
                    <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" />
                  </SearchIconSVG>

                  <Input
                    autoComplete="off"
                    disabled={disable}
                    type="search"
                    name="search"
                    placeholder="  Find Code"
                    padding="10px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    style={icdstyle()}
                    borderRadius="4px"
                    width="100%"
                    paddingLeft="24px"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    position="absolute"
                    value={icdoption1}
                    onChange={handleICD}
                  />

                  {dropdownOpen && (loading || ICDList9[3].length >= 0) ? (
                    <AllergyDropdown>
                      {loading ? (
                        <Text padding="10px 12px 8px 12px" fontSize="15px">
                          Loading...
                        </Text>
                      ) : ICDList9[3].length > 0 ? (
                        ICDList9[3].map((option) => (
                          <AllergyOption
                            key={option}
                            onClick={() => handleOptionClick(option)}
                          >
                            {option[0] + " - " + option[1]}
                          </AllergyOption>
                        ))
                      ) : (
                        <Text padding="10px 12px 8px 12px" fontSize="15px">
                          No results found
                        </Text>
                      )}
                    </AllergyDropdown>
                  ) : null}
                </Div>
                {ICDTypeErr && (
                  <ErrorMessage>Please Select any type</ErrorMessage>
                )}

                {identifyErr && (
                  <ErrorMessage>
                    Please Select Any One from DropDown
                  </ErrorMessage>
                )}
              </Div>
            </Div>
            <Div
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400px"
              fontSize="14px"
              marginBottom="8px !important"
            >
              Status
              <SpanTag color="#B00020">*</SpanTag>
            </Div>
            <Div
              padding=" 0px 0px 16px 0px"
              disable="flex"
              alignItems="flex-start"
            >
              <Div onClick={() => setShowOptions1(!ShowOptions1)}>
                <SelectInput
                  placeholder="'Select Status'"
                  width="100%"
                  height="38px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="status_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  color="#2E2E2E"
                  pl="10px"
                  margin="8px 0px 0px 0px"
                  padding="9px 12px"
                  style={
                    StatusTypeErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                >
                  {StatusType}
                </SelectInput>
                <DropdownImage
                  marginLeft="294px"
                  marginTop="-21px"
                  src={dropdownDownicon}
                ></DropdownImage>
              </Div>
              {ShowOptions1 && DropdownOptionList.length > 0 ? (
                <DropDownDiv
                  width="320px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                >
                  {DropdownOptionList.map((r, i) => {
                    return (
                      <DropdownOptions
                        key={i}
                        value={r.value}
                        padding="10px 12px 8px 12px"
                        onClick={() => setStatusTypeSelect(r)}
                      >
                        {r.label}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}

              {StatusTypeErr && (
                <ErrorMessage>Please Select Status</ErrorMessage>
              )}
            </Div>

            <Div display="flex" justifyContent="space-between">
              <Div display="flex" flexDirection="column" width="47.5%">
                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400px"
                  fontSize="14px"
                  marginBottom="8px !important"
                >
                  Start Date Diagnosis
                  <SpanTag color="#B00020">*</SpanTag>
                </Div>
                <Div position="relative">
                  <CustomDatePicker
                    width="152px"
                    id="CustomDatePicker"
                    border={
                      StartTypeErr || StartTypeErrcheck || DatediffErrcheck
                        ? " 1px solid red"
                        : "1px solid rgba(34,36,38,.15)"
                    }
                    height="36px"
                    backgroundColour="#f7f7f7 !important"
                    placeholder="yyyy-mm-dd"
                    value={aptdate}
                    onChange={(e) => {
                      let d = moment(
                        e.target.value,
                        "YYYY-MM-DD",
                        true
                      ).isValid();
                      setaptdate(e.target.value);
                      setStartTypeErr(false);
                      setStartTypeErrcheck(false);
                      setDatediffErrcheck(false);
                      if (e.target.value === "") {
                        setValidDate("");
                      } else if (d) {
                        setaptdatecheck(e.target.value);
                        let check = false;

                        if (check) {
                          if (validDate !== "") {
                            setaptdate(moment(validDate).format("YYYY-MM-DD"));
                          }
                        } else {
                          setValidDate(e.target.value);
                        }
                      }
                    }}
                    onClick={(e) => handlepicker(e)}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        event.target.value.length > 9
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => keyDownExpiry(e)}
                    onResultSelect={dateSelect}
                    showPicker={showpicker}
                    validDate={validDate}
                    closePicker={closePicker}
                    max={moment(onDate, "YYYY-MM-DD")}
                  ></CustomDatePicker>

                  <Imagecalendar
                    top="6px"
                    right="12px"
                    width="20px"
                    height="22px"
                    id="CustomDatePicker"
                    onClick={(e) => handlepicker(e)}
                    src={calendar_icon_custom}
                  ></Imagecalendar>
                  {StartTypeErr && (
                    <ErrorMessage>Please Select Start Date</ErrorMessage>
                  )}
                  {StartTypeErrcheck && (
                    <ErrorMessage>Please Select Proper Date</ErrorMessage>
                  )}
                  {DatediffErrcheck && (
                    <ErrorMessage>
                      Please Select Start Date before End Date
                    </ErrorMessage>
                  )}
                </Div>
              </Div>

              <Div
                position="relative"
                display="flex"
                flexDirection="column"
                width="47.5%"
              >
                <>
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="400px"
                    fontSize="14px"
                    marginBottom="8px !important"
                  >
                    End Date Diagnosis
                    <SpanTag color="#B00020">*</SpanTag>
                  </Div>

                  <CustomDatePicker
                    width="152px"
                    height="36px"
                    backgroundColour="#f7f7f7 !important"
                    padding="0 0 0 10px"
                    id="CustomDatePicker"
                    border={
                      EndTypeErr || EndTypeErrcheck || DatediffErrcheck
                        ? " 1px solid red"
                        : "1px solid rgba(34,36,38,.15)"
                    }
                    borderRadius="4px"
                    placeholder="yyyy-mm-dd"
                    value={onDate}
                    onChange={(e) => {
                      let d = moment(
                        e.target.value,
                        "YYYY-MM-DD",
                        true
                      ).isValid();
                      setEndTypeErr(false);
                      setEndTypeErrcheck(false);
                      setDatediffErrcheck(false);
                      setOnDate(e.target.value);
                      if (e.target.value === "") {
                        setValidDate1("");
                      } else if (d) {
                        let check = false;
                        setOnDatecheck(e.target.value);
                        if (check) {
                          if (validDate1 !== "") {
                            setOnDate(moment(validDate1).format("YYYY-MM-DD"));
                          }
                        } else {
                          setValidDate1(e.target.value);
                        }
                      }
                    }}
                    onClick={(e) => handlepicker1(e)}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        event.target.value.length > 9
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => keyDownExpiry(e)}
                    onResultSelect={dateSelect1}
                    showPicker={showpicker1}
                    validDate={validDate1}
                    closePicker={closePicker1}
                    min={moment(aptdate, "YYYY-MM-DD")}
                  />

                  <Imagecalendar
                    top="32px"
                    right="12px"
                    width="20px"
                    height="22px"
                    id="CustomDatePicker"
                    onClick={(e) => handlepicker1(e)}
                    src={calendar_icon_custom}
                  ></Imagecalendar>
                  {EndTypeErr && (
                    <ErrorMessage>Please Select end date</ErrorMessage>
                  )}
                  {EndTypeErrcheck && (
                    <ErrorMessage>Please Select Proper Date</ErrorMessage>
                  )}
                  {DatediffErrcheck && (
                    <ErrorMessage>
                      Please Select Start Date before End Date
                    </ErrorMessage>
                  )}
                </>
              </Div>
            </Div>

            <Div
              padding="16px 0px 0px 0px"
              display="flex"
              flexDirection="column"
            >
              <Div
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400px"
                fontSize="14px"
                marginBottom="8px !important"
              >
                SNOMED CT code
                <SpanTag color="#B00020">*</SpanTag>
              </Div>

              <Input
                style={
                  ctTypeErr
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                border="1px solid #cbd5e0"
                fontSize="14px"
                borderRadius="4px"
                padding="10px 0 10px 12px"
                value={ct}
                onChange={handleCt}
              ></Input>
              {ctTypeErr && <ErrorMessage>Please Select Status</ErrorMessage>}
            </Div>
            <Div
              padding=" 16px 0px 8px 0px"
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400px"
              fontSize="14px"
              marginBottom="8px !important"
            >
              Comments
            </Div>
            <TextArea
              border="1px solid rgba(34,36,38,.15)"
              padding=" 0px 0px 16px 0px"
              resize="none"
              value={comments}
              onChange={handleComments}
            ></TextArea>
          </ModalBody>
          <ModalFooter padding="1rem 25px">
            <ButtonGroup
              fontSize="14px"
              fontWeight="600"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 27px 8px 28px"
              margin="0 16px 0 0px"
              background="#FFF"
              color="#2C7BE5"
              cursor="pointer"
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              onClick={handleClose}
            >
              <Span fontSize="14px" fontWeight="600">
                Close
              </Span>
            </ButtonGroup>
            <ButtonGroup
              type="submit"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 34px 8px 34px"
              background="#2C7BE5"
              color="#FFFFFF"
              disabled={btnDisabled || dis}
              onClick={() => HandleSave()}
              cursor="pointer"
              isDisabled={dis}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              <Span
                fontSize="14px"
                fontWeight="600"
                fontFamily="'Open Sans', sans-serif"
              >
                {props.id === "0" ? "Save" : "Update"}
              </Span>
            </ButtonGroup>
          </ModalFooter>
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default AddProblem;
