export const validCountryCodes = [
  "+1",
  "+44",
  "+91",
  "+81",
  "+86",
  "+61",
  "+971",
  "+966",
  "+7",
  "+55",
  "+33",
  "+49",
  "+852",
  "+52",
  "+95",
  "+64",
  "+974",
  "+65",
  "+94",
];
