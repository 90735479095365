import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  max-width: 650px;
  /* position: absolute; */
  /* right: 24px; */
  /* margin-top: 18px; */
`;

export const PaginationAnch = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active === "active" ? "#FFF" : "#000")} ;;
  height: 36px;
  width: 36px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background:${(props) => (props.active === "active" ? "#2C7BE5" : "#FFFFFF")} ;
  border-radius: 50%;
  margin-right: 8px;
  border: ${(props) =>
    props.borderNone === "none" ? "none !important" : "1px solid #ebeaed"}; 
  font-family: "Manrope";
  font-weight: ${(props) => (props.active === "active" ? 600 : "")}  
  font-size: 14px;
  line-height: 20px;
  &:hover:not(.active) {
    background-color: ${(props) =>
      props.borderNone === "none" ? "#FFFFFF" : "#2C7BE5"}; 
    color:${(props) => (props.borderNone === "none" ? "#000" : "#FFF")}; 
  }
`;

export const PagePrevNext = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000 ;
  display: flex; 
  height: 36px;
  width: 36px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background:#FFFFFF;
  border-radius: 50%;
  margin-right: 8px;
  border:1px solid #ebeaed;  
  font-family: "Manrope";
  font-weight: ${(props) => (props.active === "active" ? 600 : "")}  
  font-size: 40px;
  line-height: 20px;
  &:hover:not(.active) {
    background-color: #2C7BE5;
    color:#FFF
  }
`;
