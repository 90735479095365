import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  AddProfileData_Api,
  ProfileDetails_Api,
  AddClinicalData_Api,
  ClinicalDetails_Api,
  UserImageUpload_Api,
  SettingSecurityDataApi,
  SettingSecurityUpdateApi,
  GetDocumentMailApi,
  GetSettingDocApi,
  GetUpdateSetDocApi,
  Doc_Image_Api,
  UserListApi,
  ProviderListApi,
  ProviderRolesApi,
  SavedProvidersApi,
  SingleProvApi,
  UpdatedProvidersApi,
  SettingSecurityApi,
  DeleteUserApi,
  ServiceDeleteApi,
  AllServiceGetApi,
  ServiceInsertApi,
  ServiceUpdateApi,
  ServiceOneGetApi,
  Currency_Api,
  CodeVerify_Api,
  CheckUserList_Api,
  CheckMailVerify_Api,
  GetProviderRolesList_api,
  InsertRole_Api,
  EditRole_Api,
  GetGroupId_Api,
  EditRoleInsert_Api,
  ProvWithRole_Api,
  rolesCutomize_api,
  ProvRoleEdit_Api,
  ProvRolesave_Api,
  DeleteRole_Api,
  CountryCode_Api,
} from "../Apis/SettingApi";
import {
  SetProfileDetails,
  SetClinicalDetails,
  setAddProfileDetailRes,
  SetSecurityData,
  SetSecurityUpdate,
  SetDocumentMailRes,
  SetSettingDocRes,
  SetUpdateSetDocRes,
  SetUpdateDocMailRes,
  SetUserList,
  SetProviderList,
  SetRoles,
  SetSingProv,
  SetUserListResponse,
  SetUpdateUserListResponse,
  SetSettingSecurity,
  SetUserDelete,
  SetAllServiceList,
  SetOneServiceList,
  SetCurrency,
  setServiceAlert,
  SetCodeVerify,
  SetCheckUser,
  SetCheckEmail,
  SetCheckPhone,
  SetCheckCell,
  SetCheckMailVerify,
  SetCheckPhoneVerify,
  SetProviderRolesList,
  SetRolesEdit,
  SetInsertRole,
  SetGroupId,
  SetEditRoleInsert,
  SetProvWithRole,
  SetEditProvRoles,
  SetRoleDelete,
  SetNoOfPages,
  SetNoOfPagesProvRoleList,
  SetCountryCode,
} from "../../StateManagement/Reducers/SettingState";
import { ProviderDetailsApi } from "../Apis/LoginApi";
import { setProviderDetails } from "../../StateManagement/Reducers/LoginState";

export function* setAddProfileEmptyResponse() {
  yield put(setAddProfileDetailRes(""));
}

export function* AddProfileDataApi({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(AddProfileData_Api, requiredData);
    if (res.status === 200) {
      yield put(setAddProfileDetailRes(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* AddClinicalDataApi({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(AddClinicalData_Api, requiredData);
    if (res.status === 200) {
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* ProfileDetailsApi({ payload }) {
  try {
    const res = yield call(ProfileDetails_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetProfileDetails(Data));
    }
  } catch (e) {
    yield put(SetProfileDetails([]));
    console.log(e.message);
  }
}
export function* ClinicalDetailsApi({ payload }) {
  try {
    const res = yield call(ClinicalDetails_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetClinicalDetails(Data));
    }
  } catch (e) {
    yield put(SetClinicalDetails([]));
    console.log(e.message);
  }
}
export function* UserImageUploadApi({ payload }) {
  const userId = payload.get("userId");
  try {
    const res = yield call(UserImageUpload_Api, [userId, payload]);
    if (res.status === 200) {
      try {
        const res2 = yield call(ProviderDetailsApi, userId);
        if (res2.status === 200) {
          let Decrypt_Response = Decrypt_Value(res2.data, "vozorpm");
          yield put(setProviderDetails(Decrypt_Response));
        }
      } catch (e) {
        console.log(e.message);

        yield put(
          setProviderDetails({
            img_url: "",
            providerName: "",
          })
        );
      }
      try {
        const res3 = yield call(ProfileDetails_Api, userId);
        if (res.status === 200) {
          const Data = Decrypt_Value(res3.data, "vozorpm");
          yield put(SetProfileDetails(Data));
        }
      } catch (e) {
        yield put(SetProfileDetails([]));
        console.log(e.message);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* SettingSecurityWorker() {
  try {
    const res = yield call(SettingSecurityDataApi);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSecurityData(Data));
    }
  } catch (e) {
    yield put(SetSecurityData([]));
  }
}

export function* SettingSecurityUpdateWorker({ payload }) {
  try {
    const decryptpayload = Encrypt_Value(payload, "vozorpm");
    const res = yield call(SettingSecurityUpdateApi, decryptpayload);
    if (res.status === 200) {
      yield put(SetSecurityUpdate("Updated SuccessFully"));
    }
  } catch (e) {
    yield put(SetSecurityUpdate("Updated Failed"));
  }
}
export function* setEmptySecurityResponseWorker() {
  yield put(SetSecurityUpdate(""));
  try {
    const res = yield call(SettingSecurityDataApi);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSecurityData(Data));
    }
  } catch (e) {
    yield put(SetSecurityData([]));
  }
}

export function* GetDocumentMailWorker() {
  try {
    const res = yield call(GetDocumentMailApi);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetDocumentMailRes(Data));
    }
  } catch (e) {
    yield put(SetDocumentMailRes([]));
  }
}

export function* GetSettingDocWorker() {
  try {
    const res = yield call(GetSettingDocApi);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSettingDocRes(Data));
    }
  } catch (e) {
    yield put(SetSettingDocRes([]));
  }
}

export function* GetUpdateSetDocWorker({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(GetUpdateSetDocApi, encryptedData);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetUpdateSetDocRes(Data));
    }
  } catch (e) {
    yield put(SetUpdateSetDocRes(""));
  }
}

export function* EmptyUpdateSetDocWorker() {
  yield put(SetUpdateSetDocRes(""));
  yield put(SetUpdateDocMailRes(""));
}

export function* UpdateDocMailWorker({ payload }) {
  let data = payload[0];
  const encryptedData = Encrypt_Value(data.article, "vozorpm");
  try {
    const res = yield call(GetUpdateSetDocApi, encryptedData);
    if (res.status === 200) {
      if (payload[1] && payload[1] !== "" && payload[1] !== null) {
        yield call(Doc_Image_Api, [data.file]);
      }
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetUpdateDocMailRes(Data));
    }
  } catch (e) {
    yield put(SetUpdateDocMailRes(""));
  }
}
export function* getUserList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(UserListApi, requiredData);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      if (Data.length !== 0) {
        yield put(SetUserList(Data));
      } else {
        yield put(SetUserList([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetUserList([]);
  }
}

export function* getProvider({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(ProviderListApi, requiredData);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      if (Data.length !== 0) {
        yield put(SetProviderList(Data));
      } else {
        yield put(SetProviderList([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetProviderList([]);
  }
}

export function* getRolesforProvider({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(ProviderRolesApi, requiredData);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      if (Data.length !== 0) {
        yield put(SetRoles(Data));
        yield put(SetNoOfPages(Data.totalPages));
      } else {
        yield put(SetRoles([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetRoles([]);
  }
}

export function* savedProvider({ payload }) {
  const requiredData = Encrypt_Value(payload.data, "vozorpm");
  const requiredData1 = Encrypt_Value(payload.datas, "vozorpm");
  try {
    const res = yield call(SavedProvidersApi, requiredData);
    if (res.status === 200) {
      try {
        const res1 = yield call(UserListApi, requiredData1);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          if (Data.length !== 0) {
            yield put(SetUserList(Data));
          } else {
            yield put(SetUserList([]));
          }
        }
      } catch (e) {
        console.log(e.message);
        SetUserList([]);
      }
      yield put(SetUserListResponse(res.data));
    } else {
      yield put(SetUserListResponse(""));
    }
  } catch (e) {
    console.log(e.message);
    SetUserListResponse("");
  }
}

export function* getSingleProvider({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(SingleProvApi, requiredData);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      if (Data.length !== 0) {
        yield put(SetSingProv(Data));
      } else {
        yield put(SetSingProv([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetSingProv([]);
  }
}

export function* updateProvider({ payload }) {
  const requiredData = Encrypt_Value(payload.data, "vozorpm");

  const requiredData1 = Encrypt_Value(payload.datas, "vozorpm");
  const value = [payload.uid, requiredData];

  try {
    const res = yield call(UpdatedProvidersApi, value);
    if (res.status === 200) {
      try {
        const res1 = yield call(UserListApi, requiredData1);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          if (Data.length !== 0) {
            yield put(SetUserList(Data));
          } else {
            yield put(SetUserList([]));
          }
        }
      } catch (e) {
        console.log(e.message);
        SetUserList([]);
      }
      yield put(SetUpdateUserListResponse(res.data));
    } else {
      yield put(SetUpdateUserListResponse(""));
    }
  } catch (e) {
    console.log(e.message);
    SetUpdateUserListResponse("");
  }
}

export function* setEmptyResponse() {
  yield put(SetUserListResponse(""));
}

export function* setEmptyUpdateResponse() {
  yield put(SetUpdateUserListResponse(""));
}

export function* setSingleProvEmptyResponse() {
  yield put(SetSingProv([]));
}

export function* getSecurtiyData({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(SettingSecurityApi, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetSettingSecurity(Data));
      } else {
        yield put(SetSettingSecurity([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetSettingSecurity([]);
  }
}

export function* deleteUser({ payload }) {
  const requiredData = Encrypt_Value(payload.datas, "vozorpm");
  try {
    const res = yield call(DeleteUserApi, payload.id);
    if (res.status === 200) {
      try {
        const res1 = yield call(UserListApi, requiredData);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          if (Data.length !== 0) {
            yield put(SetUserList(Data));
          } else {
            yield put(SetUserList([]));
          }
        }
      } catch (e) {
        console.log(e.message);
        SetUserList([]);
      }
      yield put(SetUserDelete(res.data));
    }
  } catch (e) {
    console.log(e.message);
    SetUserDelete([]);
  }
}
export function* getServiceList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(AllServiceGetApi, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetAllServiceList(Data));
      } else {
        yield put(SetAllServiceList([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetAllServiceList([]);
  }
}

export function* getServiceInsertList({ payload }) {
  const requiredData = Encrypt_Value(payload.data, "vozorpm");
  const requiredData1 = Encrypt_Value(payload.datas, "vozorpm");

  yield put(setServiceAlert(""));
  try {
    const res = yield call(ServiceInsertApi, requiredData);
    if (res.status === 200) {
      try {
        const res1 = yield call(AllServiceGetApi, requiredData1);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          if (Data.length !== 0) {
            yield put(SetAllServiceList(Data));
          } else {
            yield put(SetAllServiceList([]));
          }
        }
      } catch (e) {
        console.log(e.message);
        SetAllServiceList([]);
      }
      yield put(setServiceAlert(res.data));
    } else {
      yield put(setServiceAlert(""));
    }
  } catch (e) {
    console.log(e.message);
    setServiceAlert("");
  }
}

export function* getOneServiceList({ payload }) {
  try {
    const res = yield call(ServiceOneGetApi, payload);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetOneServiceList(Data));
      } else {
        yield put(SetOneServiceList([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetOneServiceList([]);
  }
}

export function* getServiceUpdateList({ payload }) {
  const requiredData = Encrypt_Value(payload.data, "vozorpm");
  const requiredData1 = Encrypt_Value(payload.datas, "vozorpm");
  yield put(setServiceAlert(""));
  try {
    const res = yield call(ServiceUpdateApi, requiredData);
    if (res.status === 200) {
      try {
        const res1 = yield call(AllServiceGetApi, requiredData1);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          if (Data.length !== 0) {
            yield put(SetAllServiceList(Data));
          } else {
            yield put(SetAllServiceList([]));
          }
        }
      } catch (e) {
        console.log(e.message);
        SetAllServiceList([]);
      }
      yield put(setServiceAlert(res.data));
    } else {
      yield put(setServiceAlert(""));
    }
  } catch (e) {
    console.log(e.message);
    setServiceAlert("");
  }
}

export function* CurrencyApi() {
  try {
    const res = yield call(Currency_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCurrency(temp_role[0].currency));
    }
  } catch (e) {
    yield put(SetCurrency([]));
    console.log(e.message);
  }
}

export function* getServicedeleteList({ payload }) {
  const requiredData = Encrypt_Value(payload.data, "vozorpm");
  const requiredData1 = Encrypt_Value(payload.datas, "vozorpm");

  yield put(setServiceAlert(""));
  try {
    const res = yield call(ServiceDeleteApi, requiredData);
    if (res.status === 200) {
      try {
        const res1 = yield call(AllServiceGetApi, requiredData1);
        if (res1.status === 200) {
          const Data = Decrypt_Value(res1.data, "vozorpm");
          if (Data.length !== 0) {
            yield put(SetAllServiceList(Data));
          } else {
            yield put(SetAllServiceList([]));
          }
        }
      } catch (e) {
        console.log(e.message);
        SetAllServiceList([]);
      }
      yield put(setServiceAlert(res.data));
    } else {
      yield put(setServiceAlert(""));
    }
  } catch (e) {
    console.log(e.message);
    setServiceAlert("");
  }
}
export function* CodeVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(CodeVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCodeVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* setServiceEmptyResponse() {
  yield put(SetOneServiceList([]));
}
export function* setEmptyCodeResponse() {
  yield put(SetCodeVerify("Code not found"));
}

export function* CheckUserName({ payload }) {
  try {
    const res = yield call(CheckUserList_Api, payload);
    if (res.status === 200) {
      //const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCheckUser(res.data));
    }
  } catch (e) {
    console.log(e.message);
    SetCheckUser("");
  }
}

export function* EmptyTheUserCheck() {
  yield put(SetCheckUser(""));
}
export function* CheckUserEmail({ payload }) {
  try {
    const res = yield call(CheckUserList_Api, payload);
    if (res.status === 200) {
      //const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCheckEmail(res.data));
    }
  } catch (e) {
    console.log(e.message);
    SetCheckEmail("");
  }
}

export function* EmptyTheUserEmail() {
  yield put(SetCheckEmail(""));
}
export function* CheckUserPhone({ payload }) {
  try {
    const res = yield call(CheckUserList_Api, payload);
    if (res.status === 200) {
      //const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCheckPhone(res.data));
    }
  } catch (e) {
    console.log(e.message);
    SetCheckPhone("");
  }
}

export function* EmptyTheUserPhone() {
  yield put(SetCheckPhone(""));
}
export function* CheckUserCell({ payload }) {
  try {
    const res = yield call(CheckUserList_Api, payload);
    if (res.status === 200) {
      //const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCheckCell(res.data));
    }
  } catch (e) {
    console.log(e.message);
    SetCheckCell("");
  }
}

export function* EmptyTheUserCell() {
  yield put(SetCheckCell(""));
}
export function* SetMailVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(CheckMailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCheckMailVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* SetPhoneVerifyApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(CheckMailVerify_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCheckPhoneVerify(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SetProviderRolesListWorker({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(GetProviderRolesList_api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      if (DecryptedData.length !== 0) {
        yield put(SetProviderRolesList(DecryptedData.data));
        yield put(SetNoOfPagesProvRoleList(DecryptedData.totalPages));
      } else {
        yield put(SetProviderRolesList([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetProviderRolesList([]));
  }
}

export function* GetCustomizeRoleWorker({ payload }) {
  const encryptedData = Encrypt_Value(payload.articles, "vozorpm");
  try {
    const res = yield call(rolesCutomize_api, {
      aro_id: payload.aro_id,
      articles: encryptedData,
    });
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetInsertRole(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* InsertRoleApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(InsertRole_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetInsertRole(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* EditRoleApi({ payload }) {
  try {
    const res = yield call(EditRole_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetRolesEdit(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetGroupIdApi({ payload }) {
  try {
    const res = yield call(GetGroupId_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetGroupId(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* EditRoleInsertApi({ payload }) {
  const encryptedData = Encrypt_Value(payload.articles, "vozorpm");
  try {
    const res = yield call(EditRoleInsert_Api, {
      acl_id: payload.acl_id,
      articles: encryptedData,
    });
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetEditRoleInsert(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* ProvWithRoleApi() {
  try {
    const res = yield call(ProvWithRole_Api);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetProvWithRole(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* EditProvRoleApi({ payload }) {
  try {
    const res = yield call(ProvRoleEdit_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetEditProvRoles(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SaveProvRoleApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(ProvRolesave_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetEditRoleInsert(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeleteRoleApi({ payload }) {
  try {
    const res = yield call(DeleteRole_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetRoleDelete(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}


export function* CountryCodeAPI({ payload }) {
  try {
    const res = yield call(CountryCode_Api, payload);
    if (res.status === 200) {
      // const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetCountryCode(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
