import styled, { css } from "styled-components";
import {
  DropdownOptions,
  Div,
  SpanTag,
  Tablerow,
  TableBodyCell,
  TableHeadCell,
} from "../StyledComponents";

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: #ffff;
  border: 2px solid #2e2e2e;
  border-radius: 3px;

  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    margin: 2px;
  }
`;

export const TogggleCheck = styled.input`
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  height: 16px;
  width: 16px;
  position: relative;
  background: #ffff;
  &:checked + ${CheckBoxLabel} {
    background: #fff;
    border: 2px solid #2c7be5;
    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: #407bff;
    }
  }
`;

export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;
export const DropDownVitals = styled(DropdownOptions)`
  &:hover {
    background-color: unset !important;
    color: unset !important;
  }
  cursor: revert;
`;

export const DocScrlDiv = styled(Div)`
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: lightgray;
    height: 2px;
  }
`;

export const SpanLive = styled(SpanTag)`
  &.livespan {
    gap: 14px;
    display: flex;
    cursor: pointer;
  }
`;
export const LiveDiv = styled(Div)`
  &.livediv {
    @media screen and (max-width: 820px) {
      width: 102%;
    }
  }
`;
export const LiveTablerow = styled(Tablerow)`
  /* display: contents; */
  &.liveth {
    padding: 20px 20px 20px 20px;
    @media screen and (max-width: 820px) {
      padding: 20px 5px 20px 5px;
    }
  }
  &.livetb {
    padding: 18px 20px 19px 20px;
    @media screen and (max-width: 820px) {
      padding: 20px 7px 20px 7px;
    }
  }
`;
export const LiveTableBodyCell = styled(TableBodyCell)`
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 820px) {
    // text-align: center;
    padding: 20px 5px 20px 5px;
  }

  &.livetdcell {
    padding-left: 20px !important;
    @media screen and (max-width: 820px) {
      padding-left: 10px !important;
    }
  }
`;
export const LiveTableHeadCell = styled(TableHeadCell)`
  padding: 20px 20px 20px 20px;

  @media screen and (max-width: 820px) {
    padding: 20px 5px 20px 5px;
  }
  &.livethcell {
    padding-left: 20px !important;
    @media screen and (max-width: 820px) {
      padding-left: 10px !important;
    }
  }
`;

export const SpanTagLive = styled(SpanTag)`
  @media screen and (max-width: 820px) {
    gap: 10px;
    & > svg {
      width: 13px;
    }
  }
`;
