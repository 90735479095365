import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RadioProgress from "./RadioProgress";
import {
  GetPatEnrollmentUpdate,
  GetSinglePatDetail,
  setEmptyPatUpdateResponse,
} from "../../../StateManagement/Reducers/PatientState";

import {
  PageSubTitle,
  Container,
  Text,
  Div,
  Input,
  Table,
  TableHeadCell,
  TableBodyCell,
  Tablerow,
  Image,
  TextContent,
  SpanTag,
} from "../../StyledComponents/index";
import { AiOutlineSearch } from "react-icons/ai";
import { PatientSubTitle, LabelVal, SearchIconIns, Button } from "./Styles";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import DBHost from "../../../Config/DBHost";

const PatientEligiblity = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const navigate = useNavigate();
  const params = useParams();
  const patId = params.pid;
  const dispatch = useDispatch();
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const updateResponse = useSelector(
    (state) => state.Patient.PatientUpdateResponse
  );

  let imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //

  const pagetoPatInfo = () => {
    navigate(`/PatientEnrollment/${patId}`);
    window.scrollTo(0, 0);
  };

  const pagetoPatConsent = (value) => {
    if (value === "skip") {
      localStorage.setItem("skipverify", true);
    } else {
      localStorage.setItem("skipverify", false);
    }
    if (SingPatDetails.enrollment_status === "7" && value === "next") {
      navigate(`/PatientDemo/${patId}`);
      dispatch(
        GetPatEnrollmentUpdate({
          id: patId,
          status: "3",
        })
      );
    } else if (SingPatDetails.enrollment_status === "7" && value === "skip") {
      navigate(`/patient`);
    } else if (SingPatDetails.enrollment_status === "6" && value === "next") {
      navigate(`/PatientPortalSetup/${patId}`);
      dispatch(
        GetPatEnrollmentUpdate({
          id: patId,
          status: "2",
        })
      );
    } else if (SingPatDetails.enrollment_status === "6" && value === "skip") {
      navigate(`/PatientPortalSetup/${patId}`);
      dispatch(
        GetPatEnrollmentUpdate({
          id: patId,
          status: "6",
        })
      );
    } else {
      let checkVal = value === "next" ? "1" : "0";
      navigate(`/PatientConsent/${patId}`);
      window.scrollTo(0, 0);
      dispatch(
        GetPatEnrollmentUpdate({
          id: patId,
          status: checkVal,
        })
      );
    }
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    const patId1 = SingPatDetails.pid;
    if (patId1) {
      navigate(`/PatientEligibility/${patId1}`, { replace: true });
      window.scrollTo(0, 0);
    }
  }, [SingPatDetails, navigate]);

  useEffect(() => {
    dispatch(GetSinglePatDetail(patId));
  }, []);
  useEffect(() => {
    dispatch(setEmptyPatUpdateResponse());
  }, [updateResponse]);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <>
      <Container backgroundColor="#F1F5F8">
        <TopNav />
        <LeftNav />
        <Div marginLeft="56px" className="responsive-right">
          <Div padding="24px" backgroundColor="#F1F5F8">
            <Container>
              <PageSubTitle color="#2C7BE5">Patient Enrollment</PageSubTitle>
              <Div
                backgroundColor="#ffffff"
                borderRadius="8px"
                marginTop="24px"
                display="flow-root"
              >
                <RadioProgress page={2} />
                <PatientSubTitle margin="108px 20px 20px 29px" fontSize="18px">
                  Eligibility Verification
                </PatientSubTitle>
                <Div
                  border="1px solid lightgrey"
                  margin="29px"
                  padding="24px"
                  borderRadius="4px"
                >
                  <LabelVal fontSize="18px" fontWeight="600">
                    Patient Info
                  </LabelVal>
                  <Div display="flex" marginTop="43px">
                    <Div
                      height="75px"
                      width="75px"
                      borderRadius="50%"
                      display="flex"
                      marginRight="23px"
                      fontSize="60px"
                      fontWeight="600"
                    >
                      {SingPatDetails.img_url !== null &&
                      SingPatDetails.img_url !== "" ? (
                        <Image
                          height="75px"
                          width="75px"
                          borderRadius="50%"
                          src={imageUrl + SingPatDetails.img_url}
                        ></Image>
                      ) : (
                        <Div
                          width="200px"
                          borderRadius="50%"
                          background="rgb(168, 174, 190)"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          textAlign="center"
                          fontFamily="'Open Sans',sans-serif"
                          color="#FFFFFF"
                          position="relative"
                        >
                          <SpanTag
                            position="absolute"
                            top="18px"
                            fontSize="40px"
                          >
                            {SingPatDetails.fname.charAt(0).toUpperCase()}
                          </SpanTag>
                        </Div>
                      )}
                    </Div>
                    <Div overflowX="auto">
                      <Table border="none">
                        <Tablerow width="99%">
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 5px 16px"
                            width="180px"
                            marginRight="-28px"
                          >
                            Name
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 5px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            DOB
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 5px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            SSN Number
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 5px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Patient ID
                          </TableHeadCell>
                        </Tablerow>
                        <Tablerow width="99%">
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            width="180px"
                            padding="4px 16px 20px 16px"
                            marginRight="-28px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.patname}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 1px 20px 16px"
                            width="180px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.DOB}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 1px 20px 16px"
                            width="180px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.ss}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 1px 20px 16px"
                            width="180px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.patid}
                            </TextContent>
                          </TableBodyCell>
                        </Tablerow>

                        <Tablerow width="99%">
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 16px 4px 16px"
                            width="180px"
                            marginRight="-28px"
                          >
                            Age
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 4px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Gender
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 4px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Email
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 4px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Phone Number
                          </TableHeadCell>
                        </Tablerow>
                        <Tablerow width="99%">
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 16px 20px 16px"
                            width="180px"
                            marginRight="-28px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.age}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 1px 20px 16px"
                            width="180px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.sex}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            width="180px"
                            padding="4px 1px 20px 16px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.email}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 1px 20px 16px"
                            width="180px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.phone_cell}
                            </TextContent>
                          </TableBodyCell>
                        </Tablerow>

                        <Tablerow width="99%">
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 16px 4px 16px"
                            width="180px"
                            marginRight="-28px"
                          >
                            Program
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 4px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Address
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 4px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Ethnicity
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="400"
                            color="#2E2E2E"
                            padding="4px 1px 4px 16px"
                            width="180px"
                            marginLeft="32px"
                          >
                            Race
                          </TableHeadCell>
                        </Tablerow>
                        <Tablerow width="99%">
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            padding="4px 16px 4px 16px"
                            width="180px"
                            marginRight="-28px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px"> RPM</TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            width="180px"
                            padding="4px 1px 4px 16px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.address}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            width="180px"
                            padding="4px 1px 4px 16px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.ethnicity}
                            </TextContent>
                          </TableBodyCell>
                          <TableBodyCell
                            fontFamily="'Open Sans',sans-serif"
                            fontWeight="600"
                            fontSize="16px"
                            color="#2E2E2E"
                            width="180px"
                            padding="4px 1px 4px 16px"
                            marginLeft="32px"
                            verticalAlign="top"
                          >
                            <TextContent lineHeight="24px">
                              {SingPatDetails.race}
                            </TextContent>
                          </TableBodyCell>
                        </Tablerow>
                      </Table>
                    </Div>
                  </Div>
                </Div>
                <PatientSubTitle margin="0 0 0 29px" fontSize="18px">
                  Eligibility Check
                </PatientSubTitle>
                <Div
                  border="1px solid lightgrey"
                  margin="29px"
                  borderRadius="4px"
                  padding="0px"
                >
                  <LabelVal
                    fontSize="16px"
                    fontWeight="600"
                    color="#2c7be5 !important"
                    padding="26px 0px 16px 27px"
                    display="block"
                  >
                    Insurance Eligiility & Benefit Check
                  </LabelVal>
                  <Div
                    height="80px"
                    backgroundColor="#f1f5f8"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Div width="35%" height="100%" padding="0 0 0 31px">
                      <SearchIconIns>
                        <AiOutlineSearch />
                      </SearchIconIns>
                      <Input
                        placeholder="Search"
                        fontSize="15px"
                        paddingLeft="28px"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        height="36px"
                        width="100% !important"
                        borderRadius="4px"
                      />
                    </Div>
                    <Div display="flex">
                      <Button
                        className="btn-responsive"
                        margin="0 24px 0 0"
                        // backgroundColor="#2C7BE5"
                        hoverBackColor="#005FB2"
                        activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                      >
                        Run Primary Insurance
                      </Button>
                      <Button
                        className="btn-responsive"
                        margin="0 30px 0 0"
                        // backgroundColor="#2C7BE5"
                        hoverBackColor="#005FB2"
                        activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                      >
                        Run Secondary Insurance
                      </Button>
                    </Div>
                  </Div>

                  <LabelVal
                    padding="24px 0 0 27px"
                    fontSize="16px"
                    color="#2e2e2e !important"
                    fontWeight="600"
                    display="block"
                  >
                    Latest Eligibility
                  </LabelVal>
                  <Button
                    margin="36px 0 0 27px"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  >
                    Active Coverage
                  </Button>
                  <Div
                    display="flex"
                    justifyContent="space-between"
                    margin="13px 170px 36px 27px"
                  >
                    <Container>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">Subscriber : </LabelVal>
                        <Text fontSize="14px">John Doe 20-10-1988</Text>
                      </Div>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">
                          Primary Care Provider :{" "}
                        </LabelVal>
                        <Text fontSize="14px"></Text>
                      </Div>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">Last Updated : </LabelVal>
                        <Text fontSize="14px">2 days ago</Text>
                      </Div>
                    </Container>
                    <Container>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">Insurance : </LabelVal>
                        <Text fontSize="14px">Anthem Blue Cross</Text>
                      </Div>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">Insurance ID# : </LabelVal>
                        <Text fontSize="14px">12345678</Text>
                      </Div>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">Group Number : </LabelVal>
                        <Text fontSize="14px"></Text>
                      </Div>
                      <Div margin="16px 0 0 0" display="flex">
                        <LabelVal fontWeight="600">Group Name : </LabelVal>
                        <Text fontSize="14px"></Text>
                      </Div>
                    </Container>
                  </Div>
                </Div>
                <Div
                  display="flex"
                  justifyContent="space-between"
                  margin="80px 60px 49px 60px"
                >
                  <Button
                    padding="8px 32px"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    onClick={pagetoPatInfo}
                  >
                    Previous
                  </Button>

                  <Container>
                    <Button
                      bgcolor="#fff"
                      color="#2c7be5"
                      border="1px solid #2c7be5"
                      margin="0 24px 0 0"
                      hoverBackColor="rgba(244, 246, 249, 0.75)"
                      activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                      onClick={() => pagetoPatConsent("skip")}
                    >
                      Skip Verification
                    </Button>

                    <Button
                      padding="10px 45px"
                      hoverBackColor="#005FB2"
                      activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                      onClick={() => pagetoPatConsent("next")}
                    >
                      Next
                    </Button>
                  </Container>
                </Div>
              </Div>
            </Container>
          </Div>
        </Div>
      </Container>
      {/* <PatientConsent /> */}
    </>
  );
};
export default PatientEligiblity;
