import React, { useEffect } from "react";
import moment from "moment";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import Login from "../Modules/Login";
import DashBoard from "../Modules/Dashboard";
import AccessDenied from "../Modules/AccessDenied";
import useAutoLogout from "./useAutoLogout";
import Patient from "../Modules/Patient";
import Live from "../Modules/Live";
import Devices from "../Modules/Devices";
import TreatmentPlan from "../Modules/TreatmentPlan";
import Billing from "../Modules/Billing";
import PatientEnrollment from "../Modules/Patient/PatientEnrollment";
import PatientEligiblity from "../Modules/Patient/PatientEnrollment/PatientEligiblity";
import PatientConsent from "../Modules/Patient/PatientEnrollment/PatientConsent";
import PatientPortalSetup from "../Modules/Patient/PatientEnrollment/PatientPortalSetup";
import PatientDemo from "../Modules/Patient/PatientDemo";
import BillandInsurance from "../Modules/Patient/BillandInsurance";
import Encounter from "../Modules/Patient/Encounter";
import FeeSheet from "../Modules/Patient/FeeSheet";
import SetNewPassWord from "../Modules/Login/SetNewPassword";
import ResetSuccess from "../Modules/Login/ResetSuccess";
import ForgetPassword from "../Modules/Login/ForgetPassword/index";
import EmailCheck from "../Modules/Login/EmailCheck/index";
import ViewDetailsModal from "../Modules/Patient/Encounter/ViewDetailsModal";
import ViewDetails from "../Modules/Patient/FeeSheet/ViewDetails";
import EncounterForm from "../Modules/Patient/Encounter/EncounterForm";
import { Decrypt_Value, Encrypt_Value } from "../MiddleWare/EncryptDecrypt";
import VisitEncounterIndex from "../Modules/Patient/Encounter/VisitEncounterIndex";
import MyProfile from "../Modules/Settings/MyProfile";
import User from "../Modules/Settings/User";
import RolesandPermission from "../Modules/Settings/RolesandPermission";
import Services from "../Modules/Settings/Services";
import Documents from "../Modules/Settings/Documents";
import Security from "../Modules/Settings/Security";
import AddTreatmentPlan from "../Modules/TreatmentPlan/AddTreatmentPlan";
import { useSelector } from "react-redux";
import axiosInst from "../Config";

const Router = () => {
  var idle = localStorage.getItem("idle");
  const idleinMin = parseInt(idle) * 60;
  let timer = useAutoLogout(parseInt(idleinMin));
  const CurrTime = moment().format("YYYY-MM-DD HH:mm A");
  const token_exp_time = localStorage.getItem("login_time");
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const data = {
    userid: user_id,
  };

  if (timer === 0 || token_exp_time < CurrTime) {
    axiosInst
      .post(`/vozorpm/updatelogout`, data)
      .then((response) => {
        localStorage.clear();
        localStorage.setItem("timeout", true);
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        localStorage.setItem("timeout", true);
        window.location.href = "/";
      });
  }
  let location_path = useLocation();
  const navigate = useNavigate();

  var rememberMe =
    localStorage.getItem("auto_login") &&
    Decrypt_Value(localStorage.getItem("auto_login"), "vozo");

  var userId =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const passStatus =
    localStorage.getItem("exp") &&
    Decrypt_Value(localStorage.getItem("exp"), "vozorpm");
  const allowModal = localStorage.getItem("pass_exp_modal");

  const expires_on =
    localStorage.getItem("expires_on") &&
    Decrypt_Value(localStorage.getItem("expires_on"), "vozorpm");

  const today = new Date();
  const expired_on = new Date(expires_on);

  const today1 = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const expired_on1 = new Date(
    expired_on.getFullYear(),
    expired_on.getMonth(),
    expired_on.getDate()
  );

  useEffect(() => {
    if (rememberMe && location_path.pathname !== "/") {
      let path_encrypted = Encrypt_Value(location_path.pathname, "vozo");
      localStorage.setItem("last_location", path_encrypted);
    }
  }, [location_path]);

  useEffect(() => {
    let last_path =
      localStorage.getItem("last_location") &&
      Decrypt_Value(localStorage.getItem("last_location"), "vozo");

    if (rememberMe && last_path !== null && last_path !== "/null") {
      navigate(`${last_path}`);
    }
  }, []);
  return (
    <>
      {userId === null && (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/Login" element={<Login />} />
          <Route
            exact
            path="/setnewpassword/:code/:id/:len"
            element={<SetNewPassWord />}
          />
          <Route exact path="/reset" element={<ResetSuccess />} />
          <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
          <Route exact path="/EmailCheck" element={<EmailCheck />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}

      {userId !== null &&
        (today1 <= expired_on1 &&
        (passStatus !== "grace_fail" || allowModal === null) ? (
          <>
            <Routes>
              <Route exact path="/" element={<DashBoard />} />
              <Route exact path="/dashboard" element={<DashBoard />} />
              <Route exact path="/patient" element={<Patient />} />
              <Route exact path="/live" element={<Live />} />
              <Route exact path="/devices" element={<Devices />} />
              <Route exact path="/treatmentplan" element={<TreatmentPlan />} />
              <Route
                exact
                path="/addtreatment"
                element={<AddTreatmentPlan />}
              />
              <Route
                exact
                path="/edittreatment/:planid/:pid"
                element={<AddTreatmentPlan />}
              />
              <Route exact path="/billing" element={<Billing />} />
              <Route
                path={`/PatientEnrollment/:pid`}
                element={<PatientEnrollmentWrapper />}
              />

              <Route
                exact
                path={`/PatientEligibility/:pid`}
                element={<PatientEligiblity />}
              />
              <Route
                exact
                path={`/PatientConsent/:pid`}
                element={<PatientConsent />}
              />
              <Route
                exact
                path={`/PatientPortalSetup/:pid`}
                element={<PatientPortalSetup />}
              />
              <Route
                exact
                path={`/PatientDemo/:pid`}
                element={<PatientDemo />}
              />
              <Route
                exact
                path={`/BillandInsurance/:pid`}
                element={<BillandInsurance />}
              />
              <Route exact path={`/Encounter/:pid`} element={<Encounter />} />
              <Route exact path={`/FeeSheet/:pid`} element={<FeeSheet />} />
              <Route exact path="/AccessDenied" element={<AccessDenied />} />
              <Route
                exact
                path={`/Encounter/:pid/ViewDetails/:source`}
                element={<ViewDetailsModal />}
              />
              <Route
                exact
                path="/FeeSheet/:pid/ViewDetails"
                element={<ViewDetails />}
              />
              <Route
                exact
                path="/Encounter/Encounterfrom/:pid"
                element={<EncounterForm />}
              />
              <Route
                exact
                path="/Encounter/VisitEncounter/:pid"
                element={<VisitEncounterIndex />}
              />
              <Route exact path="/myprofile" element={<MyProfile />} />
              <Route exact path="/user" element={<User />} />
              <Route
                exact
                path="/rolesandpermission"
                element={<RolesandPermission />}
              />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/documents" element={<Documents />} />
              <Route exact path="/security" element={<Security />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
              <Route exact path="/" element={<DashBoard />} />
              <Route exact path="/dashboard" element={<DashBoard />} />
            </Routes>
          </>
        ))}
    </>
  );
};
const PatientEnrollmentWrapper = () => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  useEffect(() => {
    if (SingPatDetails.enrollment_status === "3") {
      navigate(`/PatientDemo/${pid}`);
    }
  }, [navigate, pid, SingPatDetails.enrollment_status]);

  return SingPatDetails.enrollment_status !== "3" ? (
    <PatientEnrollment />
  ) : null;
};
export default Router;
