import React, { useState, memo, useEffect } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  ButtonGroup,
  Div,
  Form,
  Input,
  Span,
  ModalBody,
  Text,
} from "../../StyledComponents/index";
import { EyeIcon } from "../../Login/Styles";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import {
  GetPasswordChange,
  setEmptyPassResponse,
} from "../../../StateManagement/Reducers/LoginState";
import moment from "moment/moment";

const PasswordExpired = (props) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();
  const expiring_date =
    localStorage.getItem("expiring_date") &&
    Decrypt_Value(localStorage.getItem("expiring_date"), "vozorpm");
  const minimum = localStorage.getItem("MinimumPasswordLength")
    ? localStorage.getItem("MinimumPasswordLength")
    : "0";
  const [currentpass, setCurrentpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [repass, setRepass] = useState("");
  const [currentpasserr, setCurrentpasserr] = useState(false);
  const [newpasserr, setNewpasserr] = useState(false);
  const [repasserr, setRepasserr] = useState(false);
  const [currentpassmsg, setCurrentpassmsg] = useState("");
  const [newpassmsg, setNewpassmsg] = useState("");
  const [repassmsg, setRepassmsg] = useState("");
  const [PassShow, setPassShow] = useState(true);
  const [PassShow1, setPassShow1] = useState(true);
  const [PassShow2, setPassShow2] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [empPass, setEmppass] = useState(false);
  const [cpass, setcpass] = useState(false);
  const [lenErr, setLenErr] = useState(false);
  // const [minimum, setMinimum] = useState("0");
  const [passStrong, setPassStrong] = useState("");
  const [strength, setStrength] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [title, setTitle] = useState("");
  const [expiry, setExpiry] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const userDetails = useSelector((state) => state.Login.ProviderDetails);
  const passWordResponse = useSelector(
    (state) => state.Login.PasswordChangeResponse
  );

  // ---------------------------------------- State and Variables End--------------------------------------------------- //
  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const currentpassword = (e) => {
    setCurrentpass(e.target.value);
    setEmppass(false);
    setCurrentpasserr(false);
  };

  const newpassword = (e) => {
    setPassStrong("1");

    setNewpass(e.target.value);
    setNewpasserr(false);
    setLenErr(false);
    setPassErr(false);
    setStrength(false);
  };

  const repassword = (e) => {
    setRepass(e.target.value);
    setRepasserr(false);
    setLenErr(false);
    setPassErr(false);
    setStrength(false);
    setcpass(false);
  };

  const Onsubmit = (e) => {
    e.preventDefault();
    var upper = 0,
      lower = 0,
      number = 0,
      special = 0;
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    for (var i = 0; i < newpass.length; i++) {
      if (!isNaN(newpass[i] * 1)) number++;
      else if (format.test(newpass[i])) special++;
      else if (newpass[i] === newpass[i].toLowerCase()) lower++;
      else if (newpass[i] === newpass[i].toUpperCase()) upper++;
    }

    if (currentpass === "" || repass === null) {
      setCurrentpassmsg("Please Enter the Current Password");
      setCurrentpasserr(true);
      return false;
    } else if (newpass === "" || newpass === null) {
      setNewpassmsg("Please Enter the New Password");
      setNewpasserr(true);
      return false;
    } else if (repass === "" || repass === null) {
      setRepassmsg("Please Enter the Re-Password");
      setRepasserr(true);
      return false;
    } else if (newpass !== repass) {
      setPassErr(true);
      return false;
    } else if (
      passStrong === "1" &&
      (upper === 0 || lower === 0 || number === 0 || special === 0)
    ) {
      setStrength(true);
      return false;
    } else if (newpass.length < minimum) {
      setLenErr(true);
      return false;
    }
    dispatch(
      GetPasswordChange({
        uid: userDetails.id,
        password: newpass,
        cpass: currentpass,
      })
    );
  };
  const handlePassword = () => {
    setPassShow((prevState) => !prevState);
  };

  const handlePassword1 = () => {
    setPassShow1((prevState) => !prevState);
  };

  const handlePassword2 = () => {
    setPassShow2((prevState) => !prevState);
  };

  const closeModal = () => {
    setCurrentpasserr(false);
    setPassErr(false);
    setNewpasserr(false);
    setRepasserr(false);
    setNewpass("");
    setCurrentpass("");
    setRepass("");
    props.onHide();
  };

  // ---------------------------------------- Functions End----------------------------------------------------------- //
  // ---------------------------------------- UseEffect Start----------------------------------------------------------- //
  useEffect(() => {
    if (props.passStatus === "grace_pass") {
      setTitle("Your Password will expire on");
    } else {
      setTitle("Your Password was expired on");
    }

    setExpiry(moment(expiring_date).format("DD/MM/YYYY"));
  }, [props]);
  useEffect(() => {
    if (passWordResponse !== "") {
      if (passWordResponse.includes("Current user password not matched!")) {
        if (
          passWordResponse.includes("Reuse of previous passwords not allowed!")
        ) {
          setModalMsg("Reuse of previous passwords not allowed!");
          setModalAlerShow(true);
          setTimeout(() => {
            setModalAlerShow(false);
            setStatus("");
            setModalMsg("");
          }, 3000);
        } else {
          setModalMsg("Current user password not matched!");
          setModalAlerShow(true);
          setTimeout(() => {
            setModalAlerShow(false);
            setStatus("");
            setModalMsg("");
          }, 3000);
        }
        setStatus("fail");
      } else {
        setModalMsg(passWordResponse);
        setModalAlerShow(true);
        if (passWordResponse === "Password Updated Successfully!") {
          setStatus("success");
        } else {
          setStatus("fail");
        }
        setTimeout(() => {
          setModalAlerShow(false);
          setStatus("");
          setModalMsg("");
          localStorage.removeItem("pass_exp_modal");
          closeModal();
        }, 1500);
      }
      dispatch(setEmptyPassResponse());
    }
  }, [passWordResponse]);
  // ---------------------------------------- UseEffect End----------------------------------------------------------- //
  return (
    <>
      <Modal show={props.show} justifyContent="center">
        <ModalContainer
          justifyContent="center"
          borderRadius="8px"
          alignItems="none"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalDialog width="500px">
            <Form onSubmit={Onsubmit}>
              <ModalBody padding="19px 28px 19px 28px">
                <Div display="flex">
                  <ModalTitle
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="500px"
                    fontSize="16px"
                    lineHight="14px"
                    color="#4b4b4b"
                  >
                    {title}
                  </ModalTitle>
                  <Span color="#2C7BE5" marginLeft="4px">
                    {expiry}
                  </Span>
                </Div>
                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400px"
                  fontSize="14px"
                  marginBottom="8px !important"
                  marginTop="24px"
                >
                  Current Password
                </Div>
                <Div position="relative" padding=" 0px 0px 10px 0px">
                  <Input
                    style={
                      empPass || currentpasserr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    id="current-password"
                    className="current-password"
                    type={PassShow ? "password" : "text"}
                    placeholder="Enter Current Password"
                    padding="0 0 0 10px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    height="42px"
                    width="100%"
                    borderRadius="4px"
                    onChange={(e) => currentpassword(e, "current")}
                    value={currentpass}
                  />

                  <EyeIcon
                    color="#2E2E2E"
                    height="16px"
                    width="20px"
                    top="24px"
                    right="11px"
                  >
                    {PassShow ? (
                      <FiEye onClick={handlePassword} />
                    ) : (
                      <FaRegEyeSlash onClick={handlePassword} />
                    )}
                  </EyeIcon>

                  {currentpasserr && (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {currentpassmsg}
                    </Div>
                  )}
                </Div>

                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400px"
                  fontSize="14px"
                  marginBottom="8px !important"
                >
                  New Password
                </Div>
                <Div position="relative" padding=" 0px 0px 10px 0px">
                  <Input
                    style={
                      newpasserr || passErr || lenErr || strength
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    type={PassShow1 ? "password" : "text"}
                    placeholder="Enter New Password"
                    padding="0 0 0 10px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    height="42px"
                    width="100%"
                    borderRadius="4px"
                    onChange={(e) => newpassword(e, "new")}
                    value={newpass}
                  />

                  <EyeIcon
                    color="#2E2E2E"
                    height="16px"
                    width="20px"
                    top="24px"
                    right="11px"
                  >
                    {PassShow1 ? (
                      <FiEye onClick={handlePassword1} />
                    ) : (
                      <FaRegEyeSlash onClick={handlePassword1} />
                    )}
                  </EyeIcon>

                  {newpasserr && (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {newpassmsg}
                    </Div>
                  )}
                  {strength ? (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="11px"
                      color="red"
                      fontWeight="400"
                      line-height="27px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      Should contain atleast one uppercase, lowercase, number
                      and special character{" "}
                    </Div>
                  ) : (
                    ""
                  )}
                  {passErr ? (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="28px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      Password and confirm password are mismatching
                    </Div>
                  ) : (
                    ""
                  )}
                  {lenErr ? (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="28px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {`Minimum ${minimum} characters required`}{" "}
                    </Div>
                  ) : (
                    ""
                  )}
                </Div>

                <Div
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400px"
                  fontSize="14px"
                  marginBottom="8px !important"
                >
                  Re-type New Password
                </Div>

                <Div position="relative">
                  <Input
                    style={
                      repasserr || passErr || cpass || lenErr || strength
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    type={PassShow2 ? "password" : "text"}
                    placeholder="Re-type New Password"
                    padding="0 0 0 10px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    height="42px"
                    width="100%"
                    borderRadius="4px"
                    onChange={(e) => repassword(e, "re")}
                    value={repass}
                  />

                  <EyeIcon
                    color="#2E2E2E"
                    height="16px"
                    width="20px"
                    top="24px"
                    right="11px"
                  >
                    {PassShow2 ? (
                      <FiEye onClick={handlePassword2} />
                    ) : (
                      <FaRegEyeSlash onClick={handlePassword2} />
                    )}
                  </EyeIcon>

                  {repasserr && (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {repassmsg}
                    </Div>
                  )}
                  {passErr ? (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="28px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      Password and confirm password are mismatching
                    </Div>
                  ) : (
                    ""
                  )}

                  {lenErr ? (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="28px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {`Minimum ${minimum} characters required`}{" "}
                    </Div>
                  ) : (
                    ""
                  )}
                  {strength ? (
                    <Div
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="11px"
                      color="red"
                      fontWeight="400"
                      line-height="27px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      Should contain atleast one uppercase, lowercase, number
                      and special character{" "}
                    </Div>
                  ) : (
                    ""
                  )}
                </Div>

                <Text
                  marginTop="8px"
                  fontSize="12px"
                  color="#747474"
                  marginBottom="18px"
                >
                  {`Requirements: Minimum ${minimum} characters.Should contain atleast one
                  uppercase, lowercase, number and special character`}
                </Text>
                <Div display="flex" justifyContent="space-between">
                  {props.passStatus === "grace_fail" ? (
                    <></>
                  ) : (
                    <ButtonGroup
                      type="button"
                      fontSize="14px"
                      fontWeight="600"
                      borderRadius="4px"
                      border="1px solid #cbd5e0"
                      padding="8px 13px 8px 12px"
                      background="#ffff"
                      color="#2E2E2E"
                      hoverBackColor="#d9dadd"
                      cursor="pointer"
                      onClick={closeModal}
                    >
                      <Span fontSize="14px" fontWeight="400">
                        Cancel
                      </Span>
                    </ButtonGroup>
                  )}
                  <ButtonGroup
                    type="submit"
                    borderRadius="4px"
                    border="1px solid #2C7BE5"
                    padding="8px 20px 8px 21px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    hoverBackColor="#2C7BE5"
                    cursor="pointer"
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                    >
                      Update password
                    </Span>
                  </ButtonGroup>
                </Div>
              </ModalBody>
            </Form>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default memo(PasswordExpired);
