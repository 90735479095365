import React, { useEffect, useRef, memo } from "react";
import Chart from "chart.js/auto";
import { Div } from "../../StyledComponents";

const Line = (props) => {
  const chartRef = useRef(null);

  //   (ctx.p1.parsed.y > 85 || ctx.p1.parsed.y < 75 ? value : undefined)

  // Chart.Legend.afterFit = function() {
  //   this.height = this.height + 50;
  // };

  const data = {
    labels: props.label,
    datasets: props.dataSet,
  };

  const scale = props.axisRange
    ? {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          min: props.axisRange.min,
          max: props.axisRange.max,
          ticks: {
            stepSize: props.axisRange?.step,
          },
        },
      }
    : {
        x: {
          grid: {
            display: false,
          },
        },
      };

  const config = {
    type: "line",
    data: data,
    options: {
      layout: {
        padding: {
          bottom: 20,
        },
      },
      scales: scale,
      responsive: true,
      maintainAspectRatio: false,
      font: "'Open, sans-serif'",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => props?.label1(context),
          },
        },
      },
    },
  };

  useEffect(() => {
    const context = chartRef.current.getContext("2d");
    const myChart = new Chart(context, config);

    return () => {
      myChart.destroy();
    };
  }, [props.dataSet]);

  return (
    <Div height="300px" width="100%">
      <canvas id="myChart" ref={chartRef} />
    </Div>
  );
};

export default memo(Line);
