import axiosInst from "../../Config";

export const StateListApi = async (credential) =>
    await axiosInst.post(`vozorpm/list?list_name=state`, credential);

export const RaceListApi = async (credential) =>
    await axiosInst.post(`vozorpm/list?list_name=race`, credential);

export const EthnicityListApi = async (credential) =>
    await axiosInst.post(`vozorpm/list?list_name=ethrace`, credential);

export const LanguageListApi = async (credential) =>
    await axiosInst.post(`vozorpm/list?list_name=language`, credential);