import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

import {
  DeletePlanApi,
  GetTreatPlanApi,
  GetTreatmentlan,
  PlanNameCheckApi,
  SetTreatPlanToPatientApi,
  TreatmentlanInsert,
  UpdateTreatment,
} from "../Apis/TreatmentPlanApi";
import {
  SetDeletePlan,
  SetIcd10Codes,
  SetPlanDetails,
  SetPlanDetailsforDashB,
  SetPlanNameResponse,
  SetPlanforPat,
  SetTreatPlanDetails,
} from "../../StateManagement/Reducers/TreatmentPlanState";
import { ICD10Api } from "../Apis/MedicalApi";

export function* getTreatPlan({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(GetTreatPlanApi, requiredData);
    if (payload.pid === 0) {
      if (res.status === 200) {
        if (res.data.length !== 0) {
          const Data = Decrypt_Value(res.data, "vozorpm");
          yield put(SetPlanDetails(Data));
        } else {
          yield put(SetPlanDetails([]));
        }
      }
    } else {
      if (res.status === 200) {
        if (res.data.length !== 0) {
          const Data = Decrypt_Value(res.data, "vozorpm");
          yield put(SetPlanDetailsforDashB(Data));
        } else {
          yield put(SetPlanDetailsforDashB([]));
        }
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SetPlantoPatient({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(SetTreatPlanToPatientApi, requiredData);
    if (res.status === 200) {
      try {
        const res = yield call(GetTreatPlanApi, requiredData);
        if (payload.pid === 0) {
          if (res.status === 200) {
            if (res.data.length !== 0) {
              const Data = Decrypt_Value(res.data, "vozorpm");
              yield put(SetPlanDetails(Data));
            } else {
              yield put(SetPlanDetails([]));
            }
          }
        } else {
          if (res.status === 200) {
            if (res.data.length !== 0) {
              const Data = Decrypt_Value(res.data, "vozorpm");
              yield put(SetPlanDetailsforDashB(Data));
            } else {
              yield put(SetPlanDetailsforDashB([]));
            }
          }
        }
      } catch (e) {
        console.log(e.message);
      }

      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPlanforPat(Data));
    } else {
      yield put(SetPlanforPat([]));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeletePlan({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(DeletePlanApi, requiredData);
    if (res.status === 200) {
      try {
        const res = yield call(GetTreatPlanApi, requiredData);
        if (payload.pid === 0) {
          if (res.status === 200) {
            if (res.data.length !== 0) {
              const Data = Decrypt_Value(res.data, "vozorpm");
              yield put(SetPlanDetails(Data));
            } else {
              yield put(SetPlanDetails([]));
            }
          }
        } else {
          if (res.status === 200) {
            if (res.data.length !== 0) {
              const Data = Decrypt_Value(res.data, "vozorpm");
              yield put(SetPlanDetailsforDashB(Data));
            } else {
              yield put(SetPlanDetailsforDashB([]));
            }
          }
        }
      } catch (e) {
        console.log(e.message);
      }
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetDeletePlan(Data));
    } else {
      yield put(SetDeletePlan([]));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* planNameValidation({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozorpm");
  try {
    const res = yield call(PlanNameCheckApi, ecrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetPlanNameResponse(Data));
    }
  } catch (e) {
    yield put(SetPlanNameResponse(""));
  }
}

export function* Icd10Worker({ payload }) {
  const credential = payload.search_key;

  try {
    const response = yield call(ICD10Api, credential);
    if (response.status === 200) {
      const getValue = response.data[3].map((item) => ({
        title: `${item[0]} -- ${item[1]}`,
      }));
      yield put(SetIcd10Codes(getValue));
    }
  } catch (e) {
    yield put(SetIcd10Codes([]));
  }
}

export function* PostTreatmentPlan({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozorpm");
  try {
    const response = yield call(
      credential.id ? UpdateTreatment : TreatmentlanInsert,
      ecrypted
    );
    if (response.status === 200) {
      const Data = Decrypt_Value(response.data, "vozorpm");
      yield put(SetPlanNameResponse(Data));
    }
  } catch (e) {
    yield put(SetPlanNameResponse(""));
  }
}

export function* PlanDetailWorker({ payload }) {
  const credential = payload;
  // let ecrypted = Encrypt_Value(credential, "vozorpm");
  try {
    const response = yield call(GetTreatmentlan, credential);
    if (response.status === 200) {
      const Data = Decrypt_Value(response.data, "vozorpm");
      yield put(SetTreatPlanDetails(Data));
    }
  } catch (e) {
    yield put(SetTreatPlanDetails([]));
  }
}
