import React, { useState, useEffect } from "react";
import MultiSelect from "./MultiSelect";
import { BsXLg } from "react-icons/bs";
import moment from "moment";
import {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Div,
  Span,
  Image,
  PageSubTitle,
  Table,
  Tablerow,
  TableHead,
  TableBody,
  TableHeadCell,
  FormGroup,
  Button,
} from "../StyledComponents";
import { Input, DeviceContainer } from "./MultiSelect/styles";
import { DeviceContainerAdd, TableBodyCellDev } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  AddDevices,
  DeviceResponse,
  GetSerialCheck,
} from "../../StateManagement/Reducers/PatientDemoState";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import DBHost from "../../Config/DBHost";

const AddDevice = (props) => {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const dispatch = useDispatch();
  const All_BP_Options = [
    { value: "BP1", name: "Omron Digital Blood Pressure Monitor" },
    { value: "BP2", name: "Bodytrace Blood Pressure Monitor" },
    { value: "BP3", name: "iBlood Pressure" },
    { value: "BP4", name: "iBlood Pressure plus" },
    { value: "BP5", name: "iBlood Pressure 2.0" },
    { value: "BP6", name: "iBlood Pressure Classic" },
    { value: "BP7", name: "Hicks Blood Pressure Monitor" },
    { value: "BP8", name: "Marsonik Blood Pressure Monitor" },
    { value: "BP9", name: "Tenovi Digital Blood Pressure Monitor" },
  ];
  const All_HR_Options = [
    { value: "HR1", name: "Omron ECG" },
    { value: "HR2", name: "Kardio Screen" },
  ];
  const All_CG_Options = [
    { value: "CG1", name: "iGlucose" },
    { value: "CG2", name: "Marsonik Blood Glucose Monitor" },
    { value: "CG3", name: "Tenovi Blood Glucose Meter" },
    { value: "CG4", name: "Accusure Continuous Glucose Monitor" },
    { value: "CG5", name: "Dexcom G6 Continuous Glucose Monitor" },
    { value: "CG6", name: "Dexcom G7 Continuous Glucose Monitor" },
    { value: "CG7", name: "Freestyle Libre Continuous Glucose Monitor" },
    { value: "CG8", name: "Freestyle Libre Pro Continuous Glucose Monitor" },
  ];
  const All_PO_Options = [
    { value: "PO1", name: "iPlulse Ox" },
    { value: "PO2", name: "Marsonic Pulse Oximeter" },
    { value: "PO3", name: "Tenovi Pulse Oximeter" },
  ];
  const All_WS_options = [
    { value: "WS1", name: "Omron Digital Weight Scale" },
    { value: "WS2", name: "Bodytrace Weight Scale" },
    { value: "WS3", name: "Tenovi Weigh Scale" },
    { value: "WS4", name: "iScale" },
    { value: "WS5", name: "iScale plus" },
    { value: "WS6", name: "Marsonik Weight Scale" },
  ];
  const [BPOptions, setBPOptions] = useState([]);
  const [HROptions, setHROptions] = useState([]);
  const [CGOptions, setCGOptions] = useState([]);
  const [POOptions, setPOOptions] = useState([]);
  const [WSOptions, setWSOptions] = useState([]);
  const [error, setError] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const AllOptions = [
    BPOptions,
    HROptions,
    CGOptions,
    POOptions,
    WSOptions,
  ].flat();
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [serialCheck, setSerialCheck] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const assignedDevices = props.enrolledDevices;
  const patientData = useSelector((state) => state.Patient.SinglePatient);
  const addDeviceResponse = useSelector((state) => state.PatientDemo.DeviceRes);
  const serialResponse = useSelector(
    (state) => state.PatientDemo.serialNoCheck
  );
  const [dis, setDis] = useState(false);
  let file = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//
  const BPOptionsHandler = (options, index = "") => {
    let si = options[index]?.serialNo;
    let id = options[index]?.id;
    if (!id) {
      let a = checkAlreadyEntered(
        options,
        index,
        CGOptions,
        BPOptions,
        HROptions,
        POOptions,
        WSOptions,
        si
      );
      setBPOptions(a[0]);
      if (a[1]) {
        setSerialCheck(options[index]?.serialNo);
        setDeviceType(options[index]?.value);
      }
    } else {
      if (
        options[index]?.serialNo === assignedDevices["BPvalues"][index].serialNo
      ) {
        setBPOptions(options);
      } else {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setBPOptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      }
    }
  };

  const HROptionsHandler = (options, index = "") => {
    let si = options[index]?.serialNo;
    let id = options[index]?.id;
    if (!id) {
      let a = checkAlreadyEntered(
        options,
        index,
        CGOptions,
        BPOptions,
        HROptions,
        POOptions,
        WSOptions,
        si
      );
      setHROptions(a[0]);
      if (a[1]) {
        setSerialCheck(options[index]?.serialNo);
        setDeviceType(options[index]?.value);
      }
    } else {
      if (
        options[index]?.serialNo === assignedDevices["HRvalues"][index].serialNo
      ) {
        setHROptions(options);
      } else {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setHROptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      }
    }
  };

  const CGOptionsHandler = (options, index = "") => {
    let si = options[index]?.serialNo;
    let id = options[index]?.id;
    if (!id) {
      let a = checkAlreadyEntered(
        options,
        index,
        CGOptions,
        BPOptions,
        HROptions,
        POOptions,
        WSOptions,
        si
      );

      setCGOptions(a[0]);
      if (a[1]) {
        setSerialCheck(options[index]?.serialNo);
        setDeviceType(options[index]?.value);
      }
    } else {
      if (
        options[index]?.serialNo === assignedDevices["CGvalues"][index].serialNo
      ) {
        setCGOptions(options);
      } else {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setCGOptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      }
    }
  };

  const POOptionsHandler = (options, index = "") => {
    let si = options[index]?.serialNo;
    let id = options[index]?.id;
    if (!id) {
      let a = checkAlreadyEntered(
        options,
        index,
        CGOptions,
        BPOptions,
        HROptions,
        POOptions,
        WSOptions,
        si
      );
      setPOOptions(a[0]);
      if (a[1]) {
        setSerialCheck(options[index]?.serialNo);
        setDeviceType(options[index]?.value);
      }
    } else {
      if (
        options[index]?.serialNo === assignedDevices["POvalues"][index].serialNo
      ) {
        setPOOptions(options);
      } else {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setPOOptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      }
    }
  };

  const WSOptionsHandler = (options, index = "") => {
    let si = options[index]?.serialNo;
    let id = options[index]?.id;
    if (!id) {
      let a = checkAlreadyEntered(
        options,
        index,
        CGOptions,
        BPOptions,
        HROptions,
        POOptions,
        WSOptions,
        si
      );
      setWSOptions(a[0]);
      if (a[1]) {
        setSerialCheck(options[index]?.serialNo);
        setDeviceType(options[index]?.value);
      }
    } else {
      if (
        options[index]?.serialNo === assignedDevices["WSvalues"][index].serialNo
      ) {
        setWSOptions(options);
      } else {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setWSOptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      }
    }
  };

  const serialNumberValidation = (array) => {
    // to check if it has any errors before saving
    let a = array.map((row, index) => {
      if (row?.id !== "") {
        if (row.serialNo === "" && !row.error) {
          return { ...row, error: true, Msg: "Enter a valid Serial No" };
        } else if (row.serialNo !== "" && !row.error) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      } else {
        return row;
      }
    });
    setError((prevError) => true);
    return a;
  };

  const checkAlreadyEntered = (
    array,
    index,
    array0,
    array1,
    array2,
    array3,
    array4,
    serial
  ) => {
    // to check for repeated entries
    setIsFormSubmitted(false);
    if (serial) {
      const isInArray0 = array0.some(
        (obj) => obj["serialNo"].trim() === array[index].serialNo.trim()
      );
      if (isInArray0) {
        let a = [...array];
        let obj = {
          ...array[index],
          error: true,
          Msg: "Already Entered Pls check",
        };
        a[index] = obj;
        let b = [];
        b[0] = a;
        b[1] = false;
        return b;
      } else {
        const isInArray1 = array1.some(
          (obj) => obj["serialNo"].trim() === array[index].serialNo.trim()
        );
        if (isInArray1) {
          let a = [...array];
          let obj = {
            ...array[index],
            error: true,
            Msg: "Already Entered Pls check",
          };
          a[index] = obj;

          let b = [];
          b[0] = a;
          b[1] = false;
          return b;
        } else {
          const isInArray2 = array2.some(
            (obj) => obj["serialNo"].trim() === array[index].serialNo.trim()
          );
          if (isInArray2) {
            let a = [...array];
            let obj = {
              ...array[index],
              error: true,
              Msg: "Already Entered Pls check",
            };
            a[index] = obj;

            let b = [];
            b[0] = a;
            b[1] = false;
            return b;
          } else {
            const isInArray3 = array3.some(
              (obj) => obj["serialNo"].trim() === array[index].serialNo.trim()
            );
            if (isInArray3) {
              let a = [...array];
              let obj = {
                ...array[index],
                error: true,
                Msg: "Already Entered Pls check",
              };
              a[index] = obj;

              let b = [];
              b[0] = a;
              b[1] = false;
              return b;
            } else {
              const isInArray4 = array4.some(
                (obj) => obj["serialNo"].trim() === array[index].serialNo.trim()
              );
              if (isInArray4) {
                let a = [...array];
                let obj = {
                  ...array[index],
                  error: true,
                  Msg: "Already Entered Pls check",
                };
                a[index] = obj;

                let b = [];
                b[0] = a;
                b[1] = false;
                return b;
              } else {
                let b = [];
                b[0] = array;
                b[1] = true;
                return b;
              }
            }
          }
        }
      }
    } else {
      let b = [];
      b[0] = array;
      b[1] = true;
      return b;
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    setDis(true);
    if (!AllOptions.length) {
      setError(true);
      setModalMsg("Please select atleast one device");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
      }, 3000);
    } else {
      const updatedBPOptions = serialNumberValidation(BPOptions);
      const updatedHROptions = serialNumberValidation(HROptions);
      const updatedCGOptions = serialNumberValidation(CGOptions);
      const updatedPOOptions = serialNumberValidation(POOptions);
      const updatedWSOptions = serialNumberValidation(WSOptions);

      setBPOptions(updatedBPOptions);
      setHROptions(updatedHROptions);
      setCGOptions(updatedCGOptions);
      setPOOptions(updatedPOOptions);
      setWSOptions(updatedWSOptions);

      const allArraysPassedValidation =
        updatedBPOptions.every((row) => !row.error) &&
        updatedHROptions.every((row) => !row.error) &&
        updatedCGOptions.every((row) => !row.error) &&
        updatedPOOptions.every((row) => !row.error) &&
        updatedWSOptions.every((row) => !row.error);
      if (allArraysPassedValidation) {
        setIsFormSubmitted(true);
      } else {
        setIsFormSubmitted(false);
      }
    }
    setError(!AllOptions.length);
    setTimeout(() => {
      setDis(false);
    }, 1500);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // ------------------------------ Functions End------------------------------------------------------------- //
  useEffect(() => {
    if (isFormSubmitted && !error) {
      dispatch(
        AddDevices({
          uid: user_id,
          pid: props.pid,
          BPOptions: BPOptions,
          HROptions: HROptions,
          CGOptions: CGOptions,
          POOptions: POOptions,
          WSOptions: WSOptions,
          src: props.pid === 0 ? "Devices" : "Demo",
        })
      );
    }
  }, [error, isFormSubmitted]);

  useEffect(() => {
    if (Object.keys(assignedDevices).length > 0) {
      const updatedBPOptions = [...BPOptions, ...assignedDevices["BPvalues"]];
      setBPOptions(updatedBPOptions);
      const updatedHROptions = [...HROptions, ...assignedDevices["HRvalues"]];
      setHROptions(updatedHROptions);
      const updatedCGOptions = [...CGOptions, ...assignedDevices["CGvalues"]];
      setCGOptions(updatedCGOptions);
      const updatedPOOptions = [...POOptions, ...assignedDevices["POvalues"]];
      setPOOptions(updatedPOOptions);
      const updatedWSOptions = [...WSOptions, ...assignedDevices["WSvalues"]];
      setWSOptions(updatedWSOptions);
    }
  }, []);
  useEffect(() => {
    if (addDeviceResponse !== "") {
      if (addDeviceResponse === "Devices added sucessfully") {
        setModalAlerShow(true);
        setModalMsg("Devices added Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          props.onHide();
          props.showOverflow();
        }, 1000);
        dispatch(DeviceResponse(""));
      }
    }
    if (addDeviceResponse !== "") {
      if (addDeviceResponse === "Unable to add Devices") {
        setModalAlerShow(true);
        setModalMsg("Unable to add Devices");
        setStatus("retry");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);
        dispatch(DeviceResponse(""));
      }
    }
  }, [addDeviceResponse]);

  useEffect(() => {
    if (serialResponse === "exists") {
      let a = BPOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let b = CGOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let c = HROptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let d = POOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let f = WSOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      setWSOptions(f);
      setPOOptions(d);
      setHROptions(c);
      setCGOptions(b);
      setSerialCheck("");
      setDeviceType("");
      setBPOptions(a);
    } else if (serialResponse === "not exists") {
      let a = BPOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let b = CGOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let c = HROptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let d = POOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let f = WSOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      setWSOptions(f);
      setPOOptions(d);
      setHROptions(c);
      setCGOptions(b);
      setBPOptions(a);

      setSerialCheck("");
      setDeviceType("");
    } else if (serialResponse === "invalid") {
      let a = BPOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let b = CGOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let c = HROptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let d = POOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let f = WSOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      setWSOptions(f);
      setPOOptions(d);
      setHROptions(c);
      setCGOptions(b);
      setSerialCheck("");
      setDeviceType("");
      setBPOptions(a);
    }
  }, [serialResponse]);

  useEffect(() => {
    // to check already assigned devices
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (serialCheck !== "") {
          dispatch(
            GetSerialCheck({
              serial_number: serialCheck,
              src: props.pid === 0 ? "Devices" : "Demo",
              device_value: deviceType,
            })
          );
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [serialCheck]);
  return (
    <Div>
      <ModalHeader
        padding="20px 54px 16px 54px"
        borderLess
        justifyContent="space-between"
      >
        <ModalTitle
          lineHight="30px"
          fontSize="22px"
          fontWeight="600"
          color="#0A1629"
        >
          Add Devices
        </ModalTitle>
        <BsXLg
          style={{ color: "#407BFF", cursor: "pointer" }}
          onClick={() => props.onHide()}
          id="dbrd_ad-crd_cls"
        />
      </ModalHeader>
      <ModalBody
        padding="24px 54px 24px 54px"
        borderTop="1px solid rgba(46, 46, 46, 0.25)"
      >
        {props.pid !== 0 && (
          <Div display="flex" marginBottom="24px">
            {patientData.img_url === null ? (
              <Div
                borderRadius="50%"
                width="50px"
                height="50px"
                backgroundColor="#a8aebe"
                display="inline-block"
                marginRight="16px"
              >
                <Span
                  fontSize="28px"
                  lineHeight="16px"
                  alignItems="center"
                  textAlign="center"
                  color="#ffffff"
                  textShadow="0px 1px 0px rgba(0, 0, 0, 0.15)"
                  position="relative"
                  top="16px"
                  left="1px"
                  fontWeight="600"
                >
                  {patientData.patname.charAt(0).toUpperCase()}
                </Span>
              </Div>
            ) : (
              <Image
                src={file + patientData.img_url}
                alt="image-failed"
                loading="lazy"
                width="50px"
                height="50px"
                borderRadius="50%"
                display="inline-block"
                marginRight="12px"
              />
            )}
            <Div
              fontFamily="'Open Sans',sans-serif"
              fontSize="14px"
              lineHeight="20px"
              color="#2E2E2E"
            >
              <Span fontWeight="600" marginBottom="8px">
                {patientData.patname},Age {patientData.age}
              </Span>
              <Span fontWeight="400">
                {moment(patientData.DOB).format("DD/MM/YYYY")}
              </Span>
            </Div>
          </Div>
        )}
        <DeviceContainer display="flex">
          <DeviceContainer width="50%">
            <Div>
              <PageSubTitle marginBottom="8px">
                Blood Pressure Devices
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  options={All_BP_Options}
                  selectedOptions={BPOptions}
                  onSelectedOptionsChange={BPOptionsHandler}
                  width="378px"
                />
              </Div>
              {BPOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
            <Div>
              <PageSubTitle marginBottom="8px">
                Continuous Glucose Monitors
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  options={All_CG_Options}
                  selectedOptions={CGOptions}
                  onSelectedOptionsChange={CGOptionsHandler}
                  width="378px"
                />
              </Div>
              {CGOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
            <Div>
              <PageSubTitle marginBottom="8px">Weighing Scale</PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  options={All_WS_options}
                  selectedOptions={WSOptions}
                  onSelectedOptionsChange={WSOptionsHandler}
                  width="378px"
                />
              </Div>
              {WSOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup>
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
          </DeviceContainer>
          <DeviceContainerAdd width="50%">
            <Div>
              <PageSubTitle marginBottom="8px">
                Heart Rate Monitoring Devices
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  options={All_HR_Options}
                  selectedOptions={HROptions}
                  onSelectedOptionsChange={HROptionsHandler}
                  width="378px"
                />
              </Div>
              {HROptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
            <Div>
              <PageSubTitle marginBottom="8px">
                Pulse Oximeter Devices
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  options={All_PO_Options}
                  selectedOptions={POOptions}
                  onSelectedOptionsChange={POOptionsHandler}
                  width="378px"
                />
              </Div>
              {POOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
          </DeviceContainerAdd>
        </DeviceContainer>
      </ModalBody>
      <ModalFooter padding="16px 40px 24px 40px">
        <Div overflowX="auto">
          <PageSubTitle marginBottom="16px">Device Content</PageSubTitle>
          <Table
            border="1px solid #2E2E2E40"
            borderTop="1px solid #2E2E2E40"
            height="fit-content"
            tableLayout="fixed"
          >
            <TableHead>
              <Tablerow
                display="flex"
                background="#F1F5F8"
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                color="#2C7BE5"
                alignItems="center"
                fontSize="14px"
                lineHeight="19px"
                padding="18px 20px"
              >
                <TableHeadCell
                  width="8%"
                  borderRight="1px solid #2E2E2E40"
                  textAlign="center"
                >
                  S.No
                </TableHeadCell>
                <TableHeadCell
                  width="42%"
                  borderRight="1px solid #2E2E2E40"
                  paddingLeft="67px !important"
                >
                  Device Name
                </TableHeadCell>
                <TableHeadCell
                  width="25%"
                  borderRight="1px solid #2E2E2E40"
                  paddingLeft="57px !important"
                >
                  Device Type
                </TableHeadCell>
                <TableHeadCell width="25%" paddingLeft="53px !important">
                  Serial.no
                </TableHeadCell>
              </Tablerow>
            </TableHead>
            <TableBody>
              {AllOptions && AllOptions.length > 0 ? (
                <>
                  {AllOptions.map((option, index) => (
                    <Tablerow
                      alignItems="center"
                      display="flex"
                      fontFamily="Open Sans, sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      color="#2E2E2E"
                      lineHeight="19px"
                      padding="18px 20px"
                      border_Bottom="1px solid #2E2E2E40"
                      lastchild
                    >
                      <TableBodyCellDev
                        columnWidth="8%"
                        textAlign="center"
                        borderRight="1px solid #2E2E2E40"
                      >
                        {index + 1}
                      </TableBodyCellDev>
                      <TableBodyCellDev
                        columnWidth="42%"
                        borderRight="1px solid #2E2E2E40"
                        paddingLeft="37px !important"
                      >
                        {option.name}
                      </TableBodyCellDev>
                      <TableBodyCellDev
                        columnWidth="25%"
                        borderRight="1px solid #2E2E2E40"
                        paddingLeft="37px !important"
                      >
                        {option.value.includes("BP")
                          ? "Blood pressure"
                          : option.value.includes("HR")
                          ? "Heart Rate"
                          : option.value.includes("CG")
                          ? "Continuous Glucose Monitor"
                          : option.value.includes("PO")
                          ? "Pulse Oximeter"
                          : option.value.includes("WS")
                          ? "Weigh Scale"
                          : ""}
                      </TableBodyCellDev>
                      {option.serialNo ? (
                        <TableBodyCellDev
                          columnWidth="25%"
                          paddingLeft="37px !important"
                        >
                          {option.serialNo}
                        </TableBodyCellDev>
                      ) : (
                        <TableBodyCellDev
                          columnWidth="25%"
                          color="red !important"
                          fontSize="10px !important"
                        >
                          Please enter the valid serial no
                        </TableBodyCellDev>
                      )}
                    </Tablerow>
                  ))}
                  {Array.from({ length: 6 - AllOptions.length }).map(
                    (_, index) => (
                      <Tablerow
                        alignItems="center"
                        display="flex"
                        padding="18px 20px"
                        border_Bottom="1px solid #2E2E2E40"
                        lastchild
                      >
                        <TableBodyCellDev
                          columnWidth="8%"
                          textAlign="center"
                          height="56px"
                          borderRight="1px solid #2E2E2E40"
                        ></TableBodyCellDev>
                        <TableBodyCellDev
                          columnWidth="42%"
                          height="56px"
                          borderRight="1px solid #2E2E2E40"
                        ></TableBodyCellDev>
                        <TableBodyCellDev
                          columnWidth="25%"
                          height="56px"
                          borderRight="1px solid #2E2E2E40"
                        ></TableBodyCellDev>
                        <TableBodyCellDev columnWidth="25%"></TableBodyCellDev>
                      </Tablerow>
                    )
                  )}
                </>
              ) : (
                Array.from({ length: 6 }).map((_, index) => (
                  <Tablerow
                    alignItems="center"
                    display="flex"
                    padding="18px 20px"
                    border_Bottom="1px solid #2E2E2E40"
                    lastchild
                  >
                    <TableBodyCellDev
                      columnWidth="8%"
                      textAlign="center"
                      borderRight="1px solid #2E2E2E40"
                    ></TableBodyCellDev>
                    <TableBodyCellDev
                      columnWidth="42%"
                      borderRight="1px solid #2E2E2E40"
                    ></TableBodyCellDev>
                    <TableBodyCellDev
                      columnWidth="25%"
                      borderRight="1px solid #2E2E2E40"
                    ></TableBodyCellDev>
                    <TableBodyCellDev columnWidth="25%"></TableBodyCellDev>
                  </Tablerow>
                ))
              )}
            </TableBody>
          </Table>
        </Div>
        <Div marginTop="40px" textAlign="center">
          <Button
            height="36px"
            background="#FFF"
            borderRadius="4px"
            padding="8px 21px"
            color="#2C7BE5"
            border="1px solid #2C7BE5"
            lineHeight="19px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="14px"
            fontWeight="600"
            onClick={() => props.onHide()}
            cursor="pointer"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            activeBackgroundColor="rgba(44, 123, 229, 0.10)"
          >
            Cancel
          </Button>
          <Button
            marginLeft="40px"
            height="36px"
            background="#2C7BE5"
            borderRadius="4px"
            padding="8px 21px"
            color="#FFFFFF"
            border="none"
            lineHeight="19px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="14px"
            fontWeight="600"
            onClick={handleSave}
            cursor="pointer"
            disabled={dis}
            isDisabled={dis}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Assign
          </Button>
        </Div>
      </ModalFooter>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Div>
  );
};
export default AddDevice;
