import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Header,
  Title,
  Search,
  Data,
  Row,
  Avatar,
  AvatarLetter,
  Image,
  Text,
  TnumbComponent,
  SearchInputText,
  Dropdown,
  DropdownItems,
  FiSearchIcon,
} from "./styles";
import { SpanTag, Div } from "../../StyledComponents";

import { getMsgPatsearch } from "../../../StateManagement/Reducers/MessengerState";
import moment from "moment";
import DBHost from "../../../Config/DBHost";

const Messages = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loadingIS, setLoadingIS] = useState(false);
  const [dis, setDis] = useState(false);
  const [details, setDetails] = useState("");
  const [tempPatPic, setTempPatPic] = useState("");
  const patList = useSelector((state) => state.Messenger.patList);
  const [temp, setTemp] = useState([]);
  const [SecMsgList, setSecMsgList] = useState([]);
  const [patNames, setPatNames] = useState([]);
  const [showDrop, setShowDrop] = useState(false);

  var Test_URL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  // ----------------------------State and Variables ---------------------------------------------------///

  // -------------------------- Functions Start-------------------------------------------------------------//
  const handleChange = (e) => {
    setDis(true);
    setSearch(e.target.value);
    setDetails("");
  };
  const handleSelect = (e) => {
    setSearch(e.name);
    setDetails(e.pid);
    setDis(false);
    setTempPatPic(e.img_url);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleClick = (e) => {
    if (
      e.target.classList &&
      !e.target.classList.contains("sec-msg-dropdown", "sec-msg-select")
    ) {
      setShowDrop(() => {
        return false;
      });
    } else if (
      e.target.classList &&
      e.target.classList.contains("sec-msg-dropdown", "sec-msg-select")
    ) {
      setShowDrop(() => {
        return true;
      });
    }
  };

  const makeItAsMinutes = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    const formattedDuration = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedDuration;
  };
  //-------------------------- Functions End-------------------------------------------------------------//

  // --------------------------useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    if (details !== "") {
      setSecMsgList([]);
      let a = [];

      temp.forEach((row) => {
        if (row.pid === details) {
          if (row.message === null) {
            row.message = "";
            a.push(row);
          } else {
            a.push(row);
          }
          setSecMsgList(a);
        }
      });

      if (a.length <= 0) {
        let obj = {
          img_url: tempPatPic,
          message: "",
          PatName: search,
          unread: "0",
          date: "",
          pid: details,
        };

        a.push(obj);
        setSecMsgList(a);
      }
    } else {
      setSecMsgList(props.secMsgData);
    }

    setTemp(props.secMsgData);
  }, [details, props]);

  useEffect(() => {
    let isCancelled = false;

    setLoadingIS(true);
    setPatNames([]);
    const delayDebounceFn = setTimeout(() => {
      if (!isCancelled && search !== "") {
        let Data = "limit=10&fname=" + search;

        dispatch(getMsgPatsearch(Data));
      }
    }, 300);

    return () => {
      isCancelled = true;
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  useEffect(() => {
    if (patList.length > 0) {
      setLoadingIS(false);
      setPatNames(() => {
        return patList;
      });
    } else {
      setLoadingIS(false);
      setPatNames([]);
    }
  }, [patList]);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.addEventListener("click", handleClick);
    };
  }, []);
  // --------------------------useEffect End-------------------------------------------------------------//

  return (
    <>
      <Header>
        <Title> Secure Messages</Title>
      </Header>
      <Search
        display="flex"
        alignItems="center"
        paddingTop="9px"
        paddingBottom="9px"
        height="56px !important"
      >
        <Search
          height="36px"
          background="#fff"
          width="333px"
          paddingTop="9px"
          paddingBottom="9px"
          display="flex"
          alignItems="center"
          border=" 1px solid rgba(46, 46, 46, 0.25);"
          padding="6px 0px 13px 8px"
          br="4px"
        >
          <FiSearchIcon />
          <SearchInputText
            onChange={(e) => handleChange(e)}
            placeholder="Search Patient"
            value={search}
            className="sec-msg-dropdown"
          />
        </Search>

        <Dropdown
          selected={dis}
          active={search}
          flexDirection="column"
          width="90%"
          position="absolute"
          top="114px"
          left="21px"
          border="1px solid #DBDBDB"
          family="Inter"
          size="14px"
          lineheight="19px"
          weight="400"
          className="sec-msg-select"
        >
          {showDrop ? (
            <>
              {patNames.length !== 0 ? (
                loadingIS ? (
                  <DropdownItems
                    key={"loading"}
                    width="100%"
                    family="Inter"
                    size="14px"
                    lineheight="19px"
                    weight="400"
                    minheight="34px"
                    maxheight="max-content"
                    backgroundColor="#ffff"
                    padding="4px"
                    cursor="pointer"
                    // onClick={() => handleSelect(r)}
                  >
                    "Loading..."
                  </DropdownItems>
                ) : (
                  patNames.map((r, i) => {
                    return (
                      <DropdownItems
                        key={i}
                        width="100%"
                        family="Inter"
                        size="14px"
                        lineheight="19px"
                        weight="400"
                        minheight="34px"
                        maxheight="max-content"
                        backgroundColor="#ffff"
                        padding="4px"
                        cursor="pointer"
                        onClick={() => handleSelect(r)}
                      >
                        {r.text}
                      </DropdownItems>
                    );
                  })
                )
              ) : (
                <DropdownItems
                  width="100%"
                  height="max-content"
                  backgroundColor="#ffff"
                  padding="4px"
                  family="Inter"
                  size="14px"
                  lineheight="19px"
                  weight="400"
                >
                  {loadingIS ? "Loading..." : "No result Found"}
                </DropdownItems>
              )}
            </>
          ) : null}
        </Dropdown>
      </Search>
      <Data height="392px" width="100%">
        {SecMsgList.map((row, ind) => (
          <Row
            key={ind}
            borderBottom="1px solid #cbd5e0"
            dis="flex"
            padding="20px 20px 12px 20px"
            cursor="pointer"
            onClick={() => {
              props.handleChat(row);
              props.EmptforAnotherProv();
            }}
          >
            {row.img_url === null || row.img_url === undefined ? (
              <Avatar
                flex
                width="32px"
                height="32px"
                border="1px solid #ffffff"
              >
                <AvatarLetter>
                  {row.PatName?.charAt(0).toUpperCase()}
                </AvatarLetter>
              </Avatar>
            ) : (
              <Image
                src={Test_URL + row.img_url}
                alt="image"
                borderRadius="40px"
                height="32px"
                width="32px"
                mr="12px"
              />
            )}
            <Row
              dis="flex"
              flexDirection="column"
              width="87%"
              padding="0px 0px 0px 0px"
            >
              <Row dis="flex" justifyContent="space-between" width="100%">
                <Text
                  fontFamily="'Open Sans', sans-serif"
                  fontSize="14px"
                  color="#2E2E2E"
                  lineHeight="16.94px"
                >
                  {row.PatName}
                </Text>
                {row.date && row.date !== "" && (
                  <SpanTag
                    fontFamily="'Open Sans', sans-serif"
                    fontSize="10px"
                    lineHeight="14px"
                    color="#2E2E2EBF"
                  >
                    {moment(row.date).format("hh:mm a")}
                  </SpanTag>
                )}
              </Row>
              <Row width="100%" dis="flex">
                <Text
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16.34px"
                  color="#2E2E2E80"
                  whiteSpace="nowrap"
                  width="200px"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {row.type === "image" ? (
                    <TnumbComponent message={row.message} variant="image" />
                  ) : row.type === "doc" ? (
                    <TnumbComponent message={row.message} variant="doc" />
                  ) : row.type === "audio" ? (
                    <TnumbComponent
                      message={makeItAsMinutes(row.Voicenote_duration)}
                      variant="audio"
                    />
                  ) : (
                    row.message
                  )}
                </Text>
                {row.unread !== 0 && (
                  <Div
                    height="18px"
                    width="20px"
                    backgroundColor="#2C7BE5"
                    borderRadius="50%"
                    marginTop="-2px"
                    marginLeft="auto"
                    padding="3px"
                    zIndex="100"
                  >
                    <Text
                      fontFamily="Nunito Sans"
                      fontWeight="600"
                      fontSize="12px"
                      lineHeight="12px"
                      color="#ffffff"
                      textAlign="center"
                    >
                      {row.unread}
                    </Text>
                  </Div>
                )}
              </Row>
            </Row>
          </Row>
        ))}
      </Data>
    </>
  );
};

export default Messages;
