import React, { useState, useEffect } from "react";
import {
  Div,
  Input,
  FormLabel,
  Button,
  RedStar,
  Image,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
  SelectInput,
  Modal as ModalInfo,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalFooter,
} from "../../StyledComponents";
import {
  TitleHead,
  FieldHead,
  ErrorMessage,
  FileInput,
  Tab1 as Tab,
  Tabs1 as Tabs,
  Span1 as Span,
} from "./styles";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

const AddInsuranceTab = ({ showvalue, setShow, activetab }) => {
  //------------------------------------------State and Variables Start--------------------------------------//
  const getInsType = (value) => {
    switch (value) {
      case "1":
        return "primary";
      case "2":
        return "secondary";
      case "3":
        return "tertiary";
      default:
        return "primary";
    }
  };
  const [InsTypeErr, setInsTypeErr] = useState(false);
  const [InsuredTypeErr, setInsuredTypeErr] = useState(false);
  const [InsPayerErr, setInsPayerErr] = useState(false);
  const [MemberIdErr, setMemberIdErr] = useState(false);
  const [PlanIdErr, setPlanIdErr] = useState(false);
  const [file1Err, setfile1Err] = useState("");
  const [file2Err, setfile2Err] = useState("");
  const [instype, setinstype] = useState(getInsType(activetab));
  const [InsType, setInsType] = useState("");
  const [InsuredType, setInsuredType] = useState("");
  const [InsPayer, setInsPayer] = useState("");
  const [MemberId, setMemberId] = useState("");
  const [PlanId, setPlanId] = useState("");
  const [InsPayerPhone, setInsPayerPhone] = useState("");
  const [InsPayerFax, setInsPayerFax] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupIdErr, setGroupIdErr] = useState("");
  const [preview1, setpreview1] = useState("");
  const [labeltext1, setlabeltext1] = useState("");
  const [file1, setfile1] = useState("");

  const [preview2, setpreview2] = useState("");
  const [labeltext2, setlabeltext2] = useState("");
  const [file2, setfile2] = useState("");

  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ShowOptions2, setShowOptions2] = useState(false);
  const [active, setActive] = React.useState(activetab);
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const DropdownOptionList = [
    {
      label: "Primary Insurance",
      id: "1",
      Key: "Primary",
    },
    { label: "Secondary Insurance", id: "2", Key: "Secondary" },
    { label: "Tertiary Insurance", id: "3", Key: "Tertiary" },
  ];

  const DropdownOptionList1 = [
    { label: "Client", id: "1", Key: "Client" },
    { label: "Client’s Spouse", id: "2", Key: "Spouse" },
    { label: "Client’s Parent", id: "3", Key: "Parent" },
    { label: "Other", id: "4", Key: "Other" },
  ];
  const DropdownOptionList2 = [];

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//
  const handleInsuranceClick = (e) => {
    const index = e.target.id !== "" ? e.target.id : e.currentTarget.id;
    if (index !== active) {
      setActive(index);
    }
    if (index === "1") {
      setinstype("primary");
      //   handlechange1("primary");
    } else if (index === "2") {
      setinstype("secondary");
      //   handlechange1("secondary");
    } else if (index === "3") {
      setinstype("tertiary");
      //   handlechange1("tertiary");
    }
    setInsTypeErr(false);
  };
  const onChange = (e) => {
    if (SingPatDetails.enrollment_status !== "4") {
      var fileName = e.target.value.split("\\").pop();

      var extension = fileName.split(".").pop();
      if (
        extension.toLowerCase() === "jpg" ||
        extension.toLowerCase() === "jpeg" ||
        extension.toLowerCase() === "png"
      ) {
        if (fileName.length > 40) {
          setlabeltext1(fileName.slice(0, 4) + "..." + extension);
        } else {
          setlabeltext1(fileName);
        }
        setfile1(e.target.files[0]);
        // setfile1Err(false);
        setpreview1(URL.createObjectURL(e.target.files[0]));
      } else {
        setpreview1("");
        setfile1("");
        // setfile1Err(true);
        setlabeltext1("Add a Valid Format");
      }
    }
  };
  const onChange2 = (e) => {
    if (SingPatDetails.enrollment_status !== "4") {
      var fileName = e.target.value.split("\\").pop();

      var extension = fileName.split(".").pop();
      if (
        extension.toLowerCase() === "jpg" ||
        extension.toLowerCase() === "jpeg" ||
        extension.toLowerCase() === "png"
      ) {
        if (fileName.length > 40) {
          setlabeltext2(fileName.slice(0, 4) + "..." + extension);
        } else {
          setlabeltext2(fileName);
        }
        setfile2(e.target.files[0]);
        // setfile2Err(false);
        setpreview2(URL.createObjectURL(e.target.files[0]));
      } else {
        setpreview2("");
        setfile2("");
        // setfile2Err(true);
        setlabeltext2("Add a Valid Format");
      }
    }
  };

  const setInsTypeSelect = (r) => {
    setInsType(r.label);
    setShowOptions(false);
    if (r.label !== "") {
      setInsTypeErr(false);
    }
  };
  const setInsuredTypeSelect = (r) => {
    setInsuredType(r.label);
    setShowOptions1(false);
    if (r.label !== "") {
      setInsuredTypeErr(false);
    }
  };
  const setPayerTypeSelect = (r) => {
    setInsPayer(r.label);
    setShowOptions2(false);
    if (r.label !== "") {
      setInsPayerErr(false);
    }
  };
  const inspayphoneChange = (e) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      setInsPayerPhone(e.target.value);
    }
  };
  const inspayFaxChange = (e) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      setInsPayerFax(e.target.value);
    }
  };

  const HandleSave = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      var err = 0;

      if (InsType === "") {
        setInsTypeErr(true);
        err = 2;
      }
      if (InsuredType === "") {
        setInsuredTypeErr(true);
        err = 2;
      }
      if (InsPayer === "") {
        setInsPayerErr(true);
        err = 2;
      }
      if (MemberId === "") {
        setMemberIdErr(true);
        err = 2;
      }
      if (groupId === "") {
        setGroupIdErr(true);
        err = 2;
      }
      if (PlanId === "") {
        setPlanIdErr(true);
        err = 2;
      }
      // if (file1 === "") {
      //   setfile1Err(true);
      //   err = 2;
      // }
      // if (file2 === "") {
      //   setfile2Err(true);
      //   err = 2;
      // }

      if (err > 0) {
        return false;
      }
    }
  };

  const handleClick1 = (e) => {
    const exampleAttr = e.target.id;
    if (exampleAttr !== "insured_type") {
      setShowOptions1(false);
    }
  };

  const handleClick2 = (e) => {
    const exampleAttr = e.target.id;

    if (exampleAttr !== "payer_type") {
      setShowOptions2(false);
    }
  };
  const handleClose = () => {
    // setbtnDisabled(false);
    setShow(false);
  };

  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start--------------------------------------------------//

  useEffect(() => {
    document.addEventListener("click", handleClick1);
    document.addEventListener("click", handleClick2);

    return () => {
      document.removeEventListener("click", handleClick1);
      document.removeEventListener("click", handleClick2);
    };
  }, []);
  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);
  //------------------------------------------UseEffect End--------------------------------------------------//

  return (
    <Div padding="24px 24px 24px 28px">
      <ModalInfo
        show={showvalue}
        justifyContent="center"
        id="modal"
        onClick={(e) => {
          if (e.target.id.includes("modal")) {
            setShow(false);
          }
        }}
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          overflowX="revert"
          overflowY="revert"
          width="540px"
        >
          <ModalHeader padding="21px 21px 16px 28px" borderBottom="none">
            <Div
              color="#000"
              fontFamily="Open Sans, sans-serif"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Insurance Information
            </Div>
            <AiOutlineClose
              onClick={() => {
                handleClose();
              }}
              style={{
                marginLeft: "auto",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          </ModalHeader>

          <ModalBody
            overflowY="auto"
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              paddingTop: "0px",
              overflowX: "hidden",
            }}
          >
            <Div
              className="ins-info-tab"
              id="ins-info-tab-add"
              padding="16px 16px 0 23px"
            >
              <Tabs>
                <Tab
                  padding="8px 14px"
                  onClick={handleInsuranceClick}
                  active={active === "1"}
                  // disabled={datavalue && !btnDisabledtab.includes("primary")}
                  id="1"
                >
                  Primary&nbsp;Insurance
                </Tab>
                <Tab
                  padding="8px 14px"
                  onClick={handleInsuranceClick}
                  active={active === "2"}
                  // disabled={
                  //   datavalue && !btnDisabledtab.includes("secondary")
                  // }
                  id="2"
                >
                  Secondary&nbsp;Insurance
                </Tab>
                <Tab
                  padding="8px 14px"
                  onClick={handleInsuranceClick}
                  // disabled={datavalue && !btnDisabledtab.includes("tertiary")}
                  active={active === "3"}
                  id="3"
                >
                  Tertiary&nbsp;Insurance
                </Tab>
              </Tabs>
            </Div>
            <Div padding="0 30px 0 30px">
              <Div
                display="grid"
                margin="16.5px 0px 0px 0px"
                position="relative"
              >
                <FieldHead>
                  Primary Insured<RedStar>*</RedStar>
                </FieldHead>

                <Div position="relative">
                  <SelectInput
                    placeholder="'Select Primary Insured'"
                    width="100%"
                    height="38px"
                    background="#FFFFFF"
                    fontSize="14px"
                    name="insured_type"
                    id="insured_type"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    margin="8px 0px 0px 0px"
                    padding="9px 12px"
                    onClick={() => setShowOptions1(!ShowOptions1)}
                    style={
                      InsuredTypeErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    {InsuredType}
                  </SelectInput>
                  <DropdownImage
                    marginLeft="0px"
                    marginTop="0px"
                    top="24px"
                    right="15px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </Div>
                {ShowOptions1 && DropdownOptionList1.length > 0 ? (
                  <DropDownDiv
                    width="100%"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    marginTop="64px"
                    position="absolute"
                  >
                    {DropdownOptionList1.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.value}
                          padding="10px 12px 8px 12px"
                          onClick={() => setInsuredTypeSelect(r)}
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}

                {InsuredTypeErr && (
                  <ErrorMessage>Please Select Primary Insured</ErrorMessage>
                )}
              </Div>
              <Div display="grid" margin="24px 0px 0px 0px">
                <FieldHead position="relative">
                  Insurance Payer <RedStar>*</RedStar>
                </FieldHead>

                <Div position="relative">
                  <SelectInput
                    placeholder="'Select Payer'"
                    width="100%"
                    height="38px"
                    background="#FFFFFF"
                    fontSize="14px"
                    name="payer_type"
                    id="payer_type"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    margin="8px 0px 0px 0px"
                    padding="9px 12px"
                    onClick={() => setShowOptions2(!ShowOptions2)}
                    style={
                      InsPayerErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    {InsPayer}
                  </SelectInput>
                  <DropdownImage
                    marginLeft="0px"
                    marginTop="0px"
                    top="24px"
                    right="15px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </Div>
                {ShowOptions2 && DropdownOptionList2.length > 0 ? (
                  <DropDownDiv
                    width="100%"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    marginTop="64px"
                    maxHeight="185px"
                    overflowY="auto"
                  >
                    {DropdownOptionList2.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.value}
                          padding="10px 12px 8px 12px"
                          onClick={() => setPayerTypeSelect(r)}
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}

                {InsPayerErr && (
                  <ErrorMessage>Please Select Payer</ErrorMessage>
                )}
              </Div>
              <Div display="grid" margin="24px 0px 0px 0px">
                <FieldHead position="relative" marginBottom="8px">
                  Member ID <RedStar>*</RedStar>
                </FieldHead>
                <Input
                  borderRadius="4px"
                  background="#FFF"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  width="100%"
                  height="36px"
                  color="#2E2E2E"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  paddingLeft="10px"
                  paddingRight="10px"
                  onChange={(e) => {
                    setMemberId(e.target.value);
                    if (e.target.value !== "") {
                      setMemberIdErr(false);
                    } else {
                      setMemberIdErr(true);
                    }
                  }}
                  style={
                    MemberIdErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                ></Input>
                {MemberIdErr && (
                  <ErrorMessage>Enter a Valid Member ID</ErrorMessage>
                )}
              </Div>
              <Div display="grid" margin="24px 0px 0px 0px">
                <FieldHead position="relative" marginBottom="8px">
                  Group ID <RedStar>*</RedStar>
                </FieldHead>
                <Input
                  borderRadius="4px"
                  background="#FFF"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  width="100%"
                  height="36px"
                  color="#2E2E2E"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  paddingLeft="10px"
                  paddingRight="10px"
                  onChange={(e) => {
                    setGroupId(e.target.value);
                    if (e.target.value !== "") {
                      setGroupIdErr(false);
                    } else {
                      setGroupIdErr(true);
                    }
                  }}
                  style={
                    groupIdErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                ></Input>
                {groupIdErr && (
                  <ErrorMessage>Enter a Valid Group ID</ErrorMessage>
                )}
              </Div>
              <Div display="grid" margin="24px 0px 0px 0px">
                <FieldHead position="relative" marginBottom="8px">
                  Plan ID <RedStar>*</RedStar>
                </FieldHead>
                <Input
                  borderRadius="4px"
                  background="#FFF"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  width="100%"
                  height="36px"
                  color="#2E2E2E"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  paddingLeft="10px"
                  paddingRight="10px"
                  onChange={(e) => {
                    setPlanId(e.target.value);
                    if (e.target.value !== "") {
                      setPlanIdErr(false);
                    } else {
                      setPlanIdErr(true);
                    }
                  }}
                  style={
                    PlanIdErr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                ></Input>
                {PlanIdErr && (
                  <ErrorMessage>Enter a Valid Plan ID</ErrorMessage>
                )}
              </Div>
              <Div display="grid" margin="24px 0px 0px 0px">
                <FieldHead marginBottom="8px">Insurance Payer Phone</FieldHead>
                <Input
                  borderRadius="4px"
                  background="#FFF"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  width="100%"
                  height="36px"
                  color="#2E2E2E"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  paddingLeft="10px"
                  paddingRight="10px"
                  min="0"
                  value={InsPayerPhone}
                  onChange={inspayphoneChange}
                ></Input>
              </Div>
              <Div display="grid" margin="24px 0px 0px 0px">
                <FieldHead marginBottom="8px">Insurance Payer Fax</FieldHead>
                <Input
                  borderRadius="4px"
                  background="#FFF"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  width="100%"
                  height="36px"
                  color="#2E2E2E"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  paddingLeft="10px"
                  paddingRight="10px"
                  value={InsPayerFax}
                  onChange={inspayFaxChange}
                ></Input>
              </Div>
              <Div margin="24px 0px 0px 0px">
                <FieldHead position="relative">
                  Insurance card on file
                </FieldHead>
                <FieldHead display="block" width="100%">
                  <Div
                    width="100%"
                    border="1px dashed #2C7BE5"
                    margin="8px 0px 0px 0px"
                    borderRadius="4px"
                    padding="52px 0px"
                    cursor={
                      SingPatDetails.enrollment_status !== "4"
                        ? "pointer"
                        : "not-allowed"
                    }
                    // style={
                    //   file1Err
                    //     ? { border: "1px dashed red", borderRadius: "5px" }
                    //     : {}
                    // }
                  >
                    <FileInput
                      type="file"
                      accept=".png, .jpg, .JPEG"
                      fontSize={
                        SingPatDetails.enrollment_status === "4"
                          ? "12px"
                          : "133px"
                      }
                      disabled={SingPatDetails.enrollment_status === "4"}
                      className={
                        SingPatDetails.enrollment_status === "4"
                          ? "not-allowed-cursor"
                          : ""
                      }
                      onChange={onChange}
                    ></FileInput>
                    <Div display="grid">
                      <TitleHead
                        textAlign="center"
                        // marginLeft="103px"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                      >
                        Front of Insurance Card
                      </TitleHead>
                      {preview1 && (
                        <Image
                          src={preview1}
                          marginLeft="auto"
                          marginTop="20px"
                          marginRight="auto"
                          maxWidth="300px"
                          maxHeight="130px"
                          alt="pic"
                        ></Image>
                      )}
                      <FormLabel
                        fontFamily="'Open Sans', sans-serif"
                        textAlign="center"
                      >
                        {labeltext1}
                      </FormLabel>
                      <FormLabel
                        fontSize="10px"
                        marginLeft="65px"
                        marginTop="16px"
                        fontFamily="'Open Sans', sans-serif"
                        fontWeight="400"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed !important"
                        }
                      >
                        Drag & Drop a photo of your insurance card here or{" "}
                        <Span color="blue">Browse</Span> for a file to upload
                      </FormLabel>
                    </Div>
                  </Div>
                </FieldHead>
                {/* {file1Err && (
                  <ErrorMessage>
                    Please Select Front Of Insurance Card
                  </ErrorMessage>
                )} */}
                <FieldHead display="block" width="100%">
                  <Div
                    width="100%"
                    border="1px dashed #2C7BE5"
                    margin="16px 0px 0px 0px"
                    borderRadius="4px"
                    padding="52px 0px"
                    cursor={
                      SingPatDetails.enrollment_status !== "4"
                        ? "pointer"
                        : "not-allowed !important"
                    }
                    // style={
                    //   file2Err
                    //     ? { border: "1px dashed red", borderRadius: "5px" }
                    //     : {}
                    // }
                  >
                    <Div display="grid">
                      <FileInput
                        type="file"
                        accept=".png, .jpg, .JPEG"
                        disabled={SingPatDetails.enrollment_status === "4"}
                        className={
                          SingPatDetails.enrollment_status === "4"
                            ? "not-allowed-cursor"
                            : ""
                        }
                        fontSize={
                          SingPatDetails.enrollment_status === "4"
                            ? "12px"
                            : "133px"
                        }
                        onChange={onChange2}
                      ></FileInput>
                      <TitleHead
                        textAlign="center"
                        // marginLeft="103px"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed !important"
                        }
                      >
                        Back of Insurance Card
                      </TitleHead>
                      {preview2 && (
                        <Image
                          src={preview2}
                          marginLeft="auto"
                          marginTop="20px"
                          marginRight="auto"
                          maxWidth="300px"
                          maxHeight="130px"
                          alt="pic"
                        ></Image>
                      )}
                      <FormLabel
                        fontFamily="'Open Sans', sans-serif"
                        textAlign="center"
                      >
                        {labeltext2}
                      </FormLabel>
                      <FormLabel
                        fontFamily="'Open Sans', sans-serif"
                        fontSize="10px"
                        marginLeft="65px"
                        marginTop="16px"
                        fontWeight="400"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                      >
                        Drag & Drop a photo of your insurance card here or{" "}
                        <Span color="blue">Browse</Span> for a file to upload
                      </FormLabel>
                    </Div>
                  </Div>
                </FieldHead>
                {/* {file2Err && (
                  <ErrorMessage>
                    Please Select Back Of Insurance Card
                  </ErrorMessage>
                )} */}
              </Div>
            </Div>
          </ModalBody>

          <ModalFooter
            justifyContent="space-between"
            padding="24px 21px 20px 23px"
            borderTop="none"
          >
            <Div>
              <Button
                borderRadius="4px"
                border="1px solid #2C7BE5"
                width="100px"
                height="32px"
                background="#FFFFFF"
                color="#2C7BE5"
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                marginRight="24px"
                marginLeft="0px"
                className="close_active"
                onClick={() => handleClose()}
                cursor={
                  SingPatDetails.enrollment_status !== "4"
                    ? "pointer"
                    : "not-allowed"
                }
              >
                <Span
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Cancel
                </Span>
              </Button>
              <Button
                borderRadius="4px"
                border="1px solid #2C7BE5"
                width="100px"
                height="32px"
                background="#2C7BE5"
                color="#FFFFFF"
                hoverBackColor="#2C7BE5"
                marginRight="16px"
                cursor="pointer"
                marginLeft="0px"
                className="save_active"
                onClick={() => HandleSave()}
              >
                <Span
                  fontSize="14px"
                  fontWeight="400"
                  fontFamily="'Open Sans', sans-serif"
                  // disabled={btnDisabled}
                >
                  Save
                </Span>
              </Button>
            </Div>
          </ModalFooter>
        </ModalContainer>
      </ModalInfo>
    </Div>
  );
};
export default AddInsuranceTab;
