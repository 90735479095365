import React from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import SecuritySheet from "./SecuritySheet";
import AccessDenied from "../../AccessDenied";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const Security = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#f1f5f8">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px">
        <Div padding="16px 24px 24px 22px">
          {permissions.includes("security_access") ? (
            <SecuritySheet />
          ) : (
            <AccessDenied />
          )}
        </Div>
      </Div>
    </Container>
  );
};
export default Security;
