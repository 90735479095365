import styled from "styled-components";
import { BsFillImageFill, BsFillFileEarmarkFill } from "react-icons/bs";
import { FaMicrophone } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";
import { AiOutlinePaperClip } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { VscMention } from "react-icons/vsc";
import { Button } from "../../StyledComponents";
export const DropdownContainer = styled.div`
  position: absolute;
  top: 60px; /* Adjust the top position as per your requirements */
  right: 20px; /* Adjust the right position as per your requirements */
  width: 371px;
  background: #fff;
  border: 1px solid rgba(46, 46, 46, 0.25);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border-radius: 8px;
  z-index: 100;
`;

export const Header = styled.div`
  height: 65px; /* Adjust the height as per your requirements */
  border-bottom: 1px solid rgba(46, 46, 46, 0.25);
  background: #fff; /* Adjust the background color as per your requirements */
`;

export const Title = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-left: 20px;
`;

export const SearchContainer = styled.div`
  margin-top: ${(p) => p.marginTop && p.marginTop};
  height: ${(p) => p.height && p.height};
  background: #f1f5f8;
`;

export const Div = styled.div`
  display: ${(p) => p.dis && p.dis};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  min-height: ${(p) => p.minheight && p.minheight};
  max-height: ${(p) => p.maxheight && p.maxheight};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.radius && p.radius};
  outline: none;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
`;

export const Row = styled(Div)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  cursor: ${(p) => p.cursor && p.cursor};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  top: ${(p) => p.top && p.top};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  margin: ${(p) => p.margin && p.margin};
`;

export const Search = styled.div`
  height: ${(p) => (p.height ? p.height : "43px")};
  background: ${(p) => (p.background ? p.background : "#f4f6fc")};
  padding: ${(p) => (p.padding ? p.padding : "6px 0px 13px 21px")};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  width: ${(p) => p.width && p.width};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.br && p.br};
`;

export const DataDiv = styled.div`
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  position: ${(p) => p.position && p.position};
  border-bottom: ${(p) => p.bb && p.bb};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.ml && p.ml};
  margin-top: ${(p) => p.mt && p.mt};
  margin-right: ${(p) => p.mr && p.mr};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  overflow: ${(p) => p.overflow && p.overflow};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.br && p.br};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  text-align: ${(p) => p.textAlign && p.textAlign};
  min-width: ${(p) => p.minWidth && p.minWidth};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  aspect-ratio: ${(p) => p.aspectRatio && p.aspectRatio};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const Data = styled(DataDiv)`
  width: ${(p) => p.width && p.width};
  // max-height: 418px;
  border: ${({ drop }) => (drop ? "dotted 2px #8DA3F0" : "")};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  ${({ flex }) =>
    flex &&
    ` display : flex;
  align-items: center;
  justify-content: center;`};

  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
`;

export const AvatarLetter = styled.span`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  padding-right: ${(p) => p.pr && p.pr};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const Text = styled.p`
  margin-bottom: 0px !important;
  align-items: center;
  letter-spacing: 0.0025em;
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  padding-top: ${(p) => p.pt && p.pt};
  margin-left: ${(p) => p.ml && p.ml};
  width: ${(p) => p.width && p.width};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  height: ${(p) => p.height && p.height};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;

export const TnumbComponent = ({ message, variant }) => {
  return (
    <Row dis="flex">
      {variant === "image" && (
        <>
          <ThumbImage />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
      {variant === "doc" && (
        <>
          <ThumbDoc />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}

      {variant === "audio" && (
        <>
          <ThumbAudio />
          <Text
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="4px 0px 0px 12px"
          >
            {message}
          </Text>
        </>
      )}
    </Row>
  );
};

export const ThumbImage = styled(BsFillImageFill)`
  margin-top: 2.5px;
`;
export const ThumbDoc = styled(BsFillFileEarmarkFill)`
  margin-top: 2.5px;
`;

export const ThumbAudio = styled(FaMicrophone)`
  margin-top: 2.5px;
  font-size: 18px;
`;

export const Close = styled(BsXLg)`
  margin-left: auto;
  cursor: pointer;
  color: #407bff;
  height: 12px;
`;
export const DowloadBtn = styled(BsDownload)`
  cursor: pointer;
  color: #407bff;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
  height: 12px;
  width: 12px;
`;
export const SearchInputText = styled.input.attrs({
  placeholderTextColor: "red",
})`
  background: none;
  border: none;
  box-shadow: none;
  width: ${(p) => p.width && p.width};
  font-size: 14px;
  padding-left: 5px;
  &::placeholder {
    color: rgba(46, 46, 46, 0.5);
    font-family: "Open Sans", sans-serif;
  }
  outline-offset: -26px;
  display: ${(p) => p.display && p.display};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};

  &:focus {
    outline: none;
  }
`;

export const Attach = styled(AiOutlinePaperClip)`
  margin-right: 5px;
  width: 28px;
  color: ${(p) => (p.color ? p.color : "rgba(46, 46, 46, 0.5);")};
  cursor: pointer;
`;

export const Dropdown = styled(Div)`
  display: ${(p) => (p.selected && p.active !== "" ? "flex" : "none")};
  height: max-content;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};

  border-radius: 4px;
`;

export const DropdownItems = styled(Div)`
  &:hover {
    background-color: #dbdbdb;
  }
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  border-radius: 4px;
  cursor: ${(p) => p.cursor && p.cursor};
  z-index: 10;
`;
export const Circle = styled(Button)`
  position: relative;
  height: 24px;
  width: 24px;
  padding: 8px;
  background: #2c7be5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  & svg {
    position: absolute;
    height: 16px !important;
    width: 16px !important;
  }
`;

export const FiSendIcon = styled(FiSend)`
  color: #ffffff;
  height: 16px;
  width: 16px;
`;

export const FiSearchIcon = styled(FiSearch)`
  color: rgba(46, 46, 46, 0.75);
`;
export const AtSymbol = styled(VscMention)`
  margin-right: 5px;
  width: 28px;
  height: 28px;
  color: rgba(46, 46, 46, 0.5);
  cursor: pointer;
`;
