import { useState, memo, useEffect } from "react";
import React from "react";
import {
  Div,
  PageSubTitle,
  Form,
  Input,
  Text,
  ButtonGroup,
  Span,
  FormLabel,
  RedStar,
  Image,
  SpinnerWrapper,
  Spinner,
} from "../../StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSecurityData,
  GetSecurityUpdate,
  setEmptySecurityResponse,
} from "../../../StateManagement/Reducers/SettingState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import Logo from "../../../Assets/images/Logo.png";

const Security = () => {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const dispatch = useDispatch();
  const SecurityInfo = useSelector((state) => state.Setting.SecurityData);
  const passWordResponse = useSelector(
    (state) => state.Setting.SecurityUpdateResponse
  );

  const [Data, setData] = useState({
    minLength: "",
    gracePeriod: "5",

    passExpirationDays: "",
    idle: "",
  });
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState({
    expiry: false,
    grace: false,
    passLength: false,
    idle: false,
  });
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(true);

  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const Onsubmit = (e) => {
    e.preventDefault();
    let error = 0;
    for (let key in errors) {
      if (errors[key] === true) {
        error = 1;
        return true;
      }
    }
    let errors1 = {
      expiry: false,
      grace: false,
      passLength: false,
      idle: false,
    };

    if (
      Data.passExpirationDays !== "" &&
      parseInt(Data.passExpirationDays) >= 30
    ) {
      errors1.expiry = false;
    } else {
      errors1.expiry = true;
      error = 1;
    }

    if (Data.gracePeriod !== "" && parseInt(Data.gracePeriod) >= 3) {
      errors1.grace = false;
    } else {
      errors1.grace = true;
      error = 1;
    }

    if (Data.minLength !== "" && parseInt(Data.minLength) >= 8) {
      errors1.passLength = false;
    } else {
      errors1.passLength = true;
      error = 1;
    }
    if (
      Data.idle !== "" &&
      parseInt(Data.idle) >= 1 &&
      parseInt(Data.idle) <= 2
    ) {
      errors1.idle = false;
    } else {
      errors1.idle = true;
      error = 1;
    }

    if (error === 0) {
      setDis(true);
      const article = [
        {
          glname: "password_expiration_days",
          glindex: "0",
          glvalue: Data.passExpirationDays,
        },
        {
          glname: "password_grace_time",
          glindex: "0",
          glvalue: Data.gracePeriod,
        },
        { glname: "timeout", glindex: "0", glvalue: Data.idle },

        {
          glname: "gbl_minimum_password_length",
          glindex: "0",
          glvalue: Data.minLength,
        },
      ];
      console.log(article, "article");
      dispatch(GetSecurityUpdate(article));
      setTimeout(() => {
        setDis(false);
      }, 1500);
    } else {
      setErrors(errors1);
    }
  };

  const handleDataChange = (value, key) => {
    let a = /[0-9 ]/i.test(value);
    if ((!isNaN(value) && a) || value === "") {
      setData({ ...Data, [key]: value });
      switch (key) {
        case "passExpirationDays":
          if (value >= 30 && value <= 180) {
            setErrors({ ...errors, expiry: false, expiryMax: false });
          } else if (value < 30) {
            setErrors({ ...errors, expiry: true, expiryMax: false });
          } else if (value > 180) {
            setErrors({ ...errors, expiry: false, expiryMax: true });
          }
          break;

        case "gracePeriod":
          if (value >= 3) {
            setErrors({ ...errors, grace: false });
          } else {
            setErrors({ ...errors, grace: true });
          }
          break;

        case "minLength":
          if (value >= 8) {
            setErrors({ ...errors, passLength: false });
          } else {
            setErrors({ ...errors, passLength: true });
          }
          break;

        case "idle":
          if (value >= 1 && value <= 2) {
            setErrors({ ...errors, idle: false });
          } else {
            setErrors({ ...errors, idle: true });
          }
          break;

        default:
          break;
      }
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  useEffect(() => {
    dispatch(GetSecurityData());
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (SecurityInfo.length > 0) {
      setData({
        minLength: SecurityInfo[0]["gl_value"],
        gracePeriod: SecurityInfo[2]["gl_value"],

        passExpirationDays: SecurityInfo[1]["gl_value"],
        idle: parseInt(SecurityInfo[4]["gl_value"]),
      });
      localStorage.setItem("idle", SecurityInfo[4]["gl_value"]);
      localStorage.setItem(
        "MinimumPasswordLength",
        SecurityInfo[0]["gl_value"]
      );
      localStorage.setItem("GracePeriod", SecurityInfo[2]["gl_value"]);

      localStorage.setItem("PasswordExpiresIn", SecurityInfo[1]["gl_value"]);
    }
  }, [SecurityInfo]);

  useEffect(() => {
    if (passWordResponse !== "") {
      setModalMsg(passWordResponse);
      setModalAlerShow(true);
      if (passWordResponse === "Updated SuccessFully") {
        setStatus("success");
      } else {
        setStatus("fail");
      }
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
      }, 1500);

      dispatch(setEmptySecurityResponse());
    }
  }, [passWordResponse]);

  return loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <PageSubTitle
        padding="0px 0px 16px 0px"
        fontFamily="'Open Sans',sans-serif"
        fontWeight="600"
        fontSize="18px"
        fontStyle="normal"
        lineHight="normal"
        color="#2E2E2E"
      >
        Security
      </PageSubTitle>
      <Form onSubmit={Onsubmit}>
        <Div
          backgroundColor="#FFFFFF"
          // margin="0px 26px 0px 26px"
          height="calc(100vh - 146px )"
          overflow="hidden"
          borderRadius="8px"
          boxShadow=" 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <PageSubTitle
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="16px"
            fontStyle="normal"
            lineHight="normal"
            color="#2E2E2E"
            padding="20px 20px 7px 20px"
          >
            Password Settings
          </PageSubTitle>
          <Div
            display="flex"
            padding={
              errors.expiry === true
                ? "20px 20px 3px 20px"
                : "20px 20px 11px 20px "
            }
          >
            <Div display="flex" alignItems="center">
              <Text
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                padding="0px 8px 0px 0px"
              >
                Passwords expire and must be changed every
              </Text>

              <Input
                textAlign="center"
                width="50px"
                height="26px"
                border={
                  errors.expiry === true
                    ? "1px solid red"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="3px"
                placeholder=""
                fontWeight="400"
                fontSize="14px"
                padding=" 3px 12px 2px 13px"
                value={Data.passExpirationDays}
                onChange={(e) =>
                  handleDataChange(e.target.value, "passExpirationDays")
                }
              />

              <Text
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                fontStyle="normal"
                lineHight="normal"
                padding="0px 0px 0px 8px"
              >
                days.
              </Text>
            </Div>

            <Div position="relative">
              <RedStar left="37.2%">*</RedStar>
            </Div>
          </Div>
          {errors.expiry && (
            <FormLabel
              display="flex"
              marginBottom="11px"
              textAlign="left"
              fontSize="12px"
              color="red"
              fontWeight="400"
              fontFamily="'Open Sans', sans-serif"
              marginLeft="20px"
            >
              Password Expiration must be set to a minimum of 30 days
            </FormLabel>
          )}
          {errors.expiryMax && (
            <FormLabel
              display="flex"
              marginBottom="11px"
              textAlign="left"
              fontSize="12px"
              color="red"
              fontWeight="400"
              fontFamily="'Open Sans', sans-serif"
              marginLeft="20px"
            >
              Password Expiration must not exceed 180 days
            </FormLabel>
          )}
          <Div
            display="flex"
            padding={
              errors.grace === true
                ? "0px 20px 3px 20px"
                : "0px 20px 20px 20px "
            }
          >
            <Div display="flex" alignItems="center">
              <Text
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                padding="0px 8px 0px 0px"
              >
                Password expiration Grace period
              </Text>

              <Input
                textAlign="center"
                width="50px"
                height="26px"
                border={
                  errors.grace === true
                    ? "1px solid red"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="3px"
                placeholder=""
                fontWeight="400"
                fontSize="14px"
                padding=" 3px 12px 2px 13px"
                value={Data.gracePeriod}
                disabled={true}
                onChange={(e) =>
                  handleDataChange(e.target.value, "gracePeriod")
                }
              />

              <Text
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                padding="0px 0px 0px 8px"
              >
                days.
              </Text>
            </Div>
            <Div position="relative">
              <RedStar left="30.2%">*</RedStar>
            </Div>
          </Div>
          {errors.grace && (
            <FormLabel
              display="flex"
              marginBottom="20px"
              textAlign="left"
              fontSize="12px"
              color="red"
              fontWeight="400"
              fontFamily="'Open Sans', sans-serif"
              marginLeft="20px"
            >
              Password expiration grace period must be a minimum of 3 days
            </FormLabel>
          )}
          <Div borderBottom="1px solid rgba(46, 46, 46, 0.25)"></Div>

          <Div>
            <PageSubTitle
              fontFamily="'Open Sans',sans-serif"
              fontWeight="600"
              fontSize="16px"
              color="#2E2E2E"
              padding="16px 20px 16px 20px"
            >
              Login Settings
            </PageSubTitle>
          </Div>
          <Div
            display="flex"
            padding={
              errors.passLength === true
                ? "11px 20px 3px 20px"
                : "11px 20px 11px 20px "
            }
          >
            <Div display="flex" alignItems="center">
              <Text
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                padding="0px 8px 0px 0px"
              >
                Passwords must be at least
              </Text>
              <Input
                textAlign="center"
                width="50px"
                height="26px"
                fontWeight="400"
                fontSize="14px"
                border={
                  errors.passLength === true
                    ? "1px solid red"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="3px"
                padding=" 3px 12px 2px 13px"
                value={Data.minLength}
                onChange={(e) => handleDataChange(e.target.value, "minLength")}
              />
              <Text
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                padding="0px 0px 0px 8px"
              >
                characters long.
              </Text>
            </Div>
            <Div position="relative">
              <RedStar left="46.2%">*</RedStar>
            </Div>
          </Div>
          {errors.passLength && (
            <FormLabel
              display="flex"
              marginBottom="11px"
              textAlign="left"
              fontSize="12px"
              color="red"
              fontWeight="400"
              fontFamily="'Open Sans', sans-serif"
              marginLeft="20px"
            >
              Password Must be a minimum of 8 characters in length
            </FormLabel>
          )}

          <Div
            display="flex"
            padding={
              errors.idle === true
                ? "0px 20px 3px 20px "
                : "0px 20px 11px 20px "
            }
          >
            <Div display="flex" alignItems="center">
              <Text
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                padding="0px 8px 0px 0px"
              >
                Session time before user is logged out
              </Text>

              <Input
                textAlign="center"
                width="50px"
                height="26px"
                placeholder=""
                border={
                  errors.idle === true
                    ? "1px solid red"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="3px"
                padding=" 3px 12px 2px 13px"
                value={Data.idle}
                onChange={(e) => handleDataChange(e.target.value, "idle")}
              />

              <Text
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400"
                fontSize="14px"
                color="#2E2E2E"
                fontStyle="normal"
                lineHight="normal"
                padding="0px 0px 0px 8px"
              >
                hours.
              </Text>
            </Div>
            <Div position="relative">
              <RedStar left="45.8%">*</RedStar>
            </Div>
          </Div>
          {errors.idle && (
            <FormLabel
              display="flex"
              marginBottom="11px"
              textAlign="left"
              fontSize="12px"
              color="red"
              fontWeight="400"
              fontFamily="'Open Sans', sans-serif"
              marginLeft="20px"
            >
              Idle time must be between 1 and 2 hours
            </FormLabel>
          )}
          <>
            <ButtonGroup
              type="submit"
              borderRadius="4px"
              border="1px solid #2C7BE5"
              padding="9px 21px 8px 22px"
              background="#2C7BE5"
              color="#FFFFFF"
              fontStyle="normal"
              lineHight="normal"
              cursor="pointer"
              margin="38px 0px 21px 25px"
              disabled={dis}
              isDisabled={dis}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              <Span
                fontSize="14px"
                fontWeight="600"
                fontFamily="'Open Sans', sans-serif"
              >
                Save Information
              </Span>
            </ButtonGroup>
          </>
        </Div>
      </Form>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(Security);
