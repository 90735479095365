import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  SpanTag,
  Tablerow,
  Image,
  Spinner,
  SpinnerWrapper,
} from "../../StyledComponents/index";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { PaginDiv } from "./styles";
import Pagination from "../../StyledComponents/Pagination/Pagination";
import AddProvider from "./AddProvider";
import { RiEdit2Line } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";
import {
  GetProviderList,
  GetSettingSecurity,
  GetSingProv,
  GetUserDelete,
  GetUserList,
  setSingleProviderResponse,
  GetRoles,
} from "../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import Logo from "../../../Assets/images/Logo.png";
function UserSheet() {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const UserDetails = useSelector((state) => state.Setting.userReport);
  const SingleProvider = useSelector((state) => state.Setting.SingleProvider);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [provId, setProvId] = useState(0);
  const [mainId, setMainId] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");

  const modalRef = useRef(null);
  const [currentPosts, setCurrentPost] = useState([]);
  const [howManyPages, setHowManyPages] = useState(0);
  const dispatch = useDispatch();

  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const EmptyRowStyle = {
    height: "56px",
  };
  const emptyRows = Math.max(0, 10 - currentPosts.length);

  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      fname: "",
      lname: "",
      email: "",
      username: "",
      phonecell: "",
      phone: "",
      id: "",
      main_pro: "",
    })
  );

  const rowsToShow = [...currentPosts, ...emptyRowPlaceholders];
  let userData =
    "page=" +
    currentPage +
    "&pageSize=10&auth=1&authorized=" +
    1 +
    "&account=" +
    3 +
    "&trial=" +
    true +
    "&main_pro=" +
    "" +
    "&owner_id=" +
    null +
    "&pat_share=" +
    true +
    "&userID=" +
    userID;
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//

  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const openModal = () => {
    setOpen(true);
    setProvId(0);
    setMainId(0);
    dispatch(setSingleProviderResponse());
  };

  const openPopup = (id, pro_id) => {
    const prov = "userID=" + userID;
    dispatch(GetProviderList(prov));
    setTimeout(() => {
      setOpen(true);
    }, 500);
    setProvId(id);
    setMainId(pro_id);
    dispatch(GetSingProv(id));
  };

  const handleMouseMovement = (event) => {
    if (modalRef.current !== null) {
      if (isOpen && !modalRef.current.contains(event.target)) {
        setTimeout(() => {
          document.body.style.overflow = "";
        }, 200);
      } else if (isOpen) {
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 200);
      }
    }
    event.preventDefault();
  };

  const deleteUserData = (id) => {
    if (id === parseInt(userID)) {
      setShowAlert(true);
      setModalMsg("Unauthorized Action");
      setStatus("retry");
      setTimeout(() => {
        setShowAlert(false);
        setStatus("");
        setModalMsg("");
      }, 1500);
      return;
    }
    const confirm = window.confirm("Are you sure you want to delete?");
    if (confirm === true) {
      Promise.resolve()
        .then(() => {
          dispatch(GetUserDelete({ id: id, datas: userData }));
        })
        .then(() => {
          setShowAlert(true);
          setModalMsg("Deleted Successfully");
          setStatus("success");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
        });
    }
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  // --------------------------------------------------- useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMovement, {
      passive: false,
    });
    return () => {
      document.removeEventListener("mousemove", handleMouseMovement);
    };
  }, [isOpen]);

  useEffect(() => {
    dispatch(GetUserList(userData));
    let articles =
      "limit=40&offset=0&trial=" +
      true +
      "&account=" +
      3 +
      "&uid=" +
      userID +
      "&owner_id=" +
      null;
    dispatch(GetRoles(articles));
  }, [currentPage]);

  useEffect(() => {
    if (UserDetails) {
      setCurrentPost(UserDetails.data ?? []);
      setHowManyPages(UserDetails.totalPages ?? 0);
    }
  }, [UserDetails]);

  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Div borderRadius="8px" margin="20px 22px 24px 20px" background="#FFFFFF">
        <Div
          display="flex"
          paddingTop="26px"
          paddingBottom="16px"
          paddingLeft="20px"
          paddingRight="20px"
          border="1px solid #E2E8ED"
          borderBottom="0px"
          position="relative"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          <Div display="flex" position="relative" width="70%">
            Providers List
          </Div>

          <Button
            cursor="pointer"
            marginLeft="auto"
            padding="8px"
            border="1px solid #2C7BE5"
            borderRadius="4px"
            color="#FFFFFF"
            background="#2C7BE5"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14px"
            paddingLeft="14px"
            paddingRight="14px"
            onClick={openModal}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Add User
          </Button>
          {isOpen && (
            <AddProvider
              setOpen={setOpen}
              id={provId}
              show={isOpen}
              modalRef={modalRef}
              uid={userID}
              ProvData={SingleProvider[0]}
              mainId={mainId}
              userData={userData}
              staff={false}
            />
          )}
        </Div>

        <Table borderBottomLeftRadius="8px" borderBottomRightRadius="8px">
          <TableHead background="#F1F5F8">
            <Tablerow
              color="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="400"
              fontSize="14px"
              textAlign="left"
            >
              <TableHeadCell
                paddingLeft="30px"
                paddingRight="0px"
                width="20% !important"
              >
                Name
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="10px"
                paddingRight="30px"
                width="20% !important"
              >
                Email
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="10px"
                paddingRight="0px"
                // width="100px !important"
                width="20% !important"
              >
                Login
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="10px"
                paddingRight="0px"
                // width="110px !important"
                width="15% !important"
              >
                Cell
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="10px"
                paddingRight="0px"
                // width="120px !important"
                width="15% !important"
              >
                Home
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="10px"
                paddingRight="0px"
                // width="200px !important"
                width="10% !important"
              >
                Action
              </TableHeadCell>
            </Tablerow>
          </TableHead>
          <TableBody>
            {currentPosts.length !== 0 ? (
              rowsToShow.map((details, index) => (
                <Tablerow
                  borderBottom="1px solid #E2E8ED"
                  fontFamily="Open Sans, sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  key={index}
                  textAlign="left"
                >
                  <TableBodyCell
                    paddingLeft="30px"
                    paddingRight="0px"
                    width="20% !important"
                    style={{ wordBreak: "break-all" }}
                  >
                    {details.fname + " " + details.lname}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="10px"
                    paddingRight="0px"
                    width="20% !important"
                    style={{ wordBreak: "break-all" }}
                  >
                    {details.email}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="10px"
                    paddingRight="0px"
                    width="20% !important"
                    style={{ wordBreak: "break-all" }}
                  >
                    {details.username}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="10px"
                    paddingRight="0px"
                    width="14% !important"
                    style={{ wordBreak: "break-all" }}
                  >
                    {details.phonecell}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="10px"
                    paddingRight="0px"
                    width="14% !important"
                    style={{ wordBreak: "break-all" }}
                  >
                    {details.phone}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingRight="10px"
                    paddingLeft="0px"
                    width="12% !important"
                    style={details.fname ? null : EmptyRowStyle}
                  >
                    {details.fname ? (
                      <>
                        <SpanTag
                          height="18px"
                          width="18px"
                          color=" #2C7BE5"
                          cursor="pointer"
                          onClick={() =>
                            openPopup(details.id, details.main_pro)
                          }
                        >
                          <RiEdit2Line />
                        </SpanTag>
                        <SpanTag
                          height="18px"
                          width="16px"
                          color=" #B00020"
                          marginLeft="24px"
                          cursor="pointer"
                          onClick={() => deleteUserData(details.id)}
                        >
                          <FiTrash2
                            style={{ fill: "#ffffff", stroke: "#f24822" }}
                          />
                        </SpanTag>
                      </>
                    ) : (
                      ""
                    )}
                  </TableBodyCell>
                </Tablerow>
              ))
            ) : dataLoaded && currentPosts.length === 0 ? (
              <>
                <Tablerow>
                  <TableBodyCell textAlign="center" colSpan="8">
                    <Div>
                      <Image
                        height="133px"
                        width="133px"
                        src={Emp_data_pic}
                      ></Image>
                    </Div>
                  </TableBodyCell>
                </Tablerow>
                <Tablerow>
                  <TableBodyCell
                    textAlign="center"
                    colSpan="8"
                    paddingBottom="40px"
                  >
                    No patients has been added to show in the patient list
                  </TableBodyCell>
                </Tablerow>
              </>
            ) : (
              <SpinnerWrapper>
                <Spinner></Spinner>{" "}
                <Image
                  width="40px"
                  height="40px"
                  position="absolute"
                  src={Logo}
                />
              </SpinnerWrapper>
            )}
          </TableBody>
        </Table>
        <PaginDiv
          hidePagination={currentPosts.length === 0 || howManyPages === 1}
        >
          {currentPosts.length === 0 || howManyPages === 1 ? (
            ""
          ) : (
            <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
          )}
        </PaginDiv>
      </Div>
      {showAlert && (
        <AlertModal show={showAlert} msg={modalMsg} status={status} />
      )}
    </>
  );
}

export default UserSheet;
