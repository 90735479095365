import React, { useState, useEffect } from "react";
import { PaginationContainer, PaginationAnch, PagePrevNext } from "./Styles.js";
import {
  AiOutlineLeft,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineRight,
} from "react-icons/ai";
const Pagination = ({ pages, setCurrentPage, view, current }) => {
  const [currentButton, setCurrentButton] = useState(1);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  const numberOfPages = [];
  for (let i = 1; i <= pages; i++) {
    numberOfPages.push(i);
  }

  useEffect(() => {
    setCurrentButton(1);
  }, [view]);

  useEffect(() => {
    let tempNumberOfPages = [...arrOfCurrButtons];

    let dotsInitial = "...";

    let dotsRight = " ...";

    if (numberOfPages.length < 6) {
      tempNumberOfPages = numberOfPages;
    } else if (currentButton >= 1 && currentButton <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, 5, dotsInitial, numberOfPages.length];
    } else if (currentButton === 5) {
      const sliced = numberOfPages.slice(1, 6);
      tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    } else if (currentButton > 5 && currentButton < numberOfPages.length - 2) {
      const sliced1 = numberOfPages.slice(currentButton - 4, currentButton);
      const sliced2 = numberOfPages.slice(currentButton, currentButton + 1);
      tempNumberOfPages = [
        ...sliced1,
        ...sliced2,

        dotsRight,
        numberOfPages.length,
      ];
    } else if (currentButton > numberOfPages.length - 3) {
      const sliced = numberOfPages.slice(numberOfPages.length - 5);
      tempNumberOfPages = [...sliced];
    } else if (currentButton === dotsInitial) {
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentButton === dotsRight) {
      setCurrentButton(arrOfCurrButtons[3] + 2);
    }

    setArrOfCurrButtons(tempNumberOfPages);
    setCurrentPage(currentButton);
  }, [currentButton, view, pages]);

  useEffect(() => {
    if (current && current !== currentButton) {
      setCurrentButton(current);
    }
  }, [current]);

  //-----------------------------UseEffect End------------------------------------------------------------------ //

  return (
    <PaginationContainer>
      <PagePrevNext
        // active={`${currentButton === 1 ? "disabled" : ""}`}
        onClick={() => setCurrentButton(1)}
      >
        <AiOutlineDoubleLeft />
      </PagePrevNext>
      <PagePrevNext
        // active={`${currentButton === 1 ? "disabled" : ""}`}
        onClick={() =>
          setCurrentButton((prev) => (prev <= 1 ? prev : prev - 1))
        }
      >
        <AiOutlineLeft />
      </PagePrevNext>

      {arrOfCurrButtons.map((item, index) => {
        return (
          <PaginationAnch
            key={index}
            active={`${currentButton === item ? "active" : "disabled"}`}
            onClick={() => setCurrentButton(item)}
            borderNone={item === "..." ? "none" : ""}
          >
            {item}
          </PaginationAnch>
        );
      })}

      <PagePrevNext
        // active={`${currentButton === numberOfPages.length ? "disabled" : ""}`}
        onClick={() =>
          setCurrentButton((prev) =>
            prev >= numberOfPages.length ? prev : prev + 1
          )
        }
      >
        <AiOutlineRight />
      </PagePrevNext>
      <PagePrevNext
        // active={`${currentButton === numberOfPages.length ? "disabled" : ""}`}
        onClick={() => setCurrentButton(pages)}
      >
        <AiOutlineDoubleRight />
      </PagePrevNext>
    </PaginationContainer>
  );
};

export default Pagination;
