import { useEffect, useState } from "react";
import moment from "moment";
import axiosInstance from "../Config";
import { Encrypt_Value, Decrypt_Value } from "../MiddleWare/EncryptDecrypt";
import axiosInst from "../Config";

const useLogout = (startTime) => {
  const [timer, setTimer] = useState(startTime);
  const CurrTimes = moment().add(3, "m").format("YYYY-MM-DD HH:mm A");
  const token_exp_times = localStorage.getItem("login_time");
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  //----------------------getting refresh token before session ends starts here------------------------------//

  const refreshToken = () => {
    var refresh_token = localStorage.getItem("refresh_token");

    var details = {
      grant_type: "refresh_token",
      refresh_token: refresh_token,
    };
    const data = {
      userid: user_id,
    };
    let Encrypt_details = Encrypt_Value(details, "vozorpm");
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    axiosInstance({
      method: "post",
      url: "/vozorpm/refresh",
      data: Encrypt_details,
    })
      .then((response) => {
        var Decrpt_response = Decrypt_Value(response.data, "vozorpm");
        localStorage.setItem("token", Decrpt_response.token);
        localStorage.setItem("refresh_token", Decrpt_response.refresh_token);

        // axiosInstance.interceptors.request.use(function (config) {
        //   const token = localStorage.token;
        //   config.headers.Authorization = token;

        //   return config;
        // });
        // axiosInstance.defaults.headers.common["Authorization"] =
        //   Decrpt_response.token;
        localStorage.setItem(
          "login_time",
          moment()
            .add(Decrpt_response.expires_in, "seconds")
            .format("YYYY-MM-DD HH:mm A")
        );
      })
      .catch((error) => {
        console.log(error);
        axiosInstance
          .post(`/vozorpm/updatelogout`, data)
          .then((response) => {
            localStorage.clear();
            window.location.href = "/";
          })
          .catch((e) => {
            console.log(e);
            localStorage.clear();
            window.location.href = "/";
          });
      });
  };

  useEffect(() => {
    if (token_exp_times === CurrTimes) {
      refreshToken();
    }
    //-----------------------------getting refresh token before session ends ends here---------------------------//

    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 60000);

    const resetTimeout = () => {
      setTimer(startTime);
    };

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });

  return timer;
};

export default useLogout;
