import React from "react";
import { Container, Div } from "../StyledComponents";
import LeftNav from "../LeftNav";
import TopNav from "../TopNav";
import TreatmentPlanSheet from "./TreatmentPlanSheet";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import AccessDenied from "../AccessDenied";

const TreatmentPlan = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div
        marginLeft="56px"
        className="responsive-right"
        backgroundColor="#F1F5F8"
      >
        <Div padding="20px 20px 24px 20px">
          {permissions.includes("treat_plan") ? (
            <TreatmentPlanSheet />
          ) : (
            <AccessDenied />
          )}
        </Div>
      </Div>
    </Container>
  );
};
export default TreatmentPlan;
