import React, { useState } from "react";
import styled from "styled-components";

const AlertContainer = styled.div`
  position: relative;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 15px;
  height: 45px;
  font-size: 14px;

  ${(props) => {
    if (props.variant === "success") {
      return `
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
      `;
    } else if (props.variant === "error") {
      return `
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
      `;
    }
    // Add more styles for other variants (e.g., success, warning, danger) here
  }}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
`;

const AlertButton = ({ variant, children }) => {
  const [showAlert, setShowAlert] = useState(true);

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <AlertContainer variant={variant}>
          {children}
          <CloseButton onClick={handleClose}>&times;</CloseButton>
        </AlertContainer>
      )}
    </>
  );
};

export default AlertButton;
