import axiosInst from "../../Config";

export const PasswordResetEmailCheck_Api = async (data) =>
  await axiosInst.post(`/vozorpm/sendverificationemailforreset`, data);

export const NewPasswordEmailCheck_Api = async (data) =>
  await axiosInst.post(`/vozorpm/password/reset`, data);

export const ResetVerifyCheckApi_Api = async (data) =>
  await axiosInst.post(`/vozorpm/password/reset/link/verify`, data);

export const userSignUpApi = async (data) =>
  await axiosInst.post(`/vozorpm/user/signup`, data);
