import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  SetAllDevices,
  setTotalRecords,
} from "../../StateManagement/Reducers/DevicesState";
import { GetAllDevices_Api, GetAllRecords_Api } from "../Apis/DevicesApi";

export function* AllDevicesApi({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(GetAllDevices_Api, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetAllDevices(Data));
      } else {
        yield put(SetAllDevices([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetAllDevices([]);
  }
}

export function* AllRecordsApi({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(GetAllRecords_Api, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(setTotalRecords(Data));
      } else {
        yield put(setTotalRecords([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    setTotalRecords([]);
  }
}
