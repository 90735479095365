import React, { useState } from "react";
import {
  Button,
  Container,
  Div,
  Image,
  PageSubTitle,
  PageTitle,
  ImageContainer,
} from "../../StyledComponents";
import { ForgotDiv } from "../Styles";
import checkcircle from "../../../Assets/images/check-circle.png";
import { useNavigate, useLocation } from "react-router-dom";
import { LoginCredentials } from "../../../StateManagement/Reducers/LoginState";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import AlertButton from "../../StyledComponents/AlertButton";
const ResetSuccess = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [user] = useState(state.user_name ? state.user_name : "");
  const [pass] = useState(state.password ? state.password : "");
  const TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const Navigate = useNavigate();
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const [login, setLogin] = useState(false);
  const [error, setError] = useState(false);
  const [disBtn, setDisBtn] = useState(false);

  let DBHost = "";
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  const rpm_image = finalurl + "/sites/" + DBHost + "/images/rpm_logo.png";
  const onFinish = () => {
    setDisBtn(true);
    dispatch(
      LoginCredentials({
        username: user,
        password: pass,
        timezone: TimeZone,
      })
    );
  };

  useEffect(() => {
    if (LoginResponse.status === "LOGIN_SUCCESS") {
      let token_enc = LoginResponse.access_token;
      let refresh_token_enc = LoginResponse.refresh_token;
      let expires_in_enc = moment()
        .add(LoginResponse.expires_in, "seconds")
        .format("YYYY-MM-DD HH:mm A");
      localStorage.setItem("DBHost", Encrypt_Value("default", "vozorpm"));
      localStorage.setItem("token", token_enc);
      localStorage.setItem("login_time", expires_in_enc);
      localStorage.setItem("refresh_token", refresh_token_enc);
      localStorage.setItem("idle", LoginResponse.idle);
      localStorage.setItem(
        "provider_id",
        Encrypt_Value(LoginResponse.provider_id, "vozorpm")
      );
      setLogin(true);
      setTimeout(() => {
        Navigate("/dashboard");
      }, 2000);
    } else if (LoginResponse === "LOGIN_FAILURE") {
      setDisBtn(false);
      setError(true);
    }
  }, [LoginResponse]);
  return (
    <>
      <Container backgroundColor="#F5F5F5">
        <ImageContainer textAlign="center" backgroundColor="#ffff">
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            marginTop="72px"
            marginBottom="10px"
            textAlign="center"
          />
        </ImageContainer>
        <ForgotDiv
          className="adjust-forgot"
          position="absolute"
          maxWidth="483px"
          top="0"
          right="0"
          bottom="0"
          left="0"
          height="max-content"
          backgroundColor="#ffff"
          borderRadius="16px"
          boxShadow="0px 6px 58px rgba(196, 203, 214, 0.103611)"
          padding="40px 40px 45px 40px"
          margin="150px auto"
        >
          <Div
            width="100%"
            display="flex"
            justifyContent="center"
            padding="0px 0px 16px 0px"
          >
            <Div
              backgroundColor="#D1F9DD"
              border=" 6.5px solid #ECFDF3"
              width="80px"
              height="80px"
              display="flex"
              justifyContent="center"
              borderRadius="50%"
              alignItems="center"
            >
              <Image src={checkcircle} alt="key" />
            </Div>
          </Div>
          <Div>
            <PageTitle
              textAlign="center"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="24px"
              lineHeight="28px"
              color="#2D3748"
            >
              Password reset
            </PageTitle>
            <PageSubTitle
              textAlign="center"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="16px"
              lineHeight="19px"
              color="#718096"
              marginTop="8px"
            >
              Your password has been Successfully reset.
            </PageSubTitle>
            <PageSubTitle
              textAlign="center"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="16px"
              lineHeight="19px"
              color="#718096"
              marginTop="8px"
              marginBottom="32px"
              padding="0px 30px"
            >
              Click below to log in magically.
            </PageSubTitle>
          </Div>
          <Div width="100%">
            <Button
              type="button"
              width="100%"
              background="#2C7BE5"
              height="38px"
              fontWeight="700"
              fontFamily="'Open Sans', sans-serif"
              fontSize="16px"
              lineHeight="22px"
              cursor="pointer"
              color="#FFFFFF"
              borderRadius="4px"
              border="none"
              onClick={onFinish}
              disabled={disBtn}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              Continue
            </Button>
            {login ? (
              <AlertButton variant="success">Login Successfully</AlertButton>
            ) : (
              error && (
                <AlertButton variant="error">
                  Username or Password Incorrect
                </AlertButton>
              )
            )}
          </Div>
        </ForgotDiv>
      </Container>
    </>
  );
};

export default ResetSuccess;
