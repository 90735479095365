import styled from "styled-components";
import { CgClose } from "react-icons/cg";
import { ModalBody } from "../../StyledComponents";

export const CloseIcon = styled(CgClose)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  color: ${(p) => p.color && p.color};
`;

export const Container1 = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  padding: ${(p) => p.padding && p.padding};
`;
export const TabPanel = styled.div`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
`;
export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => (p.fontSize ? p.fontSize : " 18px")};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 64px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          
                          width: 64px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(0px);
                          width: 64px;
                        }
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 39px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(85px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(85px);
                          width: 39px;
                        }
                        @media (max-width: 750px) {
                          transform : translateX(85px);
                          width: 39px;
                        }
                         }
               `;
    }
    if (props.line === 3) {
      return `
              &::after {
                        content: "";
                        width: 40px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                      
                        transform : translateX(141px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(141px);
                          width: 40px;
                        }
                         }
                        //  @media (max-width: 750px) {
                        //   transform : translateX(141px);
                        //   width: 40px;
                        // }
                        //  }
               `;
    }
    if (props.line === 4) {
      return `
              &::after {
                        content: "";
                        width: 64px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(207px);
                        transition: transform 1s;
                        @media (max-width: 900px) {
                          transform : translateX(207px);
                          width: 64px;
                        }
                        
                        @media (max-width: 750px) {
                          transform : translateX(207px);
                          width: 64px;
                        }
                         }
               `;
    }
  }}
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  border-radius: 2px;
  background: #ffff;
  border: 1.9px solid rgba(46, 46, 46, 0.25);

  &::after {
    content: "";
    display: block;
    // border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
    /* margin: 2px; */
    // background: #ffffff;
    /* box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2); */
    // &:checked{
    //   background: #407bff;
    // }
  }
`;

export const TogggleCheck = styled.input`
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  height: 16px;
  width: 16px;
  top: 0;
  left: 0;
  position: relative;
  background: #ffff;
  &:checked + ${CheckBoxLabel} {
    background: #fff;
    border: 1.9px solid #2c7be5;
    // background: #407bff;
    &::after {
      content: "";
      display: block;
      // border-radius: 50%;
      width: 8px;
      height: 8px;
      background: #407bff;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Buttonsty = styled.span`
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  color: ${(p) => p.color && p.color};
`;

export const ModalBodyRoles = styled(ModalBody)`
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 2px;
    background-color: lightgray;
    height: 2px;
  }
`;
