import React, { useState, useEffect, useRef } from "react";
import {
  Div,
  Span,
  PageSubTitle,
  FormGroup,
  Text,
} from "../../StyledComponents";
import {
  MainContainer,
  SelectBox,
  OptionsContainer,
  OptionItem,
  ArrowIcon,
  DeleteIcon,
  Input,
} from "./styles";

const MultiSelect = ({
  options,
  width,
  selectedOptions,
  onSelectedOptionsChange,
}) => {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );
    if (isSelected) {
      return;
    }
    const updatedOptions = [
      ...selectedOptions,
      {
        ...option,
        serialNo: "",
      },
    ];
    onSelectedOptionsChange(updatedOptions);
  };
  const handleSerialNoChange = (index, value) => {
    const updatedOptions = JSON.parse(JSON.stringify(selectedOptions));
    updatedOptions[index].serialNo = value;
    updatedOptions[index].error = false;
    // Validate the serial number for the specific option
    if (!value || value.trim() === "") {
      updatedOptions[index].hasValidationError = true;
    } else {
      updatedOptions[index].hasValidationError = false;
    }

    onSelectedOptionsChange(updatedOptions, index);
  };
  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    onSelectedOptionsChange(updatedOptions, index);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start----------------------------------------------------------- //
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <MainContainer position="relative" display="block" ref={dropdownRef}>
      <SelectBox
        onClick={toggleOptions}
        width={width}
        border="1px solid rgba(46, 46, 46, 0.25);"
        padding="7px 14px 7px 14px"
        cursor="pointer"
        display="block"
        alignItems="center"
        JustifyContent="space-between"
        borderRadius="4px"
        backgroundColor="#FFFFFF"
        fontWeight="400"
        fontSize="14px"
        className="ipadadddiv"
      >
        <ArrowIcon></ArrowIcon>
        <Span lineHeight="22px" fontFamily="'Open Sans',sans-serif">
          Select Device
        </Span>
      </SelectBox>
      {selectedOptions.length > 0 &&
        selectedOptions.map((option, index) => (
          <>
            <Div
              key={option.value}
              color="#2E2E2E"
              lineHeight="19px"
              marginBottom="8px"
              width="fit-content"
              fontWeight="600"
              fontSize="14px"
              paddingRight="8px"
              marginTop="16px"
            >
              {option.name}
            </Div>
            <PageSubTitle
              fontWeight="400"
              fontSize="14px"
              lineHeight="22px"
              color="#2E2E2E"
              marginBottom="6px"
            >
              Serial No
            </PageSubTitle>
            <FormGroup marginBottom="40px" display="flex">
              <Input
                //   readOnly={option.id ? true : false}
                border={
                  option.error === true
                    ? "1px solid red"
                    : "1px solid #2E2E2E40"
                }
                padding="8px"
                borderRadius="4px"
                width="378px"
                height="36px"
                marginRight="20px"
                id={`${option.value}${index}`}
                value={option.serialNo}
                onChange={(e) => {
                  handleSerialNoChange(index, e.target.value, e.target.id);
                }}
                className="ipadadddiv"
              />
              <DeleteIcon
                cursor={option.id ? "not-allowed" : "pointer"}
                onClick={() => {
                  if (option.id) {
                    return;
                  } else {
                    handleRemoveOption(index);
                  }
                }}
              />
            </FormGroup>
            <Text
              id={`error_text${option.value}${index}`}
              display={option.error === true ? "block" : "none"}
              marginTop="-33px"
              color="red"
              fontSize="12px"
            >
              {option.Msg !== "" && option.serialNo !== ""
                ? option.Msg
                : "Enter a valid Serial No"}
            </Text>
          </>
        ))}
      {isOpen && (
        <OptionsContainer className="ipadadddiv">
          {options.map((option) => (
            <OptionItem
              key={option.value}
              onClick={() => handleOptionClick(option)}
              backgroundColor={
                selectedOptions.some(
                  (selectedOption) => selectedOption.value === option.value
                ) && "#2C7BE5"
              }
            >
              {option.name}
            </OptionItem>
          ))}
        </OptionsContainer>
      )}
    </MainContainer>
  );
};
export default MultiSelect;
