import { createSlice } from "@reduxjs/toolkit";

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    chartData: [],
    ChartLoading: false,
    LoginTime: {},
    InitialLogCheck: "",
  },
  reducers: {
    GetchartData: (state) => {
      state.ChartLoading = true;
    },

    SetchartData: (state, action) => {
      state.chartData = action.payload;
      state.ChartLoading = false;
    },
    GetLoginTime: (state) => {
      return state;
    },
    SetLoginTime: (state, action) => {
      state.LoginTime = action.payload;
    },
    GetInitial: (state) => {
      return state;
    },
  },
});

export const {
  GetchartData,
  SetchartData,
  GetLoginTime,
  SetLoginTime,
  GetInitial,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
