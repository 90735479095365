import React, { useState, useEffect, memo } from "react";
import {
  Div,
  PageSubTitle,
  PageTitle,
  DropdownImage,
  Button,
  Span,
  Container,
} from "../../StyledComponents";
import {
  DropdownButton,
  DropdownItem,
  DropdownList,
  Ptag,
  TextArea,
} from "../../Settings/User/styles";
import { FieldHead, ErrorMessage } from "../../Patient/BillandInsurance/styles";
import { useNavigate, useLocation } from "react-router-dom";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import PatientDemoHead from "../PatientDemo/PatientDemoHead";
import PatientDemographics from "../PatientDemo/PatientDemographics";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  GetAddEncounterInsert,
  setEmptyEncounterdResponse,
  GetEncounterId,
  EditEncounterId,
  setEditEncounter,
} from "../../../StateManagement/Reducers/EncounterState";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { GetProviderList } from "../../../StateManagement/Reducers/SettingState";

const EncounterForm = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [notes, setnotes] = useState("");
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [encountproerr, setEncountProerr] = useState(false);
  const [ShowOptions, setShowOptions] = useState(false);
  const [isProviderDropdownOpen, setProviderDropdownOpen] = useState(false);
  const [selectedProviderTitle, setSelectedProviderTitle] = useState("");
  const [selectedProviderIdFromBackend, setSelectedProviderIdFromBackend] =
    useState(null);
  const [dis, setDis] = useState(false);

  const params = useParams();
  const patId = params.pid;

  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const AddEncounterAlart = useSelector(
    (state) => state.Encounter.EncounterAlertMsg
  );
  const SummaryDetails = useSelector((state) => state.Encounter.EditEncounter);
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const provider_name =
    localStorage.getItem("ProviderName") &&
    Decrypt_Value(localStorage.getItem("ProviderName"), "vozorpm");
  const locate = useLocation();

  const ProvList = useSelector((state) => state.Setting.ProviderList);

  //-----------------------------State and  Variables End----------------------------------------------- //

  //-----------------------------Function Start------------------------------------------------- //
  const handleclose = () => {
    if (locate.state !== null) {
      if (locate.state.locate_from === "view_details") {
        navigate("/Encounter/" + params.pid + "/ViewDetails/encounter");
      }
    } else {
      navigate("/Encounter/" + params.pid);
    }
    setbtnDisabled(false);
    setnotes("");
  };

  const handleSave = () => {
    var err = 0;

    if (selectedProviderTitle === "") {
      setEncountProerr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }
    setDis(true);
    setbtnDisabled(true);
    if (SummaryDetails.encounterId) {
      if (SingPatDetails.enrollment_status !== "4") {
        dispatch(
          EditEncounterId({
            encounterId: SummaryDetails.encounterId,
            notes: notes,
          })
        );
      }
    } else {
      dispatch(
        GetAddEncounterInsert({
          id: selectedProviderIdFromBackend,
          pid: patId,
          notes: notes,
        })
      );
    }
    setTimeout(() => {
      setDis(false);
    }, 1500);
  };
  const noteChange = (e) => setnotes(e.target.value);
  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "encount_pro") {
      setShowOptions(false);
    }
  };
  const toggleDropdown = () => {
    setProviderDropdownOpen((prev) => !prev);
  };

  const handleSelect = (id, value) => {
    setSelectedProviderIdFromBackend(id);
    setSelectedProviderTitle(value);

    setProviderDropdownOpen(false);
    setEncountProerr(false);
  };
  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //
  useEffect(() => {
    const prov = "userID=" + user_id;
    dispatch(GetProviderList(prov));
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (selectedProviderIdFromBackend !== null) {
      const selectedOption = ProvList.find(
        (provider) =>
          Number(provider.id) === Number(selectedProviderIdFromBackend)
      );
      if (selectedOption) {
        setSelectedProviderTitle(selectedOption.title);
      }
    }
  }, [selectedProviderIdFromBackend]);

  useEffect(() => {
    if (AddEncounterAlart !== "") {
      if (AddEncounterAlart.includes("Inserted")) {
        localStorage.setItem(
          "encounter",
          Encrypt_Value(AddEncounterAlart[0], "vozorpm")
        );
        dispatch(GetEncounterId(AddEncounterAlart[0]));
        setStatus("success");
        setModalAlerShow(true);
        setModalMsg("Saved Successfully!");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          navigate("/Encounter/" + params.pid);
        }, 1500);

        dispatch(setEmptyEncounterdResponse());
      } else if (AddEncounterAlart.includes("updated")) {
        localStorage.setItem(
          "encounter",
          Encrypt_Value(AddEncounterAlart[0], "vozorpm")
        );
        dispatch(GetEncounterId(AddEncounterAlart[0]));
        setStatus("success");
        setModalAlerShow(true);
        setModalMsg("Updated Successfully!");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          if (locate.state !== null) {
            if (locate.state.locate_from === "view_details") {
              navigate("/Encounter/" + params.pid + "/ViewDetails/encounter");
            }
          } else {
            navigate("/Encounter/" + params.pid);
          }
        }, 1500);

        dispatch(setEmptyEncounterdResponse());
        dispatch(setEditEncounter({}));
      }
    }
  }, [AddEncounterAlart]);

  useEffect(() => {
    if (SummaryDetails.encounterId) {
      setnotes(SummaryDetails.notes);
    }
  }, [SummaryDetails]);

  useEffect(() => {
    const selectedOption = ProvList.find(
      (provider) => Number(provider.id) === Number(SummaryDetails.provider)
    );
    if (selectedOption) {
      setSelectedProviderTitle(selectedOption.title);
    }
  }, [ProvList, SummaryDetails]);

  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <>
      <Container backgroundColor="#F5F5F5">
        <TopNav />
        <LeftNav />

        <Div marginLeft="56px" className="responsive-right">
          <Div background="#F5F5F5" padding="20px">
            <Div padding="20px" backgroundColor="#FFFFFF" marginBottom="20px">
              <PatientDemoHead PatinetInfo="Patient Info" />
              <PatientDemographics />
            </Div>
          </Div>
          <Div background="#F5F5F5">
            <PageSubTitle
              color="#2E2E2E"
              fontSize="18px !important"
              marginTop="-16px"
              lineHeight="24.51px !important"
              marginLeft="24px !important"
            >
              Patient Encounter Form - {SingPatDetails.patname}
            </PageSubTitle>
          </Div>
          <Div background="#F5F5F5" padding="16px 24px 24px 20px">
            <Div
              backgroundColor="#ffffff"
              borderRadius="8px"
              // margin="16px 24px 16px 24px"
              height="476px"
            >
              <Div>
                <PageTitle
                  color="#2C7BE5"
                  fontSize="16px"
                  lineHeight="20px"
                  fontWeight="600 !important"
                  letterSpacing="0px !important"
                  fontFamily="'Open Sans',sans-serif"
                  marginLeft="24px"
                  paddingTop="24px"
                  cursor="pointer"
                >
                  Encounter Visit Details
                </PageTitle>
              </Div>

              <Div
                display="grid"
                margin="24px 0px 0px 24px"
                position="relative"
              >
                <FieldHead
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="600px"
                  fontSize="14px"
                  lineHeight="20px"
                >
                  Encounter Provider
                </FieldHead>

                {/* <Div position="relative">
                  <SelectInput
                    height="38px"
                    width="318px"
                    name="encount_pro"
                    background="#FFFFFF"
                    fontSize="14px"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    paddingTop="10px"
                    position="relative"
                    margin="8px 0px 0px 0px"
                    style={
                      encountproerr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    onClick={() => setShowOptions(!ShowOptions)}
                  >
                    {ProvList.map((option) => (
                      <option key={option.id} value={option.title}>
                        {option.title}
                      </option>
                    ))}
                  </SelectInput>
                  <DropdownImage
                    height="8px !important"
                    width="12px !important"
                    marginTop="-23.3px !important"
                    marginLeft="289px !important"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </Div>
                {ShowOptions && ProvList ? (
                  <DropDownDiv
                    width="318px"
                    minHeight="45px"
                    background="#FFFFFF"
                    fontSize="14px !important"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    marginTop="67px"
                  >
                    <DropdownOptions
                      value={ProvList.title}
                      padding="10px 12px 8px 12px"
                      marginTop="3px"
                      onClick={() => setencountpro()}
                    >
                      {ProvList.title}
                    </DropdownOptions>
                  </DropDownDiv>
                ) : (
                  ""
                )} */}

                <DropdownButton
                  width="318px"
                  onClick={() => {
                    toggleDropdown();
                  }}
                >
                  <div title={selectedProviderTitle}>
                    <Ptag
                      style={{
                        maxWidth: "250px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        paddingRight: "20px !important",
                        marginLeft: " 0 !important",
                        color: "rgba(46, 46, 46, 1)",
                        float: "left",
                      }}
                    >
                      {selectedProviderTitle}
                    </Ptag>
                  </div>

                  <DropdownImage
                    marginLeft="0px"
                    marginTop="0px"
                    top="16px"
                    right="20px"
                    src={dropdownDownicon}
                  ></DropdownImage>
                </DropdownButton>

                {isProviderDropdownOpen && (
                  <DropdownList
                    isOpen={isProviderDropdownOpen}
                    style={{ width: "318px" }}
                  >
                    {ProvList.map((option, index) => (
                      <>
                        <DropdownItem
                          title={option.title}
                          key={index}
                          onClick={() => {
                            handleSelect(option.id, option.title);
                          }}
                        >
                          {option.title}
                        </DropdownItem>
                      </>
                    ))}
                  </DropdownList>
                )}

                {encountproerr && (
                  <ErrorMessage>Please Select Provider</ErrorMessage>
                )}
              </Div>

              <Div display="grid" margin="0px 0px 0px 24px">
                <FieldHead
                  padding=" 16px 0px 8px 0px"
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="600px"
                  fontSize="15px"
                  marginBottom="8px !important"
                  marginTop="24px"
                  style={{ marginTop: "24px" }}
                >
                  Notes
                </FieldHead>
                <TextArea
                  border="1px solid rgba(46,46,46,0.25)"
                  borderRadius="5px"
                  fontSize="14px"
                  lheight="19px"
                  weight="400"
                  color="#2E2E2E"
                  family="'Open Sans',sans-serif"
                  paddingTop="5px"
                  paddingLeft="10px"
                  value={notes}
                  onChange={noteChange}
                  style={{ width: "97.7%", height: "100px" }}
                ></TextArea>

                <Div
                  display="flex"
                  justifyContent="center"
                  alienItems="center"
                  marginTop="78px"
                >
                  <Button
                    fontSize="14px"
                    fontWeight="600"
                    borderRadius="4px"
                    padding="10px 30px 10px 32px"
                    margin="0 24px 0 0px"
                    border=" 1px solid #2C7BE5"
                    backgroundColor="#FFF"
                    color="#2C7BE5"
                    cursor="pointer"
                    background="#FFF"
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                    onClick={() => handleclose()}
                  >
                    <Span fontSize="14px" fontWeight="600">
                      Cancel
                    </Span>
                  </Button>
                  <Button
                    type="submit"
                    fontSize="14px"
                    fontWeight="600"
                    borderRadius="4px"
                    border="1px solid #2C7BE5"
                    padding="9px 33px 8px 33.1px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    height="36px"
                    lineHeight="19px"
                    cursor="pointer"
                    onClick={handleSave}
                    disabled={btnDisabled || dis}
                    isDisabled={dis}
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="19px"
                      cursor="pointer"
                    >
                      Save
                    </Span>
                  </Button>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Container>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(EncounterForm);
