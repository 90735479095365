import React, { useState, useEffect } from "react";
import {
  Div,
  FormLabel,
  Input,
  RedStar,
  Button,
  Image,
  Text,
  SpinnerWrapper,
  Spinner,
} from "../../StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "../../Patient/BillandInsurance/styles";
import Myprofileediticon from "../../../Assets/images/myprofileediticon.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
  AddProfileData,
  GetProfileDetails,
  setEmptyAddProfileResponse,
  userImageUpload,
} from "../../../StateManagement/Reducers/SettingState";
import { GetproviderDetail } from "../../../StateManagement/Reducers/LoginState";
import {
  GetCheckMailVerify,
  GetCheckPhoneVerify,
} from "../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import UploadProfilePicture from "../../StyledComponents/UploadProfilePicture";
import DBHost from "../../../Config/DBHost";
import Logo from "../../../Assets/images/Logo.png";

const Personal = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMsg, setModalMsg] = useState("");

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [Suffix, setSuffix] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPwd, setConfirmPwd] = useState("");
  const [CurrentPwd, setCurrentPwd] = useState("");
  const [PasswordIS, setPasswordIS] = useState(true);
  const [PasswordIS1, setPasswordIS1] = useState(true);
  const [PasswordIS2, setPasswordIS2] = useState(true);

  const [FirstNameErr, setFirstNameErr] = useState("");
  const [LastNameErr, setLastNameErr] = useState("");

  const [EmailErr, setEmailErr] = useState("");
  const [MobileErr, setMobileErr] = useState("");
  const [PasswordErr, setPasswordErr] = useState("");
  const [CurrentPassword, setCurrentPassword] = useState("none");
  const [validMailErr, setValidMailErr] = useState(false);
  const [validPhoneErr, setValidPhoneErr] = useState(false);
  const [initialMail, setInitialMail] = useState(false);
  const [initialPhone, setInitialPhone] = useState(false);

  const [ConfirmPwdErr, setConfirmPwdErr] = useState("");
  const [MisMatchPwd, setMisMatchPwd] = useState("");
  const [CurrentPwdErr, setCurrentPwdErr] = useState("");
  const [isupload, setIsUpload] = useState(false);
  const [previewimg1, setpreviewimg1] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageText, setImageText] = useState("add file");
  const [profilepic, setProfilepic] = useState("");
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(true);

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";
  const minimum = localStorage.getItem("MinimumPasswordLength")
    ? localStorage.getItem("MinimumPasswordLength")
    : "0";

  const profileDetailData = useSelector((state) => state.Setting.profileDetail);
  const EmailVerify = useSelector((state) => state.Setting.MailVerify);
  const PhoneVerify = useSelector((state) => state.Setting.PhoneVerify);
  const AddProfileResponseData = useSelector(
    (state) => state.Setting.AddProfileResponse
  );
  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start--------------------------------------------------------- //
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const setFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (e.target.value !== "") {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  };
  const setLastNameChange = (e) => {
    setLastName(e.target.value);
    if (e.target.value !== "") {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  };
  const setMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };
  const setSuffixChange = (e) => {
    setSuffix(e.target.value);
  };
  const setEmailChange = (e) => {
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setEmail(e.target.value);
    setInitialMail(true);
    if (regex.test(e.target.value)) {
      setEmailErr(false);
      dispatch(
        GetCheckMailVerify({
          email: e.target.value,
          val: "mail",
          id: profileDetailData.id,
        })
      );
    } else {
      setEmailErr(true);
    }
  };
  const setMobileChange = (e) => {
    if (
      !isNaN(e.target.value) &&
      !e.target.value.includes(".") &&
      !e.target.value.includes(" ")
    ) {
      setMobileErr(false);
      setInitialPhone(true);
      setValidPhoneErr(false);
      setMobile(e.target.value.trim());
    }
    if (e.target.value === "") {
      setMobileErr(true);
    }
  };
  const setPasswordChange = (e) => {
    setPassword(e.target.value);
    var strongRegex = new RegExp(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${minimum},})`
    );

    if (e.target.value !== "") {
      setCurrentPassword("grid");
    } else {
      setCurrentPassword("none");
    }
    if (strongRegex.test(e.target.value) || e.target.value === "") {
      if (ConfirmPwd === "" || ConfirmPwd === null) {
        setConfirmPwdErr(false);
      }
      setPasswordErr(false);
    } else {
      setPasswordErr(true);
    }
    setMisMatchPwd(false);
  };
  const setConfirmPwdChange = (e) => {
    setConfirmPwd(e.target.value);
    setMisMatchPwd(false);
    var strongRegex = new RegExp(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${minimum},})`
    );

    if (strongRegex.test(e.target.value) || e.target.value === "") {
      if (Password === "" || Password === null) {
        setPasswordErr(false);
      }
      setConfirmPwdErr(false);
    } else {
      setConfirmPwdErr(true);
    }
  };
  const setCurrentPwdChange = (e) => {
    setCurrentPwd(e.target.value);
    if (e.target.value !== "") {
      setCurrentPwdErr(false);
    } else {
      setCurrentPwdErr(true);
    }
  };
  const handlesetPasswordIS = () => {
    setPasswordIS((prevState) => !prevState);
  };
  const handlesetPasswordIS1 = () => {
    setPasswordIS1((prevState) => !prevState);
  };
  const handlesetPasswordIS2 = () => {
    setPasswordIS2((prevState) => !prevState);
  };
  const savePersonal = () => {
    var err = 0;
    if (FirstName === "" || FirstName === null) {
      setFirstNameErr(true);
      err = 2;
    }
    if (LastName === "" || LastName === null) {
      setLastNameErr(true);
      err = 2;
    }

    if (Email === "" || Email === null || validMailErr) {
      setEmailErr(true);
      setValidMailErr(false);
      err = 2;
    }
    if (validMailErr) {
      setEmailErr(false);
      setValidMailErr(true);
      err = 2;
    }
    if (Mobile === "" || Mobile === null) {
      setMobileErr(true);
      setValidPhoneErr(false);
      err = 2;
    }
    if (validPhoneErr) {
      setMobileErr(false);
      setValidPhoneErr(true);
      err = 2;
    }
    if (EmailErr || PasswordErr) err = 2;
    if (ConfirmPwd !== null && ConfirmPwd !== "") {
      if (Password === null || Password === "") {
        setPasswordErr(true);
        err = 2;
      }
    }
    if (Password !== "" && Password !== null) {
      if (ConfirmPwd === "" || ConfirmPwd === null) {
        setConfirmPwdErr(true);
        err = 2;
      } else if (Password !== ConfirmPwd) {
        err = 2;
        setMisMatchPwd(true);
      } else {
        if (CurrentPwd === "" || CurrentPwd === null) {
          setCurrentPwdErr(true);
          err = 2;
        }
      }
    }

    if (err > 0) {
      return false;
    } else {
      setDis(true);
      dispatch(
        AddProfileData({
          fname: FirstName,
          lname: LastName,
          mname: MiddleName,
          suffix: Suffix,
          email: Email,
          mobile: Mobile,
          password: Password,
          cpass: CurrentPwd,
          uid: user_id,
        })
      );
      setTimeout(() => {
        dispatch(GetproviderDetail({ id: user_id }));
        setDis(false);
      }, 1500);
      if (imageFile != "") {
        const filedata = new FormData();
        filedata.append("imgfile", imageFile);
        filedata.append("userId", user_id);
        dispatch(userImageUpload(filedata));
        setImageFile("");
        setpreviewimg1("");
      }
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  //-----------------------------Function End--------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (AddProfileResponseData !== "" && AddProfileResponseData !== undefined) {
      if (
        AddProfileResponseData.includes("Incorrect password") ||
        AddProfileResponseData.includes(
          "Reuse of previous passwords not allowed!"
        )
      ) {
        setStatus("");
        setModalMsg(AddProfileResponseData);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setModalMsg("");
        }, 3000);
        setCurrentPassword("none");
        setPassword("");
        setCurrentPwd("");
        setConfirmPwd("");
      } else if (AddProfileResponseData.includes("User Details updated")) {
        setStatus("success");
        setModalMsg("User Details Updated Successfully");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setModalMsg("");
        }, 3000);
        setCurrentPassword("none");
        setPassword("");
        setCurrentPwd("");
        setConfirmPwd("");
      }

      dispatch(setEmptyAddProfileResponse());
    }
  }, [AddProfileResponseData]);

  useEffect(() => {
    dispatch(GetProfileDetails(user_id));
  }, []);
  useEffect(() => {
    if (profileDetailData !== undefined || null) {
      setFirstName(profileDetailData.fname);
      setLastName(profileDetailData.lname);
      setMiddleName(profileDetailData.mname);
      setSuffix(profileDetailData.suffix);
      setEmail(profileDetailData.email);
      setMobile(profileDetailData.phonecell);
      setPassword(profileDetailData.password);
      setConfirmPwd(profileDetailData.password);
      setProfilepic(profileDetailData.img_url);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [profileDetailData]);
  useEffect(() => {
    if (initialMail) {
      if (EmailVerify === "Email not found") {
        setValidMailErr(false);
      } else {
        setValidMailErr(true);
      }
    }
    if (Email === "") {
      setValidMailErr(false);
    }
  }, [EmailVerify]);
  useEffect(() => {
    if (initialPhone) {
      if (PhoneVerify === "Phone Number not found") {
        setValidPhoneErr(false);
      } else {
        setValidPhoneErr(true);
      }
    }
    if (Mobile === "") {
      setValidPhoneErr(false);
    }
  }, [PhoneVerify]);
  useEffect(() => {
    let isCancelled = false;

    if (Mobile !== "") {
      const handlechange = async () => {
        await timeout(1000);
        if (!isCancelled) {
          dispatch(
            GetCheckPhoneVerify({
              phone: Mobile,
              val: "phone",
              id: profileDetailData.id,
            })
          );
        }
      };

      handlechange();
      return () => {
        isCancelled = true;
      };
    }
  }, [Mobile]);
  //-----------------------------UseEffect End---------------------------------------------- //
  return loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div display="flex">
        {profilepic || previewimg1 ? (
          <>
            <Image
              height="80px"
              width="80px"
              borderRadius="50%"
              src={previewimg1 === "" ? imageUrl + profilepic : previewimg1}
              alt="image"
            />
            <Image
              marginLeft="60px"
              marginTop="56px"
              position="absolute"
              height="20px"
              width="20px"
              cursor="pointer"
              onClick={() => {
                setIsUpload(true);
              }}
              src={Myprofileediticon}
            ></Image>
          </>
        ) : (
          <>
            <Div
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="8px 12px"
              height="80px"
              width="80px"
              borderRadius="50%"
              backgroundColor="rgb(192, 192, 192)"
            >
              <Text padding="0" fontWeight="600" fontSize="40px" color="#fff">
                {profileDetailData && profileDetailData.fname
                  ? profileDetailData.fname.charAt(0).toUpperCase()
                  : ""}
              </Text>
            </Div>
            <Image
              marginLeft="60px"
              marginTop="56px"
              position="absolute"
              height="20px"
              width="20px"
              cursor="pointer"
              onClick={() => {
                setIsUpload(true);
              }}
              src={Myprofileediticon}
            ></Image>
          </>
        )}

        <Image
          marginLeft="60px"
          marginTop="56px"
          position="absolute"
          height="20px"
          width="20px"
          cursor="pointer"
          onClick={() => {
            setIsUpload(true);
          }}
          src={Myprofileediticon}
        ></Image>
        <Div display="grid">
          <FormLabel
            fontSize="18px"
            color="#2E2E2E"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="25px"
            marginLeft="24px"
            fontWeight="600"
            marginTop="16px"
            title={FirstName + "," + LastName}
          >
            {truncateText(FirstName + "," + LastName, 40)}
          </FormLabel>
          <FormLabel
            fontSize="12px"
            color="rgba(46, 46, 46, 0.50)"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="25px"
            marginLeft="24px"
            fontWeight="400"
            marginTop="-10px"
          >
            Manage your personal, Password and more
          </FormLabel>
        </Div>
      </Div>
      <Div display="flex" marginTop="34px">
        <Div display="grid">
          <FormLabel
            fontSize="14px"
            color="#2E2E2E"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="25px"
            fontWeight="400"
            marginTop="-10px"
          >
            First Name<RedStar margin="2px 0 0 0">*</RedStar>
          </FormLabel>
          <Input
            border="0.50px rgba(46, 46, 46, 0.25) solid"
            height="36px"
            width="200px"
            borderRadius="4px"
            paddingLeft="10px"
            paddingRight="10px"
            style={
              FirstNameErr
                ? { border: "1px solid red", borderRadius: "5px" }
                : { marginTop: LastNameErr ? "-25px" : "0px" }
            }
            value={FirstName}
            onChange={(e) => setFirstNameChange(e)}
          ></Input>
          {FirstNameErr && (
            <ErrorMessage margin="5px 0px 5px 0px">
              Please Enter First Name
            </ErrorMessage>
          )}
        </Div>
        <Div display="grid" marginLeft="15px">
          <FormLabel
            fontSize="14px"
            color="#2E2E2E"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="25px"
            fontWeight="400"
            marginTop="-10px"
          >
            Last Name<RedStar margin="2px 0 0 0">*</RedStar>
          </FormLabel>
          <Input
            border="0.50px rgba(46, 46, 46, 0.25) solid"
            height="36px"
            width="200px"
            borderRadius="4px"
            paddingLeft="10px"
            paddingRight="10px"
            value={LastName}
            style={
              LastNameErr
                ? { border: "1px solid red", borderRadius: "5px" }
                : { marginTop: FirstNameErr ? "-25px" : "0px" }
            }
            onChange={(e) => setLastNameChange(e)}
          ></Input>

          {LastNameErr && (
            <ErrorMessage margin="5px 0px 5px 0px">
              Please Enter Last Name
            </ErrorMessage>
          )}
        </Div>
      </Div>

      <Div display="flex" marginTop="20px">
        <Div display="grid">
          <FormLabel
            fontSize="14px"
            color="#2E2E2E"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="25px"
            fontWeight="400"
            marginTop="-10px"
          >
            Middle Name
          </FormLabel>
          <Input
            border="0.50px rgba(46, 46, 46, 0.25) solid"
            height="36px"
            width="200px"
            borderRadius="4px"
            paddingLeft="10px"
            paddingRight="10px"
            value={MiddleName}
            onChange={(e) => setMiddleNameChange(e)}
          ></Input>
        </Div>
        <Div display="grid" marginLeft="15px">
          <FormLabel
            fontSize="14px"
            color="#2E2E2E"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="25px"
            fontWeight="400"
            marginTop="-10px"
          >
            Suffix
          </FormLabel>
          <Input
            border="0.50px rgba(46, 46, 46, 0.25) solid"
            height="36px"
            width="200px"
            borderRadius="4px"
            paddingLeft="10px"
            paddingRight="10px"
            value={Suffix}
            onChange={(e) => setSuffixChange(e)}
          ></Input>
        </Div>
      </Div>

      <Div marginTop="24px">
        <FormLabel
          fontSize="16px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Account Information
        </FormLabel>
      </Div>
      <Div marginTop="12px">
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="600"
        >
          Email
        </FormLabel>
      </Div>
      <Div marginTop="2px">
        <FormLabel
          fontSize="12px"
          color="rgba(46, 46, 46, 0.50)"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Clients will not see this email address. It will only be used for
          communication with vozo about your account.
        </FormLabel>
      </Div>
      <Div marginTop="2px" display="grid">
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Account Email<RedStar margin="2px 0 0 0">*</RedStar>
        </FormLabel>
        <Input
          border="0.50px rgba(46, 46, 46, 0.25) solid"
          height="36px"
          width="200px"
          borderRadius="4px"
          paddingLeft="10px"
          paddingRight="10px"
          value={Email}
          style={
            EmailErr || validMailErr
              ? { border: "1px solid red", borderRadius: "5px" }
              : {}
          }
          onChange={(e) => setEmailChange(e)}
        ></Input>
        {EmailErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Please Enter Valid Email
          </ErrorMessage>
        )}
        {validMailErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Email Id Already Exist
          </ErrorMessage>
        )}
      </Div>
      <Div marginTop="10px" display="grid">
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Mobile Phone Number<RedStar margin="2px 0 0 0">*</RedStar>
        </FormLabel>
        <Input
          border="0.50px rgba(46, 46, 46, 0.25) solid"
          height="36px"
          width="200px"
          type="text"
          borderRadius="4px"
          paddingLeft="10px"
          paddingRight="10px"
          value={Mobile}
          style={
            MobileErr || validPhoneErr
              ? { border: "1px solid red", borderRadius: "5px" }
              : {}
          }
          onChange={(e) => setMobileChange(e)}
        ></Input>
        {MobileErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Please Enter Valid Mobile
          </ErrorMessage>
        )}

        {validPhoneErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Phone Number Already Exist
          </ErrorMessage>
        )}
      </Div>
      <Div marginTop="2px" marginBottom="16px">
        <FormLabel
          fontSize="12px"
          color="rgba(46, 46, 46, 0.50)"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Used to verify your account should you need to recover your username
          or reset your password.
        </FormLabel>
      </Div>
      <FormLabel
        fontSize="16px"
        color="#2E2E2E"
        fontFamily="'Open Sans', sans-serif"
        lineHeight="25px"
        fontWeight="400"
      >
        Change password
      </FormLabel>
      <Div marginTop="12px" display="grid">
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          New Password
        </FormLabel>
        <Input
          border="0.50px rgba(46, 46, 46, 0.25) solid"
          height="36px"
          width="200px"
          borderRadius="4px"
          paddingLeft="10px"
          paddingRight="38px"
          value={Password}
          style={
            PasswordErr || MisMatchPwd
              ? { border: "1px solid red", borderRadius: "5px" }
              : {}
          }
          type={PasswordIS ? "password" : "text"}
          onChange={(e) => setPasswordChange(e)}
        ></Input>
        {PasswordIS ? (
          <FaRegEye
            onClick={handlesetPasswordIS}
            style={{
              marginLeft: "170px",
              marginTop: "36px",
              position: "absolute",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        ) : (
          <FaRegEyeSlash
            onClick={handlesetPasswordIS}
            style={{
              marginLeft: "170px",
              marginTop: "36px",
              position: "absolute",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        )}
        {PasswordErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Please Enter Valid Password
          </ErrorMessage>
        )}
        {MisMatchPwd && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Password and confirm password are mismatching
          </ErrorMessage>
        )}
      </Div>
      <Div marginTop="12px" display="grid">
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Confirm New Password
        </FormLabel>
        <Input
          border="0.50px rgba(46, 46, 46, 0.25) solid"
          height="36px"
          width="200px"
          borderRadius="4px"
          paddingLeft="10px"
          paddingRight="38px"
          type={PasswordIS1 ? "password" : "text"}
          value={ConfirmPwd}
          style={
            ConfirmPwdErr || MisMatchPwd
              ? { border: "1px solid red", borderRadius: "5px" }
              : {}
          }
          onChange={(e) => setConfirmPwdChange(e)}
        ></Input>
        {PasswordIS1 ? (
          <FaRegEye
            onClick={handlesetPasswordIS1}
            style={{
              marginLeft: "170px",
              marginTop: "36px",
              position: "absolute",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        ) : (
          <FaRegEyeSlash
            onClick={handlesetPasswordIS1}
            style={{
              marginLeft: "170px",
              marginTop: "36px",
              position: "absolute",
              cursor: "pointer",
              opacity: "0.5",
            }}
          />
        )}
        {ConfirmPwdErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Please Enter Valid Confirm password
          </ErrorMessage>
        )}
        {MisMatchPwd && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Password and confirm password are mismatching
          </ErrorMessage>
        )}
      </Div>
      <FormLabel
        fontSize="12px"
        color="rgba(46, 46, 46, 0.50)"
        fontFamily="'Open Sans', sans-serif"
        lineHeight="25px"
        fontWeight="400"
      >
        {` Requirements: ${minimum}+ characters, at least one number, one uppercase, and one
        lowercase letter.`}
      </FormLabel>

      <Div display={CurrentPassword} marginTop="2px" marginBottom="20px">
        <FormLabel
          fontSize="14px"
          color="#2E2E2E"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="25px"
          fontWeight="400"
        >
          Enter current password to save <RedStar margin="2px 0 0 0">*</RedStar>
        </FormLabel>
        <Input
          border="0.50px rgba(46, 46, 46, 0.25) solid"
          height="36px"
          width="200px"
          borderRadius="4px"
          paddingLeft="10px"
          paddingRight="38px"
          type={PasswordIS2 ? "password" : "text"}
          value={CurrentPwd}
          style={
            CurrentPwdErr
              ? { border: "1px solid red", borderRadius: "5px" }
              : {}
          }
          onChange={(e) => setCurrentPwdChange(e)}
        ></Input>
        {PasswordIS2 ? (
          <FaRegEye
            onClick={handlesetPasswordIS2}
            style={{
              marginLeft: "170px",
              marginTop: "36px",
              position: "absolute",
              cursor: "pointer",
            }}
          />
        ) : (
          <FaRegEyeSlash
            onClick={handlesetPasswordIS2}
            style={{
              marginLeft: "170px",
              marginTop: "36px",
              position: "absolute",
              cursor: "pointer",
            }}
          />
        )}
        {CurrentPwdErr && (
          <ErrorMessage margin="5px 0px 5px 0px">
            Please Enter Valid Current password
          </ErrorMessage>
        )}
      </Div>

      <Div
        margin="24px -31px 24px -31px"
        borderBottom="1px solid rgba(46, 46, 46, 0.25)"
      ></Div>
      <Div>
        <Button
          width="136px"
          height="36px"
          background="#2C7BE5"
          borderRadius="4px"
          color="#FFFFFF"
          border="none !important"
          cursor="pointer"
          onClick={savePersonal}
          disabled={dis}
          isDisabled={dis}
          hoverBackColor="#005FB2"
          activeBackgroundColor="rgba(44, 123, 229, 0.75)"
        >
          Save
        </Button>
      </Div>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
      <UploadProfilePicture
        show={isupload}
        close={() => setIsUpload(false)}
        setImageText={setImageText}
        setpreviewimg1={setpreviewimg1}
        setImageFile={setImageFile}
      />
    </>
  );
};
export default Personal;
