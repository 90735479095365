import React, { useState } from "react";
import { Container1, TabPanel, Tabs } from "./styles";
import { Div } from "../../StyledComponents/index";
import UserSheet from "./UserSheet";
import StaffSheet from "./StaffSheet";
import { useParams } from "react-router-dom";
import {  useDispatch } from "react-redux";
import { useEffect } from "react";
import { GetCountryCode } from "../../../StateManagement/Reducers/SettingState";
const ListSheet = () => {
  
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const params = useParams();
  const handleTabs = (tabIndex, value) => {
    setActiveTab(tabIndex);
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div overflow="auto" borderRadius="8px">
            <UserSheet />
          </Div>
        );
      case 2:
        return (
          <Div overflow="auto" borderRadius="8px">
            <StaffSheet />
          </Div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
   dispatch(GetCountryCode())
  }, [])
  
  return (
    <Container1>
      <Div
        textAlign="left"
        fontSize="18px"
        fontWeight="600"
        color="#2E2E2E"
        margin="20px"
      >
        {"Users"}
      </Div>
      <TabPanel
        width="100%"
        borderRadius="8px 8px 0px 0"
        borderBottom="1px solid rgba(46, 46, 46, 0.25);"
        display="flex"
        justifyContent="flex-start"
        textAlign="center"
      >
        <Tabs
          width="130px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          // padding="0px 0px 0 20px"
          isActive={activeTab === 1}
          onClick={() => handleTabs(1, "Users")}
          line={activeTab}
        >
          Providers
        </Tabs>
        <Tabs
          width="50px"
          fontWeight="600"
          fontFamily="'Open Sans', sans-serif"
          lineHeight="20px"
          color="rgba(46, 46, 46, 0.5)"
          // padding="0px 0px 0 20px"
          isActive={activeTab === 2}
          onClick={() => handleTabs(2, "Staff")}
        >
          Staffs
        </Tabs>
      </TabPanel>
      {tabComponent()}
    </Container1>
  );
};
export default ListSheet;
