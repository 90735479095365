import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Div,
  FormLabel,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
  Spinner,
  SpinnerWrapper,
} from "../../StyledComponents";

import { PaginDiv } from "./styles";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { BsPlayCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import Pagination from "../../StyledComponents/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import {
  GetFeeCollapseDelete,
  GetFeeDelete,
  GetFeeList,
  GetFeeListCollapse,
} from "../../../StateManagement/Reducers/PatientState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import {
  SetChartviewIdempty,
  setCSVData,
} from "../../../StateManagement/Reducers/EncounterState";
import Logo from "../../../Assets/images/Logo.png";
import { SetpatientDemotimer } from "../../../StateManagement/Reducers/PatientDemoState";
const FeeSheetItem = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const [DropTable, setDropTable] = useState(false);
  const [collapsedRows, setCollapsedRows] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const pid = params.pid;
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const FeeList = useSelector((state) => state.Patient.FeeList);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const FeeListCollapse = useSelector((state) => state.Patient.FeeListCollapse);
  const isLoading = useSelector((state) => state.Patient.Loading);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = FeeList.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(FeeList.length / postsPerPage);
  const [rowData, setRowData] = useState([]);
  const [feeEid, setFeeEid] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const setcollapseTable = (arr, index, pid, eid) => {
    setFeeEid(eid);
    setDropTable(true);
    if (collapsedRows.includes(index)) {
      setCollapsedRows(collapsedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setCollapsedRows((prevCollapsedRows) => [...prevCollapsedRows, index]);
      dispatch(GetFeeListCollapse({ pid: pid, eid: eid }));
    }
  };

  const handleopen = () => {
    navigate(`/FeeSheet/${pid}/ViewDetails`);
  };
  const ExportCSV = () => {
    dispatch(setCSVData({ source: "feesheet", pid: pid }));
  };

  const deleteCollapse = (rpm, bill, pid, eid, code, status) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (code === "0") {
        return false;
      } else if (status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(
                GetFeeCollapseDelete({
                  rpm: rpm,
                  bill: bill,
                  pid: pid,
                  eid: eid,
                })
              );
              setRowData((prevRowData) =>
                prevRowData.filter((data) => data.bill_id !== bill)
              );
            })
            .then(() => {
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  const feesSheetDelete = (pid, eid, lock, status) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (lock === "lock") {
        return false;
      } else if (status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(GetFeeDelete({ pid: pid, eid: eid }));
            })
            .then(() => {
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    const isIndexPresent = rowData.some((data) => data.encounter === feeEid);
    if (!isIndexPresent) {
      setRowData((prevRowData) => [...prevRowData, ...FeeListCollapse]);
    }
    setTimeout(() => {
      dispatch(SetChartviewIdempty());
      dispatch(
        SetpatientDemotimer({
          patId: "",
          timer: "",
        })
      );
    }, 1000);
  }, [FeeListCollapse]);

  useEffect(() => {
    setCollapsedRows([]);
  }, [currentPage]);

  useEffect(() => {
    dispatch(GetFeeList(pid));
  }, []);

  // ------------------------------ useEffect End----------------------------------------------------------- //

  return isLoading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>{" "}
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div display="flex" padding="20px" alignItems="center">
        <Div>
          <FormLabel
            fontSize="16px"
            color="#2E2E2E"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="20px"
            fontWeight="600"
          >
            Codes and Charges for {SingPatDetails.patname}
          </FormLabel>
        </Div>
        <Div marginLeft="auto" display="flex" alignItems="center">
          <Button
            cursor="pointer"
            width="120px"
            textAlign="center"
            color="#2C7BE5"
            border="1px solid #2C7BE5"
            borderRadius="4px"
            background="#FFF"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14px"
            padding="6px 6px 6px 22px"
            height="36px"
            marginRight="24px"
            display="flex"
            alignItems="center"
            onClick={ExportCSV}
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            activeBackgroundColor="rgba(44, 123, 229, 0.10)"
          >
            Export csv
          </Button>
          <FormLabel
            cursor="pointer"
            fontSize="14px"
            color="#2C7BE5"
            fontFamily="'Open Sans', sans-serif"
            lineHeight="20px"
            fontWeight="400"
            onClick={handleopen}
          >
            View Details
          </FormLabel>
        </Div>
      </Div>
      <Div overflowX="auto">
        <Table border="none !important">
          <TableHead background="#F1F5F8">
            <Tablerow
              color="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              textAlign="center"
            >
              <TableHeadCell width="30%" paddingLeft="0px">
                Encounter
              </TableHeadCell>
              <TableHeadCell width="17%" paddingLeft="0px">
                Encounter ID
              </TableHeadCell>
              <TableHeadCell width="22%" paddingLeft="0px">
                Provider
              </TableHeadCell>
              <TableHeadCell width="15%" paddingLeft="0px">
                Duration
              </TableHeadCell>
              <TableHeadCell width="20%" paddingLeft="0px">
                Action
              </TableHeadCell>
            </Tablerow>
          </TableHead>
          <TableBody>
            {currentPosts.length !== 0 ? (
              currentPosts.map((details, index) => (
                <React.Fragment key={index}>
                  <Tablerow
                    borderBottom={
                      collapsedRows.includes(index)
                        ? "none !important"
                        : "1px solid #E2E8ED"
                    }
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    bbtom={true}
                    textAlign="center"
                  >
                    <TableBodyCell paddingLeft="30px">
                      {details.date_range}
                    </TableBodyCell>
                    <TableBodyCell>{details.encounter}</TableBodyCell>
                    <TableBodyCell>{details.uname}</TableBodyCell>
                    <TableBodyCell>{details.month_difference}</TableBodyCell>
                    <TableBodyCell>
                      <Div>
                        <BsPlayCircle
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#2C7BE5",
                            rotate: collapsedRows.includes(index)
                              ? "270deg"
                              : "90deg",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setcollapseTable(
                              details,
                              index,
                              details.pid,
                              details.encounter
                            )
                          }
                        />
                        <FiTrash2
                          onClick={() =>
                            SingPatDetails.enrollment_status !== "4" &&
                            feesSheetDelete(
                              details.pid,
                              details.encounter,
                              details.lock_check,
                              details.status_check
                            )
                          }
                          style={{
                            width: "20px",
                            height: "20px",

                            fill: "#ffffff",
                            stroke: "#f24822",
                            marginLeft: "20px",
                            cursor:
                              details.lock_check === "unlock" &&
                              details.status_check === "open" &&
                              SingPatDetails.enrollment_status !== "4"
                                ? "pointer"
                                : "not-allowed",
                          }}
                        />
                      </Div>
                    </TableBodyCell>
                  </Tablerow>
                  {DropTable
                    ? collapsedRows.includes(index) && (
                        <Tablerow bbtom={true}>
                          <TableBodyCell
                            colSpan="5"
                            padding="3px 24px 15px 22px "
                            borderBottom={
                              collapsedRows.includes(index) &&
                              "1px solid #E2E8ED"
                            }
                          >
                            <Div
                              background="#FFFFFF"
                              boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.25)"
                              borderRadius="8px"
                            >
                              <Div padding="17px 0px 24px 29px">
                                <FormLabel
                                  fontSize="16px"
                                  color="#2C7BE5"
                                  fontFamily="'Open Sans', sans-serif"
                                  lineHeight="20px"
                                  fontWeight="600"
                                >
                                  Fee Summary
                                </FormLabel>
                              </Div>
                              <Table border="none !important">
                                <TableHead background="#F1F5F8">
                                  <Tablerow
                                    color="#2C7BE5"
                                    fontFamily="Open Sans, sans-serif"
                                    fontWeight="600"
                                    fontSize="14px"
                                    textAlign="center"
                                  >
                                    <TableHeadCell
                                      width="15%"
                                      paddingLeft="0px"
                                    >
                                      Date
                                    </TableHeadCell>
                                    <TableHeadCell
                                      width="16%"
                                      paddingLeft="0px"
                                    >
                                      Code
                                    </TableHeadCell>
                                    <TableHeadCell
                                      width="22%"
                                      paddingLeft="0px"
                                    >
                                      Activity
                                    </TableHeadCell>
                                    <TableHeadCell
                                      width="16%"
                                      paddingLeft="0px"
                                    >
                                      Price
                                    </TableHeadCell>
                                    <TableHeadCell
                                      width="16%"
                                      paddingLeft="0px"
                                    >
                                      Quantity
                                    </TableHeadCell>
                                    <TableHeadCell
                                      width="15%"
                                      paddingLeft="0px"
                                    >
                                      Action
                                    </TableHeadCell>
                                  </Tablerow>
                                </TableHead>

                                <TableBody>
                                  {rowData.length !== 0 &&
                                  rowData !== undefined &&
                                  rowData.some(
                                    (details1) =>
                                      details1.encounter === details.encounter
                                  ) ? (
                                    rowData
                                      .filter(
                                        (details1) =>
                                          details1.encounter ===
                                          details.encounter
                                      )
                                      .map((details1, subIndex) => (
                                        <Tablerow
                                          key={subIndex}
                                          borderBottom="1px solid #E2E8ED"
                                          fontFamily="Open Sans, sans-serif"
                                          fontWeight="400"
                                          fontSize="14px"
                                          textAlign="center"
                                        >
                                          <TableBodyCell>
                                            {details1.fee_date}
                                          </TableBodyCell>
                                          <TableBodyCell>
                                            {details1.code}
                                          </TableBodyCell>
                                          <TableBodyCell>
                                            {details1.activity !== "1" &&
                                            details1.activity !== ""
                                              ? details1.activity
                                              : "-"}
                                          </TableBodyCell>
                                          <TableBodyCell>
                                            {details1.fee}
                                          </TableBodyCell>
                                          <TableBodyCell>
                                            {details1.units}
                                          </TableBodyCell>
                                          <TableBodyCell>
                                            <Div>
                                              <FiTrash2
                                                onClick={() => {
                                                  SingPatDetails.enrollment_status !==
                                                    "4" &&
                                                    deleteCollapse(
                                                      details1.rpm_id,
                                                      details1.bill_id,
                                                      details1.pid,
                                                      details1.encounter,
                                                      details1.code_disabled,
                                                      details.status_check
                                                    );
                                                }}
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  fill: "#ffffff",
                                                  stroke: "#f24822",
                                                  cursor:
                                                    details1.code_disabled ===
                                                      "1" &&
                                                    details.status_check ===
                                                      "open" &&
                                                    SingPatDetails.enrollment_status !==
                                                      "4"
                                                      ? "pointer"
                                                      : "not-allowed",
                                                }}
                                              />
                                            </Div>
                                          </TableBodyCell>
                                        </Tablerow>
                                      ))
                                  ) : (
                                    <Tablerow height="168px">
                                      <TableBodyCell
                                        textAlign="center"
                                        colSpan={6}
                                      >
                                        <Div>
                                          <Image
                                            height="100px"
                                            width="100px"
                                            src={Emp_data_pic}
                                          ></Image>
                                        </Div>
                                        <FormLabel
                                          fontSize="14px"
                                          color="#2E2E2E"
                                          fontFamily="'Open Sans', sans-serif"
                                          lineHeight="20px"
                                          fontWeight="400"
                                        >
                                          No notes has been created to view fee
                                          summary
                                        </FormLabel>
                                      </TableBodyCell>
                                    </Tablerow>
                                  )}
                                </TableBody>
                              </Table>
                            </Div>
                          </TableBodyCell>
                        </Tablerow>
                      )
                    : ""}
                </React.Fragment>
              ))
            ) : (
              <Tablerow height="409px">
                <TableBodyCell textAlign="center" colSpan={5}>
                  <Div>
                    <Image
                      height="200px"
                      width="200px"
                      src={Emp_data_pic}
                    ></Image>
                  </Div>
                  <FormLabel
                    fontSize="14px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans', sans-serif"
                    lineHeight="20px"
                    fontWeight="400"
                  >
                    No encounter has been appointed to view fee summary
                  </FormLabel>
                </TableBodyCell>
              </Tablerow>
            )}
          </TableBody>
        </Table>
      </Div>
      <PaginDiv
        hidePagination={currentPosts.length === 0 || howManyPages === 1}
      >
        {currentPosts.length === 0 || howManyPages === 1 ? (
          ""
        ) : (
          <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
        )}
      </PaginDiv>

      {showAlert && (
        <AlertModal show={showAlert} msg={modalMsg} status={status} />
      )}
    </>
  );
};
export default FeeSheetItem;
