import React from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import DocumentsSheet from "./DocumentsSheet";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import AccessDenied from "../../AccessDenied";

const Documents = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container>
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" className="responsive-right">
        <Div background="#F1F5F8" padding="16px 24px 26px 22px">
          {permissions.includes("docs_access") ? (
            <DocumentsSheet />
          ) : (
            <AccessDenied />
          )}
        </Div>
      </Div>
    </Container>
  );
};
export default Documents;
