import React, { useState, useEffect, memo } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Div,
  Span,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
  SelectInput,
  Label,
  PageSubTitle,
} from "../../../StyledComponents/index";
import dropdownDownicon from "../../../../Assets/images/dropdownDownicon.png";
import {
  ErrorMessage,
  ModalDialog,
} from "../../../StyledComponents/AddCard/Styles";
import { CheckBoxLabel, CloseIcon, TogggleCheck } from "../Styles";
import permissionList from "../Permissions/PermissionsData";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProvSaveRole,
  GetRoles,
  GetViewProvRole,
} from "../../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";

const ViewProvider = (props) => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const [Role, setRole] = useState("");
  const [roleserr, setRoleserr] = useState(false);
  const [ShowOptions, setShowOptions] = useState(false);
  const [main_id, setmain_id] = useState(props.row.group_id);
  const [dis, setDis] = useState(false);
  const tempData = {
    dashboard_access: false,
    pat_enroll: false,
    pat_update: false,
    export_tool: false,
    device_assign: false,
    encounter_add: false,
    encounter_visit: false,
    encounter_sumry: false,
    feesheet: false,
    live_access: false,
    device_inventory: false,
    treat_plan: false,
    profile_edit: false,
    message_access: false,
    notifics: false,
    enc_lock: false,
    notes_lock: false,
    user_add: false,
    service_access: false,
    docs_access: false,
    security_access: false,
    vitals_access: false,
    graph_access: false,
  };

  const [chkBoxData, setChkBoxData] = useState(tempData);
  const dispatch = useDispatch();
  const permissionval = useSelector((state) => state.Setting.EditProvRoles);
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const RolesList = useSelector((state) => state.Setting.Roles);

  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start--------------------------------------------------------- //
  const chkBoxChgVal = (name) => {
    setChkBoxData({ ...chkBoxData, [name]: !chkBoxData[name] });
  };

  const setRoles1 = (r) => {
    setRole(r.name);
    setShowOptions(false);
    setmain_id(r.group_id);
    if (r.name !== "") {
      setRoleserr(false);
    }
  };

  const handleClose = () => {
    setShowOptions(false);
    setRoleserr(false);
    setRole("");
    props.close();
  };

  // const handleSavePermission = () => {
  //   setDis(true);
  //   let err = 0;

  //   if (Role === "") {
  //     setRoleserr(true);

  //     err = 2;
  //   }
  //   setTimeout(() => {
  //     setDis(false);
  //   }, 1500);
  // };

  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "Roles_view") {
      setShowOptions(false);
    }
  };

  const handleContainerClick = (e) => {
    if (ShowOptions) {
      setShowOptions(false);
    }
    e.stopPropagation();
  };

  const saveRoles = () => {
    var main_acl = main_id;
    const article = {
      acl: main_acl,
      aro: props.aro_id,
    };

    dispatch(GetProvSaveRole(article));

    props.close();
  };

  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  useEffect(() => {
    if (main_id !== 0) {
      dispatch(GetViewProvRole({ main_id: main_id, id: props.id }));
    }
  }, [main_id]);

  useEffect(() => {
    setChkBoxData(tempData);
    if (permissionval.length > 0) {
      setRole(permissionval[0].name);
      if (permissionval[1]?.length > 0) {
        permissionval[1].forEach((item) => {
          const { value } = item;
          setChkBoxData((prevData) => ({
            ...prevData,
            [value]: true,
          }));
        });
      }
    }
  }, [permissionval, main_id]);

  useEffect(() => {
    let articles =
      "trial=" +
      true +
      "&account=" +
      3 +
      "&uid=" +
      user_id +
      "&owner_id=" +
      null;
    dispatch(GetRoles(articles));
  }, [user_id]);

  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        overflowY="auto"
        onClick={handleClose}
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          onClick={handleContainerClick}
        >
          <ModalDialog width="505px">
            <ModalHeader
              padding="12px 0px 10px 23px"
              justifyContent="space-between"
            >
              <ModalTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600"
                fontSize="24px"
                lineHight="normal"
                color="#2E2E2E"
              >
                Permission for {props.roleName}
              </ModalTitle>
              <ModalTitle marginRight="10px">
                <Button
                  border="none"
                  marginTop="9px"
                  background="white"
                  cursor="pointer"
                  onClick={handleClose}
                >
                  <CloseIcon height="24px" width="24px" color="#2E2E2E" />
                </Button>
              </ModalTitle>
            </ModalHeader>

            <Div overflowY="auto" maxHeight="400px">
              <ModalBody
                style={{ position: "sticky", top: "0" }}
                padding="0px 5px 1px 23px"
              >
                <Div padding="0 48px 0 0" margin="16px 0px 0px 0px">
                  <Label
                    size="14px"
                    lineHeight="normal"
                    weight="400"
                    fontFamily="'Open Sans',sans-serif"
                  >
                    Role
                  </Label>
                  <SelectInput
                    placeholder="Administrator"
                    width="459px"
                    height="38px"
                    name="Roles_view"
                    background="#FFFFFF"
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    color="#2E2E2E"
                    pl="10px"
                    paddingTop="10px !important"
                    margin="8px 0px 0px 0px"
                    style={
                      roleserr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    onClick={() => setShowOptions(!ShowOptions)}
                  >
                    {Role}
                  </SelectInput>

                  <DropdownImage
                    height="8px !important"
                    width="12px !important"
                    marginTop="-22px"
                    marginLeft="427px"
                    src={dropdownDownicon}
                    onClick={() => setShowOptions(!ShowOptions)}
                  ></DropdownImage>
                </Div>

                {ShowOptions && RolesList.length > 0 ? (
                  <DropDownDiv
                    width="459px"
                    background="#FFFFFF"
                    border=" 1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    marginTop="1px"
                    maxHeight="185px"
                    overflowY="auto"
                  >
                    {RolesList.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.group_id}
                          padding="10px 12px 8px 19px"
                          color="#2E2E2E"
                          fontSize="14px"
                          fontFamily="'Open Sans',sans-serif"
                          onClick={() => setRoles1(r)}
                        >
                          {r.name}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
                {roleserr && (
                  <ErrorMessage>Please Select Valid Roles</ErrorMessage>
                )}

                <PageSubTitle
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400"
                  fontSize="18px"
                  lineHight="normal"
                  color="#2E2E2E"
                  marginTop="16px"
                >
                  Permissions
                </PageSubTitle>

                {permissionList.map((ele, i) => {
                  return (
                    <Div
                      borderBottom="1px solid rgba(46, 46, 46, 0.25)"
                      marginTop="26px"
                      key={i}
                      flexCenterBetween
                      width="100%"
                      height="31px"
                    >
                      <Div>{ele.permission}</Div>

                      <Div position="relative">
                        <TogggleCheck
                          checked={chkBoxData[ele.key]}
                          type="checkbox"
                          onClick={(e) => {
                            chkBoxChgVal(ele.key);
                          }}
                          disabled={true}
                        />
                        <CheckBoxLabel htmlFor="checkbox" />
                      </Div>
                    </Div>
                  );
                })}
              </ModalBody>
            </Div>

            <ModalFooter textAlign="right" padding="24px 6px 21px 0px">
              <Button
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                background="#FFF"
                color="#2C7BE5"
                marginRight="16px"
                cursor="pointer"
                onClick={handleClose}
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                <Span fontSize="14px" fontWeight="600">
                  Cancel
                </Span>
              </Button>
              <Button
                type="submit"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 25px 8px 25px"
                background="#2C7BE5"
                color="#FFFFFF"
                marginRight="16px"
                cursor="pointer"
                onClick={saveRoles}
                disabled={dis}
                isDisabled={dis}
                hoverBackColor="#005FB2"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Save Permission
                </Span>
              </Button>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default memo(ViewProvider);
