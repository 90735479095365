import React, { useState, useEffect, useRef } from "react";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Input,
  SpanTag,
  Tablerow,
  Image,
  Spinner,
  SpinnerWrapper,
} from "../StyledComponents";
import { SearchIcon, TableDiv } from "./styles";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import Pagination from "../StyledComponents/Pagination/Pagination";
import Emp_data_pic from "../../Assets/images/No_Data_Img.png";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { PaginDiv } from "./styles";
import { RiEdit2Line } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";
import AssignTreatmentPlan from "./AssignTreatmentPlan";
import {
  GetDeletePlan,
  GetPlanDetails,
  SetDeletePlan,
  SetTreatPlanDetails,
} from "../../StateManagement/Reducers/TreatmentPlanState";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import Logo from "../../Assets/images/Logo.png";

const TreatmentPlanSheet = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef();
  const [ShowOptions, setShowOptions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPosts, setCurrentPost] = useState([]);
  const [findProv, setFindProv] = useState("");
  const [findPlan, setFindPlan] = useState("");
  const [howManyPages, setHowManyPages] = useState(0);
  const [planId, setPlanId] = useState(0);
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const TreatPlanList = useSelector(
    (state) => state.TreatmentPlan.TreatmentPlanDetails
  );

  const DeleteData = useSelector((state) => state.TreatmentPlan.DeleteResponse);
  const EmptyRowStyle = {
    height: "56px",
  };
  const emptyRows = Math.max(0, 10 - currentPosts.length);

  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      plan_name: "",
      date_added: "",
      providerName: "",
    })
  );

  const rowsToShow = [...currentPosts, ...emptyRowPlaceholders];
  const userID =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //

  const addTreatmentPlan = () => {
    Navigate("/addtreatment");
    dispatch(SetTreatPlanDetails({}));
  };

  const editTreatmentPlan = (a, b) => {
    Navigate(`/edittreatment/${a}/${b}`);
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  const addPlanToPatient = (id) => {
    setPlanId(id);
    setOpen(true);
  };

  const changeProvider = (e) => {
    setCurrentPage(1);
    setFindProv(e.target.value);
  };

  const changePlan = (e) => {
    setCurrentPage(1);
    setFindPlan(e.target.value);
  };

  const deletePlanName = (id) => {
    if (window.confirm("Are you sure you want to Delete?")) {
      dispatch(
        GetDeletePlan({
          pid: 0,
          planId: id,
          searchKey_provider: "",
          searchKey_plan: "",
          page: currentPage,
          pageSize: 10,
        })
      );
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (DeleteData !== "") {
      if (DeleteData && DeleteData === "Deleted Successfully") {
        setShowAlert(true);
        setModalMsg("Deleted successfully");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
      }
      dispatch(SetDeletePlan(""));
    }
  }, [DeleteData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        GetPlanDetails({
          searchKey_provider: findProv,
          searchKey_plan: findPlan,
          page: currentPage,
          pageSize: 10,
          pid: 0,
        })
      );
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, findPlan, findProv]);

  useEffect(() => {
    if (TreatPlanList) {
      setCurrentPost(TreatPlanList.datas ?? []);
      setHowManyPages(TreatPlanList.totalPages ?? 0);
    }
  }, [TreatPlanList]);

  useEffect(() => {
    const handleOutsideClick = async (event) => {
      const container = listRef.current;
      if (container && !container.contains(event.target)) {
        if (ShowOptions === true) {
          setShowOptions(false);
          event.stopPropagation();
        }
      }
    };
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [ShowOptions]);

  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    // isLoading ?
    //   <SpinnerWrapper>
    //     <Spinner />
    //   </SpinnerWrapper>
    // ) :

    <>
      <Div
        display="flex"
        marginTop="-4px"
        paddingBottom="16px"
        backgroundColor="#F1F5F8"
        className="PatListShow"
        fontFamily="Open Sans, sans-serif"
        fontWeight="600"
        fontSize="18px"
        alignItems="center"
      >
        <Div>Treatment Plan</Div>
      </Div>
      <Div backgroundColor="#FFFFFF" borderRadius="8px">
        <Div
          display="flex"
          paddingTop="8px"
          paddingBottom="8px"
          paddingLeft="20px"
          paddingRight="20px"
          border="1px solid #E2E8ED"
          borderBottom="0px"
          position="relative"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          <Div display="flex" position="relative" width="23%">
            <SearchIcon>
              <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" fontSize="18px" />
            </SearchIcon>

            <Input
              type="search"
              name="search"
              placeholder="Search Created by"
              padding="10px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              width="100%"
              paddingLeft="32px"
              color="rgba(46, 46, 46, 0.5)"
              fontFamily="Open Sans, sans-serif"
              fontWeight="400"
              fontSize="14px"
              position="absolute"
              value={findProv}
              onChange={(e) => changeProvider(e)}
              autoComplete
              className={findProv ? "hide-icon" : ""}
            />
          </Div>
          <Div display="flex" position="relative" width="23%">
            <SearchIcon left="30px">
              <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" fontSize="18px" />
            </SearchIcon>
            <Input
              type="search"
              name="search"
              placeholder="Search Plan"
              padding="10px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              width="100%"
              paddingLeft="32px"
              color="rgba(46, 46, 46, 0.5)"
              fontFamily="Open Sans, sans-serif"
              fontWeight="400"
              fontSize="14px"
              position="absolute"
              value={findPlan}
              onChange={(e) => changePlan(e)}
              autoComplete
              className={findPlan ? "hide-icon" : ""}
              marginLeft="20px"
            />
          </Div>
          <Div marginLeft="auto" display="flex" marginRight="0px">
            <Button
              cursor="pointer"
              marginLeft="auto"
              padding="8px"
              border="1px solid #2C7BE5"
              borderRadius="4px"
              color="#FFFFFF"
              background="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              onClick={addTreatmentPlan}
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              Add Treatment Plan
            </Button>
          </Div>
        </Div>

        <TableDiv overflowX="auto" className="TableLayout">
          <Table
            borderBottomLeftRadius={
              currentPosts.length === 0 || howManyPages === 1 ? "8px" : "0px"
            }
            borderBottomRightRadius={
              currentPosts.length === 0 || howManyPages === 1 ? "8px" : "0px"
            }
          >
            <TableHead background="#F1F5F8">
              <Tablerow
                color="#2C7BE5"
                fontFamily="Open Sans, sans-serif"
                fontWeight="400"
                fontSize="14px"
              >
                <TableHeadCell>
                  <SpanTag display="flex" paddingLeft="16px" width="128px">
                    Plan Name
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell>
                  <SpanTag display="flex" width="118px">
                    Created by
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell>
                  <SpanTag display="flex" width="118px">
                    Added Date
                  </SpanTag>
                </TableHeadCell>
                <TableHeadCell paddingLeft="24px">
                  <SpanTag
                    display="flex"
                    width="130px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Action
                  </SpanTag>
                </TableHeadCell>
              </Tablerow>
            </TableHead>
            <TableBody>
              {currentPosts.length !== 0 ? (
                rowsToShow.map((details, index) => (
                  <Tablerow
                    borderBottom="1px solid #E2E8ED"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    isHovered={true}
                    key={index}
                  >
                    <TableBodyCell
                      paddingTop="20px"
                      paddingBottom="20px"
                      paddingLeft="30px"
                      maxWidth="30px"
                      title={details.plan_name}
                    >
                      {truncateText(details.plan_name, 30)}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 18px">
                      {details.providerName}
                    </TableBodyCell>
                    <TableBodyCell padding="20px 2px 20px 18px">
                      {details.date_added}
                    </TableBodyCell>
                    <TableBodyCell
                      padding="8px 2px 8px 0px"
                      style={details.plan_name ? null : EmptyRowStyle}
                    >
                      {details.plan_name ? (
                        <Div
                          display="flex"
                          float="left"
                          alignItems="center"
                          paddingLeft="24px"
                          paddingRight="24px"
                        >
                          <Button
                            cursor="pointer"
                            marginLeft="auto"
                            padding="8px"
                            border="1px solid #2C7BE5"
                            borderRadius="4px"
                            color="#FFFFFF"
                            background="#2C7BE5"
                            fontFamily="Open Sans, sans-serif"
                            fontWeight="600"
                            fontSize="14px"
                            paddingLeft="14px"
                            onClick={() => addPlanToPatient(details.id)}
                            marginRight="20px"
                            hoverBackColor="#005FB2"
                            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                          >
                            Add
                          </Button>
                          <SpanTag
                            height="18px"
                            width="18px"
                            color=" #2C7BE5"
                            cursor="pointer"
                            onClick={() => editTreatmentPlan(details.id, 0)}
                          >
                            <RiEdit2Line />
                          </SpanTag>
                          <SpanTag
                            height="18px"
                            width="16px"
                            color=" #B00020"
                            marginLeft="24px"
                            cursor="pointer"
                            onClick={() => deletePlanName(details.id)}
                          >
                            <FiTrash2
                              style={{ fill: "#ffffff", stroke: "#f24822" }}
                            />
                          </SpanTag>
                        </Div>
                      ) : (
                        ""
                      )}
                    </TableBodyCell>
                  </Tablerow>
                ))
              ) : dataLoaded && currentPosts.length === 0 ? (
                <>
                  <Tablerow>
                    <TableBodyCell textAlign="center" colSpan="8">
                      <Div>
                        <Image
                          height="133px"
                          width="133px"
                          src={Emp_data_pic}
                        ></Image>
                      </Div>
                    </TableBodyCell>
                  </Tablerow>
                  <Tablerow>
                    <TableBodyCell
                      textAlign="center"
                      colSpan="8"
                      paddingBottom="40px"
                    >
                      No patients has been added to show in the patient list
                    </TableBodyCell>
                  </Tablerow>
                </>
              ) : (
                <SpinnerWrapper>
                  <Spinner></Spinner>
                  <Image
                    width="40px"
                    height="40px"
                    position="absolute"
                    src={Logo}
                  />
                </SpinnerWrapper>
              )}
            </TableBody>
          </Table>
        </TableDiv>
        <PaginDiv
          hidePagination={
            currentPosts.length === 0 ||
            howManyPages === 1 ||
            howManyPages === 0
          }
        >
          {currentPosts.length === 0 ||
          howManyPages === 1 ||
          howManyPages === 0 ? (
            ""
          ) : (
            <Pagination
              current={currentPage}
              pages={howManyPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </PaginDiv>
      </Div>
      {open && (
        <AssignTreatmentPlan show={open} planId={planId} setOpen={setOpen} />
      )}
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};
export default TreatmentPlanSheet;
