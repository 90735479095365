import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Emp_data_pic from "../../Assets/images/No_Data_Img.png";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
  PageSubTitle,
  Span,
} from "../StyledComponents";
import { Arrow, Tooltip, TooltipText } from "./Styles";
import AccessDenied from "../AccessDenied";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
const TreatmentPlan = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const Navigate = useNavigate();
  const [currentPosts, setCurrentPost] = useState([]);
  const TreatPlanList = useSelector(
    (state) => state.TreatmentPlan.TreatmentPlanDetails
  );
  const EmptyRowStyle = {
    height: "60px",
  };
  const emptyRows = Math.max(0, 5 - currentPosts.length);

  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      plan_name: "",
      date_added: "",
      providerName: "",
    })
  );
  const rowsToShow = [...currentPosts, ...emptyRowPlaceholders];

  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const NavigateToTreatmentPlan = () => {
    Navigate("/TreatmentPlan");
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    if (TreatPlanList) {
      setCurrentPost(TreatPlanList.datas ?? []);
    }
  }, [TreatPlanList]);

  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <>
      <Div
        gridArea="TreatmentPlan"
        width="100%"
        borderRadius="8px"
        border="1px solid rgba(46, 46, 46, 0.25)"
        paddingBottom="4px"
        backgroundColor="#fff"
      >
        <Div
          display="flex"
          padding="11px 9px 14px 16px"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="18px"
          alignItems="center"
        >
          <PageSubTitle
            color="#000"
            fontSize="18px"
            fontWeight="600"
            lineHeight="normal"
          >
            Treatment Plan
          </PageSubTitle>
          <Button
            border="none"
            fontWeight="400"
            fontSize="14px"
            color=" #2C7BE5"
            lineHeight="normal"
            cursor="pointer"
            onClick={NavigateToTreatmentPlan}
            display="flex"
            paddingLeft="20px"
            backgroundColor="transparent"
            marginLeft="auto"
          >
            <Span marginTop="-3px"> View All</Span>
            <Arrow cursor="pointer"></Arrow>
          </Button>
        </Div>
        {permissions.includes("treat_plan") ? (
          <Div backgroundColor="#FFFFFF" width="99.9%">
            <Table border="none">
              <TableHead background="#F1F5F8">
                <Tablerow
                  color="#2C7BE5"
                  fontFamily="Open Sans, sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  padding="13px 2px 13px 23px"
                >
                  <TableHeadCell width="10% !important">
                    Plan Name
                  </TableHeadCell>
                  <TableHeadCell width="10% !important">
                    Created by
                  </TableHeadCell>
                  <TableHeadCell width="10% !important">
                    Added Date
                  </TableHeadCell>
                </Tablerow>
              </TableHead>
              <TableBody>
                {currentPosts.length !== 0 ? (
                  rowsToShow.slice(0, 5).map((details, index) => (
                    <Tablerow
                      borderBottom="1px solid #E2E8ED"
                      fontFamily="Open Sans, sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      isHovered={true}
                      key={index}
                      height="72px"
                      className="treat_dashboard_"
                      position="relative"
                    >
                      <TableBodyCell
                        paddingTop="13px"
                        paddingBottom="13px"
                        paddingLeft="23px"
                        width="10% !important"
                        maxWidth="210px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {details.plan_name && (
                          <Tooltip>
                            {details.plan_name}
                            <TooltipText className="TooltipText">
                              {details.plan_name}
                            </TooltipText>
                          </Tooltip>
                        )}
                      </TableBodyCell>
                      <TableBodyCell
                        width="10% !important"
                        padding="13px 2px 13px 23px"
                      >
                        {details.providerName}
                      </TableBodyCell>
                      <TableBodyCell
                        width="10% !important"
                        padding="13px 2px 13px 23px"
                        style={details.date_added ? null : EmptyRowStyle}
                      >
                        {details.date_added}
                      </TableBodyCell>
                    </Tablerow>
                  ))
                ) : (
                  <>
                    <Tablerow>
                      <TableBodyCell textAlign="center" colSpan="3">
                        <Div>
                          <Image
                            height="133px"
                            width="133px"
                            src={Emp_data_pic}
                          ></Image>
                        </Div>
                      </TableBodyCell>
                    </Tablerow>
                    <Tablerow>
                      <TableBodyCell
                        textAlign="center"
                        colSpan="8"
                        paddingBottom="40px"
                      >
                        No Treatment plan has been added to show in the patient
                        list
                      </TableBodyCell>
                    </Tablerow>
                  </>
                )}
              </TableBody>
            </Table>
          </Div>
        ) : (
          <AccessDenied width="200px" height="200px" />
        )}
      </Div>
    </>
  );
};

export default TreatmentPlan;
