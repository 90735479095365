import styled, { css } from "styled-components";
import {
  TableBodyCell,
  ModalBody,
  Div,
  Button,
  ModalContainer,
} from "../../StyledComponents";

export const TextArea = styled.textarea`
  resize: none;
  padding: ${(p) => p.padding && p.padding};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => p.family && p.family};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  text-align: ${(p) => p.align && p.align};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  height: ${(p) => (p.scroll ? p.height : "")};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
  padding-right: ${(p) => (p.paddingRight ? p.paddingRight : "16px")};
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "12px")};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "12px")};
  outline: none;
`;
export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;

export const ViewTableBodyCell = styled(TableBodyCell)`
  padding: 0 28px 16px;

  @media screen and (max-width: 800px) {
    padding: 0 16px 16px;
  }
`;

export const ViewModalBody = styled(ModalBody)`
  padding: 0px 24px 20px 24px;
  @media screen and (max-width: 800px) {
    padding: 8px 30px 20px 30px;
  }
  @media screen and (max-width: 750px) {
    padding: 8px 20px 20px 20px;
  }
`;
export const ViewDiv = styled(Div)``;
export const ViewButton = styled(Button)`
  margin-top: 16px;
  @media screen and (max-width: 820px) {
    margin-top: 8px;
  }
`;
export const EncounterModalContainer = styled(ModalContainer)`
  width: 814px;

  @media screen and (max-width: 830px) {
    width: 80%;
  }
`;

export const Carebutton = styled(Button)`
  margin: 0 0 0 8px;

  @media screen and (max-width: 830px) {
    margin: 8px 0 0 8px;
  }
`;
