import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Div,
  Span,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  SpanTag,
  Image,
} from "../../../StyledComponents/index";
import AddLaborder from "./AddLaborder";
import { FiTrash2 } from "react-icons/fi";
import { RiEdit2Line } from "react-icons/ri";
import {
  GetLaborder,
  GetLaborderdelete,
  setEmptyLabResponse,
  GetLaborderone,
} from "../../../../StateManagement/Reducers/HealthState";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";

const Laborder = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const dispatch = useDispatch();
  const pidvalue = props.pid;
  const prblmvalue = props.value;
  const [show, setShow] = useState(false);
  const [id, setId] = useState("0");
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [labedit, setLabedit] = useState("Add New");
  const [labeditshow, setLabeditshow] = useState(false);
  const LaborderLists = useSelector((state) => state.Health.Laborder);
  const labalert = useSelector((state) => state.Health.HealthAlertMsg);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const handleopen = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      setLabedit("Add New");
      setId("0");
      dispatch(setEmptyLabResponse());
      setShow(true);
    }
  };
  const handleclose = () => {
    setShow(false);
  };

  const handleopen1 = (patient_id, procedure_order_id) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShow(true);
      console.log(patient_id, "pid", procedure_order_id);
      setLabedit(procedure_order_id);
      dispatch(
        GetLaborderone({
          orderid: procedure_order_id,
          pat_id: patient_id,
          value: props.value,
        })
      );
    }
  };
  const handledelete = (patient_id, procedure_order_id) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (window.confirm("Are you sure you want to Delete?")) {
        dispatch(
          GetLaborderdelete({
            id: procedure_order_id,
            pat_id: patient_id,
            value: props.value,
          })
        );
      }
    }
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  // ------------------------------------------------- useEffect Start-----------------------------------------------------------//

  useEffect(() => {
    if (labalert !== "") {
      if (labalert.includes("Deleted")) {
        setModalAlerShow(true);
        setModalMsg("Deleted Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetLaborder({ pat_id: props.pid, value: props.value }));
      }
      if (labalert.includes("Updated")) {
        setModalAlerShow(true);
        setModalMsg("Updated Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetLaborder({ pat_id: props.pid, value: props.value }));
      }
      if (labalert.includes("Inserted")) {
        setModalAlerShow(true);
        setModalMsg("Saved Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetLaborder({ pat_id: props.pid, value: props.value }));
      }
    }
  }, [labalert]);

  useEffect(() => {
    dispatch(GetLaborder({ pat_id: props.pid, value: props.value }));
    setModalAlerShow(false);
  }, [props.value1]);
  // ------------------------------------------------- useEffect End-----------------------------------------------------------//

  return (
    <>
      <Div
        background="#fffff"
        hight="100px"
        borderRadius="8px"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      >
        <Div padding="16px 25px 15px 31px">
          <Div
            display="flex"
            fontFamily="'Open Sans'sans-serif"
            fontStyle="normal"
            fontWeight="600"
            fontSize="18px"
            lineHeight="20px"
          >
            <Span style={{ marginTop: "10px" }}> Lab Orders List</Span>

            <Button
              display="flex"
              borderRadius="4px"
              lineHeight="20px"
              border="1px solid #2C7BE5"
              padding="9px 36px 8px 31px"
              background="#2C7BE5"
              color="#FFFFFF"
              marginLeft="auto"
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
              onClick={handleopen}
              backgroundColor="#2C7BE5"
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              <Span
                fontSize="14px"
                fontWeight="600"
                fontFamily="'Open Sans', sans-serif"
                lineHeight="20px"
              >
                Add Lab Order
              </Span>
            </Button>
          </Div>
        </Div>
        <Div overflowY="auto" maxHeight="350px">
          <Table>
            <TableHead
              background="#F1F5F8"
              hight="56px"
              style={{ position: "sticky", top: "0" }}
              zIndex="1"
            >
              <TableRow
                color="#2C7BE5"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                colSpan="8"
                paddingTop="18px"
                paddingBottom="18px"
                fontFamily="'Open Sans'sans-serif"
              >
                <TableHeadCell
                  width="250px !important"
                  textAlign="center"
                  resWidth="17% !important"
                >
                  Providers
                </TableHeadCell>
                <TableHeadCell textAlign="center">Order ID</TableHeadCell>
                <TableHeadCell textAlign="center">Lab</TableHeadCell>
                <TableHeadCell textAlign="center">Description</TableHeadCell>
                <TableHeadCell textAlign="center">Order Date</TableHeadCell>
                <TableHeadCell textAlign="center">Priority</TableHeadCell>
                <TableHeadCell textAlign="center">Status</TableHeadCell>
                <TableHeadCell textAlign="center" paddingRight="16px">
                  Action
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {LaborderLists.length !== 0 ? (
                LaborderLists.map((details, ind) => (
                  <TableRow
                    key={ind}
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="19px"
                    color="#000000"
                    fontFamily="'Open Sans'sans-serif"
                    borderBottom="1px solid #cbd5e0"
                    paddingTop="16px"
                    paddingBottom="18px"
                  >
                    <TableBodyCell
                      width="250px !important"
                      textAlign="center"
                      paddingRight="0px"
                    >
                      {details.providername}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      Order ID {details.procedure_order_id}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {details.name}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {details.procedure_name}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {details.date_ordered}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {details.order_priority}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {details.order_status}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      <SpanTag
                        height="18px"
                        width="18px"
                        color=" #2C7BE5"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                        onClick={() =>
                          handleopen1(
                            details.patient_id,
                            details.procedure_order_id
                          )
                        }
                      >
                        <RiEdit2Line />
                      </SpanTag>
                      <SpanTag
                        height="18px"
                        width="16px"
                        color=" #B00020"
                        marginLeft="25px"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                        onClick={() =>
                          handledelete(
                            details.patient_id,
                            details.procedure_order_id
                          )
                        }
                      >
                        <FiTrash2
                          style={{ fill: "#ffffff", stroke: "#f24822" }}
                        />
                      </SpanTag>
                    </TableBodyCell>
                  </TableRow>
                ))
              ) : (
                <TableRow height="309px">
                  <TableBodyCell textAlign="center" colSpan={8}>
                    <Div>
                      <Image
                        height="150px"
                        width="150px"
                        src={Emp_data_pic}
                      ></Image>
                    </Div>
                    <Div
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      lineHeight="20px"
                      fontWeight="400"
                    >
                      No lab orders to be shown,Please add some.
                    </Div>
                  </TableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Div>
      </Div>

      <AddLaborder
        setLabedit={setLabedit}
        labedit={labedit}
        handleopen={handleopen}
        show={show}
        onHide={handleclose}
        id={id}
        pidvalue={pidvalue}
        prblmvalue={prblmvalue}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default Laborder;
