import axios from "axios";
import axiosInst from "../../Config";
import DBHost from "../../Config/DBHost";

export const GetVitals_Api = async (credential) =>
  await axiosInst.get("/vozorpm/patientdemographics/vitals?pid=" + credential);

export const sendMsgApi = async (credential) =>
  await axiosInst.post("/vozorpm/patient/sendMessage", credential);

export const MessageListApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/getMessagelist`, credential);

export const PreviewDownloadApi_1 = async (credential) =>
  await axiosInst.put(`/vozorpm/PreviewDownload`, credential);

export const StatusApi = async (data) =>
  await axiosInst.get(`/rpmportal/status?pid=${data.Pid}&mode=${data.mode}`);

export const ReadingChartApi = async (data) =>
  await axiosInst.post(`/vozorpm/patient/readings/chart`, data);

export const AddDeviceApi = async (credential) =>
  await axiosInst.post(`/vozorpm/addDevice`, credential);

export const DeviceListApi = async (credential) =>
  await axiosInst.get("/vozorpm/Devicelist?pid=" + credential);

export const SerialNoCheckApi = async (credential) =>
  await axiosInst.post("/vozorpm/patient/devicevalidation", credential);

export const AddDeviceMenuApi = async (credential) =>
  await axiosInst.post(`/vozorpm/devices/addDevice`, credential);

export const ConnectDeviceApi = async (payload) => {
  return await axios.get(
    DBHost.finalurl +
      `/interface/customized/custom_device_con/authentication1.php?device=${payload.device}&data=${payload.data.enc}`,
    {
      // params: article,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }
  );
};

export const TokenApi = async (payload) => {
  return await axios.get(
    DBHost.finalurl +
      `/interface/customized/custom_device_con/token.php?device=${payload.device}&data=${payload.data.enc}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }
  );
};

export const DisconnectDeviceApi = async (payload) => {
  return await axios.get(
    DBHost.finalurl +
      `/interface/customized/custom_device_con/disconnect.php?device=${payload.device}&data=${payload.data.enc}`,
    {
      // params: article,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    }
  );
};
