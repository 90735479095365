import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import msg from "../../../Assets/images/mail.png";
import userimg from "../../../Assets/images/checkEmail_user.png";
import close from "../../../Assets/images/Cancel.png";

import {
  Container,
  PageTitle,
  PageSubTitle,
  Div,
  Image,
  Text,
  SpanTag,
  Modal,
} from "../../StyledComponents";
// import { Modal } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { GetPatientEmail } from "../../../StateManagement/Reducers/PatientState";

const CheckEmail = ({ onClose, email, pid, mailurl, disable1 }) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [disable, setdisable] = useState(disable1);
  const [sendMsg, setSenMsg] = useState("");
  const [mailError, setMailError] = useState(false);
  const Emailresponse = useSelector((state) => state.Patient.PatientEmail);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//

  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const handleEmail = (e) => {
    setMail(e.target.value);
    setMailError(false);
  };
  const EmailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return regex.test(mail);
  };
  const habdleBacktoLogin = () => {
    Navigate("/");
  };

  const handleModalClick = () => {
    onClose();
  };
  const sendEmail = () => {
    setdisable(true);
    dispatch(
      GetPatientEmail({
        pid: pid,
        mailid: email,
        MailUrl: mailurl,
      })
    );
  };
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };
  // ---------------------------------------------- Functions end-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    setdisable(false);
  }, [Emailresponse]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Modal
        show={true}
        onClick={handleModalClick}
        width="100%"
        height="100%"
        justifyContent="center"
      >
        <Container
          backgroundColor="#F5F5F5"
          onClick={handleContainerClick}
          textAlign="center"
        >
          <Div
            width="296px"
            height="250px"
            position="absolute"
            left="50%"
            top="50%"
            zIndex="1255"
            backgroundColor="#ffff"
            borderRadius="8px"
            border="1px solid rgba(46, 46, 46, 0.25)"
            transform="translate(-50%,-50%)"
          >
            <Image
              src={close}
              marginLeft="256px"
              cursor="pointer"
              marginTop="15px"
              onClick={onClose}
            />
            <Div width="100%" display="flex" justifyContent="center">
              <Div
                backgroundColor="#c9deff"
                border=" 6.5px solid #ecf3ff"
                width="50px"
                height="50px"
                display="flex"
                justifyContent="center"
                borderRadius="50%"
                alignItems="center"
                marginTop="-11px"
              >
                <Image height="20px" width="20px" src={msg} alt="mail" />
              </Div>
            </Div>
            <PageTitle
              textAlign="center"
              fontFamily="'Open Sans',sans-serif"
              fontWeight="600"
              fontSize="20px"
              lineHeight="normal"
              color="#2D3748"
              marginTop="16px"
            >
              Patient Portal Enabled
            </PageTitle>
            <PageSubTitle
              textAlign="center"
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400"
              fontSize="14px"
              lineHeight="normal"
              color="rgba(113, 128, 150, 1)"
              marginTop="12px"
              marginBottom="8px"
              align="center"
            >
              We sent a Patient Portal setup link to
            </PageSubTitle>
            <Div
              height="36px"
              width="260px"
              background="rgba(249, 248, 248, 1)"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              borderRadius="8px"
              padding="8px 0px 9px 0px"
              marginLeft="18px"
            >
              <Image height="16px" width="16px" src={userimg} alt="userimg" />
              <Text
                fontSize="14px"
                lineHeight="normal"
                fontWeight="400"
                color="rgba(44, 123, 229, 1)"
                marginLeft="5px"
              >
                {email}
              </Text>
            </Div>
            <PageSubTitle
              marginTop="16px"
              color="#7D8592"
              fontWeight="400"
              fontSize="14px"
              lineHeight="normal"
              fontFamily="'Open Sans',sans-serif"
              align="center"
              textAlign="center"
            >
              Didn't receive the mail?
              <SpanTag
                color="rgba(63, 140, 255, 1)"
                cursor={disable ? "not-allowed" : "pointer"}
                disabled={disable}
                onClick={sendEmail}
              >
                Click to resend
              </SpanTag>
            </PageSubTitle>
          </Div>
        </Container>
      </Modal>
    </>
  );
};
export default CheckEmail;
