import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageSubTitle,
  Div,
  SpanTag,
  TextArea,
  Text,
  Button,
  Image,
  Pre,
  Spinner,
  SpinnerWrapper,
} from "../../StyledComponents";
import { DocScrlDiv } from "../../Live/styles";
import { TogggleCheck, CheckBoxLabel } from "../../Live/styles";
import UploadProfilePicture from "../../StyledComponents/UploadProfilePicture";
import Avatar from "../../../Assets/images/Avatar.png";
import EditDocument from "../../Popup/EditDocuments";
import {
  GetDocumentMailRes,
  GetSettingDocRes,
  GetUpdateDocMailRes,
  EmptyUpdateSetDocRes,
  GetProfileDetails,
} from "../../../StateManagement/Reducers/SettingState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import DBHost from "../../../Config/DBHost";
import Logo from "../../../Assets/images/Logo.png";

const Documents = () => {
  // ------------------------------------------------- State and Variables Start-----------------------------------------------------------//

  const [isupload, setIsUpload] = useState(false);
  const [previewimg1, setpreviewimg1] = useState("");
  const [imageText, setImageText] = useState("add file");
  const [imageFile, setImageFile] = useState(null);
  const [invLogo, setInvLogo] = useState(false);
  const [statementLogo, setStmtLogo] = useState(false);
  const [superLogo, setSuperLogo] = useState(false);
  const [signLine, setSignLine] = useState(false);
  const [diagDesc, setDiagDesc] = useState(false);
  const [docLogo, setDocLogo] = useState(false);
  const [invEdit, setInvEdit] = useState(false);
  const [editType, setEditType] = useState("");
  const [initialVal, setInitialVal] = useState(false);
  const [invFootInfo, setInvFootInfo] = useState("");
  const [stmtFootInfo, setStmtFootInfo] = useState("");
  const [supBillFootInfo, setSupBillFootInfo] = useState("");
  const [docFooterInfo, setDocFooterInfo] = useState("");
  const [settingLogo, setSettingLogo] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(true);

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  let imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";

  const EmailDocument = useSelector((state) => state.Setting.DocumentMailResp);
  const SettingDocResp = useSelector((state) => state.Setting.SettingDocResp);
  const DocMailResp = useSelector((state) => state.Setting.UpdateDocMailResp);
  const profileDetailData = useSelector((state) => state.Setting.profileDetail);

  const dispatch = useDispatch();
  // ------------------------------------------------- State and Variables End-----------------------------------------------------------//

  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const invFooterChange = (e) => {
    setInvFootInfo(e.target.value);
  };

  const stmtFooterChange = (e) => {
    setStmtFootInfo(e.target.value);
  };

  const supBlFooterChange = (e) => {
    setSupBillFootInfo(e.target.value);
  };

  const docFooterChange = (e) => {
    setDocFooterInfo(e.target.value);
  };

  const handleEdit = (type) => {
    setInitialVal(true);
    setEditType(type);
  };

  const handleSave = () => {
    let article = [
      { glname: "invoice_logo", glindex: "0", glvalue: invLogo ? "1" : "0" },
      { glname: "invoice_footer_info", glindex: "0", glvalue: invFootInfo },
      {
        glname: "statement_logo",
        glindex: "0",
        glvalue: statementLogo ? "1" : "0",
      },
      {
        glname: "statement_footer_info",
        glindex: "0",
        glvalue: stmtFootInfo,
      },
      {
        glname: "superbills_logo",
        glindex: "0",
        glvalue: superLogo ? "1" : "0",
      },
      {
        glname: "superbills_sig_line",
        glindex: "0",
        glvalue: signLine ? "1" : "0",
      },
      {
        glname: "superbills_diag",
        glindex: "0",
        glvalue: diagDesc ? "1" : "0",
      },
      {
        glname: "superbills_footer_info",
        glindex: "0",
        glvalue: supBillFootInfo,
      },
      { glname: "document_logo", glindex: "0", glvalue: docLogo ? "1" : "0" },
      { glname: "document_info", glindex: "0", glvalue: docFooterInfo },
    ];
    setDis(true);
    const dataArray = new FormData();

    if (imageFile) {
      dataArray.append("document_logo", imageFile);
      dataArray.append("name", "setting_document_logo");
    }

    let data = {
      article,
    };

    if (imageFile && imageFile !== "" && imageFile !== null) {
      data.file = dataArray;
    }
    Promise.resolve()
      .then(() => {
        dispatch(GetUpdateDocMailRes([data, dataArray]));
      })
      .then(() => {
        setImageFile(null);
        setTimeout(() => {
          setDis(false);
        }, 1500);
      });
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  // ------------------------------------------------- UseEffect Start-----------------------------------------------------------//

  useEffect(() => {
    if (initialVal && editType !== "") {
      setInvEdit(true);
    }
  }, [editType]);

  useEffect(() => {
    dispatch(GetDocumentMailRes());
    dispatch(GetSettingDocRes());
    dispatch(GetProfileDetails(user_id));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (SettingDocResp.length > 0 || SettingDocResp !== null) {
      setInvFootInfo(SettingDocResp[2]?.gl_value);
      setStmtFootInfo(SettingDocResp[6]?.gl_value);
      setSupBillFootInfo(SettingDocResp[9]?.gl_value);
      setDocFooterInfo(SettingDocResp[0]?.gl_value);
      setDiagDesc(SettingDocResp[8]?.gl_value === "1" ? true : false);
      setSuperLogo(SettingDocResp[10]?.gl_value === "1" ? true : false);
      setSignLine(SettingDocResp[11]?.gl_value === "1" ? true : false);
      setDocLogo(SettingDocResp[1]?.gl_value === "1" ? true : false);
      setInvLogo(SettingDocResp[3]?.gl_value === "1" ? true : false);
      setStmtLogo(SettingDocResp[7]?.gl_value === "1" ? true : false);
      setSettingLogo(SettingDocResp[5]?.gl_value);
    }
  }, [SettingDocResp]);

  useEffect(() => {
    if (DocMailResp === "success") {
      dispatch(GetSettingDocRes());
      setShowAlert(true);
      setModalMsg("Your Information has been Saved");
      setStatus("success");
      setTimeout(() => {
        setShowAlert(false);
        setModalMsg("");
        setStatus("");
        dispatch(EmptyUpdateSetDocRes());
      }, 2000);
    }
  }, [DocMailResp]);

  // ------------------------------------------------- UseEffect End-----------------------------------------------------------//

  return loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <PageSubTitle color="#2E2E2E" fontSize="18px">
        Documents
      </PageSubTitle>
      <Div
        backgroundColor="#FFFFFF"
        borderRadius="8px"
        marginTop="16px"
        padding="33px 46px 0px 44px"
        boxShadow="0px 0px 4px rgba(0,0,0,0.25)"
      >
        <Div>
          <PageSubTitle>Practice Logo</PageSubTitle>
          <Div display="flex" alignItems="center" marginTop="15px">
            <Div
              height="75px"
              width="75px"
              borderRadius="50%"
              display="flex"
              marginRight="23px"
              fontSize="60px"
              fontWeight="600"
            >
              {(settingLogo !== undefined &&
                settingLogo !== null &&
                settingLogo !== "") ||
              previewimg1 !== "" ? (
                <Image
                  height="75px"
                  width="75px"
                  borderRadius="50%"
                  src={
                    previewimg1 === "" ? imageUrl + settingLogo : previewimg1
                  }
                />
              ) : (
                <Image
                  height="75px"
                  width="75px"
                  borderRadius="50%"
                  src={Avatar}
                />
              )}
            </Div>
            <Button
              height="36px"
              backgroundColor="#2c7be5"
              color="#fff"
              border="none"
              onClick={() => {
                setIsUpload(true);
              }}
              padding="8px 16px"
              borderRadius="4px"
              fontSize="16px"
              cursor="pointer"
              fontFamily="Work Sans, sans-serif"
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              Upload Practice Logo
            </Button>
          </Div>
        </Div>
        <Div padding="24px 0px 0px 0px">
          <PageSubTitle color="#2C7BE5">Invoices</PageSubTitle>
          <Div display="flex" marginTop="16px">
            <Div position="relative" marginRight="8px">
              <TogggleCheck
                checked={invLogo}
                type="checkbox"
                onClick={() => setInvLogo((prevState) => !prevState)}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </Div>
            <SpanTag fontSize="14px">Include Logo</SpanTag>
          </Div>
          <Div margin="12px 0 0 0">
            <SpanTag fontSize="14px">Footer Information</SpanTag>
            <TextArea
              width="100%"
              resize="none"
              height="60px"
              borderRadius="8px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="8px 0 0 0"
              padding="20px 16px"
              fontFamily="Open Sans, sans-serif"
              outline="none"
              value={invFootInfo}
              onChange={invFooterChange}
            ></TextArea>
          </Div>
          <Div
            border="1px solid rgba(46, 46, 46, 0.25)"
            padding="16px 20px"
            marginTop="20px"
            borderRadius="8px"
          >
            <Div
              marginBottom="16px"
              display="flex"
              justifyContent="space-between"
            >
              <PageSubTitle
                fontFamily="'Open Sans', sans-serif"
                color="#2E2E2E"
              >
                Default Invoice Email
              </PageSubTitle>
              <Button
                border="none"
                color="#2C7BE5"
                backgroundColor="unset"
                cursor="pointer"
                onClick={() => handleEdit("Invoice")}
                fontWeight="600"
              >
                Edit
              </Button>
            </Div>
            <Div
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              margin="0 -20px 0 -20px"
            ></Div>
            <Div display="flex" marginTop="16px" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)" width="105px">
                From
              </Text>
              <Text color="#2E2E2E">{profileDetailData?.email}</Text>
            </Div>
            <Div display="flex" margin="16px 0 16px 0" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)">Subject</Text>
              <DocScrlDiv overflowX="auto" marginLeft="58px" maxWidth="815px">
                <Text color="#2E2E2E" margin="0 0 10px 0px">
                  {EmailDocument.filter(
                    (ele, i) => ele.gl_name === "invoice_subject"
                  ).map((ele) => ele.gl_value)}
                </Text>
              </DocScrlDiv>
            </Div>
            <Div
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              margin="0 -20px 0 -20px"
            ></Div>
            <Div display="flex" marginTop="16px" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)">Message</Text>
              <DocScrlDiv overflowX="auto" marginLeft="48px" maxWidth="815px">
                <Pre margin="0 0 10px 0" fontFamily="'Open Sans', sans-serif">
                  {EmailDocument.filter(
                    (ele, i) => ele.gl_name === "invoice_message"
                  ).map((ele) => ele.gl_value)}
                </Pre>
              </DocScrlDiv>
            </Div>
          </Div>
        </Div>
        <Div padding="24px 0px 0px 0px">
          <PageSubTitle color="#2C7BE5">Statements</PageSubTitle>
          <Div display="flex" marginTop="16px">
            <Div position="relative" marginRight="8px">
              <TogggleCheck
                checked={statementLogo}
                type="checkbox"
                onClick={() => setStmtLogo((prevState) => !prevState)}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </Div>
            <SpanTag fontSize="14px">Include Logo</SpanTag>
          </Div>
          <Div margin="12px 0 0 0">
            <SpanTag fontSize="14px">Footer Information</SpanTag>
            <TextArea
              width="100%"
              resize="none"
              height="60px"
              borderRadius="8px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="8px 0 0 0"
              padding="20px 16px"
              fontFamily="'Open Sans', sans-serif"
              outline="none"
              value={stmtFootInfo}
              onChange={stmtFooterChange}
            ></TextArea>
          </Div>
          <Div
            border="1px solid rgba(46, 46, 46, 0.25)"
            padding="16px 20px"
            marginTop="20px"
            borderRadius="8px"
          >
            <Div
              marginBottom="16px"
              display="flex"
              justifyContent="space-between"
            >
              <PageSubTitle
                fontFamily="'Open Sans', sans-serif"
                color="#2E2E2E"
              >
                Default Statement Email
              </PageSubTitle>
              <Button
                border="none"
                color="#2C7BE5"
                backgroundColor="unset"
                cursor="pointer"
                onClick={() => handleEdit("Statement")}
                fontWeight="600"
              >
                Edit
              </Button>
            </Div>
            <Div
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              margin="0 -20px 0 -20px"
            ></Div>
            <Div display="flex" marginTop="16px" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)" width="105px">
                From
              </Text>
              <Text color="#2E2E2E">{profileDetailData?.email}</Text>
            </Div>
            <Div display="flex" margin="16px 0 16px 0" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)">Subject</Text>
              <DocScrlDiv overflowX="auto" marginLeft="58px" maxWidth="815px">
                <Text color="#2E2E2E" margin="0 0 10px 0px">
                  {EmailDocument.filter(
                    (ele, i) => ele.gl_name === "statement_subject"
                  ).map((ele) => ele.gl_value)}
                </Text>
              </DocScrlDiv>
            </Div>
            <Div
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              margin="0 -20px 0 -20px"
            ></Div>
            <Div display="flex" marginTop="16px" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)">Message</Text>
              <DocScrlDiv overflowX="auto" marginLeft="48px" maxWidth="815px">
                <Pre margin="0 0 10px 0" fontFamily="'Open Sans', sans-serif">
                  {EmailDocument.filter(
                    (ele, i) => ele.gl_name === "statement_message"
                  ).map((ele) => ele.gl_value)}
                </Pre>
              </DocScrlDiv>
            </Div>
          </Div>
        </Div>
        <Div padding="24px 0px 0px 0px">
          <PageSubTitle color="#2C7BE5">Superbills</PageSubTitle>
          <Div display="flex" marginTop="16px">
            <Div position="relative" marginRight="8px">
              <TogggleCheck
                checked={superLogo}
                type="checkbox"
                onClick={() => setSuperLogo((prevState) => !prevState)}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </Div>
            <SpanTag fontSize="14px">Include Logo</SpanTag>
          </Div>
          <Div display="flex" marginTop="12px">
            <Div position="relative" marginRight="8px">
              <TogggleCheck
                checked={signLine}
                type="checkbox"
                onClick={() => setSignLine((prevState) => !prevState)}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </Div>
            <SpanTag fontSize="14px">Include Signature Line</SpanTag>
          </Div>
          <Div display="flex" marginTop="12px">
            <Div position="relative" marginRight="8px">
              <TogggleCheck
                checked={diagDesc}
                type="checkbox"
                onClick={() => setDiagDesc((prevState) => !prevState)}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </Div>
            <SpanTag fontSize="14px">Include Diagnosis Description</SpanTag>
          </Div>
          <Div margin="12px 0 0 0">
            <SpanTag fontSize="14px">Footer Information</SpanTag>
            <TextArea
              width="100%"
              resize="none"
              height="60px"
              borderRadius="8px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="8px 0 0 0"
              padding="20px 16px"
              fontFamily="'Open Sans', sans-serif"
              outline="none"
              value={supBillFootInfo}
              onChange={supBlFooterChange}
            ></TextArea>
          </Div>
          <Div
            border="1px solid rgba(46, 46, 46, 0.25)"
            padding="16px 20px"
            marginTop="20px"
            borderRadius="8px"
          >
            <Div
              marginBottom="16px"
              display="flex"
              justifyContent="space-between"
            >
              <PageSubTitle
                fontFamily="'Open Sans', sans-serif"
                color="#2E2E2E"
              >
                Default Superbill Email
              </PageSubTitle>
              <Button
                border="none"
                color="#2C7BE5"
                backgroundColor="unset"
                cursor="pointer"
                onClick={() => handleEdit("SuperBill")}
                fontWeight="600"
              >
                Edit
              </Button>
            </Div>
            <Div
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              margin="0 -20px 0 -20px"
            ></Div>
            <Div display="flex" marginTop="16px" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)" width="105px">
                From
              </Text>
              <Text color="#2E2E2E">{profileDetailData?.email}</Text>
            </Div>
            <Div display="flex" margin="16px 0 16px 0" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)">Subject</Text>
              <DocScrlDiv overflowX="auto" marginLeft="58px" maxWidth="815px">
                <Text color="#2E2E2E" margin="0 0 10px 0px">
                  {EmailDocument.filter(
                    (ele, i) => ele.gl_name === "superbills_subject"
                  ).map((ele) => ele.gl_value)}
                </Text>
              </DocScrlDiv>
            </Div>
            <Div
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              margin="0 -20px 0 -20px"
            ></Div>
            <Div display="flex" marginTop="16px" fontSize="14px">
              <Text color="rgba(46, 46, 46, 0.50)">Message</Text>
              <DocScrlDiv overflowX="auto" marginLeft="48px" maxWidth="815px">
                <Pre margin="0 0 10px 0" fontFamily="'Open Sans', sans-serif">
                  {EmailDocument.filter(
                    (ele, i) => ele.gl_name === "superbills_message"
                  ).map((ele) => ele.gl_value)}
                </Pre>
              </DocScrlDiv>
            </Div>
          </Div>
        </Div>
        <Div padding="24px 0px 0px 0px">
          <PageSubTitle color="#2C7BE5">Documentation</PageSubTitle>
          <Div display="flex" marginTop="16px">
            <Div position="relative" marginRight="8px">
              <TogggleCheck
                checked={docLogo}
                type="checkbox"
                onClick={() => setDocLogo((prevState) => !prevState)}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </Div>
            <SpanTag fontSize="14px">Include Logo</SpanTag>
          </Div>
          <Div margin="12px 0 0 0">
            <SpanTag fontSize="14px">Footer Information</SpanTag>
            <TextArea
              width="100%"
              resize="none"
              height="60px"
              borderRadius="8px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="8px 0 0 0"
              padding="20px 16px"
              fontFamily="Open Sans, sans-serif"
              outline="none"
              value={docFooterInfo}
              onChange={docFooterChange}
            ></TextArea>
          </Div>
        </Div>
        <Div padding="36px 0 31px 0px">
          <Button
            backgroundColor="#2C7BE5"
            color="#fff"
            border="none"
            padding="9px 19px 8px 19px"
            borderRadius="4px"
            cursor="pointer"
            fontWeight="600"
            onClick={handleSave}
            disabled={dis}
            isDisabled={dis}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Save Document
          </Button>
        </Div>
      </Div>

      <UploadProfilePicture
        show={isupload}
        close={() => setIsUpload(false)}
        setImageText={setImageText}
        setpreviewimg1={setpreviewimg1}
        setImageFile={setImageFile}
      />
      {invEdit && (
        <EditDocument
          show={invEdit}
          editType={editType}
          close={() => {
            setInvEdit(false);
            setEditType("");
          }}
        />
      )}
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};

export default Documents;
