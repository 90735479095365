import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BsXLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import axios from "axios";
import moment from "moment";
import {
  Div,
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Label,
  Input,
  TextArea,
} from "../../../StyledComponents";
import { ModalDialog } from "../../../StyledComponents/AddCard/Styles";
import {
  GetAddNotesTake,
  GetRpmSingleNote,
  GetRpmGmeetData,
  setEmptyAddNoteResponse,
  GetNotesId,
  SetEmptySingle,
} from "../../../../StateManagement/Reducers/EncounterState";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import { EncounterModalContainer, Carebutton } from "../styles";

import PhoneCallRingcentral from "../RingCentralPhoneCall/PhoneCallRingcentral";
import Twiliodialpad from "../Twiliodialpad";
import { GetClinicalDetails } from "../../../../StateManagement/Reducers/SettingState";
import DBHost from "../../../../Config/DBHost";
const Note = (props) => {
  //------------------------------------------State and Variables Start--------------------------------------//
  const [ModalAnimat] = useState(true);
  const [fromtimecount, setfromtimecount] = useState("");
  const [phoneView, setPhoneView] = useState(false);
  const [videoView, setVideoView] = useState(false);
  const [chartView, setChartView] = useState(false);
  const [startvideocall, setstartvideocall] = useState(false);
  const [stopvideocall, setstopvideocall] = useState(false);
  const [videoviewtrue, setvideoviewtrue] = useState(false);

  const [MeetingLink, setMeetingLink] = useState("");
  const [MeetingID, setMeetingID] = useState("");

  const [activityBtn, setActivityBtn] = useState("");
  const [onTimeBtn, setOnTimeBtn] = useState("");
  const [timeSpCust, setTimeSpCust] = useState("");
  const [txtAreaVal, setTxtAreaVal] = useState("");
  const [view, setView] = useState("");
  const [id, setId] = useState("0");
  const dispatch = useDispatch();
  const params = useParams();
  const user_id = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const GetSingleRpmVal = useSelector((state) => state.Encounter.SingleNote);
  const GetGmeetDataVal = useSelector((state) => state.Encounter.GmeetData);
  const AddNotes = useSelector((state) => state.Encounter.NotesTake);

  const NoteID = useSelector((state) => state.Encounter.NoteId);

  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [eidval, setEidVal] = useState("");
  const [disBtn, setDisBtn] = useState(true);
  const [dis, setDis] = useState(false);

  const [audioPlatform, setaudioPlatform] = useState("");
  const [ringCentralPhoneCall, setRingCentralPhoneCall] = useState(false);
  const [twilioCentral, SetTwilioCentral] = useState(false);
  const [dialview, setdialview] = useState("");
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  let imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const clinicalDetailData = useSelector(
    (state) => state.Setting.clinicalDetail
  );

  const patientDemotimer = useSelector(
    (state) => state.PatientDemo.patientDemotimer
  );

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  const originalDateString =
    props.clickFrom === "live"
      ? props.encountDetails.currentTime
      : props.encountDetails.date;
  var formattedDate = "";
  if (props.clickFrom === "live") {
    const originalDate = moment(originalDateString, "DD/MM/YYYY HH:mm:ss");
    formattedDate = originalDate.format("MMM DD, YYYY h:mm A");
  } else {
    formattedDate = formatDate(originalDateString);
  }
  const HandleSave = () => {
    if (stopvideocall) {
      handlePreventionClick();
      return;
    }
    // setDisBtn(true);
    setDis(true);
    dispatch(
      GetAddNotesTake({
        id: id,
        pid:
          props.clickFrom === "live" ? props.encountDetails["pid"] : params.pid,
        encounter_type: view,
        activity: activityBtn,
        timespent: timeSpCust,
        eid: props.encountDetails["encounter"]
          ? props.encountDetails["encounter"]
          : eidval,
        uid: user_id,
        notes: txtAreaVal,
      })
    );
    setTimeout(() => {
      setDis(false);
    }, 3000);
  };

  const onActiveClick = (e) => {
    if (stopvideocall) {
      handlePreventionClick();
      return;
    }
    setActivityBtn(e.target.name);
  };

  const onTimeSpentClick = (e) => {
    if (e.target.name === "custom_time_spent") {
      let a = /[0-9 ]/i.test(e.target.value);
      if ((!isNaN(e.target.value) && a) || e.target.value === "") {
        setOnTimeBtn(e.target.value);
        setTimeSpCust(e.target.value);
      }
    } else {
      setOnTimeBtn(e.target.name);
    }
  };

  const clearAllData = () => {
    if (stopvideocall) {
      handlePreventionClick();
      return;
    }
    setId("0");
    setPhoneView(false);
    setVideoView(false);
    setChartView(false);
    setActivityBtn("");
    // setOnTimeBtn("");
    setTimeSpCust("");
    setTimeSpCust("");
    setTxtAreaVal("");
    setView("");
  };
  const handlePreventionClick = () => {
    setModalAlerShow(true);
    setModalMsg("Please Stop the Video Call");
    setTimeout(() => {
      setModalMsg("");
      setModalAlerShow(false);
    }, 1500);
  };
  const handleClose = () => {
    if (stopvideocall) {
      handlePreventionClick();
    } else if (startvideocall) {
      setModalAlerShow(true);
      setModalMsg("You could not close the Note");
      setTimeout(() => {
        setModalMsg("");
        setModalAlerShow(false);
      }, 1500);
    } else if (timeSpCust !== "" && !props.encountDetails["note_id"]) {
      setModalAlerShow(true);
      setModalMsg("Please Save the Note");
      setTimeout(() => {
        setModalMsg("");
        setModalAlerShow(false);
      }, 1500);
    } else {
      props.close();
      clearAllData();
      setstopvideocall(false);
    }
  };

  const initiateInsertion = () => {
    dispatch(
      GetNotesId({
        id: 0,
        pid:
          props.clickFrom === "live" ? props.encountDetails["pid"] : params.pid,
        encounter_type: view,
        activity: activityBtn,
        timespent: timeSpCust,
        eid: props.encountDetails["encounter"]
          ? props.encountDetails["encounter"]
          : eidval,
        uid: user_id,
        notes: txtAreaVal,
      })
    );
  };
  const startVideoCall = () => {
    initiateInsertion();

    let starttimewithdate = moment().format("YYYY-MM-DD HH:mm:ss");
    setfromtimecount(starttimewithdate);
    setstartvideocall(false);
    setstopvideocall(true);
    window.open(MeetingLink, "_blank");
  };
  const stopVideoCall = () => {
    let article = {
      meetingId: MeetingID,
    };
    axios
      .get(
        `${DBHost.finalurl}/interface/customized/g-meet/index.php?action=delete`,
        { params: article }
      )
      .then((res) => {})
      .catch((err) => {
        console.log("failed");
      });
    let countstarttime = moment(fromtimecount, "YYYY-MM-DD HH:mm:ss");
    let endmeetTime = moment(); // No need to format 'moment()' here

    const duration = moment.duration(endmeetTime.diff(countstarttime));
    const totalSeconds = Math.round(duration.asSeconds());

    setTimeSpCust(totalSeconds);
    setstopvideocall(false);
  };
  const viewSelect = (view) => {
    setTimeSpCust("");
    setActivityBtn("");
    if (view === "Chart View") {
      if (
        GetGmeetDataVal?.timeSpentPat !== null &&
        GetGmeetDataVal?.timeSpentPat > 0
      ) {
        setTimeSpCust(GetGmeetDataVal.timeSpentPat);
        setId(GetGmeetDataVal.noteId);
      }
    }
    if (view === "Phone Call") {
      if (stopvideocall) {
        handlePreventionClick();
        return;
      }
      if (audioPlatform === "Ring Central") {
        setRingCentralPhoneCall(true);
      } else if (audioPlatform === "Twilio") {
        SetTwilioCentral(true);

        setdialview(dialview);
      } else {
        alert("Please Select the Valid Audio Platform in Setting menu");
      }
    }
    var EventDate = moment().format("YYYY-MM-DD");
    var StartTime = moment().format("HH:mm:ss");
    var EndTime = moment().add(1, "hour").format("HH:mm:ss");
    var FromDateTime = EventDate + "T" + StartTime;
    var EndDateTime = EventDate + "T" + EndTime;

    if (view === "Video Call" && videoView === false) {
      let article = {
        start_time: FromDateTime,
        end_time: EndDateTime,
        attendees: GetGmeetDataVal.attendees,
        aid: user_id,
        pid: params.pid,
        eventdate: EventDate,
        start: StartTime,
      };
      axios
        .get(
          `${DBHost.finalurl}/interface/customized/g-meet/index.php?action=create`,
          {
            params: article,
          }
        )
        .then((res) => {
          // window.open(res.data, "_blank");
          setMeetingLink(res.data[0]);
          setMeetingID(res.data[1]);
          console.log("g-meet");
          setstartvideocall(true);
          setvideoviewtrue(false);
        })
        .catch((err) => {
          console.log("failed");
        });
    }
    switch (view) {
      case "Phone Call":
        if (
          audioPlatform !== undefined &&
          audioPlatform !== "" &&
          audioPlatform !== "0"
        ) {
          setPhoneView(true);
          setVideoView(false);
          setChartView(false);
        }
        break;
      case "Video Call":
        setVideoView(true);
        if (videoView === false) {
          setvideoviewtrue(true);
        } else if (videoView === true) {
          setModalAlerShow(true);
          setModalMsg("You already Ended A Video Call");
          setTimeout(() => {
            setModalAlerShow(false);
            setModalMsg("");
          }, 1500);
        }

        setPhoneView(false);
        setChartView(false);
        break;
      case "Chart View":
        if (stopvideocall) {
          handlePreventionClick();
          return;
        }
        setPhoneView(false);
        setVideoView(false);
        setChartView(true);
        setActivityBtn("");
        break;
      default:
    }
    setView(view);
  };
  const handletwiloClose = () => {
    SetTwilioCentral(false);
  };
  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start--------------------------------------------------//

  useEffect(() => {
    if (
      (view && activityBtn && timeSpCust) ||
      props.encountDetails["note_id"] ||
      (view === "Chart View" && timeSpCust)
    ) {
      setDisBtn(false);
    }
  }, [view, activityBtn, timeSpCust]);

  useEffect(() => {
    if (props.encountDetails["note_id"]) {
      dispatch(
        GetRpmSingleNote({ id: props.encountDetails["note_id"], type: "id" })
      );
    }
    dispatch(GetRpmGmeetData({ uid: user_id, pid: params.pid }));
  }, []);

  useEffect(() => {
    setId(NoteID);
  }, [NoteID]);

  useEffect(() => {
    if (props.encountDetails["note_id"]) {
      setTimeout(() => {
        setId(props.encountDetails["note_id"]);
        setEidVal(GetSingleRpmVal.eid);
        setView(GetSingleRpmVal.encounter_type);
        // viewSelect(GetSingleRpmVal.encounter_type);
        setActivityBtn(GetSingleRpmVal.activity);
        // setOnTimeBtn(GetSingleRpmVal.timespent);
        setTimeSpCust(GetSingleRpmVal.timespent);

        // if (
        //   GetSingleRpmVal.timespent !== "2" &&
        //   GetSingleRpmVal.timespent !== "5" &&
        //   GetSingleRpmVal.timespent !== "10" &&
        //   GetSingleRpmVal.timespent !== "20"
        // ) {
        //   setTimeSpCust(GetSingleRpmVal.timespent);
        // } else {
        //   setTimeSpCust("");
        // }
        setTxtAreaVal(GetSingleRpmVal.notes);
        if (GetSingleRpmVal.encounter_type === "Video Call") setVideoView(true);
        else if (GetSingleRpmVal.encounter_type === "Phone Call")
          setPhoneView(true);
        else if (GetSingleRpmVal.encounter_type === "Chart View")
          setChartView(true);
      }, 200);
    } else {
      if (props.clickFrom) {
        props.clickFrom === "visitEncounter" &&
          setEidVal(props.encountDetails["encounter"]);
        props.clickFrom === "live" &&
          setEidVal(props.encountDetails["recent_encounter"]);
      }
    }

    // clearAllData();
  }, [GetSingleRpmVal]);

  useEffect(() => {
    if (!props.encountDetails["note_id"]) {
      dispatch(SetEmptySingle());
    }
  }, []);

  useEffect(() => {
    if (AddNotes != "") {
      if (AddNotes === "success" && id === "0") {
        setModalAlerShow(true);
        setModalMsg("note added successfully");
        setStatus("success");
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          props.close();
          dispatch(setEmptyAddNoteResponse());
        }, 2000);
      } else if (AddNotes === "success" && id !== "0") {
        setModalAlerShow(true);
        setModalMsg("note updated successfully");
        setStatus("success");
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          props.close();
          dispatch(setEmptyAddNoteResponse());
        }, 2000);
      }
    }
  }, [AddNotes]);
  useEffect(() => {
    if (
      (clinicalDetailData !== undefined || null) &&
      clinicalDetailData.length > 0
    ) {
      setaudioPlatform(clinicalDetailData[2].gl_value);
    }
  }, [clinicalDetailData]);
  useEffect(() => {
    dispatch(GetClinicalDetails(user_id));
  }, []);
  //------------------------------------------UseEffect End--------------------------------------------------//

  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        width="100%"
        height="100%"
        onClick={handleClose}
      >
        <EncounterModalContainer
          width="51.035%"
          justifyContent="center"
          overflowY="hidden"
          borderRadius="8px"
          onClick={handleContainerClick}
        >
          <ModalDialog
            animation={ModalAnimat}
            width="100%"
            className="dialog_fixed"
          >
            <ModalHeader
              padding="19px 25px 16px 25px"
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="30px"
                fontSize="16px"
                fontWeight="600"
                color="#2E2E2E"
              >
                RPM Notes
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#2E2E2E",
                  cursor: "pointer",
                  height: "24px",
                  width: "24px",
                  background: "rgba(46, 46, 46, 0.1)",
                  padding: "6px",
                  borderRadius: "50%",
                }}
                id="dbrd_ad-crd_cls"
                onClick={handleClose}
              />
            </ModalHeader>

            <ModalBody
              padding="16px 25px 0px 25px"
              minHeight={phoneView || videoView || chartView ? "0px" : "395px"}
            >
              <Div
                borderRadius="50%"
                display="flex"
                marginRight="23px"
                fontSize="30px"
                fontWeight="600"
              >
                {SingPatDetails.img_url !== null ? (
                  <Image
                    width="75px"
                    height="75px"
                    borderRadius="50%"
                    src={imageUrl + SingPatDetails.img_url}
                  ></Image>
                ) : (
                  <Div
                    width="50px"
                    height="50px"
                    borderRadius="50%"
                    background="rgb(168, 174, 190)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    fontFamily="'Open Sans',sans-serif"
                    color="#FFFFFF"
                  >
                    {SingPatDetails.patname.charAt(0).toUpperCase()}
                  </Div>
                )}
                <Div
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  justifyContent="center"
                  marginLeft="16px"
                >
                  <Label size="14px" weight="400" display="block">
                    {props.clickFrom === "live"
                      ? props.encountDetails["patName"]
                      : props.encountDetails["patname"]}
                  </Label>
                  <Label
                    size="14px"
                    weight="400"
                    display="block"
                    marginTop="8px"
                  >
                    {props.clickFrom === "live" ? formattedDate : formattedDate}
                  </Label>
                </Div>
              </Div>
              <Div display="block" marginTop="40px" padding="0 0 0 0">
                <Div float="left" width="115px">
                  <Label
                    size="14px"
                    weight="400"
                    display="block"
                    marginTop="8px"
                  >
                    Encounter Type :
                  </Label>
                </Div>
                <Div className="activ-response">
                  <Button
                    cursor="pointer"
                    backgroundColor={phoneView ? "#F1F5F8" : "#fff"}
                    borderRadius="4px"
                    border={
                      phoneView ? "none" : "1px solid rgba(46, 46, 46, 0.25)"
                    }
                    color={phoneView ? "#2C7BE5" : "#2e2e2e"}
                    padding="10px 15px"
                    margin="0 0 0 0px"
                    className="bgFFF respBtn"
                    width="100px"
                    onClick={() => {
                      viewSelect("Phone Call");
                    }}
                    disabled={
                      videoviewtrue ||
                      timeSpCust !== "" ||
                      startvideocall ||
                      props.encountDetails["note_id"]
                    }
                  >
                    Phone Call
                  </Button>
                  {stopvideocall ? (
                    <Button
                      cursor="pointer"
                      backgroundColor={"#dc3545"}
                      borderRadius="4px"
                      border={
                        videoView ? "none" : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={"#fff"}
                      padding="10px 15px"
                      margin="0 0 0 8px"
                      width="130px"
                      onClick={() => {
                        stopVideoCall("Video Call");
                      }}
                      disabled={timeSpCust !== ""}
                    >
                      Stop Video Call
                    </Button>
                  ) : startvideocall ? (
                    <Button
                      cursor="pointer"
                      backgroundColor={"#28a745"}
                      borderRadius="4px"
                      border={
                        videoView ? "none" : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={"#fff"}
                      padding="10px 15px"
                      margin="0 0 0 8px"
                      width="130px"
                      onClick={() => {
                        startVideoCall("Video Call");
                      }}
                      disabled={timeSpCust !== ""}
                    >
                      Start Video Call
                    </Button>
                  ) : (
                    <Button
                      cursor="pointer"
                      backgroundColor={videoView ? "#F1F5F8" : "#fff"}
                      borderRadius="4px"
                      border={
                        videoView ? "none" : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={videoView ? "#2C7BE5" : "#2e2e2e"}
                      disabled={
                        videoviewtrue ||
                        timeSpCust !== "" ||
                        props.encountDetails["note_id"]
                      }
                      padding="10px 15px"
                      margin="0 0 0 8px"
                      className="bgFFF respBtn"
                      width="100px"
                      onClick={() => {
                        viewSelect("Video Call");
                      }}
                    >
                      Video Call
                    </Button>
                  )}
                  <Button
                    cursor="pointer"
                    backgroundColor={chartView ? "#F1F5F8" : "#fff"}
                    borderRadius="4px"
                    border={
                      chartView ? "none" : "1px solid rgba(46, 46, 46, 0.25)"
                    }
                    color={chartView ? "#2C7BE5" : "#2e2e2e"}
                    padding="10px 15px"
                    margin="0 0 0 8px"
                    className="bgFFF respBtn"
                    width="100px"
                    onClick={() => {
                      viewSelect("Chart View");
                    }}
                    disabled={
                      videoviewtrue ||
                      timeSpCust !== "" ||
                      startvideocall ||
                      props.encountDetails["note_id"]
                    }
                  >
                    Chart View
                  </Button>
                </Div>
              </Div>
              {phoneView || videoView ? (
                <Div
                  display="block"
                  marginTop="24px"
                  className="activityResponsive"
                >
                  <Div float="left" width="115px">
                    <Label
                      size="14px"
                      weight="400"
                      display="inline-block"
                      marginTop="8px"
                    >
                      Activity :
                    </Label>
                  </Div>
                  <Div className="activ-response">
                    <Button
                      cursor="pointer"
                      backgroundColor={
                        activityBtn === "Review PGHD" ? "#F1F5F8" : "#fff"
                      }
                      borderRadius="4px"
                      border={
                        activityBtn === "Review PGHD"
                          ? "none"
                          : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={
                        activityBtn === "Review PGHD" ? "#2C7BE5" : "#2e2e2e"
                      }
                      padding="10px 15px"
                      margin="0 0 0 0px"
                      width="120px"
                      className="bgFFF respBtn"
                      onClick={(e) => onActiveClick(e)}
                      name="Review PGHD"
                    >
                      Review PGHD
                    </Button>
                    <Button
                      cursor="pointer"
                      backgroundColor={
                        activityBtn === "Return Call" ? "#F1F5F8" : "#fff"
                      }
                      borderRadius="4px"
                      border={
                        activityBtn === "Return Call"
                          ? "none"
                          : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={
                        activityBtn === "Return Call" ? "#2C7BE5" : "#2e2e2e"
                      }
                      padding="10px 15px"
                      margin="0 0 0 8px"
                      width="100px"
                      className="bgFFF respBtn"
                      onClick={(e) => onActiveClick(e)}
                      name="Return Call"
                    >
                      Return Call
                    </Button>
                    <Button
                      cursor="pointer"
                      backgroundColor={
                        activityBtn === "Medication Refill" ? "#F1F5F8" : "#fff"
                      }
                      borderRadius="4px"
                      border={
                        activityBtn === "Medication Refill"
                          ? "none"
                          : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={
                        activityBtn === "Medication Refill"
                          ? "#2C7BE5"
                          : "#2e2e2e"
                      }
                      padding="10px 15px"
                      margin="0 0 0 8px"
                      width="144px"
                      className="bgFFF respBtn"
                      onClick={(e) => onActiveClick(e)}
                      name="Medication Refill"
                    >
                      Medication Refill
                    </Button>
                    <Button
                      cursor="pointer"
                      backgroundColor={
                        activityBtn === "Lab Orders" ? "#F1F5F8" : "#fff"
                      }
                      borderRadius="4px"
                      border={
                        activityBtn === "Lab Orders"
                          ? "none"
                          : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={
                        activityBtn === "Lab Orders" ? "#2C7BE5" : "#2e2e2e"
                      }
                      padding="10px 15px"
                      margin="0 0 0 8px"
                      width="100px"
                      className="bgFFF respBtn"
                      onClick={(e) => onActiveClick(e)}
                      name="Lab Orders"
                    >
                      Lab Orders
                    </Button>
                    <Carebutton
                      cursor="pointer"
                      backgroundColor={
                        activityBtn === "Careplan Update" ? "#F1F5F8" : "#fff"
                      }
                      borderRadius="4px"
                      border={
                        activityBtn === "Careplan Update"
                          ? "none"
                          : "1px solid rgba(46, 46, 46, 0.25)"
                      }
                      color={
                        activityBtn === "Careplan Update"
                          ? "#2C7BE5"
                          : "#2e2e2e"
                      }
                      padding="10px 15px"
                      width="144px"
                      className="bgFFF respBtn"
                      onClick={(e) => onActiveClick(e)}
                      name="Careplan Update"
                    >
                      Careplan Update
                    </Carebutton>
                  </Div>
                </Div>
              ) : (
                ""
              )}
              {chartView || phoneView || videoView ? (
                <>
                  <Div display="block" marginTop="24px">
                    <Div float="left" width="115px">
                      <Label
                        size="14px"
                        weight="400"
                        display="block"
                        marginTop="8px"
                      >
                        Time Spent :
                      </Label>
                    </Div>
                    <Div className="activ-response">
                      {/* <Button
                        cursor="pointer"
                        backgroundColor={onTimeBtn === "2" ? "#F1F5F8" : "#fff"}
                        borderRadius="4px"
                        border={
                          onTimeBtn === "2"
                            ? "none"
                            : "1px solid rgba(46, 46, 46, 0.25)"
                        }
                        color={onTimeBtn === "2" ? "#2C7BE5" : "#2e2e2e"}
                        padding="10px 15px"
                        margin="0 0 0 0"
                        width="80px"
                        className="bgFFF respBtnTimeSp"
                        onClick={(e) => onTimeSpentClick(e)}
                        name="2"
                      >
                        2 min
                      </Button>
                      <Button
                        cursor="pointer"
                        backgroundColor={onTimeBtn === "5" ? "#F1F5F8" : "#fff"}
                        borderRadius="4px"
                        border={
                          onTimeBtn === "5"
                            ? "none"
                            : "1px solid rgba(46, 46, 46, 0.25)"
                        }
                        color={onTimeBtn === "5" ? "#2C7BE5" : "#2e2e2e"}
                        padding="10px 15px"
                        margin="0 0 0 8px"
                        width="80px"
                        className="bgFFF respBtnTimeSp"
                        onClick={(e) => onTimeSpentClick(e)}
                        name="5"
                      >
                        5 min
                      </Button>
                      <Button
                        cursor="pointer"
                        backgroundColor={
                          onTimeBtn === "10" ? "#F1F5F8" : "#fff"
                        }
                        borderRadius="4px"
                        border={
                          onTimeBtn === "10"
                            ? "none"
                            : "1px solid rgba(46, 46, 46, 0.25)"
                        }
                        color={onTimeBtn === "10" ? "#2C7BE5" : "#2e2e2e"}
                        padding="10px 15px"
                        margin="0 0 0 8px"
                        width="80px"
                        className="bgFFF respBtnTimeSp"
                        onClick={(e) => onTimeSpentClick(e)}
                        name="10"
                      >
                        10 min
                      </Button>
                      <Button
                        cursor="pointer"
                        backgroundColor={
                          onTimeBtn === "20" ? "#F1F5F8" : "#fff"
                        }
                        borderRadius="4px"
                        border={
                          onTimeBtn === "20"
                            ? "none"
                            : "1px solid rgba(46, 46, 46, 0.25)"
                        }
                        color={onTimeBtn === "20" ? "#2C7BE5" : "#2e2e2e"}
                        padding="10px 15px"
                        margin="0 0 0 8px"
                        width="80px"
                        className="bgFFF respBtnTimeSp"
                        onClick={(e) => onTimeSpentClick(e)}
                        name="20"
                      >
                        20 min
                      </Button> */}
                      <Input
                        borderRight="none"
                        width="95px"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        marginLeft="0px"
                        paddingLeft="18px"
                        paddingRight="18px"
                        paddingTop="10px"
                        paddingBottom="10px"
                        btrr="0px"
                        bbrr="0px"
                        disabled={
                          props.encountDetails["note_id"] === undefined &&
                          GetSingleRpmVal.timespent === undefined
                        }
                        type="text"
                        name="custom_time_spent"
                        className="respBtnTimeSp"
                        onChange={(e) => onTimeSpentClick(e)}
                        value={timeSpCust}
                        color="#2C7BE5"
                        autoComplete={"off"}
                      ></Input>{" "}
                      <Input
                        width="55px"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        marginLeft="-50px"
                        paddingLeft="0px"
                        paddingRight="0px"
                        paddingTop="10px"
                        paddingBottom="10px"
                        borderLeft="none"
                        btlr="0px"
                        bblr="0px"
                        value={
                          timeSpCust === 1 || timeSpCust === "1"
                            ? "Second"
                            : timeSpCust > 1 || timeSpCust > "1"
                            ? "Seconds"
                            : ""
                        }
                        disabled={
                          props.encountDetails["note_id"] === undefined &&
                          GetSingleRpmVal.timespent === undefined
                        }
                        color="#2C7BE5"
                        readOnly={true}
                      ></Input>
                    </Div>
                  </Div>
                  <Div display="flex" marginTop="24px">
                    <TextArea
                      width="764px"
                      height="148px"
                      resize="none"
                      padding="10px 0 0 15px"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      placeholder="Type your Notes here!"
                      outline="none"
                      value={txtAreaVal}
                      onChange={(e) => {
                        if (stopvideocall) {
                          handlePreventionClick();
                          return;
                        }
                        setTxtAreaVal(e.target.value);
                      }}
                    ></TextArea>
                  </Div>
                </>
              ) : (
                ""
              )}
            </ModalBody>

            <ModalFooter padding="24px 24px 29px 24px" marginTop="24px">
              <Div
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Div>
                  <Button
                    color="#FFFFFF"
                    height="36px"
                    cursor="pointer"
                    width="100px"
                    background="#2C7BE5"
                    borderRadius="4px"
                    padding="9px 8px"
                    border="none !important"
                    fontWeight="600"
                    onClick={() => HandleSave()}
                    disabled={disBtn || dis}
                    // disabled={
                    //   phoneView || videoView || chartView
                    //     ? (timeSpCust === "" &&
                    //         GetSingleRpmVal.timespent === "") ||
                    //       activityBtn === "" ||
                    //       disBtn
                    //     : (timeSpCust === "" &&
                    //         GetSingleRpmVal.timespent === "") ||
                    //       disBtn ||
                    //       dis
                    // }
                    isDisabled={dis}
                    id="bil_ad-btn_crdSv"
                    backgroundColor="#2C7BE5"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  >
                    Confirm
                  </Button>
                  <Button
                    color="#2C7BE5"
                    cursor="pointer"
                    height="36px"
                    background="#FFF"
                    width="100px"
                    fontWeight="600"
                    borderRadius="4px"
                    border="1px solid #2C7BE5"
                    marginLeft="16px"
                    onClick={handleClose}
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                    disabled={
                      startvideocall ||
                      (timeSpCust !== "" && !props.encountDetails["note_id"])
                    }
                  >
                    Cancel
                  </Button>
                </Div>
                <Div>
                  <Button
                    color="#2C7BE5"
                    cursor="pointer"
                    height="36px"
                    background="#FFF"
                    width="100px"
                    fontWeight="600"
                    borderRadius="4px"
                    border="1px solid #2C7BE5"
                    onClick={() => clearAllData()}
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                    disabled={startvideocall || timeSpCust !== ""}
                  >
                    Clear
                  </Button>
                </Div>
              </Div>
            </ModalFooter>
          </ModalDialog>
        </EncounterModalContainer>
      </Modal>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}

      {ringCentralPhoneCall && (
        <PhoneCallRingcentral
          ringCentralPhoneCall={ringCentralPhoneCall}
          phone={SingPatDetails.phone_cell}
          view={view}
          closeBtn={() => {
            setRingCentralPhoneCall(false);
          }}
          phoneView={() => setPhoneView(false)}
        />
      )}
      {twilioCentral && (
        <Twiliodialpad
          initiateInsertion={initiateInsertion}
          setDuration={setTimeSpCust}
          dialview={dialview}
          close={handletwiloClose}
        />
      )}
    </>
  );
};

export default Note;
