import { takeLatest } from "redux-saga/effects";
import {
  GetInitial,
  GetLoginTime,
  GetchartData,
} from "../../StateManagement/Reducers/DashboardState";
import { getAllChartData, getLoginTimeApi } from "./DashboardSagaWorkers";
import { InitialLogCheck_Api } from "../Apis/DashboardApi";

export function* ChartList() {
  yield takeLatest(GetchartData.type, getAllChartData);
}
export function* LoginTime() {
  yield takeLatest(GetLoginTime.type, getLoginTimeApi);
}

export function* InitialLogin() {
  yield takeLatest(GetInitial.type, InitialLogCheck_Api);
}
