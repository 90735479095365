import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import EmptyLive from "../../Assets/images/EmptyLive.png";
import {
  Button,
  Div,
  FormLabel,
  Image,
  PageTitle,
  Span,
  SpanTag,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  Tablerow,
} from "../StyledComponents";
import { Arrow } from "./Styles";
import AccessDenied from "../AccessDenied";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const LiveDashboard = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const vitalsData = useSelector((state) => state.Dashboard.chartData);
  const Navigate = useNavigate();
  const [vitals, setVitals] = useState([]);
  const emptyRows = Math.max(0, 5 - vitals.length);
  const EmptyRowStyle = {
    height: "42px",
  };
  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      patname: "",
      providerName: "",
      pulse: "emptyrow",
      Respiration: "emptyrow",
      bps_1: "emptyrow",
      bps_2: "emptyrow",
      oxygen_saturation: "emptyrow",
      BloodGlucoseLevel: "emptyrow",
    })
  );
  const rowsToShow = [...vitals, ...emptyRowPlaceholders];
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //
  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const NavigateToLive = () => {
    Navigate("/Live");
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    if (vitalsData) {
      setVitals(vitalsData.vitals ?? []);
    }
  }, []);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //
  return (
    <>
      <Div
        gridArea="LiveDashboard"
        width="100%"
        border="1px solid rgba(46, 46, 46, 0.25)"
        borderRadius="8px"
        backgroundColor="#fff"
        height="100%"
      >
        <Div
          padding="0px 0px 14px 0px"
          display="flex"
          paddingTop="12px"
          paddingLeft="16px"
          alignItems="center"
        >
          <PageTitle
            textAlign="left"
            fontWeight="600"
            fontSize="18px"
            lineHeight="24px"
            color="#2E2E2E"
          >
            Live
          </PageTitle>
          <Button
            border="none"
            fontWeight="400"
            fontSize="14px"
            color=" #2C7BE5"
            lineHeight="normal"
            cursor="pointer"
            onClick={NavigateToLive}
            display="flex"
            marginLeft="auto"
            paddingLeft="20px"
            backgroundColor="transparent"
          >
            <Span> View All</Span>
            <Arrow cursor="pointer"></Arrow>
          </Button>
        </Div>
        {permissions.includes("live_access") ? (
          <Div
            backgroundColor="#FFFFFF"
            borderBottomLeftRadius="8px"
            borderBottomRightRadius="8px"
            // maxWidth="1000px"
            overflowX="auto"
          >
            <Table border="none" tableLayout="fixed">
              <TableHead background="#F1F5F8">
                <Tablerow
                  color="#2C7BE5"
                  fontWeight="400"
                  fontSize="14px"
                  padding="15px 16px 11px 16px"
                >
                  <TableHeadCell padding="15px 2px 11px 16px !important">
                    Patient Name
                  </TableHeadCell>
                  <TableHeadCell>Provider</TableHeadCell>
                  <TableHeadCell>Heart</TableHeadCell>
                  <TableHeadCell>Respiration</TableHeadCell>
                  <TableHeadCell>BP</TableHeadCell>
                  <TableHeadCell>Oxygen</TableHeadCell>
                  <TableHeadCell>Glucose</TableHeadCell>
                </Tablerow>
              </TableHead>
              <TableBody>
                {vitals.length !== 0 ? (
                  rowsToShow.map((details, index) => (
                    <Tablerow
                      key={index}
                      borderBottom="1px solid #E2E8ED"
                      fontWeight="400"
                      fontSize="14px"
                      padding="18px 20px 19px 16px"
                    >
                      <TableBodyCell color="black">
                        {details.patName}
                      </TableBodyCell>
                      <TableBodyCell>{details.providerName}</TableBodyCell>
                      <TableBodyCell
                        color={
                          details.pulse
                            ? parseInt(details.pulse) >= 60 &&
                              parseInt(details.pulse) <= 100
                              ? "#2e2e2e"
                              : parseInt(details.pulse) >= 50 &&
                                parseInt(details.pulse) <= 59
                              ? "#FFC700"
                              : parseInt(details.pulse) >= 101 &&
                                parseInt(details.pulse) <= 120
                              ? "#FFC700"
                              : parseInt(details.pulse) < 50 ||
                                parseInt(details.pulse) > 120
                              ? "#F24822"
                              : "#2e2e2e"
                            : "#2e2e2e"
                        }
                      >
                        {details.pulse &&
                        details.pulse !== undefined &&
                        details.pulse !== ""
                          ? details.pulse === "emptyrow"
                            ? " "
                            : `${details.pulse}BPM`
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell
                        style={details.Respiration ? null : EmptyRowStyle}
                        color={
                          details.Respiration
                            ? parseInt(details.Respiration) >= 12 &&
                              parseInt(details.Respiration) <= 20
                              ? "#2e2e2e"
                              : parseInt(details.Respiration) >= 9 &&
                                parseInt(details.Respiration) <= 11
                              ? "#FFC700"
                              : parseInt(details.Respiration) >= 21 &&
                                parseInt(details.Respiration) <= 23
                              ? "#FFC700"
                              : parseInt(details.Respiration) < 9 ||
                                parseInt(details.Respiration) > 23
                              ? "#F24822"
                              : "#F24822"
                            : "#2e2e2e"
                        }
                      >
                        {details.Respiration &&
                        details.Respiration !== undefined &&
                        details.Respiration !== ""
                          ? details.Respiration === "emptyrow"
                            ? " "
                            : `${details.Respiration}RPM`
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell
                        color={
                          details.bps_1 && details.bps_2
                            ? parseInt(details.bps_1) >= 130 ||
                              parseInt(details.bps_2) >= 90
                              ? "#F24822" // Critical
                              : (parseInt(details.bps_1) >= 121 &&
                                  parseInt(details.bps_1) <= 129) ||
                                (parseInt(details.bps_2) >= 81 &&
                                  parseInt(details.bps_2) <= 89)
                              ? "#FFC700" // Abnormal
                              : parseInt(details.bps_1) >= 90 &&
                                parseInt(details.bps_1) <= 120 &&
                                parseInt(details.bps_2) >= 60 &&
                                parseInt(details.bps_2) <= 80
                              ? "#2e2e2e" // Normal
                              : "#2e2e2e"
                            : "#2e2e2e"
                        }
                      >
                        {details.bps_1 &&
                        details.bps_2 &&
                        details.bps_1 !== "" &&
                        details.bps_2 !== ""
                          ? details.bps_1 === "emptyrow" &&
                            details.bps_2 === "emptyrow"
                            ? " "
                            : `${details.bps_1}/${details.bps_2}mm Hg`
                          : "-"}
                      </TableBodyCell>

                      <TableBodyCell
                        paddingLeft="2px"
                        color={
                          details.oxygen_saturation
                            ? parseInt(details.oxygen_saturation) <= 90 ||
                              parseInt(details.oxygen_saturation) > 100
                              ? "#F24822" // Critical
                              : parseInt(details.oxygen_saturation) >= 91 &&
                                parseInt(details.oxygen_saturation) <= 94
                              ? "#FFC700" // Abnormal
                              : parseInt(details.oxygen_saturation) >= 95 &&
                                parseInt(details.oxygen_saturation) <= 100
                              ? "#2e2e2e" // Normal
                              : "#2e2e2e"
                            : "#2e2e2e"
                        }
                      >
                        {details.oxygen_saturation &&
                        details.oxygen_saturation !== undefined &&
                        details.oxygen_saturation !== ""
                          ? details.oxygen_saturation === "emptyrow"
                            ? " "
                            : `${details.oxygen_saturation} %`
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell>
                        <SpanTag
                          color={
                            details.BloodGlucoseLevel
                              ? parseInt(details.BloodGlucoseLevel) >= 120 &&
                                parseInt(details.BloodGlucoseLevel) <= 140
                                ? "#2e2e2e"
                                : parseInt(details.BloodGlucoseLevel) >= 141 &&
                                  parseInt(details.BloodGlucoseLevel) <= 180
                                ? "#FFC700"
                                : parseInt(details.BloodGlucoseLevel) > 180
                                ? "#F24822"
                                : "#F24822"
                              : "#2e2e2e"
                          }
                        >
                          {details.BloodGlucoseLevel &&
                          details.BloodGlucoseLevel !== undefined &&
                          details.BloodGlucoseLevel !== ""
                            ? details.BloodGlucoseLevel === "emptyrow"
                              ? " "
                              : `${details.BloodGlucoseLevel} mg/dl`
                            : "-"}
                        </SpanTag>
                      </TableBodyCell>
                    </Tablerow>
                  ))
                ) : (
                  <Tablerow>
                    <TableBodyCell
                      textAlign="center"
                      colSpan={7}
                      padding="20px"
                    >
                      <Div padding="20px">
                        <Image
                          height="208px"
                          width="229px"
                          src={EmptyLive}
                        ></Image>
                      </Div>
                      <FormLabel
                        fontSize="18px"
                        color="#000000"
                        lineHeight="25px"
                        fontWeight="400"
                      >
                        No readings has been recorded to show in the live screen
                      </FormLabel>
                    </TableBodyCell>
                  </Tablerow>
                )}
              </TableBody>
            </Table>
          </Div>
        ) : (
          <AccessDenied width="350px" height="350px" />
        )}
      </Div>
    </>
  );
};

export default LiveDashboard;
