import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsXLg } from "react-icons/bs";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { GetCardDetails } from "../../../StateManagement/Reducers/PatientState";

import {
  Div,
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormLabel,
  Button,
  RedStar,
  Switch,
  Toggle,
  Slider,
} from "../../StyledComponents";
import {
  ModalDialog,
  Input,
  AdCardNum,
  ErrorMessage,
  CardWrapper,
} from "./Styles";
import {
  AddCardDetails,
  setEmptyAddcardResponse,
  setEmptyUpdatecardResponse,
  setEmptyDeletecardResponse,
  RemoveCardDetail,
  EditCardDetails,
} from "../../../StateManagement/Reducers/PatientState";

import { useParams } from "react-router-dom";
const stripePromise = loadStripe(
  "pk_test_51Kpt0CDQnQ7Xe39UCgSDvVss5L488O4dzesinsptZUGiA2EAqZkrGmeiBx8bILLNMOJBcjDaioxBjubljNkifP3P00Bzc9db8K"
);

const AddCardData = (props) => {
  //------------------------------------------State and Variables Start--------------------------------------//
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const params = useParams();
  const patId = params.pid;

  const [nameErr, setNameErr] = useState(false);
  const [noErr, setNoErr] = useState(false);
  const [cvvErr, setCvvErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [cardType, setCardType] = useState("");
  const [pageTitle, setPageTitle] = useState("Add a Card");
  const [saveButtonTxt, setSaveButtonTxt] = useState("Add Card");

  const [CardHolderName, setCardHolderName] = useState("");
  const [CardNumber, setCardNumber] = useState("");
  const [CVV, setCVV] = useState("");
  const [ExpDate, setExpDate] = useState("");
  const [ModalAnimat] = useState(true);
  const [btnDisable, setBtnDis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [DefaultCard, setDefaultCard] = useState(false);
  const [DefaultCardVal, setDefaultCardVal] = useState(0);

  const AddCardResponseData = useSelector(
    (state) => state.Patient.AddcardResponse
  );
  const UpdateCardResponseData = useSelector(
    (state) => state.Patient.UpdatecardResponse
  );
  const DeleteCardResponseData = useSelector(
    (state) => state.Patient.DeletecardResponse
  );

  const numcard = useSelector((state) => state.Patient.CardDetail).length;
  const inputStyle = {
    border: "1px solid black",
    color: "black",
    fontWeight: "500",
    fontFamily: "Open Sans,sans-serif",
    fontSize: "14px",

    "::placeholder": {
      color: "rgb(113, 128, 150, 0.4)",
    },
  };
  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//

  const HandleClose = () => {
    props.HandleModal();
    setNameErr(false);
    setNoErr(false);
    setCvvErr(false);
    setDateErr(false);
    setCardHolderName("");
    setCardNumber("");
    setCVV("");
    setExpDate("");
    setCardType("");
    setDefaultCard(false);
    setDefaultCardVal(0);
    setBtnDis(false);
  };

  const handleClear = () => {
    const cardElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    setTimeout(() => {
      setCardHolderName("");
    }, 300);
    cardElement !== null && cardElement.clear();
    cardCvcElement !== null && cardCvcElement.clear();
    cardExpiryElement !== null && cardExpiryElement.clear();
  };
  const cardNoChange = (cardno) => {
    if (cardno.brand) {
      setCardType(cardno.brand);
    } else {
      setCardType("none");
    }
  };

  const NameChange = (value) => {
    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setCardHolderName(value);
      setNameErr(false);
    }
  };
  const expiredDateChange = (e) => {
    let expiry = e.target.value.replace(/^(\d\d)(\d)$/g, "$1/$2");

    const expiryMonth = Number(expiry.substr(0, 2));
    const expiryYear = Number(expiry.substr(3, 2));

    if (!isNaN(expiry) || (expiry.charAt(2) === "/" && expiry.length <= 5)) {
      if (!isNaN(expiryYear)) {
        setExpDate(expiry);
      }
    }

    let currentYear = Number(new Date().getFullYear().toString().substr(-2));
    let currentMonth = Number(new Date().getMonth() + 1);
    if (expiryYear < currentYear) {
      setDateErr(true);
    } else if (currentYear === expiryYear && currentMonth > expiryMonth) {
      setDateErr(true);
    } else if (expiryMonth > 12) {
      setDateErr(true);
    } else {
      setDateErr(false);
    }
  };
  const RemoveCard = () => {
    let confirm = window.confirm("Are you sure delete this card ?");
    if (confirm === true) {
      dispatch(RemoveCardDetail({ card_id: props.row.id, pid: patId }));
    }
  };

  const HandleSave = async () => {
    if (!stripe || !elements) {
      return;
    }
    var err = 0;

    if (CardHolderName === "") {
      setNameErr(true);
      err = 2;
    }

    if (dateErr) {
      err = 2;
    }

    if (err > 0) {
      return false;
    }
    setBtnDis(true);
    const cardElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    const cardData = {
      name: CardHolderName,
    };

    if (props.row.id) {
      let exp = ExpDate.split("/");
      let article = {
        row_id: props.row.id,
        card_holder: CardHolderName,
        card_no: CardNumber,
        month: exp[0],
        year: exp[1],
        cvv: CVV,
        pid: patId,
        DefaultCardVal,
      };

      dispatch(EditCardDetails(article));
    } else {
      await stripe
        .createToken(cardElement, cardData)
        .then(async (result) => {
          if (result.error && result.error.message) {
            setStatus("");
            setModalMsg(result.error.message);
            setModalAlerShow(true);
            setTimeout(() => {
              setModalAlerShow(false);
              setModalMsg("");
            }, 3000);
            setBtnDis(false);
          } else {
            await stripe
              .createPaymentMethod({
                type: "card",
                card: cardElement,
              })
              .then((res) => {
                if (res.error && res.error.message) {
                  setStatus("");
                  setModalMsg("Failed to add card, Try again later");
                  setModalAlerShow(true);
                  setTimeout(() => {
                    setModalAlerShow(false);
                    setModalMsg("");
                  }, 3000);
                  setBtnDis(false);
                  setBtnDis(false);
                } else {
                  let article = {
                    patId,
                    CardHolderName,
                    CardNumber,
                    CVV,
                    DefaultCardVal,
                    ExpDate,
                    token: result.token.id,
                    paymentMethod: res.paymentMethod.id,
                    month: res.paymentMethod.card.exp_month,
                    year: res.paymentMethod.card.exp_year,
                  };

                  if (result.error && result.error.message) {
                    //how error message
                  } else {
                    dispatch(AddCardDetails(article));
                  }
                }
              })
              .catch((err) => {
                console.error(err.message);
              });
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  };

  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start--------------------------------------------------//

  useEffect(() => {
    if (AddCardResponseData !== "") {
      if (AddCardResponseData.includes("success")) {
        setStatus("success");
        setModalMsg("Card Details Added Successfully");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          HandleClose();
          handleClear();
        }, 3000);
      } else if (AddCardResponseData.includes("failure")) {
        setStatus("");
        setModalMsg("Failed to add card, Try again later");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 3000);
      } else if (AddCardResponseData.includes("Duplicate")) {
        setStatus("");
        setModalMsg("Another Card with this number already exist");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 3000);
      } else if (
        AddCardResponseData.includes("Your card number is incorrect")
      ) {
        setStatus("");
        setModalMsg("Your card number is incorrect");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);

          setModalMsg("");
        }, 3000);
      }

      dispatch(GetCardDetails(patId));
      dispatch(setEmptyAddcardResponse());
    }
  }, [AddCardResponseData]);

  useEffect(() => {
    if (UpdateCardResponseData !== "") {
      if (UpdateCardResponseData.includes("success")) {
        setStatus("success");
        setModalMsg("Card Details Updated Successfully");
        setModalAlerShow(true);
        HandleClose();
        handleClear();
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 3000);
      } else if (
        UpdateCardResponseData.includes(
          "Your card's expiration year is invalid."
        )
      ) {
        setStatus("");
        setModalMsg("Your card's expiration year is invalid.");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);

          setModalMsg("");
        }, 3000);
      }

      dispatch(GetCardDetails(patId));
      dispatch(setEmptyUpdatecardResponse());
    }
  }, [UpdateCardResponseData]);
  useEffect(() => {
    if (DeleteCardResponseData !== "") {
      if (DeleteCardResponseData.includes("success")) {
        setStatus("success");
        setModalMsg("Card Details Removed Successfully");
        setModalAlerShow(true);
        handleClear();
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 3000);
      } else if (DeleteCardResponseData.includes("customer is not there")) {
        setStatus();
        setModalMsg("Customer Is Not There");
        setModalAlerShow(true);
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 3000);
      }

      dispatch(GetCardDetails(patId));
      HandleClose();
      dispatch(setEmptyDeletecardResponse());
    }
  }, [DeleteCardResponseData]);

  useEffect(() => {
    if (props.edit) {
      setPageTitle("Edit a Card");
      setSaveButtonTxt("Update");
      setCardHolderName(props.row.name);
      setCardNumber(props.row.number);
      setCVV(props.row.cvv);
      setExpDate(props.row.valid);
      setCardType(props.row.cardType);
      setBtnDis(false);
      setDefaultCardVal(props.row.def);
      setDefaultCard(props.row.def === "1" ? true : false);
    } else {
      setCardNumber("");
      setCVV("");
      setExpDate("");
      setCardType("");
      setSaveButtonTxt("Add Card");
      setPageTitle("Add a Card");
      setBtnDis(false);
      setDefaultCard(false);
      setDefaultCardVal(0);
      if (numcard === 0) {
        setDefaultCard(true);
        setDefaultCardVal(1);
      } else {
        setDefaultCard(false);
        setDefaultCardVal(0);
      }
    }
  }, [props]);

  const HandleToggle = (e) => {
    if (e.target.checked === true) {
      setDefaultCard((Prevsate) => !Prevsate);
      setDefaultCardVal(1);
    } else {
      setDefaultCard((Prevsate) => !Prevsate);
      setDefaultCardVal(0);
    }
  };

  //------------------------------------------UseEffect End--------------------------------------------------//
  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <ModalContainer justifyContent="center" borderRadius="4px">
          <ModalDialog animation={ModalAnimat} width="462px">
            <ModalHeader
              padding="19px 25px 16px 25px"
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="30px"
                fontSize="16px"
                fontWeight="600"
                color="#2E2E2E"
              >
                {pageTitle}
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#2E2E2E",
                  cursor: "pointer",
                  height: "24px",
                  width: "24px",
                  background: "rgba(46, 46, 46, 0.1)",
                  padding: "6px",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  HandleClose();
                  handleClear();
                }}
                id="dbrd_ad-crd_cls"
              />
            </ModalHeader>

            <ModalBody padding="16px 25px 0px 25px">
              <Form>
                <FormGroup
                  display="flex"
                  flexDirection="column"
                  marginBottom="12px"
                >
                  <FormLabel
                    color="#2E2E2E"
                    lineHight="24px"
                    fontSize="14px"
                    fontWeight="500"
                    marginBottom="8px"
                  >
                    Card Holder Name<RedStar>*</RedStar>
                  </FormLabel>
                  <Input
                    value={CardHolderName}
                    color="#2E2E2E"
                    fontSize="14px"
                    fontFamily="'Open Sans',sans-serif"
                    style={
                      nameErr
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    onChange={(e) => NameChange(e.target.value)}
                    maxLength={16}
                  />
                  {nameErr && <ErrorMessage>Enter a valid name</ErrorMessage>}
                </FormGroup>

                <FormGroup
                  display="flex"
                  flexDirection="column"
                  marginBottom="12px"
                >
                  <FormLabel
                    color="#2E2E2E"
                    lineHight="24px"
                    fontSize="14px"
                    fontWeight="500"
                    marginBottom="8px"
                  >
                    Card Number<RedStar>*</RedStar>
                  </FormLabel>
                  {props.row.number && (
                    <AdCardNum
                      color="#2E2E2E"
                      disabled={props.edit}
                      cursor={props.edit && "not-allowed"}
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      onChange={(e) => {
                        cardNoChange(e);
                      }}
                      value={CardNumber}
                      cardType={cardType}
                      style={
                        noErr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                      id="bil_adCrd-inp_num"
                    />
                  )}
                  {!props.row.id && (
                    <CardWrapper
                      border="1px solid rgb(216, 224, 240)"
                      borderRadius="6px"
                      padding="10px 12px"
                      background="rgb(255, 255, 255)"
                      marginTop="8px"
                      height="36px"
                      width="412px"
                      cardbrand={cardType}
                    >
                      <CardNumberElement
                        className="sub-card-width"
                        options={{
                          style: {
                            base: inputStyle,
                          },
                        }}
                        onChange={(e) => {
                          cardNoChange(e);
                        }}
                      />
                    </CardWrapper>
                  )}
                </FormGroup>

                <FormGroup
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="16px"
                >
                  {!props.row.id && (
                    <FormGroup
                      display="flex"
                      flexDirection="column"
                      width="47.5%"
                    >
                      <FormLabel
                        color="#2E2E2E"
                        lineHight="24px"
                        fontSize="14px"
                        fontWeight="500"
                        marginBottom="8px"
                      >
                        CVV<RedStar>*</RedStar>
                      </FormLabel>
                      <CardWrapper
                        border="1px solid rgb(216, 224, 240)"
                        borderRadius="6px"
                        padding="10px 12px"
                        background="rgb(255, 255, 255)"
                        marginTop="8px"
                        height="36px"
                        width="196px"
                      >
                        <CardCvcElement
                          options={{
                            placeholder: "cvv",
                            style: {
                              base: {
                                "::placeholder": {
                                  color: "rgb(113, 128, 150, 0.4)",
                                },
                              },
                            },
                          }}
                        />
                      </CardWrapper>
                    </FormGroup>
                  )}

                  <FormGroup
                    display="flex"
                    flexDirection="column"
                    width="47.5%"
                  >
                    <FormLabel
                      color="#2E2E2E"
                      lineHight="24px"
                      fontSize="14px"
                      fontWeight="500"
                      marginBottom="8px"
                    >
                      Expiration<RedStar>*</RedStar>
                    </FormLabel>
                    {ExpDate && (
                      <Input
                        color="#2E2E2E"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        onChange={(e) => {
                          expiredDateChange(e);
                        }}
                        value={ExpDate}
                        style={
                          dateErr
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                        id="bil_ad-inp_mmyy"
                      />
                    )}

                    {!props.row.id && (
                      <CardWrapper
                        border="1px solid rgb(216, 224, 240)"
                        borderRadius="6px"
                        padding="10px 12px"
                        background="rgb(255, 255, 255)"
                        marginTop="8px"
                        height="36px"
                        width="196px"
                      >
                        <CardExpiryElement
                          options={{
                            style: {
                              base: {
                                "::placeholder": {
                                  color: "rgb(113, 128, 150, 0.4)",
                                },
                              },
                            },
                          }}
                        />
                      </CardWrapper>
                    )}
                  </FormGroup>
                </FormGroup>
                <FormGroup
                  display="flex"
                  alignItems="center"
                  marginBottom="16px"
                >
                  <Switch>
                    <Toggle
                      checked={DefaultCard}
                      disabled={
                        numcard === 0
                          ? true
                          : props.row.def === "1"
                          ? true
                          : false
                      }
                      cursor={
                        numcard === 0
                          ? "true"
                          : props.row.def === "1"
                          ? "true"
                          : "false"
                      }
                      type="checkbox"
                      onClick={(e) => HandleToggle(e)}
                    />
                    <Slider />
                  </Switch>
                  <FormLabel
                    color="#7D8592"
                    lineHight="24px"
                    fontSize="14px"
                    fontWeight="500"
                    marginLeft="8px"
                  >
                    Default Card
                  </FormLabel>
                </FormGroup>
              </Form>
            </ModalBody>

            <ModalFooter padding="16px 24px 21px 25px">
              <Div display="flex" justify="flex-end" alignItems="center">
                <Button
                  display={props.edit ? "block" : "none"}
                  color="#ef5f5f"
                  height="36px"
                  width="121px"
                  background="rgba(239,95,95,0.2)"
                  backgroundColor="unset !important"
                  borderRadius="4px"
                  padding="9px 8px"
                  border="0.5px solid #ef5f5f"
                  fontWeight="600"
                  onClick={() => RemoveCard()}
                  disabled={
                    btnDisable ? true : props.row.def === "1" ? true : false
                  }
                  cursor={
                    btnDisable
                      ? "not-allowed"
                      : props.row.def === "1"
                      ? "not-allowed"
                      : "pointer"
                  }
                  id="bil_ad-btn_crdSv"
                >
                  Remove
                </Button>

                <Button
                  color="#2C7BE5"
                  cursor="pointer"
                  height="36px"
                  background="#FFFFFF"
                  width="100px"
                  fontWeight="600"
                  marginLeft="auto"
                  borderRadius="4px"
                  border="1px solid #2C7BE5"
                  onClick={() => {
                    HandleClose();
                    handleClear();
                  }}
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                >
                  Cancel
                </Button>
                <Button
                  color="#FFFFFF"
                  height="36px"
                  cursor="pointer"
                  width="100px"
                  background="#2C7BE5"
                  borderRadius="4px"
                  padding="9px 8px"
                  border="none !important"
                  fontWeight="600"
                  marginLeft="16px"
                  onClick={() => HandleSave()}
                  disabled={btnDisable}
                  isDisabled={btnDisable}
                  id="bil_ad-btn_crdSv"
                  hoverBackColor="#005FB2"
                  activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                >
                  {saveButtonTxt}
                </Button>
              </Div>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>

      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

const AddCard = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <AddCardData {...props} />
    </Elements>
  );
};

export default AddCard;
