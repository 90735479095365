import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  SetGetList,
  SetEditList,
  SetLabResponse,
  SetDeleteLists,
  SetMedPost,
  SetMedPut,
  SetMedUpdate,
  SetLaborder,
  SetLaborderone,
  SetLabList,
  SetProviderList,
  setHealthAlert,
} from "../../StateManagement/Reducers/HealthState";
import {
  ListsApi,
  EditListsApi,
  InsertListsApi,
  DeleteListsApi,
  UpdateListsApi,
  AllerUpdateListsApi,
  AllerInsertListsApi,
  LabApi,
  DeleteMedList,
  MedicationPostApi,
  MedicationPutApi,
  MedicationUpdateApi,
  LabOrderApi,
  LabOrderoneApi,
  LabOrderInsertApi,
  LabOrderDeleteApi,
  LabOrderUpdateApi,
  ProviderApi,
  LabListApi,
} from "../Apis/HealthApi";

export function* Lists({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(ListsApi, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Response = Decrypt_Value(res.data, "vozorpm");
        yield put(SetGetList(Response));
      } else {
        yield put(SetGetList([]));
      }
    }
  } catch (e) {
    SetGetList([]);
    console.log(e.message);
  }
}

export function* UpdateList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(EditListsApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozorpm");
      yield put(SetEditList(Response));
    }
  } catch (e) {
    yield put(SetGetList([]));

    console.log(e.message);
  }
}

export function* InsertList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(InsertListsApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}
export function* DeleteList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(DeleteListsApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}

export function* UpdatedList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));

  try {
    const res = yield call(UpdateListsApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}

export function* AllerUpdateList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(AllerUpdateListsApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}

export function* AllerInsertList({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(AllerInsertListsApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}

export function* LabGet({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(LabApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozorpm");

      yield put(SetLabResponse(Response));
    }
  } catch (e) {
    yield put(SetLabResponse("DET_FAILURE"));
    console.log(e.message);
  }
}

export function* DeleteLists({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(DeleteMedList, requiredData);

    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}

export function* medicationpostlist({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(MedicationPostApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert([]));
    console.log(e.message);
  }
}

export function* medicationputlist({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(MedicationPutApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozorpm");
      yield put(SetMedPut(Response));
    }
  } catch (e) {
    yield put(SetMedPut([]));
    console.log(e.message);
  }
}

export function* medicationupdatelist({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));
  try {
    const res = yield call(MedicationUpdateApi, requiredData);

    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert(""));
    console.log(e.message);
  }
}

export function* Laborder({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(LabOrderApi, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Response = Decrypt_Value(res.data, "vozorpm");
        yield put(SetLaborder(Response));
      } else {
        yield put(SetLaborder([]));
      }
    }
  } catch (e) {
    SetLaborder([]);
    console.log(e.message);
  }
}

export function* Laborderone({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(LabOrderoneApi, requiredData);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Response = Decrypt_Value(res.data, "vozorpm");
        yield put(SetLaborderone(Response));
      } else {
        yield put(SetLaborderone([]));
      }
    }
  } catch (e) {
    SetLaborderone([]);
    console.log(e.message);
  }
}
export function* LaborderInsert({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));

  try {
    const res = yield call(LabOrderInsertApi, requiredData);

    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert(""));
    console.log(e.message);
  }
}
export function* LaborderDelete({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));

  try {
    const res = yield call(LabOrderDeleteApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert(""));
    console.log(e.message);
  }
}

export function* LaborderUpdate({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");
  yield put(setHealthAlert(""));

  try {
    const res = yield call(LabOrderUpdateApi, requiredData);
    if (res.status === 200) {
      yield put(setHealthAlert(res.data));
    }
  } catch (e) {
    yield put(setHealthAlert(""));
    console.log(e.message);
  }
}

export function* Providers({ payload }) {
  try {
    const res = yield call(ProviderApi, payload);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const DecryptedData = Decrypt_Value(res.data, "vozorpm");
        yield put(SetProviderList(DecryptedData));
      } else {
        yield put(SetProviderList([]));
      }
    }
  } catch (e) {
    SetProviderList([]);
    console.log(e.message);
  }
}
export function* Labs({ payload }) {
  try {
    const res = yield call(LabListApi, payload);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const DecryptedData = Decrypt_Value(res.data, "vozorpm");
        yield put(SetLabList(DecryptedData));
      } else {
        yield put(SetLabList([]));
      }
    }
  } catch (e) {
    SetLabList([]);
    console.log(e.message);
  }
}
export function* setLabEmptyResponse() {
  yield put(SetLaborderone([]));
}
