import styled, { css } from "styled-components";
import { ModalHeader } from "../../StyledComponents";
export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none !important;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;

export const TextArea = styled.textarea`
  outline: none;
  width: 100%;
  min-height: 80px;
  height: ${(p) => p.height && p.height};
  cursor: ${(p) => p.cursor && p.cursor};
  width: ${(p) => (p.width ? p.width : "100%")};
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
  resize: none;
  &:hover {
    border: 1px solid rgba(46, 46, 46, 0.25);
  }
`;
export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: absolute;
  right: ${(p) => p.right && p.right};
  top: ${(p) => p.top && p.top};
`;
export const ModalHeader1 = styled(ModalHeader)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
`;
