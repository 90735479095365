import { createSlice } from "@reduxjs/toolkit";

export const MedicalSlice = createSlice({
  name: "Medical",
  initialState: {
    DrugList: {},
    ICD10List: {},
    ICD9List: {},
    ProcedureList: {},
  },
  reducers: {
    GetDrugList: (user) => {
      return user;
    },
    SetDrugList: (state, action) => {
      state.DrugList = action.payload;
    },
    GetICD10List: (user) => {
      return user;
    },
    GetICD9List: (user) => {
      return user;
    },
    SetICD9List: (state, action) => {
      state.ICD9List = action.payload;
    },
    GetProcedure: (user) => {
      return user;
    },
    SetProcedure: (state, action) => {
      state.ProcedureList = action.payload;
    },
  },
});

export const {
  GetDrugList,
  SetDrugList,
  GetICD10List,
  GetICD9List,
  SetICD9List,
  GetProcedure,
  SetProcedure,
} = MedicalSlice.actions;

export default MedicalSlice.reducer;
