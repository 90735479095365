import { createSlice } from "@reduxjs/toolkit";

export const TreatmentPlanSlice = createSlice({
  name: "TreatmentPlan",
  initialState: {
    TreatmentPlanDetails: [],
    TreatmentPlanDetailsForDashBoard: [],
    InsertPlanToPatient: "",
    DeleteResponse: "",
     PlanNameResponse: "",
    Icd10List: [],
    planDetails: [],
  },
  reducers: {
    GetPlanDetails: (state) => {
      return state;
    },

    SetPlanDetails: (state, action) => {
      state.TreatmentPlanDetails = action.payload;
    },
    GetPlanforPat: (state) => {
      return state;
    },

    SetPlanforPat: (state, action) => {
      state.InsertPlanToPatient = action.payload;
    },

    GetPlanDetailsforDashB: (state) => {
      return state;
    },

    SetPlanDetailsforDashB: (state, action) => {
      state.TreatmentPlanDetailsForDashBoard = action.payload;
    },

    GetDeletePlan: (state) => {
      return state;
    },

    SetDeletePlan: (state, action) => {
      state.DeleteResponse = action.payload;
    },
    GetPlanNameValidation: (data) => {
      return data;
    },
    SetPlanNameResponse: (state, action) => {
      state.PlanNameResponse = action.payload;
    },
    GetIcd10Codes: (data) => {
      return data;
    },
    SetIcd10Codes: (state, action) => {
      state.Icd10List = action.payload;
    },
    InsertTreatmentPlan: (data) => {
      return data;
    },
    GetTreatPlanDetails: (data) => {
      return data;
    },
    SetTreatPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
  },
});

export const {
  GetPlanDetails,
  SetPlanDetails,
  GetPlanforPat,
  SetPlanforPat,
  GetPlanDetailsforDashB,
  SetPlanDetailsforDashB,
  GetDeletePlan,
  SetDeletePlan,
  SetPlanNameResponse,
  GetPlanNameValidation,
  SetIcd10Codes,
  GetIcd10Codes,
  InsertTreatmentPlan,
  GetTreatPlanDetails,
  SetTreatPlanDetails,
} = TreatmentPlanSlice.actions;

export default TreatmentPlanSlice.reducer;
