import React, { useEffect, useRef } from "react";
import { Div, PageSubTitle, SpanTag, Text } from "../StyledComponents";
import { Canvas } from "./Styles";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";
import AccessDenied from "../AccessDenied";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const VitalSummary = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const chartRef = useRef(null);
  const chartRef1 = useRef(null);
  const countData = useSelector((state) => state.Dashboard.chartData);
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //
  const legend = (colors, labels) => {
    return (
      <>
        {colors.map((x, ind) => (
          <Div
            display="inline-flex"
            alignItems="center"
            key={`c${ind}`}
            marginLeft={
              ind !== 0 ? (ind === colors.length - 1 ? "10px" : "12px") : "10px"
            }
            marginRight={ind === colors.length - 1 ? "0px" : "10px"}
          >
            <SpanTag
              backgroundColor={x}
              borderRadius="2px"
              height="12px"
              width="12px"
            />
            <Text
              marginLeft="8px"
              fontFamily="'Open Sans',sans-serif"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight="22px"
              color="#2E2E2E"
              margin="12px 0 8px 8px"
            >
              {labels[ind]}
            </Text>
          </Div>
        ))}
      </>
    );
  };
  // ----------------------------------------- Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    const ctx = permissions.includes("vitals_access")
      ? chartRef.current.getContext("2d")
      : null;
    const labels = [
      ["Heart Rate"],
      ["Respiration Rate"],
      ["Blood Pressure"],
      ["Oxygen"],
      ["Glucose"],
    ];

    const data = {
      labels: labels,
      datasets: countData && [
        {
          label: "Normal",
          data: [
            countData.pulse_normal && countData.pulse_normal,
            countData.Respiration_normal && countData.Respiration_normal,
            countData.BP_normal && countData.BP_normal,
            countData.oxygen_normal && countData.oxygen_normal,
            countData.Glucose_normal && countData.Glucose_normal,
          ],
          backgroundColor: "#2C7BE5",
          borderColor: "#2C7BE5",
          borderWidth: 1,
        },
        {
          label: "Abnormal",
          data: [
            countData.pulse_abnormal && countData.pulse_abnormal,
            countData.Respiration_abnormal && countData.Respiration_abnormal,
            countData.BP_abnormal && countData.BP_abnormal,
            countData.oxygen_abnormal && countData.oxygen_abnormal,
            countData.Glucose_abnormal && countData.Glucose_abnormal,
          ],
          backgroundColor: "#FFC700",
          borderColor: "#FFC700",
          borderWidth: 1,
        },
        {
          label: "Critical",
          data: [
            countData.pulse_critical && countData.pulse_critical,
            countData.Respiration_critical && countData.Respiration_critical,
            countData.BP_critical && countData.BP_critical,
            countData.oxygen_critical && countData.oxygen_critical,
            countData.Glucose_critical && countData.Glucose_critical,
          ],
          backgroundColor: "#F24822",
          borderColor: "#F24822",
          borderWidth: 1,
        },
      ],
    };

    // Configure the options for your chart
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 16,
          right: 7,
          top: 12,
          bottom: 10,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            fontSize: 12,
          },
        },

        y: {
          border: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            display: true,
            maxTicksLimit: 7,
            minTicksLimit: 3,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    // Create a new bar chart instance
    const newChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
    });

    return () => {
      // Cleanup: Destroy the chart when the component unmounts
      newChart.destroy();
    };
  }, []);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //
  return (
    <>
      <Div
        gridArea="VitalSummary"
        width="100%"
        borderRadius="8px"
        border="1px solid rgba(46, 46, 46, 0.25)"
        backgroundColor="#fff"
      >
        <PageSubTitle
          color="#2E2E2E"
          fontSize="18px"
          fontWeight="600"
          lineHeight="normal"
          padding="10px 20px 0px 16px"
          marginBottom="-7px"
        >
          Vital Summary
        </PageSubTitle>
        {permissions.includes("vitals_access") ? (
          <>
            {legend(
              ["#2C7BE5", "#FFC700", "#F24822"],
              ["Normal", "Abnormal", "Critical"]
            )}
            <Div
              height="auto"
              maxHeight="400px"
              className="canvas_vitaldashboard"
            >
              <Canvas
                ref={chartRef}
                width="calc(100vw - 500px)"
                height="320"
              ></Canvas>
            </Div>
          </>
        ) : (
          <AccessDenied width="350px" height="350px" />
        )}
      </Div>
    </>
  );
};

export default VitalSummary;
