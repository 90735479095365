import React, { useEffect } from "react";
import {
  Div,
  Image,
  SpanTag,
  Spinner,
  SpinnerWrapper,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  Tablerow,
} from "../../StyledComponents";
import { PaginDiv, TableDiv } from "../../Patient/PatientList/styles";
import { useState } from "react";
import Pagination from "../../StyledComponents/Pagination/Pagination";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDevices } from "../../../StateManagement/Reducers/DevicesState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import Logo from "../../../Assets/images/Logo.png";

const All = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const provider_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const AllDevicesDetails = useSelector((state) => state.Device.AllDevices);
  const [currentPage, setCurrentPage] = useState(1);
  const addDeviceResponse = useSelector((state) => state.PatientDemo.DeviceRes);

  const [currentPosts, setCurrentPost] = useState([]);
  const [howManyPages, setHowManyPages] = useState(0);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // -----------------------useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);

    dispatch(
      GetAllDevices({
        uid: provider_id,
        status: props.type,
        search_key: props.searchvalue,
        vitals: props.deviceType,
        page: 1,
        pageSize: 10,
      })
    );
    setCurrentPage(1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.searchvalue, props.type, props.deviceType]);

  useEffect(() => {
    dispatch(
      GetAllDevices({
        uid: provider_id,
        status: props.type,
        search_key: props.searchvalue,
        vitals: props.deviceType,
        page: currentPage,
        pageSize: 10,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    if (AllDevicesDetails) {
      setCurrentPost(AllDevicesDetails.data ?? []);
      setHowManyPages(AllDevicesDetails.totalPages ?? 0);
    }
  }, [AllDevicesDetails]);

  useEffect(() => {
    if (addDeviceResponse !== "") {
      if (addDeviceResponse === "Devices added sucessfully") {
        dispatch(
          GetAllDevices({
            uid: provider_id,
            status: props.type,
            search_key: props.searchvalue,
            vitals: props.deviceType,
            page: 1,
            pageSize: 10,
          })
        );
        setCurrentPage(1);
      }
    }
  }, [addDeviceResponse]);

  // -------------------------useEffect End-------------------------------------------------------------//
  return (
    <>
      {loading ? (
        <SpinnerWrapper>
          <Spinner></Spinner>
          <Image width="40px" height="40px" position="absolute" src={Logo} />
        </SpinnerWrapper>
      ) : (
        <>
          <TableDiv overflowX="auto" className="TableLayout deviceall">
            <Table
              borderBottomLeftRadius={
                currentPosts.length === 0 || howManyPages === 1 ? "8px" : "0px"
              }
              borderBottomRightRadius={
                currentPosts.length === 0 || howManyPages === 1 ? "8px" : "0px"
              }
            >
              <TableHead background="#F1F5F8">
                <Tablerow
                  color="#2C7BE5"
                  fontFamily="Open Sans, sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                >
                  <TableHeadCell paddingLeft="25px">Device ID</TableHeadCell>
                  <TableHeadCell
                    width={
                      props.type !== "Unassigned"
                        ? "35% !important"
                        : "40% !important"
                    }
                  >
                    Device Type
                  </TableHeadCell>
                  <TableHeadCell
                    width={
                      props.type !== "Unassigned"
                        ? "50% !important"
                        : "40% !important"
                    }
                  >
                    Device Name
                  </TableHeadCell>
                  {props.type !== "Unassigned" && (
                    <TableHeadCell width="35% !important">
                      {props.type === "Return"
                        ? `Returned by `
                        : props.type === "Lost"
                        ? `Lost by `
                        : "Patient Name"}
                    </TableHeadCell>
                  )}
                  {props.type === "Inactive" && (
                    <TableHeadCell>Inactive days</TableHeadCell>
                  )}
                  {props.type !== "Unassigned" && (
                    <TableHeadCell>Device Issued on</TableHeadCell>
                  )}
                  <TableHeadCell
                    paddingRight={props.type === "Unassigned" ? "16px" : ""}
                  >
                    Device Added on
                  </TableHeadCell>
                  {props.type !== "Unassigned" && (
                    <TableHeadCell
                      paddingRight={props.type !== "" ? "16px" : ""}
                    >
                      {props.type === "Return"
                        ? `Returned Date `
                        : props.type === "Lost"
                        ? `Marked Lost on`
                        : "Last Data Sync"}
                    </TableHeadCell>
                  )}
                  {props.type === "" && (
                    <TableHeadCell
                      textAlign="center"
                      paddingRight="16px"
                      width="35% !important"
                    >
                      Status
                    </TableHeadCell>
                  )}
                </Tablerow>
              </TableHead>
              <TableBody>
                {currentPosts.length !== 0 ? (
                  currentPosts.map((details, index) => (
                    <Tablerow
                      borderBottom="1px solid #E2E8ED"
                      fontFamily="Open Sans, sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      isHovered={true}
                      key={index}
                    >
                      <TableBodyCell
                        paddingTop="20px"
                        paddingBottom="20px"
                        paddingLeft="24px"
                      >
                        {details.serial_number}
                      </TableBodyCell>
                      <TableBodyCell padding="20px 2px 20px 16px">
                        {details.DeviceDescription}
                      </TableBodyCell>
                      <TableBodyCell padding="20px 2px 20px 16px">
                        {details.device_model}
                      </TableBodyCell>
                      {props.type !== "Unassigned" && (
                        <TableBodyCell padding="20px 2px 20px 16px">
                          {details.patName}
                        </TableBodyCell>
                      )}
                      {props.type === "Inactive" && (
                        <TableBodyCell paddingLeft="32px">
                          {details.day}
                        </TableBodyCell>
                      )}
                      {props.type !== "Unassigned" && (
                        <TableBodyCell
                          padding="20px 0px 20px 16px"
                          width="106% !important"
                          display="flex"
                          alignItems="center"
                          height="100%"
                        >
                          {details.IssuedOn}
                        </TableBodyCell>
                      )}
                      <TableBodyCell padding="20px 2px 20px 16px">
                        {details.AddedOn}
                      </TableBodyCell>
                      {props.type !== "Unassigned" && (
                        <TableBodyCell padding="20px 2px 20px 16px">
                          {props.type === "Return"
                            ? details.ReturnOn
                            : props.type === "Lost"
                            ? details.LostOn
                            : details.lastDateSync}
                        </TableBodyCell>
                      )}
                      {props.type === "" && (
                        <TableBodyCell textAlign="center" paddingLeft="16px">
                          {/* <Div
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    > */}
                          <SpanTag
                            padding="4px 8px"
                            borderRadius="8px"
                            backgroundColor={
                              details.status === "Active"
                                ? "rgba(52, 168, 83, 0.10)"
                                : details.status === "Inactive"
                                ? "rgba(46, 46, 46, 0.10)"
                                : details.status === "Lost"
                                ? "rgba(242, 72, 34, 0.10)"
                                : details.status === "Return"
                                ? "rgba(44, 123, 229, 0.10)"
                                : details.status === "Unassigned"
                                ? "rgba(255, 210, 51, 0.10)"
                                : ""
                            }
                            color={
                              details.status === "Active"
                                ? "#34A853"
                                : details.status === "Inactive"
                                ? "#2E2E2E"
                                : details.status === "Lost"
                                ? "#F24822"
                                : details.status === "Return"
                                ? "#2C7BE5"
                                : details.status === "Unassigned"
                                ? "#FFD233"
                                : ""
                            }
                            fontFamily="Open Sans, sans-serif"
                            fontSize=" 14px"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="normal"
                          >
                            {details.status}
                          </SpanTag>
                          {/* </Div> */}
                        </TableBodyCell>
                      )}
                    </Tablerow>
                  ))
                ) : dataLoaded && currentPosts.length === 0 ? (
                  <>
                    <Tablerow>
                      <TableBodyCell textAlign="center" colSpan="8">
                        <Div>
                          <Image
                            height="133px"
                            width="133px"
                            src={Emp_data_pic}
                          ></Image>
                        </Div>
                      </TableBodyCell>
                    </Tablerow>
                    <Tablerow>
                      <TableBodyCell
                        textAlign="center"
                        colSpan="8"
                        paddingBottom="40px"
                      >
                        No Devices has been added to show in the Device list
                      </TableBodyCell>
                    </Tablerow>
                  </>
                ) : (
                  <SpinnerWrapper>
                    <Spinner></Spinner>
                    <Image
                      width="40px"
                      height="40px"
                      position="absolute"
                      src={Logo}
                    />
                  </SpinnerWrapper>
                )}
              </TableBody>
            </Table>
          </TableDiv>
          <PaginDiv
            hidePagination={currentPosts.length === 0 || howManyPages === 1}
          >
            {currentPosts.length === 0 || howManyPages === 1 ? (
              ""
            ) : (
              <Pagination
                pages={howManyPages}
                setCurrentPage={setCurrentPage}
                current={currentPage}
              />
            )}
          </PaginDiv>
        </>
      )}
    </>
  );
};

export default All;
