import { takeLatest } from "redux-saga/effects";

import {
  AddProfileDataApi,
  AddClinicalDataApi,
  ProfileDetailsApi,
  ClinicalDetailsApi,
  setAddProfileEmptyResponse,
  UserImageUploadApi,
  SettingSecurityUpdateWorker,
  SettingSecurityWorker,
  setEmptySecurityResponseWorker,
  GetDocumentMailWorker,
  GetSettingDocWorker,
  GetUpdateSetDocWorker,
  EmptyUpdateSetDocWorker,
  UpdateDocMailWorker,
  getProvider,
  getUserList,
  getRolesforProvider,
  savedProvider,
  getSingleProvider,
  updateProvider,
  setEmptyResponse,
  setSingleProvEmptyResponse,
  getSecurtiyData,
  deleteUser,
  setEmptyUpdateResponse,
  getServiceList,
  getServiceInsertList,
  getOneServiceList,
  getServiceUpdateList,
  CurrencyApi,
  getServicedeleteList,
  setServiceEmptyResponse,
  CodeVerifyApi,
  setEmptyCodeResponse,
  EmptyTheUserCheck,
  CheckUserName,
  EmptyTheUserEmail,
  CheckUserEmail,
  CheckUserPhone,
  CheckUserCell,
  EmptyTheUserPhone,
  EmptyTheUserCell,
  SetMailVerifyApi,
  SetPhoneVerifyApi,
  SetProviderRolesListWorker,
  InsertRoleApi,
  EditRoleApi,
  GetGroupIdApi,
  EditRoleInsertApi,
  ProvWithRoleApi,
  GetCustomizeRoleWorker,
  EditProvRoleApi,
  SaveProvRoleApi,
  DeleteRoleApi,
  CountryCodeAPI,
} from "./SettingSagaWorkers";
import {
  AddProfileData,
  AddClinicalData,
  GetProfileDetails,
  GetClinicalDetails,
  setEmptyAddProfileResponse,
  userImageUpload,
  GetSecurityData,
  GetSecurityUpdate,
  setEmptySecurityResponse,
  GetDocumentMailRes,
  GetSettingDocRes,
  GetUpdateSetDocRes,
  EmptyUpdateSetDocRes,
  GetUpdateDocMailRes,
  GetSingProv,
  GetUpdateProv,
  SetEmptyUserListResponse,
  setSingleProviderResponse,
  GetSettingSecurity,
  GetUserDelete,
  SetEmptyUpdateResponse,
  GetUserList,
  GetProviderList,
  GetRoles,
  GetSaveProv,
  GetOneServiceList,
  GetServiceUpdateList,
  GetCurrency,
  GetServiceDeleteList,
  GetAllServiceList,
  GetServiceInsertList,
  setEmptyServiceResponse,
  GetCodeVerify,
  setEmptyCodeMatchResponse,
  GetCheckUser,
  EmptyingCheckUser,
  EmptyingCheckEmail,
  GetCheckPhone,
  EmptyingCheckPhone,
  GetCheckCell,
  GetCheckEmail,
  EmptyingCheckCell,
  GetCheckMailVerify,
  GetCheckPhoneVerify,
  GetProviderRolesList,
  GetInsertRole,
  GetRolesEdit,
  GetGroupId,
  GetEditRoleInsert,
  GetProvWithRole,
  GetCustomizeRole,
  GetViewProvRole,
  GetProvSaveRole,
  GetRoleDelete,
  GetCountryCode,
} from "../../StateManagement/Reducers/SettingState";
import { GetProviderRolesList_api } from "../Apis/SettingApi";
export function* ProviderList() {
  yield takeLatest(GetUserList.type, getUserList);
}

export function* GetProvidersList() {
  yield takeLatest(GetProviderList.type, getProvider);
}

export function* ProviderRoles() {
  yield takeLatest(GetRoles.type, getRolesforProvider);
}

export function* SavedProvidersList() {
  yield takeLatest(GetSaveProv.type, savedProvider);
}

export function* SingleProviderSaga() {
  yield takeLatest(GetSingProv.type, getSingleProvider);
}

export function* UpdateSingleProvider() {
  yield takeLatest(GetUpdateProv.type, updateProvider);
}

export function* EmptyResponseSaga() {
  yield takeLatest(SetEmptyUserListResponse.type, setEmptyResponse);
}

export function* EmptyUpdateResponseSaga() {
  yield takeLatest(SetEmptyUpdateResponse.type, setEmptyUpdateResponse);
}

export function* SingleProvEmptyResponseSaga() {
  yield takeLatest(setSingleProviderResponse.type, setSingleProvEmptyResponse);
}

export function* SettingSecurityData() {
  yield takeLatest(GetSettingSecurity.type, getSecurtiyData);
}

export function* DeleteUserData() {
  yield takeLatest(GetUserDelete.type, deleteUser);
}

export function* AddProfileEmptyingResponseSaga() {
  yield takeLatest(setEmptyAddProfileResponse.type, setAddProfileEmptyResponse);
}
export function* AddProfileDatas() {
  yield takeLatest(AddProfileData.type, AddProfileDataApi);
}
export function* AddClinicalDatas() {
  yield takeLatest(AddClinicalData.type, AddClinicalDataApi);
}

export function* ProfileDetails() {
  yield takeLatest(GetProfileDetails.type, ProfileDetailsApi);
}
export function* ClinicalDetails() {
  yield takeLatest(GetClinicalDetails.type, ClinicalDetailsApi);
}
export function* userImageUploadSaga() {
  yield takeLatest(userImageUpload.type, UserImageUploadApi);
}
export function* SettingSecuritySaga() {
  yield takeLatest(GetSecurityData.type, SettingSecurityWorker);
}

export function* SettingSecurityUpdateSaga() {
  yield takeLatest(GetSecurityUpdate.type, SettingSecurityUpdateWorker);
}

export function* EmptyingSecurityResponseSaga() {
  yield takeLatest(
    setEmptySecurityResponse.type,
    setEmptySecurityResponseWorker
  );
}

export function* GetDocumentMailSaga() {
  yield takeLatest(GetDocumentMailRes.type, GetDocumentMailWorker);
}

export function* GetSettingDocSaga() {
  yield takeLatest(GetSettingDocRes.type, GetSettingDocWorker);
}

export function* GetUpdateSetDocSaga() {
  yield takeLatest(GetUpdateSetDocRes.type, GetUpdateSetDocWorker);
}

export function* EmptyUpdateSetDocSaga() {
  yield takeLatest(EmptyUpdateSetDocRes.type, EmptyUpdateSetDocWorker);
}

export function* UpdateDocMailSaga() {
  yield takeLatest(GetUpdateDocMailRes.type, UpdateDocMailWorker);
}
export function* GetServiceData() {
  yield takeLatest(GetAllServiceList.type, getServiceList);
}

export function* GetServiceDeleteData() {
  yield takeLatest(GetServiceDeleteList.type, getServicedeleteList);
}

export function* GetServiceInsertData() {
  yield takeLatest(GetServiceInsertList.type, getServiceInsertList);
}

export function* GetServiceOneData() {
  yield takeLatest(GetOneServiceList.type, getOneServiceList);
}

export function* GetServiceUpdateData() {
  yield takeLatest(GetServiceUpdateList.type, getServiceUpdateList);
}

export function* Currency() {
  yield takeLatest(GetCurrency.type, CurrencyApi);
}
export function* EmptyingServiceResponseSaga() {
  yield takeLatest(setEmptyServiceResponse.type, setServiceEmptyResponse);
}
export function* EmptyingCodeResponseSaga() {
  yield takeLatest(setEmptyCodeMatchResponse.type, setEmptyCodeResponse);
}
export function* ServiceCodeVerify() {
  yield takeLatest(GetCodeVerify.type, CodeVerifyApi);
}
export function* CheckUserNameSaga() {
  yield takeLatest(GetCheckUser.type, CheckUserName);
}
export function* EmptyRespofUserCheck() {
  yield takeLatest(EmptyingCheckUser.type, EmptyTheUserCheck);
}

export function* CheckUserEmailSaga() {
  yield takeLatest(GetCheckEmail.type, CheckUserEmail);
}
export function* EmptyRespofEmailCheck() {
  yield takeLatest(EmptyingCheckEmail.type, EmptyTheUserEmail);
}

export function* CheckUserPhoneSaga() {
  yield takeLatest(GetCheckPhone.type, CheckUserPhone);
}
export function* EmptyRespofPhoneCheck() {
  yield takeLatest(EmptyingCheckPhone.type, EmptyTheUserPhone);
}

export function* CheckUserCellSaga() {
  yield takeLatest(GetCheckCell.type, CheckUserCell);
}
export function* EmptyRespofCellCheck() {
  yield takeLatest(EmptyingCheckCell.type, EmptyTheUserCell);
}
export function* SetMailVerify() {
  yield takeLatest(GetCheckMailVerify.type, SetMailVerifyApi);
}
export function* SetPhoneVerify() {
  yield takeLatest(GetCheckPhoneVerify.type, SetPhoneVerifyApi);
}

export function* GetProviderRolesListSaga() {
  yield takeLatest(GetProviderRolesList.type, SetProviderRolesListWorker);
}

export function* GetCustomizeRoleSaga() {
  yield takeLatest(GetCustomizeRole.type, GetCustomizeRoleWorker);
}

export function* InsertRoleSaga() {
  yield takeLatest(GetInsertRole.type, InsertRoleApi);
}
export function* EditRoleSaga() {
  yield takeLatest(GetRolesEdit.type, EditRoleApi);
}
export function* GetGroupIdSaga() {
  yield takeLatest(GetGroupId.type, GetGroupIdApi);
}
export function* EditRoleInsertSaga() {
  yield takeLatest(GetEditRoleInsert.type, EditRoleInsertApi);
}
export function* ProviderWithRoleSaga() {
  yield takeLatest(GetProvWithRole.type, ProvWithRoleApi);
}

export function* EditProvRoleSaga() {
  yield takeLatest(GetViewProvRole.type, EditProvRoleApi);
}

export function* SaveProvRoleSaga() {
  yield takeLatest(GetProvSaveRole.type, SaveProvRoleApi);
}

export function* DeleteRoleSaga() {
  yield takeLatest(GetRoleDelete.type, DeleteRoleApi);
}

export function* CountryCodeDetails() {
  yield takeLatest(GetCountryCode.type, CountryCodeAPI);
}
