import React from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import ListSheet from "./ListSheet";
import AccessDenied from "../../AccessDenied";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const User = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div
        marginLeft="56px"
        backgroundColor="#F1F5F8"
        // className="responsive-right"
      >
        <Div>
          {permissions.includes("user_add") ? <ListSheet /> : <AccessDenied />}
        </Div>
      </Div>
    </Container>
  );
};
export default User;
