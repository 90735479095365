import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
    SetStateListDetail,
    SetLangListDetail,
    SetRaceListDetail,
    SetEthListDetail,
} from "../../StateManagement/Reducers/DropDownList";
import { StateListApi, RaceListApi, EthnicityListApi, LanguageListApi } from "../Apis/ListApi";

export function* StateListOptionsCheck({ payload }) {
    const credential = Encrypt_Value(payload, "vozorpm");
    try {
        const res = yield call(StateListApi, credential);
        if (res.status === 200) {
            const Data = Decrypt_Value(res.data, "vozorpm");
            yield put(SetStateListDetail(Data));
        }
    } catch (e) {
        yield put(SetStateListDetail([]));

        console.log(e.message);
    }
}

export function* RaceListOptionsCheck({ payload }) {
    const credential = Encrypt_Value(payload, "vozorpm");
    try {
        const res = yield call(RaceListApi, credential);
        if (res.status === 200) {
            const Data = Decrypt_Value(res.data, "vozorpm");
            yield put(SetRaceListDetail(Data));
        }
    } catch (e) {
        yield put(SetRaceListDetail([]));

        console.log(e.message);
    }
}

export function* EthnicityListOptionsCheck({ payload }) {
    const credential = Encrypt_Value(payload, "vozorpm");
    try {
        const res = yield call(EthnicityListApi, credential);
        if (res.status === 200) {
            const Data = Decrypt_Value(res.data, "vozorpm");
            yield put(SetEthListDetail(Data));
        }
    } catch (e) {
        yield put(SetEthListDetail([]));

        console.log(e.message);
    }
}

export function* LangListOptionsCheck({ payload }) {
    const credential = Encrypt_Value(payload, "vozorpm");
    try {
        const res = yield call(LanguageListApi, credential);
        if (res.status === 200) {
            const Data = Decrypt_Value(res.data, "vozorpm");
            yield put(SetLangListDetail(Data));
        }
    } catch (e) {
        yield put(SetLangListDetail([]));

        console.log(e.message);
    }
}
