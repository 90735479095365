import React, { useState, memo } from "react";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import Lock_icon from "../../../Assets/images/Lock_icon.png";
import { BsPlayCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineFileAdd, AiOutlineEdit } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Notes from "./Notes";
import {
  SpanTag,
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
  FormLabel,
} from "../../StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  GetEncounterSummary,
  GetRpmSingleNote,
  setIsLocked,
  GetRpmNotes,
  RpmSingleDelete,
  setEmptyDelNoteResponse,
  setEditEncounter,
  EditEncounterId,
  SetChartviewIdempty,
} from "../../../StateManagement/Reducers/EncounterState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { GetVisitEncounterList } from "../../../StateManagement/Reducers/EncounterState";
import { MdLockOpen, MdLockOutline } from "react-icons/md";
import { GetFeeDelete } from "../../../StateManagement/Reducers/PatientState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { SetpatientDemotimer } from "../../../StateManagement/Reducers/PatientDemoState";

const EncounterSheet = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const patId = params.pid;
  const enc_id =
    localStorage.getItem("encounter") &&
    Decrypt_Value(localStorage.getItem("encounter"), "vozorpm");
  const provider_name =
    localStorage.getItem("ProviderName") &&
    Decrypt_Value(localStorage.getItem("ProviderName"), "vozorpm");
  const EncounterId = useSelector((state) => state.Encounter.EncounterId);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const SummaryDetails = useSelector(
    (state) => state.Encounter.EncounterSummary
  );

  const [expandedRows, setExpandedRows] = useState([]);
  const [collapsedRows1, setCollapsedRows1] = useState([]);
  const [activeState, setActiveState] = useState([
    {
      note: "",
      billing: "",
      changeNoteSummary: false,
      changeBillSummary: false,
    },
  ]);

  const [show, setShow] = useState(false);
  const [encountDetails, setEncounterDetails] = useState([]);
  const [initialShow, setInitialShow] = useState(false);
  const GetRpmVal = useSelector((state) => state.Encounter.NotesGet);
  const RemoveOneNote = useSelector((state) => state.Encounter.SingleDelete);
  const TakeNote = useSelector((state) => state.Encounter.NotesTake);
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [currentEid, setCurrentEid] = useState("");
  const { state } = useLocation();
  const [live_eid, setLiveEid] = useState(state ? state.eid : "");
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start------------------------------------------------- //

  const handleCollapseShow = (index, details) => {
    let detail = details ? details : { encounter: enc_id };
    dispatch(GetRpmNotes({ id: detail.encounter, type: "eid" }));
    setCurrentEid(detail.encounter);
    setLiveEid("");
    if (detail.diff) {
      if (collapsedRows1.includes(index)) {
        setCollapsedRows1(
          collapsedRows1.filter((rowIndex) => rowIndex !== index)
        );
      } else {
        setCollapsedRows1([...collapsedRows1, index]);
      }
    } else {
      if (expandedRows.includes(index)) {
        setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
      } else {
        setExpandedRows([...expandedRows, index]);
      }

      setActiveState((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = {
          ...updatedState[index],
          billing: "",
          note: "activeColor",
          changeNoteSummary: true,
          changeBillSummary: false,
        };
        return updatedState;
      });
    }
  };

  const handleNoteClick = (index) => {
    setActiveState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        billing: "",
        note: "activeColor",
        changeNoteSummary: true,
        changeBillSummary: false,
      };
      return updatedState;
    });
  };

  const handleBillingClick = (index) => {
    setActiveState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        billing: "activeColor",
        note: "",
        changeNoteSummary: false,
        changeBillSummary: true,
      };
      return updatedState;
    });
  };
  const viewDetailsPage = () => {
    navigate(`/Encounter/${patId}/ViewDetails/encounter`);
  };

  const handleEdit = (details) => {
    if (SingPatDetails.enrollment_status !== "4") {
      navigate(`/Encounter/Encounterfrom/${patId}`);
      dispatch(
        setEditEncounter({
          encounterId: EncounterId,
          provider: details.id,
          notes: details.notes,
        })
      );
    }
  };

  const handleClose = () => {
    setEncounterDetails([]);
    setShow(false);
  };
  const handleDelete = (id, eid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (window.confirm("Are you sure want to delete") === true) {
        dispatch(RpmSingleDelete({ id, type: "encounter" }));
      }
    }
  };

  const handleNoteAdd = (details, eid, type) => {
    if (permissions.includes("notes_lock")) {
      if (SingPatDetails.enrollment_status !== "4") {
        if (type === "edit") {
          dispatch(GetRpmSingleNote({ id: details.note_id, type: "id" }));
        } else {
          setCurrentEid(details.encounter);
        }
        const patname = SummaryDetails[0].patname;
        const updatedDetails = { ...details, patname: patname };
        setTimeout(() => {
          setInitialShow(true);
          setEncounterDetails(updatedDetails);
        }, 500);
      }
    } else {
      denied_access();
    }
  };

  const handleNoteLock = (details) => {
    if (permissions.includes("enc_lock")) {
      if (SingPatDetails.enrollment_status !== "4") {
        const encounterID = details.encounter;
        if (details.lockstatus === 1) {
          const data = {
            encounter: encounterID,
            locked: 0,
          };

          dispatch(setIsLocked(data));
          setShowAlert(true);
          setModalMsg("unlocked Successfully");
          setStatus("success");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
        } else {
          const data = {
            encounter: encounterID,
            locked: 1,
          };

          dispatch(setIsLocked(data));
          setShowAlert(true);
          setModalMsg("locked Successfully");
          setStatus("success");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
        }
      }
    } else {
      denied_access();
    }
  };

  const encounterDelete = (details) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (details.lockstatus === 1) {
        setShowAlert(true);
        setModalMsg("Encounter is locked");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
        return false;
      } else if (details.status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(
                GetFeeDelete({ pid: params.pid, eid: details.encounter })
              );
            })
            .then(() => {
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  const denied_access = () => {
    setModalMsg("Permission Denied");
    setStatus("Failed");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setStatus("");
      setModalMsg("");
    }, 1500);
  };

  function makeItAsMinutes(seconds) {
    const minutes = seconds / 60;
    return `${minutes.toFixed(2)} ${minutes > 1 ? "minutes" : "minute"}`;
  }
  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    dispatch(
      GetEncounterSummary(EncounterId && enc_id !== 0 ? EncounterId : enc_id)
    );
    if (!live_eid) {
      setExpandedRows([]);
      setCollapsedRows1([]);
    }
  }, [EncounterId, enc_id]);

  useEffect(() => {
    if (live_eid) {
      handleCollapseShow(0, SummaryDetails[0]);
    } else {
      setExpandedRows([]);
      setCollapsedRows1([]);
    }
    setTimeout(() => {
      dispatch(SetChartviewIdempty());
      dispatch(
        SetpatientDemotimer({
          patId: "",
          timer: "",
        })
      );
    }, 1000);
  }, []);

  useEffect(() => {
    if (initialShow) {
      if (encountDetails.length != 0) setShow(true);
      else setShow(false);
    }
  }, [encountDetails]);

  useEffect(() => {
    if (RemoveOneNote === "success") {
      dispatch(GetEncounterSummary(currentEid));
      setShowAlert(true);
      setModalMsg("note deleted successfully");
      setStatus("success");
      setTimeout(() => {
        setShowAlert(false);
        setModalMsg("");
        setStatus("");
        dispatch(setEmptyDelNoteResponse());
      }, 2000);
    }
  }, [RemoveOneNote]);

  useEffect(() => {
    if (TakeNote === "success") {
      dispatch(GetEncounterSummary(currentEid != "" ? currentEid : live_eid));
    }
  }, [TakeNote]);

  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <>
      <Div display="flex">
        <Div
          margin="24px 0px 24px 29px"
          color="#2E2E2E"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="18px"
        >
          Encounter Summary for {SingPatDetails.patname}
        </Div>
        <Button
          border="none"
          backgroundColor="#FFFFFF"
          marginLeft="auto"
          marginRight="20px"
          color="#2C7BE5"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="14px"
          cursor="pointer"
          onClick={viewDetailsPage}
        >
          View Details
        </Button>
      </Div>

      <Div overflowX="auto">
        <Table border="none !important">
          <TableHead background="#F1F5F8">
            <Tablerow
              color="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              colSpan="5"
              // className="tbody_padding"
              textAlign="center"
            >
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Encounter</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Encounter ID</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Provider</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Duration</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Action</SpanTag>
              </TableHeadCell>
            </Tablerow>
          </TableHead>
          <TableBody>
            {SummaryDetails.length !== 0 ? (
              SummaryDetails.map((details, index) => (
                <React.Fragment key={index}>
                  <Tablerow
                    color="#2E2E2E"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    borderBottom={
                      expandedRows.includes(index) ||
                      collapsedRows1.includes(index)
                        ? "none !important"
                        : "1px solid #E2E8ED"
                    }
                    colSpan="5"
                    textAlign="center"
                  >
                    <TableBodyCell
                      // paddingLeft="38px"
                      paddingTop="19px"
                      paddingBottom="20px"
                      borderBottom="1px solid #E2E8ED"
                    >
                      {details.enc_date}
                    </TableBodyCell>
                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {details.encounter}
                    </TableBodyCell>
                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {details.provider}
                    </TableBodyCell>
                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {details.duration}
                    </TableBodyCell>

                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {details.diff ? (
                        <SpanTag
                          gap="14px"
                          display="flex"
                          justifyContent="center"
                        >
                          <BsPlayCircle
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#2C7BE5",
                              rotate:
                                expandedRows.includes(index) ||
                                collapsedRows1.includes(index)
                                  ? "270deg"
                                  : "90deg",
                              cursor: "pointer",
                              marginLeft: "32px",
                            }}
                            onClick={() => handleCollapseShow(index, details)}
                          />
                          {details.diff !== "1" && (
                            <AiOutlineFileAdd
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2E2E2E",
                                cursor:
                                  details.status === "open" &&
                                  SingPatDetails.enrollment_status !== "4"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() =>
                                details.status === "open" &&
                                SingPatDetails.enrollment_status !== "4" &&
                                handleNoteAdd(details, details.EncounterId)
                              }
                            />
                          )}
                          {details.lockstatus === 1 ? (
                            <MdLockOutline
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2E2E2E",
                                cursor:
                                  SingPatDetails.enrollment_status !== "4"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => handleNoteLock(details)}
                            />
                          ) : (
                            <MdLockOpen
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2E2E2E",
                                cursor:
                                  SingPatDetails.enrollment_status !== "4"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => handleNoteLock(details)}
                            />
                          )}

                          <FiTrash2
                            onClick={() => encounterDelete(details)}
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#B00020",
                              marginLeft: "3px",
                              fill: "#ffffff",
                              stroke: "#f24822",
                              cursor:
                                details.lockstatus === 0 &&
                                details.status === "open" &&
                                SingPatDetails.enrollment_status !== "4"
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                          />
                        </SpanTag>
                      ) : (
                        <SpanTag
                          gap="14px"
                          display="flex"
                          justifyContent="center"
                        >
                          <BsPlayCircle
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#2C7BE5",
                              rotate:
                                expandedRows.includes(index) ||
                                collapsedRows1.includes(index)
                                  ? "270deg"
                                  : "90deg",
                              cursor: "pointer",
                            }}
                            onClick={() => handleCollapseShow(index, details)}
                          />
                          {details.diff !== "1" && (
                            <AiOutlineFileAdd
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2E2E2E",
                                cursor:
                                  details.status === "open" &&
                                  SingPatDetails.enrollment_status !== "4"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() =>
                                details.status === "open" &&
                                SingPatDetails.enrollment_status !== "4" &&
                                handleNoteAdd(details, details.EncounterId)
                              }
                            />
                          )}
                          {details.lockstatus === 1 ? (
                            <MdLockOutline
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2E2E2E",
                                cursor:
                                  SingPatDetails.enrollment_status !== "4"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => handleNoteLock(details)}
                            />
                          ) : (
                            <MdLockOpen
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2E2E2E",
                                cursor:
                                  SingPatDetails.enrollment_status !== "4"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => handleNoteLock(details)}
                            />
                          )}

                          <FiTrash2
                            onClick={() => encounterDelete(details)}
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#B00020",
                              cursor:
                                details.lockstatus === 0 &&
                                SingPatDetails.enrollment_status !== "4" &&
                                details.status === "open"
                                  ? "pointer"
                                  : "not-allowed",
                              fill: "#ffffff",
                              stroke: "#f24822",
                            }}
                          />
                        </SpanTag>
                      )}
                    </TableBodyCell>
                  </Tablerow>

                  {expandedRows.includes(index) ? (
                    <Tablerow textAlign="center">
                      <TableBodyCell
                        colSpan="6"
                        padding="0px 24px 14px 22px "
                        borderBottom={
                          expandedRows.includes(index) && "1px solid #E2E8ED"
                        }
                      >
                        <Table
                          borderTop="1px solid #E2E8ED"
                          // borderTopLeftRadius="8px"
                          // borderTopRightRadius="8px"
                          borderRadius="8px"
                          className="getlast"
                          tableLayout="fixed"
                        >
                          <TableHead>
                            <Tablerow
                              color="rgba(46, 46, 46, 0.5)"
                              fontFamily="Open Sans, sans-serif"
                              fontWeight="600"
                              fontSize="16px"
                              // className="tbody_padding"
                              display="flex"
                              width="90vw"
                            >
                              <TableHeadCell
                                className={activeState[index]?.note}
                                cursor="pointer"
                                onClick={() => handleNoteClick(index)}
                                paddingRight="16px"
                              >
                                Note Summary
                              </TableHeadCell>
                              <TableHeadCell
                                cursor="pointer"
                                onClick={() => handleBillingClick(index)}
                                className={activeState[index]?.billing}
                              >
                                Billing Summary
                              </TableHeadCell>
                            </Tablerow>
                            <Tablerow
                              backgroundColor="#F1F5F8"
                              color="#2C7BE5"
                              fontFamily="Open Sans, sans-serif"
                              fontWeight="600"
                              fontSize="14px"
                              textAlign="center"
                              // className="tbody_padding"
                              paddingLeft="0px"
                            >
                              <TableHeadCell
                                paddingLeft="0px"
                                width="18% !important"
                              >
                                Date
                              </TableHeadCell>
                              <TableHeadCell
                                paddingLeft="0px"
                                width="15% !important"
                              >
                                Activity
                              </TableHeadCell>
                              <TableHeadCell paddingLeft="0px">
                                {activeState[index]?.changeBillSummary
                                  ? "Duration"
                                  : "Notes"}
                              </TableHeadCell>
                              <TableHeadCell paddingLeft="0px">
                                {activeState[index]?.changeBillSummary
                                  ? "Code"
                                  : "Performed by"}
                              </TableHeadCell>
                              <TableHeadCell paddingLeft="0px">
                                {activeState[index]?.changeBillSummary
                                  ? "Charges"
                                  : "Duration"}
                              </TableHeadCell>

                              <TableHeadCell paddingLeft="0px">
                                Action
                              </TableHeadCell>
                            </Tablerow>
                          </TableHead>
                          {details.details.length !== 0 ? (
                            details.details.map((details) => (
                              <TableBody>
                                <Tablerow
                                  bbtom
                                  borderBottom="1px solid #E2E8ED"
                                  color="#2E2E2E"
                                  fontFamily="Open Sans, sans-serif"
                                  fontWeight="400"
                                  fontSize="14px"
                                  className="collapse_lastChild"
                                  textAlign="center"
                                >
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                    paddingRight="0px"
                                  >
                                    {details.date2}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                    paddingRight="0px"
                                  >
                                    {details.activity !== "1" &&
                                    details.activity !== ""
                                      ? details.activity
                                      : "-"}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                    paddingRight="0px"
                                  >
                                    {activeState[index]?.changeBillSummary
                                      ? details.duration
                                        ? makeItAsMinutes(details.duration)
                                        : "-"
                                      : details.notes !== null &&
                                        details.notes !== ""
                                      ? details.notes
                                      : "-"}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                    paddingRight="0px"
                                  >
                                    {activeState[index]?.changeBillSummary
                                      ? details.code
                                        ? `CPT4 - ${details.code}`
                                        : "-"
                                      : details.provider}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                    paddingRight="0px"
                                  >
                                    {activeState[index]?.changeBillSummary
                                      ? details.fee
                                        ? details.fee
                                        : "-"
                                      : details.duration
                                      ? makeItAsMinutes(details.duration)
                                      : "-"}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                  >
                                    {
                                      <SpanTag
                                        gap="14px"
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <AiOutlineEdit
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            color: "#2C7BE5",
                                            cursor:
                                              SummaryDetails[0].status !==
                                                "closed" &&
                                              details.code !== "99453" &&
                                              details.code !== "99454" &&
                                              SingPatDetails.enrollment_status !==
                                                "4"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          onClick={() => {
                                            return SummaryDetails[0].status !==
                                              "closed" &&
                                              details.code !== "99453" &&
                                              details.code !== "99454" &&
                                              SingPatDetails.enrollment_status !==
                                                "4"
                                              ? handleNoteAdd(
                                                  details,
                                                  EncounterId,
                                                  "edit"
                                                )
                                              : "";
                                          }}
                                        />
                                        <FiTrash2
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            color: "#B00020",
                                            cursor:
                                              SummaryDetails[0].status !==
                                                "closed" &&
                                              details.code !== "99453" &&
                                              details.code !== "99454" &&
                                              SingPatDetails.enrollment_status !==
                                                "4"
                                                ? "pointer"
                                                : "not-allowed",
                                            fill: "#ffffff",
                                            stroke: "#f24822",
                                          }}
                                          onClick={() => {
                                            return (
                                              SummaryDetails[0].status !==
                                                "closed" &&
                                              details.code !== "99453" &&
                                              details.code !== "99454" &&
                                              SingPatDetails.enrollment_status !==
                                                "4" &&
                                              handleDelete(
                                                details.note_id,
                                                EncounterId
                                              )
                                            );
                                          }}
                                        />
                                      </SpanTag>
                                    }
                                  </TableBodyCell>
                                </Tablerow>
                              </TableBody>
                            ))
                          ) : (
                            <TableBody>
                              <TableBodyCell textAlign="center" colSpan={6}>
                                <Div>
                                  <Image
                                    height="133px"
                                    width="133px"
                                    src={Emp_data_pic}
                                  ></Image>
                                  <Div
                                    fontSize="14px"
                                    color="#2E2E2E"
                                    fontFamily="'Open Sans',sans-serif"
                                    lineHeight="20px"
                                    fontWeight="400"
                                  >
                                    No notes has been created to view it’s
                                    summary
                                  </Div>
                                </Div>
                              </TableBodyCell>
                            </TableBody>
                          )}
                        </Table>
                      </TableBodyCell>
                    </Tablerow>
                  ) : (
                    ""
                  )}

                  {collapsedRows1.includes(index) ? (
                    <Tablerow bbtom={true} textAlign="center">
                      <TableBodyCell
                        colSpan="5"
                        padding="3px 24px 15px 22px "
                        width="100% !important"
                        borderBottom={
                          collapsedRows1.includes(index) && "1px solid #E2E8ED"
                        }
                      >
                        <Div
                          background="#FFFFFF"
                          boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.25)"
                          borderRadius="8px"
                        >
                          <Div padding="17px 0px 23px 29px" textAlign="left">
                            <FormLabel
                              fontSize="16px"
                              color="#2C7BE5"
                              fontFamily="'Open Sans', sans-serif"
                              lineHeight="20px"
                              fontWeight="600"
                            >
                              Summary
                            </FormLabel>
                          </Div>
                          <Table border="none !important">
                            <TableHead background="#F1F5F8">
                              <Tablerow
                                fontFamily="Open Sans, sans-serif"
                                fontWeight="600"
                                fontSize="14px"
                                color="#2C7BE5"
                                lineHeight="20px"
                                textAlign="center"
                                colspan="3"
                              >
                                <TableHeadCell
                                  width="25%"
                                  paddingBottom="18px"
                                  paddingTop="18px"
                                  paddingRight="20px"
                                >
                                  Date
                                </TableHeadCell>

                                <TableHeadCell
                                  width="50%"
                                  paddingBottom="18px"
                                  paddingTop="18px"
                                  paddingRight="20px"
                                >
                                  Description
                                </TableHeadCell>
                                <TableHeadCell
                                  width="25%"
                                  paddingLeft="1px"
                                  paddingBottom="18px"
                                  paddingTop="18px"
                                  paddingRight="20px"
                                >
                                  Action
                                </TableHeadCell>
                              </Tablerow>
                            </TableHead>
                            <TableBody>
                              {SummaryDetails[0].details.length !== 0 ? (
                                SummaryDetails[0].details.map(
                                  (details, index) => (
                                    <Tablerow
                                      borderBottom="1px solid #E2E8ED"
                                      fontFamily="Open Sans, sans-serif"
                                      fontWeight="400"
                                      fontSize="14px"
                                      color="#2E2E2E"
                                      lineHeight="20px"
                                      textAlign="center"
                                      colspan="3"
                                    >
                                      <TableBodyCell
                                        textAlign="center"
                                        paddingTop="12px"
                                        paddingBottom="12px"
                                        paddingLeft="20px"
                                        paddingRight="16px"
                                      >
                                        {details.date1}
                                      </TableBodyCell>

                                      <TableBodyCell
                                        paddingTop="12px"
                                        paddingBottom="12px"
                                        paddingLeft="16px"
                                        paddingRight="20px"
                                      >
                                        {details.notes !== ""
                                          ? details.notes
                                          : ""}
                                      </TableBodyCell>
                                      <TableBodyCell
                                        textAlign="center"
                                        paddingTop="12px"
                                        paddingBottom="12px"
                                        paddingLeft="16px"
                                        paddingRight="20px"
                                      >
                                        <Div>
                                          {
                                            <SpanTag
                                              gap="20px"
                                              display="flex"
                                              justifyContent="center"
                                            >
                                              <AiOutlineEdit
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  color: "#2C7BE5",
                                                  marginLeft: "-5px",
                                                  cursor:
                                                    SingPatDetails.enrollment_status !==
                                                    "4"
                                                      ? "pointer"
                                                      : "not-allowed",
                                                }}
                                                onClick={() =>
                                                  handleEdit(details)
                                                }
                                              />
                                              <FiTrash2
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  color: "#B00020",
                                                  marginLeft: "5px",
                                                  fill: "#ffffff",
                                                  stroke: "#f24822",
                                                  cursor:
                                                    SingPatDetails.enrollment_status !==
                                                    "4"
                                                      ? "pointer"
                                                      : "not-allowed",
                                                }}
                                                onClick={() =>
                                                  handleDelete(
                                                    details.note_id,
                                                    details.eid
                                                  )
                                                }
                                              />
                                            </SpanTag>
                                          }
                                        </Div>
                                      </TableBodyCell>
                                    </Tablerow>
                                  )
                                )
                              ) : (
                                <Tablerow height="168px">
                                  <TableBodyCell textAlign="center" colSpan={5}>
                                    <Div>
                                      <Image
                                        height="100px"
                                        width="100px"
                                        src={Emp_data_pic}
                                      ></Image>
                                    </Div>
                                    <Div
                                      fontSize="14px"
                                      color="#2E2E2E"
                                      fontFamily="'Open Sans',sans-serif"
                                      lineHeight="20px"
                                      fontWeight="400"
                                    >
                                      No Summary to be shown as the notes has
                                      not been created
                                    </Div>
                                  </TableBodyCell>
                                </Tablerow>
                              )}
                            </TableBody>
                          </Table>
                        </Div>
                      </TableBodyCell>
                    </Tablerow>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))
            ) : (
              <Tablerow>
                <TableBodyCell textAlign="center" colSpan={5}>
                  <Div>
                    <Image
                      height="133px"
                      width="133px"
                      src={Emp_data_pic}
                    ></Image>
                    <Div
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      lineHeight="20px"
                      fontWeight="400"
                    >
                      No notes has been created to view it’s summary
                    </Div>
                  </Div>
                </TableBodyCell>
              </Tablerow>
            )}
          </TableBody>
        </Table>
      </Div>

      {show && (
        <>
          <Notes
            encountDetails={encountDetails}
            show={show}
            close={handleClose}
            clickFrom={"encounter"}
          ></Notes>
        </>
      )}
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};
export default memo(EncounterSheet);
