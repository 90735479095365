import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Div, PageSubTitle } from "../StyledComponents";
import Chart from "chart.js/auto";
const Overview = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const chartRef = useRef(null);
  const countData = useSelector((state) => state.Dashboard.chartData);
  //-------------------------------------------State and Variables End-------------------------------------------------------- //
  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const labels = [
      ["Total", "Patients"],
      ["New", "Patients"],
      ["Enrolled", "Patients"],
      ["Pending", "Patients"],
      ["Unenrolled", "Patients"],
      "Users",
    ];
    const data = {
      labels: labels,
      datasets: countData && [
        {
          data: [
            countData.Total_patients,
            countData.new_patients,
            countData.enrolled_patients,
            countData.Pending_patients,
            countData.unenrolled_patients,
            countData.users,
          ],

          backgroundColor: "#2C7BE5",
          borderColor: "#2C7BE5",
          borderWidth: 1,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      barPercentage: 0.4,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: "nearest",
          position: "average",
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            fontSize: 12,
            maxTicksLimit: 10,
            minTicksLimit: 5,
          },
        },

        y: {
          border: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            display: true,
            maxTicksLimit: 7,
            minTicksLimit: 3,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    // Create a new bar chart instance
    const newChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
    });
    return () => {
      // Cleanup: Destroy the chart when the component unmounts
      newChart.destroy();
    };
  }, []);

  useEffect(() => {
    const outerBox = document.querySelector(".outer-box");
    const innerBox = document.querySelector(".inner-box");

    const updateInnerBoxHeight = () => {
      const newInnerBoxHeight =
        (innerBox !== null &&
          outerBox !== null &&
          innerBox.offsetHeight / outerBox.offsetHeight) * 100;

      if (innerBox !== null && outerBox !== null) {
        innerBox.style.height = newInnerBoxHeight + "%";
      }
    };

    updateInnerBoxHeight();

    window.addEventListener("resize", updateInnerBoxHeight);

    return () => {
      window.removeEventListener("resize", updateInnerBoxHeight);
    };
  }, []);

  // ----------------------------------------- UseEffect End ------------------------------------------------------- //
  return (
    <>
      <Div
        className="outer-box"
        gridArea="Overview"
        width="100%"
        borderRadius="8px"
        border="1px solid rgba(46, 46, 46, 0.25)"
        backgroundColor="#fff"
        padding="20px"
      >
        <PageSubTitle
          color="#2E2E2E"
          fontSize="18px"
          fontWeight="600"
          lineHeight="normal"
          padding="10px 20px 14px 16px"
        >
          Overview
        </PageSubTitle>
        <Div height="0px" className="canvas_dashboard inner-box">
          <canvas ref={chartRef} height="328px"></canvas>
        </Div>
      </Div>
    </>
  );
};

export default Overview;
