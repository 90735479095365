import React from "react";
import { Container, Div } from "../StyledComponents";
import LeftNav from "../LeftNav";
import TopNav from "../TopNav";
import BillingSheet from "./BillingSheet";

const Billing = () => {
  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" background="#F1F5F8">
        <Div background="#FFFFFF" padding="20px" margin="20px">
          <BillingSheet />
        </Div>
      </Div>
    </Container>
  );
};
export default Billing;
