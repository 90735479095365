import { createSlice } from "@reduxjs/toolkit";

export const ListSlice = createSlice({
    name: "DropDownList",
    initialState: {
        StateList: [],
        LangList: [],
        RaceList: [],
        EthList: [],
    },
    reducers: {
        GetStateListDetail: (state) => {
            return state;
        },
        SetStateListDetail: (state, action) => {
            state.StateList = action.payload;
        },
        GetLangListDetail: (state) => {
            return state;
        },
        SetLangListDetail: (state, action) => {
            state.LangList = action.payload;
        },
        GetRaceListDetail: (state) => {
            return state;
        },
        SetRaceListDetail: (state, action) => {
            state.RaceList = action.payload;
        },
        GetEthListDetail: (state) => {
            return state;
        },
        SetEthListDetail: (state, action) => {
            state.EthList = action.payload;
        },
    },
});

export const {
    GetStateListDetail,
    SetStateListDetail,
    GetLangListDetail,
    SetLangListDetail,
    GetRaceListDetail,
    SetRaceListDetail,
    GetEthListDetail,
    SetEthListDetail,
} = ListSlice.actions;

export default ListSlice.reducer;