import React, { useState, useEffect, useRef } from "react";
import MultiSelect from "./MultiSelect/index";
import { BsXLg } from "react-icons/bs";
import moment from "moment";
import {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Div,
  Span,
  Image,
  PageSubTitle,
  FormGroup,
  Button,
} from "../../../StyledComponents";
import { Input, DeviceContainer } from "./MultiSelect/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  AddDevices,
  DeviceResponse,
  GetDeviceConnection,
  GetDisconnection,
  GetSerialCheck,
  GetTokenCheck,
  SetSerialCheck,
  SetTokenCheck,
  getDeviceList,
  setIntegrationResponse,
  setMarsonikSource,
} from "../../../../StateManagement/Reducers/PatientDemoState";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../../MiddleWare/EncryptDecrypt";
import MarsonikModal from "./MarsonikModal";
import Confirmation from "./Confirmation";
import { useParams } from "react-router-dom";
import DBHost from "../../../../Config/DBHost";

const AddDevice = (props) => {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const dispatch = useDispatch();
  const params = useParams();

  const pid = params.pid;

  // let DBHost = "";

  // let firstDot = window.location.hostname.split(".");

  // if (firstDot[0] === "localhost" || firstDot[0] === "mumbairpm") {
  //   DBHost = "default";
  // } else {
  //   DBHost = firstDot[0];
  // }
  const All_BP_Options = [
    { value: "BP1", name: "Terra App - Omron" },
    { value: "BP2", name: "Bodytrace Blood Pressure Monitor" },
    { value: "BP3", name: "iBlood Pressure" },
    { value: "BP4", name: "iBlood Pressure plus" },
    { value: "BP5", name: "iBlood Pressure 2.0" },
    { value: "BP6", name: "iBlood Pressure Classic" },
    { value: "BP7", name: "Marsonik" },
    { value: "BP8", name: "Tenovi Digital Blood Pressure Monitor" },
  ];
  const All_HR_Options = [{ value: "HR1", name: "Terra App - Omron" }];
  const All_CG_Options = [
    { value: "CG1", name: "iGlucose" },
    { value: "CG2", name: "Marsonik Blood Glucose Monitor" },
    { value: "CG3", name: "Tenovi Blood Glucose Meter" },
    { value: "CG4", name: "Dexcom" },
    { value: "CG5", name: "Ambrosia App" },
    { value: "CG6", name: "Tidepool" },
    { value: "CG7", name: "Terra App - Freestyle Libre" },
  ];
  const All_PO_Options = [
    { value: "PO1", name: "iPlulse Ox" },
    { value: "PO2", name: "Marsonic" },
    { value: "PO3", name: "Tenovi Pulse Oximeter" },
  ];
  const All_WS_options = [
    { value: "WS1", name: "Terra App - Omron" },
    { value: "WS2", name: "Bodytrace Weight Scale" },
    { value: "WS3", name: "Tenovi Weigh Scale" },
    { value: "WS4", name: "iScale" },
    { value: "WS5", name: "iScale plus" },
    { value: "WS6", name: "Marsonik" },
  ];
  // Integration devices list start
  const ExcludedSerialCheckList = [
    "WS1",
    "WS6",
    "PO2",
    "CG2",
    "CG4",
    "CG5",
    "CG6",
    "CG7",
    "HR1",
    "BP1",
    "BP7",
  ];
  // Integration devices list end
  // Exclude Integration Response Empty device start
  const ExcludeIntegrationEmpty = [
    "CG7",
    "BP1",
    "HR1",
    "WS1",
    "CG4",
    "BP7",
    "WS6",
  ];
  // Exclude Integration Response Empty device End
  const [BPOptions, setBPOptions] = useState([]);
  const [HROptions, setHROptions] = useState([]);
  const [CGOptions, setCGOptions] = useState([]);
  const [POOptions, setPOOptions] = useState([]);
  const [WSOptions, setWSOptions] = useState([]);
  const [error, setError] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const AllOptions = [
    BPOptions,
    HROptions,
    CGOptions,
    POOptions,
    WSOptions,
  ].flat();
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [serialCheck, setSerialCheck] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [connectionPending, setConnectionPending] = useState(false);
  const [showMarsonik, setMarsonik] = useState(false);
  const [ConnectingOption, setConnectingOption] = useState({});
  const [delID, setID] = useState(true);
  const [conval, setConVal] = useState("");
  const [disconnect, setDisconnect] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const assignedDevices = props.enrolledDevices;
  const patientData = useSelector((state) => state.Patient.SinglePatient);
  const addDeviceResponse = useSelector((state) => state.PatientDemo.DeviceRes);
  const TokenResp = useSelector((state) => state.PatientDemo.tokenCheck);

  const serialResponse = useSelector(
    (state) => state.PatientDemo.serialNoCheck
  );
  const [dis, setDis] = useState(false);
  let file = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const marsoniksrc = useSelector((state) => state.PatientDemo.marsoniksource);
  const Integrationres = useSelector(
    (state) => state.PatientDemo.IntegrationResponse
  );
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//
  const BPOptionsHandler = (options, index = "") => {
    if (options.length > 0) {
      let si = options[index]?.serialNo;
      let id = options[index]?.id;

      if (!id) {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setBPOptions(a[0]);
        // heart rate monitoring logic start
        if (HROptions.length === 0 && a[0][0]?.serialNo === "") {
          setHROptions(a[0]);
        } else if (!HROptions[0]?.value.startsWith("HR")) {
          setHROptions(a[0]);
        }
        // heart rate monitoring logic end
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      } else {
        if (
          options[index]?.serialNo ===
          assignedDevices["BPvalues"][index].serialNo
        ) {
          setBPOptions(options);
        } else {
          let a = checkAlreadyEntered(
            options,
            index,
            CGOptions,
            BPOptions,
            HROptions,
            POOptions,
            WSOptions,
            si
          );
          setBPOptions(a[0]);
          if (a[1]) {
            setSerialCheck(options[index]?.serialNo);
            setDeviceType(options[index]?.value);
          }
        }
      }
    } else {
      setBPOptions([]);
      // setConnectionPending(false);
      // heart rate monitoring logic start
      if (HROptions[0]?.value.startsWith("BP") && POOptions.length === 0) {
        setHROptions([]);
      } else if (
        POOptions.length > 0 &&
        !HROptions[0]?.value.startsWith("HR")
      ) {
        setHROptions(POOptions);
      }
      // heart rate monitoring logic end
    }
  };

  const HROptionsHandler = (options, index = "") => {
    if (options.length > 0) {
      let si = options[index]?.serialNo;
      let id = options[index]?.id;
      if (!id) {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setHROptions(a[0]);
        if (
          HROptions[0]?.value.startsWith("BP") &&
          !a[0][0]?.value.startsWith("HR1")
        ) {
          setBPOptions(a[0]);
        }
        // if (HROptions[0]?.value.startsWith("PO")) {
        //   setPOOptions(a[0]);
        // }
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      } else {
        if (
          options[index]?.serialNo ===
          assignedDevices["HRvalues"][index].serialNo
        ) {
          setHROptions(options);
        } else {
          let a = checkAlreadyEntered(
            options,
            index,
            CGOptions,
            BPOptions,
            HROptions,
            POOptions,
            WSOptions,
            si
          );
          setHROptions(a[0]);
          if (a[1]) {
            setSerialCheck(options[index]?.serialNo);
            setDeviceType(options[index]?.value);
          }
        }
      }
    } else {
      // setConnectionPending(false);
      setHROptions([]);
    }
  };

  const CGOptionsHandler = (options, index = "") => {
    if (options.length > 0) {
      let si = options[index]?.serialNo;
      let id = options[index]?.id;
      if (!id) {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );

        setCGOptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      } else {
        if (
          options[index]?.serialNo ===
          assignedDevices["CGvalues"][index].serialNo
        ) {
          setCGOptions(options);
        } else {
          let a = checkAlreadyEntered(
            options,
            index,
            CGOptions,
            BPOptions,
            HROptions,
            POOptions,
            WSOptions,
            si
          );
          setCGOptions(a[0]);
          if (a[1]) {
            setSerialCheck(options[index]?.serialNo);
            setDeviceType(options[index]?.value);
          }
        }
      }
    } else {
      // setConnectionPending(false);
      setCGOptions([]);
    }
  };

  const POOptionsHandler = (options, index = "") => {
    if (options.length > 0) {
      let si = options[index]?.serialNo;
      let id = options[index]?.id;
      if (!id) {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setPOOptions(a[0]);
        // heart rate monitoring logic start
        if (HROptions.length === 0 && a[0][0]?.serialNo === "") {
          setHROptions(a[0]);
        } else if (HROptions[0]?.value.startsWith("PO")) {
          setHROptions(a[0]);
        }
        // heart rate monitoring logic end
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      } else {
        if (
          options[index]?.serialNo ===
          assignedDevices["POvalues"][index].serialNo
        ) {
          setPOOptions(options);
        } else {
          let a = checkAlreadyEntered(
            options,
            index,
            CGOptions,
            BPOptions,
            HROptions,
            POOptions,
            WSOptions,
            si
          );
          setPOOptions(a[0]);
          if (a[1]) {
            setSerialCheck(options[index]?.serialNo);
            setDeviceType(options[index]?.value);
          }
        }
      }
    } else {
      // setConnectionPending(false);
      setPOOptions([]);
      // heart rate monitoring logic start
      if (HROptions[0]?.value.startsWith("PO") && BPOptions.length === 0) {
        setHROptions([]);
      }
      //  else if (
      //   BPOptions.length > 0 &&
      //   !HROptions[0]?.value.startsWith("HR")
      // ) {
      //   setHROptions(BPOptions);
      // }
      // heart rate monitoring logic end
    }
  };

  const WSOptionsHandler = (options, index = "") => {
    if (options.length > 0) {
      let si = options[index]?.serialNo;
      let id = options[index]?.id;
      if (!id) {
        let a = checkAlreadyEntered(
          options,
          index,
          CGOptions,
          BPOptions,
          HROptions,
          POOptions,
          WSOptions,
          si
        );
        setWSOptions(a[0]);
        if (a[1]) {
          setSerialCheck(options[index]?.serialNo);
          setDeviceType(options[index]?.value);
        }
      } else {
        if (
          options[index]?.serialNo ===
          assignedDevices["WSvalues"][index].serialNo
        ) {
          setWSOptions(options);
        } else {
          let a = checkAlreadyEntered(
            options,
            index,
            CGOptions,
            BPOptions,
            HROptions,
            POOptions,
            WSOptions,
            si
          );
          setWSOptions(a[0]);
          if (a[1]) {
            setSerialCheck(options[index]?.serialNo);
            setDeviceType(options[index]?.value);
          }
        }
      }
    } else {
      // setConnectionPending(false);
      setWSOptions([]);
    }
  };

  const serialNumberValidation = (array) => {
    // to check if it has any errors before saving
    let a = array.map((row, index) => {
      if (row?.id && row.id !== "") {
        // edit already entered
        if (row.serialNo === "" && !row.error) {
          if (ExcludedSerialCheckList.includes(row.value)) {
            // integrated devices block
            return row;
          } else {
            return { ...row, error: true, Msg: "Enter a valid Serial No" };
          }
        } else if (row.serialNo !== "" && !row.error) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      } else {
        // Insert time
        if (row.serialNo === "" && !row.error) {
          if (ExcludedSerialCheckList.includes(row.value)) {
            // integrated devices

            if (row?.connected === true && row.Msg === "") return row;
            else {
              return { ...row, error: true, Msg: "Pls connect device" };
            }
          } else {
            return { ...row, error: true, Msg: "Enter a valid Serial No" };
          }
        } else if (row.serialNo !== "" && !row.error) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      }
    });
    setError((prevError) => true);
    return a;
  };

  const checkAlreadyEntered = (
    array,
    index,
    array0,
    array1,
    array2,
    array3,
    array4,
    serial
  ) => {
    // to check for repeated entries
    setIsFormSubmitted(false);
    if (serial !== "" && serial !== undefined) {
      const isInArray0 = array0.some(
        (obj) =>
          obj["serialNo"] &&
          obj["serialNo"].trim() === array[index]?.serialNo.trim()
      );
      if (isInArray0) {
        let a = [...array];
        let obj = {
          ...array[index],
          error: true,
          Msg: "Already Entered Pls check",
        };
        a[index] = obj;
        let b = [];
        b[0] = a;
        b[1] = false;
        return b;
      } else {
        const isInArray1 = array1.some(
          (obj) =>
            obj["serialNo"] &&
            obj["serialNo"].trim() === array[index]?.serialNo.trim()
        );
        if (isInArray1) {
          let a = [...array];
          let obj = {
            ...array[index],
            error: true,
            Msg: "Already Entered Pls check",
          };
          a[index] = obj;

          let b = [];
          b[0] = a;
          b[1] = false;
          return b;
        } else {
          const isInArray2 = array2.some(
            (obj) =>
              obj["serialNo"] &&
              obj["serialNo"].trim() === array[index]?.serialNo.trim()
          );
          if (isInArray2) {
            let a = [...array];
            let obj = {
              ...array[index],
              error: true,
              Msg: "Already Entered Pls check",
            };
            a[index] = obj;

            let b = [];
            b[0] = a;
            b[1] = false;
            return b;
          } else {
            const isInArray3 = array3.some(
              (obj) =>
                obj["serialNo"] &&
                obj["serialNo"].trim() === array[index]?.serialNo.trim()
            );
            if (isInArray3) {
              let a = [...array];
              let obj = {
                ...array[index],
                error: true,
                Msg: "Already Entered Pls check",
              };
              a[index] = obj;

              let b = [];
              b[0] = a;
              b[1] = false;
              return b;
            } else {
              const isInArray4 = array4.some(
                (obj) =>
                  obj["serialNo"] &&
                  obj["serialNo"].trim() === array[index]?.serialNo.trim()
              );
              if (isInArray4) {
                let a = [...array];
                let obj = {
                  ...array[index],
                  error: true,
                  Msg: "Already Entered Pls check",
                };
                a[index] = obj;

                let b = [];
                b[0] = a;
                b[1] = false;
                return b;
              } else {
                let b = [];
                b[0] = array;
                b[1] = true;
                return b;
              }
            }
          }
        }
      }
    } else {
      let b = [];
      b[0] = array;
      b[1] = true;
      return b;
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    setDis(true);
    if (!AllOptions.length) {
      setError(true);
      setModalMsg("Please select atleast one device");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
      }, 3000);
    } else {
      const updatedBPOptions = serialNumberValidation(BPOptions);
      const updatedHROptions = serialNumberValidation(HROptions);
      const updatedCGOptions = serialNumberValidation(CGOptions);
      const updatedPOOptions = serialNumberValidation(POOptions);
      const updatedWSOptions = serialNumberValidation(WSOptions);

      setBPOptions(updatedBPOptions);
      setHROptions(updatedHROptions);
      setCGOptions(updatedCGOptions);
      setPOOptions(updatedPOOptions);
      setWSOptions(updatedWSOptions);

      const allArraysPassedValidation =
        updatedBPOptions.every((row) => !row.error && row.Msg === "") &&
        updatedHROptions.every((row) => !row.error && row.Msg === "") &&
        updatedCGOptions.every((row) => !row.error && row.Msg === "") &&
        updatedPOOptions.every((row) => !row.error && row.Msg === "") &&
        updatedWSOptions.every((row) => !row.error && row.Msg === "");
      if (allArraysPassedValidation) {
        setIsFormSubmitted(true);
      } else {
        setIsFormSubmitted(false);
      }
    }
    setError(!AllOptions.length);
    setTimeout(() => {
      setDis(false);
    }, 1500);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Integration devices connecting function
  const handleConnection = (option) => {
    setConnectionPending(true);
    setConnectingOption(option);
    let article = {
      site: DBHost.DBHost,
      pid: props.pid,
      user_id: user_id,
    };

    if (
      option?.name.includes("Marsonik") ||
      option?.name.includes("Marsonic")
    ) {
      let enc = Encrypt_Value(article, "rpm");
      dispatch(GetDeviceConnection({ device: "Marsonik", data: { enc } }));
    } else {
      if (
        option?.name.includes("Ambrosia App") ||
        option?.name.includes("Tidepool")
      ) {
        article.email = option.email;
        article.password = option.password;
      }
      let enc = Encrypt_Value(article, "rpm");
      dispatch(GetDeviceConnection({ device: option.name, data: { enc } }));
    }
  };

  const handleMessage = (e) => {
    let data = e.data;

    if (data["ClientId"] && data["ClientId"] !== "") {
      const temp = [{ ...ConnectingOption }];
      temp[0].connected = true;
      temp[0].error = false;
      temp[0].Msg = "";
      temp[0].Status = 1;
      const setValueOptions = (value, setOptions) => {
        if (temp[0]?.value.includes(value)) {
          setOptions(temp);
        }
      };

      setValueOptions("BP", setBPOptions);
      setValueOptions("CG", setCGOptions);
      setValueOptions("HR", setHROptions);
      setValueOptions("PO", setPOOptions);
      setValueOptions("WS", setWSOptions);
      setConnectionPending(false);
      dispatch(setMarsonikSource(""));
      setMarsonik(false);
    } else {
      const temp = [{ ...ConnectingOption }];
      temp[0].connected = true;
      temp[0].error = false;
      temp[0].Msg = "";
      temp[0].Status = 2;
      const setValueOptions = (value, setOptions) => {
        if (temp[0]?.value.includes(value)) {
          setOptions(temp);
        }
      };

      setValueOptions("BP", setBPOptions);
      setValueOptions("CG", setCGOptions);
      setValueOptions("HR", setHROptions);
      setValueOptions("PO", setPOOptions);
      setValueOptions("WS", setWSOptions);
      setConnectionPending(false);
      dispatch(setMarsonikSource(""));
      setMarsonik(false);
      // setConnectionPending(false);
    }
  };
  const checkDel = (newState, value) => {
    if (ConnectingOption.value === value) setID(newState);
  };

  const DisconnectConfirm = (data, option) => {
    setDisconnect(data);
    setSelectedOption(option);
  };
  const handleDisconnection = (choice) => {
    if (choice) {
      let article = {
        site: DBHost.DBHost,
        pid: props.pid,
        user_id: user_id,
        value: selectedOption.value,
      };
      let deviceName = "";

      if (
        selectedOption?.name.includes("Marsonik") ||
        selectedOption?.name.includes("Marsonic")
      ) {
        deviceName = "Marsonik";
      } else if (selectedOption?.serialNo !== "") {
        article.serialNo = selectedOption.serialNo;
        article.device = selectedOption?.name;
        article.value = selectedOption?.value;
        deviceName = "Others";
      } else {
        deviceName = selectedOption?.name;
      }
      let enc = Encrypt_Value(article, "rpm");
      dispatch(GetDisconnection({ device: deviceName, data: { enc } }));
    } else {
    }
    setDisconnect(false);
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  useEffect(() => {
    if (isFormSubmitted && !error) {
      dispatch(
        AddDevices({
          uid: user_id,
          pid: props.pid,
          BPOptions: BPOptions,
          HROptions: HROptions,
          CGOptions: CGOptions,
          POOptions: POOptions,
          WSOptions: WSOptions,
          src: props.pid === 0 ? "Devices" : "Demo",
        })
      );
    }
  }, [error, isFormSubmitted]);

  useEffect(() => {
    if (assignedDevices["BPvalues"].length <= 0) {
      setBPOptions([]);
    } else {
      const updatedBPOptions = [...assignedDevices["BPvalues"]];

      setBPOptions(updatedBPOptions);
    }
    if (assignedDevices["HRvalues"].length <= 0) {
      setHROptions([]);
    } else {
      const updatedHROptions = [...assignedDevices["HRvalues"]];
      setHROptions(updatedHROptions);
    }
    if (assignedDevices["CGvalues"].length <= 0) {
      setCGOptions([]);
    } else {
      const updatedCGOptions = [...assignedDevices["CGvalues"]];
      setCGOptions(updatedCGOptions);
    }
    if (assignedDevices["POvalues"].length <= 0) {
      setPOOptions([]);
    } else {
      const updatedPOOptions = [...assignedDevices["POvalues"]];
      setPOOptions(updatedPOOptions);
    }
    if (assignedDevices["WSvalues"].length <= 0) {
      setWSOptions([]);
    } else {
      const updatedWSOptions = [...assignedDevices["WSvalues"]];
      setWSOptions(updatedWSOptions);
    }

    return () => {
      setBPOptions([]);
      setHROptions([]);
      setCGOptions([]);
      setPOOptions([]);
      setWSOptions([]);
    };
  }, [assignedDevices]);

  useEffect(() => {
    if (addDeviceResponse !== "") {
      if (addDeviceResponse === "Devices added sucessfully") {
        setModalAlerShow(true);
        setModalMsg("Devices added Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          props.onHide();
          props.showOverflow();
        }, 1000);
        dispatch(DeviceResponse(""));
        dispatch(setIntegrationResponse(""));
        dispatch(SetTokenCheck(""));
        dispatch(SetSerialCheck(""));
      }
    }
    if (addDeviceResponse !== "") {
      if (addDeviceResponse === "Unable to add Devices") {
        setModalAlerShow(true);
        setModalMsg("Unable to add Devices");
        setStatus("retry");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);
        dispatch(DeviceResponse(""));
        dispatch(setIntegrationResponse(""));
        dispatch(SetTokenCheck(""));
        dispatch(SetSerialCheck(""));
      } else if (addDeviceResponse === "error_tenovi") {
        setModalAlerShow(true);
        setModalMsg("Unable to add tenovi device");
        setStatus("retry");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          props.onHide();
        }, 1500);
        dispatch(DeviceResponse(""));
        dispatch(setIntegrationResponse(""));
        dispatch(SetTokenCheck(""));
        dispatch(SetSerialCheck(""));
      }
    }
    return () => {
      setBPOptions([]);
      setHROptions([]);
      setCGOptions([]);
      setPOOptions([]);
      setWSOptions([]);
    };
  }, [addDeviceResponse]);

  useEffect(() => {
    if (marsoniksrc.includes("https")) {
      setMarsonik(true);
      // setConnectionPending(false);
    }

    if (Integrationres !== "") {
      if (Integrationres === "Deleted") {
        setModalAlerShow(true);
        setModalMsg("Device Removed Successfully");
        setStatus("success");
        dispatch(getDeviceList(pid));
        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
          dispatch(setIntegrationResponse(""));
        }, 1500);
      } else {
        const temp = [{ ...ConnectingOption }];
        if (Integrationres === "Email sent") {
          temp[0].connected = Integrationres === "Email sent";
          temp[0].error = !temp[0].connected;
          temp[0].Msg = temp[0].error ? "Email is not sent" : "Email sent";

          setModalAlerShow(true);
          setModalMsg("Email sent Successfully!");
          setStatus("success");

          setTimeout(() => {
            setModalAlerShow(false);
            setModalMsg("");
            setStatus("");
          }, 1500);
        } else {
          if (
            Integrationres === "Succeeded" ||
            Integrationres === "verification failed" ||
            Integrationres === "failed"
          ) {
            setConVal("");
            setConnectionPending(false);
          }
          temp[0].connected = Integrationres === "Succeeded";
          temp[0].error = !temp[0].connected;
          temp[0].Msg = temp[0].error ? "Devices is not Connected" : "";
          if (
            temp[0].name.includes("Ambrosia App") ||
            temp[0].name.includes("Tidepool")
          ) {
            temp[0].Status = Integrationres === "Succeeded" ? 1 : 2;
          } else {
            temp[0].Status = 0;
          }
          if (
            Integrationres.includes("failed") ||
            Integrationres.includes("invalid")
          ) {
            setModalAlerShow(true);
            setModalMsg("Integration failed");
            setStatus("retry");

            setTimeout(() => {
              setModalAlerShow(false);
              setModalMsg("");
              setStatus("");
            }, 1500);
          }

          if (TokenResp !== "") {
            temp[0].Status = TokenResp;
          }
        }

        const setValueOptions = (value, setOptions) => {
          if (temp[0]?.value.includes(value)) {
            setOptions(temp);
          }
        };

        if (HROptions.length > 0) {
          if (HROptions[0]?.value.includes(temp[0]?.value)) {
            // setHROptions(temp);
            let a = JSON.parse(JSON.stringify(HROptions));
            a[0].connected = temp[0].connected;
            a[0].error = temp[0].error;
            a[0].Msg = temp[0].Msg;
            if (TokenResp !== "") {
              a[0].Status = TokenResp;
            }
            setHROptions(a);
          }
        }

        setValueOptions("BP", setBPOptions);
        setValueOptions("CG", setCGOptions);
        setValueOptions("HR", setHROptions);
        setValueOptions("PO", setPOOptions);
        setValueOptions("WS", setWSOptions);
        if (!ExcludeIntegrationEmpty.includes(ConnectingOption.value)) {
          dispatch(setIntegrationResponse(""));
        }
      }
    }
  }, [marsoniksrc, Integrationres]);

  useEffect(() => {
    let interval;

    const checkToken = () => {
      const article = { site: DBHost.DBHost, pid: props.pid };
      const enc = Encrypt_Value(article, "rpm");
      const deviceName = ConnectingOption.name;
      dispatch(GetTokenCheck({ device: deviceName, data: { enc } }));
    };

    const startInterval = () => {
      interval = setInterval(() => {
        if (TokenResp === "0" || TokenResp === 0) {
          checkToken();
        }
      }, 1000);
    };

    if (delID === true && Integrationres === "Email sent") {
      checkToken();
      startInterval();
      return () => clearInterval(interval);
    }
    if (Integrationres === "Succeeded") {
      dispatch(setIntegrationResponse(""));
    }
  }, [delID, Integrationres, TokenResp, dispatch]);

  useEffect(() => {
    if (serialResponse === "exists") {
      let a = BPOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let b = CGOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let c = HROptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let d = POOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let f = WSOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: true, Msg: "Serial No already exists" };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      setWSOptions(f);
      setPOOptions(d);
      setHROptions(c);
      setCGOptions(b);
      setSerialCheck("");
      setDeviceType("");
      setBPOptions(a);
    } else if (serialResponse === "not exists") {
      let a = BPOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let b = CGOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let c = HROptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let d = POOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      let f = WSOptions.map((row) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return { ...row, error: false };
        }

        return row;
      });
      setWSOptions(f);
      setPOOptions(d);
      setHROptions(c);
      setCGOptions(b);
      setBPOptions(a);

      setSerialCheck("");
      setDeviceType("");
    } else if (serialResponse === "invalid") {
      let a = BPOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let b = CGOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let c = HROptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let d = POOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      let f = WSOptions.map((row, index) => {
        if (row.serialNo === serialCheck && row.serialNo !== "") {
          return {
            ...row,
            error: true,
            Msg: "Please check the device and serial No",
          };
        } else if (row.error === undefined) {
          return { ...row, error: false, Msg: "" };
        } else {
          return row;
        }
      });
      setWSOptions(f);
      setPOOptions(d);
      setHROptions(c);
      setCGOptions(b);
      setSerialCheck("");
      setDeviceType("");
      setBPOptions(a);
    }
    if (serialResponse === "") {
      return () => {
        setBPOptions([]);
        setHROptions([]);
        setCGOptions([]);
        setPOOptions([]);
        setWSOptions([]);
      };
    }
    setDis(false);
  }, [serialResponse]);

  useEffect(() => {
    // to check already assigned devices
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (!ExcludedSerialCheckList.includes(deviceType)) {
          if (serialCheck !== "") {
            dispatch(
              GetSerialCheck({
                serial_number: serialCheck,
                src: props.pid === 0 ? "Devices" : "Demo",
                device_value: deviceType,
              })
            );
          }
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [serialCheck]);

  useEffect(() => {
    document.addEventListener("message", handleMessage);

    return () => {
      document.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Div>
      <ModalHeader
        padding="20px 54px 16px 54px"
        borderLess
        justifyContent="space-between"
      >
        <ModalTitle
          lineHight="30px"
          fontSize="22px"
          fontWeight="600"
          color="#0A1629"
        >
          Add Devices
        </ModalTitle>
        <BsXLg
          style={{ color: "#407BFF", cursor: "pointer" }}
          onClick={() => {
            props.onHide();
            dispatch(setIntegrationResponse(""));
            dispatch(SetTokenCheck(""));
            dispatch(SetSerialCheck(""));
          }}
          id="dbrd_ad-crd_cls"
        />
      </ModalHeader>
      <ModalBody
        padding="24px 54px 40px 54px"
        borderTop="1px solid rgba(46, 46, 46, 0.25)"
      >
        {props.pid !== 0 && (
          <Div display="flex" marginBottom="24px">
            {patientData.img_url === null ? (
              <Div
                borderRadius="50%"
                width="50px"
                height="50px"
                backgroundColor="#a8aebe"
                display="inline-block"
                marginRight="16px"
              >
                <Span
                  fontSize="28px"
                  lineHeight="16px"
                  alignItems="center"
                  textAlign="center"
                  color="#ffffff"
                  textShadow="0px 1px 0px rgba(0, 0, 0, 0.15)"
                  position="relative"
                  top="16px"
                  left="1px"
                  fontWeight="600"
                >
                  {patientData.patname.charAt(0).toUpperCase()}
                </Span>
              </Div>
            ) : (
              <Image
                src={file + patientData.img_url}
                alt="image-failed"
                loading="lazy"
                width="50px"
                height="50px"
                borderRadius="50%"
                display="inline-block"
                marginRight="12px"
              />
            )}
            <Div
              fontFamily="'Open Sans',sans-serif"
              fontSize="14px"
              lineHeight="20px"
              color="#2E2E2E"
            >
              <Span fontWeight="600" marginBottom="8px">
                {patientData.patname},Age {patientData.age}
              </Span>
              <Span fontWeight="400">
                {moment(patientData.DOB).format("DD/MM/YYYY")}
              </Span>
            </Div>
          </Div>
        )}
        <DeviceContainer display="flex">
          <DeviceContainer width="50%">
            <Div>
              <PageSubTitle marginBottom="8px">
                Blood Pressure Devices
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  checkDel={checkDel}
                  options={All_BP_Options}
                  selectedOptions={BPOptions}
                  onSelectedOptionsChange={BPOptionsHandler}
                  ExcludedSerialCheckList={ExcludedSerialCheckList}
                  handleConnection={handleConnection}
                  connectionPending={connectionPending}
                  SetDisconnect={DisconnectConfirm}
                  width="378px"
                  setDis={setDis}
                  setConVal={setConVal}
                  conval={conval}
                />
              </Div>
              {BPOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
            <Div>
              <PageSubTitle marginBottom="8px">
                Continuous Glucose Monitors
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  checkDel={checkDel}
                  options={All_CG_Options}
                  selectedOptions={CGOptions}
                  onSelectedOptionsChange={CGOptionsHandler}
                  ExcludedSerialCheckList={ExcludedSerialCheckList}
                  handleConnection={handleConnection}
                  connectionPending={connectionPending}
                  SetDisconnect={DisconnectConfirm}
                  width="378px"
                  setDis={setDis}
                  setConVal={setConVal}
                  conval={conval}
                />
              </Div>
              {CGOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
            <Div>
              <PageSubTitle marginBottom="8px">Weighing Scale</PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  checkDel={checkDel}
                  options={All_WS_options}
                  selectedOptions={WSOptions}
                  onSelectedOptionsChange={WSOptionsHandler}
                  ExcludedSerialCheckList={ExcludedSerialCheckList}
                  handleConnection={handleConnection}
                  connectionPending={connectionPending}
                  SetDisconnect={DisconnectConfirm}
                  width="378px"
                  setDis={setDis}
                  setConVal={setConVal}
                  conval={conval}
                />
              </Div>
              {WSOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
          </DeviceContainer>
          <DeviceContainer width="50%">
            <Div>
              <PageSubTitle marginBottom="8px">
                Heart Rate Monitoring Devices
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  checkDel={checkDel}
                  options={All_HR_Options}
                  selectedOptions={HROptions}
                  onSelectedOptionsChange={HROptionsHandler}
                  ExcludedSerialCheckList={ExcludedSerialCheckList}
                  handleConnection={handleConnection}
                  connectionPending={connectionPending}
                  SetDisconnect={DisconnectConfirm}
                  width="378px"
                  setDis={setDis}
                  setConVal={setConVal}
                  conval={conval}
                />
              </Div>
              {HROptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
            <Div>
              <PageSubTitle marginBottom="8px">
                Pulse Oximeter Devices
              </PageSubTitle>
              <Div display="block" position="relative" marginBottom="24px">
                <MultiSelect
                  checkDel={checkDel}
                  options={All_PO_Options}
                  selectedOptions={POOptions}
                  onSelectedOptionsChange={POOptionsHandler}
                  ExcludedSerialCheckList={ExcludedSerialCheckList}
                  handleConnection={handleConnection}
                  connectionPending={connectionPending}
                  SetDisconnect={DisconnectConfirm}
                  width="378px"
                  setDis={setDis}
                  setConVal={setConVal}
                  conval={conval}
                />
              </Div>
              {POOptions.length === 0 && (
                <>
                  <PageSubTitle
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="22px"
                    color="#2E2E2E"
                    marginBottom="6px"
                  >
                    Serial No
                  </PageSubTitle>
                  <FormGroup marginBottom="40px">
                    <Input
                      border="1px solid #2E2E2E40"
                      borderRadius="4px"
                      width="378px"
                      height="36px"
                      background="rgba(46, 46, 46, 0.1);"
                      disabled
                      className="ipadadddiv"
                    />
                  </FormGroup>
                </>
              )}
            </Div>
          </DeviceContainer>
        </DeviceContainer>
      </ModalBody>
      <ModalFooter padding="24px 40px">
        <Div textAlign="center">
          <Button
            height="36px"
            background="#FFF"
            borderRadius="4px"
            padding="8px 21px"
            color="#2C7BE5"
            border="1px solid #2C7BE5"
            lineHeight="19px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="14px"
            fontWeight="600"
            onClick={() => {
              props.onHide();
              dispatch(setIntegrationResponse(""));
              dispatch(SetTokenCheck(""));
              dispatch(SetSerialCheck(""));
            }}
            cursor="pointer"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            activeBackgroundColor="rgba(44, 123, 229, 0.10)"
          >
            Cancel
          </Button>
          <Button
            marginLeft="40px"
            height="36px"
            background="#2C7BE5"
            borderRadius="4px"
            padding="8px 21px"
            color="#FFFFFF"
            border="none"
            lineHeight="19px"
            fontFamily="'Open Sans',sans-serif"
            fontSize="14px"
            fontWeight="600"
            onClick={handleSave}
            cursor="pointer"
            disabled={dis}
            isDisabled={dis}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Assign
          </Button>
        </Div>
      </ModalFooter>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
      {showMarsonik && (
        <MarsonikModal
          show={showMarsonik}
          HandleModal={() => {
            setMarsonik(false);
            setConnectionPending(false);
            dispatch(setMarsonikSource(""));
          }}
          src={marsoniksrc}
        />
      )}
      {disconnect && (
        <Confirmation
          show={disconnect}
          option={selectedOption.name}
          Disconnect={handleDisconnection}

          // src={marsoniksrc}
        />
      )}
    </Div>
  );
};
export default AddDevice;
