import React, { useState, useEffect } from "react";
import RadioProgress from "./RadioProgress";
import { BsCalendar } from "react-icons/bs";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../../Assets/images/Avatar.png";

import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import {
  PageSubTitle,
  Div,
  Input,
  Text,
  RedStar,
  Image,
  SpanTag,
  ErrorMessage,
} from "../../StyledComponents";
import {
  PatientSubTitle,
  LabelVal,
  Button,
  DropDown,
  DropDownListContainer,
  DropDownList,
  ListItem,
} from "./Styles";

import {
  GetPatEnrollment,
  GetSinglePatDetail,
  GetGenderList,
  GetRaceList,
  GetLanguageList,
  GetMailVerify,
  GetPhoneVerify,
  GetSSNVerify,
  PatientDetails,
} from "../../../StateManagement/Reducers/PatientState";
import UploadProfilePicture from "../../StyledComponents/UploadProfilePicture";
import { FiChevronDown } from "react-icons/fi";
import { validCountryCodes } from "./CountryCodes";
import DBHost from "../../../Config/DBHost";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const PatientEnrollment = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const patId = params.pid;

  const [patDetails, setPatDetails] = useState([]);
  const [validDate1, setValidDate1] = useState(
    moment(new Date(), "DD/MM/YYYY")
  );
  const EthnicityList = [
    { id: 1, title: "Hispanic or Latino" },
    { id: 2, title: "Not Hispanic or Latino" },
    { id: 3, title: "Declined to specify" },
  ];
  const BloodGrList = [
    { id: 1, title: "O possitive", val: "O +" },
    { id: 2, title: "O negative", val: "O -" },
    { id: 3, title: "A possitive", val: "A +" },
    { id: 4, title: "A negative", val: "A -" },
    { id: 5, title: "B possitive", val: "B +" },
    { id: 6, title: "B negative", val: "B -" },
    { id: 7, title: "AB possitive", val: "AB +" },
    { id: 8, title: "AB negative", val: "AB -" },
  ];
  const [inputList, setInputList] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
    ssnNum: "",
    email: "",
    phone: "",
    bloodGr: "",
    race: "",
    ethnicity: "",
    language: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    img_url: "",
  });
  const [inputListErr, setInputListErr] = useState({
    firstName: false,
    lastName: false,
    dob: false,
    gender: false,
    email: false,
    phone: false,
    race: false,
    ethnicity: false,
    address: false,
    city: false,
    state: false,
    zip: false,
  });
  const [isupload, setIsUpload] = useState(false);
  const [mailerr, setmailerr] = useState(false);

  const [showpicker1, setShowPicker1] = useState(false);

  const [imageText, setImageText] = useState("add file");
  const [previewimg1, setpreviewimg1] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [selectValidDate, setSelectValidDate] = useState(false);
  const [dis, setDis] = useState(false);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  const GenderStatusList = useSelector(
    (state) => state.Patient.GenderStatusList
  );
  const RaceStatusList = useSelector((state) => state.Patient.RaceStatusList);
  const EmailVerify = useSelector((state) => state.Patient.MailVerify);
  const PhoneVerify = useSelector((state) => state.Patient.PhoneVerify);
  const SSNVerify = useSelector((state) => state.Patient.SSNVerify);
  const LanguageStatusList = useSelector(
    (state) => state.Patient.LanguageStatusList
  );

  const [raceDrop, setRaceDrop] = useState(false);
  const [ethnicityDrop, setEthnicityDrop] = useState(false);
  const [languageDrop, setLanguageDrop] = useState(false);
  const [bloodDrop, setBloodDrop] = useState(false);
  const [genderDrop, setGenderDrop] = useState(false);
  const [temp, setTemp] = useState(false);
  const [validMailErr, setValidMailErr] = useState(false);
  const [validPhoneErr, setValidPhoneErr] = useState(false);
  const [initialMail, setInitialMail] = useState(false);
  const [initialPhone, setInitialPhone] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [ssnErr, setSsnerr] = useState(false);
  const [validSSNErr, setValidSSNErr] = useState(false);
  const [initialSSN, setInitialSSN] = useState(false);
  const [c_code, setCCode] = useState("+91");
  const [validC_codeErr, setValidC_codeErr] = useState(false);

  let imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const userID = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------------------ Functions Start--------------------------------------------------------- //

  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker1();
    }
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const formatSSN = (ssn) => {
    const cleanedSSN = ssn.replace(/\D/g, "");

    let formatted = cleanedSSN.slice(0, 3);

    if (cleanedSSN.length > 3) {
      formatted += "-" + cleanedSSN.slice(3, 5);
    }

    if (cleanedSSN.length > 5) {
      formatted += "-" + cleanedSSN.slice(5, 9);
    }

    return formatted;
  };

  const inputChange = (e) => {
    if (e.target.name === "firstName")
      setInputListErr((prevState) => ({ ...prevState, firstName: false }));
    if (e.target.name === "lastName")
      setInputListErr((prevState) => ({ ...prevState, lastName: false }));
    if (e.target.name === "dob")
      setInputListErr((prevState) => ({ ...prevState, dob: false }));
    if (e.target.name === "address")
      setInputListErr((prevState) => ({ ...prevState, address: false }));
    if (e.target.name === "state")
      setInputListErr((prevState) => ({ ...prevState, state: false }));
    if (e.target.name === "city")
      setInputListErr((prevState) => ({ ...prevState, city: false }));
    if (e.target.name === "zip" || e.target.name === "phone") {
      let a = /[0-9 ]/i.test(e.target.value);
      if ((!isNaN(e.target.value) && a) || e.target.value === "") {
        if (e.target.name === "phone") {
          setInitialPhone(true);
          setDisBtn(true);
          setInputList({ ...inputList, [e.target.name]: e.target.value });
          if (e.target.value.length == 0) {
            document.getElementById("ccode_inp").focus();
          }
          setInputListErr((prevState) => ({ ...prevState, phone: false }));
        } else if (e.target.name === "zip") {
          setInputList({ ...inputList, [e.target.name]: e.target.value });
          setInputListErr((prevState) => ({ ...prevState, zip: false }));
        }
      }
    } else if (e.target.name === "email") {
      setInitialMail(true);
      setInputList({ ...inputList, [e.target.name]: e.target.value });
      const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (mailRegex.test(e.target.value)) {
        setmailerr(false);
        dispatch(
          GetMailVerify({ email: e.target.value, val: "mail", pid: patId })
        );
        setInputListErr((prevState) => ({ ...prevState, email: false }));
      } else {
        setmailerr(true);
      }
    } else if (e.target.name === "country_code") {
      setValidC_codeErr(false);
      const countryCode = e.target.value.replace(/[()]/g, "");
      let a = /^[0-9+]+$/.test(countryCode);
      if (a && countryCode.length >= 1) {
        setCCode(countryCode);
      }
      if (countryCode.length == 5) {
        document.getElementById("phone_inp").focus();
      }
    } else {
      setInputList({ ...inputList, [e.target.name]: e.target.value });
    }
    if (e.target.name === "ssnNum") {
      const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
      const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
      const formattedSSN = formatSSN(inputValue.slice(0, 9)); // Limit to 9 characters
      setInitialSSN(true);
      setInputList({ ...inputList, [e.target.name]: formattedSSN });
      if (ssnRegex.test(formattedSSN)) {
        setValidSSNErr(false);
        setSsnerr(false);
        dispatch(GetSSNVerify({ ssn: formattedSSN, val: "ssn", pid: patId }));
      } else {
        setSsnerr(true);
        setValidSSNErr(false);
      }
    }
    // if (e.target.name === "email") {
    //   if (mailRegex.test(e.target.value)) {
    //     setmailerr(false);
    //   } else {
    //     setmailerr(true);
    //   }
    // }

    if (e.target.id === "date") {
      setInputList({ ...inputList, dob: e.target.value });
      let d = moment(e.target.value, "YYYY-MM-DD", true).isValid();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      let today = `${year}-${month}-${day}`;
      if (new Date(e.target.value) > new Date(today) || !d) {
        setSelectValidDate(true);
      } else {
        setInputListErr((prevState) => ({ ...prevState, dob: false }));
        setSelectValidDate(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    const regex = /^[a-zA-Z]+$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const inputNineDig = (e) => {
    var pattern = /^\d{9}$/;
    if (pattern.test(e.target.value)) {
      setSsnerr(false);
      e.preventDefault();
    } else {
      setSsnerr(true);
    }
  };
  const handlepicker1 = (e) => {
    e.preventDefault();
    setShowPicker1(true);
  };

  const closePicker1 = () => {
    setShowPicker1(false);
  };

  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setInputList({ ...inputList, dob: moment(value).format("YYYY-MM-DD") });
      setInputListErr((prevState) => {
        return {
          ...prevState,
          dob: false,
        };
      });
      setValidDate1(new Date(value));
      setSelectValidDate(false);
    }
    closePicker1();
  };

  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    const inputValue = inp.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const caretPosition = inp.selectionStart;

    if (key !== 8 && key !== 46) {
      if (numericValue.length === 4 && caretPosition === 4) {
        inp.value = numericValue + "-";
      } else if (numericValue.length === 6 && caretPosition === 7) {
        inp.value =
          numericValue.slice(0, 4) + "-" + numericValue.slice(4, 6) + "-";
      } else if (numericValue.length > 6 && caretPosition === 10) {
        inp.value =
          numericValue.slice(0, 4) +
          "-" +
          numericValue.slice(4, 6) +
          "-" +
          numericValue.slice(6, 8);
      }
    }
  };

  const onButtonClick = () => {
    const dataArray = new FormData();
    let err = 0;
    if (inputList.firstName === "") {
      setInputListErr((prevState) => ({ ...prevState, firstName: true }));
      err = 2;
    }
    if (inputList.lastName === "") {
      setInputListErr((prevState) => ({ ...prevState, lastName: true }));
      err = 2;
    }
    if (inputList.dob === "") {
      setInputListErr((prevState) => ({ ...prevState, dob: true }));
      err = 2;
    }
    if (inputList.gender === "") {
      setInputListErr((prevState) => ({ ...prevState, gender: true }));
      err = 2;
    }

    if (inputList.email === "") {
      setInputListErr((prevState) => ({ ...prevState, email: true }));
      err = 2;
    }
    if (inputList.phone === "") {
      setInputListErr((prevState) => ({ ...prevState, phone: true }));
      err = 2;
    }
    // if (inputList.race === "") {
    //   setInputListErr((prevState) => ({ ...prevState, race: true }));
    //   err = 2;
    // }
    // if (inputList.ethnicity === "") {
    //   setInputListErr((prevState) => ({ ...prevState, ethnicity: true }));
    //   err = 2;
    // }
    if (inputList.address === "") {
      setInputListErr((prevState) => ({ ...prevState, address: true }));
      err = 2;
    }
    if (inputList.city === "") {
      setInputListErr((prevState) => ({ ...prevState, city: true }));
      err = 2;
    }
    if (inputList.state === "") {
      setInputListErr((prevState) => ({ ...prevState, state: true }));
      err = 2;
    }
    if (inputList.zip === "") {
      setInputListErr((prevState) => ({ ...prevState, zip: true }));
      err = 2;
    }

    if (inputList.ssnNum === "" || inputList.ssnNum.length < 9) {
      setSsnerr(true);
      err = 2;
    }
    if (!validCountryCodes.includes(c_code)) {
      setValidC_codeErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }
    setDis(true);
    let article = {
      id: patId,
      fname: inputList.firstName,
      mname: inputList.middleName,
      lname: inputList.lastName,
      DOB: inputList.dob,
      sex: inputList.gender,
      ss: inputList.ssnNum,
      email: inputList.email,
      phone_cell: c_code + "-" + inputList.phone,
      blood_group: inputList.bloodGr,
      race: inputList.race,
      ethnicity: inputList.ethnicity,
      language: inputList.language,
      street: inputList.address,
      city: inputList.city,
      state: inputList.state,
      postal_code: inputList.zip,
      enrollment_status: "0",
      providerID: userID,
    };

    if (imageFile) {
      dataArray.append("imgfile", imageFile);
    }

    let data = {
      article,
    };

    if (imageFile && imageFile !== "" && imageFile !== null) {
      data.file = dataArray;
    }

    Promise.resolve()
      .then(() => {
        dispatch(GetPatEnrollment([data, imageFile, patId]));
      })
      .then(() => {
        setImageFile(null);
      });
    // return false;
    navigate(`/PatientEligibility/${patId}`);
    setTimeout(() => {
      setDis(false);
    }, 1000);
  };
  const handleRace = () => {
    setRaceDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setLanguageDrop(false);
    setBloodDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const raceValChange = (value) => {
    setInputList({ ...inputList, ["race"]: value });
    setInputListErr((prevState) => ({ ...prevState, race: false }));
    setRaceDrop(false);
  };

  const handleEthnicity = () => {
    setEthnicityDrop((prevState) => !prevState);
    setRaceDrop(false);
    setLanguageDrop(false);
    setBloodDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const ethnicityValChange = (value) => {
    setInputList({ ...inputList, ["ethnicity"]: value });
    setInputListErr((prevState) => ({ ...prevState, ethnicity: false }));
    setEthnicityDrop(false);
  };

  const handleLanguage = () => {
    setLanguageDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setRaceDrop(false);
    setBloodDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const languageValChange = (value) => {
    setInputList({ ...inputList, ["language"]: value });
    setLanguageDrop(false);
  };

  const handleBloodGroup = () => {
    setBloodDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setLanguageDrop(false);
    setRaceDrop(false);
    setGenderDrop(false);
    setTemp(true);
  };

  const bloodGrValChange = (value, group) => {
    setInputList({ ...inputList, ["bloodGr"]: group });
    setBloodDrop(false);
  };

  const handleGender = () => {
    setGenderDrop((prevState) => !prevState);
    setEthnicityDrop(false);
    setLanguageDrop(false);
    setBloodDrop(false);
    setRaceDrop(false);
    setTemp(true);
  };

  const genderValChange = (value) => {
    setInputList({ ...inputList, ["gender"]: value });
    setInputListErr((prevState) => ({ ...prevState, gender: false }));

    setGenderDrop(false);
  };

  window.onclick = function (e) {
    if (genderDrop && !temp) {
      setGenderDrop(false);
    } else if (languageDrop && !temp) {
      setLanguageDrop(false);
    } else if (ethnicityDrop && !temp) {
      setEthnicityDrop(false);
    } else if (bloodDrop && !temp) {
      setBloodDrop(false);
    } else if (raceDrop && !temp) {
      setRaceDrop(false);
    }
  };
  // ------------------------------------------ Functions End--------------------------------------------------------- //

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);
    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(GetSinglePatDetail(patId));
    dispatch(GetGenderList("sex"));
    dispatch(GetRaceList("ethrace"));
    dispatch(GetLanguageList("language"));
  }, []);

  useEffect(() => {
    setPatDetails(SingPatDetails);
  }, [SingPatDetails]);

  useEffect(() => {
    let phonenumSplit = patDetails.phone_cell
      ? patDetails.phone_cell.split("-")
      : "";

    setCCode(phonenumSplit["1"] === undefined ? "+91" : phonenumSplit["0"]);
    setInputList({
      firstName: patDetails.fname || "",
      middleName: patDetails.mname || "",
      lastName: patDetails.lname || "",
      dob: patDetails.DOB || "",
      gender: patDetails.sex || "",
      ssnNum: patDetails.ss || "",
      email: patDetails.email || "",
      phone: phonenumSplit["1"] || "",
      bloodGr: patDetails.blood_group || "",
      race: patDetails.race || "",
      ethnicity: patDetails.ethnicity || "",
      language: patDetails.language || "",
      address: patDetails.street || "",
      city: patDetails.city || "",
      state: patDetails.state || "",
      zip: patDetails.postal_code || "",
      img_url: patDetails.img_url || "",
    });
  }, [patDetails]);

  useEffect(() => {
    if (initialMail) {
      if (EmailVerify === "Email not found" || patId === EmailVerify.pid) {
        setValidMailErr(false);
      } else {
        setValidMailErr(true);
      }
    }
    if (inputList.email === "") {
      setValidMailErr(false);
    }
  }, [EmailVerify]);

  useEffect(() => {
    if (initialPhone) {
      if (
        PhoneVerify === "Phone Number not found" ||
        patId === PhoneVerify.pid
      ) {
        setValidPhoneErr(false);
      } else {
        setValidPhoneErr(true);
      }
    }
    if (inputList.phone === "") {
      setValidPhoneErr(false);
    }
  }, [PhoneVerify]);

  useEffect(() => {
    if (initialSSN) {
      if (SSNVerify === "SSN not found" || patId === SSNVerify.pid) {
        setValidSSNErr(false);
      } else {
        setValidSSNErr(true);
      }
    }
  }, [SSNVerify]);

  useEffect(() => {
    let isCancelled = false;

    if (inputList.phone !== "") {
      const handlechange = async () => {
        await timeout(2000);
        if (!isCancelled) {
          dispatch(
            GetPhoneVerify({
              phone: c_code + "-" + inputList.phone,
              val: "phone",
              pid: patId,
            })
          );
          setDisBtn(false);
        }
      };

      handlechange();
      return () => {
        isCancelled = true;
      };
    }
  }, [c_code, inputList.phone]);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <>
      <PageSubTitle color="#2C7BE5">Patient Enrollment</PageSubTitle>
      <Div
        backgroundColor="#ffffff"
        borderRadius="8px"
        marginTop="24px"
        height="1057px"
      >
        <RadioProgress page={1} />

        <PatientSubTitle margin="108px 20px 20px 29px">
          Patient Information
        </PatientSubTitle>
        <Div marginLeft="37px">
          <Div display="flex" alignItems="center">
            <Div
              height="75px"
              width="75px"
              borderRadius="50%"
              display="flex"
              marginRight="23px"
              fontSize="60px"
              fontWeight="600"
            >
              {(patDetails.img_url !== undefined &&
                patDetails.img_url !== null &&
                patDetails.img_url !== "") ||
              previewimg1 !== "" ? (
                <Image
                  height="75px"
                  width="75px"
                  borderRadius="50%"
                  src={
                    previewimg1 === ""
                      ? imageUrl + patDetails.img_url
                      : previewimg1
                  }
                />
              ) : patDetails.img_url === null && previewimg1 === "" ? (
                <Div
                  width="200px"
                  borderRadius="50%"
                  background="rgb(168, 174, 190)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  fontFamily="Open Sans, sans-serif"
                  color="#FFFFFF"
                  position="relative"
                >
                  <SpanTag position="absolute" top="18px" fontSize="40px">
                    {patDetails.fname !== undefined &&
                      patDetails.fname.charAt(0).toUpperCase()}
                  </SpanTag>
                </Div>
              ) : (
                <Image
                  height="75px"
                  width="75px"
                  borderRadius="50%"
                  src={Avatar}
                />
              )}
            </Div>
            <Button
              height="36px"
              bgcolor="#fff"
              color="#2c7be5"
              border="1px solid #2c7be5"
              onClick={() => {
                setIsUpload(true);
              }}
              hoverBackColor="rgba(244, 246, 249, 0.75)"
              activeBackgroundColor="rgba(44, 123, 229, 0.10)"
            >
              Upload Photo
            </Button>
          </Div>
          <Div display="flex" padding="32px 0 0 0px">
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                First Name<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="232px"
                height="36px"
                border={
                  inputListErr.firstName
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="4px"
                type="text"
                name="firstName"
                value={inputList.firstName}
                onKeyPress={handleKeyPress}
                onChange={(e) => inputChange(e)}
              />
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">Middle Name</LabelVal>
              <Input
                padding="0 0 0 10px"
                width="232px"
                height="36px"
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="4px"
                type="text"
                name="middleName"
                value={inputList.middleName}
                onKeyPress={handleKeyPress}
                onChange={(e) => inputChange(e)}
              />
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Last Name<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="232px"
                height="36px"
                border={
                  inputListErr.lastName
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="4px"
                type="text"
                name="lastName"
                value={inputList.lastName}
                onKeyPress={handleKeyPress}
                onChange={(e) => inputChange(e)}
              />
            </Div>
          </Div>
          <Div display="flex" padding="48px 0 0 0">
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Date of Birth<RedStar>*</RedStar>
              </LabelVal>
              <CustomDatePicker
                padding="0 0 0 10px"
                id="CustomDatePicker"
                width="232px"
                height="36px"
                backgroundColour="#f7f7f7 !important"
                border={
                  inputListErr.dob
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="4px"
                placeholder="YYYY-MM-DD"
                value={inputList.dob}
                name="dob"
                onChange={(e) => inputChange(e)}
                onClick={(e) => handlepicker1(e)}
                onKeyPress={(event) => {
                  if (
                    !/[0-9]/.test(event.key) ||
                    event.target.value.length > 9
                  ) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(e) => keyDownExpiry(e)}
                onResultSelect={dateSelect1} // state updation for picking calendar dates
                showPicker={showpicker1}
                validDate={validDate1} // value in date object for calendar manipulation
                closePicker={closePicker1}
                max={moment(new Date(), "DD/MM/YYYY")}
              />
              {selectValidDate && (
                <ErrorMessage style={{ zIndex: "9999", marginTop: "60px" }}>
                  Please enter a valid Date
                </ErrorMessage>
              )}
              <BsCalendar className="calendarIcon" />
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Gender<RedStar>*</RedStar>
              </LabelVal>
              <Div>
                <DropDown>
                  <Div
                    width="232px"
                    height="36px"
                    border={
                      inputListErr.gender
                        ? "1px solid #B00020"
                        : "1px solid rgba(46, 46, 46, 0.25)"
                    }
                    borderRadius="4px"
                    fontSize="14px"
                    paddingLeft="10px"
                    paddingTop="3px"
                    onClick={handleGender}
                    onMouseLeave={() => setTemp(false)}
                  >
                    {inputList.gender ? inputList.gender : "Select Gender"}
                    <FiChevronDown />
                  </Div>
                  {genderDrop && (
                    <DropDownListContainer overflowY>
                      <DropDownList>
                        {GenderStatusList.map((list, ind) => (
                          <ListItem
                            key={ind}
                            onClick={() => genderValChange(list.title)}
                            value={list.title}
                          >
                            {list.title}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDown>
              </Div>
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                SSN Number<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="232px"
                height="36px"
                borderRadius="4px"
                type="text"
                name="ssnNum"
                border={
                  ssnErr || validSSNErr
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                value={inputList.ssnNum}
                onKeyPress={inputNineDig}
                onChange={(e) => inputChange(e)}
              />
              {ssnErr && (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  SSN Must be 9 digit
                </Text>
              )}
              {validSSNErr && (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  SSN Already Exist
                </Text>
              )}
            </Div>
          </Div>
          <Div display="flex" padding="48px 0 0 0">
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Email ID<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="232px"
                height="36px"
                border={
                  mailerr || inputListErr.email
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="4px"
                type="text"
                name="email"
                value={inputList.email}
                onChange={(e) => inputChange(e)}
              />
              {mailerr ? (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  Enter valid email id
                </Text>
              ) : validMailErr ? (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  Email Id Already Exist
                </Text>
              ) : (
                ""
              )}
              {/* {validMailErr ? (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  Email Id Already Exist
                </Text>
              ):""} */}
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Phone Number<RedStar>*</RedStar>
              </LabelVal>
              <Div flexCenterAll>
                <Input
                  type="text"
                  width={`${38 + (c_code?.length - 1) * 6}px`}
                  height="36px"
                  id="ccode_inp"
                  border={
                    inputListErr.phone
                      ? "1px solid #B00020"
                      : "1px solid rgba(46, 46, 46, 0.25)"
                  }
                  btrr="0px"
                  bbrr="0px"
                  bblr="4px"
                  btlr="4px"
                  brw="0px"
                  padding="0 0 0 10px"
                  value={`(${c_code})`}
                  name="country_code"
                  maxLength={7}
                  onChange={(e) => inputChange(e)}
                />
                <Input
                  padding="0 0 0 2px"
                  id="phone_inp"
                  width={`${194 - (c_code?.length - 1) * 6}px`}
                  height="36px"
                  btrr="4px"
                  bbrr="4px"
                  bblr="0px"
                  btlr="0px"
                  type="text"
                  name="phone"
                  blw="0px"
                  value={inputList.phone}
                  border={
                    inputListErr.phone
                      ? "1px solid #B00020"
                      : "1px solid rgba(46, 46, 46, 0.25)"
                  }
                  onChange={(e) => inputChange(e)}
                />
              </Div>
              {validPhoneErr && (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  Phone Number Already Exist
                </Text>
              )}
              {validC_codeErr && (
                <Text
                  color="#B00020"
                  fontSize="12px"
                  position="absolute"
                  marginTop="65px"
                >
                  Enter Valid Country Code
                </Text>
              )}
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">Blood Group</LabelVal>
              <Div>
                <DropDown>
                  <Div
                    width="232px"
                    height="36px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    fontSize="14px"
                    paddingLeft="10px"
                    paddingTop="3px"
                    onClick={handleBloodGroup}
                    onMouseLeave={() => setTemp(false)}
                  >
                    {inputList.bloodGr
                      ? inputList.bloodGr
                      : "Select Blood Group"}
                    <FiChevronDown />
                  </Div>
                  {bloodDrop && (
                    <DropDownListContainer overflowY>
                      <DropDownList>
                        {BloodGrList.map((list, ind) => (
                          <ListItem
                            key={ind}
                            onClick={() =>
                              bloodGrValChange(list.title, list.val)
                            }
                            value={list.title}
                          >
                            {list.val}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDown>
              </Div>
            </Div>
          </Div>
          <Div display="flex" padding="48px 0 0 0">
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Race
                {/* <RedStar>*</RedStar> */}
              </LabelVal>
              <Div>
                <DropDown>
                  <Div
                    width="232px"
                    height="36px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    // border={
                    //   inputListErr.race
                    //     ? "1px solid #B00020"
                    //     : "1px solid rgba(46, 46, 46, 0.25)"
                    // }
                    borderRadius="4px"
                    fontSize="14px"
                    paddingLeft="10px"
                    paddingTop="3px"
                    onClick={handleRace}
                    onMouseLeave={() => setTemp(false)}
                  >
                    <div
                      style={{
                        maxWidth: "200px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                      }}
                    >
                      {inputList.race ? inputList.race : "Select Race"}
                    </div>
                    <FiChevronDown />
                  </Div>
                  {raceDrop && (
                    <DropDownListContainer overflowY>
                      <DropDownList>
                        {RaceStatusList.map((list, ind) => (
                          <ListItem
                            key={ind}
                            onClick={() => raceValChange(list.title)}
                            value={list.title}
                          >
                            {list.title}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDown>
              </Div>
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Ethnicity
                {/* <RedStar>*</RedStar> */}
              </LabelVal>
              <Div>
                <DropDown>
                  <Div
                    width="232px"
                    height="36px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    // border={
                    //   inputListErr.ethnicity
                    //     ? "1px solid #B00020"
                    //     : "1px solid rgba(46, 46, 46, 0.25)"
                    // }
                    // borderRadius="4px"
                    fontSize="14px"
                    paddingLeft="10px"
                    paddingTop="3px"
                    onClick={handleEthnicity}
                    onMouseLeave={() => setTemp(false)}
                  >
                    {inputList.ethnicity
                      ? inputList.ethnicity
                      : "Select Ethnicity"}
                    <FiChevronDown />
                  </Div>
                  {ethnicityDrop && (
                    <DropDownListContainer overflowY>
                      <DropDownList>
                        {EthnicityList.map((list, ind) => (
                          <ListItem
                            key={ind}
                            onClick={() => ethnicityValChange(list.title)}
                            value={list.title}
                          >
                            {list.title}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDown>
              </Div>
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">Language</LabelVal>
              <Div>
                <DropDown>
                  <Div
                    width="232px"
                    height="36px"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="4px"
                    fontSize="14px"
                    paddingLeft="10px"
                    paddingTop="3px"
                    onClick={handleLanguage}
                    onMouseLeave={() => setTemp(false)}
                  >
                    <div
                      style={{
                        maxWidth: "190px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                      }}
                    >
                      {inputList.language
                        ? inputList.language
                        : "Select Language"}
                    </div>

                    <FiChevronDown />
                  </Div>
                  {languageDrop && (
                    <DropDownListContainer overflowY>
                      <DropDownList>
                        {LanguageStatusList.map((list, ind) => (
                          <ListItem
                            key={ind}
                            onClick={() => languageValChange(list.title)}
                            value={list.title}
                          >
                            {list.title}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDown>
              </Div>
            </Div>
          </Div>
          <Div display="flex" padding="48px 0 0 0">
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Address<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                height="36px"
                borderRadius="4px"
                type="text"
                width="512px !important"
                name="address"
                value={inputList.address}
                border={
                  inputListErr.address
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                onChange={(e) => inputChange(e)}
              />
            </Div>
          </Div>
          <Div display="flex" padding="16px 0 0 0">
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                City<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="200px"
                height="36px"
                border={
                  inputListErr.city
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="4px"
                type="text"
                name="city"
                value={inputList.city}
                onChange={(e) => inputChange(e)}
              />
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                State<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="200px"
                height="36px"
                borderRadius="4px"
                type="text"
                name="state"
                border={
                  inputListErr.state
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                value={inputList.state}
                onChange={(e) => inputChange(e)}
              />
            </Div>
            <Div display="inline-grid" padding="0 48px 0 0">
              <LabelVal margin="0 0 8px 0">
                Zipcode<RedStar>*</RedStar>
              </LabelVal>
              <Input
                padding="0 0 0 10px"
                width="200px"
                height="36px"
                border={
                  inputListErr.zip
                    ? "1px solid #B00020"
                    : "1px solid rgba(46, 46, 46, 0.25)"
                }
                borderRadius="4px"
                type="text"
                name="zip"
                value={inputList.zip}
                onChange={(e) => inputChange(e)}
              />
            </Div>
          </Div>
        </Div>
        <Div margin="83px 48px 66px 0" display="flex" justifyContent="end">
          <Button
            title={
              inputList.firstName === "" ||
              inputList.lastName === "" ||
              inputList.gender === "" ||
              inputList.email === "" ||
              inputList.phone === "" ||
              inputList.race === "" ||
              inputList.ethnicity === "" ||
              inputList.address === "" ||
              inputList.city === "" ||
              inputList.state === "" ||
              mailerr ||
              inputList.dob === "" ||
              inputList.zip === ""
                ? "fill-up all mandatory fields"
                : ""
            }
            disabled={disBtn || validMailErr || validPhoneErr || mailerr || dis}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            isDisabled={dis}
            onClick={onButtonClick}
          >
            Next
          </Button>
        </Div>
      </Div>
      <UploadProfilePicture
        show={isupload}
        close={() => setIsUpload(false)}
        setImageText={setImageText}
        setpreviewimg1={setpreviewimg1}
        setImageFile={setImageFile}
      />
    </>
  );
};
export default PatientEnrollment;
