import styled, { css, keyframes } from "styled-components";
import { Link, NavLink } from "react-router-dom";

export const Image = styled.img`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border: ${(p) => p.border && p.border};
  color: ${(p) => p.color && p.color};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  cursor: ${(p) => p.cursor && p.cursor};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  &.dropdownimg {
    @media screen and (max-width: 950px) {
      right: 32px;
      top: 16px;
    }
    @media screen and (max-width: 750px) {
      right: 32px;
      top: 16px;
    }
  }
`;

export const Div = styled.div`
  visibility: ${({ visibility }) => visibility && visibility};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`}  
  ${(p) => p.fontSize && `font-size : ${p.fontSize};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  ${(p) => p.alignItems && `align-items : ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content : ${p.justifyContent};`}
  ${(p) => p.textShadow && `text-shadow : ${p.textShadow};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  box-sizing: ${(p) => p.boxSizing && p.boxSizing};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-right: ${(p) => p.borderRight && p.borderRight};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  gap: ${(p) => p.gap && p.gap};
  border-right: ${(p) => p.borderRight && p.borderRight};
  transform: ${(p) => p.transform && p.transform};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  float: ${(p) => p.float && p.float};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  border-top-left-radius: ${(p) =>
    p.borderTopLeftRadius && p.borderTopLeftRadius};
  border-top-right-radius: ${(p) =>
    p.borderTopRightRadius && p.borderTopRightRadius};
  border-bottom-left-radius: ${(p) =>
    p.borderBottomLeftRadius && p.borderBottomLeftRadius};
  border-bottom-right-radius: ${(p) =>
    p.borderBottomRightRadius && p.borderBottomRightRadius};

  max-width: ${(p) => p.maxWidth && p.maxWidth};
  min-width: ${(p) => p.minWidth && p.minWidth};
  min-height: ${(p) => p.minHeight && p.minHeight};
  /* grid-area: ${(p) => p.gridArea && p.gridArea}; */
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterVertical) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
    justify-content : center;
    `;
    } else if (p.flexCenterBetween) {
      return `display : flex;
    align-items : start;
    justify-content : space-between;
    `;
    }
  }}
  width: ${(p) => {
    if (p.widthOffset) {
      return `calc(100% - ${p.widthOffset})`;
    } else {
      return p.width && p.width;
    }
  }};

  &.responsive-right {
    @media screen and (max-width: 1071px) {
      position: absolute;
    }
  }
  &.flexWrapDis {
    @media screen and (max-width: 1071px) {
      display: block;
    }
  }
  &.consentDivAlign {
    @media screen and (max-width: 1200px) {
      height: 960px;
    }
  }
  & > .calendarIcon {
    position: absolute;
    margin-top: 34px;
    margin-left: 200px;
  }
  &.add-user #CustomDatePicker {
    right: -30%;
  }
  &#add-customdatepic-user #CustomDatePicker {
    bottom: 38px;
  }
  @media (min-width: 1401px) and (max-width: 1740px) {
    &.canvas_dashboard > canvas {
      height: 355px !important;
      // width: 423px !important;
    }
  }

  @media (min-width: 1741px) and (max-width: 2000px) {
    &.canvas_dashboard > canvas {
      height: 350px !important;
      // width: 423px !important;
    }
  }

  @media (min-width: 700px) and (max-width: 1401px) {
    &.canvas_dashboard > canvas {
      height: 400px !important;
    }
  }
  @media (min-width: 1401px) and (max-width: 1740px) {
    &.canvas_vitaldashboard > canvas {
      height: 400px !important;
    }
  }
  & svg {
    opacity: 0.7;
  }
`;

export const Text = styled.p`
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-top: ${(p) => p.svgmarginTop && p.svgmarginTop};
    margin-left: ${(p) => p.svgmarginLeft && p.svgmarginLeft};
  }
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  display: ${(p) => p.display && p.display};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  &.flexWrapTxt {
    @media screen and (max-width: 1071px) {
      margin-left: 13px;
    }
  }
  &.flexWrapDt {
    @media screen and (max-width: 1071px) {
      margin-left: 23px;
    }
  }
  /* @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  } */
`;

export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.flex && p.flex};
  align-items: ${(p) => p.alignItems && p.alignItems};
  color: ${(p) => p.color && p.color};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
`;

export const PageSubTitle = styled.h4`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.flex && p.flex};
  align-items: ${(p) => p.alignItems && p.alignItems};
  color: ${(p) => p.color && p.color};
  padding: ${(p) => p.padding && p.padding};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  text-align: ${(p) => p.textAlign && p.textAlign};
  cursor: ${(p) => p.cursor && p.cursor};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const Button = styled.button`
  cursor: ${(p) => p.cursor && p.cursor};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  /* background-color: ${(p) => p.backgroundColor && p.backgroundColor}; */
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  border-color: ${(p) => p.borderColor && p.borderColor};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin: ${(p) => p.margin && p.margin};
  outline: ${(p) => p.outline && p.outline};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};

  background-color: ${(p) => p.backgroundColor && p.backgroundColor};

  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }

  &:active {
    background-color: ${(p) =>
      p.activeBackgroundColor || p.hoverBackColor || "initial"};
  }

  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    position: ${(p) => p.svgPosition && p.svgPosition};
    top: ${(p) => p.svgTop && p.svgTop};
    left: ${(p) => p.svgLeft && p.svgLeft};
  }

  &:after {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ff4d4d;
  }

  ${(p) =>
    p.disabled &&
    css`
      /* pointer-events: none; */
      cursor: not-allowed;
      ${p.disableActive ? "" : `background-color: rgba(44, 123, 229, 0.5);`}
      /* Add hover and active styles for disabled state */
      &:hover {
        background-color: rgba(44, 123, 229, 0.5);
      }

      &:active {
        background-color: rgba(44, 123, 229, 0.5);
      }
    `}
  &:hover {
    &.bgFFF {
      background-color: #f4f6f9;
    }
    &.bg-2c7be5 {
      background-color: #005fb2;
    }
  }

  ${(props) =>
    props.isDisabled &&
    css`
      /* pointer-events: none; */
      cursor: not-allowed;
      background-color: rgba(44, 123, 229, 0.5);

      /* Add hover and active styles for disabled state */
      &:hover {
        background-color: rgba(44, 123, 229, 0.5);
      }

      &:active {
        background-color: rgba(44, 123, 229, 0.5);
      }
    `}
`;

export const LinK = styled(Link)`
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  line-height: ${(p) => p.lineheight && p.lineheight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  svg {
    font-size: ${(p) => p.fontSize && p.fontSize};
    margin-right: ${(p) => p.marginRight && p.marginRight};
  }
`;
export const A = styled.a`
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  line-height: ${(p) => p.lineheight && p.lineheight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const Container = styled.div`
  display: ${(p) => p.display && p.display};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding: ${(p) => p.padding && p.padding};
  min-width: ${(p) => p.minWidth && p.minWidth};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  position: ${(p) => p.position && p.position};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
`;
export const BodyContainer = styled.div`
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  padding: ${(p) => p.padding && p.padding};
  height: ${(p) => p.height && p.height};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-align: ${(p) => p.textAlign && p.textAlign};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.Flex && p.Flex};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
`;
export const ImageContainer = styled.div`
  margin-top: ${(props) => props.marginTop && props.marginTop};
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  text-align: ${(props) => props.textAlign && props.textAlign};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;
export const Span = styled.p`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-shadow: ${(p) => p.textShadow && p.textShadow};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  cursor: ${(p) => p.cursor && p.cursor};
  &.Customizerole-edit-btn {
    @media (max-width: 788px) {
      font-size: 11px;
      font-weight: 400;
    }
  }

  &.roles-edit-btn {
    @media (max-width: 788px) {
      font-size: 11px;
      font-weight: 400;
      postition: absolute;
    }
  }
`;

// Form //
export const Form = styled.form``;

export const FormLabel = styled.label`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  padding: ${(p) => p.padding && p.padding};
`;

export const FormGroup = styled.div`
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  position: ${(p) => p.position && p.position};
  margin: ${(p) => p.margin && p.margin};
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
  float: ${(p) => p.float && p.float};
`;
export const Label = styled.label`
  font-size: ${(p) => p.size && p.size};
  font-weight: ${(p) => p.weight && p.weight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: ${(p) => p.display && p.display};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;
export const Input = styled.input.attrs((props) => ({
  readOnly: props.readOnly && "readonly",
  autoComplete: props.autoComplete && "off",
}))`
  color: ${(p) => p.color && p.color};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};
  text-align: ${(p) => p.textAlign && p.textAlign};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
  border-right: ${(p) => p.borderRight && p.borderRight};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border-top-left-radius: ${(p) => p.btlr && p.btlr};
  border-top-right-radius: ${(p) => p.btrr && p.btrr};
  border-bottom-left-radius: ${(p) => p.bblr && p.bblr};
  border-bottom-right-radius: ${(p) => p.bbrr && p.bbrr};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  &:focus {
    outline: none;
  }
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  cursor: ${(p) => p.cursor && p.cursor};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  /* min-width: 135px; */
  &.hide-icon::-webkit-search-cancel-button,
  .hide-icon::-webkit-search-decoration,
  .hide-icon::-webkit-search-results-button,
  .hide-icon::-webkit-search-results-decoration {
    display: none;
  }
  &::placeholder {
    color: ${(p) => p.pColor && p.pColor};
  }
  border-left-width: ${(p) => p.blw && p.blw};
  border-right-width: ${(p) => p.brw && p.brw};
`;
// Form
// Check box
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: ${(p) => p.position && p.position};
  opacity: ${(p) => p.opacity && p.opacity};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const StyledCheckbox = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background-color: ${(p) => (p.checked ? p.backgroundColor : "transparent")};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;

export const CheckIcon = styled.svg`
  fill: ${(p) => p.fill && p.fill};
  stroke: ${(p) => p.stroke && p.stroke};
  stroke-width: ${(p) => p.strokeWidth && p.strokeWidth};
`;
// Check box

// Nav links

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: ${(p) => (p.padding ? p.padding : " 14px 0px 10px 5px")}!important;
  &:active {
    color: rgba(44, 123, 229, 1);
  }
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  &.active {
    color: rgba(44, 123, 229, 1);
  }
`;

export const Ul = styled.ul``;

export const Li = styled.li`
  list-style-type: none;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : " 8px")};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
`;

export const Nav = styled.nav`
  /* margin-top: 21px; */
  padding: 0px 12px 0px 12px;

  text-decoration: none;
`;
export const Navicon = styled.span`
  background-position: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "'Open Sans'", sans-serif;
  background-repeat: no-repeat;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;
export const SearchIconSVG = styled.svg`
  z-index: 1;
  width: 18px;
  height: 18px;
  position: absolute;
  top: ${(p) => (p.top ? p.top : "10px")};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  left: 8px;
`;

// Nav links
export const Data = styled(Div)`
  width: ${(p) => p.width && p.width};
  max-height: 418px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
// Table
export const Table = styled.table`
  margin-top: 1px;
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  height: ${(p) => (p.height ? p.height : "100px")};
  border: ${(p) => (p.border ? p.border : "1px solid #E2E8ED")};
  border-top: ${(p) => (p.borderTop ? p.borderTop : "none")};
  width: ${(p) => (p.width ? p.width : "100%")};
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  border-collapse: ${(p) => p.collapse && p.collapse};
  border-top-left-radius: ${(p) =>
    p.borderTopLeftRadius && p.borderTopLeftRadius};
  border-top-right-radius: ${(p) =>
    p.borderTopRightRadius && p.borderTopRightRadius};
  border-bottom-left-radius: ${(p) =>
    p.borderBottomLeftRadius && p.borderBottomLeftRadius};
  border-bottom-right-radius: ${(p) =>
    p.borderBottomRightRadius && p.borderBottomRightRadius};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};

  &.getlast > tbody:last-of-type > tr > td {
    border-bottom: none !important;
  }
  @media (min-width: 1575px) and (max-width: 3500px) {
    &.treat_dashboard_ {
      height: 60px !important;
    }
  }
`;
export const Table1 = styled.table`
  margin-top: 1px;
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  height: ${(p) => (p.height ? p.height : "100px")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border: ${(p) => (p.border ? p.border : "1px solid #E2E8ED")};
  border-top: ${(p) => (p.borderTop ? p.borderTop : "none")};
  width: ${(p) => (p.width ? p.width : "100%")};
  display: ${(p) => p.display && p.display};
  width: 100%;
  table-layout: fixed;
`;
export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(props) => props.background};
  display: ${(p) => (p.scroll ? "table" : "")};
  width: ${(p) => (p.scroll ? "100%" : "")};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  height: ${(p) => p.height && p.height};

  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  z-index: ${(p) => p.zIndex && p.zIndex};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  overflow-y: ${(p) => (p.scroll ? "scroll" : "")};
  display: ${(p) => (p.scroll ? "block" : "")};
  height: ${(p) => (p.scroll ? p.height : "")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  background-color: ${(props) => props.background};
  min-width: ${(p) => p.minWidth && p.minWidth};
  overflow: ${(p) => p.overflow && p.overflow};
  width: ${(p) => p.width && p.width};
`;

export const TableHeadCell = styled.th`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: 14px;
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  color: ${(p) => (p.color ? p.color : "#777984")};
  /* padding: ${(p) => (p.padding ? p.padding : "20px 20px 20px 30px")}; */
  /* padding-left: 24px; */
  padding: ${(p) => p.padding && p.padding};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "20px")};
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "20px")};
  display: ${(p) => p.display && p.display};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  border-right: ${(p) => p.borderRight && p.borderRight};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  flex: ${(p) => p.flex && p.flex};
  &.activeColor {
    color: #2c7be5 !important;
  }
  cursor: ${(p) => p.cursor && p.cursor};
  @media (min-width: 800px) and (max-width: 820px) {
    width: ${(p) => p.resWidth && p.resWidth};
  }
`;

export const TableBodyCell = styled.td`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "15px")};
  align-items: ${(p) => p.alignItems && p.alignItems};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")} !important;
  color: ${(p) => (p.color ? p.color : " #000000")};
  text-align: ${(p) => p.textAlign && p.textAlign};
  border-bottom: ${(p) => (p.borderBottom ? p.borderBottom : "none")};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
  padding-right: ${(p) => (p.paddingRight ? p.paddingRight : "16px")};
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "12px")};
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "12px")};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  border-right: ${(p) => p.borderRight && p.borderRight};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  min-width: ${(p) => p.minWidth && p.minWidth};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  flex: ${(p) => p.flex && p.flex};
  width: ${(p) => p.width && p.width};
  width: ${(props) => props.columnWidth || "auto"};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border: ${(p) => p.border && p.border};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  word-wrap: ${(p) => p.wordWrap && p.wordWrap};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  @media screen and (max-width: 820px) {
    &.maxWidthChange {
      max-width: 20px;
    }
  }
`;
export const TableRow = styled.tr`
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  text-align: left;
  background-color: ${(props) => props.background};
  cursor: ${(p) => p.cursor && p.cursor};
  border-bottom: ${(p) => (p.borderbottom ? "1px solid #D8E0F0" : "none")};
  border-bottom: ${(p) => p.border_Bottom && p.border_Bottom};
  height: ${(p) => (p.height ? p.height : "")};
  &:last-child ${TableBodyCell} {
    border-bottom: ${(p) => (p.bbtom ? "1px solid #D8E0F0" : "none")};
  }
  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }
  display: ${(p) => (p.scroll ? "table" : p.display)};
  width: ${(p) => (p.scroll ? "100%" : p.width)};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
  }
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};

  &:hover {
    background-color: ${(p) => p.bghovercolor && p.bghovercolor};
  }
  border: ${({ border }) => border && border};
  text-align: ${({ textAlign }) => textAlign && textAlign};

  display: ${({ display }) => (display ? display : "table-row")};
  & > th,
  & > td {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
    line-height: ${(p) => p.lineHeight && p.lineHeight};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    padding: ${({ padding }) => padding && padding};
    padding-top: ${(p) => p.paddingTop && p.paddingTop};
    padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
    background-color: ${(p) => p.backgroundColor && p.backgroundColor};
    border-right: ${(p) => p.borderRight && p.borderRight};
  }
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  &.table_last_child tr:last-child {
    border-bottom: "none !important";
  }
  &:last-child {
    border-bottom: ${(p) => (p.lastchild ? "none" : p.lastchild)};
    border-right: ${(p) => (p.lastchild ? "none" : p.lastchild)};
  }
  &.treat_dashboard_ tr:last-child {
    border-bottom: "none !important";
  }
`;

export const Modal = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: 0;
  left: 0;
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1255)};
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100vh")};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;

export const ModalContainer = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  z-index: 1055;
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "hidden")};
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  outline: 0;
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : " #ffffff"};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  cursor: auto;
  border: ${(p) => p.border && p.border};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  padding: ${(p) => p.padding && p.padding};
  min-width: ${(p) => p.minWidth && p.minWidth};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-bottom: ${(p) => (p.borderLess ? "none" : "1px solid #d8e0f0")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  height: ${(p) => p.height && p.height};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
`;

export const ModalTitle = styled.h4`
  line-height: ${(p) => (p.lineHight ? p.lineHight : "27px")};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  color: ${(p) => (p.color ? p.color : "#232934")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-style: ${(p) => (p.fontStyle ? p.fontStyle : p.fontStyle)};
  margin: ${(p) => p.margin && p.margin};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

export const ModalBody = styled.div`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding: ${(p) => p.padding && p.padding};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  border-top: ${(p) => p.borderTop && p.borderTop};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border: ${(p) => p.border && p.border};
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: lightgray;
    height: 2px;
  }
`;

export const ModalFooter = styled.div`
  border-top: ${(p) => (p.noBorderTop ? "none" : "1px solid #d8e0f0")};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  text-align: ${(p) => p.textAlign && p.textAlign};
  border-top: ${(p) => p.borderTop && p.borderTop};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  float: ${(p) => p.float && p.float};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;
export const Modclosebtn = styled.button`
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  background: ${(p) => p.background && p.background};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  line-height: 1.15px;
  cursor: pointer;
  svg {
    padding-right: 2px;
  }
`;

export const ButtonGroup = styled.button`
  display: ${(p) => p.display && p.display};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  disabled: ${(p) => p.disabled && p.disabled};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }

  &:active {
    background-color: ${(p) =>
      p.activeBackgroundColor || p.hoverBackColor || "initial"};
  }

  @media (max-width: 1050px) {
    padding: 7px 7px 7px 7px;
  }
  @media (max-width: 800px) {
    padding: 4px 4px 4px 4px;
  }

  &.roles-edit-btn {
    @media (max-width: 788px) {
      margin-right: 10px;
    }
  }
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      background-color: rgba(44, 123, 229, 0.5);

      &:hover {
        background-color: rgba(44, 123, 229, 0.5);
      }

      &:active {
        background-color: rgba(44, 123, 229, 0.5);
      }
    `}
`;

export const InputField = styled.div`
  position: relative;
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};

  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  // box-shadow: ${(p) => p.boxShadow && p.boxShadow};
`;

export const Staricon = styled.span`
  color: ${(p) => p.color && p.color};
  background-position: center;
  font-weight: 600;
  font-size: 16px;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  font-family: "'Open Sans'", sans-serif;
  background-repeat: no-repeat;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
`;
export const Tablerow = styled(TableRow)`
  border: ${({ border }) => border && border};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  position: ${({ position }) => position && position};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  display: ${({ display }) => (display ? display : "table-row")};
  & > th,
  & > td {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
    line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    padding: ${({ padding }) => padding && padding};
    padding-top: ${(p) => p.paddingTop && p.paddingTop};
    padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};

    background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  }

  overflow-x: ${(p) => p.overflowX && p.overflowX};
  cursor: ${({ isHovered }) => (isHovered ? "pointer" : "default")};
  &.table_last_child tr:last-child {
    border-bottom: "none !important";
  }
  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }
  align-items: ${(p) => p.alignItems && p.alignItems};
  &.tbody_padding > th {
    padding-left: 16px !important;
  }
`;
export const SpanTag = styled.span`
  gap: ${(p) => p.gap && p.gap};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  position: ${(p) => p.position && p.position};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  background-position: ${(p) => p.backgroundPosition && p.backgroundPosition};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  float: ${(p) => p.float && p.float};
  margin: ${(p) => p.margin && p.margin};
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};

  ${({ bullet }) =>
    bullet &&
    `
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;      
      background-color: #D8E0F0;
      height: 8px;
      width: 8px;
    `}
`;
export const Accordion = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const ArrowIcon = styled.span`
  margin-left: auto;
`;

export const AccordionWrapper = styled.div`
  padding-left: 15px; /* Adjust the left padding as desired */
`;

export const AccordionLi = styled(Li)`
  font-size: 14px;
`;
export const Select = styled.select`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  float: ${(p) => p.float && p.float};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding-left: ${(p) => p.pl && p.pl};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  &:focus {
    outline: none;
  }
`;
export const RedStar = styled.span`
  color: #b00020;
  vertical-align: 1px;
  font-size: 20px;
  margin: ${(p) => p.margin && p.margin};
  position: ${(p) => (p.position ? p.position : "absolute")};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  bottom: ${(p) => p.bottom && p.bottom};
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 10px;
  margin-top: 50px;
  position: absolute;
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  bottom: ${(p) => p.bottom && p.bottom};
`;
export const CustomDropdownImage = styled.img`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  height: 8px;
  width: 12px;
`;

export const TextContent = styled.span`
  display: block;
  word-wrap: break-word;
  overflow: hidden;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
`;
export const SelectInput = styled.div`
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.pl && p.pl};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: relative;
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  &:empty:before {
    content: ${(p) => p.placeholder && p.placeholder};
    color: rgba(46, 46, 46, 0.5);
    font-weight: 400;
    font-size: 14px;
  }
`;

export const DropDownDiv = styled.div`
  margin: ${(p) => p.margin && p.margin};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  display: grid;
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: absolute;
  z-index: 1000;
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  left: ${(p) => p.left && p.left};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow: ${(p) => p.overflow && p.overflow};
  bottom: ${(p) => p.bottom && p.bottom};
  top: ${(p) => p.top && p.top};
`;

export const DropdownOptions = styled.label`
  display: ${(p) => p.display && p.display};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  &:hover {
    background-color: #2c7be5 !important;
    color: #ffffff !important;
  }
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    color: gray; /* Apply a grayed-out color for disabled option */
    pointer-events: none; /* Disable click events for disabled option */
  `}

  &:hover {
    ${(props) =>
      !props.disabled &&
      `
      background-color: #2c7be5 !important;
      color: #ffffff !important;
    `}
  }
`;
export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: absolute;
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "-24px")};
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "385px")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
`;
export const DropdownContainer = styled.div`
  position: relative;
  display: ${(p) => (p.display ? p.display : "inline-block")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;

export const DropdownButton = styled.button`
  background-color: #ffffff;
  color: #2e2e2e;
  padding: ${(props) => (props.padding ? props.padding : "8px")};
  border: none;
  cursor: pointer;
  border: 1px solid rgba(46, 46, 46, 0.25);
  width: ${(p) => p.width && p.width};
  display: ${(p) => p.display && p.display};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  position: ${(p) => p.position && p.position};
`;

export const DropdownContent = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  border-radius: 4px;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  height: ${(p) => (p.height ? p.height : "100%")};
  width: ${(p) => (p.width ? p.width : "100%")};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : "#ffffff"};
  /* background-color: #ffffff; */
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: ${(p) => p.position && p.position};
`;

export const Spinner = styled.div`
  position: relative;
  border: 4px solid #f3f3f3;
  border-left: 6px solid #3498db;
  border-bottom: 6px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spinAnimation} 1s linear infinite;
  font-weight: bold;
`;
export const SpinnerLetter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: blue;
  pointer-events: none;
`;

export const DropdownToggle = styled.button`
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  /* width: 100%; */
`;

export const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const TextArea = styled.textarea`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  resize: ${(p) => p.resize && p.resize};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  outline: ${(p) => p.outline && p.outline};
  margin: ${(p) => p.margin && p.margin};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
`;
export const FileInput = styled.input`
  display: none;
`;
export const Pre = styled.pre`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin: ${(p) => p.margin && p.margin};
`;
export const GridContainer = styled.div`
  display: block;
  gap: 20px;
  padding-right: 20px;

  grid-template-areas: "overview live-dashboard";
  grid-template-rows: auto;
  grid-template-columns: 40% 60%;

  @media (max-width: 820px) {
    padding-right: 0px;

    grid-template-areas:
      "overview"
      "live-dashboard";

    grid-template-rows: auto;
    grid-template-columns: 100%;
  }
`;

export const GridContainer1 = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 20px;
  padding-right: 20px;

  grid-template-areas: "vital-summary treatment-plan";
  grid-template-rows: auto;
  grid-template-columns: 60% 40%;

  @media (max-width: 820px) {
    padding-right: 0px;
    grid-template-areas:
      "vital-summary"
      "treatment-plan";

    grid-template-rows: auto auto;
    grid-template-columns: 100%;
  }
`;

export const GridContainer3 = styled.div`
  display: grid;
  margin-top: 20px;

  grid-template-areas: "MyPatients";
  grid-template-rows: auto;
  grid-template-columns: 100%;

  @media (max-width: 820px) {
    grid-template-areas: "MyPatients";

    grid-template-rows: auto;
    grid-template-columns: 100%;
  }
`;
export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cbd5e0;
  transition: 0.4s;
  border-radius: 34px;
  cursor: ${(p) => (p.cursor ? "not-allowed" : "pointer")};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07), 0px 0.5px 2px rgba(0, 0, 0, 0.1);
  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 2px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: 0.4s;
    cursor: ${(p) => (p.cursor ? "not-allowed" : "pointer")};
  }
`;

export const Toggle = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${Slider} {
    background-color: #407bff;
    cursor: ${(p) => (p.cursor === "true" ? "not-allowed" : "pointer")};
    &:before {
      transform: translateX(8px);
      cursor: ${(p) => (p.cursor === "true" ? "not-allowed" : "pointer")};
    }
  }
`;
