import React, { useState, useEffect, useRef } from "react";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
  Spinner,
  SpinnerWrapper,
} from "../../StyledComponents/index";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { PaginDiv } from "./styles";
import Pagination from "../../StyledComponents/Pagination/Pagination";
import AddServices from "./AddServices";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllServiceList,
  GetCurrency,
  GetOneServiceList,
  setEmptyServiceResponse,
  setEmptyCodeMatchResponse,
} from "../../../StateManagement/Reducers/SettingState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import Logo from "../../../Assets/images/Logo.png";

const ServicesSheet = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const dispatch = useDispatch();
  const EmptyRowStyle = {
    height: "56px",
  };

  const ServiceList = useSelector((state) => state.Setting.ServiceList);
  const Servicealert = useSelector((state) => state.Setting.ServiceAlertMsg);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [id, setId] = useState("");
  const modalRef = useRef(null);

  const currency = useSelector((state) => state.Setting.Currency);
  // Calculate the number of empty rows needed for the current page
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const [currentPosts, setCurrentPost] = useState([]);
  const [howManyPages, setHowManyPages] = useState(0);
  const emptyRows = Math.max(0, 10 - currentPosts.length);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const change = (x) => {
    if (x === null || x === undefined) {
      return `${currency} 0`;
    }
    if (x === "") {
      return ``;
    } else {
      return `${currency} ${x}`;
    }
  };

  // Create an array of empty row placeholders
  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      code: "",
      codeName: "",
      fee: "",
      action: "",
      // You can customize the empty row properties here if needed
    })
  );
  const updatedEmptyRows = emptyRowPlaceholders.map((row) => ({
    ...row,
    fee: change(row.fee), // Remove dollar symbol
  }));
  const rowsToShow = [...currentPosts, ...updatedEmptyRows];

  const openModal = () => {
    dispatch(setEmptyServiceResponse());
    setId("0");
    setOpen(true);
  };
  const openEditModal = (id) => {
    dispatch(setEmptyCodeMatchResponse());
    setId(id);
    setOpen(true);
    dispatch(GetOneServiceList(id));
  };

  const handleMouseMovement = (event) => {
    if (modalRef.current !== null) {
      if (isOpen && !modalRef.current.contains(event.target)) {
        setTimeout(() => {
          document.body.style.overflow = "";
        }, 200);
      } else if (isOpen) {
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 200);
      }
    }
    event.preventDefault();
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMovement, {
      passive: false,
    });
    return () => {
      document.removeEventListener("mousemove", handleMouseMovement);
    };
  }, [isOpen]);

  useEffect(() => {
    if (Servicealert !== "") {
      if (Servicealert.includes("Deleted")) {
        setModalAlerShow(true);
        setModalMsg("Deleted Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetAllServiceList(serviceData));
      }
      if (Servicealert.includes("Updated")) {
        setModalAlerShow(true);
        setModalMsg("Updated Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetAllServiceList(serviceData));
      }
      if (Servicealert.includes("Inserted")) {
        setModalAlerShow(true);
        setModalMsg("Saved Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
        }, 1500);

        dispatch(GetAllServiceList(serviceData));
      }
    }
  }, [Servicealert]);
  let serviceData =
    "page=" +
    currentPage +
    "&pageSize=10&auth=1&authorized=" +
    1 +
    "&account=" +
    3 +
    "&trial=" +
    true +
    "&main_pro=" +
    "" +
    "&owner_id=" +
    null +
    "&pat_share=" +
    true +
    "&userID=" +
    userID;
  useEffect(() => {
    dispatch(GetAllServiceList(serviceData));
  }, [currentPage]);
  useEffect(() => {
    if (ServiceList) {
      setCurrentPost(ServiceList.data ?? []);
      setHowManyPages(ServiceList.totalPages ?? 0);
    }
  }, [ServiceList]);
  useEffect(() => {
    dispatch(GetCurrency());
    setModalAlerShow(false);
  }, []);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Div
        display="flex"
        marginTop="-3px"
        paddingBottom="16px"
        className="PatListShow"
        fontFamily="Open Sans, sans-serif"
        fontWeight="600"
        fontSize="18px"
        alignItems="center"
      >
        Services
      </Div>
      <Div backgroundColor="#FFFFFF" borderRadius="8px">
        <Div
          display="flex"
          paddingTop="18px"
          paddingBottom="15px"
          paddingLeft="24px"
          paddingRight="36px"
          border="1px solid #E2E8ED"
          borderBottom="0px"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          <Div
            display="flex"
            alignItems="center"
            width="70%"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="16px"
          >
            Services List
          </Div>

          <Button
            cursor="pointer"
            marginLeft="auto"
            padding="8px"
            border="1px solid #2C7BE5"
            borderRadius="4px"
            color="#FFFFFF"
            background="#2C7BE5"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14px"
            paddingLeft="14px"
            paddingRight="14px"
            onClick={() => openModal()}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Add Services
          </Button>
          {isOpen && (
            <AddServices
              id={id}
              setOpen={setOpen}
              show={isOpen}
              modalRef={modalRef}
              serviceData={serviceData}
            />
          )}
        </Div>
        <Table borderBottomLeftRadius="8px" borderBottomRightRadius="8px">
          <TableHead background="#F1F5F8">
            <Tablerow
              color="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="400"
              fontSize="14px"
              textAlign="center"
            >
              <TableHeadCell
                paddingLeft="50px"
                textAlign="left"
                paddingRight="0px"
                width="200px !important"
              >
                Code
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="20px"
                textAlign="left"
                paddingRight="0px"
                width="400px !important"
              >
                Code Name
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="20px"
                textAlign="left"
                paddingRight="0px"
                width="200px !important"
              >
                Price
              </TableHeadCell>
              <TableHeadCell
                paddingLeft="0px"
                paddingRight="0px"
                width="200px !important"
              >
                Action
              </TableHeadCell>
            </Tablerow>
          </TableHead>
          <TableBody>
            {currentPosts.length !== 0 ? (
              rowsToShow.map((details, index) => (
                <Tablerow
                  borderBottom="1px solid #E2E8ED"
                  fontFamily="Open Sans, sans-serif"
                  fontWeight="400"
                  fontSize="14px"
                  key={index}
                >
                  <TableBodyCell
                    color="#2C7BE5"
                    paddingLeft="50px"
                    textAlign="left"
                    paddingRight="0px"
                    width="200px !important"
                  >
                    {details.code_type === "Custom"
                      ? `${details.code}`
                      : details.code_type === "CPT4" && `CPT4-${details.code}`}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="20px"
                    paddingRight="0px"
                    width="400px !important"
                    textAlign="left"
                    overflowWrap="anywhere"
                  >
                    {details.code_text}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="20px"
                    textAlign="left"
                    paddingRight="0px"
                    width="200px !important"
                  >
                    {change(details.fee)}
                  </TableBodyCell>
                  <TableBodyCell
                    paddingLeft="0px"
                    paddingRight="0px"
                    style={details.code ? null : EmptyRowStyle}
                    textAlign="center"
                    width="200px !important"
                  >
                    {details.code ? (
                      <Button
                        cursor="pointer"
                        marginLeft="auto"
                        padding="8px"
                        border="1px solid #2C7BE5"
                        borderRadius="4px"
                        color="#FFFFFF"
                        background="#2C7BE5"
                        fontFamily="Open Sans, sans-serif"
                        fontWeight="600"
                        fontSize="14px"
                        paddingLeft="24px"
                        paddingRight="24px"
                        onClick={() => openEditModal(details.id)}
                      >
                        Edit
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableBodyCell>
                </Tablerow>
              ))
            ) : dataLoaded && currentPosts.length === 0 ? (
              <>
                <Tablerow>
                  <TableBodyCell textAlign="center" colSpan="8">
                    <Div>
                      <Image
                        height="133px"
                        width="133px"
                        src={Emp_data_pic}
                      ></Image>
                    </Div>
                  </TableBodyCell>
                </Tablerow>
                <Tablerow>
                  <TableBodyCell
                    textAlign="center"
                    colSpan="8"
                    paddingBottom="40px"
                  >
                    No services has been added to show in the services list
                  </TableBodyCell>
                </Tablerow>
              </>
            ) : (
              <SpinnerWrapper>
                <Spinner></Spinner>{" "}
                <Image
                  width="40px"
                  height="40px"
                  position="absolute"
                  src={Logo}
                />
              </SpinnerWrapper>
            )}
          </TableBody>
        </Table>
        <PaginDiv
          hidePagination={currentPosts.length === 0 || howManyPages === 1}
        >
          {currentPosts.length === 0 || howManyPages === 1 ? (
            ""
          ) : (
            <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
          )}
        </PaginDiv>
      </Div>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default ServicesSheet;
