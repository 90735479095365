import React, { memo } from "react";
import {
  Div,
  PageSubTitle,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
} from "../../../StyledComponents";

import permissionList from "./PermissionsData";

const Permissions = () => {
  return (
    <>
      <Div
        background="#FFFFFF"
        margin="10px 26px 37px 26px"
        borderRadius="6px"
        boxShadow=" 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Div>
          <PageSubTitle
            padding="27px 0px 20px 24px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="16px"
            fontStyle="normal"
            lineHight="normal"
            color="#2E2E2E"
          >
            Permission List
          </PageSubTitle>
        </Div>

        <Div>
          <Table>
            <TableHead background="#F1F5F8">
              <TableRow
                color="#2C7BE5"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                colSpan="3"
                paddingTop="19px"
                paddingBottom="18px"
                fontFamily="'Open Sans'sans-serif"
              >
                <TableHeadCell width="30%" paddingLeft="10%" textAlign="left">
                  Permission
                </TableHeadCell>
                <TableHeadCell paddingRight="0px" width="42%" textAlign="left">
                  Description
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {permissionList.map((item, index) => {
                return (
                  <TableRow
                    fontSize="14px"
                    lineHeight="20px"
                    fontFamily="'Open Sans'sans-serif"
                    borderBottom="1px solid #cbd5e0"
                    paddingTop="19px"
                    paddingBottom="18px"
                    key={index}
                  >
                    <TableBodyCell
                      paddingRight="0px"
                      fontWeight="400"
                      color="#2E2E2E"
                      paddingLeft="10%"
                      textAlign="left"
                    >
                      {item.permission}
                    </TableBodyCell>
                    <TableBodyCell
                      paddingRight="0px"
                      fontWeight="400"
                      textAlign="left"
                    >
                      {item.description}
                    </TableBodyCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Div>
      </Div>
    </>
  );
};

export default memo(Permissions);
