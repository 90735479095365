import styled from "styled-components";

export const Tooltip = styled.span`
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  &:hover .TooltipText {
    opacity: 1;
    visibility: visible;
  }
`;
export const TooltipText = styled.span`
  visibility: hidden;
  background-color: rgb(45, 55, 72);
  color: #fff;
  /* text-align: center; */
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 108%;
  left: -51%;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgb(45, 55, 72) transparent;
  }
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};

  &.voice-note-animation {
    width: 158px;
    height: 6px;
    background-color: #5f595c;
    position: relative;
    overflow: hidden;
  }

  &.voice-note-animation::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      #007bff 50%,
      #007bff 100%
    );
    transform: translateX(-100%);
    animation: wave-animation 1.5s linear infinite;
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  &.paused-animation::before {
    animation-play-state: paused;
  }
`;
