import { takeLatest } from "redux-saga/effects";
import {
    GetStateListDetail,
    GetLangListDetail,
    GetRaceListDetail,
    GetEthListDetail,
} from "../../StateManagement/Reducers/DropDownList";

import { StateListOptionsCheck, RaceListOptionsCheck, EthnicityListOptionsCheck, LangListOptionsCheck } from "./ListOptionsSagaWorkers";

export function* stateListOptions() {
    yield takeLatest(GetStateListDetail.type, StateListOptionsCheck);
}

export function* raceListOptions() {
    yield takeLatest(GetRaceListDetail.type, RaceListOptionsCheck);
}

export function* ethListOptions() {
    yield takeLatest(GetEthListDetail.type, EthnicityListOptionsCheck);
}

export function* langListOptions() {
    yield takeLatest(GetLangListDetail.type, LangListOptionsCheck);
}


