import React, { useState, useEffect } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import AddAllergy from "../../../Popup/AddAllergy/AddAllergy";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  SpanTag,
  Span,
  Image,
} from "../../../StyledComponents";
import {
  GetList,
  GetDeleteList,
  GetEditList,
  clearAllergyData,
} from "../../../../StateManagement/Reducers/HealthState";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";

const Allergy = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const dispatch = useDispatch();
  const pidvalue = props.pid;
  const prblmvalue = props.value;
  const [id, setId] = useState("0");
  const [modalMsg, setModalMsg] = useState("");
  const [show, setShow] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const allergyalert = useSelector((state) => state.Health.HealthAlertMsg);
  const AllergyLists = useSelector((state) => state.Health.List);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const lockClose = () => {
    setShow(false);
    setId("0");
  };
  const handleopen = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShow(true);
      setId("0");
      dispatch(clearAllergyData(""));
    }
  };
  const Status = (St) => {
    if (St === "0") {
      return "Inactive";
    } else if (St === "1") {
      return "Active";
    } else {
      return "Resolved";
    }
  };
  const handleopen1 = (id, pid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setId(() => {
        return id;
      });
      dispatch(GetEditList({ pid: pid, id: id, value: props.value }));
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  };

  const handledelete = (id, pid) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (window.confirm("Are you sure you want to Delete?")) {
        dispatch(GetDeleteList({ id: id, pid: pid, value: props.value }));
      }
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//
  // ------------------------------ useEffect Start----------------------------------------------------------- //

  useEffect(() => {
    if (allergyalert !== "") {
      if (allergyalert.includes("Inserted")) {
        setModalAlerShow(true);
        setModalMsg("Saved Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);

        dispatch(GetList({ pid: props.pid, value: props.value }));
      }
      if (allergyalert.includes("Updated")) {
        setModalAlerShow(true);
        setModalMsg("Updated Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);

        dispatch(GetList({ pid: props.pid, value: props.value }));
      }
      if (allergyalert.includes("Deleted")) {
        setModalAlerShow(true);
        setModalMsg("Deleted Successfully!");
        setStatus("success");

        setTimeout(() => {
          setModalAlerShow(false);
          setModalMsg("");
          setStatus("");
        }, 1500);

        dispatch(GetList({ pid: props.pid, value: props.value }));
      }
    }
  }, [allergyalert]);

  useEffect(() => {
    dispatch(GetList({ pid: props.pid, value: props.value }));
    setModalAlerShow(false);
  }, [props.value1]);
  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <Div
      background=" #FFFFFF"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="8px"
      height="100%"
    >
      <Div padding="15px 25px 15px 30px">
        <Div
          display="flex"
          alignItems="center"
          fontFamily="'Open Sans',sans-serif"
          fontStyle="normal"
          fontWeight="600"
          fontSize="18px"
          lineHeight="20px"
        >
          <Div> Allergies List</Div>

          <Button
            borderRadius="4px"
            border="1px solid #2C7BE5"
            padding="9px 36px 8px 36px"
            background="#2C7BE5"
            color="#FFFFFF"
            marginLeft="auto"
            cursor={
              SingPatDetails.enrollment_status !== "4"
                ? "pointer"
                : "not-allowed"
            }
            backgroundColor="#2C7BE5"
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            onClick={handleopen}
          >
            <Span
              fontSize="14px"
              fontWeight="600"
              fontFamily="'Open Sans', sans-serif"
            >
              Add Allergy
            </Span>
          </Button>
        </Div>
      </Div>
      <Div overflowY="auto" maxHeight="350px">
        <Table border="none">
          <TableHead
            background="#F1F5F8"
            hight="56px"
            style={{ position: "sticky", top: "0" }}
            zIndex="1"
          >
            <TableRow
              fontFamily="'Open Sans', sans-serif"
              fontStyle="normal"
              fontWeight="600"
              fontSize="14px"
              color="#2C7BE5"
              colSpan="5"
              paddingTop="18px"
              paddingBottom="18px"
              lineHeight="20px"
            >
              <TableHeadCell
                width="250px !important"
                textAlign="center"
                resWidth="20% !important"
              >
                Allergy Drug
              </TableHeadCell>
              <TableHeadCell textAlign="center" resWidth="15% !important">
                Reaction
              </TableHeadCell>
              <TableHeadCell textAlign="center">Status</TableHeadCell>
              <TableHeadCell textAlign="center">Notes</TableHeadCell>
              <TableHeadCell textAlign="center" paddingRight="16px">
                Action
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {AllergyLists.length !== 0 ? (
              AllergyLists.map((x, ind) => (
                <React.Fragment key={ind}>
                  <TableRow
                    fontFamily="'Open Sans', sans-serif"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                    color="#000000"
                    paddingTop="16px"
                    paddingBottom="18px"
                    borderBottom="1px solid #cbd5e0"
                    lineHeight="20px"
                    key={ind}
                  >
                    <TableBodyCell
                      // paddingLeft="42px !important"
                      width="250px !important"
                      textAlign="center"
                      paddingRight="0px"
                    >
                      {x.subtype}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {x.reaction ? x.reaction : "N/A"}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      {Status(x.activity)}
                    </TableBodyCell>
                    <TableBodyCell
                      textAlign="center"
                      paddingRight="0px"
                      title={x.comments}
                    >
                      {x.comments ? truncateText(x.comments, 30) : "None"}
                    </TableBodyCell>
                    <TableBodyCell textAlign="center" paddingRight="0px">
                      <SpanTag
                        height="18px"
                        width="18px"
                        color=" #2C7BE5"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                        onClick={() => handleopen1(x.id, x.pid)}
                      >
                        <RiEdit2Line />
                      </SpanTag>
                      <SpanTag
                        height="18px"
                        width="16px"
                        color=" #B00020"
                        marginLeft="31px"
                        cursor={
                          SingPatDetails.enrollment_status !== "4"
                            ? "pointer"
                            : "not-allowed"
                        }
                        onClick={() => handledelete(x.id, x.pid)}
                      >
                        <FiTrash2
                          style={{ fill: "#ffffff", stroke: "#f24822" }}
                        />
                      </SpanTag>
                    </TableBodyCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow height="309px">
                <TableBodyCell textAlign="center" colSpan={5}>
                  <Div>
                    <Image
                      height="150px"
                      width="150px"
                      src={Emp_data_pic}
                    ></Image>
                  </Div>
                  <Div
                    fontSize="14px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans',sans-serif"
                    lineHeight="20px"
                    fontWeight="400"
                  >
                    No allergies to be shown,Please add some.
                  </Div>
                </TableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Div>
      <AddAllergy
        show={show}
        close={lockClose}
        id={id}
        pidvalue={pidvalue}
        prblmvalue={prblmvalue}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Div>
  );
};

export default Allergy;
