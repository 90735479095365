import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import BillingTab from "./BillingTab";
import InsuranceTab from "./InsuranceTab";
import PatientDemoHead from "../PatientDemo/PatientDemoHead";
import PatientDemographics from "../PatientDemo/PatientDemographics";
import { Tabs, TabPanel } from "./styles";
import { GetCardDetails } from "../../../StateManagement/Reducers/PatientState";
import { useParams } from "react-router-dom";
import { SetChartviewIdempty } from "../../../StateManagement/Reducers/EncounterState";
import { SetpatientDemotimer } from "../../../StateManagement/Reducers/PatientDemoState";

const BillandInsurance = () => {
  //------------------------------------------State and Variables Start--------------------------------------//
  const dispatch = useDispatch();
  const params = useParams();
  const patId = params.pid;
  const [opt, setOpt] = useState("1");
  const [billOpt, setBillOpt] = useState(true);
  const [insOpt, setInsOpt] = useState(false);

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//

  const handleTabs = (e) => {
    if (e === "1") {
      setOpt("1");
      setBillOpt(true);
      setInsOpt(false);
    } else if (e === "2") {
      setOpt("2");
      setInsOpt(true);
      setBillOpt(false);
    }
  };

  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start--------------------------------------------------//

  useEffect(() => {
    dispatch(GetCardDetails(patId));
    setTimeout(() => {
      dispatch(SetChartviewIdempty());
      dispatch(
        SetpatientDemotimer({
          patId: "",
          timer: "",
        })
      );
    }, 1000);
  }, [dispatch]);
  //------------------------------------------UseEffect End--------------------------------------------------//
  return (
    <Container backgroundColor="#F5F5F5" position="relative">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" className="responsive-right">
        <Div background="#F5F5F5" padding="20px">
          <Div padding="20px" backgroundColor="#FFFFFF" borderRadius="8px">
            <PatientDemoHead PatinetInfo="Patient Info" />

            <PatientDemographics />
          </Div>
        </Div>
        <Div background="#F5F5F5" padding="0px 24px 24px 20px">
          <Div background="#FFFFFF">
            <TabPanel>
              <Tabs
                active={billOpt}
                line={opt}
                onClick={() => handleTabs("1")}
                id="dbrd_billing-lst"
              >
                Billing
              </Tabs>
              <Tabs
                active={insOpt}
                onClick={() => handleTabs("2")}
                id="dbrd_insurance-lst"
              >
                Insurance
              </Tabs>
            </TabPanel>
            {opt === "1" ? <BillingTab /> : <InsuranceTab />}
          </Div>
        </Div>
      </Div>
    </Container>
  );
};
export default BillandInsurance;
