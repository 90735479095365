import React, { memo } from "react";
import userpic from "../../Assets/images/access_error.svg";
import { Link } from "react-router-dom";
import { Div, Image } from "../StyledComponents";

function AccesDenied(props) {
  return (
    <>
      <Div
        margin="16px 20px 20px 20px"
        backgroundColor="#ffffff"
        borderRadius="16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="17px 40px"
      >
        <Div display="block">
          <Div
            fontSize="26px"
            fontWeight="600"
            color="#000000"
            fontFamily="work sans, sans-serif"
            lineHeight="30.5px"
          >
            Permission Denied
          </Div>
          <Div
            color=" #718096"
            fontSize="14px"
            lineHeight=" 17px"
            marginTop=" 5px"
          >
            You don’t have permission to view this page.
          </Div>
          {/* <div className="perission_bo_back_btn">
             <Link to={{ pathname: back }}>
              {" "}
              <Button variant="primary" className="go_back_btnn">
                {" "}
                Go Back home{" "}
              </Button>
            </Link> 
          </div> */}
        </Div>
        <>
          <Image
            width={props.width && props.width}
            height={props.height && props.height}
            src={userpic}
            alt="user"
          />
        </>
      </Div>
    </>
  );
}

export default memo(AccesDenied);
