import React, { useEffect } from "react";
import {
  Div,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  SpanTag,
  Image,
} from "../../../StyledComponents";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { RiEdit2Line } from "react-icons/ri";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import {
  GetDeletePlan,
  GetPlanDetailsforDashB,
  SetDeletePlan,
} from "../../../../StateManagement/Reducers/TreatmentPlanState";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import { useNavigate } from "react-router";

const TreatmentplanList = ({ pid }) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");

  const TreatPlanList = useSelector(
    (state) => state.TreatmentPlan.TreatmentPlanDetailsForDashBoard
  );

  const DeleteData = useSelector((state) => state.TreatmentPlan.DeleteResponse);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  const [list, setList] = useState([]);
  const EmptyRowStyle = {
    height: "48px",
  };
  const emptyRows = Math.max(0, 7 - list.length);

  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      createdBy: "",
      planName: "",
      AddedDate: "",
    })
  );

  const rowsToShow = [...list, ...emptyRowPlaceholders];

  const deletePlan = (id) => {
    if (window.confirm("Are you sure you want to Delete?")) {
      dispatch(
        GetDeletePlan({
          pid: pid,
          planId: id,
          searchKey_provider: "",
          searchKey_plan: "",
          page: "",
          pageSize: "",
        })
      );
    }
  };

  const editTreatmentPlan = (a, b) => {
    Navigate(`/edittreatment/${a}/${b}`);
  };

  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (DeleteData !== "") {
      if (DeleteData && DeleteData === "Deleted Successfully") {
        setShowAlert(true);
        setModalMsg("Deleted Successfully");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
      }
      dispatch(SetDeletePlan(""));
    }
  }, [DeleteData]);

  useEffect(() => {
    // setCurrentPage(1);
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        GetPlanDetailsforDashB({
          searchKey_provider: "",
          searchKey_plan: "",
          page: "",
          pageSize: "",
          pid: pid,
        })
      );
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [pid]);

  useEffect(() => {
    setList(TreatPlanList.datas ?? []);
  }, [TreatPlanList]);

  return (
    <Div
      background=" #FFFFFF"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="8px"
      height="100%"
    >
      <Div padding="17px 25px 16px 18px">
        <Div
          borderRadius="8px"
          display="flex"
          alignItems="center"
          fontFamily="'Open Sans',sans-serif"
          fontStyle="normal"
          fontWeight="600"
          fontSize="18px"
          lineHeight="20px"
        >
          <Div> Treatment plan List</Div>
        </Div>
      </Div>
      <Div overflowY="auto" maxHeight="430px">
        <Table border="none">
          <TableHead
            background="#F1F5F8"
            hight="56px"
            style={{ position: "sticky", top: "0", zIndex: "1" }}
          >
            <TableRow
              fontFamily="'Open Sans', sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              color="#2C7BE5"
              colSpan="5"
              paddingTop="14px"
              paddingBottom="14px"
            >
              <TableHeadCell textAlign="left" paddingLeft="24px">
                Created by
              </TableHeadCell>
              <TableHeadCell textAlign="left">Plan Name</TableHeadCell>
              <TableHeadCell textAlign="left" width="25%">
                Added Date
              </TableHeadCell>
              <TableHeadCell textAlign="center" paddingRight="16px" width="25%">
                Action
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list.length !== 0 ? (
              rowsToShow.map((x, ind) => (
                <React.Fragment key={ind}>
                  <TableRow
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    color="#000000"
                    lineHeight="19px"
                    borderBottom="1px solid #cbd5e0"
                    paddingTop="12px"
                    paddingBottom="16px"
                    key={ind}
                  >
                    <TableBodyCell
                      textAlign="left"
                      paddingRight="0px"
                      paddingLeft="26px"
                    >
                      {x.providerName}
                    </TableBodyCell>
                    <TableBodyCell
                      textAlign="left"
                      paddingRight="0px"
                      wordBreak="break-word"
                      title={x.plan_name}
                    >
                      {truncateText(x.plan_name, 30)}
                    </TableBodyCell>
                    <TableBodyCell textAlign="left" paddingRight="0px">
                      {x.date_added}
                    </TableBodyCell>
                    <TableBodyCell
                      textAlign="center"
                      paddingRight="16px"
                      style={x.providerName ? null : EmptyRowStyle}
                    >
                      {x.providerName ? (
                        <>
                          <SpanTag
                            height="18px"
                            width="18px"
                            color=" #2C7BE5"
                            cursor={
                              SingPatDetails?.enrollment_status === "4"
                                ? "not-allowed"
                                : "pointer"
                            }
                            onClick={() =>
                              SingPatDetails?.enrollment_status === "4"
                                ? ""
                                : editTreatmentPlan(x.id, x.pid)
                            }
                          >
                            <RiEdit2Line />
                          </SpanTag>
                          <SpanTag
                            height="18px"
                            width="16px"
                            color=" #B00020"
                            marginLeft="25%"
                            cursor={
                              SingPatDetails?.enrollment_status === "4"
                                ? "not-allowd"
                                : "pointer"
                            }
                            onClick={() =>
                              SingPatDetails?.enrollment_status === "4"
                                ? ""
                                : deletePlan(x.id)
                            }
                          >
                            <FiTrash2
                              style={{ fill: "#ffffff", stroke: "#f24822" }}
                            />
                          </SpanTag>
                        </>
                      ) : (
                        ""
                      )}
                    </TableBodyCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow height="307px">
                <TableBodyCell textAlign="center" colSpan={5}>
                  <Div>
                    <Image
                      height="150px"
                      width="150px"
                      src={Emp_data_pic}
                    ></Image>
                  </Div>
                  <Div
                    fontSize="14px"
                    color="#2E2E2E"
                    fontFamily="'Open Sans',sans-serif"
                    lineHeight="20px"
                    fontWeight="400"
                  >
                    No TreatmenPlans to be shown,Please add some.
                  </Div>
                </TableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Div>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </Div>
  );
};

export default TreatmentplanList;
