import { call, put } from "redux-saga/effects";
import { Decrypt_Value } from "../EncryptDecrypt";
import {
  ChartDataApi,
  GetLoginTime_Api,
  InitialLogCheck_Api,
} from "../Apis/DashboardApi";
import {
  SetLoginTime,
  SetchartData,
} from "../../StateManagement/Reducers/DashboardState";

export function* getAllChartData({ payload }) {
  try {
    const res = yield call(ChartDataApi, payload);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetchartData(Data));
      } else {
        yield put(SetchartData([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetchartData([]);
  }
}

export function* getLoginTimeApi({ payload }) {
  try {
    const res = yield call(GetLoginTime_Api, payload);
    if (res.status === 200) {
      if (res.data.length !== 0) {
        const Data = Decrypt_Value(res.data, "vozorpm");
        yield put(SetLoginTime(Data));
      } else {
        yield put(SetLoginTime([]));
      }
    }
  } catch (e) {
    console.log(e.message);
    SetLoginTime([]);
  }
}

export function* checkInitialLog({ payload }) {
  try {
    const res = yield call(InitialLogCheck_Api, payload);
    if (res.status === 200) {
    }
  } catch (e) {
    console.log(e.message);
  }
}
