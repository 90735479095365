import React from "react";
import LeftNav from "../LeftNav";
import TopNav from "../TopNav";
import { Container, Div } from "../StyledComponents";
import DeviceList from "./DeviceList";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import AccessDenied from "../AccessDenied";

const Devices = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#F5F5F5">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" className="responsive-right">
        {permissions.includes("device_inventory") ? (
          <Div padding="16px 22px 24px 20px" backgroundColor="#F1F5F8">
            <DeviceList />
          </Div>
        ) : (
          <AccessDenied />
        )}
      </Div>
    </Container>
  );
};
export default Devices;
