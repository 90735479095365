import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  GetVitals,
  MsgResponse,
  setMessageList,
  setPreviewDownload,
  setReadingsData,
  setDeviceList,
  DeviceResponse,
  SetSerialCheck,
  SetStatus,
  setMarsonikSource,
  setIntegrationResponse,
  SetTokenCheck,
  setFiltDeviceList,
} from "../../StateManagement/Reducers/PatientDemoState";
import {
  GetVitals_Api,
  sendMsgApi,
  MessageListApi,
  PreviewDownloadApi_1,
  ReadingChartApi,
  AddDeviceApi,
  DeviceListApi,
  SerialNoCheckApi,
  AddDeviceMenuApi,
  StatusApi,
  ConnectDeviceApi,
  TokenApi,
  DisconnectDeviceApi,
} from "../Apis/PatientDemo";

export function* VitalsApi({ payload }) {
  try {
    const res = yield call(GetVitals_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(GetVitals(Data));
    }
  } catch (e) {
    yield put(GetVitals("CAN'T FETCH VITALS"));
  }
}

export function* SendMsgWorker({ payload }) {
  const payload2 = payload.get("message");
  const decryptpayload = Decrypt_Value(payload2, "vozorpm");
  const Provider_Id = decryptpayload.sender_id;
  const pid_Id = decryptpayload.receive_id;
  try {
    const res = yield call(sendMsgApi, payload);
    if (res.status === 200) {
      const getData = { Pid: pid_Id, Provider_Id: Provider_Id };
      let ecrypted = Encrypt_Value(getData, "vozorpm");
      const resL = yield call(MessageListApi, ecrypted);
      if (resL.status === 200) {
        let Decrypted_data = Decrypt_Value(resL.data, "vozorpm");
        yield put(setMessageList(Decrypted_data));
      }
    }
  } catch (e) {
    yield put(MsgResponse("CAN'T POST"));
  }
}

export function* MessageList({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozorpm");
  try {
    const res = yield call(MessageListApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozorpm");
      yield put(setMessageList(Decrypted));
    }
  } catch (e) {
    yield put(setMessageList([]));
  }
}

export function* PreviewDownloadApi({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozorpm");
  try {
    const res = yield call(PreviewDownloadApi_1, credential);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozorpm");
      yield put(setPreviewDownload(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* EmptyDownloadApi() {
  yield put(setPreviewDownload(""));
}
export function* PatientStatus({ payload }) {
  try {
    const res = yield call(StatusApi, payload);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "rpmportal");
      yield put(SetStatus(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetStatus(""));
  }
}
export function* ReadingChartData({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozorpm");
  try {
    const res = yield call(ReadingChartApi, credential);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozorpm");
      yield put(setReadingsData(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setReadingsData({}));
  }
}

export function* DevicesAdd({ payload }) {
  const pid = payload.pid;
  yield put(SetSerialCheck(""));
  let credential = Encrypt_Value(payload, "vozorpm");
  try {
    if (payload.src === "Demo") {
      const res = yield call(AddDeviceApi, credential);
      if (res.status === 200) {
        const response = yield call(DeviceListApi, pid);
        if (res.data !== "error_tenovi") {
          yield put(DeviceResponse("Devices added sucessfully"));
        } else {
          yield put(DeviceResponse("error_tenovi"));
        }
        if (response.status === 200) {
          const Data = Decrypt_Value(response.data, "vozorpm");
          yield put(setDeviceList(Data));

          const devicesList1 = Data.Device_model?.filter(
            (item) =>
              item.value !== "HR2" &&
              item.value !== "HR3" &&
              item.name !== "Dexcom" &&
              item.name !== "Terra App - Freestyle Libre" &&
              item.name !== "Terra App - Omron" &&
              item.name !== "Marsonik" &&
              item.name !== "Ambrosia App" &&
              item.name !== "Tidepool"
          );
          const newData = { ...Data, Device_model: devicesList1 };
          yield put(setFiltDeviceList(newData));
        }
      }
    } else {
      const res = yield call(AddDeviceMenuApi, credential);
      if (res.status === 200) {
        yield put(DeviceResponse("Devices added sucessfully"));
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(DeviceResponse("Unable to add Devices"));
  }
}
export function* getDevices({ payload }) {
  try {
    const res = yield call(DeviceListApi, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setDeviceList(Data));
      const devicesList1 = Data.Device_model?.filter(
        (item) =>
          item.value !== "HR2" &&
          item.value !== "HR3" &&
          item.name !== "Dexcom" &&
          item.name !== "Terra App - Freestyle Libre" &&
          item.name !== "Terra App - Omron" &&
          item.name !== "Marsonik" &&
          item.name !== "Ambrosia App" &&
          item.name !== "Tidepool"
      );
      const newData = { ...Data, Device_model: devicesList1 };
      yield put(setFiltDeviceList(newData));
    }
  } catch (e) {
    yield put(setDeviceList([]));
    console.log(e.message);
  }
}
export function* serialNoCheckWorker({ payload }) {
  try {
    yield put(SetSerialCheck(""));
    const res = yield call(SerialNoCheckApi, payload);
    if (res.status === 200) {
      // const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetSerialCheck(res.data));
    }
  } catch (e) {
    yield put(SetSerialCheck(""));
    console.log(e.message);
  }
}

export function* IntegrationWorker({ payload }) {
  try {
    const res = yield call(ConnectDeviceApi, payload);
    if (res.status === 200) {
      if (payload.device === "Marsonik") yield put(setMarsonikSource(res.data));
      else yield put(setIntegrationResponse(res.data));
    }
  } catch (e) {
    yield put(setMarsonikSource(""));
    yield put(setIntegrationResponse(""));
    console.log(e.message);
  }
}

export function* TokenDetails({ payload }) {
  try {
    const res = yield call(TokenApi, payload);
    if (res.status === 200) {
      yield put(SetTokenCheck(res.data));
      if (parseInt(res.data) === 1) {
        yield put(setIntegrationResponse("Succeeded"));
      }
    }
  } catch (e) {
    yield put(SetTokenCheck(""));
    console.log(e.message);
  }
}

export function* DisconnectWorker({ payload }) {
  try {
    const res = yield call(DisconnectDeviceApi, payload);
    if (res.status === 200) {
      yield put(setIntegrationResponse(res.data));
    }
  } catch (e) {
    yield put(setIntegrationResponse(""));
  }
}
