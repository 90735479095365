import React, { memo } from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import EncounterSheet from "./EncounterSheet";
import PatientDemoHead from "../PatientDemo/PatientDemoHead";
import PatientDemographics from "../PatientDemo/PatientDemographics";
import AccesDenied from "../../AccessDenied";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const Encounter = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#F5F5F5">
      <TopNav />
      <LeftNav />

      <Div marginLeft="56px" className="responsive-right">
        {permissions.includes("encounter_sumry") ? (
          <>
            <Div background="#F5F5F5" padding="20px">
              <Div
                padding="20px"
                backgroundColor="#FFFFFF"
                marginBottom="20px"
                borderRadius="8px"
              >
                <PatientDemoHead PatinetInfo="Patient Info" />
                <PatientDemographics />
              </Div>

              <Div
                backgroundColor="#FFFFFF"
                border="1px solid #E2E8ED"
                borderRadius="8px"
              >
                <EncounterSheet />
              </Div>
            </Div>
          </>
        ) : (
          <>
            <AccesDenied />
          </>
        )}
      </Div>
    </Container>
  );
};
export default memo(Encounter);
