const permissionList = [
  {
    permission: "Access Dashboard",
    description: "Allow user to view summarized overview of the system",
    key: "dashboard_access",
  },
  {
    permission: "Enroll & Unenroll Patients",
    description: "Allow the user to enroll & unenroll patient",
    key: "pat_enroll",
  },
  {
    permission: "Update Patients",
    description: "Allow the user to update patient information",
    key: "pat_update",
  },
  {
    permission: "Import & Export Patients",
    description: "Allow the user to use import and export tools",
    key: "export_tool",
  },
  {
    permission: "Add Devices",
    description: "Allow the user to assign devices to the patients",
    key: "device_assign",
  },
  {
    permission: "Access Add Encounter",
    description: "Allow user to schedule an encounter for a patient",
    key: "encounter_add",
  },
  {
    permission: "Access Visit Encounter",
    description: "Allow user to view and delete encounters visit history",
    key: "encounter_visit",
  },
  {
    permission: "Access Encounter Summary",
    description: "Allow user to view and delete encounter summary",
    key: "encounter_sumry",
  },
  {
    permission: "Access Fee Sheet",
    description: "Allow user to view, delete and export encounters fee sheet",
    key: "feesheet",
  },
  {
    permission: "View Live",
    description: "Allow user to view live vital readings",
    key: "live_access",
  },
  {
    permission: "Access Devices",
    description: "Allow user to view and add or edit devices to the inventory",
    key: "device_inventory",
  },
  {
    permission: "Treatment Plan",
    description:
      "Allow user to view, create and assign a treatment plan to patients",
    key: "treat_plan",
  },
  {
    permission: "Manage Profile",
    description: "Allows the user to view and edit doctor's profiles",
    key: "profile_edit",
  },
  {
    permission: "Access to message center",
    description: "Allows the user to access the message center",
    key: "message_access",
  },
  {
    permission: "Show Notifications",
    description: "Allow access to view or show notification",
    key: "notifics",
  },
  {
    permission: "Manage Permissions",
    description:
      "Allows the user to manage permissions of their doctors and staff",
    key: "permission_access",
  },
  {
    permission: "Sign/Lock Encounters",
    description: "Allows the user to sign and lock encounter",
    key: "enc_lock",
  },
  {
    permission: "Access RPM Notes",
    description: "Allow user to add and delete appointments",
    key: "notes_lock",
  },
  {
    permission: "Manage Users",
    description: "Allows the user to create new or edit existing staff members",
    key: "user_add",
  },
  {
    permission: "Manage Services",
    description: "Allows the user to create new or edit existing services",
    key: "service_access",
  },
  {
    permission: "Manage Documents",
    description: "Allow user to manage how things are documented",
    key: "docs_access",
  },
  {
    permission: "Access Security",
    description: "Allow user to change the accounts security settings",
    key: "security_access",
  },
  {
    permission: "Show Vitals",
    description: "Allow user to view vitals of individual patients",
    key: "vitals_access",
  },
  {
    permission: "Show Readings graph",
    description:
      "Allow user to view graph readings based on the vitals of patients",
    key: "graph_access",
  },
];

export default permissionList;
