import { takeLatest } from "redux-saga/effects";
import {
  MessengerList,
  MessengerPatientList,
  startSendMessage,
  startSendImage,
  getSingle,
  previewDownloadWorker,
  UnreadCount,
} from "./MessengerSagaWorker";
import {
  getMessageList,
  getMsgPatsearch,
  sendMessage,
  sendImage,
  getSingleMessage,
  getPreviewFile,
  getUnreadCount,
} from "../../StateManagement/Reducers/MessengerState";

export function* GetMessenger() {
  yield takeLatest(getMessageList.type, MessengerList);
}

export function* GetMessengerPatient() {
  yield takeLatest(getMsgPatsearch.type, MessengerPatientList);
}

export function* SendMessage() {
  yield takeLatest(sendMessage.type, startSendMessage);
}

export function* SendImageMessage() {
  yield takeLatest(sendImage.type, startSendImage);
}

export function* SingleMessage() {
  yield takeLatest(getSingleMessage.type, getSingle);
}

export function* PreviewDownload() {
  yield takeLatest(getPreviewFile.type, previewDownloadWorker);
}

export function* UnreadCountSaga() {
  yield takeLatest(getUnreadCount.type, UnreadCount);
}
