import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Div,
  SelectInput,
  FormLabel,
  Button,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
} from "../../StyledComponents";
import {
  TitleHead,
  Checkbox,
  CardContainer,
  CardGroup,
  DispFlex,
  VisaImg,
  LogoChip,
  CardNum,
  ValidThr,
  HoldName,
  HoldStatTxt,
  HoldDynTxt,
  CardHoldTxt,
  ValidTxt,
  RadOpacityBg1,
  RadOpacityBg,
} from "./styles";
import AddCard from "../../StyledComponents/AddCard";
import ChipPin from "./Group.png";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { GetCardDetails } from "../../../StateManagement/Reducers/PatientState";
import { useParams } from "react-router-dom";

const BillingTab = () => {
  //------------------------------------------State and Variables Start--------------------------------------//

  const dispatch = useDispatch();
  const params = useParams();
  const patId = params.pid;
  const [AddShow, setAddShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [idx, setIdx] = useState([]);
  const [billDocumentHideshow, setbillDocumentHideshow] = useState(true);
  const [DropdownSelectOptions, setDropdownSelectOptions] = useState(
    "Send email with billing documents attached"
  );
  const [ShowOptions, setShowOptions] = useState(false);

  const CardDetails = useSelector((state) => state.Patient.CardDetail);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const DropdownOptionList = [
    {
      label: " Send email with billing documents attached",
      id: "1",
      Key: "SendEmail",
    },
    { label: "None", id: "2", Key: "None" },
  ];

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//

  const setbillDocuments = (r) => {
    if (r.Key === "None") {
      setbillDocumentHideshow(false);
    } else {
      setbillDocumentHideshow(true);
    }
    setDropdownSelectOptions(r.label);
    setShowOptions(false);
  };

  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "sendemail_dropdown") {
      setShowOptions(false);
    }
  };

  const handleEdit = (ind) => {
    if (SingPatDetails.enrollment_status !== "4") {
      setAddShow(true);
      setEdit(true);
      setIdx(ind);
    }
  };

  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start--------------------------------------------------//

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  useEffect(() => {
    dispatch(GetCardDetails(patId));
  }, [dispatch]);
  //------------------------------------------UseEffect End--------------------------------------------------//
  return (
    <>
      <Div padding="24px 24px 24px 28px">
        <TitleHead>My Cards</TitleHead>

        <CardGroup>
          {CardDetails.length !== 0 ? (
            <>
              {CardDetails.map((row, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <CardContainer
                      selected={ind}
                      img={row.card_type}
                      onClick={() => handleEdit(row)}
                      cursor={
                        SingPatDetails.enrollment_status !== "4"
                          ? "pointer"
                          : "not-allowed"
                      }
                    >
                      <DispFlex>
                        <VisaImg img={row.cardType} selected={ind} />
                        <LogoChip src={ChipPin} />
                      </DispFlex>

                      <CardNum selected={ind}>{row.number}</CardNum>

                      <HoldStatTxt>
                        <CardHoldTxt selected={ind}>Card Holder</CardHoldTxt>
                        <ValidTxt selected={ind}>Valid</ValidTxt>
                      </HoldStatTxt>
                      <HoldDynTxt>
                        <HoldName selected={ind}>{row.name}</HoldName>
                        <ValidThr selected={ind}>{row.valid}</ValidThr>
                      </HoldDynTxt>
                      <RadOpacityBg selected={ind}></RadOpacityBg>
                      <RadOpacityBg1 selected={ind}></RadOpacityBg1>
                    </CardContainer>
                    {CardDetails.length - 1 === ind && (
                      <>
                        {ind < 2 && (
                          <Div display="flex">
                            <Div
                              width="320px"
                              height="182px"
                              border="1px dashed #2C7BE5"
                              borderRadius="4px"
                              padding="81px 120px"
                              cursor={
                                SingPatDetails.enrollment_status !== "4"
                                  ? "pointer"
                                  : "not-allowed"
                              }
                              backgroundColor="#F1F5F8"
                              onClick={() => {
                                if (SingPatDetails.enrollment_status !== "4") {
                                  setAddShow(true);
                                  setEdit(false);
                                  setIdx([]);
                                }
                              }}
                            >
                              <FormLabel
                                cursor="pointer"
                                color="#2C7BE5"
                                fontSize="14px"
                              >
                                + Add Card
                              </FormLabel>
                            </Div>
                          </Div>
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}{" "}
            </>
          ) : (
            <Div display="flex">
              <Div
                width="320px"
                height="182px"
                border="1px dashed #2C7BE5"
                borderRadius="4px"
                padding="81px 120px"
                cursor={
                  SingPatDetails.enrollment_status !== "4"
                    ? "pointer"
                    : "not-allowed"
                }
                backgroundColor="#F1F5F8"
                onClick={() => {
                  if (SingPatDetails.enrollment_status !== "4") {
                    setAddShow(true);
                    setEdit(false);
                    setIdx([]);
                  }
                }}
              >
                <FormLabel
                  cursor={
                    SingPatDetails.enrollment_status !== "4"
                      ? "pointer"
                      : "not-allowed"
                  }
                  color="#2C7BE5"
                  fontSize="14px"
                >
                  + Add Card
                </FormLabel>
              </Div>
            </Div>
          )}
        </CardGroup>
      </Div>
      <Div
        margin="0px 0px 0px 0px"
        borderBottom="1px solid rgba(46, 46, 46, 0.25)"
      ></Div>
      <Div padding="24px 24px 24px 28px" display="grid">
        <TitleHead>Email Billing Notification</TitleHead>
        <Div>
          <SelectInput
            width="412px"
            name="sendemail_dropdown"
            height="38px"
            background="#FFFFFF"
            fontSize="14px"
            fontFamily="'Open Sans',sans-serif"
            border="1px solid rgba(46, 46, 46, 0.25)"
            borderRadius="4px"
            color="#2E2E2E"
            pl="10px"
            margin="24px 0px 0px 0px"
            padding="9px 12px"
            onClick={() => setShowOptions(!ShowOptions)}
          >
            {DropdownSelectOptions}
          </SelectInput>
          <DropdownImage src={dropdownDownicon}></DropdownImage>
        </Div>
        {ShowOptions ? (
          <DropDownDiv
            width="412px"
            background="#FFFFFF"
            border=" 1px solid rgba(46, 46, 46, 0.25)"
            borderRadius="4px"
            marginTop="81px"
          >
            {DropdownOptionList.map((r, i) => {
              return (
                <DropdownOptions
                  key={i}
                  value={r.Key}
                  name={r.Key}
                  padding="10px 12px 8px 12px"
                  onClick={() => setbillDocuments(r)}
                >
                  {r.label}
                </DropdownOptions>
              );
            })}
          </DropDownDiv>
        ) : (
          ""
        )}

        {billDocumentHideshow && (
          <Div margin="19px 0px 0px 0px">
            <Checkbox></Checkbox>
            <FormLabel color="#2E2E2E" fontSize="14px" marginLeft="10px">
              Email generated Invoice to this mail
            </FormLabel>
          </Div>
        )}
        <Div margin="118px 0px 0px 0px" textAlign="end">
          <Button
            width="136px"
            height="36px"
            background="#2C7BE5"
            borderRadius="4px"
            color="#FFFFFF"
            border="none !important"
            cursor={
              SingPatDetails.enrollment_status !== "4"
                ? "pointer"
                : "not-allowed"
            }
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Save
          </Button>
        </Div>
      </Div>

      <AddCard
        show={AddShow}
        edit={edit}
        row={idx}
        HandleModal={() => setAddShow(false)}
      />
    </>
  );
};
export default BillingTab;
