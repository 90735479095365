import React, { useState } from "react";
import {
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  SpanTag,
  Image,
  Span,
  Input,
} from "../../../StyledComponents";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import { RiSearchLine } from "react-icons/ri";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPlanDetails,
  GetPlanDetailsforDashB,
  GetPlanforPat,
  SetPlanforPat,
} from "../../../../StateManagement/Reducers/TreatmentPlanState";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";

const AddTreatmentPlanDashTable = ({ pid }) => {
  const dispatch = useDispatch();
  const [referalName, setReferalName] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");

  const TreatPlanList = useSelector(
    (state) => state.TreatmentPlan.TreatmentPlanDetails
  );

  const InsertData = useSelector(
    (state) => state.TreatmentPlan.InsertPlanToPatient
  );
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const [list, setList] = useState([]);

  const EmptyRowStyle = {
    height: "37px",
  };
  const emptyRows = Math.max(0, 10 - list.length);

  const emptyRowPlaceholders = Array.from(
    { length: emptyRows },
    (_, index) => ({
      plan_name: "",
    })
  );

  const rowsToShow = [...list, ...emptyRowPlaceholders];

  const AssignPlanToPatient = (planId) => {
    dispatch(
      GetPlanforPat({
        planId: planId,
        pid: pid,
        searchKey_provider: "",
        searchKey_plan: "",
        page: "",
        pageSize: "",
      })
    );
  };

  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (InsertData !== "") {
      if (InsertData === "success") {
        setShowAlert(true);
        setModalMsg("Assigned successfully to the Patient");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);

        dispatch(SetPlanforPat(""));
      }
    }
  }, [InsertData]);

  useEffect(() => {
    // setCurrentPage(1);
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        GetPlanDetails({
          searchKey_provider: referalName,
          searchKey_plan: "",
          page: "",
          pageSize: "",
          pid: 0,
        })
      );
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [referalName]);

  useEffect(() => {
    setList(TreatPlanList.datas ?? []);
  }, [TreatPlanList]);

  return (
    <>
      <Div
        background=" #FFFFFF"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="8px"
        height="100%"
      >
        <Div>
          <Div
            borderRadius="8px"
            display="flex"
            alignItems="center"
            fontFamily="'Open Sans',sans-serif"
            fontStyle="normal"
            fontWeight="600"
            fontSize="16px"
            lineHeight="20px"
            justifyContent="space-between"
            padding="8px  17px 8px 17px"
          >
            <Div> Add Treatment Plan</Div>
            <Div
              width="200px"
              height="28px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              display="flex"
              alignItems="center"
              padding="4px 0 4px 9px"
              borderRadius="4px"
            >
              <RiSearchLine
                fontSize="16px"
                style={{ color: "rgba(46, 46, 46, 1)" }}
              />
              <Input
                padding="0 0 0 6px"
                fontSize="12px"
                fontWeight="400"
                width="100%"
                height="100%"
                border="none"
                color="rgba(46, 46, 46, 1)"
                type="text"
                name="ReferalTo"
                placeholder="Created by"
                value={referalName}
                onChange={(e) => setReferalName(e.target.value)}
              />
            </Div>
          </Div>
        </Div>
        <Div overflowY="auto" maxHeight="430px">
          <Table border="none">
            <TableHead
              background="#F1F5F8"
              hight="56px"
              style={{ position: "sticky", top: "-1px", zIndex: "1" }}
            >
              <TableRow
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                color="#2C7BE5"
                colSpan="5"
                paddingTop="8px"
                paddingBottom="8px"
              >
                <TableHeadCell textAlign="left" paddingLeft="43px" width="70%">
                  Plan Name
                </TableHeadCell>
                <TableHeadCell textAlign="center" paddingRight="10px">
                  Action
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.length !== 0 ? (
                rowsToShow.map((x, ind) => (
                  <React.Fragment key={ind}>
                    <TableRow
                      fontFamily="'Open Sans', sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      color="#000000"
                      lineHeight="19px"
                      borderBottom="1px solid #cbd5e0"
                      paddingTop="8px"
                      paddingBottom="8px"
                      key={ind}
                    >
                      <TableBodyCell
                        textAlign="left"
                        paddingRight="0px"
                        paddingLeft="41px"
                        wordBreak="break-word"
                        title={x.plan_name}
                      >
                        {truncateText(x.plan_name, 30)}
                      </TableBodyCell>
                      <TableBodyCell
                        textAlign="center"
                        paddingRight="2px"
                        paddingTop="5px !important"
                        paddingBottom="5px !important"
                        style={x.plan_name ? null : EmptyRowStyle}
                      >
                        {x.plan_name ? (
                          <>
                            <Button
                              borderRadius="4px"
                              border="1px solid #2C7BE5"
                              padding="5px 26px 4px 27px"
                              background="#2C7BE5"
                              color="#FFFFFF"
                              hoverBackColor="#2C7BE5"
                              marginLeft="auto"
                              cursor={
                                SingPatDetails?.enrollment_status === "4"
                                  ? "not-allowed"
                                  : "pointer"
                              }
                              onClick={() =>
                                SingPatDetails?.enrollment_status === "4"
                                  ? ""
                                  : AssignPlanToPatient(x.id)
                              }
                            >
                              <Span
                                fontSize="14px"
                                fontWeight="600"
                                fontFamily="'Open Sans', sans-serif"
                                color="#FFF"
                              >
                                Add
                              </Span>
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </TableBodyCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow height="307px">
                  <TableBodyCell textAlign="center" colSpan={5}>
                    <Div>
                      <Image
                        height="150px"
                        width="150px"
                        src={Emp_data_pic}
                      ></Image>
                    </Div>
                    <Div
                      fontSize="14px"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      lineHeight="20px"
                      fontWeight="400"
                    >
                      No TreatmenPlans to be shown,Please add some.
                    </Div>
                  </TableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Div>
      </Div>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};

export default AddTreatmentPlanDashTable;
