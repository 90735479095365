import React, { useState, useEffect } from "react";
import PatientDemographics from "./PatientDemographics";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import PatientDemoHead from "./PatientDemoHead";
import ReadingsData from "../ReadingsData";
import PatientDemographicsSheet from "./PatientDemographicsSheet";
import Vitals from "./Vitals";
import Devices from "./Devices";
import { useSelector } from "react-redux";

function PatientDemo() {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const [details, setDetails] = useState([]);
  const [disable, setdisable] = useState(false);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  //-------------------------------------------State and Variables End-------------------------------------------------------- //
  // ----------------------------------------- Functions Start--------------------------------------------------------- //

  const handleClick = () => {
    if (details.enrollment_status === "4") {
      setdisable(true);
    } else {
      setdisable(false);
    }
  };

  // ----------------------------------------- Functions End--------------------------------------------------------- //
  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    setDetails(SingPatDetails);
  }, [SingPatDetails]);
  useEffect(() => {
    handleClick();
  }, [details]);
  // ----------------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <Container backgroundColor="#F5F5F5">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px">
        <Div background="#F5F5F5" padding="20px">
          <Div
            padding="20px 20px 20px 15px"
            backgroundColor="#FFFFFF"
            borderRadius="8px"
          >
            <PatientDemoHead />
            <PatientDemographics />
          </Div>

          <Div
            onClick={handleClick}
            style={{
              cursor: disable ? "not-allowed" : "pointer",
            }}
          >
            <Div
              backgroundColor="#FFFFFF"
              marginTop="16px"
              borderRadius="8px"
              padding="0px 32px"
            >
              <Vitals />
            </Div>
            <Div
              background="#FFFFFF"
              padding="20px"
              marginTop="16px"
              borderRadius="8px"
            >
              <Devices />
            </Div>
            <Div padding="20px 0px 20px 0px">
              <ReadingsData />
            </Div>
            <Div background="#F5F5F5" padding="0px 0px 4px 0px">
              <Div
                width="100%"
                background="#ffffff"
                borderRadius="8px"
                backgroundColor="#FFFFFF"
              >
                <PatientDemographicsSheet />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Container>
  );
}

export default PatientDemo;
