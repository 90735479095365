import React from "react";
import { Div } from "../../../StyledComponents";
import TreatmentplanList from "./TreatmentplanList";
import AddTreatmentPlanDashTable from "./AddTreatmentPlanDashTable";
import AccessDenied from "../../../AccessDenied";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";

const TreatmenPlan = ({ pid }) => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <>
      <Div display="flex" gap="24px">
        {permissions.includes("treat_plan") ? (
          <>
            <Div width="58%">
              <TreatmentplanList pid={pid} />
            </Div>
            <Div width="41%">
              <AddTreatmentPlanDashTable pid={pid} />
            </Div>
          </>
        ) : (
          <AccessDenied width="400px" height="350px" />
        )}
      </Div>
    </>
  );
};

export default TreatmenPlan;
