import React, { useState, useEffect } from "react";
import {
  Image,
  Input,
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Div,
  FormLabel,
  Button,
  Row,
  RedStar,
  ErrorMessage,
  DropdownImage,
  SelectInput,
  DropDownDiv,
  DropdownOptions,
} from "../../StyledComponents/index";
import Avatar from "../../../Assets/images/Avatar.png";
import { AiOutlineClose } from "react-icons/ai";
import CustomDatePicker from "../../StyledComponents/datepicker/CustomDatePicker";
import moment from "moment";
import { BsCalendar } from "react-icons/bs";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { useSelector, useDispatch } from "react-redux";
import {
  GetSinglePatDetail,
  setEmptyPatUpdateResponse,
  GetPatEnrollment,
  GetMailVerify,
  GetPhoneVerify,
  GetSSNVerify,
} from "../../../StateManagement/Reducers/PatientState";
import {
  GetStateListDetail,
  GetLangListDetail,
  GetRaceListDetail,
  GetEthListDetail,
} from "../../../StateManagement/Reducers/DropDownList";
import { useParams } from "react-router-dom";
import UploadProfilePicture from "../../StyledComponents/UploadProfilePicture";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Wrapper } from "../styles";
import { validCountryCodes } from "../../Patient/PatientEnrollment/CountryCodes";
import DBHost from "../../../Config/DBHost";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const MyComponent = ({ onClose }) => {
  //-------------------------------------------useState and Variables Start--------------------------------------------------------//
  const [person, setPerson] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    ssnNumber: "",
    emailId: "",
    phoneNumber: "",
    bloodGroup: "",
    race: "",
    ethnicity: "",
    language: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    img_url: "",
    c_code: "",
  });

  const [validC_codeErr, setValidC_codeErr] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [ssnError, setSsnError] = useState(false);
  const [emailIDError, setEmailIDError] = useState(false);
  const [phnumError, setPhnumError] = useState(false);
  const [raceError, setRaceError] = useState(false);
  const [ethnicityError, setEthnicityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [showpicker, setShowPicker] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ShowOptions2, setShowOptions2] = useState(false);
  const [ShowOptions3, setShowOptions3] = useState(false);
  const [ShowOptions4, setShowOptions4] = useState(false);
  const [ShowOptions5, setShowOptions5] = useState(false);
  const [patDetails, setPatDetails] = useState([]);
  const [isupload, setIsUpload] = useState(false);
  const [validDate, setValidDate] = useState(moment(new Date(), "YYYY-MM-DD"));
  const [previewimg1, setpreviewimg1] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageText, setImageText] = useState("add file");
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [initialMail, setInitialMail] = useState(false);
  const [initialPhone, setInitialPhone] = useState(false);
  const [initialSSN, setInitialSSN] = useState(false);
  const [validMailErr, setValidMailErr] = useState(false);
  const [validPhoneErr, setValidPhoneErr] = useState(false);
  const [validSSNErr, setValidSSNErr] = useState(false);
  const [isValidationComplete, setValidationComplete] = useState(false);
  const [dis, setDis] = useState(false);

  const bloodGroupDetails = [
    { label: "A+", id: "1" },
    { label: "A-", id: "2" },
    { label: "B+", id: "3" },
    { label: "B-", id: "4" },
    { label: "AB+", id: "5" },
    { label: "AB-", id: "6" },
    { label: "O+", id: "7" },
    { label: "O-", id: "8" },
  ];

  const genderDetails = [
    { label: "Male", id: "1" },
    { label: "Female", id: "2" },
    { label: "Others", id: "3" },
  ];

  const ethdetails = [
    { label: "Hispanic or Latino", id: "1" },
    { label: "Not Hispanic or Latino", id: "2" },
    { label: "Declined to specify", id: "3" },
  ];
  const dispatch = useDispatch();

  const params = useParams();
  const patId = params.pid;
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const errorArray = [{ title: "Error", id: "1", disabled: true }];
  const racedetails =
    useSelector((state) => state.DropdownList.EthList) || errorArray;
  const langdetails =
    useSelector((state) => state.DropdownList.LangList) || errorArray;

  const updateResponse = useSelector(
    (state) => state.Patient.PatientUpdateResponse
  );
  const EmailVerify = useSelector((state) => state.Patient.MailVerify);
  const PhoneVerify = useSelector((state) => state.Patient.PhoneVerify);
  const ssnVerify = useSelector((state) => state.Patient.SSNVerify);

  let imageUrl = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const userID = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  //-------------------------------------------useState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//
  const handleChange = (e) => {
    if (e.target.name === "phoneNumber") {
      setInitialPhone(true);
      let a = /[0-9 ]/i.test(e.target.value);
      if ((!isNaN(e.target.value) && a) || e.target.value === "") {
        setPerson({ ...person, [e.target.name]: e.target.value });
        if (e.target.value === "") {
          setPhnumError(true);
          setValidPhoneErr(false);
        } else {
          setPhnumError(false);
        }
      }
      if (e.target.value.length == 0) {
        document.getElementById("ccode_inp").focus();
      }
    } else if (e.target.name === "c_code") {
      setValidC_codeErr(false);
      const countryCode = e.target.value.replace(/[()]/g, "");
      let a = /^[0-9+]+$/.test(countryCode);
      if (a && countryCode.length >= 1) {
        setPerson({ ...person, [e.target.name]: countryCode });
      }
      if (countryCode.length == 5) {
        document.getElementById("phone_inp").focus();
      }
    } else if (e.target.name === "firstName") {
      let a = /^[a-zA-Z]+$/.test(e.target.value);
      let capitalizedValue =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      if (a || e.target.value === "") {
        if (e.target.value === "" && e.target.name === "firstName") {
          setFirstNameError(true);
        } else {
          setFirstNameError(false);
        }
        setPerson({ ...person, [e.target.name]: capitalizedValue });
      }
    } else if (e.target.name === "lastName") {
      let a = /^[a-zA-Z]+$/.test(e.target.value);
      let capitalizedValue =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      if (a || e.target.value === "") {
        if (e.target.value === "" && e.target.name === "lastName") {
          setLastNameError(true);
        } else {
          setLastNameError(false);
        }
        setPerson({ ...person, [e.target.name]: capitalizedValue });
      }
    } else if (e.target.name === "middleName") {
      let a = /^[a-zA-Z]+$/.test(e.target.value);
      let capitalizedValue =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      if (a || e.target.value === "") {
        setPerson({ ...person, [e.target.name]: capitalizedValue });
      }
    } else if (e.target.name === "ssnNumber") {
      const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
      const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
      const formattedSSN = formatSSN(inputValue.slice(0, 9)); // Limit to 9 characters
      setInitialSSN(true);
      setPerson({ ...person, [e.target.name]: formattedSSN });
      if (ssnRegex.test(formattedSSN)) {
        setSsnError(false);
        dispatch(GetSSNVerify({ ssn: formattedSSN, val: "ssn", pid: patId }));
      } else {
        setSsnError(true);
      }
    } else if (e.target.name === "address") {
      setAddressError(false);
      setPerson({ ...person, [e.target.name]: e.target.value });
    } else if (e.target.name === "city") {
      let capitalizedValue =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setCityError(false);
      setPerson({ ...person, [e.target.name]: capitalizedValue });
    } else if (e.target.name === "state") {
      let capitalizedValue =
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
      setStateError(false);
      setPerson({ ...person, [e.target.name]: capitalizedValue });
    } else if (e.target.name === "zipcode") {
      setZipcodeError(false);
      setPerson({ ...person, [e.target.name]: e.target.value });
    } else if (e.target.id === "date") {
      setPerson({ ...person, dateOfBirth: e.target.value });
      if (e.target.value === "") {
        setDOBError(true);
      } else {
        setDOBError(false);
      }
      let d = moment(e.target.value, "YYYY-MM-DD", true).isValid();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      let today = `${year}-${month}-${day}`;
      if (new Date(e.target.value) > new Date(today) || !d) {
        setDOBError(true);
      } else {
        setDOBError(false);
        setValidDate(e.target.value);
      }
    } else {
      setPerson({ ...person, [e.target.name]: e.target.value });
    }

    const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (e.target.name === "emailId") {
      setInitialMail(true);
      if (mailRegex.test(e.target.value)) {
        setEmailIDError(false);
        dispatch(
          GetMailVerify({ email: e.target.value, val: "mail", pid: patId })
        );
      } else {
        setEmailIDError(true);
      }
    }
  };

  const formatSSN = (ssn) => {
    const cleanedSSN = ssn.replace(/\D/g, "");

    let formatted = cleanedSSN.slice(0, 3);

    if (cleanedSSN.length > 3) {
      formatted += "-" + cleanedSSN.slice(3, 5);
    }

    if (cleanedSSN.length > 5) {
      formatted += "-" + cleanedSSN.slice(5, 9);
    }

    return formatted;
  };

  const formatZip = (zip) => {
    const cleanedZip = zip.replace(/\D/g, "");

    let formatted = cleanedZip.slice(0, 5);

    if (cleanedZip.length > 5) {
      formatted += "-" + cleanedZip.slice(5, 9);
    }

    return formatted;
  };

  const handleSave = () => {
    let err = 0;
    if (person.zipcode === "") {
      setZipcodeError(true);
      err = 2;
    }
    if (person.address === "") {
      setAddressError(true);
      err = 2;
    }
    if (person.city === "") {
      setCityError(true);
      err = 2;
    }
    if (person.state === "") {
      setStateError(true);
      err = 2;
    }
    if (!validCountryCodes.includes(person.c_code)) {
      setValidC_codeErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }
    if (
      person.firstName.trim() === "" ||
      person.lastName.trim() === "" ||
      person.dateOfBirth === "" ||
      person.gender.trim() === "" ||
      person.ssnNumber.trim() === "" ||
      person.emailId.trim() === "" ||
      person.phoneNumber.trim() === "" ||
      person.race.trim() === "" ||
      person.ethnicity.trim() === "" ||
      person.address.trim() === "" ||
      person.city.trim() === "" ||
      person.state.trim() === "" ||
      person.zipcode.trim() === ""
    ) {
      return;
    } else {
      onButtonClick();
    }
  };

  const handlepicker = (e) => {
    setShowPicker(!showpicker);
    setShowOptions1(false);
    setShowOptions2(false);
    setShowOptions3(false);
    setShowOptions4(false);
    setShowOptions5(false);
  };

  const closePicker = () => {
    setShowPicker(false);
  };

  const dateSelect = (value, e) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      const formattedDate = moment(value).format("YYYY-MM-DD");
      setValidDate(new Date(value));
      setPerson((prevState) => ({
        ...prevState,
        dateOfBirth: formattedDate,
      }));
      if (dobError) {
        setDOBError(false);
      }
    } else {
      setValidDate("");
      setPerson((prevState) => ({
        ...prevState,
        dateOfBirth: "",
      }));
    }
    closePicker();
  };
  const handleClose = () => {
    onClose();
  };

  const handleContainerClick = (event) => {
    event.stopPropagation();
    setShowOptions1(false);
    setShowOptions2(false);
    setShowOptions3(false);
    setShowOptions4(false);
    setShowOptions5(false);
    if (
      event.target.id !== "CustomDatePicker" &&
      event.target.id !== "CustomDatePickerbuttons" &&
      event.target.id !== "date" &&
      event.target.id !== "date1" &&
      event.target.className !== "year"
    ) {
      closePicker();
    }
  };

  const setBloodGroupSelect = (r) => {
    const { label } = r;
    setPerson((prevState) => ({
      ...prevState,
      bloodGroup: label,
    }));
    setShowOptions1(false);
  };

  const setRaceSelect = (r) => {
    setPerson((prevState) => ({
      ...prevState,
      race: r,
    }));
    setShowOptions2(false);
  };

  const setEthnicitySelect = (r) => {
    const { label } = r;
    setPerson((prevState) => ({
      ...prevState,
      ethnicity: label,
    }));
    setShowOptions3(false);
  };

  const setLanguageSelect = (r) => {
    setPerson((prevState) => ({
      ...prevState,
      language: r,
    }));
    setShowOptions4(false);
  };

  const setGenderSelect = (r) => {
    const { label } = r;
    setPerson((prevState) => ({
      ...prevState,
      gender: label,
    }));
    setShowOptions5(false);
  };

  const onButtonClick = () => {
    if (
      !firstNameError &&
      !lastNameError &&
      !dobError &&
      !genderError &&
      !ssnError &&
      !emailIDError &&
      !phnumError &&
      !raceError &&
      !ethnicityError &&
      !addressError &&
      !cityError &&
      !stateError &&
      !zipcodeError &&
      !validMailErr &&
      !validPhoneErr &&
      !validSSNErr &&
      isValidationComplete
    ) {
      const dataArray = new FormData();
      setDis(true);
      let article = {
        id: patId,
        fname: person.firstName,
        mname: person.middleName,
        lname: person.lastName,
        DOB: person.dateOfBirth,
        sex: person.gender,
        ss: person.ssnNumber,
        email: person.emailId,
        phone_cell: person.c_code + "-" + person.phoneNumber,
        blood_group: person.bloodGroup,
        race: person.race,
        ethnicity: person.ethnicity,
        language: person.language,
        street: person.address,
        city: person.city,
        state: person.state,
        postal_code: person.zipcode,
        enrollment_status: "3",
        providerID: userID,
      };

      if (imageFile) {
        dataArray.append("imgfile", imageFile);
      }

      let data = {
        article,
      };

      if (imageFile && imageFile !== "" && imageFile !== null) {
        data.file = dataArray;
      }

      Promise.resolve()
        .then(() => {
          dispatch(GetPatEnrollment([data, imageFile, patId]));
        })
        .then(() => {
          setImageFile(null);
          setTimeout(() => {
            setDis(false);
          }, 1500);
        });
    }
  };
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;
    const key = event.keyCode || event.charCode;

    const inputValue = inp.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const caretPosition = inp.selectionStart;

    if (key !== 8 && key !== 46) {
      if (numericValue.length === 4 && caretPosition === 4) {
        inp.value = numericValue + "-";
      } else if (numericValue.length === 6 && caretPosition === 7) {
        inp.value =
          numericValue.slice(0, 4) + "-" + numericValue.slice(4, 6) + "-";
      } else if (numericValue.length > 6 && caretPosition === 10) {
        inp.value =
          numericValue.slice(0, 4) +
          "-" +
          numericValue.slice(4, 6) +
          "-" +
          numericValue.slice(6, 8);
      }
    }
  };
  // ------------------------------------------------- Functions end-----------------------------------------------------------//
  // ------------------------------------------------- useEffects starts-----------------------------------------------------------//
  useEffect(() => {
    dispatch(GetSinglePatDetail(patId));
  }, []);

  useEffect(() => {
    setPatDetails(SingPatDetails);
  }, [SingPatDetails]);

  useEffect(() => {
    dispatch(GetStateListDetail());
    dispatch(GetRaceListDetail());
    dispatch(GetEthListDetail());
    dispatch(GetLangListDetail());
  }, []);

  useEffect(() => {
    let phonenumSplit = patDetails.phone_cell
      ? patDetails.phone_cell.split("-")
      : "";

    setPerson({
      firstName: patDetails.fname || "",
      middleName: patDetails.mname || "",
      lastName: patDetails.lname || "",
      dateOfBirth: patDetails.DOB || "",
      gender: patDetails.sex || "",
      ssnNumber: patDetails.ss || "",
      emailId: patDetails.email || "",
      phoneNumber: phonenumSplit["1"] || "",
      bloodGroup: patDetails.blood_group || "",
      race: patDetails.race || "",
      ethnicity: patDetails.ethnicity || "",
      language: patDetails.language || "",
      address: patDetails.street || "",
      city: patDetails.city || "",
      state: patDetails.state || "",
      zipcode: patDetails.postal_code || "",
      img_url: patDetails.img_url || "",
      c_code: phonenumSplit["0"] || "",
    });
  }, [patDetails]);

  useEffect(() => {
    if (updateResponse !== "") {
      if (updateResponse === "1") {
        setModalMsg("Patient information has been updated!");
        setModalAlerShow(true);
        setStatus("success");
        setTimeout(() => {
          setModalAlerShow(false);
          setStatus("");
          setModalMsg("");
          handleClose();
        }, 1500);
      } else {
        setModalMsg("Patient information has not been updated!");
        setModalAlerShow(true);
        setStatus("fail");
        setTimeout(() => {
          setModalAlerShow(false);
          setStatus("");
          setModalMsg("");
        }, 3000);
      }
      dispatch(setEmptyPatUpdateResponse());
    }
  }, [updateResponse]);

  useEffect(() => {
    if (initialMail) {
      if (EmailVerify === "Email not found" || patId === EmailVerify.pid) {
        setValidMailErr(false);
      } else {
        setValidMailErr(true);
      }
    }
    if (person.emailId === "") {
      setValidMailErr(false);
    }
  }, [EmailVerify]);

  useEffect(() => {
    if (initialPhone) {
      if (
        PhoneVerify === "Phone Number not found" ||
        patId === PhoneVerify.pid
      ) {
        setValidPhoneErr(false);
      } else {
        setValidPhoneErr(true);
      }
    }
    if (person.phoneNumber === "") {
      setValidPhoneErr(false);
    }
  }, [PhoneVerify]);

  useEffect(() => {
    let isCancelled = false;

    if (person.phoneNumber !== "") {
      const handlechange = async () => {
        await timeout(500);
        if (!isCancelled) {
          dispatch(
            GetPhoneVerify({
              phone: person.c_code + "-" + person.phoneNumber,
              val: "phone",
              pid: patId,
            })
          );
          setValidationComplete(true);
        }
      };

      handlechange();
      return () => {
        isCancelled = true;
        setValidationComplete(false);
      };
    }
  }, [person.c_code, person.phoneNumber]);

  useEffect(() => {
    if (initialSSN) {
      if (ssnVerify === "SSN not found" || patId === ssnVerify.pid) {
        setValidSSNErr(false);
      } else {
        setValidSSNErr(true);
      }
    }
    if (person.ssnNumber === "") {
      setValidSSNErr(false);
    }
  }, [ssnVerify]);
  // ------------------------------------------------- useEffects ends-----------------------------------------------------------//

  return (
    <Modal
      show={true}
      justifyContent="center"
      width="max-width"
      height="max-height"
      onClick={handleClose}
      right="0px"
      bottom="0px"
    >
      <ModalContainer
        display="block"
        justifyContent="center"
        borderRadius="6.26866px"
        onClick={handleContainerClick}
        className="modalcontainer"
      >
        <ModalHeader borderLess={true}>
          <ModalTitle
            marginLeft="28.21px"
            marginTop="28.21px"
            fontFamily="Open Sans, sans-serif"
            fontWeight="600"
            fontSize="14.1px"
            lineHight="19.21px"
            color="rgba(46, 46, 46, 1)"
          >
            Patient Information
          </ModalTitle>
          <ModalTitle>
            <Button
              border="none"
              paddingLeft="6px"
              paddingTop="6px"
              marginLeft="429.79px"
              marginTop="32px"
              background="white"
              onClick={handleClose}
              cursor="pointer"
            >
              <AiOutlineClose />
            </Button>
          </ModalTitle>
        </ModalHeader>
        <ModalBody display="block">
          <Row>
            <Div display="flex">
              {(patDetails.img_url !== undefined &&
                patDetails.img_url !== null &&
                patDetails.img_url !== "") ||
              previewimg1 !== "" ? (
                <Image
                  borderRadius="50%"
                  height="65.3px"
                  width="65.3px"
                  marginTop="16.32px"
                  marginLeft="34.74px"
                  src={
                    previewimg1 === ""
                      ? imageUrl + patDetails.img_url
                      : previewimg1
                  }
                />
              ) : patDetails.img_url === null && previewimg1 === "" ? (
                <Div
                  background="rgb(168, 174, 190)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  fontFamily="Open Sans, sans-serif"
                  borderRadius="50%"
                  height="65.3px"
                  width="65.3px"
                  marginTop="16.32px"
                  marginLeft="34.74px"
                  fontSize="35px"
                  color="#FFFFFF"
                >
                  {patDetails.fname !== undefined &&
                    patDetails.fname.charAt(0).toUpperCase()}
                </Div>
              ) : (
                <Image
                  borderRadius="50%"
                  height="65.3px"
                  width="65.3px"
                  marginTop="16.32px"
                  marginLeft="34.74px"
                  src={Avatar}
                />
              )}
              <Button
                background="#FFF"
                border="1px solid"
                borderColor="#2C7BE5"
                borderRadius="3.13433px"
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                paddingLeft="10px"
                paddingTop="6.27px"
                paddingRight="10px"
                paddingBottom="6.27px"
                marginLeft="22.53px"
                marginTop="34.79px"
                height="28.21px"
                width="96.38px"
                color="#2C7BE5"
                onClick={() => {
                  setIsUpload(true);
                }}
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                Upload Photo
              </Button>
            </Div>
          </Row>
          <Row>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="25.16px"
              marginRight="18.81px"
              marginLeft="28.21px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                First Name<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="firstName"
                value={person.firstName}
                onChange={handleChange}
                placeholder=""
                width="181.79px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {firstNameError && (
                <ErrorMessage>Please enter your first name.</ErrorMessage>
              )}
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="25.16px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Middle Name
              </FormLabel>
              <Input
                type="text"
                name="middleName"
                value={person.middleName}
                onChange={handleChange}
                placeholder=""
                width="181.79px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
            </Div>
            <Div display="flex" flexDirection="column" marginTop="25.16px">
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Last Name<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="lastName"
                value={person.lastName}
                onChange={handleChange}
                placeholder=""
                width="181.79px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {lastNameError && (
                <ErrorMessage>Please enter your last name.</ErrorMessage>
              )}
            </Div>
          </Row>
          <Row>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="11.8px"
              marginLeft="28.21px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                marginBottom="6.16px"
                color="rgba(46, 46, 46, 1)"
              >
                Date of Birth<RedStar>*</RedStar>
              </FormLabel>
              <Div position="relative">
                <CustomDatePicker
                  padding="0 0 0 5px"
                  id="CustomDatePicker"
                  width="181.79px"
                  borderRadius="3.13433px"
                  height="28.21px"
                  backgroundColour="#f7f7f7 !important"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  placeholder="YYYY-MM-DD"
                  color="rgba(46, 46, 46, 1)"
                  value={person.dateOfBirth} //used in application
                  onChange={(e) => handleChange(e)}
                  onClick={(e) => handlepicker(e)}
                  onKeyPress={(event) => {
                    if (
                      !/[0-9]/.test(event.key) ||
                      event.target.value.length > 9
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(e) => keyDownExpiry(e)}
                  onResultSelect={dateSelect} // state updation for picking calendar dates
                  showPicker={showpicker}
                  validDate={validDate} // value in date object for calendar manipulation
                  closePicker={closePicker}
                  max={moment(new Date(), "DD/MM/YYYY")}
                  setSelectValidDate={setDOBError}
                  // min={aptdate}
                />
                {/* <BsCalendar className="calendarIcon" /> */}
                <BsCalendar
                  className="calendarIcon"
                  style={{
                    position: "absolute",
                    top: "-28px",
                    right: "21px",
                  }}
                />
                {dobError && (
                  <ErrorMessage style={{ zIndex: "9999", marginTop: "4px" }}>
                    Please enter a valid Date
                  </ErrorMessage>
                )}
              </Div>
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Gender<RedStar>*</RedStar>
              </FormLabel>
              <Div position="relative">
                <SelectInput
                  className="detailinput"
                  placeholder={person.gender}
                  width="181.79px"
                  borderRadius="3.13433px"
                  height="28.21px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="insured_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  margin="6.16px 0px 0px 0px"
                  padding="6px 12px 0px 5px"
                  color="rgba(46, 46, 46, 1)"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions5(!ShowOptions5);
                    setShowOptions1(false);
                    setShowOptions2(false);
                    setShowOptions4(false);
                    setShowOptions3(false);
                    setShowPicker(false);
                  }}
                >
                  {person.gender}
                </SelectInput>
                <DropdownImage
                  top="42px"
                  right="10px"
                  src={dropdownDownicon}
                ></DropdownImage>
                {ShowOptions5 && genderDetails.length > 0 ? (
                  <DropDownDiv
                    margin="0 0 0 0"
                    width="181.79px"
                    background="#FFFFFF"
                    border="0.783582px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    maxHeight="146px"
                    overflowY="auto"
                    overflowX="hidden"
                    // marginTop="64px"
                  >
                    {genderDetails.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.label}
                          padding="4px 12px 4px 12px"
                          onClick={() => setGenderSelect(r)}
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
              </Div>
              {genderError && (
                <ErrorMessage>Please enter your gender.</ErrorMessage>
              )}
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                SSN Number<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="ssnNumber"
                value={person.ssnNumber}
                onChange={handleChange}
                placeholder=""
                width="181.79px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
                id="ssnInput"
              />
              {ssnError ? (
                <ErrorMessage>Please enter a valid SSN.</ErrorMessage>
              ) : validSSNErr ? (
                <ErrorMessage>SSN Already Exists.</ErrorMessage>
              ) : null}
            </Div>
          </Row>
          <Row>
            <Div
              display="flex"
              flexDirection="column"
              marginLeft="28.21px"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Email ID<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="emailId"
                value={person.emailId}
                onChange={handleChange}
                placeholder=""
                width="181.79px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {emailIDError ? (
                <ErrorMessage>Please enter a valid email ID.</ErrorMessage>
              ) : validMailErr ? (
                <ErrorMessage>Email ID Already Exists.</ErrorMessage>
              ) : null}
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Phone Number<RedStar>*</RedStar>
              </FormLabel>
              <Div flexCenterAll>
                <Input
                  width={`${38 + (person.c_code.length - 2) * 8}px`}
                  id="ccode_inp"
                  btrr="0px"
                  bbrr="0px"
                  bblr="3.13433px"
                  btlr="3.13433px"
                  brw="0px"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  height="28.21px"
                  marginTop="6.16px"
                  paddingLeft="5px"
                  name="c_code"
                  value={`(${person.c_code})`}
                  onChange={handleChange}
                  maxLength={7}
                />
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phone_inp"
                  value={person.phoneNumber}
                  onChange={handleChange}
                  placeholder=""
                  width={`${143.79 - (person.c_code.length - 2) * 8}px`}
                  height="28.21px"
                  marginTop="6.16px"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  paddingLeft="5px"
                  color="rgba(46, 46, 46, 1)"
                  btrr="3.13433px"
                  bbrr="3.13433px"
                  bblr="0px"
                  btlr="0px"
                  blw="0px"
                />
              </Div>

              {phnumError ? (
                <ErrorMessage>Please enter your phone number.</ErrorMessage>
              ) : validC_codeErr ? (
                <ErrorMessage>Enter Valid Country Code</ErrorMessage>
              ) : (
                ""
              )}
              {validPhoneErr && (
                <ErrorMessage>Phone Number Already Exist</ErrorMessage>
              )}
            </Div>
            <Div display="flex" flexDirection="column" marginTop="11.8px">
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Blood Group
              </FormLabel>
              <Div position="relative">
                <SelectInput
                  className="detailinput"
                  placeholder={person.bloodGroup}
                  width="181.79px"
                  borderRadius="3.13433px"
                  height="28.21px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="insured_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  margin="6.16px 0px 0px 0px"
                  padding="6px 12px 0px 5px"
                  color="rgba(46, 46, 46, 1)"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions1(!ShowOptions1);
                    setShowOptions2(false);
                    setShowOptions3(false);
                    setShowOptions4(false);
                    setShowOptions5(false);
                    setShowPicker(false);
                  }}
                >
                  {person.bloodGroup ? person.bloodGroup : "Select Blood Group"}
                </SelectInput>
                <DropdownImage
                  top="42px"
                  right="10px"
                  src={dropdownDownicon}
                ></DropdownImage>
                {ShowOptions1 && bloodGroupDetails.length > 0 ? (
                  <DropDownDiv
                    margin="0 0 0 0px"
                    width="181.79px"
                    background="#FFFFFF"
                    border="0.783582px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    maxHeight="146px"
                    overflowY="auto"
                    overflowX="hidden"
                    // marginTop="64px"
                  >
                    {bloodGroupDetails.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.label}
                          padding="4px 12px 4px 12px"
                          onClick={() => setBloodGroupSelect(r)}
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
              </Div>
            </Div>
          </Row>
          <Row>
            <Div
              display="flex"
              flexDirection="column"
              marginLeft="28.21px"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Race<RedStar>*</RedStar>
              </FormLabel>
              <Div position="relative">
                <SelectInput
                  className="detailinput"
                  placeholder={person.race}
                  width="181.79px"
                  borderRadius="3.13433px"
                  height="28.21px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="insured_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  margin="6.16px 0px 0px 0px"
                  padding="6px 12px 0px 5px"
                  color="rgba(46, 46, 46, 1)"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions2(!ShowOptions2);
                    setShowOptions1(false);
                    setShowOptions3(false);
                    setShowOptions4(false);
                    setShowOptions5(false);
                    setShowPicker(false);
                  }}
                >
                  <Wrapper maxWidth="148px">{person.race}</Wrapper>
                </SelectInput>
                <DropdownImage
                  top="42px"
                  right="10px"
                  src={dropdownDownicon}
                ></DropdownImage>
                {ShowOptions2 && racedetails.length > 0 ? (
                  <DropDownDiv
                    margin="0 0 0 0"
                    width="181.79px"
                    background="#FFFFFF"
                    border="0.783582px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    maxHeight="146px"
                    overflowY="auto"
                    overflowX="hidden"
                    // marginTop="64px"
                  >
                    {racedetails.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.title}
                          padding="4px 12px 4px 12px"
                          onClick={() => setRaceSelect(r.title)}
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {r.title}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
              </Div>
              {raceError && (
                <ErrorMessage>Please enter your race.</ErrorMessage>
              )}
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Ethnicity<RedStar>*</RedStar>
              </FormLabel>
              <Div position="relative">
                <SelectInput
                  className="detailinput"
                  placeholder={person.ethnicity}
                  width="181.79px"
                  borderRadius="3.13433px"
                  height="28.21px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="insured_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  margin="6.16px 0px 0px 0px"
                  padding="6px 12px 0px 5px"
                  color="rgba(46, 46, 46, 1)"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions3(!ShowOptions3);
                    setShowOptions1(false);
                    setShowOptions2(false);
                    setShowOptions4(false);
                    setShowOptions5(false);
                    setShowPicker(false);
                  }}
                >
                  {person.ethnicity}
                </SelectInput>
                <DropdownImage
                  top="42px"
                  right="10px"
                  src={dropdownDownicon}
                ></DropdownImage>
                {ShowOptions3 && ethdetails.length > 0 ? (
                  <DropDownDiv
                    margin="0 0 0 0"
                    width="181.79px"
                    background="#FFFFFF"
                    border="0.783582px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    maxHeight="146px"
                    overflowY="auto"
                    overflowX="hidden"
                    // marginTop="64px"
                  >
                    {ethdetails.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.label}
                          padding="4px 12px 4px 12px"
                          onClick={() => setEthnicitySelect(r)}
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {r.label}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
              </Div>
              {ethnicityError && (
                <ErrorMessage>Please enter your ethnicity.</ErrorMessage>
              )}
            </Div>
            <Div display="flex" flexDirection="column" marginTop="11.8px">
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Language
              </FormLabel>
              <Div position="relative">
                <SelectInput
                  className="detailinput"
                  placeholder={person.language}
                  width="181.79px"
                  borderRadius="3.13433px"
                  height="28.21px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="insured_type"
                  fontFamily="'Open Sans',sans-serif"
                  border="0.783582px solid rgba(46, 46, 46, 0.25)"
                  margin="6.16px 0px 0px 0px"
                  padding="6px 12px 0px 5px"
                  color="rgba(46, 46, 46, 1)"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions4(!ShowOptions4);
                    setShowOptions2(false);
                    setShowOptions3(false);
                    setShowOptions1(false);
                    setShowOptions5(false);
                    setShowPicker(false);
                  }}
                >
                  <Wrapper maxWidth="152px">
                    {person.language ? person.language : "Select Language"}
                  </Wrapper>
                </SelectInput>
                <DropdownImage
                  top="42px"
                  right="10px"
                  src={dropdownDownicon}
                ></DropdownImage>
                {ShowOptions4 && langdetails.length > 0 ? (
                  <DropDownDiv
                    margin="0 0 0 0"
                    width="181.79px"
                    background="#FFFFFF"
                    border="0.783582px solid rgba(46, 46, 46, 0.25)"
                    borderRadius="3.13433px"
                    maxHeight="146px"
                    overflowY="auto"
                    overflowX="hidden"
                    // marginTop="64px"
                  >
                    {langdetails.map((r, i) => {
                      return (
                        <DropdownOptions
                          key={i}
                          value={r.title}
                          padding="4px 12px 4px 12px"
                          onClick={() => setLanguageSelect(r.title)}
                          fontSize="14px"
                          color="rgba(46, 46, 46, 1)"
                        >
                          {r.title}
                        </DropdownOptions>
                      );
                    })}
                  </DropDownDiv>
                ) : (
                  ""
                )}
              </Div>
            </Div>
          </Row>
          <Row>
            <Div
              display="flex"
              flexDirection="column"
              marginLeft="28.21px"
              marginTop="11.8px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Address<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="address"
                value={person.address}
                onChange={handleChange}
                placeholder=""
                width="401.19px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {addressError && (
                <ErrorMessage>Please enter your address.</ErrorMessage>
              )}
            </Div>
          </Row>
          <Row>
            <Div
              display="flex"
              flexDirection="column"
              marginLeft="28.21px"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                City<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="city"
                value={person.city}
                onChange={handleChange}
                placeholder=""
                width="156.72px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {cityError && (
                <ErrorMessage>Please enter your city.</ErrorMessage>
              )}
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              marginTop="11.8px"
              marginRight="18.81px"
            >
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                State<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="state"
                value={person.state}
                onChange={handleChange}
                placeholder=""
                width="156.72px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {stateError && (
                <ErrorMessage>Please enter your state.</ErrorMessage>
              )}
            </Div>
            <Div display="flex" flexDirection="column" marginTop="11.8px">
              <FormLabel
                fontFamily="Open Sans, sans-serif"
                fontWeight="600"
                fontSize="10.97px"
                lineHight="14.94px"
                color="rgba(46, 46, 46, 1)"
              >
                Zipcode<RedStar>*</RedStar>
              </FormLabel>
              <Input
                type="text"
                name="zipcode"
                value={person.zipcode}
                onChange={(e) => {
                  if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
                    handleChange(e);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key == " ") {
                    e.preventDefault();
                  }
                }}
                placeholder=""
                width="156.72px"
                borderRadius="3.13433px"
                height="28.21px"
                marginTop="6.16px"
                border="0.783582px solid rgba(46, 46, 46, 0.25)"
                paddingLeft="5px"
                color="rgba(46, 46, 46, 1)"
              />
              {zipcodeError && (
                <ErrorMessage>Please enter a valid ZIP code.</ErrorMessage>
              )}
            </Div>
          </Row>
          {/* Add more input fields for the remaining properties */}
        </ModalBody>
        <ModalFooter
          noBorderTop={true}
          textAlign="left"
          marginRight="27.62px"
          marginLeft="516px"
          marginTop="24.06px"
          marginBottom="30.79px"
        >
          <Button
            color="white"
            border="none"
            borderRadius="3.13433px"
            background="#2C7BE5"
            paddingBottom="6.94px"
            paddingLeft="36px"
            paddingTop="6.27px"
            paddingRight="36px"
            onClick={handleSave}
            disabled={dis}
            isDisabled={dis}
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
          >
            Save
          </Button>
        </ModalFooter>
        <UploadProfilePicture
          show={isupload}
          close={() => setIsUpload(false)}
          setImageText={setImageText}
          setpreviewimg1={setpreviewimg1}
          setImageFile={setImageFile}
        />
      </ModalContainer>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Modal>
  );
};

export default MyComponent;
