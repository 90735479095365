import React, { useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import Lock_icon from "../../../Assets/images/Lock_icon.png";
import { BsPlayCircle } from "react-icons/bs";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";

import Notes from "./Notes";
import {
  SpanTag,
  Div,
  Button,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Tablerow,
  Image,
} from "../../StyledComponents";
import Pagination from "../../StyledComponents/Pagination/Pagination";
import { useEffect } from "react";
import { PaginDiv } from "./styles";
import {
  GetVisitEncounterList,
  GetEncounterSummary,
  GetEncounterId,
  setIsLocked,
} from "../../../StateManagement/Reducers/EncounterState";
import { MdLockOpen, MdLockOutline } from "react-icons/md";
import { GetFeeDelete } from "../../../StateManagement/Reducers/PatientState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const VisitEncounter = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [visitDetails, setVisitDetails] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeState, setActiveState] = useState([
    {
      note: "",
      billing: "",
      changeNoteSummary: false,
      changeBillSummary: false,
    },
  ]);

  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  const patDetails = useSelector(
    (state) => state.Encounter.VisitEncountersList
  );

  const currentPosts = visitDetails.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(visitDetails.length / postsPerPage);

  const [currentEid, setCurrentEid] = useState("");
  const [encountDetails, setEncounterDetails] = useState([]);
  const [initialShow, setInitialShow] = useState(false);
  const TakeNote = useSelector((state) => state.Encounter.NotesTake);
  const Navigate = useNavigate();
  const patId = params.pid;
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start------------------------------------------------- //

  const handleCollapseShow = (index, details) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }

    setActiveState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        billing: "",
        note: "activeColor",
        changeNoteSummary: true,
        changeBillSummary: false,
      };
      return updatedState;
    });
  };

  const handleNoteClick = (index) => {
    setActiveState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        billing: "",
        note: "activeColor",
        changeNoteSummary: true,
        changeBillSummary: false,
      };
      return updatedState;
    });
  };

  const handleBillingClick = (index) => {
    setActiveState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        billing: "activeColor",
        note: "",
        changeNoteSummary: false,
        changeBillSummary: true,
      };
      return updatedState;
    });
  };

  const viewDetailsPage = () => {
    navigate("/Encounter/" + params.pid + "/ViewDetails/visit");
  };

  const handleClose = () => {
    setEncounterDetails([]);
    setShow(false);
  };

  const encounterDelete = (row) => {
    if (SingPatDetails.enrollment_status !== "4") {
      if (row.lockstatus === 1) {
        setShowAlert(true);
        setModalMsg("Encounter is locked");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
        return false;
      } else if (row.status === "closed") {
        return false;
      } else {
        const confirm = window.confirm("Are you sure you want to delete?");
        if (confirm === true) {
          Promise.resolve()
            .then(() => {
              dispatch(GetFeeDelete({ pid: params.pid, eid: row.encounter }));
            })
            .then(() => {
              setShowAlert(true);
              setModalMsg("Deleted Successfully");
              setStatus("success");
              setTimeout(() => {
                setShowAlert(false);
                setStatus("");
                setModalMsg("");
              }, 1500);
            });
        }
      }
    }
  };

  const handleNoteLock = (row) => {
    if (permissions.includes("enc_lock")) {
      if (SingPatDetails.enrollment_status !== "4") {
        const encounterID = row.encounter;
        if (row.lockstatus === 1) {
          const data = {
            encounter: encounterID,
            locked: 0,
            pid: params.pid,
          };

          dispatch(setIsLocked(data));
          setShowAlert(true);
          setModalMsg("unlocked Successfully");
          setStatus("success");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
        } else {
          const data = {
            encounter: encounterID,
            locked: 1,
            pid: params.pid,
          };

          dispatch(setIsLocked(data));
          setShowAlert(true);
          setModalMsg("locked Successfully");
          setStatus("success");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
        }
      }
    } else {
      denied_access();
    }
  };

  const handleNoteAdd = (details, eid) => {
    if (permissions.includes("notes_lock")) {
      setCurrentEid(eid);
      setInitialShow(true);
      setEncounterDetails(details);
    } else {
      denied_access();
    }
  };

  const denied_access = () => {
    setModalMsg("Permission Denied");
    setStatus("Failed");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setStatus("");
      setModalMsg("");
    }, 1500);
  };

  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //
  useEffect(() => {
    setExpandedRows([]);
  }, [currentPage]);

  useEffect(() => {
    setVisitDetails(patDetails);
  }, [patDetails]);

  useEffect(() => {
    dispatch(GetVisitEncounterList({ pid: params.pid }));
  }, [params.pid]);

  useEffect(() => {
    if (initialShow) {
      if (encountDetails.length != 0) setShow(true);
      else setShow(false);
    }
  }, [encountDetails]);

  useEffect(() => {
    if (TakeNote === "success") {
      dispatch(GetEncounterSummary(currentEid));
      dispatch(GetEncounterId(currentEid));
      setTimeout(() => {
        Navigate("/Encounter/" + params.pid, {
          state: {
            eid: currentEid,
          },
        });
      }, 2000);
    }
  }, [TakeNote]);
  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <>
      <Div display="flex">
        <Div
          margin="24px 0px 24px 29px"
          color="#2E2E2E"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="18px"
        >
          Visit History for {SingPatDetails.patname}
        </Div>
        <Button
          border="none"
          backgroundColor="#FFFFFF"
          marginLeft="auto"
          marginRight="20px"
          color="#2C7BE5"
          fontFamily="Open Sans, sans-serif"
          fontWeight="600"
          fontSize="14px"
          cursor="pointer"
          onClick={viewDetailsPage}
        >
          View Details
        </Button>
      </Div>

      <Div overflowX="auto">
        <Table border="none !important">
          <TableHead background="#F1F5F8" paddingLeft="0px">
            <Tablerow
              color="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              colSpan="5"
              textAlign="center"

              // className="tbody_padding"
            >
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Encounter</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Encounter ID</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Provider</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Duration</SpanTag>
              </TableHeadCell>
              <TableHeadCell paddingLeft="0px">
                <SpanTag>Action</SpanTag>
              </TableHeadCell>
            </Tablerow>
          </TableHead>
          <TableBody>
            {currentPosts.length !== 0 ? (
              currentPosts.map((row, index) => (
                <React.Fragment key={index}>
                  <Tablerow
                    color="#2E2E2E"
                    fontFamily="Open Sans, sans-serif"
                    fontWeight="400"
                    fontSize="14px"
                    bbtom="1px solid #E2E8ED"
                    borderBottom={
                      expandedRows.includes(index)
                        ? "none !important"
                        : "1px solid #E2E8ED"
                    }
                    colSpan="5"
                    textAlign="center"
                  >
                    <TableBodyCell
                      paddingLeft="38px"
                      paddingTop="19px"
                      paddingBottom="20px"
                      borderBottom="1px solid #E2E8ED"
                    >
                      {row.enc_date}
                    </TableBodyCell>
                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {row.encounter}
                    </TableBodyCell>
                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {row.provider}
                    </TableBodyCell>
                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      {row.enc_duration}
                    </TableBodyCell>

                    <TableBodyCell paddingTop="19px" paddingBottom="20px">
                      <SpanTag
                        gap="14px"
                        display="flex"
                        justifyContent="center"
                      >
                        <BsPlayCircle
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#2C7BE5",
                            rotate: expandedRows.includes(index)
                              ? "270deg"
                              : "90deg",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCollapseShow(index)}
                        />
                        <AiOutlineFileAdd
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#2E2E2E",
                            cursor:
                              row.status === "open" &&
                              SingPatDetails.enrollment_status !== "4"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={() =>
                            row.status === "open" &&
                            SingPatDetails.enrollment_status !== "4"
                              ? handleNoteAdd(row, row.encounter)
                              : ""
                          }
                        />
                        {row.lockstatus === 1 ? (
                          <MdLockOutline
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#2E2E2E",
                              cursor:
                                SingPatDetails.enrollment_status !== "4"
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            onClick={() => handleNoteLock(row)}
                          />
                        ) : (
                          <MdLockOpen
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#2E2E2E",
                              cursor:
                                SingPatDetails.enrollment_status !== "4"
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            onClick={() => handleNoteLock(row)}
                          />
                        )}
                        <FiTrash2
                          style={{
                            width: "20px",
                            height: "20px",

                            fill: "#ffffff",
                            stroke: "#f24822",
                            cursor:
                              row.lockstatus === 0 &&
                              row.status === "open" &&
                              SingPatDetails.enrollment_status !== "4"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={() => encounterDelete(row)}
                        />
                      </SpanTag>
                    </TableBodyCell>
                  </Tablerow>

                  {expandedRows.includes(index) ? (
                    <Tablerow>
                      <TableBodyCell
                        colSpan="6"
                        padding="0px 24px 14px 22px "
                        borderBottom={
                          expandedRows.includes(index) && "1px solid #E2E8ED"
                        }
                        textAlign="center"
                      >
                        <Table
                          borderTop="1px solid #E2E8ED"
                          borderRadius="8px"
                          className="getlast"
                        >
                          <TableHead>
                            <Tablerow
                              color="rgba(46, 46, 46, 0.5)"
                              fontFamily="Open Sans, sans-serif"
                              fontWeight="600"
                              fontSize="16px"
                              textAlign="center"
                              // className="tbody_padding"
                            >
                              <TableHeadCell
                                cursor="pointer"
                                width="141px"
                                onClick={() => handleNoteClick(index)}
                                className={activeState[index]?.note}
                              >
                                Note Summary
                              </TableHeadCell>
                              <TableHeadCell
                                cursor="pointer"
                                width="154px"
                                onClick={() => handleBillingClick(index)}
                                className={activeState[index]?.billing}
                              >
                                Billing Summary
                              </TableHeadCell>
                            </Tablerow>
                            <Tablerow
                              backgroundColor="#F1F5F8"
                              color="#2C7BE5"
                              fontFamily="Open Sans, sans-serif"
                              fontWeight="600"
                              fontSize="14px"
                              textAlign="center"
                              // className="tbody_padding"
                            >
                              <TableHeadCell paddingLeft="0px">
                                Date
                              </TableHeadCell>

                              <TableHeadCell paddingLeft="0px">
                                {activeState[index]?.changeBillSummary
                                  ? "Code"
                                  : "Provider"}
                              </TableHeadCell>
                              <TableHeadCell paddingLeft="0px">
                                {activeState[index]?.changeBillSummary
                                  ? "charge"
                                  : "Billing"}
                              </TableHeadCell>
                              {activeState[index]?.changeBillSummary ? (
                                <>
                                  <TableHeadCell paddingLeft="0px">
                                    Paid
                                  </TableHeadCell>
                                  <TableHeadCell paddingLeft="0px">
                                    Adjustment
                                  </TableHeadCell>
                                  <TableHeadCell paddingLeft="0px">
                                    Balance
                                  </TableHeadCell>
                                </>
                              ) : (
                                " "
                              )}
                              <TableHeadCell paddingLeft="0px">
                                Insurance
                              </TableHeadCell>
                            </Tablerow>
                          </TableHead>
                          {row.details.length !== 0 ? (
                            row.details.map((details) => (
                              <TableBody>
                                <Tablerow
                                  bbtom
                                  borderBottom="1px solid #E2E8ED"
                                  color="#2E2E2E"
                                  fontFamily="Open Sans, sans-serif"
                                  fontWeight="400"
                                  fontSize="14px"
                                  className="collapse_lastChild"
                                  textAlign="center"
                                >
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                  >
                                    {details.date1}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                  >
                                    {activeState[index]?.changeBillSummary
                                      ? `CPT4 - ${details.code}`
                                      : details.provider}
                                  </TableBodyCell>
                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                  >
                                    {activeState[index]?.changeBillSummary
                                      ? details.fee
                                      : `CPT4 - ${details.code}`}
                                  </TableBodyCell>
                                  {activeState[index]?.changeBillSummary ? (
                                    <>
                                      <TableBodyCell
                                        paddingTop="19px"
                                        paddingBottom="19px"
                                      >
                                        {details.paid ? details.paid : "0.00"}
                                      </TableBodyCell>
                                      <TableBodyCell
                                        paddingTop="19px"
                                        paddingBottom="19px"
                                      >
                                        {details.adjustment
                                          ? details.adjustment
                                          : "0.00"}
                                      </TableBodyCell>
                                      <TableBodyCell
                                        paddingTop="19px"
                                        paddingBottom="19px"
                                      >
                                        {details.balance
                                          ? details.balance
                                          : "0.00"}
                                      </TableBodyCell>
                                    </>
                                  ) : (
                                    " "
                                  )}

                                  <TableBodyCell
                                    paddingTop="19px"
                                    paddingBottom="19px"
                                  ></TableBodyCell>
                                </Tablerow>
                              </TableBody>
                            ))
                          ) : (
                            <TableBody>
                              <TableBodyCell textAlign="center" colSpan={6}>
                                <Div>
                                  <Image
                                    height="133px"
                                    width="133px"
                                    src={Emp_data_pic}
                                  ></Image>
                                </Div>
                              </TableBodyCell>
                            </TableBody>
                          )}
                        </Table>
                      </TableBodyCell>
                    </Tablerow>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))
            ) : (
              <Tablerow>
                <TableBodyCell textAlign="center" colSpan={5}>
                  <Div>
                    <Image
                      height="133px"
                      width="133px"
                      src={Emp_data_pic}
                    ></Image>
                  </Div>
                </TableBodyCell>
              </Tablerow>
            )}
          </TableBody>
        </Table>
      </Div>
      <PaginDiv
        hidePagination={currentPosts.length === 0 || howManyPages === 1}
      >
        {currentPosts.length === 0 || howManyPages === 1 ? (
          ""
        ) : (
          <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
        )}
      </PaginDiv>
      {show && (
        <>
          <Notes
            encountDetails={encountDetails}
            show={show}
            close={handleClose}
            clickFrom={"visitEncounter"}
          ></Notes>
        </>
      )}
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};
export default memo(VisitEncounter);
