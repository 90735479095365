import styled, { css } from "styled-components";

export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;

export const TableDiv = styled.div`
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow-x: ${(p) => p.overflowX && p.overflowX};

  &.responsive-right {
    @media screen and (max-width: 1071px) {
      position: absolute;
    }
  }

  &.TableLayout {
    overflow-x: auto;
    max-width: 100%;
  }

  &.TableLayout > table {
    table-layout: fixed;
    width: 100%;
  }
  &.TableLayout th,
  &.TableLayout td {
    white-space: normal;
    word-wrap: break-word;
    width: 30%;
  }

  @media (min-width: 250px) and (max-width: 1260px) {
    &.TableLayout > table {
      table-layout: auto;
    }
    &.TableLayout th,
    &.TableLayout td {
      word-wrap: break-word;
      overflow-wrap: break-word;
      /* overflow: hidden; */
      white-space: normal;
      text-overflow: clip;
    }
    &.TableLayout.deviceall td {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }
`;

export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: absolute;
  right: ${(p) => p.right && p.right};
  top: ${(p) => p.top && p.top};
`;
