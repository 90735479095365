import React, { useState, memo, useEffect } from "react";
import {
  Div,
  PageSubTitle,
  Span,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableRow,
  ButtonGroup,
  Image,
  SpinnerWrapper,
  Spinner,
} from "../../../StyledComponents";
import ViewStaff from "./ViewStaff";
import Emp_data_pic from "../../../../Assets/images/No_Data_Img.png";
import {
  GetProviderRolesList,
  SetEditRoleInsert,
  SetInsertRole,
} from "../../../../StateManagement/Reducers/SettingState";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import AddRoles from "../Roles/AddRoles";
import { PaginDiv } from "../../../Patient/PatientList/styles";
import Pagination from "../../../StyledComponents/Pagination/Pagination";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";
import Logo from "../../../../Assets/images/Logo.png";

const Staff = () => {
  //-----------------------------State and  Variables Start----------------------------------------------- //
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [aro_id, setaro_id] = useState(0);
  const [singleRow, setSingleRow] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [aro, setaro] = useState(0);
  const [id, setid] = useState(0);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const howManyPages = useSelector(
    (state) => state.Setting.noofpagesProvRoleList
  );
  const ProviderRolesList = useSelector(
    (state) => state.Setting.ProviderRolesList
  );
  const InsertRole = useSelector((state) => state.Setting.InsertRole);
  const EditRole = useSelector((state) => state.Setting.EditRoleInsert);
  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start------------------------------------------------- //
  const handleViewStaffopen = (row) => {
    setShow(true);
    setSingleRow(row);
    setRoleName(row.role_name);
    setid(row.user_id);

    setaro(row.aro_id);
  };

  const handleclose = () => {
    setShow(false);
  };
  const handleclose1 = () => {
    setShow1(false);
  };

  const handleopen = (item) => {
    setShow1(true);
    setaro_id(item.aro_id);
  };

  //-----------------------------Function End------------------------------------------------- //

  //-----------------------------useEffect start------------------------------------------------- //

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);
  useEffect(() => {
    let article =
      "pat_share=" +
      true +
      "&auth=0" +
      "&main_pro=" +
      "" +
      "&owner_id=" +
      null +
      "&userID=" +
      userID +
      "&authorized=" +
      0 +
      "&trial=" +
      true +
      "&account=" +
      3 +
      "&page=" +
      currentPage +
      "&pageSize=10";
    dispatch(GetProviderRolesList(article));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [currentPage]);

  useEffect(() => {
    let article =
      "pat_share=" +
      true +
      "&auth=0" +
      "&main_pro=" +
      "" +
      "&owner_id=" +
      null +
      "&userID=" +
      userID +
      "&authorized=" +
      0 +
      "&trial=" +
      true +
      "&account=" +
      3 +
      "&page=" +
      currentPage +
      "&pageSize=10";

    if (InsertRole === "success" || EditRole === "success") {
      dispatch(GetProviderRolesList(article));
      setModalAlerShow(true);
      setModalMsg("Role inserted Successfully");
      setStatus("success");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        dispatch(SetInsertRole(""));
        dispatch(SetEditRoleInsert(""));
        // setCurrentPage(1);
      }, 1500);
    }
  }, [InsertRole, EditRole]);

  //-----------------------------useEffect End------------------------------------------------- //

  return loading ? (
    <SpinnerWrapper>
      <Spinner></Spinner>
      <Image width="40px" height="40px" position="absolute" src={Logo} />
    </SpinnerWrapper>
  ) : (
    <>
      <Div
        background="#FFFFFF"
        margin="10px 26px 25px 26px"
        borderRadius="6px"
        boxShadow=" 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Div>
          <PageSubTitle
            padding="27px 0px 20px 24px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="16px"
            fontStyle="normal"
            lineHight="normal"
            color="#2E2E2E"
          >
            Staff Roles List
          </PageSubTitle>
        </Div>

        <Div>
          <Table>
            <TableHead background="#F1F5F8">
              <TableRow
                color="#2C7BE5"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                colSpan="3"
                paddingTop="18px"
                paddingBottom="19px"
                fontFamily="'Open Sans'sans-serif"
              >
                <TableHeadCell width="26%" paddingLeft="40px">
                  Staff
                </TableHeadCell>
                <TableHeadCell>Role</TableHeadCell>
                <TableHeadCell
                  textAlign="center"
                  paddingRight="29px"
                  paddingLeft="0px"
                >
                  Action
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {ProviderRolesList.length !== 0 ? (
                  ProviderRolesList.map((item, i) => (
                    <TableRow
                      key={i}
                      fontSize="14px"
                      lineHeight="20px"
                      fontFamily="'Open Sans'sans-serif"
                      borderBottom="1px solid #cbd5e0"
                      paddingTop="8px"
                      paddingBottom="10px"
                    >
                      <TableBodyCell
                        paddingLeft="37px"
                        paddingRight="0px"
                        fontWeight="600"
                        color="#2E2E2E"
                      >
                        {item.pro_name}
                      </TableBodyCell>
                      <TableBodyCell
                        paddingRight="0px"
                        fontWeight="400"
                        color="#2E2E2E"
                      >
                        {item.role_name}
                      </TableBodyCell>
                      <TableBodyCell
                        minWidth="12%"
                        textAlign="center"
                        paddingRight="0px"
                        paddingLeft="37px"
                      >
                        <ButtonGroup
                          borderRadius="4px"
                          border="1px solid #2C7BE5"
                          padding="9px 27px 8px 27px"
                          background="#FFFFFF"
                          color="#FFFFFF"
                          fontStyle="normal"
                          lineHight="normal"
                          width="80px"
                          cursor="pointer"
                          marginRight="24px"
                          onClick={() => handleViewStaffopen(item)}
                          hoverBackColor="rgba(244, 246, 249, 0.75)"
                          activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                        >
                          <Span
                            fontSize="14px"
                            fontWeight="600"
                            fontFamily="'Open Sans', sans-serif"
                            color="#2C7BE5"
                          >
                            Edit
                          </Span>
                        </ButtonGroup>

                        <ButtonGroup
                          type="submit"
                          borderRadius="4px"
                          border="1px solid #2C7BE5"
                          padding="9px 21px 8px 22px"
                          background="#2C7BE5"
                          color="#FFFFFF"
                          fontStyle="normal"
                          lineHight="normal"
                          cursor="pointer"
                          onClick={() => handleopen(item)}
                          hoverBackColor="#005FB2"
                          activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                        >
                          <Span
                            fontSize="14px"
                            fontWeight="600"
                            fontFamily="'Open Sans', sans-serif"
                          >
                            Customize role
                          </Span>
                        </ButtonGroup>
                      </TableBodyCell>
                    </TableRow>
                  ))
                ) : dataLoaded && ProviderRolesList.length === 0 ? (
                  <TableRow height="309px">
                    <TableBodyCell textAlign="center" colSpan={8}>
                      <Div>
                        <Image
                          height="150px"
                          width="150px"
                          src={Emp_data_pic}
                        ></Image>
                      </Div>
                      <Div
                        fontSize="14px"
                        color="#2E2E2E"
                        fontFamily="'Open Sans',sans-serif"
                        lineHeight="20px"
                        fontWeight="400"
                      >
                        No lab orders to be shown,Please add some.
                      </Div>
                    </TableBodyCell>
                  </TableRow>
                ) : (
                  <SpinnerWrapper>
                    <Spinner></Spinner>{" "}
                    <Image
                      width="40px"
                      height="40px"
                      position="absolute"
                      src={Logo}
                    />
                  </SpinnerWrapper>
                )}
              </>
            </TableBody>
          </Table>
          <PaginDiv
            hidePagination={
              ProviderRolesList?.length === 0 || howManyPages === 1
            }
          >
            {ProviderRolesList?.length === 0 || howManyPages === 1 ? (
              ""
            ) : (
              <Pagination
                current={currentPage}
                pages={howManyPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </PaginDiv>
        </Div>
      </Div>

      {show && (
        <ViewStaff
          show={show}
          close={handleclose}
          row={singleRow}
          roleName={roleName}
          id={id}
          aro_id={aro}
          userID={userID}
          currentPage={currentPage}
        />
      )}
      {/* <AddCustomizeprovider show={show1} close={handleclose1} /> */}
      <AddRoles
        show={show1}
        // group_id={roleGroup}
        acl_id={0}
        aro_enable={true}
        aro_id={aro_id}
        close={handleclose1}
      />
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default memo(Staff);
