import styled from "styled-components";
import { Span, StyledLink } from "../StyledComponents";
export const StyledIcon = styled(Span)`
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  margin-top: ${(p) => p.marginTop && p.marginTop};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  display: ${(p) => p.display && p.display};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;

export const NavIconText = styled.span`
  opacity: 0;
  position: absolute;
  background-position: center;
  font-weight: 600;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  line-height: 20px;
  font-family: "'Open Sans'", sans-serif;
  background-repeat: no-repeat;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "21px")};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "16px")};
  transform: translate(5px, -50%);
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  color: ${(p) => p.color && p.color};

  &svg {
    margin-left: 7px !important;
  }
`;

export const LeftNavContainer = styled.div`
  position: fixed;
  top: 0px;
  z-index: 1103;
  width: 300px;
  height: 100%;
  padding: 16px 26px 20px 6px;
  display: flex;
  transition: width 0.3s ease;
  width: 60px;
  background: #ffffff;
  border-right: 1px solid rgba(46, 46, 46, 0.15);

  &:hover {
    width: 200px;
    /* overflow: auto; */
  }

  &.hovered ul div ul li {
    margin-bottom: 10px !important;
  }

  &.unhovered ul div {
    display: none !important;
  }

  &:hover ${NavIconText} {
    opacity: 1;
    pointer-events: visible;
    cursor: pointer;
  }
`;

export const Container = styled.div``;

export const NavItemContainer = styled.div`
  position: relative;
`;

export const StyledLinkWithHover = styled(StyledLink)`
  /* &:hover ${NavIconText} {
    opacity: 1;
  } */
`;
