import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Div,
  Image,
  Li,
  Nav,
  Navicon,
  StyledLink,
  Ul,
} from "../StyledComponents";
import {
  LeftNavContainer,
  NavIconText,
  NavItemContainer,
  StyledIcon,
  StyledLinkWithHover,
  Container,
} from "./style";
import { RiDashboardFill, RiHeartPulseLine } from "react-icons/ri";
import { BsFillPeopleFill, BsShieldPlus } from "react-icons/bs";
import { MdLiveTv, MdSettings } from "react-icons/md";
import { BiWallet } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import DBHost from "../../Config/DBHost";
import Logo from "../../../src/Assets/images/Logo.png";

const LeftNav = () => {
  // ----------------------------State and Variables ---------------------------------------------------///

  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pid = params.pid;

  const [accordionStates, setAccordionStates] = useState([]);
  const [angle, setAngle] = useState(false);
  const [settingDrop, setSettingDrop] = useState();
  const [notHovered, setNotHovered] = useState(true);
  const toggleAccordion = (index) => {
    setAccordionStates((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };
  const isLinkEnabled =
    SingPatDetails.enrollment_status !== undefined &&
    SingPatDetails.enrollment_status !== "0" &&
    SingPatDetails.enrollment_status !== "1" &&
    SingPatDetails.enrollment_status !== "2";
  const isLinkEnabled1 =
    SingPatDetails.enrollment_status !== "3" &&
    SingPatDetails.enrollment_status !== "4";
  // ----------------------------State and Variables ---------------------------------------------------///

  //-------------------------Functions Start-------------------------------------------------------------//
  const generateStyle = () => {
    return {
      cursor: isLinkEnabled ? "pointer" : "not-allowed",
    };
  };

  const generateStyle1 = () => {
    return {
      cursor: isLinkEnabled1 ? "pointer" : "not-allowed",
    };
  };
  const handleSettingsToggle = (event) => {
    event.preventDefault();
    setSettingDrop(!settingDrop);
  };
  const isActive = () => {
    const activeUrls = [
      `/Encounter/${pid}`,
      `/Encounter/Encounterfrom/${pid}`,
      `/Encounter/VisitEncounter/${pid}`,
    ];
    const currentUrl = location.pathname;
    return activeUrls.includes(currentUrl);
  };
  const isActive2 = () => {
    const activeUrls = ["/addtreatment"];
    const currentUrl = location.pathname;
    return activeUrls.includes(currentUrl);
  };
  // -------------------------Functions End-------------------------------------------------------------//
  // -----------------------useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    // Initialize the accordion states
    const initialStates = new Array(1).fill(false);
    setAccordionStates(initialStates);
  }, []);

  useEffect(() => {
    if (
      location.pathname === `/PatientDemo/${pid}` ||
      location.pathname === `/BillandInsurance/${pid}` ||
      location.pathname === `/PatientEnrollment/${pid}` ||
      location.pathname === `/Encounter/${pid}` ||
      location.pathname === `/FeeSheet/${pid}` ||
      location.pathname === `/PatientEligibility/${pid}` ||
      location.pathname === `/PatientConsent/${pid}` ||
      location.pathname === `/PatientPortalSetup/${pid}` ||
      location.pathname === `/Encounter/Encounterfrom/${pid}` ||
      location.pathname === `/Encounter/VisitEncounter/${pid}`
    ) {
      setAccordionStates((prevState) => {
        const updatedStates = [...prevState];
        updatedStates[0] = true;
        setAngle(true);
        return updatedStates;
      });
    }

    if (
      location.pathname === `/user` ||
      location.pathname === `/rolesandPermission` ||
      location.pathname === `/services` ||
      location.pathname === `/documents` ||
      location.pathname === `/security` ||
      location.pathname === "/myprofile"
    ) {
      setSettingDrop(true);
    }
  }, [location]);
  // -------------------------useEffect End-------------------------------------------------------------//

  return (
    <LeftNavContainer
      onMouseEnter={() => setNotHovered(false)}
      onMouseLeave={() => setNotHovered(true)}
      className={notHovered ? "unhovered" : "hovered"}
    >
      <Nav>
        <Ul>
          <Li>
            <StyledLinkWithHover to="/dashboard" exact="true" height="40px">
              <StyledIcon height="18px" width="18px">
                <Image
                  cursor="pointer"
                  loading="lazy"
                  src={Logo}
                  onClick={() => navigate("/dashboard")}
                  height="40px"
                  width="58px"
                  objectFit="contain"
                  marginRight="20px"
                  marginLeft="-17px"
                  textAlign="center"
                />
              </StyledIcon>
              <NavIconText
                marginLeft="30px"
                fontSize="20px"
                style={{ color: "#000" }}
              >
                VOZO
              </NavIconText>
            </StyledLinkWithHover>
          </Li>

          <Li>
            <StyledLinkWithHover to="/dashboard" exact="true" height="40px">
              <StyledIcon height="18px" width="18px">
                <RiDashboardFill />
              </StyledIcon>
              <NavIconText>Dashboard</NavIconText>
            </StyledLinkWithHover>
          </Li>
          <Li>
            <StyledLinkWithHover to="/patient" exact="true" height="40px">
              <StyledIcon
                height="15px"
                width="20px"
                onClick={() => toggleAccordion(0)}
              >
                <BsFillPeopleFill />
              </StyledIcon>
              <NavIconText onClick={() => toggleAccordion(0)}>
                Patient
                <Div marginLeft="6px" marginTop="2px">
                  {angle ? (
                    accordionStates[0] ? (
                      <FaAngleUp />
                    ) : (
                      <FaAngleDown />
                    )
                  ) : null}
                </Div>
              </NavIconText>
            </StyledLinkWithHover>
            {angle && (
              <Container className={notHovered ? "hideonHover" : ""}>
                <Ul>
                  <Li fontSize="14px" fontWeight="600" marginBottom="20px">
                    <StyledLinkWithHover
                      to={`/PatientEnrollment/${pid}`}
                      exact
                      style={generateStyle1()}
                      onClick={(event) => {
                        isLinkEnabled1
                          ? event.stopPropagation()
                          : event.preventDefault();
                      }}
                    >
                      <NavIconText marginLeft="0px" style={generateStyle1()}>
                        Patient Enrollment
                      </NavIconText>
                    </StyledLinkWithHover>
                  </Li>
                  <Li fontSize="14px" fontWeight="600" marginBottom="20px">
                    <StyledLinkWithHover
                      to={`/PatientDemo/${pid}`}
                      exact
                      style={generateStyle()}
                      onClick={(event) => {
                        isLinkEnabled
                          ? event.stopPropagation()
                          : event.preventDefault();
                      }}
                    >
                      <NavIconText marginLeft="0px" style={generateStyle()}>
                        Patient Demographics
                      </NavIconText>
                    </StyledLinkWithHover>
                  </Li>
                  <Li fontSize="14px" fontWeight="600" marginBottom="20px">
                    <StyledLinkWithHover
                      to={`/BillandInsurance/${pid}`}
                      exact
                      style={generateStyle()}
                      onClick={(event) => {
                        isLinkEnabled
                          ? event.stopPropagation()
                          : event.preventDefault();
                      }}
                    >
                      <NavIconText marginLeft="0px" style={generateStyle()}>
                        Bill & Insurance
                      </NavIconText>
                    </StyledLinkWithHover>
                  </Li>
                  <Li fontSize="14px" fontWeight="600" marginBottom="20px">
                    <StyledLinkWithHover
                      to={`/Encounter/${pid}`}
                      className={isActive() ? "active" : ""}
                      exact
                      style={generateStyle()}
                      onClick={(event) => {
                        isLinkEnabled
                          ? event.stopPropagation()
                          : event.preventDefault();
                      }}
                    >
                      <NavIconText marginLeft="0px" style={generateStyle()}>
                        Encounter
                      </NavIconText>
                    </StyledLinkWithHover>
                  </Li>
                  <Li fontSize="14px" fontWeight="600" marginBottom="20px">
                    <StyledLinkWithHover
                      to={`/FeeSheet/${pid}`}
                      exact
                      style={generateStyle()}
                      onClick={(event) => {
                        isLinkEnabled
                          ? event.stopPropagation()
                          : event.preventDefault();
                      }}
                    >
                      <NavIconText marginLeft="0px" style={generateStyle()}>
                        Fee Sheet
                      </NavIconText>
                    </StyledLinkWithHover>
                  </Li>
                </Ul>
              </Container>
            )}
          </Li>
          <Li>
            <StyledLinkWithHover to="/Live" exact="true" height="40px">
              <StyledIcon height="20px" width="22px">
                <MdLiveTv />
              </StyledIcon>
              <NavIconText> Live</NavIconText>
            </StyledLinkWithHover>
          </Li>
          <Li>
            <StyledLinkWithHover to="/Devices" exact="true" height="40px">
              <StyledIcon height="24px" width="24px" marginLeft="-1px">
                <RiHeartPulseLine />
              </StyledIcon>
              <NavIconText>Devices</NavIconText>
            </StyledLinkWithHover>
          </Li>
          <Li>
            <StyledLinkWithHover
              to="/TreatmentPlan"
              className={isActive2() ? "active" : ""}
              exact="true"
              height="40px"
            >
              <StyledIcon height="20px" width="19px">
                <BsShieldPlus />
              </StyledIcon>

              <NavIconText marginLeft="18px">Treatment Plan</NavIconText>
            </StyledLinkWithHover>
          </Li>
          <Li>
            <StyledLinkWithHover to="/Billing" exact="true" height="40px">
              <StyledIcon height="21px" width="24px" marginLeft="-2px">
                <BiWallet />
              </StyledIcon>

              <NavIconText>Billing</NavIconText>
            </StyledLinkWithHover>
          </Li>
          <Li>
            <StyledLinkWithHover
              to={`/settingsnav`}
              exact="true"
              height="40px"
              onClick={handleSettingsToggle}
            >
              <StyledIcon height="20px" width="20px">
                <MdSettings />
              </StyledIcon>

              <NavIconText>
                Settings
                <Div marginLeft="6px" marginTop="5px">
                  {settingDrop ? <FaAngleUp /> : <FaAngleDown />}
                </Div>
              </NavIconText>
            </StyledLinkWithHover>
          </Li>
        </Ul>

        {settingDrop && (
          <Div paddingLeft="15px">
            <Ul>
              <Li fontSize="14px" fontWeight="600" marginBottom="">
                <StyledLinkWithHover to="/myprofile" exact="true">
                  <NavIconText marginLeft="0px">My Profile</NavIconText>
                </StyledLinkWithHover>
              </Li>
              <Li fontSize="14px" fontWeight="600" marginBottom="">
                <StyledLinkWithHover to={`/user`} exact>
                  <NavIconText marginLeft="0px">User</NavIconText>
                </StyledLinkWithHover>
              </Li>
              <Li fontSize="14px" fontWeight="600" marginBottom="">
                <StyledLinkWithHover to={`/rolesandPermission`} exact>
                  <NavIconText marginLeft="0px">Roles & Permission</NavIconText>
                </StyledLinkWithHover>
              </Li>
              <Li fontSize="14px" fontWeight="600" marginBottom="">
                <StyledLinkWithHover to={`/services`} exact>
                  <NavIconText marginLeft="0px"> Services </NavIconText>
                </StyledLinkWithHover>
              </Li>
              <Li fontSize="14px" fontWeight="600" marginBottom="">
                <StyledLinkWithHover to={`/documents`} exact>
                  <NavIconText marginLeft="0px"> Documents </NavIconText>
                </StyledLinkWithHover>
              </Li>
              <Li fontSize="14px" fontWeight="600" marginBottom="">
                <StyledLinkWithHover to={`/security`} exact>
                  <NavIconText marginLeft="0px"> Security </NavIconText>
                </StyledLinkWithHover>
              </Li>
            </Ul>
          </Div>
        )}
      </Nav>
    </LeftNavContainer>
  );
};

export default LeftNav;
