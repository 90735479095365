import React from "react";
import { Container, Div } from "../StyledComponents";
import LeftNav from "../LeftNav";
import TopNav from "../TopNav";
import PatientList from "./PatientList";

const Patient = () => {
  return (
    <>
      <PatientList />
    </>
  );
};
export default Patient;
