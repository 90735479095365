import React, { useRef, useState } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Modclosebtn,
  ButtonGroup,
  Span,
  ModalHeader,
  ModalFooter,
  PageSubTitle,
  Text,
  Div,
  Label,
  Input,
  TextArea,
  Select,
  DropdownOptions,
  DropDownDiv,
  SelectInput,
} from "../../StyledComponents";
import { DropDown, DropdownImage, ModalBodyDoc } from "../styles";
import { FaTimes } from "react-icons/fa";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import {
  GetUpdateSetDocRes,
  GetDocumentMailRes,
  EmptyUpdateSetDocRes,
} from "../../../StateManagement/Reducers/SettingState";
import arrowIcon from "../../../Assets/images/dropdownDownicon.png";
const EditEmailContent = (props) => {
  // ------------------------------------------------- State and Variables Start-----------------------------------------------------------//

  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  const EmailDocument = useSelector((state) => state.Setting.DocumentMailResp);
  const UpdateDocRes = useSelector((state) => state.Setting.UpdateSetDocResp);
  const profileDetailData = useSelector((state) => state.Setting.profileDetail);

  const [invEmailSub, setInvEmailSub] = useState("");
  const [invEmailMsg, setInvEmailMsg] = useState("");
  const [stmtEmailSub, setStmtEmailSub] = useState("");
  const [stmtEmailMsg, setStmtEmailMsg] = useState("");
  const [supEmailSub, setSupEmailSub] = useState("");
  const [supEmailMsg, setSupEmailMsg] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const [dis, setDis] = useState(false);
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [dropDown3, setDropDown3] = useState(false);
  const [dropDown4, setDropDown4] = useState(false);
  const [status1, setstatus1] = useState("");
  const [status2, setstatus2] = useState("");
  const [status3, setstatus3] = useState("");
  const [status4, setstatus4] = useState("");
  const clinicianRef = useRef(null);
  const PracticeRef = useRef(null);
  const clientRef = useRef(null);
  const linkRef = useRef(null);

  const ClinicianValue = [
    { title: "Clinician Full Name", value: "{Clinician Full Name}" },
    { title: "Clinician First Name", value: "{Clinician First Name}" },
    { title: "Clinician Last Name", value: "{Clinician Last Name}" },
    { title: "Clinician Email", value: "{Clinician Email}" },
  ];

  const PracticeValue = [
    { title: "Practice Name", value: "{Practice Name}" },
    { title: "Practice Address", value: "{Practice Address}" },
    { title: "Practice Phone Number", value: "{Practice Phone Number}" },
    { title: "Practice City", value: "{Practice City}" },
    { title: "Practice Zipcode", value: "{Practice Zipcode}" },
  ];

  const ClientValue = [
    { title: "Client Full Name", value: "{Client Full Name}" },
    { title: "Client First Name", value: "{Client First Name}" },
    { title: "Client Last Name", value: "{Client Last Name}" },
    { title: "Client Mobile Number", value: "{Client Mobile Number}" },
    { title: "Client Email Address", value: "{Client Email Address}" },
    { title: "Client Address", value: "{Client Address}" },
    { title: "Client City", value: "{Client City}" },
    { title: "Client Zipcode", value: "{Client Zipcode}" },
    { title: "Client BirthDate", value: "{Client BirthDate}" },
    { title: "Client Gender", value: "{Client Gender}" },
  ];

  const LinkValue = [
    { title: "Client Portal Login Link", value: "{Client Portal Login Link}" },
  ];
  // ------------------------------------------------- State and Variables End-----------------------------------------------------------//

  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const Toggling1 = () => setDropDown1((prevState) => !prevState);
  const Toggling2 = () => setDropDown2((prevState) => !prevState);
  const Toggling3 = () => setDropDown3((prevState) => !prevState);
  const Toggling4 = () => setDropDown4((prevState) => !prevState);

  const handleClick = (e) => {
    if (clinicianRef.current && !clinicianRef.current.contains(e.target)) {
      setDropDown1(false);
    }
  };
  const handleClick1 = (e) => {
    if (PracticeRef.current && !PracticeRef.current.contains(e.target)) {
      setDropDown2(false);
    }
  };
  const handleClick2 = (e) => {
    if (clientRef.current && !clientRef.current.contains(e.target)) {
      setDropDown3(false);
    }
  };
  const handleClick3 = (e) => {
    if (linkRef.current && !linkRef.current.contains(e.target)) {
      setDropDown4(false);
    }
  };

  const handleClose = () => {
    props.close();
  };
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const handleSubjectChange = (e) => {
    if (props.editType === "Invoice") {
      setInvEmailSub(e.target.value);
    } else if (props.editType === "Statement") {
      setStmtEmailSub(e.target.value);
    } else {
      setSupEmailSub(e.target.value);
    }
  };

  const handleMsgChange = (e) => {
    if (props.editType === "Invoice") {
      setInvEmailMsg(e.target.value);
    } else if (props.editType === "Statement") {
      setStmtEmailMsg(e.target.value);
    } else {
      setSupEmailMsg(e.target.value);
    }
  };

  const handleSave = () => {
    setDis(true);
    if (props.editType === "Invoice")
      dispatch(
        GetUpdateSetDocRes([
          { glname: "invoice_from", glindex: "0", glvalue: "" },
          { glname: "invoice_subject", glindex: "0", glvalue: invEmailSub },
          { glname: "invoice_message", glindex: "0", glvalue: invEmailMsg },
        ])
      );
    else if (props.editType === "Statement")
      dispatch(
        GetUpdateSetDocRes([
          { glname: "statement_from", glindex: "0", glvalue: "" },
          {
            glname: "statement_subject",
            glindex: "0",
            glvalue: stmtEmailSub,
          },
          {
            glname: "statement_message",
            glindex: "0",
            glvalue: stmtEmailMsg,
          },
        ])
      );
    else
      dispatch(
        GetUpdateSetDocRes([
          { glname: "superbills_from", glindex: "0", glvalue: "" },
          {
            glname: "superbills_subject",
            glindex: "0",
            glvalue: supEmailSub,
          },
          {
            glname: "superbills_message",
            glindex: "0",
            glvalue: supEmailMsg,
          },
        ])
      );
    setTimeout(() => {
      setDis(false);
    }, 1500);
  };

  const OnSelectChange = (e) => {
    // console.log(e.target.value);
    if (e !== "") {
      var texttxtarea = document.getElementById("mailmessage");
      var CurrentPoint = texttxtarea.selectionStart;
      var EndPoint = texttxtarea.selectionEnd;
      var difference = texttxtarea.value.substring(CurrentPoint, EndPoint);
      var SetValue =
        texttxtarea.value.substring(0, CurrentPoint) +
        e +
        difference +
        texttxtarea.value.substring(EndPoint);
      texttxtarea.value = SetValue;
      if (props.editType === "Invoice") setInvEmailMsg(SetValue);
      else if (props.editType === "Statement") setStmtEmailMsg(SetValue);
      else setSupEmailMsg(SetValue);
      texttxtarea.focus();
      texttxtarea.selectionEnd = EndPoint + e.length;
    }
  };

  const setclinicselect = (r) => {
    OnSelectChange(r.value);
    setDropDown1(false);
  };

  const setpracticeselect = (r) => {
    OnSelectChange(r.value);
    setDropDown2(false);
  };
  const setclientselect = (r) => {
    OnSelectChange(r.value);
    setDropDown3(false);
  };

  const setlinksselect = (r) => {
    OnSelectChange(r.value);
    setDropDown4(false);
  };

  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  // ------------------------------------------------- UseEffect Start-----------------------------------------------------------//

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  useEffect(() => {
    setInvEmailSub(
      EmailDocument.filter((ele, i) => ele.gl_name === "invoice_subject").map(
        (ele) => ele.gl_value
      )[0]
    );
    setInvEmailMsg(
      EmailDocument.filter((ele, i) => ele.gl_name === "invoice_message").map(
        (ele) => ele.gl_value
      )[0]
    );

    setStmtEmailSub(
      EmailDocument.filter((ele, i) => ele.gl_name === "statement_subject").map(
        (ele) => ele.gl_value
      )[0]
    );
    setStmtEmailMsg(
      EmailDocument.filter((ele, i) => ele.gl_name === "statement_message").map(
        (ele) => ele.gl_value
      )[0]
    );
    setSupEmailSub(
      EmailDocument.filter(
        (ele, i) => ele.gl_name === "superbills_subject"
      ).map((ele) => ele.gl_value)[0]
    );
    setSupEmailMsg(
      EmailDocument.filter(
        (ele, i) => ele.gl_name === "superbills_message"
      ).map((ele) => ele.gl_value)[0]
    );
  }, []);

  useEffect(() => {
    if (UpdateDocRes === "success") {
      dispatch(GetDocumentMailRes());
      setShowAlert(true);
      setModalMsg("Your Information has been Saved");
      setStatus("success");
      setTimeout(() => {
        props.close();
        setShowAlert(false);
        setModalMsg("");
        setStatus("");
        dispatch(EmptyUpdateSetDocRes());
      }, 2000);
    }
  }, [UpdateDocRes]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClick);
    document.addEventListener("mouseup", handleClick1);
    document.addEventListener("mouseup", handleClick2);
    document.addEventListener("mouseup", handleClick3);

    return () => {
      document.removeEventListener("mouseup", handleClick);
      document.removeEventListener("mouseup", handleClick1);
      document.removeEventListener("mouseup", handleClick2);
      document.removeEventListener("mouseup", handleClick3);
    };
  }, []);

  // ------------------------------------------------- UseEffect End-----------------------------------------------------------//

  return (
    <>
      <Modal
        show={props.show}
        onClick={props.close}
        justifyContent="center"
        overflowY="auto"
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainer
          position="relative"
          height="auto"
          justifyContent="center"
          borderRadius="8px"
          overflowX="revert"
          overflowY="revert"
          onClick={handleContainerClick}
        >
          <ModalDialog width="unset">
            <ModalHeader padding="11px 20px 14px 19px">
              <ModalTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600px"
                fontSize="16px"
                lineHight="22px"
                color="#2E2E2E"
                fontStyle="SemiBold"
              >
                Edit {props.editType} Email Message Content
              </ModalTitle>
              <Modclosebtn onClick={handleClose}>
                <FaTimes cursor="pointer" />
              </Modclosebtn>
            </ModalHeader>

            <ModalBodyDoc
              padding="15px 20px 16px 20px"
              height="400px"
              overflow="auto"
            >
              <PageSubTitle>Default Invoice Email</PageSubTitle>
              <Div display="flex" margin="10px 0 0 0" fontSize="14px">
                <Text color="#2d3748" width="62px">
                  From
                </Text>
                <Text color="#2c7be5">{profileDetailData?.email}</Text>
              </Div>
              <Div marginTop="10px" fontSize="14px" fontWeight="600">
                <Label display="block">Macros</Label>
                {/* <Select
                  height="32px"
                  border="1px solid #ced4da"
                  borderRadius="4px"
                  width="85px"
                  margin="10px 10px 0 0"
                  onChange={OnSelectChange}
                  value=""
                >
                  <option value=""> Clinician</option>
                  {ClinicianValue.map((ele, i) => {
                    return (
                      <option key={i} value={ele.value}>
                        {ele.title}
                      </option>
                    );
                  })}
                </Select>
                <Select
                  height="32px"
                  border="1px solid #ced4da"
                  borderRadius="4px"
                  width="85px"
                  margin="0 10px 0 0"
                  onChange={OnSelectChange}
                  value=""
                >
                  <option value=""> Practice</option>
                  {PracticeValue.map((ele, i) => {
                    return (
                      <option key={i} value={ele.value}>
                        {ele.title}
                      </option>
                    );
                  })}
                </Select>
                <Select
                  height="32px"
                  border="1px solid #ced4da"
                  borderRadius="4px"
                  width="85px"
                  margin="0 10px 0 0"
                  onChange={OnSelectChange}
                  value=""
                >
                  <option value=""> Client</option>
                  {ClientValue.map((ele, i) => {
                    return (
                      <option key={i} value={ele.value}>
                        {ele.title}
                      </option>
                    );
                  })}
                </Select>
                <Select
                  height="32px"
                  border="1px solid #ced4da"
                  borderRadius="4px"
                  width="85px"
                  margin="0 10px 0 0"
                  onChange={OnSelectChange}
                  value=""
                >
                  <option value=""> Links</option>
                  {LinkValue.map((ele, i) => {
                    return (
                      <option key={i} value={ele.value}>
                        {ele.title}
                      </option>
                    );
                  })}
                </Select> */}

                <Div display="flex">
                  <DropDown
                    marginTop="0px"
                    ref={clinicianRef}
                    marginRight="20px"
                    position="relative"
                  >
                    <Div onClick={Toggling1}>
                      <SelectInput
                        width="200px"
                        height="30px"
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="17px"
                        letterSpacing="0.0025em"
                        color="#747474"
                        background="#FFFFFF"
                        name="marital"
                        border="1px solid #cbd5e0"
                        borderRadius="4px"
                        // margin="0px 24px 0px 0px"
                        padding="0px 12px"
                        value={status1}
                        id="mypf_pat-mri_sta"
                      >
                        {status1 || "Clinician"}
                        <DropdownImage
                          height="7px"
                          width="10px"
                          top="11px"
                          right="10px"
                          src={arrowIcon}
                        ></DropdownImage>
                      </SelectInput>
                    </Div>
                    {dropDown1 && ClinicianValue.length > 0 ? (
                      <DropDownDiv
                        width="200px"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        top="32px"
                      >
                        {ClinicianValue.map((r, i) => {
                          return (
                            <DropdownOptions
                              fontSize="14px"
                              key={i}
                              value={r.title}
                              padding="7px 12px 7px 12px"
                              onClick={() => setclinicselect(r)}
                            >
                              {r.title}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      ""
                    )}
                  </DropDown>
                  <DropDown
                    marginTop="0px"
                    ref={PracticeRef}
                    position="relative"
                  >
                    <Div onClick={Toggling2}>
                      <SelectInput
                        width="200px"
                        height="30px"
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="17px"
                        letterSpacing="0.0025em"
                        color="#747474"
                        background="#FFFFFF"
                        name="marital"
                        border="1px solid #cbd5e0"
                        borderRadius="4px"
                        // margin="0px 24px 0px 0px"
                        padding="0px 12px"
                        value={status2}
                        id="mypf_pat-mri_sta"
                      >
                        {status2 || "Practice"}
                        <DropdownImage
                          height="7px"
                          width="10px"
                          top="11px"
                          right="10px"
                          src={arrowIcon}
                        ></DropdownImage>
                      </SelectInput>
                    </Div>
                    {dropDown2 && PracticeValue.length > 0 ? (
                      <DropDownDiv
                        width="200px"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                      >
                        {PracticeValue.map((r, i) => {
                          return (
                            <DropdownOptions
                              fontSize="14px"
                              key={i}
                              value={r.title}
                              padding="7px 12px 7px 12px"
                              onClick={() => setpracticeselect(r)}
                            >
                              {r.title}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      ""
                    )}
                  </DropDown>
                </Div>
                <Div display="flex">
                  <DropDown
                    marginTop="0px"
                    ref={clientRef}
                    marginRight="20px"
                    position="relative"
                  >
                    <Div onClick={Toggling3}>
                      <SelectInput
                        width="200px"
                        height="30px"
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="17px"
                        letterSpacing="0.0025em"
                        color="#747474"
                        background="#FFFFFF"
                        name="marital"
                        border="1px solid #cbd5e0"
                        borderRadius="4px"
                        // margin="0px 24px 0px 0px"
                        padding="0px 12px"
                        value={status3}
                        id="mypf_pat-mri_sta"
                      >
                        {status3 || "client"}
                        <DropdownImage
                          height="7px"
                          width="10px"
                          top="11px"
                          right="10px"
                          src={arrowIcon}
                        ></DropdownImage>
                      </SelectInput>
                    </Div>
                    {dropDown3 && ClientValue.length > 0 ? (
                      <DropDownDiv
                        width="200px"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        maxHeight="225px"
                        overflowY="auto"
                      >
                        {ClientValue.map((r, i) => {
                          return (
                            <DropdownOptions
                              fontSize="14px"
                              key={i}
                              value={r.title}
                              padding="7px 12px 7px 12px"
                              onClick={() => setclientselect(r)}
                            >
                              {r.title}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      ""
                    )}
                  </DropDown>
                  <DropDown marginTop="0px" ref={linkRef} position="relative">
                    <Div onClick={Toggling4}>
                      <SelectInput
                        width="200px"
                        height="30px"
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="17px"
                        letterSpacing="0.0025em"
                        color="#747474"
                        background="#FFFFFF"
                        name="marital"
                        border="1px solid #cbd5e0"
                        borderRadius="4px"
                        // margin="0px 24px 0px 0px"
                        padding="0px 12px"
                        value={status4}
                        id="mypf_pat-mri_sta"
                      >
                        {status4 || "Links"}
                        <DropdownImage
                          height="7px"
                          width="10px"
                          top="11px"
                          right="10px"
                          src={arrowIcon}
                        ></DropdownImage>
                      </SelectInput>
                    </Div>
                    {dropDown4 && LinkValue.length > 0 ? (
                      <DropDownDiv
                        width="200px"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                      >
                        {LinkValue.map((r, i) => {
                          return (
                            <DropdownOptions
                              fontSize="14px"
                              key={i}
                              value={r.title}
                              padding="7px 12px 7px 12px"
                              onClick={() => setlinksselect(r)}
                            >
                              {r.title}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      ""
                    )}
                  </DropDown>
                </Div>
              </Div>

              <Div marginTop="10px" fontSize="14px" fontWeight="600">
                <Label display="block">Subject</Label>
                <Input
                  type="text"
                  height="30px"
                  fontWeight="600"
                  padding="0 0 0 10px"
                  width="100%"
                  border="1px solid #ced4da"
                  marginTop="7px"
                  borderRadius="4px"
                  value={
                    props.editType === "Invoice"
                      ? invEmailSub
                      : props.editType === "Statement"
                      ? stmtEmailSub
                      : supEmailSub
                  }
                  onChange={handleSubjectChange}
                />
              </Div>
              <Div marginTop="10px" fontSize="14px" fontWeight="600">
                <Label display="block">Message</Label>
                <TextArea
                  id="mailmessage"
                  width="100%"
                  border="1px solid #ced4da"
                  margin="7px 0 0 0"
                  padding="0.375rem 0.75rem"
                  borderRadius="4px"
                  outline="none"
                  height="300px"
                  fontSize="14px"
                  fontWeight="600"
                  backgroundColor="#fafafa"
                  value={
                    props.editType === "Invoice"
                      ? invEmailMsg
                      : props.editType === "Statement"
                      ? stmtEmailMsg
                      : supEmailMsg
                  }
                  onChange={handleMsgChange}
                ></TextArea>
              </Div>
            </ModalBodyDoc>
            <ModalFooter padding="1rem 25px">
              <ButtonGroup
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                margin="0 16px 0 0px"
                background="#FFF"
                color="#2C7BE5"
                cursor="pointer"
                onClick={handleClose}
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                <Span fontSize="14px" fontWeight="600">
                  Close
                </Span>
              </ButtonGroup>
              <ButtonGroup
                type="submit"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 34px 8px 34px"
                background="#2C7BE5"
                color="#FFFFFF"
                cursor="pointer"
                onClick={handleSave}
                disabled={dis}
                isDisabled={dis}
                hoverBackColor="#005FB2"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="'Open Sans',sans-serif"
                >
                  Save
                </Span>
              </ButtonGroup>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};

export default EditEmailContent;
