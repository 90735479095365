import React from "react";
import {
  Button,
  Div,
  Input,
  Modal,
  ModalContainer,
  PageTitle,
  SearchIconSVG,
  Span,
  Text,
} from "../StyledComponents";
import { TabPanel } from "../Patient/PatientDemo/styles";
import { Tabs } from "./styles";
import { useState } from "react";
import All from "./All/All";
import { AiOutlineSearch } from "react-icons/ai";
import { StyledIcon } from "../LeftNav/style";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useRef } from "react";
import { CheckBoxLabel, DropDownVitals, TogggleCheck } from "../Live/styles";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import AddDevice from "./AddDevice";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { getRecords } from "../../StateManagement/Reducers/DevicesState";

const DeviceList = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const vitalsRef = useRef(null);
  const modalRef = useRef(null);
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = useState("medical_problem");
  const [value1, setValue1] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [deviceSearch, setDeviceSearch] = useState("");
  const [selectedVitalsDevice, setSelectedVitalsDevice] = useState([]);

  const [DeviceOption, setDeviceOption] = useState([
    {
      id: "1",
      text: "Blood Pressure ",
      value: "Blood Pressure Device",
      checked: false,
    },
    {
      id: "2",
      text: "Pulse Oximeter ",
      value: "Pulse Oximeter Device",
      checked: false,
    },
    {
      id: "3",
      text: "Glucose Monitor ",
      value: "Glucose Monitor Device",
      checked: false,
    },
    {
      id: "4",
      text: "Electro Cardiography ",
      value: "Electro Cardiography Device",
      checked: false,
    },
    {
      id: "5",
      text: "Weigh Scale ",
      value: "Weigh Scale Device",
      checked: false,
    },
  ]);
  const [type, SetType] = useState("");
  const [show, setShow] = useState(false);
  const [deviceType, setDeviceType] = useState([]);
  const records = useSelector((state) => state.Device.TotalRecords);

  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const handleDropDownClick = (event) => {
    event.stopPropagation();
  };
  const handleDeviceSearch = (event) => {
    setDeviceSearch(event.target.value);
  };
  const handleTabs = (tabIndex, value) => {
    setValue(value);
    setActiveTab(tabIndex);
    setValue1(true);
  };

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div overflow="auto" paddingTop="16px">
            <All
              pid={"1"}
              value={value}
              value1={value1}
              searchvalue={deviceSearch}
              type={type}
              deviceType={deviceType}
            />
          </Div>
        );
      case 2:
        return (
          <Div overflow="auto" paddingTop="16px">
            <All
              pid={"1"}
              value={value}
              value1={value1}
              searchvalue={deviceSearch}
              type={type}
              deviceType={deviceType}
            />
          </Div>
        );
      case 3:
        return (
          <Div overflow="auto" paddingTop="16px">
            <All
              pid={"1"}
              value={value}
              value1={value1}
              searchvalue={deviceSearch}
              type={type}
              deviceType={deviceType}
            />
          </Div>
        );
      case 4:
        return (
          <Div overflow="auto" paddingTop="16px">
            <All
              pid={"1"}
              value={value}
              value1={value1}
              searchvalue={deviceSearch}
              type={type}
              deviceType={deviceType}
            />
          </Div>
        );
      case 5:
        return (
          <Div overflow="auto" paddingTop="16px">
            <All
              pid={"1"}
              value={value}
              value1={value1}
              searchvalue={deviceSearch}
              type={type}
              deviceType={deviceType}
            />
          </Div>
        );
      case 6:
        return (
          <Div overflow="auto" paddingTop="16px">
            <All
              pid={"1"}
              value={value}
              value1={value1}
              searchvalue={deviceSearch}
              type={type}
              deviceType={deviceType}
            />
          </Div>
        );
      default:
        return null;
    }
  };
  const deviceSelection = (e, row) => {
    let a = [...DeviceOption];
    a.map((r) => {
      if (r.id === row.id) {
        r.checked = e.target.checked;
      }
    });

    //  let count = 0;
    let vital = [];
    a.map((row) => {
      if (row.checked === true) {
        vital.push(row.text);
      }
    });

    setSelectedVitalsDevice(vital);
    setDeviceType(vital);
  };

  const RemoveSelectedVitalsDevice = (e, device) => {
    e.stopPropagation();
    let a = [...DeviceOption];
    a.map((r) => {
      if (r.text === device) {
        r.checked = false;
      }
    });
    setDeviceOption(a);
    let vital = [];
    a.map((row) => {
      if (row.checked === true) {
        vital.push(row.text);
      }
    });
    setSelectedVitalsDevice(vital);
    setDeviceType(vital);
  };
  const closeModal = () => {
    setShow(false);
  };
  const dispatch = useDispatch();

  const provider_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");

  const addDeviceResponse = useSelector((state) => state.PatientDemo.DeviceRes);

  const handleClick = (event) => {
    if (modalRef.current !== null) {
      if (show && modalRef.current.contains(event.target)) {
        document.body.style.overflow = "hidden";
      }
      if (!show) {
        document.body.style.overflow = "";
      }
    }
    event.preventDefault();
  };

  const showOverflow = () => {
    document.body.style.overflow = "";
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    document.addEventListener("click", handleClick, { passive: false });
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [show]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (vitalsRef.current && !vitalsRef.current.contains(event.target)) {
        if (dropOpen === true) {
          setDropOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropOpen]);

  useEffect(() => {
    dispatch(
      getRecords({
        uid: provider_id,
        search_key: deviceSearch,
        vitals: deviceType,
      })
    );
  }, [deviceSearch, deviceType]);

  useEffect(() => {
    if (addDeviceResponse !== "") {
      if (addDeviceResponse === "Devices added sucessfully") {
        dispatch(
          getRecords({
            uid: provider_id,
            search_key: deviceSearch,
            vitals: deviceType,
          })
        );
        handleTabs(1, "All");
        SetType("");
      }
    }
  }, [addDeviceResponse]);
  // ----------------------------------------- UseEffect Start ------------------------------------------------------- //
  return (
    <>
      <Div padding="0px 0px 16px 0px">
        <PageTitle
          textAlign="left"
          fontFamily="'Open Sans', sans-serif"
          fontWeight="600"
          fontSize="18px"
          lineHeight="24px"
          color="#2E2E2E"
        >
          Devices
        </PageTitle>
      </Div>
      <Div background="#FFFFFF" borderRadius="8px">
        <Div
          display="flex"
          padding="16px 32px 16px 31px"
          justifyContent="space-between"
        >
          <Div position="relative" display="flex">
            <SearchIconSVG>
              <AiOutlineSearch color="rgba(46, 46, 46, 0.5)" />
            </SearchIconSVG>

            <Input
              type="search"
              name="search"
              placeholder="Search by Devices"
              padding="8px 16px 8px 30px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              maxWidth="200px"
              minWidth="100px"
              height="38px"
              color="rgba(46, 46, 46, 0.5)"
              fontFamily="'Open Sans', sans-serif"
              fontWeight="400"
              fontSize="14px"
              position="absolute"
              value={deviceSearch}
              onChange={handleDeviceSearch}
              className={deviceSearch ? "hide-icon" : ""}
              autoComplete
            />
            <Div
              minWidth="200px"
              display="block"
              margin="0px 0px 0px 20px"
              ref={vitalsRef}
            >
              <Div
                minHeight="38px"
                border="1px solid rgba(46, 46, 46, 0.25)"
                width="100%"
                //padding="2px 4px"
                borderRadius="4px"
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setDropOpen(!dropOpen);
                }}
              >
                <Div display="flex" flexWrap="wrap" padding="5px 5px 0px 5px">
                  {selectedVitalsDevice.length > 0 ? (
                    selectedVitalsDevice.map((device) => {
                      return (
                        <>
                          <Div
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            backgroundColor="#f1f5f8"
                            borderRadius="4px"
                            padding="4px"
                            margin="0px 0px 4px 4px"
                          >
                            <Text
                              fontWeight="400"
                              fontSize="14px"
                              color="#2E2E2E"
                              lineHeigh="15px"
                              padding="0px 4px 0px 0px"
                            >
                              {device}
                            </Text>
                            <StyledIcon fontSize="11px">
                              <FaTimes
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  RemoveSelectedVitalsDevice(e, device)
                                }
                              />
                            </StyledIcon>
                          </Div>
                        </>
                      );
                    })
                  ) : (
                    <Div
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="   #FFF"
                      borderRadius="4px"
                      padding="4px"
                      margin="0px 0px 4px 4px"
                    >
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        color="rgba(46, 46, 46, 0.7)"
                        fontFamily="'Open Sans', sans-serif"
                      >
                        Devices Type
                      </Text>
                    </Div>
                  )}
                </Div>
                <StyledIcon
                  fontSize="29px"
                  color="rgba(46, 46, 46, 0.25)"
                  display="flex"
                  marginLeft="auto"
                >
                  <RiArrowDropDownLine />
                </StyledIcon>
              </Div>
              <Div
                minWidth="200px"
                border="1px solid rgba(46, 46, 46, 0.25)"
                position="absolute"
                // top="37px"
                zIndex="1000"
                background="#ffff"
                borderRadius="4px"
                // margin="0px 0px 0px 20px"
                display={dropOpen ? "block" : "none"}
                onClick={handleDropDownClick}
              >
                {DeviceOption.map((row, i) => {
                  return (
                    <Div display="flex">
                      <Div position="relative" margin="11px 12px 0px 12px">
                        <TogggleCheck
                          checked={row.checked}
                          type="checkbox"
                          onChange={(e) => deviceSelection(e, row)}
                        />
                        <CheckBoxLabel htmlFor="checkbox" />
                      </Div>
                      <DropDownVitals
                        // ref={vitalsRef}
                        name={row.text}
                        key={row.id}
                        display="flex"
                        value={row.value}
                        padding="10px 12px 8px 12px"
                      >
                        <Span
                          fontSize="14px"
                          fontFamily="'Open Sans', sans-serif"
                          fontWeight="400"
                          lineHeight="19px"
                        >
                          {row.text}
                        </Span>
                      </DropDownVitals>
                    </Div>
                  );
                })}
              </Div>
            </Div>
          </Div>
          <Div display="flex" justifyContent="end" margin="0px 0px 0px 20px">
            <Button
              ref={modalRef}
              cursor="pointer"
              color="#FFFFFF"
              border="none"
              borderRadius="4px"
              background="#2C7BE5"
              fontFamily="Open Sans, sans-serif"
              fontWeight="600"
              fontSize="14px"
              padding="10px 17px"
              height="36px"
              // marginRight="20px"
              display="flex"
              alignItems="center"
              onClick={(e) => {
                setShow(true);
                //e.stopPropagation();
              }}
              backgroundColor="#2C7BE5"
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              Add Devices
            </Button>
          </Div>
        </Div>
        <TabPanel
          width="100%"
          background="#FFFFFF"
          borderRadius="8px 8px 0px 0"
          borderBottom="1px solid rgba(46, 46, 46, 0.25);"
          display="flex"
          justifyContent="flex-start"
          padding="5px 25px 0 0px"
        >
          <Tabs
            width="70px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="14px"
            color="rgba(46, 46, 46, 0.50)"
            fontStyle="normal"
            lineHight="normal"
            padding="0px 0px 0 24px"
            isActive={activeTab === 1}
            onClick={() => {
              handleTabs(1, "All");
              SetType("");
            }}
            line={activeTab}
          >
            All
            <span>({records.total_records})</span>
          </Tabs>
          <Tabs
            width="94px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="14px"
            color="rgba(46, 46, 46, 0.5)"
            padding="0px 0px 0 22px"
            isActive={activeTab === 2}
            onClick={() => {
              handleTabs(2, "Active");
              SetType("Active");
            }}
          >
            Active
            <span>
              ({records.total_active === null ? "0" : records.total_active})
            </span>
          </Tabs>
          <Tabs
            width="106px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="14px"
            padding="0px 0px 0 20px"
            color="rgba(46, 46, 46, 0.5)"
            isActive={activeTab === 3}
            onClick={() => {
              handleTabs(3, "Inactive");
              SetType("Inactive");
            }}
          >
            Inactive
            <span>
              ({records.total_inactive === null ? "0" : records.total_inactive})
            </span>
          </Tabs>
          <Tabs
            width="131px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="14px"
            color="rgba(46, 46, 46, 0.5)"
            padding="0px 0px 0 17px"
            isActive={activeTab === 4}
            onClick={() => {
              handleTabs(4, "Unassigned");
              SetType("Unassigned");
            }}
          >
            Unassigned
            <span>
              (
              {records.total_unassigned === null
                ? "0"
                : records.total_unassigned}
              )
            </span>
          </Tabs>
          <Tabs
            width="99px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="14px"
            color="rgba(46, 46, 46, 0.5)"
            padding="0px 0px 0 17px"
            isActive={activeTab === 5}
            onClick={() => {
              handleTabs(5, "Return");
              SetType("Return");
            }}
          >
            Return
            <span>
              ({records.total_return === null ? "0" : records.total_return})
            </span>
          </Tabs>
          <Tabs
            width="82px"
            fontFamily="'Open Sans',sans-serif"
            fontWeight="600"
            fontSize="14px"
            color="rgba(46, 46, 46, 0.5)"
            padding="0px 0px 0 17px"
            isActive={activeTab === 6}
            onClick={() => {
              handleTabs(6, "Lost");
              SetType("Lost");
            }}
          >
            Lost
            <span>
              ({records.total_lost === null ? "0" : records.total_lost})
            </span>
          </Tabs>
        </TabPanel>
        {tabComponent()}
      </Div>
      {show && (
        <Modal
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          show={show}
          zIndex="1150"
        >
          <ModalContainer
            width="100%"
            height="100%"
            borderRadius="8px"
            border="1px solid rgba(46, 46, 46, 0.25)"
            maxWidth="100%"
          >
            <AddDevice
              onHide={closeModal}
              pid={0}
              enrolledDevices={[]}
              showOverflow={showOverflow}
            />
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default DeviceList;
