import React, { useState, useEffect } from "react";
import { Container, Div, FormLabel } from "../../StyledComponents";
import { Container1, TabPanel } from "../../Patient/PatientDemo/styles";
import Personal from "./Personal";
import Clinical from "./Clinical";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import { Tabs } from "./styles";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import AccessDenied from "../../AccessDenied";

const MyProfile = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = useState("Personal");
  const [value1, setValue1] = useState(false);

  const handleTabs = (tabIndex, value) => {
    setValue(value);
    setActiveTab(tabIndex);
    setValue1(true);
  };

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div padding="20px 31px 28px 24px">
            <Personal value={value} value1={value1} />
          </Div>
        );
      case 2:
        return (
          <Div padding="24px 31px 28px 24px">
            <Clinical value={value} value1={value1} />
          </Div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Container backgroundColor="#F5F5F5">
        <TopNav />
        <LeftNav />
        <Div marginLeft="54px">
          <Div marginTop="16px">
            <FormLabel
              fontSize="18px"
              color="#2E2E2E"
              fontFamily="'Open Sans', sans-serif"
              lineHeight="25px"
              marginLeft="24px"
              fontWeight="600"
            >
              My Profile
            </FormLabel>
          </Div>
          <Div
            background="#FFFFFF"
            border="1px solid #E2E8ED"
            borderRadius="8px"
            margin="16px 24px 24px 24px"
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
          >
            {permissions.includes("profile_edit") ? (
              <Container1>
                <TabPanel
                  width="100%"
                  background="#ffffff"
                  borderRadius="8px 8px 0px 0"
                  borderBottom="1px solid rgba(46, 46, 46, 0.25);"
                  display="flex"
                  justifyContent="flex-start"
                  padding="27px 25px 0 0px"
                >
                  <Tabs
                    width="130px"
                    fontWeight="600"
                    fontFamily="'Open Sans', sans-serif"
                    lineHeight="2px"
                    color="rgba(46, 46, 46, 0.5)"
                    padding="0px 0px 0 24px"
                    fontSize="16px"
                    isActive={activeTab === 1}
                    marginTop="8px"
                    onClick={() => handleTabs(1, "Personal")}
                    line={activeTab}
                  >
                    Personal
                  </Tabs>
                  <Tabs
                    width="98px"
                    fontWeight="600"
                    fontFamily="'Open Sans', sans-serif"
                    lineHeight="2px"
                    fontSize="16px"
                    color="rgba(46, 46, 46, 0.5)"
                    padding="0px 0px 0 0px"
                    marginTop="8px"
                    marginLeft="-15px"
                    isActive={activeTab === 2}
                    onClick={() => handleTabs(2, "allergy")}
                  >
                    Clinical
                  </Tabs>
                </TabPanel>
                {tabComponent()}
              </Container1>
            ) : (
              <AccessDenied />
            )}
          </Div>
        </Div>
      </Container>
    </>
  );
};
export default MyProfile;
