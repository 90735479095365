import { createSlice } from "@reduxjs/toolkit";
import { Data } from "../../Modules/StyledComponents";

export const MessengerSlice = createSlice({
  name: "messenger",
  initialState: {
    messageList: [],
    patList: [],
    singleMessage: [],
    previewFile: "",
    NewMsgs: 0,
    unreadcount: [],
  },
  reducers: {
    getMessageList: (user) => {
      return user;
    },
    setMessageList: (state, action) => {
      state.messageList = action.payload;
    },
    getMsgPatsearch: (search) => {
      return search;
    },
    setMsgPatSearch: (state, action) => {
      state.patList = action.payload;
    },
    sendMessage: (Data) => {
      return Data;
    },
    sendImage: (data) => {
      return data;
    },
    getSingleMessage: (data) => {
      return data;
    },
    getPreviewFile: (data) => {
      return data;
    },
    setSingleMessage: (state, action) => {
      state.singleMessage = action.payload;
    },
    setPreviewFile: (state, action) => {
      state.previewFile = action.payload;
    },
    clearPreview: (state) => {
      state.previewFile = "";
    },
    setNewMsgCount: (state, action) => {
      state.NewMsgs = action.payload;
    },
    setUnreadCount: (state, action) => {
      state.unreadcount = action.payload;
    },
    getUnreadCount: (state) => {
      return state;
    },
  },
});

export const {
  getMessageList,
  setMessageList,
  getMsgPatsearch,
  setMsgPatSearch,
  sendMessage,
  sendImage,
  getSingleMessage,
  setSingleMessage,
  setPreviewFile,
  getPreviewFile,
  clearPreview,
  setNewMsgCount,
  setUnreadCount,
  getUnreadCount,
} = MessengerSlice.actions;

export default MessengerSlice.reducer;
