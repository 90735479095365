import { useEffect, useState } from "react";

import AudioPreview from "./AudioPreview";
import { Div } from "./styles";

const RecordingStatus = ({
  isRecording,
  isRecordingPaused,
  audioURLs,
  recordingKey,
  one,
}) => {
  const [timer, setTimer] = useState(0);
  const [getUrls, SetUrls] = useState([]);
  useEffect(() => {
    let interval;

    if (isRecording && !isRecordingPaused) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (!isRecordingPaused) {
      clearInterval(interval);
      setTimer(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRecording, isRecordingPaused]);

  useEffect(() => {
    SetUrls(audioURLs);
  }, [audioURLs]);

  return (
    <Div>
      {isRecording ? (
        <Div display="flex" alignItems="center">
          <Div>{timer}s</Div>
          <Div marginLeft="12px" className="voice-note-animation"></Div>
        </Div>
      ) : (
        !one && (
          <Div display="flex" alignItems="center">
            <Div>{timer}s</Div>
            <Div
              marginLeft="20px"
              className="voice-note-animation paused-animation"
            ></Div>
          </Div>
        )
      )}
      {isRecordingPaused && (
        <Div
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          paddingBottom="3px"
        >
          <Div marginLeft="6px">
            {getUrls && <AudioPreview key={recordingKey} audioBlob={getUrls} />}
          </Div>
        </Div>
      )}
    </Div>
  );
};

export default RecordingStatus;
