import { Container, Text, Div } from "../../StyledComponents/index";
import { TogggleCheck, CheckBoxLabelN, RadioContainer } from "./Styles";
const RadioProgress = (props) => {
  const handleCheckboxChange = (event) => {
    if (props.onChange) {
      props.onChange(event.target.checked);
    }
  };

  return (
    <RadioContainer className="radio-adj">
      {/* <RadioContainer> */}
      <Container margin="16px 0 0 0">
        <Container display="flex" margin="0 0 17px 0" cursor="pointer">
          <Container position="relative">
            <TogggleCheck
              position="relative"
              id="checkboxcheck"
              type="checkbox"
              checked={true}
              onChange={handleCheckboxChange}
            />
            <CheckBoxLabelN
              check={true}
              position="absolute"
              htmlFor="checkboxcheck"
            />
          </Container>
          <Text
            className="font-adj"
            fontSize="16px"
            position="absolute"
            marginTop="47px"
            marginLeft="-29px"
            color="#2E2E2E"
            width="94px"
          >
            Patient Info
          </Text>
        </Container>
      </Container>
      <Container margin="16px 0 0 0">
        <Container display="flex" margin="0 0 17px 0" cursor="pointer">
          <Container position="relative">
            <TogggleCheck
              position="relative"
              id="checkboxcheck"
              type="checkbox"
              checked={props.page === 2 || props.page === 3 || props.page === 4}
              onChange={handleCheckboxChange}
            />
            <CheckBoxLabelN
              check={props.page === 2 || props.page === 3 || props.page === 4}
              position="absolute"
              htmlFor="checkboxcheck"
            />
          </Container>
          <Text
            className="font-adj"
            fontSize="16px"
            position="absolute"
            marginTop="47px"
            marginLeft="-29px"
            color="#2E2E2E"
            width="94px"
          >
            Eligibility Verification
          </Text>
        </Container>
      </Container>
      <Container margin="16px 0 0 0">
        <Container display="flex" margin="0 0 17px 0" cursor="pointer">
          <Container position="relative">
            <TogggleCheck
              position="relative"
              id="checkboxcheck"
              type="checkbox"
              checked={props.page === 3 || props.page === 4}
              onChange={handleCheckboxChange}
            />
            <CheckBoxLabelN
              check={props.page === 3 || props.page === 4}
              position="absolute"
              htmlFor="checkboxcheck"
            />
          </Container>
          <Text
            className="font-adj"
            fontSize="16px"
            position="absolute"
            marginTop="47px"
            marginLeft="-29px"
            color="#2E2E2E"
            width="94px"
          >
            Consent
          </Text>
        </Container>
      </Container>
      <Container margin="16px 0 0 0">
        <Container display="flex" margin="0 0 17px 0" cursor="pointer">
          <Container position="relative">
            <TogggleCheck
              position="relative"
              id="checkboxcheck"
              type="checkbox"
              checked={props.page === 4}
              onChange={handleCheckboxChange}
            />
            <CheckBoxLabelN
              check={props.page === 4}
              position="absolute"
              htmlFor="checkboxcheck"
            />
          </Container>
          <Text
            className="font-adj"
            fontSize="16px"
            position="absolute"
            marginTop="47px"
            marginLeft="-29px"
            color="#2E2E2E"
            width="94px"
          >
            Patient Portal
          </Text>
        </Container>
      </Container>
    </RadioContainer>
  );
};
export default RadioProgress;
