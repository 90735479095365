import React, { useState, useEffect } from "react";
import {
  Div,
  Button,
  Table,
  TableRow,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  Image,
} from "../../StyledComponents";
import { Tab1 as Tab, Tabs1 as Tabs, Span1 as Span } from "./styles";
import { TitleHead, FieldHead, ErrorMessage, FileInput } from "./styles";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import Emp_data_pic from "../../../Assets/images/No_Data_Img.png";
import { useSelector } from "react-redux";
import AddInsuranceTab from "./AddInsuranceTab";
const InsuranceTab = () => {
  //------------------------------------------State and Variables Start--------------------------------------//

  const [Show, setShow] = useState(false);

  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const [active, setActive] = React.useState("1");
  const [instype, setinstype] = useState("primary");

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//
  const handleInsuranceClick = (e) => {
    const index = e.target.id !== "" ? e.target.id : e.currentTarget.id;
    if (index !== active) {
      setActive(index);
    }
    if (index === "1") {
      setinstype("primary");
      // handlechange1("primary");
    } else if (index === "2") {
      setinstype("secondary");
      // handlechange1("secondary");
    } else if (index === "3") {
      setinstype("tertiary");
      // handlechange1("tertiary");
    }
  };

  const handleOpen = () => {
    setShow(true);
  };
  const insurancedata = [];
  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start--------------------------------------------------//

  //------------------------------------------UseEffect End--------------------------------------------------//

  return (
    <Div padding="16px 24px 24px 28px">
      <TitleHead>Insurance Information</TitleHead>

      <Div
        className="ins-info-tab"
        display="flex"
        justifyContent="space-between"
        marginTop="14px"
      >
        <Tabs>
          <Tab
            onClick={handleInsuranceClick}
            active={active === "1"}
            id="1"
            borderBottom="none"
          >
            Primary&nbsp;Insurance
          </Tab>
          <Tab
            onClick={handleInsuranceClick}
            active={active === "2"}
            id="2"
            borderBottom="none"
          >
            Secondary&nbsp;Insurance
          </Tab>
          <Tab
            onClick={handleInsuranceClick}
            active={active === "3"}
            id="3"
            borderBottom="none"
          >
            Teritary&nbsp;Insurance
          </Tab>
        </Tabs>
        <Div>
          <Button
            margin="0px 0px 0px 0px"
            padding="2px 10px"
            backgroundColor="#2C7BE5"
            borderRadius="4px"
            border="#2C7BE5"
            fontSize="14px"
            fontWeight="600"
            fontFamily="'Open Sans',sans-serif"
            color="#FFF"
            width="120px"
            height="36px"
            lineHeight="normal"
            hoverBackColor="#005FB2"
            activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            className="save_active"
            onClick={handleOpen}
          >
            Add Insurance
          </Button>
        </Div>
      </Div>
      <Div
        overflowX="auto"
        overflowY="auto"
        maxHeight="500px"
        borderRadius="4px"
        border="1px solid rgba(46, 46, 46, 0.25)"
        marginTop="22px"
      >
        <Table border="none" borderTop="none" borderRadius="4px">
          <TableHead
            style={{ position: "sticky", top: "0" }}
            background="#F1F5F8"
          >
            <TableRow
              fontSize="14px"
              fontWeight="600"
              colSpan="12"
              paddingTop="8px"
              paddingBottom="8px"
              lineHeight="20px"
              fontFamily="'Open Sans',sans-serif"
            >
              <TableHeadCell
                textAlign="left"
                color="#2C7BE5"
                width="9%"
                paddingLeft="16px"
              >
                Primary Insured
              </TableHeadCell>
              <TableHeadCell textAlign="left" color="#2C7BE5" width="9%">
                Insurance Payer
              </TableHeadCell>
              <TableHeadCell textAlign="left" color="#2C7BE5" width="9%">
                Member ID
              </TableHeadCell>
              <TableHeadCell textAlign="left" color="#2C7BE5" width="9%">
                Plan ID
              </TableHeadCell>

              <TableHeadCell textAlign="left" color="#2C7BE5" width="9%">
                Insurance Payer Phone
              </TableHeadCell>
              <TableHeadCell textAlign="left" color="#2C7BE5" width="9%">
                Insurance Payer Fax
              </TableHeadCell>

              <TableHeadCell
                textAlign="center"
                color="#2C7BE5"
                width="10%"
                paddingRight="30px"
              >
                Action
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <>
              {insurancedata.length !== 0 &&
                insurancedata.map((details, ind) => (
                  <TableRow
                    key={ind}
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px !important"
                    textAlign="center"
                    borderBottom="none"
                    paddingTop="12px"
                    paddingBottom="13px"
                    fontFamily="'Open Sans',sans-serif"
                    color="#2E2E2E"
                  >
                    <TableBodyCell
                      textAlign="left"
                      color="#2E2E2E"
                      cursor="pointer"
                      paddingLeft="16px"
                      maxWidth="100px"
                    >
                      <Div
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {details.primary_insured
                          ? details.primary_insured
                          : "None"}
                      </Div>
                    </TableBodyCell>

                    <TableBodyCell
                      textAlign="left"
                      color="#2E2E2E"
                      maxWidth="100px"
                    >
                      <Div
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {details.insurance_payer
                          ? details.insurance_payer
                          : "None"}
                      </Div>
                    </TableBodyCell>
                    <TableBodyCell
                      textAlign="left"
                      color="#2E2E2E"
                      maxWidth="100px"
                    >
                      <Div
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {details.member_id ? details.member_id : "None"}
                      </Div>
                    </TableBodyCell>
                    <TableBodyCell
                      textAlign="left"
                      color="#2E2E2E"
                      maxWidth="100px"
                    >
                      <Div
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {details.plan_id ? details.plan_id : "None"}
                      </Div>
                    </TableBodyCell>

                    <TableBodyCell
                      textAlign="left"
                      color="#2E2E2E"
                      maxWidth="100px"
                    >
                      <Div
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {details.payer_phone ? details.payer_phone : "None"}
                      </Div>
                    </TableBodyCell>
                    <TableBodyCell
                      textAlign="left"
                      color="#2E2E2E"
                      maxWidth="100px"
                    >
                      <Div
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        {details.payer_fax ? details.payer_fax : "None"}
                      </Div>
                    </TableBodyCell>

                    <TableBodyCell
                      textAlign="center"
                      color="#2E2E2E"
                      paddingLeft="0px"
                    >
                      <Span
                        height="24px"
                        width="24px"
                        color="#2C7BE5"
                        cursor="pointer"
                        fontSize="20px"
                        // onClick={() => handleedit(details.type, details.id)}
                      >
                        <RiEdit2Line />
                      </Span>
                      <Span
                        height="24px"
                        width="24px"
                        color="#F24822"
                        marginLeft="16px"
                        cursor="pointer"
                        fontSize="20px"
                        // onClick={(e) => handledelete(details.type)}
                      >
                        <RiDeleteBin6Line />
                      </Span>
                    </TableBodyCell>
                  </TableRow>
                ))}

              {insurancedata.length === 0 && insurancedata.length === 0 && (
                <TableRow height="168px">
                  <TableBodyCell textAlign="center" colSpan={7}>
                    <Div>
                      <Image height="100px" width="100px" src={Emp_data_pic} />
                    </Div>
                  </TableBodyCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
      </Div>
      {Show && (
        <AddInsuranceTab
          showvalue={Show}
          // id={id}
          setShow={setShow}
          // getrefVal2={getrefVal2}
          // datavalue={datavalue}
          // dataFetch={getData}
          // btnDisabledtab={btnDisabledtab}
          // setbtnDisabledtab={setbtnDisabledtab}
          activetab={active}
        />
      )}
    </Div>
  );
};
export default InsuranceTab;
