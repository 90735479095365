import React from "react";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import PasswordExpired from "../Popup/PasswordExpired/PasswordExpired";
import { useState } from "react";
import { useEffect } from "react";

const ExpiredPassword = () => {
  const [show, setShow] = useState(false);
  const allowModal = localStorage.getItem("pass_exp_modal");
  const passStatus =
    localStorage.getItem("exp") &&
    Decrypt_Value(localStorage.getItem("exp"), "vozorpm");
  const closeModal = () => {
    setShow(false);
  };
  useEffect(() => {
    if (passStatus) {
      if (passStatus === "grace_pass" && allowModal) {
        setShow(true);

        localStorage.removeItem("pass_exp_modal");
      }
      if (passStatus === "grace_fail" && allowModal) {
        setShow(true);
      }
    }
  }, [passStatus]);
  return (
    <>
      {show && (
        <PasswordExpired
          show={show}
          onHide={closeModal}
          passStatus={passStatus}
        />
      )}
    </>
  );
};

export default ExpiredPassword;
