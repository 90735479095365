import axiosInst from "../../Config";

export const DrugApi = async (credential) =>
  await axiosInst.post(
    `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=${credential}`
  );

export const ICD10Api = async (credential) =>
  await axiosInst.post(
    `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${credential}`
  );
export const ICD9Api = async (credential) =>
  await axiosInst.post(
    `https://clinicaltables.nlm.nih.gov/api/icd9cm_dx/v3/search?terms=${credential}`
  );
export const ProcedureApi = async (credential) =>
  await axiosInst.post(
    `https://clinicaltables.nlm.nih.gov/api/loinc_items/v3/search?terms=${credential}`
  );
