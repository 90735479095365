import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  AddEncounterApi,
  GetEncounterOptions_Api,
  GetEncounterSummary_Api,
  GetVisitEncountersApi,
  LockApi,
  encounterViewDetails,
  FeeSheetViewDetails,
  AddNotesTake_Api,
  GetRpmNotes_Api,
  GetRpmGmeetData_Api,
  DeleteRpmSingleNotes_Api,
  exportCSV_Api,
  EditEncounterApi,
  InsNoteId_Api,
  ChartviewIdApi,
} from "../Apis/EncounterApi";
import {
  SetVisitEncounterList,
  setEncounterAlertMsg,
  setEncounterId,
  setEncounterOptions,
  setEncounterSummary,
  setLockResponse,
  setEncounterDetails,
  setFeeSheetDetails,
  SetAddNotesTake,
  SetRpmNotes,
  SetRpmSingleNote,
  SetRpmGmeetData,
  SetRpmSingleDelete,
  setCSVData,
  SetNotesId,
  SetChartviewId,
} from "../../StateManagement/Reducers/EncounterState";

export function* AddEncounterInsert({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(AddEncounterApi, requiredData);
    if (res.status === 200) {
      yield put(setEncounterAlertMsg(res.data));
      yield put(setEncounterId(res.data[0]));
    }
  } catch (e) {
    yield put(setEncounterAlertMsg([]));
    console.log(e.message);
  }
}
export function* EditEncounterUpdate({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(EditEncounterApi, requiredData);
    if (res.status === 200) {
      yield put(setEncounterAlertMsg(res.data));
    }
  } catch (e) {
    yield put(setEncounterAlertMsg([]));
    console.log(e.message);
  }
}

export function* setEnconterEmptyResponse() {
  yield put(setEncounterAlertMsg(""));
}

export function* EncounterOptionsApi({ payload }) {
  try {
    const res = yield call(GetEncounterOptions_Api, payload);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setEncounterOptions(Data));
    }
  } catch (e) {
    yield put(setEncounterOptions("CAN'T FETCH Options"));
  }
}

export function* VisitEncounterWorker({ payload }) {
  try {
    const res = yield call(GetVisitEncountersApi, payload.pid);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetVisitEncounterList(Data));
    }
  } catch (e) {
    yield put(SetVisitEncounterList([]));
  }
}

export function* EncounterSummaryApi({ payload }) {
  yield put(setEncounterId(payload));
  try {
    yield put(setEncounterSummary([]));
    const res = yield call(GetEncounterSummary_Api, payload);
    if (res.status === 200) {
      const Data = [Decrypt_Value(res.data, "vozorpm")];

      if (
        Decrypt_Value(res.data, "vozorpm").length > 0 ||
        Object.keys(Decrypt_Value(res.data, "vozorpm")).length > 0
      ) {
        yield put(setEncounterSummary(Data));
      }
    }
  } catch (e) {
    yield put(setEncounterSummary("CAN'T FETCH Summary"));
  }
}

export function* EncounterIdWorker({ payload }) {
  yield put(setEncounterId(payload));
}

export function* LockSet({ payload }) {
  const requiredData = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(LockApi, requiredData);
    if (res.status === 200) {
      const Response = Decrypt_Value(res.data, "vozorpm");
      if (payload.pid) {
        const res2 = yield call(GetVisitEncountersApi, payload.pid);
        if (res2.status === 200) {
          const Data = Decrypt_Value(res2.data, "vozorpm");
          yield put(SetVisitEncounterList(Data));
        }
      } else if (payload.viewdetails) {
        if (payload.src === "visit") {
          delete payload.viewdetails.eid;
        }
        const requiredData =
          payload.src !== "visit"
            ? Encrypt_Value(payload.viewdetails, "vozorpm")
            : Encrypt_Value(payload.viewdetails, "vozorpm");
        const res4 = yield call(encounterViewDetails, requiredData);
        if (res4.status === 200) {
          const Data = Decrypt_Value(res4.data, "vozorpm");
          yield put(setEncounterDetails(Data));
        }
      } else {
        const res3 = yield call(GetEncounterSummary_Api, payload.encounter);
        if (res3.status === 200) {
          const Data = [Decrypt_Value(res3.data, "vozorpm")];

          yield put(setEncounterSummary(Data));
        }
      }

      yield put(setLockResponse("success"));
    }
  } catch (e) {
    yield put(setLockResponse("lock_FAILURE"));
    console.log(e.message);
  }
}

export function* encounterViewDetailsWorker({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(encounterViewDetails, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(setEncounterDetails(DecryptedData));
    }
  } catch (e) {
    yield put(setEncounterDetails([]));
    console.log(e.message);
  }
}
export function* FeeSheetViewDetailsWorker({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(FeeSheetViewDetails, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(setFeeSheetDetails(DecryptedData));
    }
  } catch (e) {
    yield put(setFeeSheetDetails([]));
    console.log(e.message);
  }
}
export function* AddNotesTakeApi({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  SetAddNotesTake("");
  try {
    const res = yield call(AddNotesTake_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetAddNotesTake(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* InsertNotesId({ payload }) {
  const encryptedData = Encrypt_Value(payload, "vozorpm");
  yield put(SetNotesId(""));
  try {
    const res = yield call(InsNoteId_Api, encryptedData);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetNotesId(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetRpmNotesApi({ payload }) {
  try {
    const res = yield call(GetRpmNotes_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetRpmNotes(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* GetRpmSingleNoteApi({ payload }) {
  SetRpmSingleNote({});
  try {
    const res = yield call(GetRpmNotes_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetRpmSingleNote(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* GetRpmGmeetDataApi({ payload }) {
  SetRpmGmeetData({});
  try {
    const res = yield call(GetRpmGmeetData_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetRpmGmeetData(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* RpmSingleDeleteApi({ payload }) {
  SetRpmSingleDelete("");
  try {
    const res = yield call(DeleteRpmSingleNotes_Api, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");

      yield put(SetRpmSingleDelete(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* setAddNoteEmpty() {
  yield put(SetAddNotesTake(""));
}

export function* setDelNoteEmpty() {
  yield put(SetRpmSingleDelete(""));
}

export function* setSingleEmpty() {
  yield put(SetRpmSingleNote({}));
}
const downloadCSV = (csvContent, fileName) => {
  const csvData = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const csvURL = window.URL.createObjectURL(csvData);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", fileName);
  tempLink.click();
};
export function* ExportCSVFile({ payload }) {
  try {
    const res = yield call(exportCSV_Api, payload);
    if (res.status === 200) {
      const csvContent = res.data;
      const fileName = "FeeSheet.csv";
      downloadCSV(csvContent, fileName);
    }
  } catch (e) {
    console.log(e.message);
    yield put(setCSVData(""));
  }
}

export function* Chartviewworker({ payload }) {
  yield put(SetChartviewId({}));
  try {
    const res = yield call(ChartviewIdApi, payload);
    if (res.status === 200) {
      const DecryptedData = Decrypt_Value(res.data, "vozorpm");
      yield put(SetChartviewId(DecryptedData));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* setchartviewEmpty() {
  yield put(SetChartviewId({}));
}
