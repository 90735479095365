import { call, put } from "redux-saga/effects";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import {
  LoginResponse,
  SetLogo,
  setPasswordChange,
  setProviderDetails,
  setEmailRes,
  setOtp,
  setSaveLoading,
} from "../../StateManagement/Reducers/LoginState";
import {
  LoginApi,
  LogoExtension_Api,
  ProviderDetailsApi,
  ChangePassWordApi,
  LoginEmailCheck_Api,
} from "../Apis/LoginApi";

export function* LoginCheck({ payload }) {
  const credential = Encrypt_Value(payload, "vozorpm");
  try {
    const res = yield call(LoginApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(LoginResponse(Data));
      if (Data.status === "LOGIN_SUCCESS") {
        let encryptProviderName = Encrypt_Value(Data.provider_name, "vozorpm");
        let encryptUserId = Encrypt_Value(Data.provider_id, "vozorpm");
        localStorage.setItem("ProviderName", encryptProviderName);
        localStorage.setItem("user_id", encryptUserId);
      }
    }
  } catch (e) {
    yield put(LoginResponse("LOGIN_FAILURE"));
    console.log(e.message);
  }
}

export function* LogoApi() {
  try {
    const res = yield call(LogoExtension_Api);
    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozo");
      yield put(SetLogo(Decrypt_Response[4]["gl_value"]));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* PasswordChangeWorker({ payload }) {
  try {
    const res = yield call(ChangePassWordApi, payload);
    if (res.status === 200) {
      yield put(setPasswordChange(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setPasswordChange(""));
  }
}

export function* ProviderDetail({ payload }) {
  try {
    const res = yield call(ProviderDetailsApi, payload.id);
    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozorpm");
      yield put(setProviderDetails(Decrypt_Response));
    }
  } catch (e) {
    console.log(e.message);

    yield put(
      setProviderDetails({
        img_url: "",
        providerName: "",
      })
    );
  }
}
export function* setEmptyResponse() {
  yield put(setPasswordChange(""));
}

export function* LoginEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "vozorpm");

  try {
    const res = yield call(LoginEmailCheck_Api, email);
    if (res.status === 200) {
      yield put(setSaveLoading(false));
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setOtp(Data.output));
      localStorage.setItem("LoginOTP", Encrypt_Value(Data.output, "vozorpm"));
      yield put(setEmailRes(Data));
    } else {
      yield put(setSaveLoading(false));
    }
  } catch (e) {
    yield put(setSaveLoading(false));
    console.log(e.message);
  }
}
