import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { LoginCredentials } from "../../StateManagement/Reducers/LoginState";
import {
  Button,
  LinK,
  FormLabel,
  Form,
  Container,
  ImageContainer,
  Image,
  FormGroup,
  Input,
  BodyContainer,
  PageTitle,
  PageSubTitle,
  HiddenCheckbox,
  StyledCheckbox,
  CheckIcon,
  Div,
  A,
  Text,
} from "../StyledComponents";
import { InputContainer, IconContainer, EyeIcon } from "./Styles";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FiLock, FiMail, FiArrowRight } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import vozoicon from "../../Assets/images/Vozo_Icon.png";
import AlertButton from "../StyledComponents/AlertButton";
import moment from "moment";
import axiosInst from "../../Config";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const Login = () => {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [misMatch, setMissMatch] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [PasswordShow, setPasswordShow] = useState(true);
  const [errMsgPass, setErrMsgPass] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [checked, setChecked] = useState(false);
  const [login, setLogin] = useState(false);
  const [error, setError] = useState(false);
  const TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  var valuesArray =
    LoginResponse.roles &&
    LoginResponse.roles.map(function (item) {
      return item.value;
    });

  var valuesString = JSON.stringify(valuesArray);

  var encrypt_permission = Encrypt_Value(valuesString, "vozorpm");

  // Store the JSON string in local storage
  localStorage.setItem("permissions", encrypt_permission);
  let DBHost = "";
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  const rpm_image = finalurl + "/sites/" + DBHost + "/images/rpm_logo.png";

  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//
  const UserNameChange = (e) => {
    setUsername(e.target.value);
    setNameErr(false);
  };

  const UserPassword = (e) => {
    setPassword(e.target.value);
    setMissMatch(false);
  };
  const Onsubmit = async (e) => {
    e.preventDefault();
    let check_encrypted = Encrypt_Value(checked, "vozo");
    localStorage.setItem("auto_login", check_encrypted);
    if (username !== "" && password !== "") {
      dispatch(
        LoginCredentials({
          username: username,
          password: password,
          timezone: TimeZone,
        })
      );
    } else {
      if (password === "") {
        setErrMsgPass("Please Enter the Password");
        setMissMatch(true);
      }
      if (username === "") {
        setErrMsg("Please Enter the Username");
        setNameErr(true);
      }
    }
  };
  const toggleCheckbox = () => {
    setChecked(!checked);
  };
  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start----------------------------------------------------------- //
  useEffect(() => {
    if (LoginResponse.status === "LOGIN_SUCCESS") {
      if (LoginResponse.exp) {
        let exp_enc = LoginResponse.exp
          ? Encrypt_Value(LoginResponse.exp, "vozorpm")
          : null;
        localStorage.setItem("exp", exp_enc);
      }
      let expiring_date = LoginResponse.exp
        ? Encrypt_Value(LoginResponse.expiring_date, "vozorpm")
        : null;
      localStorage.setItem("pass_exp_modal", true);
      localStorage.setItem("expiring_date", expiring_date);
      let token_enc = LoginResponse.access_token;
      let refresh_token_enc = LoginResponse.refresh_token;
      let expires_in_enc = moment()
        .add(LoginResponse.expires_in, "seconds")
        .format("YYYY-MM-DD HH:mm A");
      let firstDot = window.location.hostname.split(".");
      if (
        firstDot[0] === "localhost" ||
        firstDot[0] === "mumbairpm" ||
        firstDot[0] === "production"
      ) {
        localStorage.setItem("DBHost", Encrypt_Value("default", "vozorpm"));
      } else {
        localStorage.setItem("DBHost", Encrypt_Value(firstDot[0], "vozorpm"));
      }
      localStorage.setItem("token", token_enc);
      axiosInst.defaults.headers.common["Authorization"] = `${token_enc}`;
      localStorage.setItem("login_time", expires_in_enc);
      localStorage.setItem("refresh_token", refresh_token_enc);
      localStorage.setItem("idle", LoginResponse.idle);
      localStorage.setItem(
        "MinimumPasswordLength",
        LoginResponse.min_pass_length
      );
      let expires_on = LoginResponse.expires_on
        ? Encrypt_Value(LoginResponse.expires_on, "vozorpm")
        : null;
      localStorage.setItem("expires_on", expires_on);
      setLogin(true);
      setNameErr(false);
      setMissMatch(false);
      setTimeout(() => {
        Navigate("/dashboard");
      }, 1000);
    } else if (LoginResponse === "LOGIN_FAILURE") {
      setError(true);
    }
  }, [LoginResponse]);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <Container
      width="100%"
      // backgroundColor="#F5F5F5"
      padding="1px"
      height="100%"
    >
      <ImageContainer textAlign="center">
        <Image
          loading="lazy"
          src={rpm_image}
          height="80px"
          width="173px"
          objectFit="contain"
        />
      </ImageContainer>

      <BodyContainer
        backgroundColor="#FFFFFF"
        marginRight="auto"
        marginLeft="auto"
        padding="40px"
        borderRadius="16px"
        maxWidth="483px"
      >
        <PageTitle
          textAlign="center"
          fontFamily="'Open Sans',sans-serif"
          fontWeight="500"
          fontSize="24px"
          lineHeight="28px"
          color="#2D3748"
        >
          Welcome Back
        </PageTitle>
        <PageSubTitle
          textAlign="center"
          fontFamily="'Inter',sans-serif"
          fontWeight="400"
          fontSize="16px"
          lineHeight="19px"
          color="#718096"
          marginTop="8px"
          marginBottom="32px"
        >
          Login to RPM
        </PageSubTitle>
        <Form onSubmit={Onsubmit}>
          <FormGroup marginBottom="20px" display="grid">
            <FormLabel
              marginBottom="7px"
              color="#7D8592"
              fontWeight="500"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="'Open Sans',sans-serif"
            >
              Username
            </FormLabel>
            <InputContainer>
              <IconContainer>
                <FiMail />
              </IconContainer>
              <Input
                placeholder="Enter your username"
                color="#7D8592"
                background="#ffffff"
                border="1px solid #d8e0f0"
                boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                borderRadius="4px"
                height="44px"
                padding="0px 18px 0px 40px"
                fontSize="14px"
                lineHeight="17px"
                width="100%"
                onChange={(e) => UserNameChange(e)}
                value={username}
              />
            </InputContainer>
            {nameErr && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter,sans-serif"
                marginLeft="3px"
              >
                {errMsg}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup marginBottom="20px" display="grid">
            <FormLabel
              marginBottom="7px"
              color="#7D8592"
              fontWeight="500"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="'Open Sans',sans-serif"
            >
              Password
            </FormLabel>
            <InputContainer>
              <IconContainer>
                <FiLock />
              </IconContainer>
              <Input
                type={PasswordShow ? "password" : "text"}
                placeholder="Enter password"
                color="#7D8592 !important"
                background="#ffffff"
                border="1px solid #d8e0f0"
                boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                borderRadius="4px"
                height="44px"
                padding="0px 18px 0px 40px"
                fontSize="14px"
                lineHeight="17px"
                width="100%"
                value={password}
                onChange={(e) => UserPassword(e)}
                autoComplete="off"
              />
              <EyeIcon>
                {PasswordShow ? (
                  <FaRegEye
                    onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                  />
                ) : (
                  <FaRegEyeSlash
                    onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                  />
                )}
              </EyeIcon>
            </InputContainer>
            {misMatch && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="'inter',sans-serif"
                marginLeft="3px"
              >
                {errMsgPass}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup
            textAlign="right"
            justifyContent="flex-end"
            marginBottom="20px"
            display="grid"
            float="left"
          >
            <Div display="flex" alignItems="center">
              <HiddenCheckbox
                id="remember"
                checked={checked}
                onChange={toggleCheckbox}
                position="absolute"
                opacity="0"
                cursor="pointer"
              />
              <StyledCheckbox
                checked={checked}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="20px"
                height="20px"
                border="2px solid rgba(46, 46, 46, 0.25)"
                borderRadius="3px"
                backgroundColor="#FFFFFF"
              >
                {checked && (
                  <CheckIcon
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#3f8cff"
                    strokeWidth="2px"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </CheckIcon>
                )}
              </StyledCheckbox>
              <FormLabel
                fontFamily="'Open Sans',sans-serif"
                fontWeight="400"
                fontSize="16px"
                lineHeight="22px"
                color="#7D8592"
                marginLeft="8px"
                htmlFor="remember"
              >
                Remember me
              </FormLabel>
            </Div>
          </FormGroup>
          <FormGroup
            textAlign="right"
            justifyContent="flex-end"
            marginBottom="20px"
            display="flex"
            float="right"
          >
            <LinK
              to="/ForgetPassword"
              fontSize="16px"
              lineheight="22px"
              fontFamily="'Open Sans',sans-serif"
              fontWeight="400"
              color="#7D8592"
            >
              Forgot Password?
            </LinK>
          </FormGroup>
          <FormGroup marginBottom="20px" display="block">
            <Button
              background="#2C7BE5"
              width="100%"
              fontWeight="700"
              fontSize="16px"
              lineHeight="22px"
              height="48px"
              cursor="pointer"
              color="#FFFFFF"
              borderRadius="4px"
              border="none"
              svgfontSize="20px"
              svgPosition="relative"
              svgTop="5px"
              svgLeft="10px"
              hoverBackColor="#005FB2"
              activeBackgroundColor="rgba(44, 123, 229, 0.75)"
            >
              Login
              <FiArrowRight />
            </Button>
          </FormGroup>
        </Form>
        {login ? (
          <AlertButton variant="success">Login Successfully</AlertButton>
        ) : (
          error && (
            <AlertButton variant="error">
              Username or Password Incorrect
            </AlertButton>
          )
        )}
      </BodyContainer>
    </Container>
  );
};
export default memo(Login);
