import styled from "styled-components";
import { AiOutlineRight } from "react-icons/ai";
import { HiLockClosed } from "react-icons/hi";
import { Div } from "../StyledComponents";
export const GridDiv = styled.div`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.JustifyContent && p.JustifyContent};
  flex: ${(p) => p.flex && p.flex};
  height: ${(p) => p.height && p.height};
  ${(p) =>
    p.gridTop &&
    `
    @media only screen and (min-width: 2560px) {
      display : grid;
      grid-template-areas:
      "Overview LiveDashboard LiveDashboard"
      "VitalSummary VitalSummary TreatmentPlan" 
      "MyPatients MyPatients MyPatients";
      grid-template-columns: 1fr 1fr 1fr ;
      grid-template-rows:370px 370px 370px;
      column-gap: 24px;
      row-gap:24px;

      }
      @media only screen and (max-width: 2560px) {
        display : grid;
        grid-template-areas:
        "Overview LiveDashboard LiveDashboard"
        "VitalSummary VitalSummary TreatmentPlan" 
        "MyPatients MyPatients MyPatients";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows:max-content max-content max-content;
        column-gap: 24px;
        row-gap:24px;

        }
        @media only screen and (max-width: 2080px) {
          display : grid;
          grid-template-areas:
          "Overview LiveDashboard LiveDashboard"
          "VitalSummary VitalSummary TreatmentPlan" 
          "MyPatients MyPatients MyPatients";
          grid-template-columns: 1fr 1fr 2fr;
          grid-template-rows:max-content max-content max-content;
          column-gap: 24px;
          row-gap:24px;
          }
          @media only screen and (max-width: 1800px) {
            display : grid;
            grid-template-areas:
            "Overview LiveDashboard LiveDashboard LiveDashboard"
            "VitalSummary VitalSummary TreatmentPlan TreatmentPlan" 
            "MyPatients MyPatients MyPatients MyPatients";
            grid-template-columns:1fr 1fr 1fr 1fr;            
            grid-template-rows:max-content max-content  max-content;
            column-gap: 24px;
            row-gap:24px;
            }
      @media only screen and (max-width: 1500px) {
        display : grid;
        grid-template-areas:
        "Overview LiveDashboard LiveDashboard LiveDashboard"
        "VitalSummary VitalSummary TreatmentPlan TreatmentPlan" 
        "MyPatients MyPatients MyPatients MyPatients";
   grid-template-columns: 1fr 1fr 1fr 2fr;
   grid-template-rows:max-content max-content max-content max-content;
        column-gap: 24px;
        row-gap:24px;
        }
        @media only screen and (max-width: 1400px) {
          display : grid;
          grid-template-areas:
          "Overview TreatmentPlan TreatmentPlan "
          "LiveDashboard LiveDashboard LiveDashboard " 
          "VitalSummary VitalSummary VitalSummary "
          "MyPatients MyPatients MyPatients ";
     grid-template-columns: 1fr 1fr 1fr ;
     grid-template-rows:max-content max-content max-content ;
          column-gap: 24px;
          row-gap:24px;
          }
      
          @media only screen and (max-width: 1200px) {
        display : grid;
        grid-template-areas:
        "Overview"
        "LiveDashboard" 
        "VitalSummary" 
        "TreatmentPlan"
        "MyPatients";
        grid-template-columns:calc(100vw - 153px);
        grid-template-rows:max-content;
        column-gap: 24px;
        row-gap:24px;
       }
    
    `}
`;

export const Canvas = styled.canvas``;
export const Arrow = styled(AiOutlineRight)`
  color: rgba(44, 123, 229, 1);
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: 12px;
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const Tooltip = styled.span`
  cursor: pointer;
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  &:hover .TooltipText {
    opacity: 1;
    visibility: visible;
  }
`;
export const TooltipText = styled.span`
  position: absolute;
  left: 7%;
  top: 80%;
  word-break: break-word;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 1002;
  background-color: #f5f5f5;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const OverViewDiv = styled(Div)`
  margin-top: 16px;
  @media (max-width: 820px) {
    margin-top: 20px;
    width: 100%;
    float: unset;
  }
`;

export const OverViewDivBlock = styled(Div)`
  @media (max-width: 820px) {
    display: block;
  }
`;

export const LockIcon = styled(HiLockClosed)`
  width: 24px;
  height: 24px;
  margin-bottom: 16px;
  margin-right: 8px;
  color: #2c7be5;
`;
