import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Div,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Input,
  PageTitle,
  ImageContainer,
} from "../../StyledComponents";
import { ExpPageSubtitle } from "../Styles";
import key from "../../../Assets/images/key.png";
import { EyeIcon, InputContainer, ForgotDiv } from "../Styles";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { ArrowBack } from "../../TopNav/Chat/styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  GetNewPswd,
  GetResetVerify,
} from "../../../StateManagement/Reducers/PreLoginState";
const SetNewPassWord = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  var user_id = params.id;
  const minimum = params.len;
  const { state } = useLocation();
  const [PasswordShow, setPasswordShow] = useState(true);
  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [passErr1, setPassErr1] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [misMatch, setMissMatch] = useState(false);
  const [redirect, setReDirect] = useState(false);
  const [allow, setAllow] = useState("allowed");
  const [resetId, setResetId] = useState("");
  const [disBtn, setDisBtn] = useState(false);

  const emailResponse = useSelector((state) => state.PreLogin.NewPswd);

  const verifyReset = useSelector((state) => state.PreLogin.ResetVerify);

  let DBHost = "";
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "mumbairpm" ||
    firstDot[0] === "production"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  const rpm_image = finalurl + "/sites/" + DBHost + "/images/rpm_logo.png";
  const UserPassword = (e) => {
    setMissMatch(false);
    setPassErr1(false);
    setPassword(e.target.value);
    var strongRegex = new RegExp(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${minimum},})`
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr1(false);
    } else {
      setPassErr1(true);
      setErrMsg(
        `passWord must contain Atleast  1 upperlower 1number 1 symbol and minimum ${minimum} characters length `
      );
    }
  };
  const UserPassword1 = (e) => {
    setPassword1(e.target.value);

    var strongRegex = new RegExp(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${minimum},})`
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
    } else {
      setMissMatch(true);
      setErrMsg(
        `passWord must contain Atleast  1 upperlower 1number 1 symbol and minimum ${minimum} characters length `
      );
    }
  };

  const Onsubmit = (e) => {
    e.preventDefault();
    setDisBtn(true);
    if (passErr1 || misMatch) {
      setDisBtn(false);
      return false;
    }
    if (password === "" || password1 === "") {
      setMissMatch(true);
      setErrMsg("Password does't empty");
      setDisBtn(false);
      return false;
    }

    if (password === password1) {
      dispatch(
        GetNewPswd({
          type: {
            password: password,
            user: user_id,
            reset_id: resetId,
          },
        })
      );
      setReDirect(true);
    } else {
      setMissMatch(true);
      setErrMsg("PassWord Mismatched");
      setReDirect(false);
      setDisBtn(false);
    }
  };

  const handleBacktoLogin = () => {
    Navigate("/");
  };

  useEffect(() => {
    dispatch(
      GetResetVerify({
        type: {
          code: params.code,
          user_id: user_id,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (redirect) {
      if (emailResponse !== "") {
        if (
          emailResponse ===
          "The password you entered must not be an old password"
        ) {
          setMissMatch(true);
          setErrMsg("The password you entered must not be an old password");
          setDisBtn(false);
        } else {
          if (emailResponse["username"]) {
            Navigate("/reset", {
              state: {
                user_name: emailResponse["username"],
                password: password,
              },
            });
          }
        }
      }
    }
  }, [emailResponse]);

  useEffect(() => {
    if (verifyReset[1] === "reset allowed") {
      setAllow("allowed");
      setResetId(verifyReset[0]);
    } else if (
      verifyReset[1] === "reset disallowed" ||
      verifyReset[1] === "code invalid"
    ) {
      setAllow("disallowed");
      setResetId(0);
    }
  }, [verifyReset]);
  return (
    <>
      <Container backgroundColor="#F5F5F5">
        <ImageContainer textAlign="center" backgroundColor="#ffff">
          <Image
            loading="lazy"
            src={rpm_image}
            height="80px"
            width="173px"
            objectFit="contain"
            marginTop="72px"
            marginBottom="10px"
            textAlign="center"
          />
        </ImageContainer>
        <ForgotDiv
          className="adjust-forgot"
          position="absolute"
          maxWidth="483px"
          top="0"
          right="0"
          bottom="0"
          left="0"
          height="max-content"
          backgroundColor="#ffff"
          borderRadius="16px"
          boxShadow="0px 6px 58px rgba(196, 203, 214, 0.103611)"
          padding="40px 40px 45px 40px"
          margin="150px auto"
        >
          {allow === "allowed" ? (
            <>
              <Div
                width="100%"
                display="flex"
                justifyContent="center"
                padding="0px 0px 16px 0px"
              >
                <Div
                  backgroundColor="#c9deff"
                  border=" 6.5px solid #ecf3ff"
                  width="80px"
                  height="80px"
                  display="flex"
                  justifyContent="center"
                  borderRadius="50%"
                  alignItems="center"
                >
                  <Image src={key} alt="key" />
                </Div>
              </Div>
              <Div>
                <PageTitle
                  textAlign="center"
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="400"
                  fontSize="24px"
                  lineHeight="28px"
                  color="#2D3748"
                >
                  Set new Password
                </PageTitle>
                <ExpPageSubtitle
                  textAlign="center"
                  fontFamily="'Open Sans', sans-serif"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="19px"
                  color="#718096"
                  marginTop="8px"
                  marginBottom="32px"
                  padding="0px 30px"
                >
                  Your new password must be different to previously user
                  passwords.
                </ExpPageSubtitle>
              </Div>
              <Form onSubmit={Onsubmit}>
                <FormGroup marginBottom="20px" display="grid">
                  <FormLabel
                    marginBottom="7px"
                    color="#7D8592"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="24px"
                    fontFamily="'Work Sans', sans-serif"
                  >
                    New Password
                  </FormLabel>
                  <InputContainer>
                    <Input
                      type={PasswordShow ? "password" : "text"}
                      placeholder="Enter password"
                      color="#7D8592 !important"
                      background="#ffffff"
                      border="1px solid #d8e0f0"
                      boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                      borderRadius="4px"
                      height="44px"
                      padding="0px 18px 0px 10px"
                      fontSize="14px"
                      lineHeight="17px"
                      width="100%"
                      value={password}
                      onChange={(e) => UserPassword(e)}
                    />
                    <EyeIcon>
                      {PasswordShow ? (
                        <FaRegEye
                          onClick={() =>
                            setPasswordShow((Prevstate) => !Prevstate)
                          }
                        />
                      ) : (
                        <FaRegEyeSlash
                          onClick={() =>
                            setPasswordShow((Prevstate) => !Prevstate)
                          }
                        />
                      )}
                    </EyeIcon>
                  </InputContainer>
                  {passErr1 && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>
                <FormGroup marginBottom="20px" display="grid">
                  <FormLabel
                    marginBottom="7px"
                    color="#7D8592"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="24px"
                    fontFamily="'Work Sans', sans-serif"
                  >
                    Confirm Password
                  </FormLabel>
                  <InputContainer>
                    <Input
                      type={PasswordShow1 ? "password" : "text"}
                      placeholder="Enter password"
                      color="#7D8592 !important"
                      background="#ffffff"
                      border="1px solid #d8e0f0"
                      boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                      borderRadius="4px"
                      height="44px"
                      padding="0px 18px 0px 10px"
                      fontSize="14px"
                      lineHeight="17px"
                      width="100%"
                      value={password1}
                      onChange={(e) => UserPassword1(e)}
                    />
                    <EyeIcon>
                      {PasswordShow1 ? (
                        <FaRegEye
                          onClick={() =>
                            setPasswordShow1((Prevstate) => !Prevstate)
                          }
                        />
                      ) : (
                        <FaRegEyeSlash
                          onClick={() =>
                            setPasswordShow1((Prevstate) => !Prevstate)
                          }
                        />
                      )}
                    </EyeIcon>
                  </InputContainer>
                  {misMatch && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter, sans-serif"
                      marginLeft="3px"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>

                <FormGroup marginBottom="20px" display="grid">
                  <Button
                    type="submit"
                    background="#2C7BE5"
                    height="38px"
                    fontFamily="'Open Sans', sans-serif"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="22px"
                    cursor="pointer"
                    color="#FFFFFF"
                    borderRadius="4px"
                    border="none"
                    disabled={disBtn}
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  >
                    Reset Password
                  </Button>
                </FormGroup>
              </Form>
            </>
          ) : (
            <>
              <ExpPageSubtitle
                textAlign="center"
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="20px"
                lineHeight="19px"
                color="#cc0000"
                marginTop="8px"
                marginBottom="32px"
                flex="flex"
                alignItems="center"
                height="150px"
                justifyContent="center"
              >
                <IoCloseSharp className="expire_svg" />
                Your Password Link has Expired
              </ExpPageSubtitle>
              <ExpPageSubtitle
                textAlign="center"
                align="center"
                fontFamily="'Open Sans',sans-serif"
                color="#3F8CFF"
                fontWeight="400"
                fontSize="16px"
                lineHeight="22px"
                backgroundColor="#FFFFFF"
                height="48px"
                marginTop="23px"
                onClick={handleBacktoLogin}
                cursor="pointer"
              >
                <ArrowBack
                  marginRight="7px !important"
                  marginBottom="-4px !important"
                />
                Back to login
              </ExpPageSubtitle>
            </>
          )}
        </ForgotDiv>
      </Container>
    </>
  );
};

export default SetNewPassWord;
