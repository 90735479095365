import styled from "styled-components";
import { TableBodyCell } from "../StyledComponents";
import { DeviceContainer } from "./MultiSelect/styles";

export const Tabs = styled.div`
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => (p.fontSize ? p.fontSize : " 18px")};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin: ${(p) => p.margin && p.margin};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-style: normal;
  color: ${(props) => (props.isActive ? " #407bff" : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === 1) {
      return `
              &::after {
                        content: "";
                        width: 45px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(0px);
                        transition: transform 1s;
                        
                        
                         }
               `;
    }

    if (props.line === 2) {
      return `
              &::after {
                        content: "";
                        width: 62px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(70px);
                        transition: transform 1s;
                        // @media (max-width: 900px) {
                        //   transform : translateX(123px);
                        //   width: 76px;
                        // }
                        // @media (max-width: 750px) {
                        //   transform : translateX(123px);
                        //   width: 76px;
                        // }
                         }
               `;
    }
    if (props.line === 3) {
      return `
              &::after {
                        content: "";
                        width: 77px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;                        
                        transform : translateX(160px);
                        transition: transform 1s;
                         }
               `;
    }
    if (props.line === 4) {
      return `
              &::after {
                        content: "";
                        width: 103px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(265px);
                        transition: transform 1s;
                         }
               `;
    }
    if (props.line === 5) {
      return `
              &::after {
                        content: "";
                        width: 72px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(394px);
                        transition: transform 1s;
                         }
               `;
    }
    if (props.line === 6) {
      return `
              &::after {
                        content: "";
                        width: 61px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(493px);
                        transition: transform 1s;
                         }
               `;
    }
  }}
`;

export const TableBodyCellDev = styled(TableBodyCell)`
  overflow-x: auto;
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: lightgray;
    height: 2px;
  }
`;

export const DeviceContainerAdd = styled(DeviceContainer)`
  @media (min-width: 781px) and (max-width: 870px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
