import styled from "styled-components";

export const Div = styled.div`
  display: ${(p) => p.dis && p.dis};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  min-height: ${(p) => p.minheight && p.minheight};
  max-height: ${(p) => p.maxheight && p.maxheight};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.radius && p.radius};
  outline: none;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
`;

export const Header = styled.div`
  height: 43px; /* Adjust the height as per your requirements */
  border-bottom: 1px solid rgba(46, 46, 46, 0.25);
  padding: 10px 0px 8px 15px;
  background: #fff; /* Adjust the background color as per your requirements */
`;

export const Title = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #2e2e2e;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
`;

export const DataDiv = styled.div`
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  position: ${(p) => p.position && p.position};
  border-bottom: ${(p) => p.bb && p.bb};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.ml && p.ml};
  margin-top: ${(p) => p.mt && p.mt};
  margin-right: ${(p) => p.mr && p.mr};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  overflow: ${(p) => p.overflow && p.overflow};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.br && p.br};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  text-align: ${(p) => p.textAlign && p.textAlign};
  min-width: ${(p) => p.minWidth && p.minWidth};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  aspect-ratio: ${(p) => p.aspectRatio && p.aspectRatio};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const Data = styled(DataDiv)`
  width: ${(p) => p.width && p.width};
  // max-height: 418px;
  border: ${({ drop }) => (drop ? "dotted 2px #8DA3F0" : "")};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Row = styled(Div)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  cursor: ${(p) => p.cursor && p.cursor};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  top: ${(p) => p.top && p.top};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  ${({ flex }) =>
    flex &&
    ` display : flex;
  align-items: center;
  justify-content: center;`};

  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
`;

export const AvatarLetter = styled.span`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  padding-right: ${(p) => p.pr && p.pr};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const Text = styled.p`
  margin-bottom: 0px !important;
  align-items: center;
  letter-spacing: 0.0025em;
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  padding-top: ${(p) => p.pt && p.pt};
  width: ${(p) => p.width && p.width};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  height: ${(p) => p.height && p.height};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 60px; /* Adjust the top position as per your requirements */
  right: 20px; /* Adjust the right position as per your requirements */
  width: 371px;
  height: 500px;
  background: #fff;
  border: 1px solid rgba(46, 46, 46, 0.25);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border-radius: 8px;
  z-index: 100;
`;
