import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Modclosebtn,
  ButtonGroup,
  Div,
  Input,
  Span,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownOptions,
  DropDownDiv,
  Text,
  ErrorMessage,
} from "../StyledComponents";

import { FaTimes } from "react-icons/fa";
import { ModalDialog } from "../StyledComponents/AddCard/Styles";
import { AiOutlineSearch } from "react-icons/ai";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import { useDispatch, useSelector } from "react-redux";
import { getPatientSearchList } from "../../StateManagement/Reducers/PatientState";

import { SearchIcon } from "./styles";
import {
  GetPlanforPat,
  SetPlanforPat,
} from "../../StateManagement/Reducers/TreatmentPlanState";

function AssignTreatmentPlan({ show, setOpen, planId }) {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [modalMsg, setModalMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [patValidation, setPatValidation] = useState(false);
  const [insertDataVal, setInsertDataVal] = useState("");
  const [patId, setPatId] = useState();
  const dispatch = useDispatch();
  const InsertData = useSelector(
    (state) => state.TreatmentPlan.InsertPlanToPatient
  );
  const patientList = useSelector((state) => state.Patient.patientlist);
  const searchDropdown = useRef(null);
  const handleClickOutside = (event) => {
    // if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //   setProviderDropdownOpen(false);
    // }
  };

  const handleInputChange = (e) => {
    setPatValidation(false);
    const { value } = e.target;

    setLoading(true);
    setSearchTerm(value);
    if (value !== "") {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  };
  const handleOptionClick = (pid, fname, lname) => {
    setPatId(pid);
    setSearchTerm(fname + " " + lname);
    setDropdownVisible(false);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const AddTreatPlanToPatient = (e) => {
    setInsertDataVal("");
    if (searchTerm === "") {
      setPatValidation(true);
      return;
    } else {
      dispatch(
        GetPlanforPat({
          planId: planId,
          pid: patId,
          searchKey_provider: "",
          searchKey_plan: "",
          page: "",
          pageSize: "",
        })
      );
    }
  };
  useEffect(() => {
    if (InsertData !== "") {
      if (InsertData && InsertData === "success") {
        setShowAlert(true);
        setModalMsg("Assigned successfully to the Patient");
        setStatus("success");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
          setOpen(false);
        }, 1500);
      }
      dispatch(SetPlanforPat(""));
    }
  }, [InsertData]);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (searchTerm !== "") {
          dispatch(getPatientSearchList({ search_key: searchTerm }));
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm !== "") setList(patientList);
  }, [patientList]);

  // useEffect(() => {
  //   if (!show) {
  //     setList([]);
  //   }
  // }, [show]);

  useEffect(() => {
    if (patientList.length >= 0) {
      setLoading(false);
    }
  }, [patientList]);

  // useEffect(() => {
  //   setInsertDataVal(InsertData);
  // }, [InsertData]);

  // const getInsertVal = () => {
  //   setInsertDataVal(InsertData);
  // };

  // ------------------------------ useEffect End----------------------------------------------------------- //

  return (
    <>
      <Div>
        <Modal
          id="modal"
          show={show}
          justifyContent="center"
          style={{
            width: screenSize.width * 1.0,
            height: screenSize.height * 1.0,
          }}
          onClick={(e) => {
            if (e.target.id.includes("modal")) {
              setOpen(false);
            }
          }}
        >
          <ModalContainer
            position="relative"
            justifyContent="center"
            borderRadius="8px"
            overflowX="revert"
            overflowY="revert"
          >
            <ModalDialog width="460px">
              <div id="modaldialog">
                <ModalHeader padding="26px 27px 16px 29px">
                  <ModalTitle
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600px"
                    fontSize="16px"
                    lineHight="22px"
                    color="#2E2E2E"
                    fontStyle="SemiBold"
                  >
                    Add Treatment Plan
                  </ModalTitle>
                  <Modclosebtn onClick={() => setOpen(false)}>
                    <FaTimes />
                  </Modclosebtn>
                </ModalHeader>
                <ModalBody
                  padding="20px 20px 20px 24px"
                  //ref={modalRef}
                  overflowY="auto"
                  style={{
                    maxHeight: "450px",
                    // overflowY: "auto",
                  }}
                >
                  <Div position="relative" width="100%">
                    <SearchIcon>
                      <AiOutlineSearch
                        color="rgba(46, 46, 46, 0.75)"
                        fontSize="18px"
                      />
                    </SearchIcon>

                    <Input
                      type="search"
                      name="search"
                      placeholder="Search Patient"
                      padding="10px"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      width="100%"
                      paddingLeft="32px"
                      color={
                        searchTerm ? "rgb(0, 0, 0)" : "rgba(46, 46, 46, 0.75)"
                      }
                      fontFamily="Open Sans, sans-serif"
                      fontWeight="400"
                      fontSize="14px"
                      position="absolute"
                      value={searchTerm}
                      onChange={handleInputChange}
                      autoComplete
                      className={searchTerm ? "hide-icon" : ""}
                    />

                    {isDropdownVisible && (loading || list.length >= 0) ? (
                      <div ref={searchDropdown}>
                        <DropDownDiv
                          width="100%"
                          background="#FFFFFF"
                          border="1px solid rgba(46, 46, 46, 0.25)"
                          borderRadius="4px"
                          top="35px"
                          maxHeight="256px"
                          overflowY="auto"
                        >
                          {loading ? (
                            <Text padding="10px 12px 8px 12px" fontSize="15px">
                              Loading...
                            </Text>
                          ) : patientList.filter(
                              (option) => option.enrollment_status === "3"
                            ).length > 0 ? (
                            patientList
                              .filter(
                                (option) => option.enrollment_status === "3"
                              )
                              .map((option) => (
                                <DropdownOptions
                                  key={option.id}
                                  padding="10px 12px 8px 12px"
                                  fontSize="15px"
                                  fontFamily="sans seriff"
                                  onClick={() =>
                                    handleOptionClick(
                                      option.pid,
                                      option.fname,
                                      option.lname
                                    )
                                  }
                                >
                                  {option.fname} {option.lname}
                                </DropdownOptions>
                              ))
                          ) : (
                            <Text padding="10px 12px 8px 12px" fontSize="15px">
                              No results found
                            </Text>
                          )}
                        </DropDownDiv>
                      </div>
                    ) : null}
                  </Div>
                  {patValidation && (
                    <ErrorMessage top="37%">
                      Select the Patient name to Assign the Plan
                    </ErrorMessage>
                  )}
                </ModalBody>
              </div>

              <ModalFooter padding="1rem 25px">
                <ButtonGroup
                  fontSize="14px"
                  fontWeight="600"
                  borderRadius="4px"
                  border="1px solid #2C7BE5"
                  padding="9px 27px 8px 28px"
                  margin="0 16px 0 0px"
                  background="#FFF"
                  color="#2C7BE5"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                  cursor="pointer"
                  onClick={() => setOpen(false)}
                >
                  <Span fontSize="14px" fontWeight="600">
                    Cancel
                  </Span>
                </ButtonGroup>
                <ButtonGroup
                  borderRadius="4px"
                  border="1px solid #2C7BE5"
                  padding="9px 34px 8px 34px"
                  background="#2C7BE5"
                  color="#FFFFFF"
                  hoverBackColor="#005FB2"
                  activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  onClick={AddTreatPlanToPatient}
                  cursor="pointer"
                >
                  <Span
                    fontSize="14px"
                    fontWeight="600"
                    fontFamily="'Open Sans', sans-serif"
                  >
                    Add Plan
                  </Span>
                </ButtonGroup>
              </ModalFooter>
            </ModalDialog>
          </ModalContainer>
        </Modal>
        {showAlert && (
          <AlertModal show={showAlert} status={status} msg={modalMsg} />
        )}
      </Div>
    </>
  );
}

export default AssignTreatmentPlan;
