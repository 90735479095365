import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalContainer,
  Input,
  Div,
} from "../../../StyledComponents/index";
import { DialpadContainer, DialpadButton } from "./styles";
import {
  ModalDialog,
  ModalTitle,
} from "../../../StyledComponents/AddCard/Styles";
import { BsXLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import axios from "axios";
import AlertModal from "../../../StyledComponents/AlertModal/AlertModal";

function Twiliodialpad(props) {
  //------------------------------------------State and Variables Start--------------------------------------//
  const Twilio = window.Twilio;
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const phonesplit = SingPatDetails.phone_cell
    ? SingPatDetails.phone_cell.split("-")
    : "";
  const [phoneNumber, setPhoneNumber] = useState(
    phonesplit["1"] === undefined
      ? phonesplit["0"]
      : phonesplit["0"] + phonesplit["1"] || ""
  );
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);
  const [token, setToken] = useState(null);
  const [callStatus, setCallStatus] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);

  //------------------------------------------State and Variables end--------------------------------------//
  //------------------------------------------Functions Start------------------------------------------------//

  const handleBackspace = () => {
    const inputElement = document.getElementById("phoneNumberInput");
    const cursorPosition = inputElement ? inputElement.selectionStart : 0;

    if (cursorPosition === 0) {
      return;
    }
    inputRef.current.focus();
    const updatedNumber =
      phoneNumber.slice(0, cursorPosition - 1) +
      phoneNumber.slice(cursorPosition);

    setPhoneNumber(updatedNumber);
    setCursorPosition(cursorPosition - 1);
  };
  const handleButtonClick = (value, event) => {
    const inputElement = document.getElementById("phoneNumberInput");
    const cursorPosition = inputElement ? inputElement.selectionStart : 0;
    inputRef.current.focus();
    setPhoneNumber((prev) => {
      let updatedNumber;
      if (cursorPosition === prev.length) {
        updatedNumber = prev + value;
      } else {
        updatedNumber =
          prev.slice(0, cursorPosition) + value + prev.slice(cursorPosition);
      }

      setCursorPosition(cursorPosition + 1);
      inputElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      return updatedNumber;
    });
  };
  const handleDial = () => {
    if (phoneNumber !== "" && phoneNumber) {
      if (callStatus !== "connected") {
        if (Twilio && token && token !== "") {
          // Ensure Twilio and token are available
          var params = {
            To: phoneNumber,
          };
          Twilio.Device.connect(params, {
            // Make sure to pass the phone number as a string
          });
          Twilio.Device.connect((conn) => {
            props.initiateInsertion();
          });
          Twilio.Device.error((error) => {
            console.error("Twilio.Device Error:", error.message);
            setCallStatus("error");
          });
        } else {
          console.error("Twilio or token not available.");
        }
      } else if (callStatus === "connected") {
        hangup();
      }
    } else {
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
      }, 2000);
    }
  };
  const hangup = () => {
    if (Twilio && token && token !== "") {
      Twilio.Device.disconnectAll();
      setCallStatus("idle");
    }
  };

  const handleClose = () => {
    if (callStatus !== "connected") {
      props.close();
    }
  };
  const handleContainerClick = (event) => {
    event.stopPropagation();
  };
  //------------------------------------------Functions End------------------------------------------------//
  //------------------------------------------useEffect Start------------------------------------------------//

  useEffect(() => {
    if (SingPatDetails.phone_cell && SingPatDetails.phone_cell.length > 0) {
      setCursorPosition(SingPatDetails.phone_cell.length);
    }
  }, [SingPatDetails.phone_cell]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = cursorPosition;
      inputRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);
  useEffect(() => {
    // Ensure Twilio library is available
    Twilio.Device.debug = true;
    if (window.Twilio) {
      axios
        .get("https://ceil-olm-2765.twil.io/capability-token")
        .then((res) => {
          setToken(res.data.token);

          // Initialize Twilio Device with the token

          const device = Twilio.Device.setup(res.data.token);
          device.ready(() => {
            console.log("Twilio Device is ready");
          });

          device.connect((conn) => {
            setCallStatus("connected");
            const startTime = new Date();

            conn.on("disconnect", () => {
              const endTime = new Date();
              const durationInSeconds = Math.floor(
                (endTime - startTime) / 1000
              );

              console.log("Call duration:", durationInSeconds, "seconds");
              props.setDuration(durationInSeconds);
            });
          });

          device.disconnect((conn) => {
            setCallStatus("idle");
          });

          device.error((error) => {
            setCallStatus("error");
            console.error("Twilio Device error:", error);
          });
        })
        .catch((error) => {
          console.error("Error fetching capability token:", error);
          // Handle error gracefully
        });
    } else {
      console.error("Twilio library not found.");
      // Handle case where Twilio library is not available
    }
  }, []);

  //------------------------------------------useEffect End------------------------------------------------//

  return (
    <>
      <Modal
        show={true}
        justifyContent="center"
        width="100%"
        height="100%"
        onClick={handleClose}
      >
        <ModalContainer borderRadius="8px" onClick={handleContainerClick}>
          <ModalDialog width="360px">
            <ModalHeader
              padding="0px 25px 0px 25px"
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="30px"
                fontSize="16px"
                fontWeight="600"
                color="#2E2E2E"
              >
                Twilio
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#2E2E2E",
                  cursor: "pointer",
                  height: "24px",
                  width: "24px",
                  background: "rgba(46, 46, 46, 0.1)",
                  padding: "6px",
                  borderRadius: "50%",
                }}
                id="dbrd_ad-crd_cls"
                onClick={handleClose}
              />
            </ModalHeader>

            <ModalBody>
              <Div display="flex" justifyContent="center" alignItems="center">
                <Input
                  id="phoneNumberInput"
                  width="296px"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  marginTop="10px"
                  paddingLeft="18px"
                  paddingRight="18px"
                  paddingTop="10px"
                  paddingBottom="10px"
                  placeholder="Phone Number"
                  type="text"
                  ref={inputRef}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      e.preventDefault();
                      handleBackspace();
                    }
                  }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                ></Input>
              </Div>
              <DialpadContainer>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                  <DialpadButton
                    key={number}
                    onClick={(e) => handleButtonClick(number, e)}
                  >
                    {number}
                  </DialpadButton>
                ))}
                <DialpadButton onClick={handleBackspace}>⌫</DialpadButton>
                <DialpadButton onClick={(e) => handleButtonClick(0, e)}>
                  0
                </DialpadButton>
                <DialpadButton
                  onClick={handleDial}
                  bgcolor={callStatus === "connected" ? "red" : "green"}
                  color="white"
                >
                  {callStatus === "connected" ? "Hang Up" : "Dial"}
                </DialpadButton>
              </DialpadContainer>
            </ModalBody>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {ModalAlerShow && (
        <AlertModal
          show={ModalAlerShow}
          msg={"Add Valid Phone Number"}
          status={"retry"}
        />
      )}
    </>
  );
}

export default Twiliodialpad;
