import axiosInst from "../../Config";

export const MessageListApi = async (data) =>
  await axiosInst.get(`/vozorpm/appointment/secure/Message?uid=${data}`);

export const MessagePatientSearchApi = async (data) =>
  await axiosInst.get(`/vozorpm/patientsearchportal?search=${data}`);

export const SendMessageApi = async (data) =>
  await axiosInst.post(`/vozorpm/provider/sendMessage`, data);

export const SendImageApi = async (data) =>
  await axiosInst.post(`/vozorpm/patient/sendImage`, data);

export const getSingleMessageApi = async (data) =>
  await axiosInst.post(`/vozorpm/singlepatient/getMessagelist`, data);

export const previewDownloadApi = async (data) =>
  await axiosInst.post(`/vozorpm/PreviewDownloadmessenger`, data);
export const UnreadCountApi = async (credential) =>
  await axiosInst.put(`/vozorpm/patient/setreadmsgs`, credential);
