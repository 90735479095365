import styled from "styled-components";

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tab = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;
  gap: 10px;
  width: auto;
  height: 40px;
  font-size: 14px;
  font-weight: 600;

  &:not(:last-child) {
    border-right: none;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  & path {
    pointer-events: none;
  }
  ${({ active }) =>
    active ? "background : #2C7BE5;" : "background : #FFFFFF;"}
  border: 1px solid rgba(46, 46, 46, 0.25);
  ${({ active }) => (active ? "color : white;" : "color : #2E2E2E;")}
  ${({ alert }) =>
    alert
      ? `& svg{
      display : block;
      height : 20px;
      width : 20px;
      color : red;      
    }`
      : `& svg{
      display : none;
    }`}
`;
export const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none;")}
  ${({ border }) =>
    border
      ? `border : ${border};`
      : "border : 1px solid rgba(46, 46, 46, 0.25);"}
  ${({ borderRadius }) =>
    borderRadius ? `border-radius : ${borderRadius};` : "border-radius : 8px"}
`;
