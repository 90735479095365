import React from "react";
import { Container, Div } from "../StyledComponents";
import LeftNav from "../LeftNav";
import TopNav from "../TopNav";
import LiveSheet from "./LiveSheet";
import AccesDenied from "../AccessDenied";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const Live = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div
        marginLeft="56px"
        backgroundColor="#F1F5F8"
        // className="responsive-right"
      >
        {permissions.includes("live_access") ? (
          <Div padding="16px 24px 24px 20px">
            <LiveSheet />
          </Div>
        ) : (
          <AccesDenied />
        )}
      </Div>
    </Container>
  );
};
export default Live;
