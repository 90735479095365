import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalContainer,
  ModalHeader,
  Button,
  FileInput,
} from "../../StyledComponents";
import {
  Avatar,
  AvatarLetter,
  Row,
  Image,
  Div,
  Data,
  Text,
  SearchInputText,
  DowloadBtn,
  Close,
} from "../../TopNav/Messages/styles";
import {
  Call,
  Video,
  ChatBoxMsg,
  At,
  Attach,
  Send,
  ModalContainerChat,
} from "./style";
import { ChatBox } from "../../TopNav/Chat/styles";
import moment from "moment";
import DocType from "../../../Assets/images/docType.png";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import {
  SendMsgData,
  getMessagerList,
  getPreviewDownload,
  GetStatus,
  clearPreviewDownload,
} from "../../../StateManagement/Reducers/PatientDemoState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import DBHost from "../../../Config/DBHost";

const ChatPopup = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------///
  const hiddenFileInput = React.useRef(null);
  const [imgPre, setImgPre] = useState(false);
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState(null);
  const [ImgUrl, setImgUrl] = useState("");
  const [viewType, setViewType] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [drop, setDrop] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMsg, setModalMsg] = useState("");

  var imageUrl =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/MessageImages/";
  const Test_URL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";
  const User_Profile_URL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";

  const messageEl = useRef(null);
  const inputEl = useRef(null);

  const PreviewDownload = useSelector(
    (state) => state.PatientDemo.previewdownload
  );
  const data = useSelector((state) => state.PatientDemo.MessageList);
  const Status = useSelector((state) => state.PatientDemo.Status);
  // ----------------------------State and Variables ---------------------------------------------------///

  // -------------------------- Functions Start-------------------------------------------------------------//
  const dispatch = useDispatch();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrop(true);
    } else if (e.type === "dragleave") {
      setDrop(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const ext = file.type.split("/")[1];

      switch (ext) {
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "pdf":
          setSelectedFile(file);
          setDrop(false);
          break;
        default:
          setSelectedFile();
          setShowAlert(true);
          setModalMsg("Unsupported file format");
          setStatus("fail");
          setTimeout(() => {
            setShowAlert(false);
            setStatus("");
            setModalMsg("");
          }, 1500);
          setDrop(false);
      }
    }
  };

  const handlePreview = (item) => {
    setImgPre(true);
    setImgUrl(item);
    let getName = item.split("/");
    setFileName(getName[getName.length - 1]);
  };
  const handleDownload = () => {
    dispatch(getPreviewDownload({ file: ImgUrl }));
  };
  const handleContainerClick = (event) => {
    event.stopPropagation();
  };
  const imgPreFls = () => setImgPre(false);
  const filtInputAttach = () => {
    hiddenFileInput.current.value = "";
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const ext = event.target.files[0].type.split("/")[1];
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
      case "pdf":
        setSelectedFile(event.target.files[0]);
        break;
      default:
        setSelectedFile();
        setShowAlert(true);
        setModalMsg("unsupported file format");
        setStatus("fail");
        setTimeout(() => {
          setShowAlert(false);
          setStatus("");
          setModalMsg("");
        }, 1500);
    }
  };
  const valSetfunc = (e) => {
    setValue(e.target.value);
  };

  const handleOnSend = () => {
    const filedata = new FormData();
    let article = {
      message: value,
      ipAddress: "127.0.0.1",
      sender_id: props.Provider_Id,
      sender: "Provider",
      recip_id: props.providerName,
      receive_id: props.pid.toString(),
      status: 1,
      message_type: "text",
    };

    let enc = Encrypt_Value(article, "vozorpm");
    filedata.append("message", enc);

    if (selectedFile) {
      filedata.append("fileupload", selectedFile);
    } else if (value === "") {
      return false;
    }

    dispatch(SendMsgData(filedata));
    setValue("");
    setSelectedFile(null);
  };
  const selFileNull = () => setSelectedFile(null);

  // -------------------------- Functions End-------------------------------------------------------------//

  // -------------------------- useEffect Start-------------------------------------------------------------//
  useEffect(() => {
    if (props.pid !== "") {
      const credential = { Pid: props.pid, Provider_Id: props.Provider_Id };
      dispatch(GetStatus({ Pid: props.pid, mode: "RPMPortal" }));
      dispatch(getMessagerList(credential));
    }
  }, [props.pid]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    setFileName(selectedFile.name);
    var checkType = selectedFile.type.split("/");

    if (checkType[0] === "image") {
      setViewType("image");
    } else {
      setViewType("doc");
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      dispatch(clearPreviewDownload(""));
    }
  }, [PreviewDownload]);

  useEffect(() => {
    messageEl.current.scrollIntoView();
  }, [data]);
  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  // --------------------------useEffect End-------------------------------------------------------------//

  return (
    <>
      <Modal
        show={props.chatShow}
        justifyContent="center"
        cursor="pointer"
        onClick={props.handleCloseChat}
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainerChat
          justifyContent="center"
          width="788px"
          height="643px"
          borderRadius="8px"
          onClick={handleContainerClick}
        >
          <ModalHeader height="68px">
            {props.patpic === null ? (
              <Avatar width="40px" height="40px" margin="17px 16px 11px 26px">
                <AvatarLetter top="12px" left="10px" fontSize="26px">
                  {props.patName.charAt(0).toUpperCase()}
                </AvatarLetter>
              </Avatar>
            ) : (
              <Row
                width="40px"
                height="40px"
                radius="50%"
                margin="17px 16px 11px 26px"
              >
                <Image
                  src={Test_URL + props.patpic}
                  alt="image"
                  loading="lazy"
                  width="40px"
                  height="40px"
                  mr="8px"
                />
              </Row>
            )}

            <Div
              height="45px"
              flexDirection="column"
              alignItems="flex-start"
              marginTop="10px"
            >
              <Text
                fontFamily="'Open Sans',sans-serif"
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                lineHeight=" 22px"
                color="#0A1629"
              >
                {props.patName}
              </Text>
              <Text
                fontFamily="'Open Sans',sans-serif"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
                lineHeight=" 19px"
                color={
                  Status === "Online" ? "#0EAA5F" : "rgba(46, 46, 46, 0.50)"
                }
              >
                {Status}
              </Text>
            </Div>
            <Call />
            <Video />
          </ModalHeader>
          <Data
            height="502px"
            width="100%"
            padding="16px 29px 16px 26px"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            drop={drop}
          >
            <FileInput
              ref={inputEl}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            {data.length !== 0 ? (
              data.map((item, ind) => {
                return (
                  <ChatBox
                    user={item.sender === "Provider" ? true : false}
                    type={item.type}
                    key={ind}
                    marginLeft={
                      (item.type === "image" || item.type === "doc") &&
                      item.sender === "Provider"
                        ? "auto"
                        : ""
                    }
                  >
                    {item.type !== "date" && item.sender !== "Provider" && (
                      <>
                        {props.patpic === null ? (
                          <Avatar width="32px" height="32px">
                            <AvatarLetter top="8px" left="9px">
                              {item.username.charAt(0).toUpperCase()}
                            </AvatarLetter>
                          </Avatar>
                        ) : (
                          <Image
                            src={Test_URL + props.patpic}
                            alt="image-failed"
                            loading="lazy"
                            width="32px"
                            height="32px"
                            mr="8px"
                            borderRadius="40px"
                          />
                        )}
                      </>
                    )}
                    <ChatBoxMsg
                      user={item.sender === "Provider" ? true : false}
                      type={item.type}
                      padding=" 8px 10px 7px 10px"
                      day={
                        item.type === "date" &&
                        moment(item.date).calendar(null, {
                          sameDay: "[Today]",
                          nextDay: "[Tomorrow]",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday]",
                          lastWeek: "dddd, MMMM D",
                          sameElse: "dddd, MMMM D",
                        })
                      }
                    >
                      {item.type === "date" && (
                        <Row>
                          <Text
                            font-family="'Open Sans',sans-serif"
                            font-style=" normal"
                            font-weight=" 600"
                            font-size="14px"
                            line-height="22px"
                          >
                            {moment(item.date).calendar(null, {
                              sameDay: "[Today]",
                              nextDay: "[Tomorrow]",
                              nextWeek: "dddd",
                              lastDay: "[Yesterday]",
                              lastWeek: "dddd, MMMM D",
                              sameElse: "dddd, MMMM D",
                            })}
                          </Text>
                        </Row>
                      )}

                      {item.type === "text" && (
                        <>
                          <Text
                            width="100%"
                            fontFamily="'Open Sans', sans-serif"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="19px"
                            textAlign="left"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            wordBreak="break-word"
                          >
                            {item.message}
                          </Text>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm")}
                          </Text>
                        </>
                      )}
                      {item.type === "image" && (
                        <>
                          <Row dis="flex" flexDirection="column" width="100%">
                            <Image
                              cursor="pointer"
                              width="214px"
                              height="129px"
                              objectFit="contain"
                              display="block"
                              src={item.imageUrl && imageUrl + item.imageUrl}
                              alt=" "
                              onClick={() => {
                                handlePreview(imageUrl + item.imageUrl);
                                setViewType("image");
                              }}
                            />

                            <Text
                              fontFamily="'Open Sans', sans-serif"
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="17px"
                              textAlign="left"
                              // paddingRight="40px"
                              color={
                                item.sender === "Provider"
                                  ? "#EDEDED"
                                  : "#2D3748"
                              }
                              display="inline"
                              width="100%"
                              wordBreak="break-word"
                            >
                              {item.message}
                            </Text>
                          </Row>
                          <Text
                            width="unset"
                            margin="auto 0 0 10px"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                          >
                            {item.date && moment(item.date).format("h:mm ")}
                          </Text>
                        </>
                      )}
                      {item.type === "doc" && (
                        <>
                          <Row dis="flex" flexDirection="column">
                            <Image
                              cursor="pointer"
                              MaxWidth="221px"
                              MaxHeight="137px"
                              objectFit="contain"
                              display="block"
                              src={DocType}
                              alt=" "
                              onClick={() => {
                                handlePreview(imageUrl + item.imageUrl);
                                setViewType("doc");
                              }}
                            />
                            <Text
                              fontFamily="'Open Sans', sans-serif"
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="17px"
                              color={
                                item.sender === "Provider"
                                  ? "#EDEDED"
                                  : "#2D3748"
                              }
                              display="inline"
                              width="100%"
                              textAlign="left"
                              wordBreak="break-word"
                            >
                              {item.message}
                            </Text>
                          </Row>
                          <Text
                            width="unset"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="12px"
                            color={
                              item.sender === "Provider" ? "#EDEDED" : "#2D3748"
                            }
                            display="inline"
                            textAlign="right"
                            verticalAlign="sub"
                            margin="auto 0 0 10px"
                          >
                            {item.date && moment(item.date).format("h:mm ")}
                          </Text>
                        </>
                      )}
                    </ChatBoxMsg>
                    {item.type !== "date" && item.sender === "Provider" && (
                      <>
                        {props.profileimage === null ||
                        props.profileimage === "" ? (
                          <Avatar width="32px" height="32px" minWidth="32px">
                            <AvatarLetter top="7px" left="9px">
                              {props.providerName.charAt(0).toUpperCase()}
                            </AvatarLetter>
                          </Avatar>
                        ) : (
                          <Image
                            src={User_Profile_URL + props.profileimage}
                            alt="image-failed"
                            loading="lazy"
                            borderRadius="40px"
                            height="32px"
                            width="32px"
                            mr="8px"
                          />
                        )}
                      </>
                    )}
                  </ChatBox>
                );
              })
            ) : (
              <Div
                display="flex"
                justifyContent="center"
                fontWeight="500"
                fontSize="14px"
                lineHeight="17px"
                color="#8A969F"
                backColor="unset"
              >
                No Messages....
              </Div>
            )}

            <Row ref={messageEl}></Row>
          </Data>
          {selectedFile && (
            <Row
              position="absolute"
              top="68px"
              width="100%"
              height="100%"
              backgroundColor="rgba(244, 244, 244, 0.9)"
              display="flex"
              flexDirection="column"
              padding="20px"
              radius="4px"
              MaxWidth="788px"
              maxHeight="502px"
            >
              <Row
                dis="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontSize="13px" lineHeight="18px" color="#91929e">
                  {fileName}
                </Text>
                <Close onClick={selFileNull} />
              </Row>
              {viewType === "image" && (
                <Row
                  position="relative"
                  dis="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%"
                  overFlow="hidden"
                >
                  <Image
                    src={preview}
                    width="max-content"
                    height="max-content"
                    MaxHeight="100%"
                    MaxWidth="100%"
                    margin="auto"
                    objectFit="contain"
                  />
                </Row>
              )}
              {viewType === "doc" && (
                <Row
                  position="relative"
                  dis="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%"
                  overFlow="hidden"
                >
                  <Image
                    src={DocType}
                    width="max-content"
                    height="max-content"
                    MaxHeight="350px"
                    MaxWidth="350px"
                    margin="auto"
                  />
                </Row>
              )}
            </Row>
          )}
          {imgPre && (
            <Row
              position="absolute"
              top="68px"
              width="100%"
              height="100%"
              backgroundColor="rgba(244, 244, 244, 0.9)"
              display="flex"
              flexDirection="column"
              padding="20px"
              borderRadius="4px"
              overFlow="hidden"
              maxWidth="788px"
              maxHeight="572px"
            >
              <Row
                dis="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="0 0 8px"
              >
                <Row dis="flex">
                  <Text
                    fontSize="13px"
                    lineHeight="18px"
                    color="#91929e"
                    MaxWidth="50vw"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    {fileName}
                  </Text>
                </Row>
                <Row dis="flex" overflow="hidden">
                  <DowloadBtn onClick={handleDownload} />
                  <Close onClick={imgPreFls} />
                </Row>
              </Row>
              {viewType === "image" && (
                <Row
                  position="relative"
                  dis="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%"
                  overFlow="hidden"
                >
                  <Image
                    src={ImgUrl}
                    objectFit="contain"
                    width="max-content"
                    height="max-content"
                    MaxHeight="100%"
                    MaxWidth="100%"
                  />
                </Row>
              )}
              {viewType === "doc" && (
                <iframe
                  title="img"
                  src={ImgUrl}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  display="block"
                />
              )}
            </Row>
          )}

          {!imgPre && (
            <Row
              dis="flex"
              position="relative"
              margin="0px 33px 16px 34px"
              background="#ffffff"
              border="1px solid #EAEAEA"
              boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
              radius="8px"
              marginLeft="20px"
              display="flex"
              maxHeight="fit-content"
              padding="8px 12px 8px 17px"
              height="56px"
            >
              <SearchInputText
                type="file"
                accept=".pdf,.png,.jpg,.bmp,.jpeg"
                ref={hiddenFileInput}
                onChange={(e) => handleChange(e)}
                display="none"
              />
              <Attach color="#2E2E2E" onClick={filtInputAttach} />
              <At onClick={() => setValue(value + "@")} />

              <SearchInputText
                placeholder="Type your message here…"
                value={value}
                paddingBottom="2px"
                width="100%"
                onChange={(e) => valSetfunc(e)}
                onKeyPress={(e) => e.key === "Enter" && handleOnSend()}
              />
              <Button
                width="56px"
                height="44px"
                borderRadius="14px"
                backgroundColor="#2C7BE5"
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="none"
                cursor="pointer"
                onClick={handleOnSend}
              >
                <Send />
              </Button>
            </Row>
          )}
        </ModalContainerChat>
      </Modal>
      {showAlert && (
        <AlertModal show={showAlert} status={status} msg={modalMsg} />
      )}
    </>
  );
};

export default ChatPopup;
