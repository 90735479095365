import React, { useState } from "react";
import {
  Button,
  Div,
  Modal,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from "../../../../StyledComponents";
import { ModalDialog } from "../../../../StyledComponents/AddCard/Styles";
import { BsXLg } from "react-icons/bs";

const MarsonikModal = (props) => {
  const [ModalAnimat] = useState(true);

  return (
    <>
      <Modal show={props.show} justifyContent="center">
        <ModalContainer justifyContent="center" borderRadius="4px">
          <ModalDialog animation={ModalAnimat} width="462px">
            <ModalHeader
              padding="19px 25px 16px 25px"
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="30px"
                fontSize="16px"
                fontWeight="600"
                color="#2E2E2E"
              >
                Marsonik
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#2E2E2E",
                  cursor: "pointer",
                  height: "24px",
                  width: "24px",
                  background: "rgba(46, 46, 46, 0.1)",
                  padding: "6px",
                  borderRadius: "50%",
                }}
                onClick={() => props.HandleModal()}
                id="dbrd_ad-crd_cls"
              />
            </ModalHeader>
            <ModalBody padding="16px 25px 16px 25px" height="82vh">
              <iframe
                src={props.src}
                Width="100%"
                Height="100%"
                title="marsonik"
              />
            </ModalBody>
            {/* 
            <ModalFooter padding="16px 24px 21px 25px">
              <Div display="flex" justify="flex-end" alignItems="center">
                <Button
                //   display={props.edit ? "block" : "none"}
                  color="#ef5f5f"
                  height="36px"
                  cursor="pointer"
                  width="121px"
                  background="rgba(239,95,95,0.2)"
                  borderRadius="4px"
                  padding="9px 8px"
                  border="0.5px solid #ef5f5f"
                  fontWeight="600"
                //   onClick={() => RemoveCard()}
                //   disabled={btnDisable}
                  id="bil_ad-btn_crdSv"
                >
                  Remove Card
                </Button>

                <Button
                  color="#2C7BE5"
                  cursor="pointer"
                  height="36px"
                  background="#FFFFFF"
                  width="100px"
                  fontWeight="600"
                  marginLeft="auto"
                  borderRadius="4px"
                  border="1px solid #2C7BE5"
                //   onClick={() => HandleClose()}
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                >
                  Cancel
                </Button>
                <Button
                  color="#FFFFFF"
                  height="36px"
                  cursor="pointer"
                  width="100px"
                  background="#2C7BE5"
                  borderRadius="4px"
                  padding="9px 8px"
                  border="none !important"
                  fontWeight="600"
                  marginLeft="16px"
                  //   onClick={() => HandleSave()}
                  //   disabled={btnDisable}
                  //   isDisabled={btnDisable}
                  id="bil_ad-btn_crdSv"
                  hoverBackColor="#005FB2"
                  activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                >
                  Cancel
                </Button>
              </Div>
            </ModalFooter> */}
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default MarsonikModal;
