import React, { useState, useRef, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";

// import { getInsertDocumentslist } from "../../../StateManagement/Reducers/DocumentsState";

import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
  Image,
  Button,
  SpinnerWrapper,
  Spinner,
} from "../index";
import {
  Childdiv,
  ModalDialog,
  DragDrop,
  FileInput,
  Parentdiv,
  ProgressLabel,
} from "./Styles";

import { BsXLg } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import UploadFile from "../../../Assets/images/uploadDoc.png";
import pdfIcon from "../../../Assets/images/EllipsePdf.png";
import {
  GetPatDetails,
  getPatientImport,
  setPatientImport,
} from "../../../StateManagement/Reducers/PatientState";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import Logo from "../../../Assets/images/Logo.png";
import AlertModal from "../AlertModal/AlertModal";

const UploadDocument = ({
  show,
  close,
  column_name,
  sort_order,
  search_key,
  page,
  active,
}) => {
  // ------------------------------ State And Variables Start------------------------ //

  // const dispatch = useDispatch();

  const hiddenFileInput = useRef(null);
  const [ModalAnimat] = useState(true);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const userID = Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
  const Loading = useSelector((state) => state.Patient.LoadImport);
  const ImportedCount = useSelector((state) => state.Patient.patientImport);

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const HandleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size < 10e6) {
      if (fileUploaded.type === "text/csv") {
        setFile(fileUploaded);
      } else {
        window.alert("upload only csv");
      }
    }
  };

  const importData = async () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text.split("\n");
      if (rows[rows.length - 1] === "") {
        rows.pop();
      }
      const headers = rows[0].split(",");
      const parsedRows = [];

      for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split(",").map((value) => value.trim());
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          if (rowData[j] !== undefined)
            obj[headers[j]] = rowData[j].replace(/"/g, "").trim();
        }
        parsedRows.push(obj);
      }
      dispatch(getPatientImport({ data: parsedRows, providerID: userID }));
    };

    reader.readAsText(file);
  };

  const ClearFile = () => {
    setFile(null);
    hiddenFileInput.current.value = "";
  };

  const HandleClose = () => {
    close();
  };

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };
  // ------------------------------Functions End---------------------------- //

  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    console.log(ImportedCount);
    if (ImportedCount > 0) {
      setModalMsg("Some patients email or phone number already exist");
      setStatus("Failed");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
        dispatch(setPatientImport(""));
        HandleClose();
      }, 2000);
    } else if (ImportedCount === "Success") {
      setModalMsg("Patient details are imported");
      setStatus("success");
      setModalAlerShow(true);
      setTimeout(() => {
        setModalAlerShow(false);
        setStatus("");
        setModalMsg("");
        dispatch(setPatientImport(""));
        HandleClose();
      }, 2000);
    }
    dispatch(
      GetPatDetails({
        column_name: column_name,
        sort_order: sort_order,
        search_key: search_key,
        page: page,
        pageSize: 10,
        active: active,
        uid: userID,
      })
    );
  }, [ImportedCount]);

  // ------------------------------UseEffect End-------------------------- //

  return (
    <>
      {Loading ? (
        <SpinnerWrapper>
          <Spinner></Spinner>
          <Image width="40px" height="40px" position="absolute" src={Logo} />
        </SpinnerWrapper>
      ) : (
        <Modal
          show={show}
          display="flex"
          justifyContent="center"
          width="100%"
          height="100%"
          onClick={close}
        >
          <ModalContainer
            justifyContent="center"
            borderRadius="12px"
            onClick={handleContainerClick}
          >
            <ModalDialog animation={ModalAnimat}>
              <Div
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <ModalTitle
                  lineHight="27px"
                  fontSize="20px"
                  fontWeight="600"
                  color="#0a1629"
                >
                  Import CSV
                </ModalTitle>

                <BsXLg
                  style={{
                    color: "#407BFF",
                    cursor: "pointer",
                    height: "14px",
                    width: "14px",
                  }}
                  onClick={HandleClose}
                />
              </Div>
              <DragDrop>
                <FileInput
                  type="file"
                  ref={hiddenFileInput}
                  onChange={HandleChange}
                  style={{
                    paddingBottom: "56%",
                    paddingLeft: "39%",
                    position: "absolute",
                    marginLeft: "-52%",
                    opacity: 0,
                  }}
                  multiple={false}
                  accept=".csv"
                />
                <Text
                  fontSize="16px"
                  lineHeight=" 22px"
                  color="#263446"
                  margin="20px 0 20px 0"
                >
                  Drag &amp; Drop
                </Text>
                <Image
                  loading="lazy"
                  width="45px"
                  margin="0px auto 25px auto"
                  src={UploadFile}
                />
                <Text fontSize="12px" color="#000000" marginBottom="20px">
                  (or)
                </Text>
                <Text fontSize="16px" color="#2C7BE5" marginBottom="20px">
                  Choose File
                </Text>
                <Text fontSize="12px" color="#7d8592" marginBottom="20px">
                  CSV File Smaller than 10mb
                </Text>
              </DragDrop>

              {file && (
                <>
                  <Div
                    display="flex"
                    justifyContent="space-between"
                    margin="20px 0px 8px 0px"
                  ></Div>
                  <Parentdiv>
                    <Childdiv>
                      <Image
                        loading="lazy"
                        width="32px"
                        height="32px"
                        margin="8px 12px"
                        src={pdfIcon}
                      />
                      <ProgressLabel file={file ? true : false}>
                        {file.name}
                      </ProgressLabel>
                    </Childdiv>
                    <AiOutlineCloseCircle
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "20px",
                      }}
                      onClick={ClearFile}
                    />
                  </Parentdiv>
                  {/* ))} */}
                  {/* <Div flexCenterVertical={true} justifyContent="center">
                <Button
                  background="#000000"
                  borderRadius="8px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="16px"
                  color="#ffffff"
                  padding="12px 20px"
                  marginTop="20px"
                  cursor="pointer"
                  onClick={UploadDocument}
                  disabled={btnDisable}
                >
                  Upload File
                </Button>
              </Div> */}
                </>
              )}
              <Div display="flex" justifyContent="end" marginTop="18px">
                <Button
                  backgroundColor="#fff"
                  color="#2c7be5"
                  padding="10px 27px"
                  border="1px solid #2c7eb5"
                  borderRadius="8px"
                  cursor="pointer"
                  marginRight="24px"
                  onClick={() => close()}
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                >
                  Cancel
                </Button>
                {file && (
                  <Button
                    backgroundColor="#2c7be5"
                    padding="10px 32px"
                    border="none"
                    borderRadius="8px"
                    color="#fff"
                    cursor="pointer"
                    onClick={importData}
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                  >
                    Done
                  </Button>
                )}
              </Div>
            </ModalDialog>
          </ModalContainer>
        </Modal>
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default UploadDocument;
