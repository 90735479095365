import React, { useState, useEffect, memo } from "react";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Div,
  Input,
  Span,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropDownDiv,
  DropdownOptions,
  DropdownImage,
  SelectInput,
  RedStar,
  Label,
  SearchIconSVG,
  Text,
} from "../../../StyledComponents";
import { AllergyDropdown, AllergyOption } from "../styles";
import { TextArea } from "../../Encounter/styles";
import { Imagecalendar, Wrapper } from "../../../Popup/styles";
import calendar_icon_custom from "../../../../Assets/images/calendar_icon_custom.png";
import { ErrorMessage } from "../../BillandInsurance/styles";
import { useSelector, useDispatch } from "react-redux";
import { ModalDialog } from "../../../StyledComponents/AddCard/Styles";
import { AiOutlineSearch } from "react-icons/ai";
import CustomDatePicker from "../../../StyledComponents/datepicker/CustomDatePicker";
import { AiOutlineClose } from "react-icons/ai";
import dropdownDownicon from "../../../../Assets/images/dropdownDownicon.png";
import moment from "moment";
import {
  GetMedPost,
  GetMedUpdate,
} from "../../../../StateManagement/Reducers/HealthState";
import { GetDrugList } from "../../../../StateManagement/Reducers/MedicalState";
const AddMedications = (props) => {
  //-----------------------------State and  Variables Start----------------------------------------------- //

  const dispatch = useDispatch();
  const [refills, setrefills] = useState("");
  const [notes, setnotes] = useState("");
  const [sig_note, setsig_note] = useState("");
  const [drug_name, setdrug_name] = useState("");
  const [dquantity, setdquantity] = useState("");
  const [Status, setstatus] = useState("");
  const [OrderStatus, setOrderStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [Statuserr, setStatuserr] = useState(false);
  const [OrderStaerr, setOrderStaerr] = useState(false);
  const [drugerr, setDrugerr] = useState(false);
  const [drumatchgerr, setDrugMatcherr] = useState(false);
  const [dateerr1, setDateerr1] = useState(false);
  const [dateerr2, setDateerr2] = useState(false);
  const [dateerr3, setDateerr3] = useState(false);
  const [dateerr1Check, setDateerr1Check] = useState(false);
  const [dateerr2Check, setDateerr2Check] = useState(false);
  const [dateerr3Check, setDateerr3Check] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ShowOptions2, setShowOptions2] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [meddate, setMedDate] = useState("");
  const [meddateCheck, setMedDateCheck] = useState("");
  const [Dateval2check, setDateval2Check] = useState("");
  const [Dateval3check, setDateval3Check] = useState("");
  const [Dateval3, setDateval3] = useState("");
  const [showpicker, setShowPicker] = useState(false);
  const [ShowPicker1, setShowPicker1] = useState(false);
  const [ShowPicker2, setShowPicker2] = useState(false);
  const [Dateval2, setDateVal2] = useState("");
  const [DatediffErrcheck, setDatediffErrcheck] = useState(false);

  const [validDate, setValidDate] = useState(moment(new Date(), "YYYY-MM-DD"));
  const [validDate1, setValidDate1] = useState(
    moment(new Date(), "YYYY-MM-DD")
  );
  const [validDate2, setValidDate2] = useState(
    moment(new Date(), "YYYY-MM-DD")
  );
  const [dis, setDis] = useState(false);
  const addmedicationdetails = useSelector((state) => state.Health.MedPut);
  const Drugdetails = useSelector((state) => state.Medical.DrugList);
  //-----------------------------State and  Variables End----------------------------------------------- //
  //-----------------------------Function Start--------------------------------------------------------- //
  const handlepicker = (e) => {
    e.preventDefault();

    if (showpicker === false) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
    setShowOptions1(false);
    setShowPicker1(false);
    setShowPicker2(false);
  };
  const handlepicker1 = (e) => {
    e.preventDefault();
    if (ShowPicker1 === false) {
      setShowPicker1(true);
    } else {
      setShowPicker1(false);
    }
    setShowPicker(false);
    setShowPicker2(false);
  };
  const handlepicker2 = (e) => {
    e.preventDefault();
    if (ShowPicker2 === false) {
      setShowPicker2(true);
    } else {
      setShowPicker2(false);
    }
    setShowPicker1(false);
    setShowPicker(false);
  };
  const closePicker = () => {
    setShowPicker(false);
  };
  const closePicker1 = () => {
    setShowPicker1(false);
  };
  const closePicker2 = () => {
    setShowPicker2(false);
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };
  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setMedDate(moment(value).format("YYYY-MM-DD"));
      setMedDateCheck(moment(value).format("YYYY-MM-DD"));
      setDateerr1(false);
      setDateerr1Check(false);
      setValidDate(new Date(value));
    } else {
      setMedDate("");
      setMedDateCheck("");
      setValidDate("");
    }
    closePicker();
  };
  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setDateVal2(moment(value).format("YYYY-MM-DD"));
      setDateval2Check(moment(value).format("YYYY-MM-DD"));
      setDateerr2(false);
      setDateerr2Check(false);
      setDatediffErrcheck(false);
      setValidDate1(new Date(value));
    } else {
      setDateVal2("");
      setDateval2Check("");
      setValidDate("");
    }
    closePicker1();
  };
  const dateSelect2 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setDateval3(moment(value).format("YYYY-MM-DD"));
      setDateval3Check(moment(value).format("YYYY-MM-DD"));
      setDateerr3(false);
      setDateerr3Check(false);
      setDatediffErrcheck(false);
      setValidDate2(new Date(value));
    } else {
      setDateval3("");
      setDateval3Check("");
      setValidDate2("");
    }
    closePicker2();
  };
  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker();
      closePicker1();
      closePicker2();
    }
  };

  const setStatusMediction = (r) => {
    setstatus(r.label);
    setShowOptions1(false);
    if (r.label !== "") {
      setStatuserr(false);
    }
  };
  const setOrderstatus = (r) => {
    setOrderStatus(r.label);
    setShowOptions2(false);
    if (r.label !== "") {
      setOrderStaerr(false);
    }
  };

  const StatusDropdownOptionList1 = [
    { label: "Active", id: 1 },
    { label: "Inactive", id: 2 },
  ];
  const OrderDropdownOptionList2 = [
    { label: "Ordered", id: "1" },
    { label: "Administered through RPM note", id: "2" },
    { label: "Electronic eRx sent", id: "3" },
    { label: "Phoned into Pharmacy", id: "4" },
    { label: "Faxed to Pharmacy", id: "5" },
    { label: "Discontinued", id: "6" },
  ];
  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "status_medi") {
      setShowOptions1(false);
    }
  };
  const handleClick1 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "order_status") {
      setShowOptions2(false);
    }
  };

  const HandleSaveMedi = () => {
    let err = 0;

    if (Status === "") {
      setStatuserr(true);

      err = 2;
    }

    if (OrderStatus === "") {
      setOrderStaerr(true);
      err = 2;
    }

    if (meddate === "") {
      setDateerr1(true);
      err = 2;
    } else if (meddate !== meddateCheck) {
      setDateerr1Check(true);
      err = 2;
    }

    if (Dateval2 === "") {
      setDateerr2(true);
      err = 2;
    } else if (Dateval2 !== Dateval2check) {
      setDateerr2Check(true);
      setDatediffErrcheck(false);
      err = 2;
    }

    if (Dateval2 > Dateval3) {
      setDatediffErrcheck(true);

      err = 2;
    }

    if (Dateval3 === "") {
      setDateerr3(true);
      err = 2;
    } else if (Dateval3 !== Dateval3check) {
      setDateerr3Check(true);
      setDatediffErrcheck(false);

      err = 2;
    }

    if (drug_name === "") {
      setDrugerr(true);
      err = 2;
    } else if (drug_name !== selectedValue) {
      setDrugMatcherr(true);
      err = 2;
    }
    var StatusFinal = 0;

    if (Status === "Active") {
      StatusFinal = 1;
    } else if (Status === "Inactive") {
      StatusFinal = 2;
    } else {
      setStatuserr(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    }
    setDis(true);
    if (props.id === "0") {
      setbtnDisabled(true);
      const article = {
        pid: props.pidvalue,
        type: props.medvalue,
        title: drug_name,
        signote: sig_note,
        activity: StatusFinal,
        Datetimeprescribed: meddate,
        begdate: Dateval2,
        enddate: Dateval3,
        quantity: dquantity,
        refills: refills,
        orderstatus: OrderStatus,
        notes: notes,
        value: props.medvalue,
      };
      dispatch(GetMedPost(article));
    } else {
      setbtnDisabled(true);
      const article = {
        pid: props.pidvalue,
        type: props.medvalue,
        id: props.id,
        title: drug_name,
        signote: sig_note,
        activity: StatusFinal,
        Datetimeprescribed: meddate,
        begdate: Dateval2,
        enddate: Dateval3,
        quantity: dquantity,
        refills: refills,
        orderstatus: OrderStatus,
        notes: notes,
        value: props.medvalue,
      };
      dispatch(GetMedUpdate(article));
    }

    handleClose();
    setTimeout(() => {
      setDis(false);
    }, 1000);
  };

  const handleContainerClick = (e) => {
    if (
      e.target.id === "CustomDatePicker" ||
      e.target.id === "CustomDatePickerbuttons" ||
      e.target.id === "date" ||
      e.target.id === "date1" ||
      e.target.className === "year"
    ) {
    } else {
      closePicker();
      closePicker1();
      closePicker2();
    }
    if (ShowOptions1) {
      setShowOptions1(false);
    }
    if (ShowOptions2) {
      setShowOptions2(false);
    }
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
    e.stopPropagation();
  };

  const refillChange = (e) => {
    const inputValue = e.target.value.trim();
    if (!isNaN(inputValue) && inputValue.length < 6) {
      setrefills(inputValue);
    }
  };
  const dispenseChange = (e) => {
    const inputValue = e.target.value.trim();
    if (!isNaN(inputValue) && inputValue.length < 6) {
      setdquantity(inputValue);
    }
  };
  const handleDrug = (e) => {
    setLoading(true);
    let drugname = e.target.value;

    setdrug_name(e.target.value);
    if (drugname.trim() !== "") {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
    setDrugerr(false);
    setDrugMatcherr(false);
  };

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    setdrug_name(value);
    setDropdownOpen(false);
  };

  const handleClose = () => {
    setbtnDisabled(false);
    setrefills("");
    setnotes("");
    setsig_note("");
    setdrug_name("");
    setdquantity("");
    setstatus("");
    setOrderStatus("");
    setMedDate("");
    setDateval3("");
    setDateVal2("");
    setStatuserr(false);
    setOrderStaerr(false);
    setOrderStaerr(false);
    setDrugerr(false);
    setDrugMatcherr(false);
    setDateerr1(false);
    setDateerr1Check(false);
    setDateerr3Check(false);
    setDatediffErrcheck(false);
    setDateerr2Check(false);
    setDateerr2(false);
    setDateerr3(false);
    setValidDate(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    setValidDate1(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    setValidDate2(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));

    props.close();
  };

  const signoteChange = (e) => setsig_note(e.target.value);
  const noteChange = (e) => setnotes(e.target.value);

  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (Drugdetails[1] !== null) {
      setLoading(false);
    }
  }, [Drugdetails[1]]);

  useEffect(() => {
    document.addEventListener("click", handleCustomCalendar);

    return () => {
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleClick1);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleClick1);
    };
  }, []);

  useEffect(() => {
    if (props.id !== "0") {
      setdrug_name(addmedicationdetails.title);
      setSelectedValue(addmedicationdetails.title);
      setsig_note(addmedicationdetails.sig_note);
      setMedDate(addmedicationdetails.Datetimeprescribed);
      setValidDate(addmedicationdetails.Datetimeprescribed);
      setdquantity(addmedicationdetails.Dispensequantity);
      setrefills(addmedicationdetails.Refills);
      setDateVal2(addmedicationdetails.begdate);
      setValidDate1(addmedicationdetails.begdate);
      setDateval2Check(addmedicationdetails.begdate);
      setDateval3(addmedicationdetails.enddate);
      setValidDate2(addmedicationdetails.enddate);
      setDateval3Check(addmedicationdetails.enddate);

      if (addmedicationdetails.Status === "1") {
        setstatus("Active");
      } else if (addmedicationdetails.Status === "2") {
        setstatus("Inactive");
      } else {
        setstatus("");
      }
      setMedDateCheck(addmedicationdetails.Datetimeprescribed);
      setOrderStatus(addmedicationdetails.order_status);
      setnotes(addmedicationdetails.comments);
    } else {
      setrefills("");
      setnotes("");
      setsig_note("");
      setdrug_name("");
      setdquantity("");
      setstatus("");
      setOrderStatus("");
      setMedDate("");
      setDateval3("");
      setDateVal2("");
      setStatuserr(false);
      setOrderStaerr(false);
      setOrderStaerr(false);
      setDrugerr(false);
      setDrugMatcherr(false);
      setDateerr1(false);
      setDateerr1Check(false);
      setDateerr2Check(false);
      setDateerr3Check(false);
      setDatediffErrcheck(false);
      setDateerr2(false);
      setDateerr3(false);
    }
  }, [addmedicationdetails]);

  useEffect(() => {
    if (props.id !== 0) {
      dispatch(GetDrugList(drug_name));
    }
  }, [drug_name]);

  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        onClick={handleClose}
        overflowY="auto"
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          overflowX="revert"
          overflowY="revert"
          onClick={handleContainerClick}
        >
          <ModalDialog width="374px">
            <ModalHeader padding="17px 0px 18px 24px" height="69px">
              <ModalTitle
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600px"
                fontSize="16px"
                marginTop="13px"
                lineHight="22px"
                color="#2E2E2E"
              >
                {props.id === "0" ? "Add Medication" : "Edit Medication"}
              </ModalTitle>
              <ModalTitle>
                <Button
                  border="none"
                  marginLeft="187px"
                  marginTop="20px"
                  fontSize="0.9em"
                  background="white"
                  cursor="pointer"
                  onClick={handleClose}
                >
                  <AiOutlineClose />
                </Button>
              </ModalTitle>
            </ModalHeader>

            <ModalBody padding="0px 26px 10px 26px">
              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="15px 0px 0px 0px"
                    position="relative"
                  >
                    <Label
                      marginBottom="7px"
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Drug Name<RedStar>*</RedStar>
                    </Label>
                    <SearchIconSVG style={{ top: "37px", left: "13px" }}>
                      <AiOutlineSearch />
                    </SearchIconSVG>

                    <Input
                      padding="0 0 0 10px"
                      width="154px"
                      height="38px"
                      fontSize="14px"
                      placeholder="Find Drug"
                      backgroundColour="#f7f7f7 !important"
                      border="1px solid rgba(34,36,38,.15)"
                      borderRadius="4px"
                      paddingLeft="35px"
                      value={drug_name}
                      onChange={handleDrug}
                      style={
                        drugerr || drumatchgerr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                    />
                    {dropdownOpen && (loading || Drugdetails[1].length >= 0) ? (
                      <AllergyDropdown>
                        {loading ? (
                          <Text padding="10px 12px 8px 12px" fontSize="15px">
                            Loading...
                          </Text>
                        ) : Drugdetails[1].length > 0 ? (
                          Drugdetails[1].map((option) => (
                            <AllergyOption
                              key={option}
                              onClick={() => handleOptionClick(option)}
                            >
                              {option}
                            </AllergyOption>
                          ))
                        ) : (
                          <Text padding="10px 12px 8px 12px" fontSize="15px">
                            No results found
                          </Text>
                        )}
                      </AllergyDropdown>
                    ) : null}

                    {drugerr && (
                      <ErrorMessage>Please Select Drug Name</ErrorMessage>
                    )}

                    {drumatchgerr && (
                      <ErrorMessage>
                        Please Select Any One DropDown
                      </ErrorMessage>
                    )}
                  </Div>
                </Div>
                <Div Div display="flex" flexDirection="column" width="47.5%">
                  <Div padding="0 48px 0 0" margin="16px 0px 0px 0px">
                    <Label
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      SIG Note
                    </Label>
                    <Input
                      width="154px"
                      height="38px"
                      name="sending_to"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      paddingTop="8px"
                      padding="8px"
                      marginTop="7px"
                      margin="8px 0px 0px 0px"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      maxWidth="313px"
                      value={sig_note}
                      onChange={signoteChange}
                    ></Input>
                  </Div>
                </Div>
              </Div>

              <Div padding="0 48px 0 0" margin="15px 0px 0px 0px">
                <Label
                  size="14px"
                  lineHeight="19.07px"
                  weight="400"
                  fontFamily="'Open Sans',sans-serif"
                >
                  Status<RedStar>*</RedStar>
                </Label>
                <SelectInput
                  width="322px"
                  height="38px"
                  name="status_medi"
                  background="#FFFFFF"
                  fontSize="14px"
                  fontFamily="'Open Sans',sans-serif"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  color="#2E2E2E"
                  pl="10px"
                  paddingTop="8px !important"
                  margin="7px 0px 0px 0px"
                  style={
                    Statuserr
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  onClick={() => setShowOptions1(!ShowOptions1)}
                >
                  {Status}
                </SelectInput>
                <DropdownImage
                  height="8px !important"
                  width="12px !important"
                  marginTop="-22px"
                  marginLeft="288px"
                  src={dropdownDownicon}
                  onClick={() => setShowOptions1(!ShowOptions1)}
                ></DropdownImage>
              </Div>
              {ShowOptions1 && StatusDropdownOptionList1.length > 0 ? (
                <DropDownDiv
                  width="322px"
                  height="86px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  marginTop="2px"
                >
                  {StatusDropdownOptionList1.map((r, i) => {
                    return (
                      <DropdownOptions
                        key={i}
                        value={r.label}
                        padding="10px 12px 8px 19px"
                        color="#2E2E2E"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        onClick={() => setStatusMediction(r)}
                      >
                        {" "}
                        {r.label}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
              {Statuserr && (
                <ErrorMessage>Please Select Valid Status</ErrorMessage>
              )}

              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="16px 0px 0px 0px"
                  >
                    <Label
                      style={{ marginBottom: "5px" }}
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Date Prescribed<RedStar>*</RedStar>
                    </Label>
                    <Div position="relative">
                      <CustomDatePicker
                        width="152px"
                        id="CustomDatePicker"
                        height="36px"
                        style={{ paddingLeft: "21px" }}
                        backgroundColour="#f7f7f7 !important"
                        border={
                          dateerr1 || dateerr1Check
                            ? "1px solid red !important"
                            : "1px solid rgba(34,36,38,.15)"
                        }
                        placeholder="yyyy-mm-dd"
                        value={meddate} //used in application
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "YYYY-MM-DD",
                            true
                          ).isValid();
                          setMedDate(e.target.value);
                          setDateerr1(false);
                          setDateerr1Check(false);
                          if (e.target.value === "") {
                            setValidDate("");
                          } else if (d) {
                            setMedDateCheck(e.target.value);
                            let check = false;
                            if (check) {
                              if (validDate !== "") {
                                setMedDate(
                                  moment(validDate).format("YYYY-MM-DD")
                                );
                              }
                            } else {
                              setValidDate(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect} // state updation for picking calendar dates
                        showPicker={showpicker}
                        validDate={validDate} // value in date object for calendar manipulation
                        closePicker={closePicker}
                      ></CustomDatePicker>
                      {dateerr1 && (
                        <ErrorMessage>Please Select Date</ErrorMessage>
                      )}
                      {dateerr1Check && (
                        <ErrorMessage>Please Select Proper Date</ErrorMessage>
                      )}
                      <Imagecalendar
                        top="6px"
                        right="20px"
                        width="20px"
                        height="22px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                  </Div>
                </Div>
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="15px 0px 0px 0px"
                  >
                    <Label
                      style={{ marginBottom: "7px" }}
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Start Date<RedStar>*</RedStar>
                    </Label>
                    <Div position="relative">
                      <CustomDatePicker
                        width="152px"
                        height="36px"
                        backgroundColour="#f7f7f7 !important"
                        padding="0 0 0 10px"
                        id="CustomDatePicker"
                        border={
                          dateerr2 || dateerr2Check || DatediffErrcheck
                            ? "1px solid red !important"
                            : "1px solid rgba(34,36,38,.15)"
                        }
                        borderRadius="4px"
                        placeholder="yyyy-mm-dd"
                        value={Dateval2} //used in application
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "YYYY-MM-DD",
                            true
                          ).isValid();
                          setDateVal2(e.target.value);
                          setDateerr2(false);
                          setDateerr2Check(false);
                          setDatediffErrcheck(false);
                          if (e.target.value === "") {
                            setValidDate1("");
                          } else if (d) {
                            setDateval2Check(e.target.value);

                            let check = false;
                            if (check) {
                              if (validDate1 !== "") {
                                setDateVal2(
                                  moment(validDate1).format("YYYY-MM-DD")
                                );
                              }
                            } else {
                              setValidDate1(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker1(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect1} // state updation for picking calendar dates
                        showPicker={ShowPicker1}
                        validDate={validDate1} // value in date object for calendar manipulation
                        closePicker={closePicker1}
                        max={moment(Dateval3, "YYYY-MM-DD")}
                      />
                      {dateerr2 && (
                        <ErrorMessage>Please Select Date</ErrorMessage>
                      )}
                      {dateerr2Check && (
                        <ErrorMessage>Please Select Proper Date</ErrorMessage>
                      )}
                      {DatediffErrcheck && (
                        <ErrorMessage>
                          Please Start Date Before End Date
                        </ErrorMessage>
                      )}
                      <Imagecalendar
                        top="6px"
                        right="20px"
                        width="20px"
                        height="22px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker1(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                  </Div>
                </Div>
              </Div>

              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="15px 0px 0px 0px"
                  >
                    <Label
                      style={{ marginBottom: "7px" }}
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      End Date<RedStar>*</RedStar>
                    </Label>
                    <Div position="relative">
                      <CustomDatePicker
                        width="152px"
                        id="CustomDatePicker"
                        height="36px"
                        backgroundColour="#f7f7f7 !important"
                        border={
                          dateerr3 || dateerr3Check || DatediffErrcheck
                            ? "1px solid red !important"
                            : "1px solid rgba(34,36,38,.15)"
                        }
                        placeholder="yyyy-mm-dd"
                        value={Dateval3} //used in application
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "YYYY-MM-DD",
                            true
                          ).isValid();
                          setDateval3(e.target.value);
                          setDateerr3(false);
                          setDateerr3Check(false);
                          setDatediffErrcheck(false);
                          if (e.target.value === "") {
                            setValidDate2("");
                          } else if (d) {
                            setDateval3Check(e.target.value);

                            let check = false;
                            if (check) {
                              if (validDate2 !== "") {
                                setDateVal2(
                                  moment(validDate2).format("YYYY-MM-DD")
                                );
                              }
                            } else {
                              setValidDate2(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker2(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect2} // state updation for picking calendar dates
                        showPicker={ShowPicker2}
                        validDate={validDate2} // value in date object for calendar manipulation
                        closePicker={closePicker2}
                        min={moment(Dateval2, "YYYY-MM-DD")}
                      >
                        {" "}
                      </CustomDatePicker>
                      {dateerr3 && (
                        <ErrorMessage>Please Select Date</ErrorMessage>
                      )}
                      {dateerr3Check && (
                        <ErrorMessage>Please Select Proper Date</ErrorMessage>
                      )}
                      {DatediffErrcheck && (
                        <ErrorMessage>
                          Please Start Date Before End Date
                        </ErrorMessage>
                      )}

                      <Imagecalendar
                        top="6px"
                        right="20px"
                        width="20px"
                        height="22px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker2(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                  </Div>
                </Div>
                <Div Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="16px 0px 0px 0px"
                  >
                    <Label
                      marginBottom="-4px"
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Dispensed quantity
                    </Label>
                    <Input
                      width="154px"
                      height="38px"
                      min="0"
                      name="sending_to"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      paddingTop="8px"
                      padding="8px"
                      marginTop="8px"
                      margin="8px 0px 0px 0px"
                      value={dquantity}
                      onChange={dispenseChange}
                    ></Input>
                  </Div>
                </Div>
              </Div>

              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="19px 0px 0px 0px"
                  >
                    <Label
                      style={{ marginBottom: "5px" }}
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Number of Refills
                    </Label>

                    <Input
                      padding="0 0 0 8px"
                      width="154px"
                      height="38px"
                      min="0"
                      backgroundColour="#f7f7f7 !important"
                      border="1px solid rgba(34,36,38,.15)"
                      borderRadius="4px"
                      position="relative"
                      value={refills}
                      onChange={refillChange}
                    />
                  </Div>
                </Div>
                <Div Div display="flex" flexDirection="column" width="47.5%">
                  <Div padding="0 48px 0 0" margin="16px 0px 0px 0px">
                    <Label
                      size="14px"
                      lineHeight="19.07px"
                      weight="400"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Order Status<RedStar>*</RedStar>
                    </Label>
                    <SelectInput
                      width="154px"
                      height="38px"
                      name="order_status"
                      background="#FFFFFF"
                      fontSize="14px"
                      fontFamily="'Open Sans',sans-serif"
                      border="1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      color="#2E2E2E"
                      pl="10px"
                      display="flex"
                      alignItems="center"
                      margin="8px 0px 0px 0px"
                      style={
                        OrderStaerr
                          ? { border: "1px solid red", borderRadius: "5px" }
                          : {}
                      }
                      onClick={() => setShowOptions2(!ShowOptions2)}
                    >
                      <Wrapper maxWidth="120px">{OrderStatus}</Wrapper>
                    </SelectInput>
                    <DropdownImage
                      height="8px !important"
                      width="12px !important"
                      marginTop="-22px"
                      marginLeft="127px"
                      src={dropdownDownicon}
                      onClick={() => setShowOptions2(!ShowOptions2)}
                    ></DropdownImage>
                  </Div>
                  {ShowOptions2 && OrderDropdownOptionList2.length > 0 ? (
                    <DropDownDiv
                      width="230px"
                      height="204px"
                      background="#FFFFFF"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      marginTop="83px"
                      fontSize="14px"
                      fontWeight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                      marginLeft="-77px !important"
                    >
                      {OrderDropdownOptionList2.map((r, i) => {
                        return (
                          <DropdownOptions
                            key={i}
                            value={r.value}
                            padding="10px 12px 8px 12px"
                            fontSize="14px"
                            lineHeight="normal"
                            fontWeight="400"
                            color="#2E2E2E"
                            fontFamily="'Open Sans',sans-serif"
                            onClick={() => setOrderstatus(r)}
                          >
                            {" "}
                            {r.label}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  ) : (
                    ""
                  )}
                  {OrderStaerr && (
                    <ErrorMessage>
                      Please Select Valid Order Status
                    </ErrorMessage>
                  )}
                </Div>
              </Div>

              <Div padding="0 48px 0 0" margin="16px 0px 0px 0px">
                <Label
                  size="15px"
                  lineHeight="19.07px"
                  weight="400"
                  fontFamily="'Open Sans',sans-serif"
                >
                  Notes
                </Label>
                <TextArea
                  style={{ width: "322px", height: "80px" }}
                  background="#FFFFFF"
                  fontSize="14px"
                  family="'Open Sans',sans-serif"
                  border="1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  color="#2E2E2E"
                  pl="10px"
                  marginTop="8px"
                  margin="8px 0px 0px 0px"
                  value={notes}
                  onChange={noteChange}
                ></TextArea>
              </Div>
            </ModalBody>

            <ModalFooter padding="1rem 23px" style={{ height: "69px" }}>
              <Button
                fontSize="14px"
                fontWeight="600"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                background="#FFF"
                color="#2C7BE5"
                marginRight="14px"
                cursor="pointer"
                onClick={handleClose}
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
              >
                <Span fontSize="14px" fontWeight="600">
                  Close
                </Span>
              </Button>
              <Button
                type="submit"
                borderRadius="4px"
                border="1px solid #2C7BE5"
                padding="9px 27px 8px 28px"
                background="#2C7BE5"
                color="#FFFFFF"
                marginRight="16px"
                cursor="pointer"
                onClick={() => HandleSaveMedi()}
                disabled={btnDisabled || dis}
                isDisabled={dis}
                hoverBackColor="#005FB2"
                activeBackgroundColor="rgba(44, 123, 229, 0.75)"
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Save
                </Span>
              </Button>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default memo(AddMedications);
