import React, { useEffect, useState } from "react";
import {
  Div,
  PageSubTitle,
  Text,
  Image,
  SpanTag,
  SelectInput,
  DropdownImage,
  DropdownOptions,
  DropDownDiv,
} from "../../StyledComponents";
import { Tabs, Tab, Content } from "./styles.js";
import Line from "./Line";
import { IoIosAlert } from "react-icons/io";
import Emp_data_pic from "../../../Assets/images/readings_empty_pic.png";
import { useDispatch, useSelector } from "react-redux";
import { getReadingsData } from "../../../StateManagement/Reducers/PatientDemoState";
import dropdownDownicon from "../../../Assets/images/dropdownDownicon.png";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt/index.js";
import AccessDenied from "../../AccessDenied/index.js";

const ReadingsData = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//

  const [active, setActive] = React.useState("0");
  let pid = window.location.href.split("/").pop();
  const dispatch = useDispatch();

  const selectOptions = [
    {
      value: "week",
      label: "Week",
    },
    {
      value: "month",
      label: "Month",
    },
  ];
  const [selectedOption, setSelected] = useState("Month");
  const [axisFormat, setAxisFormat] = useState("select");
  const readingsData = useSelector((state) => state.PatientDemo.readingsData);
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowDropdown, setShowDropdown] = useState(false);
  const SingPatDetails = useSelector((state) => state.Patient.SinglePatient);
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");

  const [dates, setDate] = useState([]);

  const [ChartLabel, setChartLabel] = useState([
    "May 1",
    "May 2",
    "May 3",
    "May 4",
    "May 5",
    "May 6",
    "May 7",
    "May 8",
    "May 9",
    "May 10",
    "May 11",
    "May 12",
    "May 13",
    "May 14",
    "May 15",
    "May 16",
    "May 17",
  ]);

  // ========== Heart Rate chart datas start ============ //

  const [HeartRateData, setHeartRateData] = useState([]);

  const HrCondition = (ctx) =>
    ctx.p1.parsed.y > 100 || ctx.p1.parsed.y < 60
      ? ctx.p0.parsed.y < 100 || ctx.p0.parsed.y > 60
        ? "#F24822"
        : "#2C7BE5"
      : ctx.p0.parsed.y > 100 || ctx.p0.parsed.y < 60
      ? "#2C7BE5"
      : "#2C7BE5";

  const hrPointStyle = (ctx) =>
    ctx.parsed.y < 60 || ctx.parsed.y > 100 ? "#F24822" : "#2C7BE5";

  const hrLabel = (ctx) =>
    ctx.parsed.y < 60 || ctx.parsed.y > 100
      ? `Abnormal Reading: ${ctx.parsed.y}`
      : `Normal Reading: ${ctx.parsed.y}`;

  const hrps = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 60 || ctx.parsed.y > 100
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";

  const setHeart = (data) => {
    setHeartData([
      {
        label: "Normal Reading",
        data: data,
        fill: false,
        borderColor: (ctx) => hrps(ctx),
        pointBackgroundColor: (ctx) => hrPointStyle(ctx),
        segment: {
          borderColor: (ctx) => HrCondition(ctx),
        },
      },
      {
        label: "Abnormal Reading",
        fill: false,
        borderColor: "#F24822",
        pointBackgroundColor: "#F24822",
      },
    ]);
  };

  const [HrDataSet, setHeartData] = useState([
    {
      label: "Normal Reading",
      data: HeartRateData,
      fill: false,
      borderColor: "#2C7BE5",
      pointBackgroundColor: (ctx) => hrPointStyle(ctx),
      segment: {
        borderColor: (ctx) => HrCondition(ctx),
      },
    },
    {
      label: "Abnormal Reading",
      fill: false,
      borderColor: "#F24822",
      pointBackgroundColor: "#F24822",
    },
  ]);

  // ========== Heart Rate chart datas end ============ //

  // ========== Blood Pressure chart datas start ============ //

  const [BloodPressData, setBloodPressData] = useState([]);

  const BpCondition1 = (ctx) =>
    ctx.p1.parsed.y >= 90 && ctx.p1.parsed.y <= 120 ? "#C8EDF9" : "#F24822";
  const BpCondition2 = (ctx) =>
    ctx.p1.parsed.y >= 60 && ctx.p1.parsed.y <= 80 ? "#2C7BE5" : "#F24822";
  const bpLabel = (ctx) => {
    const systolicLabel =
      ctx.parsed.y < 90 || ctx.parsed.y > 120
        ? `Systolic Pressure(Abnormal): ${ctx.parsed.y}`
        : `Systolic Pressure(Normal): ${ctx.parsed.y}`;

    const diastolicLabel =
      ctx.parsed.y < 60 || ctx.parsed.y > 80
        ? `Diastolic Pressure(Abnormal): ${ctx.parsed.y}`
        : `Diastolic Pressure(Normal): ${ctx.parsed.y}`;
    return ctx.dataset.chartName === "systolic"
      ? systolicLabel
      : diastolicLabel;
  };

  const bpPointStyle1 = (ctx) =>
    ctx.parsed.y < 90 || ctx.parsed.y > 120 ? "#F24822" : "#C8EDF9";

  const bpPointStyle2 = (ctx) =>
    ctx.parsed.y < 60 || ctx.parsed.y > 80 ? "#F24822" : "#2C7BE5";

  const bpBorderStyle1 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 90 || ctx.parsed.y > 120
        ? "#F24822"
        : "#C8EDF9"
      : "#C8EDF9";
  const bpBorderStyle2 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 60 || ctx.parsed.y > 80
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";
  const setBloodPressure = (data) => {
    setBpDataSet([
      {
        label: (ctx) => bpLabel(ctx),
        data: data[0],
        fill: false,
        borderColor: (ctx) => bpBorderStyle1(ctx),
        chartName: "systolic",
        pointBackgroundColor: (ctx) => bpPointStyle1(ctx),
        segment: {
          borderColor: (ctx) => BpCondition1(ctx),
        },
      },
      {
        label: (ctx) => bpLabel(ctx),
        data: data[1],
        fill: false,
        borderColor: (ctx) => bpBorderStyle2(ctx),
        chartName: "diastolic",
        pointBackgroundColor: (ctx) => bpPointStyle2(ctx),
        segment: {
          borderColor: (ctx) => BpCondition2(ctx),
        },
      },
      {
        label: "Abnormal Reading",
        fill: false,
        borderColor: "#F24822",
        pointBackgroundColor: "#F24822",
      },
    ]);
  };

  const [BpDataSet, setBpDataSet] = useState([
    {
      label: "Systolic Pressure",
      data: BloodPressData.length > 0 ? BloodPressData[0] : [],
      fill: false,
      borderColor: "#C8EDF9",
      pointBackgroundColor: "#C8EDF9",
      segment: {
        borderColor: (ctx) => BpCondition1(ctx),
      },
    },
    {
      label: "Diastolic Pressure",
      data: BloodPressData[1],
      fill: false,
      borderColor: "#2C7BE5",
      pointBackgroundColor: "#2C7BE5",
      segment: {
        borderColor: (ctx) => BpCondition2(ctx),
      },
    },
    {
      label: "Abnormal Reading",
      fill: false,
      borderColor: "#F24822",
      pointBackgroundColor: "#F24822",
    },
  ]);

  // ========== Blood Pressure chart datas end ============ //

  // ========== Oxygen chart datas Start ============ //

  const OxyCondition = (ctx) =>
    ctx.p1.parsed.y <= 100 && ctx.p1.parsed.y >= 95 ? "#2C7BE5" : "#F24822";

  const oxyPointStyle = (ctx) =>
    ctx.parsed.y > 100 || ctx.parsed.y < 95 ? "#F24822" : "#2C7BE5";
  const oxyBorderStyle = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y > 100 || ctx.parsed.y < 95
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";

  const oxyLabel = (ctx) =>
    ctx.parsed.y > 100 || ctx.parsed.y < 95
      ? `Abnormal Reading: ${ctx.parsed.y}`
      : `Normal Reading: ${ctx.parsed.y}`;
  const [OxygenData, setOxygenData] = useState([]);

  const setOxygeData = (data) => {
    setOxygenDataSet([
      {
        label: (ctx) => oxyLabel(ctx),
        data: data,
        fill: false,
        borderColor: (ctx) => oxyBorderStyle(ctx),
        pointBackgroundColor: (ctx) => oxyPointStyle(ctx),
        segment: {
          borderColor: (ctx) => OxyCondition(ctx),
        },
      },
      {
        label: "Abnormal Reading",
        fill: false,
        borderColor: "#F24822",
        pointBackgroundColor: "#F24822",
      },
    ]);
  };

  const [OxyDataSet, setOxygenDataSet] = useState([
    {
      label: "Normal Reading",
      data: OxygenData,
      fill: false,
      borderColor: "#2C7BE5",
      pointBackgroundColor: "#2C7BE5",
      segment: {
        borderColor: (ctx) => OxyCondition(ctx),
      },
    },
    {
      label: "Abnormal Reading",
      fill: false,
      borderColor: "#F24822",
      pointBackgroundColor: "#F24822",
    },
  ]);

  // ========== Oxygen chart datas end ============ //

  // ========== Glucose chart datas Start ============ //

  const [GluData, setGluData] = useState([]);

  const fastingCondition = (ctx) =>
    ctx.p1.parsed.y >= 80 && ctx.p1.parsed.y <= 100 ? "#C8EDF9" : "#F24822";

  const afterMealCondition = (ctx) =>
    ctx.p1.parsed.y >= 170 && ctx.p1.parsed.y <= 200 ? "#2C7BE5" : "#F24822";

  const twoHoursCondition = (ctx) =>
    ctx.p1.parsed.y >= 120 && ctx.p1.parsed.y <= 140 ? "#FFD233" : "#F24822";

  const GluBorderStyle2 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 170 || ctx.parsed.y > 200
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";

  const GluBorderStyle3 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 120 || ctx.parsed.y > 140
        ? "#F24822"
        : "#FFD233"
      : "#FFD233";

  const GluLabel = (ctx) => {
    const fastingLabel =
      ctx.parsed.y >= 80 && ctx.parsed.y <= 100
        ? `Blood Glucose (Fasting) - Normal Reading: ${ctx.parsed.y}`
        : `Blood Glucose (Fasting) - Abnormal Reading: ${ctx.parsed.y}`;

    const afterMealLabel =
      ctx.parsed.y >= 170 && ctx.parsed.y <= 200
        ? `Blood Glucose (After Meal) - Normal Reading: ${ctx.parsed.y}`
        : `Blood Glucose (After Meal) - Abnormal Reading: ${ctx.parsed.y}`;

    const twoHoursLabel =
      ctx.parsed.y >= 120 && ctx.parsed.y <= 140
        ? `Blood Glucose (2-3 hrs After Meal) - Normal Reading: ${ctx.parsed.y}`
        : `Blood Glucose (2-3 hrs After Meal) - Abnormal Reading: ${ctx.parsed.y}`;

    if (ctx.dataset.chartName === "fasting") {
      return fastingLabel;
    } else if (ctx.dataset.chartName === "afterMeal") {
      return afterMealLabel;
    } else {
      return twoHoursLabel;
    }
  };
  const fastingCondition1 = (ctx) =>
    ctx.parsed.y > 100 || ctx.parsed.y < 80 ? "#F24822" : "#C8EDF9";

  const hrps1 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y > 100 || ctx.parsed.y < 80
        ? "#F24822"
        : "#C8EDF9"
      : "#C8EDF9";

  const twoHoursCondition1 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 120 || ctx.parsed.y > 140
        ? "#F24822"
        : "#FFD233"
      : "#FFD233";

  const afterMealCondition1 = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 170 || ctx.parsed.y > 200
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";

  const setGlucoseDataset = (data) => {
    setGlucoseDataSet([
      {
        label: (ctx) => GluLabel(ctx),
        data: data[0],
        fill: false,
        borderColor: (ctx) => hrps1(ctx),
        pointBackgroundColor: (ctx) => fastingCondition1(ctx),
        chartName: "fasting",
        segment: {
          borderColor: (ctx) => fastingCondition(ctx),
        },
      },
      {
        label: (ctx) => GluLabel(ctx),
        data: data[1],
        fill: false,
        pointBackgroundColor: (ctx) => afterMealCondition1(ctx),
        chartName: "afterMeal",
        borderColor: (ctx) => GluBorderStyle2(ctx),
        segment: {
          borderColor: (ctx) => afterMealCondition(ctx),
        },
      },
      {
        label: (ctx) => GluLabel(ctx),
        data: data[2],
        fill: false,
        pointBackgroundColor: (ctx) => twoHoursCondition1(ctx),
        chartName: "twoHours",
        borderColor: (ctx) => GluBorderStyle3(ctx),
        segment: {
          borderColor: (ctx) => twoHoursCondition(ctx),
        },
      },
      {
        label: "Abnormal Reading",
        fill: false,
        borderColor: "#F24822",
        pointBackgroundColor: "#F24822",
      },
    ]);
  };

  const [GluDataSet, setGlucoseDataSet] = useState([
    {
      label: "Blood Glucose (Fasting)",
      data: GluData[0],
      fill: false,
      borderColor: "#C8EDF9",
      pointBackgroundColor: "#C8EDF9",
      segment: {
        borderColor: (ctx) => fastingCondition(ctx),
      },
    },
    {
      label: "Blood Glucose (After Meal)",
      data: GluData[1],
      fill: false,
      borderColor: "#2C7BE5",
      pointBackgroundColor: "#2C7BE5",
      segment: {
        borderColor: (ctx) => afterMealCondition(ctx),
      },
    },
    {
      label: "Blood Glucose (2-3 hrs After Meal)",
      data: GluData[2],
      fill: false,
      borderColor: "#FFD233",
      pointBackgroundColor: "#FFD233",
      segment: {
        borderColor: (ctx) => twoHoursCondition(ctx),
      },
    },
    {
      label: "Abnormal Reading",
      fill: false,
      borderColor: "#F24822",
      pointBackgroundColor: "#F24822",
    },
  ]);

  // ========== Glucose chart datas End ============ //

  // ========== Respiration chart datas start ============ //
  const [RespirateData, setRespirateData] = useState([]);

  const setResDtset = (data) => {
    setResDataset([
      {
        label: (ctx) => respRateLabel(ctx),
        data: data,
        fill: false,
        borderColor: (ctx) => respBorderStyle(ctx),
        pointBackgroundColor: (ctx) => respPointStyle(ctx),
        segment: {
          borderColor: (ctx) => RespirateCondition(ctx),
        },
      },
      {
        label: "Abnormal Reading",
        fill: false,
        borderColor: "#F24822",
        pointBackgroundColor: "#F24822",
      },
    ]);
  };

  const [ResDataSet, setResDataset] = useState([
    {
      label: "Normal Reading",
      data: RespirateData,
      fill: false,
      borderColor: "#2C7BE5",
      pointBackgroundColor: "#2C7BE5",
      segment: {
        borderColor: (ctx) => RespirateCondition(ctx),
      },
    },
    {
      label: "Abnormal Reading",
      fill: false,
      borderColor: "#F24822",
      pointBackgroundColor: "#F24822",
    },
  ]);

  const respRateLabel = (ctx) =>
    ctx.parsed.y > 20 || ctx.parsed.y < 12
      ? `Abnormal Reading: ${ctx.parsed.y}`
      : `Normal Reading: ${ctx.parsed.y}`;

  const respBorderStyle = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 12 || ctx.parsed.y > 20
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";

  const respPointStyle = (ctx) =>
    ctx.parsed.y < 12 || ctx.parsed.y > 20 ? "#F24822" : "#2C7BE5";

  const RespirateCondition = (ctx) =>
    ctx.p1.parsed.y >= 12 && ctx.p1.parsed.y <= 20 ? "#2C7BE5" : "#F24822";

  // ========== Respiration chart datas end ============ //

  // ========== weight chart datas start ============ //

  const [weightData, setWeightData] = useState([]);

  const weightLabel = (ctx) =>
    ctx.parsed.y > 80 || ctx.parsed.y < 30
      ? `Abnormal Reading: ${ctx.parsed.y}`
      : `Normal Reading: ${ctx.parsed.y}`;

  const weightBorderStyle = (ctx) =>
    ctx.parsed
      ? ctx.parsed.y < 30 || ctx.parsed.y > 80
        ? "#F24822"
        : "#2C7BE5"
      : "#2C7BE5";

  const weightPointStyle = (ctx) =>
    ctx.parsed.y < 30 || ctx.parsed.y > 80 ? "#F24822" : "#2C7BE5";

  const setWeightDtset = (data) => {
    setWeightDataset([
      {
        label: (ctx) => weightLabel(ctx),
        data: data,
        fill: false,
        borderColor: (ctx) => weightBorderStyle(ctx),
        pointBackgroundColor: (ctx) => weightPointStyle(ctx),
        segment: {
          borderColor: (ctx) => WeightCondition(ctx),
        },
      },
      {
        label: "Abnormal Reading",
        fill: false,
        borderColor: "#F24822",
        pointBackgroundColor: "#F24822",
      },
    ]);
  };
  const [weightDataSet, setWeightDataset] = useState([
    {
      label: "Normal Reading",
      data: weightData,
      fill: false,
      borderColor: "#2C7BE5",
      pointBackgroundColor: "#2C7BE5",
      segment: {
        borderColor: (ctx) => WeightCondition(ctx),
      },
    },
    {
      label: "Abnormal Reading",
      fill: false,
      borderColor: "#F24822",
      pointBackgroundColor: "#F24822",
    },
  ]);

  const WeightCondition = (ctx) =>
    ctx.p1.parsed.y < 80 ? "#2C7BE5" : "#F24822";

  // ========== weight chart datas end ============ //

  const HRAxis = {
    max: 240,
    min: 40,
    step: 40,
  };

  const BpAxis = {
    max: 200,
    min: 50,
    step: 30,
  };

  const oxygenAxis = {
    max: 100,
    min: 50,
    step: 10,
  };

  const GlucoseAxis = {
    max: 400,
    min: 0,
    step: 80,
  };

  const RespiratoryAxis = {
    max: 24,
    min: 0,
    step: 6,
  };

  const weightAxis = {
    max: 240,
    min: 40,
    step: 40,
  };

  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------Functions Start ------------------------------------------------------------//

  const selectView = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShowOptions(!ShowOptions);
    }
  };

  const openAxisOption = () => {
    if (SingPatDetails.enrollment_status !== "4") {
      setShowDropdown(!ShowDropdown);
    }
  };

  const chartAxisSelect = (e) => {
    let value = e.target.getAttribute("data-option");
    let text = e.target.getAttribute("data-value");
    if (selectedOption === "Week") {
      let data = {
        pid,
        axisFormat: value,
        view: selectedOption,
      };
      dispatch(getReadingsData(data));
    } else {
      let data = {
        pid,
        axisFormat: value,
        view: selectedOption,
      };
      dispatch(getReadingsData(data));
    }

    if (text !== "No data found") {
      setAxisFormat(() => {
        return text;
      });
    }
    setShowDropdown(false);
  };

  const handleDropdown = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "view_drop") {
      setShowOptions(false);
    }
    if (exampleAttr !== "value_drop") {
      setShowDropdown(false);
    }
  };

  const handleClick = (e) => {
    if (SingPatDetails.enrollment_status !== "4") {
      const index = e.target.id !== "" ? e.target.id : e.currentTarget.id;
      if (index !== active) {
        setActive(index);
      }
    }
  };

  const handleView = (label) => {
    setSelected(label);
    readingsData["month_filter"] &&
    readingsData["month_filter"].length > 0 &&
    readingsData["month_filter"] !== null
      ? setAxisFormat("select")
      : setAxisFormat("");
  };

  // function to return two filter dropdown
  const DropDowns = () => {
    return (
      <>
        <Div
          width={selectedOption === "Week" && axisFormat ? "28%" : "13.5%"}
          float="right"
        >
          <Div
            width="100%"
            float="right"
            position="relative"
            display="flex"
            alignItems="center"
          >
            <SelectInput
              name="value_drop"
              height="38px"
              background="#FFFFFF"
              fontSize="14px"
              fontFamily="'Open Sans',sans-serif"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              color="#2E2E2E"
              width="100%"
              onClick={openAxisOption}
              display="flex"
              pl="9px"
              alignItems="center"
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
            >
              <Div
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                maxWidth="86%"
              >
                {axisFormat ? axisFormat : "No data found"}
              </Div>
            </SelectInput>
            <DropdownImage
              marginTop="0px"
              marginLeft="0px"
              name="value_drop"
              right="8px"
              top="16px"
              onClick={openAxisOption}
              src={dropdownDownicon}
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
            ></DropdownImage>
          </Div>
          {ShowDropdown ? (
            <DropDownDiv
              width={selectedOption === "Week" ? "28%" : "13.5%"}
              background="#FFFFFF"
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              marginTop="45px"
              maxHeight="200px"
              overflow="auto"
            >
              <>
                {dates.length > 0 ? (
                  dates.map((x, ind) => (
                    <>
                      {selectedOption === "Month" ? (
                        <DropdownOptions
                          data-option={x}
                          key={`a${ind}`}
                          data-value={x}
                          fontSize="14px"
                          onClick={(e) => chartAxisSelect(e)}
                          padding="10px 12px 8px 12px"
                        >
                          {x}
                        </DropdownOptions>
                      ) : (
                        <>
                          {x.map((y, i) => (
                            <DropdownOptions
                              data-option={y.value}
                              key={`d${i}`}
                              data-value={y.text}
                              fontSize="14px"
                              onClick={(e) => chartAxisSelect(e)}
                              padding="10px 12px 8px 12px"
                            >
                              {y.text}
                            </DropdownOptions>
                          ))}
                        </>
                      )}
                    </>
                  ))
                ) : (
                  <DropdownOptions fontSize="14px" padding="10px 12px 8px 12px">
                    No&nbsp;data&nbsp;found
                  </DropdownOptions>
                )}
              </>
            </DropDownDiv>
          ) : (
            ""
          )}
        </Div>

        <Div width="9%" float="right" marginRight="40px">
          <Div
            width="100%"
            float="right"
            position="relative"
            display="flex"
            alignItems="center"
          >
            <SelectInput
              name="view_drop"
              height="38px"
              background="#FFFFFF"
              fontSize="14px"
              fontFamily="'Open Sans',sans-serif"
              border="1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              color="#2E2E2E"
              width="100%"
              onClick={selectView}
              display="flex"
              pl="9px"
              alignItems="center"
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
            >
              <Div
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                maxWidth="84%"
              >
                {selectedOption}
              </Div>
            </SelectInput>
            <DropdownImage
              marginTop="0px"
              name="view_drop"
              marginLeft="0px"
              right="8px"
              top="16px"
              onClick={selectView}
              src={dropdownDownicon}
              cursor={
                SingPatDetails.enrollment_status !== "4"
                  ? "pointer"
                  : "not-allowed"
              }
            ></DropdownImage>
          </Div>
          {ShowOptions ? (
            <DropDownDiv
              width="9%"
              background="#FFFFFF"
              border=" 1px solid rgba(46, 46, 46, 0.25)"
              borderRadius="4px"
              marginTop="45px"
            >
              <>
                {selectOptions.map((x, ind) => (
                  <DropdownOptions
                    key={`b${ind}`}
                    value={x.value}
                    fontSize="14px"
                    onClick={(e) => handleView(x.label)}
                    padding="10px 12px 8px 12px"
                  >
                    {x.label}
                  </DropdownOptions>
                ))}
              </>
            </DropDownDiv>
          ) : (
            ""
          )}
        </Div>
      </>
    );
  };

  // function to return no data image block
  const NodataImage = () => {
    return (
      <Div margin="24px 0 0 0">
        <Image
          src={Emp_data_pic}
          alt="!"
          height="41%"
          width="30.5%"
          margin="auto"
          display="block"
        />
        <Text
          fontFamily="'Open Sans', sans-serif"
          fontWeight="400"
          fontSize="14px"
          textAlign="center"
          marginTop="24px"
          marginBottom="40px"
        >
          No&nbsp;reading&nbsp;has&nbsp;been&nbsp;taken&nbsp;for&nbsp;this&nbsp;device
        </Text>
      </Div>
    );
  };

  const legend = (colors, labels, condition) => {
    return (
      <>
        {condition
          ? colors.map((x, ind) => (
              <Div
                display="inline-flex"
                alignItems="center"
                key={`c${ind}`}
                marginLeft={
                  ind !== 0
                    ? ind === colors.length - 1
                      ? "10px"
                      : "12px"
                    : "10px"
                }
                marginRight={ind === colors.length - 1 ? "0px" : "10px"}
              >
                <SpanTag
                  backgroundColor={x}
                  borderRadius="50%"
                  height="10px"
                  width="10px"
                />
                <Text
                  marginLeft="8px"
                  fontFamily="'Open Sans',sans-serif"
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="22px"
                  color="#2E2E2E"
                  margin="12px 0 8px 8px"
                >
                  {labels[ind]}
                </Text>
              </Div>
            ))
          : null}
      </>
    );
  };

  // ------------------------------Functions End ------------------------------------------------------------//

  // ------------------------------useEffect End ------------------------------------------------------------//

  useEffect(() => {
    if (Object.keys(readingsData).length > 0) {
      setChartLabel(() => {
        return readingsData["y_axis"];
      });

      if (selectedOption === "Month") {
        setDate(() => {
          return readingsData["month_filter"][0] !== "" &&
            readingsData["month_filter"][0] != null
            ? readingsData["month_filter"]
            : ["No data found"];
        });
      } else {
        setDate(() => {
          return readingsData["week_filter"] &&
            readingsData["week_filter"].length > 0 &&
            readingsData["week_filter"][0] !== null
            ? readingsData["week_filter"]
            : { value: "", text: "No data found" };
        });
      }

      setHeartRateData(readingsData["pulse"]);
      setHeart(readingsData["pulse"]);
      setBloodPressData(readingsData["blood_pressure"]);
      setBloodPressure(readingsData["blood_pressure"]);
      setOxygenData(readingsData["oxygen"]);
      setOxygeData(readingsData["oxygen"]);
      setGluData(readingsData["glucose"]);
      setWeightData(readingsData["weight"]);
      setGlucoseDataset(readingsData["glucose"]);
      setWeightDtset(readingsData["weight"]);
      setRespirateData(readingsData["respiration"]);
      setResDtset(readingsData["respiration"]);

      (axisFormat === "" ||
        axisFormat === "select" ||
        selectedOption === "Month") &&
        readingsData["select_value"] !== "No data found" &&
        setAxisFormat(readingsData["select_value"]);
    }
  }, [readingsData]);

  useEffect(() => {
    let data = {
      pid,
      axisFormat: "",
      view: selectedOption,
    };
    dispatch(getReadingsData(data));
  }, [selectedOption]);

  useEffect(() => {
    document.addEventListener("click", handleDropdown);

    return () => {
      document.removeEventListener("click", handleDropdown);
    };
  }, []);

  // ------------------------------useEffect End ------------------------------------------------------------//

  return (
    <Div
      margin="0px"
      padding="25px 24px"
      backgroundColor="#FFFFFF"
      borderRadius="8px"
      position="relative"
    >
      <PageSubTitle
        fontFamily="'Open Sans', sans-serif"
        fontStyle="normal"
        fontWeight="600"
        fontSize="18px"
        lineHeight="25px"
        color="#2E2E2E"
        marginBottom="25px"
      >
        Readings&nbsp;Data
      </PageSubTitle>
      {permissions.includes("graph_access") ? (
        <>
          <Tabs>
            <Tab
              disabled={SingPatDetails.enrollment_status === "4"}
              onClick={handleClick}
              active={active === "0"}
              id="0"
              alert={readingsData?.abnormal?.pulse}
            >
              Heart&nbsp;Rate
              <IoIosAlert />
            </Tab>
            <Tab
              disabled={SingPatDetails.enrollment_status === "4"}
              onClick={handleClick}
              active={active === "1"}
              id="1"
              alert={readingsData?.abnormal?.blood_pressure}
            >
              Blood&nbsp;Pressure
              <IoIosAlert />
            </Tab>
            <Tab
              disabled={SingPatDetails.enrollment_status === "4"}
              onClick={handleClick}
              active={active === "2"}
              id="2"
              alert={readingsData?.abnormal?.oxygen}
            >
              Oxygen
              <IoIosAlert />
            </Tab>
            <Tab
              disabled={SingPatDetails.enrollment_status === "4"}
              onClick={handleClick}
              active={active === "3"}
              id="3"
              alert={readingsData?.abnormal?.glucose}
            >
              Glucose
              <IoIosAlert />
            </Tab>
            <Tab
              disabled={SingPatDetails.enrollment_status === "4"}
              onClick={handleClick}
              active={active === "4"}
              id="4"
              alert={readingsData?.abnormal?.respiration}
            >
              Respiratory&nbsp;Rate
              <IoIosAlert />
            </Tab>
            <Tab
              disabled={SingPatDetails.enrollment_status === "4"}
              onClick={handleClick}
              active={active === "5"}
              id="5"
              alert={readingsData?.abnormal?.weight}
            >
              Weight
              <IoIosAlert />
            </Tab>
          </Tabs>
          <>
            <Content active={active === "0"}>
              <Div padding="30px 21px">
                {DropDowns()}
                <PageSubTitle
                  fontFamily="'Open Sans', sans-serif"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  marginLeft="10px"
                >
                  Heart&nbsp;Rate&nbsp;&#40;BPM&#41;
                </PageSubTitle>
                {legend(
                  ["#2C7BE5", "#F24822"],
                  ["Normal Reading", "Abnormal Reading"],
                  true
                )}
                <Div>
                  {HeartRateData.length > 0 ? (
                    <Line
                      stepSize={10}
                      label={ChartLabel}
                      dataSet={HrDataSet}
                      label1={hrLabel}
                      axisRange={HRAxis}
                    />
                  ) : (
                    NodataImage()
                  )}
                </Div>
              </Div>
            </Content>
            <Content active={active === "1"}>
              <Div padding="30px 21px">
                {DropDowns()}
                <PageSubTitle
                  fontFamily="'Open Sans', sans-serif"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  marginLeft="10px"
                >
                  Blood&nbsp;Pressure&nbsp;&#40;mm/Hg&#41;
                </PageSubTitle>
                {legend(
                  ["#C8EDF9", "#2C7BE5", "#F24822"],
                  [
                    "Systolic Pressure",
                    "Diastolic Pressure",
                    "Abnormal Reading",
                  ],
                  true
                )}
                <Div>
                  {BloodPressData.length > 0 &&
                  (BloodPressData[0].length > 0 ||
                    BloodPressData[1].length > 0) ? (
                    <Line
                      stepSize={25}
                      label={ChartLabel}
                      dataSet={BpDataSet}
                      axisRange={BpAxis}
                      label1={bpLabel}
                    />
                  ) : (
                    NodataImage()
                  )}
                </Div>
              </Div>
            </Content>
            <Content active={active === "2"}>
              <Div padding="30px 21px">
                {DropDowns()}
                <PageSubTitle
                  fontFamily="'Open Sans', sans-serif"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  marginLeft="10px"
                >
                  Oxygen&nbsp;&#40;%&#41;
                </PageSubTitle>
                {legend(
                  ["#2C7BE5", "#F24822"],
                  ["Normal Reading", "Abnormal Reading"],
                  true
                )}
                <Div>
                  {OxygenData.length > 0 ? (
                    <Line
                      stepSize={2}
                      label={ChartLabel}
                      dataSet={OxyDataSet}
                      label1={oxyLabel}
                      axisRange={oxygenAxis}
                    />
                  ) : (
                    NodataImage()
                  )}
                </Div>
              </Div>
            </Content>
            <Content active={active === "3"}>
              <Div padding="30px 21px">
                {DropDowns()}
                <PageSubTitle
                  fontFamily="'Open Sans', sans-serif"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  marginLeft="10px"
                >
                  Glucose&nbsp;&#40;mg/dL&#41;
                </PageSubTitle>
                {legend(
                  ["#C8EDF9", "#2C7BE5", "#FFD233", "#F24822"],
                  [
                    "Blood Glucose (Fasting)",
                    "Blood Glucose (After Meal)",
                    "Blood Glucose (2-3 hrs After Meal)",
                    "Abnormal Reading",
                  ],
                  true
                )}
                <Div>
                  {GluData.length > 0 &&
                  (GluData[0].length > 0 ||
                    GluData[1].length > 0 ||
                    GluData[2].length > 0) ? (
                    <Line
                      stepSize={50}
                      label={ChartLabel}
                      dataSet={GluDataSet}
                      axisRange={GlucoseAxis}
                      label1={GluLabel}
                    />
                  ) : (
                    NodataImage()
                  )}
                </Div>
              </Div>
            </Content>
            <Content active={active === "4"}>
              <Div padding="30px 21px">
                {DropDowns()}
                <PageSubTitle
                  fontFamily="'Open Sans', sans-serif"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  marginLeft="10px"
                >
                  Respiratory&nbsp;Rate&nbsp;&#40;RPM&#41;
                </PageSubTitle>
                {legend(
                  ["#2C7BE5", "#F24822"],
                  ["Normal Reading", "Abnormal Reading"],
                  true
                )}
                <Div>
                  {RespirateData.length > 0 ? (
                    <Line
                      stepSize={6}
                      label={ChartLabel}
                      dataSet={ResDataSet}
                      axisRange={RespiratoryAxis}
                      label1={respRateLabel}
                    />
                  ) : (
                    NodataImage()
                  )}
                </Div>
              </Div>
            </Content>
            <Content active={active === "5"}>
              <Div padding="30px 21px">
                {DropDowns()}
                <PageSubTitle
                  fontFamily="'Open Sans', sans-serif"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="16px"
                  marginLeft="10px"
                >
                  Weight&nbsp;&#40;Kg&#41;
                </PageSubTitle>
                {legend(
                  ["#2C7BE5", "#F24822"],
                  ["Normal Reading", "Abnormal Reading"],
                  true
                )}
                <Div>
                  {weightData.length > 0 ? (
                    <Line
                      stepSize={20}
                      label={ChartLabel}
                      condition={WeightCondition}
                      dataSet={weightDataSet}
                      axisRange={weightAxis}
                      label1={weightLabel}
                    />
                  ) : (
                    NodataImage()
                  )}
                </Div>
              </Div>
            </Content>
          </>
        </>
      ) : (
        <AccessDenied width="500px" height="350px" />
      )}
    </Div>
  );
};

export default ReadingsData;
