import React, { useState } from "react";
import { Container1, TabPanel, Tabs } from "./Styles";
import { Div, PageSubTitle } from "../../StyledComponents/index";
import Provider from "./Provider/Provider";
import Staff from "./Staff/Staff";
import Roles from "./Roles/Roles";
import Permissions from "./Permissions/Permissions";

const RolesandPermissionSheet = () => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = useState("medical_problem");
  const [value1, setValue1] = useState(false);
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  // ------------------------------------------------- Functions Start-----------------------------------------------------------//

  const handleTabs = (tabIndex, value) => {
    setValue(value);
    setActiveTab(tabIndex);
    setValue1(true);
  };
  // ------------------------------------------------- Functions End-----------------------------------------------------------//

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div overflow="auto">
            <Provider pid={"1"} value={value} value1={value1} />
          </Div>
        );
      case 2:
        return (
          <Div overflow="auto">
            <Staff pid={"1"} value={value} value1={value1} />
          </Div>
        );
      case 3:
        return (
          <Div overflow="auto">
            <Roles pid={"1"} value={value} value1={value1} />
          </Div>
        );
      case 4:
        return (
          <Div overflow="auto">
            <Permissions pid={"1"} value={value} value1={value1} />
          </Div>
        );
      default:
        return null;
    }
  };
  return (
    <Container1>
      <PageSubTitle
        fontFamily="'Open Sans',sans-serif"
        fontWeight="600"
        fontSize="18px"
        fontStyle="normal"
        lineHight="normal"
        color="#2E2E2E"
        padding="16px 0px 0px 24px"
      >
        Roles And Permissions
      </PageSubTitle>
      <TabPanel
        width="100%"
        background="#F1F5F8"
        borderRadius="8px 8px 0px 0"
        borderBottom="1px solid rgba(46, 46, 46, 0.25);"
        display="flex"
        justifyContent="flex-start"
        padding="8px 25px 0 0px"
      >
        <Tabs
          // width="90px"
          fontFamily="'Open Sans',sans-serif"
          fontWeight="600"
          fontSize="14px"
          color="#2E2E2E"
          fontStyle="normal"
          lineHight="normal"
          padding="0px 0px 0 24px"
          isActive={activeTab === 1}
          onClick={() => handleTabs(1, "Providers")}
          line={activeTab}
        >
          Providers
        </Tabs>
        <Tabs
          // width="78px"
          fontFamily="'Open Sans',sans-serif"
          fontWeight="600"
          fontSize="14px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 24px"
          isActive={activeTab === 2}
          onClick={() => handleTabs(2, "Staff")}
        >
          Staff
        </Tabs>
        <Tabs
          // width="49px"
          fontFamily="'Open Sans',sans-serif"
          fontWeight="600"
          fontSize="14px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 24px"
          isActive={activeTab === 3}
          onClick={() => handleTabs(3, "Roles")}
        >
          Roles
        </Tabs>
        <Tabs
          // width="100px"
          fontFamily="'Open Sans',sans-serif"
          fontWeight="600"
          fontSize="14px"
          color="rgba(46, 46, 46, 0.5)"
          padding="0px 0px 0 24px"
          isActive={activeTab === 4}
          onClick={() => handleTabs(4, "Permissions")}
        >
          Permissions
        </Tabs>
      </TabPanel>
      {tabComponent()}
    </Container1>
  );
};

export default RolesandPermissionSheet;
