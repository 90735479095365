import axiosInst from "../../Config";

export const ListsApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/medical_problem`, credential);
export const EditListsApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/medical_problem/Edit`, credential);
export const DeleteListsApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/medical_problem/Delete`, credential);
export const InsertListsApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/medical_problem/Insert`, credential);
export const UpdateListsApi = async (credential) =>
  await axiosInst.put(`/vozorpm/patient/medical_problem/Update`, credential);
export const AllerUpdateListsApi = async (credential) =>
  await axiosInst.put(`/vozorpm/patient/allergy/Update`, credential);
export const AllerInsertListsApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/allergy/Insert`, credential);
export const LabApi = async (credential) =>
  await axiosInst.post(`/vozorpm/lab/get`, credential);

export const DeleteMedList = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/medication/Delete`, credential);
export const MedicationPostApi = async (data) =>
  await axiosInst.post(`/vozorpm/patient/medaicationpost`, data);
export const MedicationPutApi = async (data) =>
  await axiosInst.post(`/vozorpm/patient/medaication/Edit`, data);
export const MedicationUpdateApi = async (data) =>
  await axiosInst.put(`/vozorpm/patient/medaication/Update`, data);

export const LabOrderApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/laborder`, credential);
export const LabOrderoneApi = async (credential) =>
  await axiosInst.post(`/vozorpm/single/procedure/order`, credential);
export const LabOrderDeleteApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/laborder/Delete`, credential);
export const LabOrderInsertApi = async (credential) =>
  await axiosInst.post(`/vozorpm/patient/laborder/Insert`, credential);
export const LabOrderUpdateApi = async (credential) =>
  await axiosInst.put(`/vozorpm/patient/laborder/Update`, credential);
export const ProviderApi = async (credential) =>
  await axiosInst.get(`/vozorpm/provider/active/procedure`, credential);
export const LabListApi = async (credential) =>
  await axiosInst.put(`/vozorpm/get/lab/data`, credential);
