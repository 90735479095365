import axiosInst from "../../Config";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import DBHost from "../../Config/DBHost";
import axios from 'axios'
const UID =
  localStorage.getItem("user_id") &&
  Decrypt_Value(localStorage.getItem("user_id"), "vozorpm");
export const AddProfileData_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/AddProfileData`, credential);
export const AddClinicalData_Api = async (credential) =>
  await axiosInst.post(`/vozorpm/AddClinicalData`, credential);
export const ProfileDetails_Api = async (credential) =>
  await axiosInst.get("/vozorpm/UserProfile/details?id=" + credential);
export const ClinicalDetails_Api = async (credential) =>
  await axiosInst.get("/vozorpm/UserClinical/details?id=" + credential);
export const UserImageUpload_Api = async (data) =>
  await axiosInst.post(
    `/vozorpm/Userprofile/imageupload?uid=${data[0]}`,
    data[1]
  );
export const SettingSecurityUpdateApi = async (data) =>
  await axiosInst.put(`/vozorpm/settingsecurity`, data);

export const SettingSecurityDataApi = async () =>
  await axiosInst.get("/vozorpm/settingsecuritydata");

export const GetDocumentMailApi = async () =>
  await axiosInst.get(`/vozorpm/settingmail`);

export const GetSettingDocApi = async () =>
  await axiosInst.get(`/vozorpm/settingdocumentdata`);

export const GetUpdateSetDocApi = async (data) =>
  await axiosInst.put(`/vozorpm/settingdocumentupdate`, data);

export const Doc_Image_Api = async (data) =>
  await axiosInst.post(`/vozorpm/doc_image/Upload`, data[0]);

export const UserListApi = async (data) => {
  return await axiosInst.get("/vozorpm/userreport?data=" + data);
};

export const ProviderListApi = async (data) => {
  return await axiosInst.get("/vozorpm/provider?data=" + data);
};

export const ProviderRolesApi = async (data) => {
  return await axiosInst.get("/vozorpm/users/roles?search=" + data);
};

export const SavedProvidersApi = async (credential) =>
  await axiosInst.post(`/vozorpm/userinsert`, credential);

export const SingleProvApi = async (data) => {
  return await axiosInst.get("/vozorpm/setting/users?id=" + data);
};

export const UpdatedProvidersApi = async (credential) =>
  await axiosInst.post(
    `/vozorpm/userupdate?id=` + credential[0],
    credential[1]
  );

export const SettingSecurityApi = async () => {
  return await axiosInst.get("/vozorpm/settingsecuritydata");
};

export const DeleteUserApi = async (id) => {
  return await axiosInst.delete("/vozorpm/userdelete?id=" + id);
};

export const AllServiceGetApi = async (data) =>
  await axiosInst.get("/vozorpm/showallcustomecode?data=" + data);

export const ServiceDeleteApi = async (credential) =>
  await axiosInst.post("/vozorpm/deletecustomcode", credential);

export const ServiceInsertApi = async (credential) =>
  await axiosInst.post("/vozorpm/insertcustomcode_1", credential);

export const ServiceOneGetApi = async (credential) =>
  await axiosInst.get("/vozorpm/getonecustomcodeedit?id=" + credential);

export const ServiceUpdateApi = async (credential) =>
  await axiosInst.put("/vozorpm/customcodeedittask", credential);

export const Currency_Api = async () =>
  await axiosInst.get(`/vozorpm/setting/billing/currencydata?uid=${UID}`);

export const CodeVerify_Api = async (credential) =>
  await axiosInst.get(`/vozorpm/codecheck?articles=` + credential);

export const CheckUserList_Api = async (data) => {
  return axiosInst.get(`/vozorpm/setting/userdetails_check`, {
    params: data,
  });
};
export const CheckMailVerify_Api = async (credential) =>
  await axiosInst.get(`/vozorpm/settingmailcheck?articles=` + credential);

export const GetProviderRolesList_api = async (credential) =>
  await axiosInst.get(`/vozorpm/users/providerroles?search=` + credential);

export const rolesCutomize_api = async (credential) =>
  await axiosInst.post(
    `/vozorpm/users/roles/customize?aro_id=` + credential.aro_id,
    credential.articles
  );

export const InsertRole_Api = async (credential) =>
  await axiosInst.post("/vozorpm/users/roles/add", credential);

export const EditRole_Api = async (acl_id) =>
  await axiosInst.get("/vozorpm/users/roles/rap?acl_id=" + acl_id);

export const GetGroupId_Api = async (uid) =>
  await axiosInst.get("/vozorpm/users/groupid?uid=" + uid);

export const EditRoleInsert_Api = async (data) =>
  await axiosInst.put(
    "/vozorpm/users/roles/edit?aclid=" + data.acl_id,
    data.articles
  );
export const ProvWithRole_Api = async () =>
  await axiosInst.get("/vozorpm/providerwithroles");

export const ProvRoleEdit_Api = async (credential) =>
  await axiosInst.get(
    "/vozorpm/users/roles/provider?mainid=" +
      credential.main_id +
      "&id=" +
      credential.id
  );

export const ProvRolesave_Api = async (credential) =>
  await axiosInst.post("/vozorpm/users/rolesforprovider", credential);

export const DeleteRole_Api = async (data) => {
  return await axiosInst.delete(
    "/vozorpm/roles/delete?id=" + data.id + "&grpid=" + data.grpid
  );
};

export const CountryCode_Api = async () =>
  await axios.get(`${DBHost.finalurl}/interface/register/get_country_code_rpm.php`);
