import axiosInst from "../../Config";

export const GetTreatPlanApi = async (data) =>
  await axiosInst.get(`/vozorpm/getTreatmentPlan?data=${data}`);

export const SetTreatPlanToPatientApi = async (data) =>
  await axiosInst.post(`/vozorpm/setTreatmentPlan/toPatient`, data);

export const DeletePlanApi = async (data) =>
  await axiosInst.get(`/vozorpm/deletePlanName?data=${data}`);

  export const PlanNameCheckApi = async (credential) =>
  await axiosInst.put(`/vozorpm/PlanNameValidation`, credential);

export const TreatmentlanInsert = async (credential) =>
  await axiosInst.post(`/vozorpm/treatmentplan`, credential);

export const GetTreatmentlan = async (credential) =>
  await axiosInst.get(
    `/vozorpm/gettreatmentplan?id=${credential.id}&pid=${credential.pid}`
  );

export const UpdateTreatment = async (credential) =>
  await axiosInst.put(`/vozorpm/updatetreatmentplan`, credential);