import { takeLatest } from "redux-saga/effects";
import {
  LoginCredentials,
  GetLogo,
  GetPasswordChange,
  GetproviderDetail,
  setEmptyPassResponse,
  GetEmailRes,
} from "../../StateManagement/Reducers/LoginState";

import {
  LoginCheck,
  LogoApi,
  PasswordChangeWorker,
  ProviderDetail,
  setEmptyResponse,
  LoginEmailCheckApi,
} from "./LoginSagaWorkers";

export function* Login() {
  yield takeLatest(LoginCredentials.type, LoginCheck);
}
export function* Logo() {
  yield takeLatest(GetLogo.type, LogoApi);
}
export function* ChangePassword() {
  yield takeLatest(GetPasswordChange.type, PasswordChangeWorker);
}
export function* GetProviderDetailSaga() {
  yield takeLatest(GetproviderDetail.type, ProviderDetail);
}

export function* EmptyingResponseSaga() {
  yield takeLatest(setEmptyPassResponse.type, setEmptyResponse);
}
export function* LoginEmailCheckSaga() {
  yield takeLatest(GetEmailRes.type, LoginEmailCheckApi);
}
