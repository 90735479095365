import React from "react";
import { Container, Div, FormLabel } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import FeeSheetItem from "./FeeSheetItem";
import PatientDemoHead from "../PatientDemo/PatientDemoHead";
import PatientDemographics from "../PatientDemo/PatientDemographics";
import AccesDenied from "../../AccessDenied";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const FeeSheet = () => {
  const permissions =
    localStorage.getItem("permissions") &&
    Decrypt_Value(localStorage.getItem("permissions"), "vozorpm");
  return (
    <Container backgroundColor="#F5F5F5">
      <TopNav />
      <LeftNav />
      <Div marginLeft="56px" className="responsive-right">
        {permissions.includes("feesheet") ? (
          <>
            <Div background="#F5F5F5" padding="20px" margin="0px 0px 4px 0px">
              <Div padding="20px" backgroundColor="#FFFFFF" borderRadius="8px">
                <PatientDemoHead PatinetInfo="Patient Info" />

                <PatientDemographics />
              </Div>
            </Div>

            <FormLabel
              fontSize="18px"
              color="#2E2E2E"
              fontFamily="'Open Sans', sans-serif"
              lineHeight="25px"
              marginLeft="24px"
              fontWeight="600"
            >
              Fee Sheet
            </FormLabel>
            <Div
              background="#FFFFFF"
              boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
              opacity="0.9"
              borderRadius="8px"
              margin="24px 20px 20px 20px"
            >
              <FeeSheetItem />
            </Div>
          </>
        ) : (
          <AccesDenied />
        )}
      </Div>
    </Container>
  );
};
export default FeeSheet;
