import React from "react";
import {
  Button,
  Div,
  Modal,
  ModalBody,
  ModalContainer,
} from "../../../../StyledComponents";
import { ModalDialog } from "../../../../StyledComponents/AddCard/Styles";

const Confirmation = (props) => {
  const ModalAnimat = true;

  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <ModalContainer justifyContent="center" borderRadius="4px">
          <ModalDialog animation={ModalAnimat} width="320px">
            <ModalBody padding="16px 25px 16px 25px">
              <Div
                color="#2E2E2E"
                lineHeight="19px"
                width="fit-content"
                fontWeight="600"
                fontSize="14px"
                textAlign="center"
                fontFamily="Open Sans,sans-serif"
              >
                Are you sure you want to disconnect “{props.option}”
              </Div>
              <Div padding="20px 20px 0px 20px" textAlign="center">
                <Button
                  color="#FFFFFF"
                  height="36px"
                  cursor="pointer"
                  // width="121px"
                  background="#2C7BE5"
                  borderRadius="4px"
                  padding="9px 28px"
                  border="0.5px solid #2C7BE5"
                  fontWeight="600"
                  marginRight="20px"
                  onClick={() => props.Disconnect(true)}
                  hoverBackColor="#2C7BE5"
                  activeBackgroundColor="#2C7BE5"
                >
                  Yes
                </Button>

                <Button
                  color="#2C7BE5"
                  cursor="pointer"
                  height="36px"
                  background="#FFFFFF"
                  // width="100px"
                  fontWeight="600"
                  marginLeft="auto"
                  borderRadius="4px"
                  padding="9px 28px"
                  border="1px solid #2C7BE5"
                  onClick={() => props.Disconnect(false)}
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                >
                  No
                </Button>
              </Div>
            </ModalBody>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default Confirmation;
