import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import {
  setReEmailRes,
  SetNewPswd,
  SetResetVerify,
  userSignupEnd,
} from "../../StateManagement/Reducers/PreLoginState";
import { setSaveLoading } from "../../StateManagement/Reducers/LoginState";
import {
  PasswordResetEmailCheck_Api,
  NewPasswordEmailCheck_Api,
  ResetVerifyCheckApi_Api,
  userSignUpApi,
} from "../Apis/PreLoginApi";

export function* PasswordResetEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "vozorpm");
  try {
    const res = yield call(PasswordResetEmailCheck_Api, email);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(setReEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* NewPasswordEmailCheckApi({ payload }) {
  const pswd_data = Encrypt_Value(payload.type, "vozorpm");
  yield put(SetNewPswd(""));
  try {
    const res = yield call(NewPasswordEmailCheck_Api, pswd_data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetNewPswd(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* ResetVerifyCheckApi({ payload }) {
  const reset_data = Encrypt_Value(payload.type, "vozorpm");

  try {
    const res = yield call(ResetVerifyCheckApi_Api, reset_data);

    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozorpm");
      yield put(SetResetVerify(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* userSignUpWorker({ payload }) {
  const article = Encrypt_Value(payload, "vozorpm");

  try {
    const res = yield call(userSignUpApi, article);
    const Data = Decrypt_Value(res.data, "vozorpm");

    if (res.status === 200) {
      yield put(userSignupEnd(Data));
      yield put(setSaveLoading(false));
    } else {
      yield put(setSaveLoading(false));
    }
  } catch (e) {
    yield put(setSaveLoading(false));
    yield put(userSignupEnd("failed"));
    console.log(e.message);
  }
}

export function* setForgetEmpty() {
  yield put(setReEmailRes(""));
}
