import { createSlice } from "@reduxjs/toolkit";

export const HealthSlice = createSlice({
  name: "Health",
  initialState: {
    List: [],
    EditList: {},
    InsertList: {},
    DeleteList: {},
    UpdateList: {},
    AllerUpdateList: {},
    AllerInsertList: {},
    LabResponse: [],
    DeleteLists: [],
    MedPost: [],
    MedPut: [],
    MedUpdate: [],
    Laborder: [],
    Laborderone: {},
    ProviderList: [],
    LabList: [],
    HealthAlertMsg: "",
  },
  reducers: {
    GetList: (user) => {
      return user;
    },
    SetGetList: (state, action) => {
      state.List = action.payload;
    },
    GetEditList: (user) => {
      return user;
    },
    SetEditList: (state, action) => {
      state.EditList = action.payload;
    },
    clearAllergyData: (state) => {
      state.EditList = {};
    },
    GetInsertList: (user) => {
      return user;
    },
    GetDeleteList: (user) => {
      return user;
    },
    GetUpdateList: (user) => {
      return user;
    },
    GetAllerInsertList: (user) => {
      return user;
    },
    GetAllerUpdateList: (user) => {
      return user;
    },
    LabCredentials: (user) => {
      return user;
    },

    SetLabResponse: (state, action) => {
      state.LabResponse = action.payload;
    },

    MedDeleteLists: (user) => {
      return user;
    },
    SetDeleteLists: (state, action) => {
      state.DeleteLists = action.payload;
    },

    GetMedPost: (user) => {
      return user;
    },
    SetMedPost: (state, action) => {
      state.MedPost = action.payload;
    },

    GetMedPut: (user) => {
      return user;
    },
    SetMedPut: (state, action) => {
      state.MedPut = action.payload;
    },

    GetMedUpdate: (user) => {
      return user;
    },
    SetMedUpdate: (state, action) => {
      state.MedUpdate = action.payload;
    },
    GetLaborder: (user) => {
      return user;
    },
    SetLaborder: (state, action) => {
      state.Laborder = action.payload;
    },
    GetLaborderone: (user) => {
      return user;
    },
    SetLaborderone: (state, action) => {
      state.Laborderone = action.payload;
    },
    GetLaborderdelete: (user) => {
      return user;
    },
    GetLaborderupdate: (user) => {
      return user;
    },
    GetLaborderinsert: (user) => {
      return user;
    },
    GetProviderList: (user) => {
      return user;
    },
    SetProviderList: (state, action) => {
      state.ProviderList = action.payload;
    },
    GetLabList: (user) => {
      return user;
    },
    SetLabList: (state, action) => {
      state.LabList = action.payload;
    },
    setHealthAlert: (state, action) => {
      state.HealthAlertMsg = action.payload;
    },
    setEmptyLabResponse: (state) => {
      state.Laborderone = state;
    },
  },
});

export const {
  GetList,
  SetGetList,
  GetEditList,
  SetEditList,
  GetInsertList,
  GetDeleteList,
  GetUpdateList,
  GetAllerUpdateList,
  GetAllerInsertList,
  clearAllergyData,
  SetLabResponse,
  LabCredentials,
  MedDeleteLists,
  SetDeleteLists,
  SetMedPost,
  GetMedPost,
  GetMedPut,
  SetMedPut,
  GetMedUpdate,
  SetMedUpdate,
  GetLaborder,
  SetLaborder,
  GetLaborderone,
  SetLaborderone,
  GetLaborderdelete,
  GetLaborderupdate,
  GetLaborderinsert,
  GetProviderList,
  SetProviderList,
  GetLabList,
  SetLabList,
  setHealthAlert,
  setEmptyLabResponse,
} = HealthSlice.actions;

export default HealthSlice.reducer;
