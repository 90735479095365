import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  GetLabList,
  GetLaborderinsert,
  GetLaborderupdate,
  GetProviderList,
} from "../../../../StateManagement/Reducers/HealthState";
import {
  AllergyDropdown,
  AllergyOption,
} from "../../../Patient/PatientDemo/styles";
import { GetProcedure } from "../../../../StateManagement/Reducers/MedicalState";
import {
  Modal,
  ModalContainer,
  ModalTitle,
  Div,
  Input,
  Span,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropDownDiv,
  DropdownOptions,
  SelectInput,
  RedStar,
  Label,
  SearchIconSVG,
  Text,
} from "../../../StyledComponents";
import { DropdownImage } from "../styles";
import { ErrorMessage } from "../../BillandInsurance/styles";
import { ModalDialog } from "../../../StyledComponents/AddCard/Styles";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPlusSquare } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import CustomDatePicker from "../../../StyledComponents/datepicker/CustomDatePicker";
import { AiOutlineClose } from "react-icons/ai";
import { Imagecalendar } from "../../../Popup/styles";
import calendar_icon_custom from "../../../../Assets/images/calendar_icon_custom.png";
import dropdownDownicon from "../../../../Assets/images/dropdownDownicon.png";

const AddLaborder = (props) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//

  const [procedureOrder, setProceureOrder] = useState(props.labedit);
  const [Orderpro, setOrderPro] = useState("");
  const [Orderid, setOrderid] = useState("0");
  const [sendingto, setSendingTo] = useState("");
  const [labid, setlabid] = useState("");
  const [Priority, setPriority] = useState("");
  const [status, setStatus] = useState("");
  const [history, setHistory] = useState("");
  const [proordererr, setProOrdererr] = useState(false);
  const [orderprovidererr, setOrderProvidererr] = useState(false);
  const [sendingtoerr, setSendingToerr] = useState(false);
  const [priorityerr, setPriorityerr] = useState(false);
  const [statuserr, setStatuserr] = useState(false);
  const [inputList, setInputList] = useState([
    {
      procedure: "",
      selected: false,
      matched: false,
      errorStatus: false,
      matchStatus: false,
    },
  ]);
  const [inputListcheck, setInputListcheck] = useState([
    {
      procedure: "",
      selected: false,
      matched: false,
      errorStatus: false,
      matchStatus: false,
    },
  ]);
  const [prcdnm, setprcdnm] = useState("");
  const [prcdind, setprcdind] = useState("");
  const [procedure, setProcedure] = useState([]);
  const [codes, setCodes] = useState([]);
  const [storeicd, setstoreicd] = useState([]);
  const [ShowOptions, setShowOptions] = useState(false);
  const [ShowOptions1, setShowOptions1] = useState(false);
  const [ShowOptions2, setShowOptions2] = useState(false);
  const [ShowOptions3, setShowOptions3] = useState(false);
  const [ShowOptions4, setShowOptions4] = useState(false);
  const [ShowOptions5, setShowOptions5] = useState(false);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState("");
  const [todate, setToDate] = useState("");
  const [datecheck, setDatecheck] = useState("");
  const [todatecheck, setToDatecheck] = useState("");
  const [Dateerr, setDateerr] = useState(false);
  const [todateerr, setToDateerr] = useState(false);
  const [Dateerrcheck, setDateerrcheck] = useState(false);
  const [todateerrcheck, setToDateerrcheck] = useState(false);
  const [showpicker, setShowPicker] = useState(false);
  const [showpicker1, setShowPicker1] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(inputList.length).fill(false)
  );
  const [ProcedureType, setProcedureType] = useState("");
  const [search2, setSearch2] = useState("");
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [procedureErr, setprocedureErr] = useState(false);
  const [DatediffErrcheck, setDatediffErrcheck] = useState(false);

  var hcarr = [];
  const [validDate, setValidDate] = useState(
    moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
  );
  const [validDate1, setValidDate1] = useState(
    moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
  );
  const [dis, setDis] = useState(false);

  const dispatch = useDispatch();
  const PriorityDropdownOptionList3 = [
    { label: "Low", id: "1" },
    { label: "Medium", id: "2" },
    { label: "High", id: "3" },
    { label: "Very High", id: "3" },
  ];
  const statusDropdownOptionList4 = [
    { label: "Pending", id: "1" },
    { label: "Routed", id: "2" },
    { label: "Complete", id: "3" },
    { label: "Canceled", id: "3" },
  ];
  const historyDropdownOptionList5 = [
    { label: "Yes", id: "1" },
    { label: "No", id: "2" },
  ];
  const ProcedureList = useSelector((state) => state.Medical.ProcedureList);
  const ProviderList = useSelector((state) => state.Health.ProviderList);
  const LabList = useSelector((state) => state.Health.LabList);
  const LaborderoneList = useSelector((state) => state.Health.Laborderone);
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//
  //-----------------------------Function Start------------------------------------------------- //
  const inputstyle = (i) => {
    return {
      marginLeft: "-23px",
      border:
        inputList[i].errorStatus === true || inputList[i].matchStatus === true
          ? "1px solid red"
          : "",
    };
  };
  const closePicker = () => {
    setShowPicker(false);
  };
  const closePicker1 = () => {
    setShowPicker1(false);
  };
  const handleCustomCalendar = (e) => {
    if (
      e.target.id !== "CustomDatePicker" &&
      e.target.id !== "CustomDatePickerbuttons" &&
      e.target.id !== "date" &&
      e.target.id !== "date1" &&
      e.target.className !== "year"
    ) {
      closePicker();
      closePicker1();
    }
  };

  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setToDate(moment(value).format("YYYY-MM-DD"));
      setToDatecheck(moment(value).format("YYYY-MM-DD"));
      setToDateerr(false);
      setToDateerrcheck(false);
      setDatediffErrcheck(false);
      setValidDate(new Date(value));
    } else {
      setToDate("");
      setToDatecheck("");
      setValidDate("");
    }
    closePicker();
  };
  const dateSelect1 = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setDate(moment(value).format("YYYY-MM-DD"));
      setDatecheck(moment(value).format("YYYY-MM-DD"));
      setDateerr(false);
      setDateerrcheck(false);
      setDatediffErrcheck(false);
      setValidDate1(new Date(value));
    } else {
      setDate("");
      setDatecheck("");
      setValidDate1("");
    }
    closePicker1();
  };

  const keyDownExpiry = (event) => {
    const inp = event.target;

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };

  const handlepicker = (e) => {
    e.preventDefault();
    setShowPicker(true);
    setShowPicker1(false);
    setShowOptions1(false);
    setShowOptions2(false);
    setShowOptions3(false);
    setShowOptions4(false);
    setShowOptions5(false);
  };
  const handlepicker1 = (e) => {
    e.preventDefault();
    setShowPicker1(true);
    setShowPicker(false);
    setShowOptions1(false);
    setShowOptions2(false);
    setShowOptions3(false);
    setShowOptions4(false);
    setShowOptions5(false);
  };
  const handleOptionClick = (option, i, value) => {
    setprocedureErr(false);
    var store = option.split(" - ");
    let finttext = option.search("-");
    if (finttext > 1) {
      codes[prcdind] = store[0];
      procedure[prcdind] = store[1];
    }
    setSearch2(option);
    setvalue();
    let inputs = [...inputList];
    inputs[i].procedure = option;
    inputs[i].selected = true;
    inputs[i].matched = false;
    setInputList(inputs);
    setInputListcheck(inputs);
    setProcedureType("");

    setDropdownOpen((prevState) => {
      const newState = [...prevState];
      newState[i] = false;
      return newState;
    });
  };
  const handleDrug = (e, i) => {
    setprocedureErr(false);
    setLoading(true);
    const updatedInputList = [...inputList];
    updatedInputList[i] = {
      ...updatedInputList[i],
      procedure: e.target.value,
      matched: true,
      selected: false,
    };
    updatedInputList[i].matchStatus = false;
    updatedInputList[i].errorStatus = false;
    setInputList(updatedInputList);
    prepareProcedure(e, i);
    setProcedureType(e.target.value);
    setDropdownOpen((prevState) => {
      const newState = [...prevState];
      newState[i] = true;
      for (let j = 0; j < newState.length; j++) {
        if (j !== i) {
          newState[j] = false;
        }
      }

      return newState;
    });
  };
  const setvalue = () => {
    let store = search2.split(" - ");
    let findtext = search2.indexOf("-");
    if (findtext > 1) {
      const list = [...inputList];
      list[prcdind][prcdnm] = `${store[0]} ${store[1]}`;
      setInputList(list);
      setProcedureType("");
    }
  };

  const prepareProcedure = (name, index) => {
    setprcdnm(() => {
      return name;
    });
    setprcdind(() => {
      return index;
    });
  };
  const handleRemoveClick = (index) => {
    if (inputList.length !== 1) {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
      if (codes[index] !== undefined) {
        codes.splice(index, 1);
      }
      if (procedure[index] !== undefined) {
        procedure.splice(index, 1);
      }
      if (storeicd[index] !== undefined) {
        storeicd.splice(index, 1);
      }
    } else {
      setprocedureErr(true);
    }
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        procedure: "",
        selected: false,
        matched: false,
        errorStatus: false,
        matchStatus: false,
      },
    ]);
    setProcedureType("");
  };

  const setOrderProvider = (p) => {
    setOrderPro(p.title);
    setOrderid(p.id);
    setShowOptions1(false);
    if (p.title !== "") {
      setOrderProvidererr(false);
    }
  };
  const setsendto = (p) => {
    setSendingTo(p.name);
    setlabid(p.ppid);
    setShowOptions2(false);
    if (p.name !== "") {
      setSendingToerr(false);
    }
  };
  const setpriority = (p) => {
    setPriority(p.label);
    setShowOptions3(false);
    if (p.label !== "") {
      setPriorityerr(false);
    }
  };
  const setsatuspro = (p) => {
    setStatus(p.label);
    setShowOptions4(false);
    if (p.label !== "") {
      setStatuserr(false);
    }
  };
  const sethistorypro = (p) => {
    setHistory(p.label);
    setShowOptions5(false);
  };

  const handleClick = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "status") {
      setShowOptions4(false);
    }
  };
  const handleClick1 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "history_order") {
      setShowOptions5(false);
    }
  };
  const handleClick2 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "Priority") {
      setShowOptions3(false);
    }
  };
  const handleClick3 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "sending_to") {
      setShowOptions2(false);
    }
  };
  const handleClick4 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "pro_order") {
      setShowOptions(false);
    }
  };
  const handleClick5 = (e) => {
    const exampleAttr = e.target.getAttribute("name");

    if (exampleAttr !== "name_pro") {
      setShowOptions1(false);
    }
  };
  const errorCheck = () => {
    const updatedInputList = inputList.map((value, index) => {
      if (inputList[index]["selected"] === false) {
        return { ...value, errorStatus: true };
      } else {
        return { ...value, errorStatus: false };
      }
    });

    const Error = updatedInputList.some((item) => item.errorStatus === true);

    setprocedureErr(true);
    setInputList(updatedInputList);

    return Error;
  };

  const matchCheck = () => {
    const updatedInputList = inputList.map((value, index) => {
      if (
        inputListcheck[index] &&
        inputListcheck[index]["procedure"] &&
        inputList[index]["procedure"] !== inputListcheck[index]["procedure"]
      ) {
        if (inputList[index]["matched"] === true) {
          return { ...value, matchStatus: true };
        } else {
          return { ...value, matchStatus: false };
        }
      } else {
        return value;
      }
    });

    const MatchError = updatedInputList.some(
      (item, index) =>
        inputListcheck[index] &&
        item["procedure"] !== inputListcheck[index]["procedure"] &&
        item.matchStatus === true
    );

    setInputList(updatedInputList);
    return MatchError;
  };
  const handleclose1 = () => {
    setbtnDisabled(false);
    setOrderPro("");
    setSendingTo("");
    setOrderid("0");
    setDate("");
    setToDate("");
    setDatecheck("");
    setToDatecheck("");
    setStatus("");
    setHistory("");
    setPriority("");
    setProceureOrder("Add New");
    setCodes([]);
    setProcedure([]);
    setstoreicd([]);
    setProcedureType("");
    setInputList([
      {
        procedure: "",
        selected: false,
        matched: false,
        errorStatus: false,
        matchStatus: false,
      },
    ]);
    props.onHide();
    setStatuserr(false);
    setPriorityerr(false);
    setSendingToerr(false);
    setOrderProvidererr(false);
    setProOrdererr(false);
    setDateerr(false);
    setToDateerr(false);
    setDateerrcheck(false);
    setToDateerrcheck(false);
    setprocedureErr(false);
    setDatediffErrcheck(false);
    setValidDate(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
    setValidDate1(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
  };
  const HandleSaveLab = () => {
    var err = 0;
    if (procedureOrder === "") {
      setProOrdererr(true);
      err = 2;
    }
    if (Orderpro === "") {
      setOrderProvidererr(true);
      err = 2;
    }
    if (sendingto === "") {
      setSendingToerr(true);
      err = 2;
    }
    if (Priority === "") {
      setPriorityerr(true);
      err = 2;
    }
    if (status === "") {
      setStatuserr(true);
      err = 2;
    }
    if (date === "") {
      setDateerr(true);
      err = 2;
    } else if (date !== datecheck) {
      setDateerrcheck(true);
    }
    if (date < todate) {
      setDatediffErrcheck(true);
      err = 2;
    }
    if (todate === "") {
      setToDateerr(true);
      err = 2;
    } else if (todate !== todatecheck) {
      setToDateerrcheck(true);
    }
    var conditioncheck1 = matchCheck();
    if (conditioncheck1 === true) {
      err = 2;
    }
    var conditioncheck = errorCheck();
    if (conditioncheck === true) {
      setprocedureErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }
    setDis(true);
    if (procedureOrder !== "Add New" && procedureOrder !== "") {
      setbtnDisabled(true);
      dispatch(
        GetLaborderupdate({
          id: procedureOrder,
          pat_id: props.pidvalue,
          type: props.prblmvalue,
          provider: Orderid,
          lab: labid,
          order_date: todate,
          order_time: date,
          status: status,
          priority: Priority,
          history: history,
          pro_code: codes,
          pro_name: procedure,
          procedure: inputList,
          diagnosis: storeicd,
          xtra: 0,
          value: props.prblmvalue,
        })
      );
    } else {
      props.setLabedit("Add New");
      setOrderPro("");
      setSendingTo("");
      setOrderid("0");
      setDate("");
      setToDate("");
      setStatus("");
      setHistory("");
      setPriority("");
      setCodes([]);
      setProcedure([]);
      setInputList([
        {
          procedure: "",
          selected: false,
          matched: false,
          errorStatus: false,
          matchStatus: false,
        },
      ]);
      setProcedureType("");
      setbtnDisabled(true);
      dispatch(
        GetLaborderinsert({
          pat_id: props.pidvalue,
          type: props.prblmvalue,
          provider: Orderid,
          lab: labid,
          order_date: todate,
          order_time: date,
          status: status,
          priority: Priority,
          history: history,
          pro_code: inputList.map((item) => item.procedure.split(" - ")[0]),
          pro_name: inputList.map((item) => item.procedure.split(" - ")[1]),
          procedure: inputList,
          diagnosis: storeicd,
          xtra: 0,
          value: props.prblmvalue,
        })
      );
    }
    setTimeout(() => {
      setDis(false);
    }, 1000);
    handleclose1();
  };

  const HandleSaveTrans = () => {
    var err = 0;
    if (procedureOrder === "") {
      setProOrdererr(true);
      err = 2;
    }
    if (Orderpro === "") {
      setOrderProvidererr(true);
      err = 2;
    }
    if (sendingto === "") {
      setSendingToerr(true);
      err = 2;
    }
    if (Priority === "") {
      setPriorityerr(true);
      err = 2;
    }
    if (status === "") {
      setStatuserr(true);
      err = 2;
    }

    if (date === "") {
      setDateerr(true);
      err = 2;
    } else if (date !== datecheck) {
      setDateerrcheck(true);
      setDatediffErrcheck(false);
      err = 2;
    }
    if (date < todate) {
      setDatediffErrcheck(true);
      err = 2;
    }
    if (todate === "") {
      setToDateerr(true);
      err = 2;
    } else if (todate !== todatecheck) {
      setToDateerrcheck(true);
      setDatediffErrcheck(false);
      err = 2;
    }
    var conditioncheck1 = matchCheck();
    if (conditioncheck1 === true) {
      err = 2;
    }
    var conditioncheck = errorCheck();
    if (conditioncheck === true) {
      setprocedureErr(true);
      err = 2;
    }
    if (err > 0) {
      return false;
    }

    if (procedureOrder !== "Add New" && procedureOrder !== "") {
      setbtnDisabled(true);
      dispatch(
        GetLaborderupdate({
          id: procedureOrder,
          pat_id: props.pidvalue,
          type: props.prblmvalue,
          provider: Orderid,
          lab: labid,
          order_date: todate,
          order_time: date,
          status: status,
          priority: Priority,
          history: history,
          pro_code: codes,
          pro_name: procedure,
          diagnosis: ProcedureType,
          xtra: 0,
          value: props.prblmvalue,
        })
      );
    } else {
      props.setLabedit("Add New");
      setOrderPro("");
      setSendingTo("");
      setOrderid("0");
      setDate("");
      setToDate("");
      setStatus("");
      setHistory("");
      setPriority("");
      setProcedureType("");
      setbtnDisabled(true);
      dispatch(
        GetLaborderinsert({
          pat_id: props.pidvalue,
          type: props.prblmvalue,
          provider: Orderid,
          lab: labid,
          order_date: todate,
          order_time: date,
          status: status,
          priority: Priority,
          history: history,
          pro_code: inputList.map((item) => item.procedure.split(" - ")[0]),
          pro_name: inputList.map((item) => item.procedure.split(" - ")[1]),
          diagnosis: ProcedureType,
          xtra: 0,
          value: props.prblmvalue,
        })
      );
    }
    handleclose1();
  };
  const handleContainerClick = (e) => {
    if (
      e.target.id === "CustomDatePicker" ||
      e.target.id === "CustomDatePickerbuttons" ||
      e.target.id === "date" ||
      e.target.id === "date1" ||
      e.target.className === "year"
    ) {
    } else {
      closePicker();
      closePicker1();
      if (ShowOptions) {
        setShowOptions(false);
      }
      if (ShowOptions1) {
        setShowOptions1(false);
      }
      if (ShowOptions2) {
        setShowOptions2(false);
      }
      if (ShowOptions3) {
        setShowOptions3(false);
      }
      if (ShowOptions4) {
        setShowOptions4(false);
      }
      if (ShowOptions5) {
        setShowOptions5(false);
      }
      if (dropdownOpen) {
        setDropdownOpen(Array(inputList.length).fill(false));
      }
    }
    e.stopPropagation();
  };

  var hcval = ProcedureList[3];
  var hcval1 = ProcedureList[1];
  if (hcval !== undefined) {
    for (let i = 0; i < hcval.length; i++) {
      hcarr.push(hcval[i] + " - " + hcval1[i]);
    }
  }
  //-----------------------------Function End------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //
  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleClick1);
    document.addEventListener("click", handleClick2);
    document.addEventListener("click", handleClick3);
    document.addEventListener("click", handleClick4);
    document.addEventListener("click", handleClick5);
    document.addEventListener("click", handleCustomCalendar);
    dispatch(GetProviderList());
    dispatch(GetLabList());

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleClick1);
      document.removeEventListener("click", handleClick2);
      document.removeEventListener("click", handleClick3);
      document.removeEventListener("click", handleClick4);
      document.removeEventListener("click", handleClick5);
      document.removeEventListener("click", handleCustomCalendar);
    };
  }, []);

  useEffect(() => {
    dispatch(GetProcedure(ProcedureType));
  }, [ProcedureType]);
  useEffect(() => {
    if (ProcedureList !== null) {
      setLoading(false);
    }
  }, [ProcedureList]);
  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);
  useEffect(() => {
    setProceureOrder(props.labedit);
  }, [props.labedit]);

  useEffect(() => {
    if (Object.keys(LaborderoneList).length > 0 && props.id !== 0) {
      setProceureOrder(LaborderoneList[0]?.procedure_order_id);
      setDate(LaborderoneList[0]?.date_collected);
      setDatecheck(LaborderoneList[0]?.date_collected);
      setOrderPro(LaborderoneList[0]?.providername);
      if (LaborderoneList[0]?.providername) {
        setOrderid(LaborderoneList[0]?.provider_id);
      }

      setToDate(LaborderoneList[0].date_ordered);
      setToDatecheck(LaborderoneList[0].date_ordered);
      setValidDate(LaborderoneList[0].date_ordered);
      setValidDate1(LaborderoneList[0]?.date_collected);
      setSendingTo(LaborderoneList[0]?.name);
      if (LaborderoneList[0]?.name) {
        setlabid(LaborderoneList[0]?.lab_id);
      }
      setPriority(LaborderoneList[0]?.order_priority);
      setStatus(LaborderoneList[0]?.order_status);
      setHistory(LaborderoneList[0]?.history_order);
      setProcedureType(LaborderoneList[0]?.order_diagnosis);
      var temp = [];
      var temp1 = [];
      LaborderoneList[2]?.forEach((item) => {
        temp1.push(item.procedure_code);
        setCodes(temp1);
        temp.push(item.procedure_name);
        setProcedure(temp);
      });

      setInputList(LaborderoneList[1]);
      setInputListcheck(LaborderoneList[1]);
    } else {
      props.setLabedit("Add New");
      setOrderPro("");
      setSendingTo("");
      setOrderid("0");
      setDate("");
      setToDate("");
      setStatus("");
      setHistory("");
      setPriority("");
      setCodes([]);
      setProcedure([]);
      setstoreicd([]);
      setProcedureType("");
      setInputList([
        {
          procedure: "",
          selected: false,
          matched: false,
          errorStatus: false,
          matchStatus: false,
        },
      ]);
      setStatuserr(false);
      setPriorityerr(false);
      setSendingToerr(false);
      setOrderProvidererr(false);
      setProOrdererr(false);
      setDateerr(false);
      setToDateerr(false);
      setDatediffErrcheck(false);
    }
  }, [LaborderoneList]);

  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <>
      <Modal
        show={props.show}
        justifyContent="center"
        overflowY="auto"
        onClick={handleclose1}
        style={{
          width: screenSize.width * 1.0,
          height: screenSize.height * 1.0,
        }}
      >
        <ModalContainer
          justifyContent="center"
          borderRadius="8px"
          overflowX="revert"
          overflowY="revert"
          position="relative"
          onClick={handleContainerClick}
        >
          <ModalDialog width="420px">
            <ModalHeader padding="17px 27px 18px 24px" height="64px">
              <ModalTitle
                marginTop="10px"
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600px !important"
                fontSize="16px"
                lineHight="22px"
                color="#2E2E2E"
                lineHeight="21.79px"
              >
                {procedureOrder === "Add New"
                  ? "Add Lab Order"
                  : `Edit Lab Order`}
              </ModalTitle>
              <ModalTitle>
                <Button
                  marginTop="10px"
                  border="none"
                  marginLeft="232.79px"
                  fontSize="0.9em"
                  background="white"
                  onClick={handleclose1}
                >
                  <AiOutlineClose />
                </Button>
              </ModalTitle>
            </ModalHeader>
            <ModalBody padding="0px 27px 7px 23px" overflowY="scroll">
              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div display="grid" margin="15px 0px 0px 0px">
                    <Label
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Saved Procedure Order{" "}
                      <RedStar position="relative">*</RedStar>
                    </Label>

                    <Div position="relative">
                      <SelectInput
                        width="180px"
                        height="38px"
                        name="pro_order"
                        background="#FFFFFF"
                        fontSize="15px"
                        fontFamily="'Open Sans',sans-serif"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        color="#2E2E2E"
                        paddingTop="8px"
                        pl="10px"
                        margin="8px 0px 0px 0px"
                        style={
                          proordererr
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                      >
                        {procedureOrder === "Add New"
                          ? "Add New"
                          : `Order ID ${procedureOrder}`}
                      </SelectInput>
                    </Div>
                    {proordererr && (
                      <ErrorMessage>Please Select valid procedure</ErrorMessage>
                    )}
                  </Div>
                </Div>
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div display="grid" margin="15px 0px 0px 0px">
                    <Label
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Ordering Provider<RedStar position="relative">*</RedStar>
                    </Label>

                    <Div
                      position="relative"
                      onClick={() => setShowOptions1(!ShowOptions1)}
                    >
                      <SelectInput
                        width="180px"
                        height="38px"
                        name="name_pro"
                        background="#FFFFFF"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        color="#2E2E2E"
                        pl="10px"
                        paddingTop="8px"
                        margin="8px 0px 0px 0px"
                        value={LaborderoneList.providername}
                        style={
                          orderprovidererr
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                      >
                        <Div
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          width="80%"
                        >
                          {Orderpro}
                        </Div>
                      </SelectInput>
                      <DropdownImage
                        top="24px"
                        right="10px"
                        src={dropdownDownicon}
                      ></DropdownImage>
                    </Div>
                    {ShowOptions1 && (
                      <DropDownDiv
                        width="178px"
                        height="auto"
                        fontFamily="'Open Sans',sans-serif"
                        background="#FFFFFF"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        marginTop="68px"
                      >
                        {ProviderList.map((p, i) => {
                          return (
                            <DropdownOptions
                              key={i}
                              padding="10px 12px 8px 12px"
                              wordBreak="break-word"
                              onClick={() => setOrderProvider(p)}
                            >
                              {p.title}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    )}
                    {orderprovidererr && (
                      <ErrorMessage>Please Select valid provider</ErrorMessage>
                    )}
                  </Div>
                </Div>
              </Div>

              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="18px 0px 0px 0px"
                  >
                    <Label
                      style={{ marginBottom: "5px" }}
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Order Date<RedStar position="relative">*</RedStar>
                    </Label>
                    <Div position="relative">
                      <CustomDatePicker
                        padding="0 0 0 10px"
                        id="CustomDatePicker"
                        width="180px"
                        height="38px"
                        fontFamily="'Open Sans',sans-serif"
                        backgroundColour="#f7f7f7 !important"
                        border={
                          todateerr || todateerrcheck || DatediffErrcheck
                            ? "1px solid red"
                            : "1px solid rgba(34,36,38,.15)"
                        }
                        borderRadius="4px"
                        placeholder="yyyy-mm-dd"
                        position="relative"
                        value={todate}
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "YYYY-MM-DD",
                            true
                          ).isValid();
                          setToDate(e.target.value);
                          setToDateerr(false);
                          setToDateerrcheck(false);
                          setDatediffErrcheck(false);
                          if (e.target.value === "") {
                            setValidDate("");
                          } else if (d) {
                            let check = false;
                            setToDatecheck(e.target.value);
                            if (check) {
                              if (validDate !== "") {
                                setToDate(
                                  moment(validDate).format("YYYY-MM-DD")
                                );
                              }
                            } else {
                              setValidDate(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect}
                        showPicker={showpicker}
                        validDate={validDate}
                        closePicker={closePicker}
                        max={moment(date, "YYYY-MM-DD")}
                      />
                      {todateerr && (
                        <ErrorMessage>Please Select Date</ErrorMessage>
                      )}
                      {todateerrcheck && (
                        <ErrorMessage>Please Select Proper Date</ErrorMessage>
                      )}
                      {DatediffErrcheck && (
                        <ErrorMessage>
                          Please Select Order Date before Internal time
                          Collected
                        </ErrorMessage>
                      )}
                      <Imagecalendar
                        top="6px"
                        right="20px"
                        width="20px"
                        height="22px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                  </Div>
                </Div>
                <Div Div display="flex" flexDirection="column" width="47.5%">
                  <Div padding="0 48px 0 0" margin="15px 0px 0px 0px">
                    <Label
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Sending To<RedStar position="relative">*</RedStar>
                    </Label>
                    <Div
                      width="180px"
                      position="relative"
                      onClick={() => setShowOptions2(!ShowOptions2)}
                    >
                      <SelectInput
                        width="180px"
                        height="38px"
                        name="sending_to"
                        background="#FFFFFF"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        color="#2E2E2E"
                        pl="10px"
                        paddingTop="8px"
                        margin="8px 0px 0px 0px"
                        value={LaborderoneList.name}
                        style={
                          sendingtoerr
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                      >
                        <Div
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          width="80%"
                        >
                          {sendingto}
                        </Div>
                      </SelectInput>

                      <DropdownImage
                        top="16px"
                        right="10px"
                        src={dropdownDownicon}
                      ></DropdownImage>
                    </Div>
                  </Div>
                  {ShowOptions2 && (
                    <DropDownDiv
                      width="178px"
                      height="auto"
                      background="#FFFFFF"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      marginTop="83px"
                    >
                      {LabList.map((p, i) => {
                        return (
                          <DropdownOptions
                            key={i}
                            padding="10px 12px 8px 12px"
                            onClick={() => setsendto(p)}
                          >
                            {p.name}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  )}
                  {sendingtoerr && (
                    <ErrorMessage>Please Select valid lab</ErrorMessage>
                  )}
                </Div>
              </Div>

              <Div display="flex" justifyContent="space-between">
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div
                    display="inline-grid"
                    padding="0 48px 0 0"
                    margin="18px 0px 0px 0px"
                  >
                    <Label
                      style={{ marginBottom: "5px" }}
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Internal Time Collected
                      <RedStar position="relative">*</RedStar>
                    </Label>
                    <Div position="relative">
                      <CustomDatePicker
                        padding="0 0 0 10px"
                        id="CustomDatePicker"
                        width="180px"
                        height="38px"
                        fontFamily="'Open Sans',sans-serif"
                        backgroundColour="#f7f7f7 !important"
                        border={
                          Dateerr || Dateerrcheck || DatediffErrcheck
                            ? "1px solid red !important"
                            : "1px solid rgba(34,36,38,.15)"
                        }
                        borderRadius="4px"
                        placeholder="yyyy-mm-dd"
                        position="relative"
                        value={date}
                        onChange={(e) => {
                          let d = moment(
                            e.target.value,
                            "YYYY-MM-DD",
                            true
                          ).isValid();
                          setDateerr(false);
                          setDateerrcheck(false);
                          setDatediffErrcheck(false);
                          setDate(e.target.value);
                          if (e.target.value === "") {
                            setValidDate1("");
                          } else if (d) {
                            let check = false;
                            setDatecheck(e.target.value);
                            if (check) {
                              if (validDate1 !== "") {
                                setDate(
                                  moment(validDate1).format("YYYY-MM-DD")
                                );
                              }
                            } else {
                              setValidDate1(e.target.value);
                            }
                          }
                        }}
                        onClick={(e) => handlepicker1(e)}
                        onKeyPress={(event) => {
                          if (
                            !/[0-9]/.test(event.key) ||
                            event.target.value.length > 9
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(e) => keyDownExpiry(e)}
                        onResultSelect={dateSelect1}
                        showPicker={showpicker1}
                        validDate={validDate1}
                        closePicker={closePicker1}
                        min={moment(todate, "YYYY-MM-DD")}
                      />
                      {Dateerr && (
                        <ErrorMessage>Please Select Date</ErrorMessage>
                      )}
                      {Dateerrcheck && (
                        <ErrorMessage>Please Select Proper Date</ErrorMessage>
                      )}
                      {DatediffErrcheck && (
                        <ErrorMessage>
                          Please Select Order Date before Internal time
                          Collected
                        </ErrorMessage>
                      )}
                      <Imagecalendar
                        top="6px"
                        right="20px"
                        width="20px"
                        height="22px"
                        id="CustomDatePicker"
                        onClick={(e) => handlepicker1(e)}
                        src={calendar_icon_custom}
                      ></Imagecalendar>
                    </Div>
                  </Div>
                </Div>
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div padding="0 48px 0 0" margin="15px 0px 0px 0px">
                    <Label
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Priority<RedStar position="relative">*</RedStar>
                    </Label>
                    <Div
                      width="180px"
                      position="relative"
                      onClick={() => setShowOptions3(!ShowOptions3)}
                    >
                      <SelectInput
                        width="180px"
                        height="38px"
                        name="Priority"
                        background="#FFFFFF"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        color="#2E2E2E"
                        paddingTop="8px"
                        pl="10px"
                        margin="8px 0px 0px 0px"
                        value={LaborderoneList.order_priority}
                        style={
                          priorityerr
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                      >
                        {Priority}
                      </SelectInput>
                      <DropdownImage
                        top="16px"
                        right="10px"
                        src={dropdownDownicon}
                      ></DropdownImage>
                    </Div>
                  </Div>
                  {ShowOptions3 && PriorityDropdownOptionList3.length > 0 ? (
                    <DropDownDiv
                      width="178px"
                      height="149px"
                      background="#FFFFFF"
                      fontFamily="'Open Sans',sans-serif"
                      border=" 1px solid rgba(46, 46, 46, 0.25)"
                      borderRadius="4px"
                      marginTop="83px"
                    >
                      {PriorityDropdownOptionList3.map((p, i) => {
                        return (
                          <DropdownOptions
                            key={i}
                            value={p.value}
                            padding="10px 12px 8px 12px"
                            onClick={() => setpriority(p)}
                          >
                            {p.label}
                          </DropdownOptions>
                        );
                      })}
                    </DropDownDiv>
                  ) : (
                    ""
                  )}
                  {priorityerr && (
                    <ErrorMessage>Please Select Priority</ErrorMessage>
                  )}
                </Div>
              </Div>

              <Div
                display="flex"
                justifyContent="space-between"
                paddingBottom="15px"
              >
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div display="grid" marginTop="15px">
                    <Label
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      Status<RedStar position="relative">*</RedStar>
                    </Label>

                    <Div
                      position="relative"
                      onClick={() => setShowOptions4(!ShowOptions4)}
                    >
                      <SelectInput
                        width="180px"
                        name="status"
                        height="38px"
                        background="#FFFFFF"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        color="#2E2E2E"
                        paddingTop="8px"
                        pl="10px"
                        margin="8px 0px 0px 0px"
                        value={LaborderoneList.order_status}
                        style={
                          statuserr
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                      >
                        {status}
                      </SelectInput>
                      <DropdownImage
                        top="24px"
                        right="10px"
                        src={dropdownDownicon}
                      ></DropdownImage>
                    </Div>
                    {ShowOptions4 && statusDropdownOptionList4.length > 0 ? (
                      <DropDownDiv
                        width="178px"
                        height="149px"
                        background="#FFFFFF"
                        fontFamily="'Open Sans',sans-serif"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        marginTop="67px"
                      >
                        {statusDropdownOptionList4.map((p, i) => {
                          return (
                            <DropdownOptions
                              key={i}
                              value={p.value}
                              padding="10px 12px 8px 12px"
                              onClick={() => setsatuspro(p)}
                            >
                              {p.label}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      ""
                    )}
                    {statuserr && (
                      <ErrorMessage>Please Select Invalid status</ErrorMessage>
                    )}
                  </Div>
                </Div>
                <Div display="flex" flexDirection="column" width="47.5%">
                  <Div display="grid" marginTop="15px">
                    <Label
                      size="14px"
                      lineHeight="19px"
                      weight="400"
                      color="#2E2E2E"
                      fontFamily="'Open Sans',sans-serif"
                    >
                      History Order
                    </Label>

                    <Div
                      position="relative"
                      onClick={() => setShowOptions5(!ShowOptions5)}
                    >
                      <SelectInput
                        width="180px"
                        height="38px"
                        name="history_order"
                        background="#FFFFFF"
                        fontSize="14px"
                        fontFamily="'Open Sans',sans-serif"
                        border="1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        color="#2E2E2E"
                        pl="10px"
                        paddingTop="8px"
                        margin="8px 0px 0px 0px"
                        marginBottom="15px"
                        value={LaborderoneList.history_order}
                      >
                        {history}
                      </SelectInput>
                      <DropdownImage
                        top="24px"
                        right="10px"
                        src={dropdownDownicon}
                      ></DropdownImage>
                    </Div>
                    {ShowOptions5 && historyDropdownOptionList5.length > 0 ? (
                      <DropDownDiv
                        width="178px"
                        height="81px"
                        background="#FFFFFF"
                        fontFamily="'Open Sans',sans-serif"
                        border=" 1px solid rgba(46, 46, 46, 0.25)"
                        borderRadius="4px"
                        marginTop="68px"
                      >
                        {historyDropdownOptionList5.map((p, i) => {
                          return (
                            <DropdownOptions
                              key={i}
                              value={p.value}
                              padding="10px 12px 8px 12px"
                              onClick={() => sethistorypro(p)}
                            >
                              {p.label}
                            </DropdownOptions>
                          );
                        })}
                      </DropDownDiv>
                    ) : (
                      ""
                    )}
                  </Div>
                </Div>
              </Div>
              <Div
                borderBottom="1px solid #E2E8ED"
                margin=" 0px -21px 0px -24px"
              />
              <Div padding="16px 5px 20px 24px" style={{ height: "120px" }}>
                <Label
                  size="14px"
                  lineHeight="19px"
                  weight="400"
                  color="#2E2E2E"
                  fontFamily="'Open Sans',sans-serif"
                  marginLeft="-21px"
                >
                  Procedure
                </Label>
                {inputList.map((x, i) => (
                  <>
                    <Div
                      key={`${i}inputList`}
                      display="flex"
                      justifyContent="center"
                      alienItem="center"
                      marginTop="8px"
                    >
                      <Div width="100%" display="flex" position="relative">
                        <SearchIconSVG style={{ left: "-15px" }}>
                          <AiOutlineSearch />
                        </SearchIconSVG>

                        <Input
                          type="search"
                          name="search"
                          fontSize="15px"
                          paddingLeft="28px"
                          border="1px solid rgba(46, 46, 46, 0.25)"
                          height="36px"
                          width="100%"
                          style={inputstyle(i)}
                          borderRadius="4px"
                          value={inputList[i].procedure}
                          onChange={(e) => handleDrug(e, i)}
                          autoComplete="off"
                        />
                        {dropdownOpen[i] &&
                        (loading || hcarr.length >= 0 || procedureErr) ? (
                          <>
                            <AllergyDropdown
                              style={{ marginLeft: "-24px", zIndex: "2" }}
                            >
                              {loading ? (
                                <Text
                                  padding="10px 12px 8px 12px"
                                  fontSize="15px"
                                >
                                  Loading...
                                </Text>
                              ) : hcarr.length > 0 ? (
                                hcarr.map((option, j) => (
                                  <AllergyOption
                                    key={option}
                                    onClick={() => handleOptionClick(option, i)}
                                  >
                                    {option}
                                  </AllergyOption>
                                ))
                              ) : (
                                <Text
                                  padding="10px 12px 8px 12px"
                                  fontSize="15px"
                                >
                                  No results found
                                </Text>
                              )}
                            </AllergyDropdown>
                          </>
                        ) : null}
                      </Div>

                      <BsPlusSquare
                        onClick={handleAddClick}
                        style={{
                          cursor: "pointer",
                          fontSize: "26px",
                          color: "#2C7BE5",
                          marginTop: "5px",
                        }}
                      />
                      {inputList.length !== 0 && (
                        <FiTrash2
                          onClick={() => handleRemoveClick(i)}
                          style={{
                            cursor: "pointer",
                            fontSize: "25px",
                            border: "1px solid #f24822",
                            marginLeft: "14px",
                            marginRight: "-4px",
                            borderRadius: "4px",
                            color: "#B00020",
                            marginTop: "5px",
                            fill: "#ffffff",
                            stroke: "#f24822",
                          }}
                        />
                      )}
                    </Div>
                    {(inputList[i].errorStatus === true ||
                      inputList[i].matchStatus === true) && (
                      <ErrorMessage margin=" 0.25rem 0 0 -20px">
                        Please Select anyone from Dropdown
                      </ErrorMessage>
                    )}
                  </>
                ))}
              </Div>
            </ModalBody>
            <ModalFooter padding="1rem 23px" style={{ height: "83px" }}>
              <Button
                fontSize="14px"
                fontWeight="600"
                lineHeight="19px"
                borderRadius="4px"
                fontFamily="'Open Sans', sans-serif"
                border="1px solid #2C7BE5"
                padding="9px 31px 8px 31px"
                background="#FFF"
                color="#2C7BE5"
                cursor="pointer"
                marginRight="14px"
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                activeBackgroundColor="rgba(44, 123, 229, 0.10)"
                onClick={handleclose1}
              >
                <Span
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19px"
                  fontFamily="'Open Sans', sans-serif"
                >
                  Close
                </Span>
              </Button>
              {procedureOrder !== "Add New" ? (
                <>
                  <Button
                    type="submit"
                    borderRadius="4px"
                    cursor="pointer"
                    fontFamily="'Open Sans', sans-serif"
                    border="1px solid #2C7BE5"
                    padding="8px 22px 7px 24px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    marginRight="11px"
                    onClick={HandleSaveLab}
                    backgroundColor="#2C7BE5"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    disabled={dis}
                    isDisabled={dis}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="19px"
                    >
                      Update
                    </Span>
                  </Button>
                  <Button
                    type="submit"
                    borderRadius="4px"
                    cursor="pointer"
                    fontFamily="'Open Sans', sans-serif"
                    border="1px solid #2C7BE5"
                    padding="8px 10px 7px 11px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    onClick={HandleSaveTrans}
                    backgroundColor="#2C7BE5"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    disabled={dis}
                    isDisabled={dis}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="19px"
                    >
                      Update & Transmit
                    </Span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    borderRadius="4px"
                    cursor="pointer"
                    fontFamily="'Open Sans', sans-serif"
                    border="1px solid #2C7BE5"
                    padding="8px 33px 7px 32px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    marginRight="15px"
                    disabled={btnDisabled || dis}
                    onClick={HandleSaveLab}
                    backgroundColor="#2C7BE5"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    isDisabled={dis}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="19px"
                    >
                      Save
                    </Span>
                  </Button>
                  <Button
                    type="submit"
                    borderRadius="4px"
                    cursor="pointer"
                    fontFamily="'Open Sans', sans-serif"
                    border="1px solid #2C7BE5"
                    padding="8px 14px 7px 15px"
                    background="#2C7BE5"
                    color="#FFFFFF"
                    onClick={HandleSaveTrans}
                    disabled={dis}
                    backgroundColor="#2C7BE5"
                    hoverBackColor="#005FB2"
                    activeBackgroundColor="rgba(44, 123, 229, 0.75)"
                    isDisabled={dis}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="600"
                      fontFamily="'Open Sans', sans-serif"
                      lineHeight="19px"
                    >
                      Save & Transmit
                    </Span>
                  </Button>
                </>
              )}
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default AddLaborder;
