import { createSlice } from "@reduxjs/toolkit";

export const DeviceSlice = createSlice({
  name: "Device",
  initialState: {
    AllDevices: [],
    TotalRecords: [],
  },
  reducers: {
    SetAllDevices: (state, action) => {
      state.AllDevices = action.payload;
    },
    GetAllDevices: (data) => {
      return data;
    },

    setTotalRecords: (state, action) => {
      state.TotalRecords = action.payload;
    },

    getRecords: (data) => {
      return data;
    },
  },
});

export const { SetAllDevices, GetAllDevices, setTotalRecords, getRecords } =
  DeviceSlice.actions;

export default DeviceSlice.reducer;
