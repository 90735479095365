import styled from "styled-components";

import master from "../../../Assets/images/masterindex0.png";
import discover from "../../../Assets/images/discoverindex0.png";
import diners from "../../../Assets/images/dinersindex0.png";
import amex from "../../../Assets/images/amexindex0.png";
import Visa from "../../../Assets/images/visaindex0.png";
import master1 from "../../../Assets/images/masterindex1.png";
import discover1 from "../../../Assets/images/discoverindex1.png";
import diners1 from "../../../Assets/images/dinersindex1.png";
import amex1 from "../../../Assets/images/amexindex1.png";
import Visa1 from "../../../Assets/images/visaindex1.png";

const backgroundUrl = (cardType, i) => {
  let cardType1 = cardType.replace(/ /g, "").toLowerCase();
  switch (cardType1) {
    case "visa":
      if (i !== 0) {
        return Visa;
      } else {
        return Visa1;
      }
      break;
    case "americanexpress":
      if (i !== 0) {
        return amex;
      } else {
        return amex1;
      }
      break;
    case "americanexpresscorporate":
      if (i !== 0) {
        return amex;
      } else {
        return amex1;
      }
      break;
    case "mastercard":
      if (i !== 0) {
        return master;
      } else {
        return master1;
      }
      break;
    case "mastercard2":
      if (i !== 0) {
        return master;
      } else {
        return master1;
      }
      break;
    case "discover":
      if (i !== 0) {
        return discover;
      } else {
        return discover1;
      }
      break;
    case "disco2":
      if (i !== 0) {
        return discover;
      } else {
        return discover1;
      }
      break;
    case "disco3":
      if (i !== 0) {
        return discover;
      } else {
        return discover1;
      }
      break;
    case "dinersclub":
      if (i !== 0) {
        return diners;
      } else {
        return diners1;
      }
      break;
    default:
      return "none";
  }
};

export const TabPanel = styled.div`
  width: 100%;
  height: 61px;
  background: #ffffff;
  border-bottom: 1px solid #d5ddec;
  display: flex;
  justify-content: flex-start;
`;

export const Tabs = styled.div`
  width: 52px;
  height: 16px;
  font-family: ${(props) => (props.active ? "'Open Sans'" : "'Open Sans'")},
    sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-left: 28px;
  margin-top: 26px;
  margin-bottom: 7px;
  color: ${(props) => (props.active ? " #2C7BE5" : "rgba(46, 46, 46, 0.25)")};
  cursor: pointer;
  ${(props) => {
    if (props.line === "1") {
      return `
              &::after {
                        content: "";
                        width: 48px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 17px;
                        transform : translateX(0px);
                        transition: transform 1s;
                         }
               `;
    }

    if (props.line === "2") {
      return `
              &::after {
                        content: "";
                        width: 75px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 17px;
                        transform : translateX(80px);
                        transition: transform 1s;
                         }
               `;
    }
  }}
`;
export const TitleHead = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #2e2e2e;
  font-family: "Open Sans", sans-serif;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;
export const FieldHead = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2e2e2e;
  font-family: "Open Sans", sans-serif;
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  width: ${(p) => p.width && p.width};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  height: 12px;
  width: 12px;
`;
export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  text-align: left;
  margin: ${(p) => p.margin && p.margin};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "80%")};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  position: ${(p) => p.position && p.position};
`;
export const FileInput = styled.input`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "133px")};
  position: absolute;
  opacity: 0;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer !important")};
  margin-top: -52px;
  width: 411px;
  .not-allowed-cursor {
    cursor: not-allowed;
  }
`;

export const CardContainer = styled.div`
  background: ${(props) =>
    props.selected
      ? "#FFFFFF"
      : "linear-gradient(104deg, #194bfb 6.07%, #3a49f9 93.22%)"};
  border-radius: 8px;
  margin-right: ${(p) => (p.marginRight ? p.marginRight : "24px")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  cursor: ${(p) => (p.cursor ? p.cursor : "pointer !important")};
  height: 182px;
  width: ${(p) => (p.width ? p.width : "320px")};
  border: ${(props) => props.selected && "1px solid #3f8cff"};
  padding: ${(p) => (p.padding ? p.padding : "20px 16px")};
  position: relative;
  overflow: hidden;
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
`;
export const CardGroup = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-left: -4px;
  margin-bottom: -4px;
`;
export const DispFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const VisaImg = styled.img.attrs((props) => ({
  src: backgroundUrl(props.img, props.selected),
}))`
  width: max-content;
  height: max-content;
`;
export const LogoChip = styled.img`
  width: 53px;
  height: 35px;
  margin-bottom: 20px;
`;

export const CardTxtSpan = styled.span`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

export const CardNum = styled(CardTxtSpan)`
  height: 19px;
  line-height: 19px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  color: ${(props) => (props.selected ? "#2D3748" : "#ffff")};
  margin-left: 4px;
  margin-top: -15px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
`;

export const HoldTxt = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HoldStatTxt = styled(HoldTxt)`
  margin-top: 26px;
`;

export const HoldDynTxt = styled(HoldTxt)`
  margin-top: 11px;
`;

export const HoldName = styled.span`
  height: 19px;
  left: 76.25%;
  right: 14.31%;
  top: calc(50% - 19px / 2 - 112px);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${(props) => (props.selected ? "#2D3748" : "#ffff")};
`;

export const ValidTxt = styled(CardTxtSpan)`
  line-height: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: ${(props) => (props.selected ? "#2D3748" : "#ffff")};

  width: 45px;
`;

export const ValidThr = styled(ValidTxt)`
  font-size: 16px;
  color: ${(props) => (props.selected ? "#2D3748" : "#ffff")};
`;
export const CardHoldTxt = styled(CardTxtSpan)`
  height: 16px;
  top: calc(50% - 16px / 2 - 137.5px);
  line-height: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: ${(props) => (props.selected ? "#2D3748" : "#ffff")};
`;
export const RadOpacityBg = styled.div`
  width: 321px;
  height: 226px;
  background: transparent;
  border: ${(props) =>
    props.selected ? "113px solid #f7f7f7" : "113px solid #ffffff59"};
  border-radius: 50%;
  margin-top: -313px;
  margin-left: 101px;
`;
export const RadOpacityBg1 = styled(RadOpacityBg)`
  margin-top: 12px;
  margin-left: -155px;
  border: ${(props) =>
    props.selected ? "113px solid #f7f7f7" : "113px solid #ffffff59"};
`;
export const Tabs1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tab = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 36px;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;

  &:not(:last-child) {
    border-right: none;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  & path {
    pointer-events: none;
  }
  ${({ active }) =>
    active ? "background : #2C7BE5;" : "background : #FFFFFF;"}
  border: 1px solid rgba(46, 46, 46, 0.25);
  ${({ active }) => (active ? "color : white;" : "color : #2E2E2E;")}
  ${({ alert }) =>
    alert
      ? `& svg{
      display : block;
      height : 20px;
      width : 20px;
      color : red;      
    }`
      : `& svg{
      display : none;
    }`}
`;

export const Tab1 = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: auto;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  padding: ${(p) => (p.padding ? p.padding : "8px 16px")};
  &:not(:last-child) {
    border-right: none;
    // border-bottom: none;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
    // border-bottom: none;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
    // border-bottom: none;
  }

  & path {
    pointer-events: none;
  }
  ${({ active }) =>
    active ? "background : #2C7BE5;" : "background : #FFFFFF;"}
  border: 1px solid rgba(46, 46, 46, 0.25);
  ${({ active }) => (active ? "color : white;" : "color : #2E2E2E;")}
  ${({ alert }) =>
    alert
      ? `& svg{
      display : block;
      height : 20px;
      width : 20px;
      color : red;      
    }`
      : `& svg{
      display : none;
    }`}
`;
export const Span1 = styled.span`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  color: ${(p) => p.color && p.color};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  overflow: ${(p) => p.overflow && p.overflow};
`;
