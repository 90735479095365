import styled from "styled-components";
import { ModalBody } from "../StyledComponents";
export const Imagecalendar = styled.img`
  position: absolute;
  object-fit: contain;
  cursor: pointer;
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  left: ${(p) => p.left && p.left};
`;
export const TextArea = styled.textarea`
  outline: none;
  width: 100%;
  min-height: 80px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
  resize: ${(p) => p.resize && p.resize};
  &:hover {
    border: 1px solid rgba(46, 46, 46, 0.25);
  }
`;
export const Modal = styled.div`
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: 1255;
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100vh")};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;
export const Wrapper = styled.div`
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const ModalBodyDoc = styled(ModalBody)`
  &::-webkit-scrollbar-thumb {
    background: rgba(46, 46, 46, 0.5);
    border-radius: 100px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: lightgray;
  }
`;

export const DropDown = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 8px;
  svg {
    font-size: 12px;
    margin-left: 7px;
  }
  margin-right: ${(p) => p.marginRight && p.marginRight};
  width: ${(p) => p.width && p.width};
  position: ${(p) => p.position && p.position};
`;

export const DropdownImage = styled.img`
  height: ${(p) => (p.height ? p.height : "8px")};
  width: ${(p) => (p.width ? p.width : "12px")};
  position: absolute;
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
`;
