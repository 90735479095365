import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight, BiPencil } from "react-icons/bi";
import { MdArrowDropDown } from "react-icons/md";
import { useMemo } from "react";
import moment from "moment";
import YearBlock from "./YearBlock";
import { DatePickerInput, DayButtons, Div, Span, P } from "./styles";
const CustomCalendar = (props) => {
  const [showDate, setShowDate] = useState(props.todayDate);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDate1, setSelectedDate1] = useState({});
  const [selectedMonth, setSelectedMonth] = useState("");
  const [showSpan, setShowSpan] = useState(true);
  const [showYear, setShowYear] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [val, setVal] = useState("");
  const currentDate = new Date();
  const week = { nl: ["S", "M", "T", "W", "T", "F", "S"] };

  // first day of the month, CAREFULL: object will change by for loop!
  const firstDayThisMonth = new Date(
    showDate.getFullYear(),
    showDate.getMonth(),
    1
  );
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const disabledDate = props.disabledDates ?? 0;
  const maxDate = props.max ?? null;
  const minDate = props.min ?? null;

  const dayOfWeek = (firstDayThisMonth.getDay() + 7) % 7;
  // first day of next month
  const firstDayNextMonth = new Date(
    showDate.getFullYear(),
    showDate.getMonth() + 1,
    1
  );

  const month = useMemo(() => {
    const m = [];
    for (
      let d = firstDayThisMonth;
      d < firstDayNextMonth;
      d.setDate(d.getDate() + 1)
    ) {
      m.push(new Date(d));
    }

    return m;
  }, [showDate]);

  const MonthForward = () => {
    if (showDate.getMonth() == 11) {
      if (showDate.getFullYear() == 2099) {
        return;
      } else {
        handleMonthNav(new Date(showDate.getFullYear() + 1, 0, 1));
        setShowDate(new Date(showDate.getFullYear() + 1, 0, 1));
      }
    } else {
      handleMonthNav(
        new Date(showDate.getFullYear(), showDate.getMonth() + 1, 1)
      );
      setShowDate(new Date(showDate.getFullYear(), showDate.getMonth() + 1, 1));
    }
  };

  const MonthBackward = () => {
    if (showDate.getMonth() == 0) {
      if (showDate.getFullYear() == 1900) {
        return;
      } else {
        handleMonthNav(new Date(showDate.getFullYear() - 1, 11, 1));
        setShowDate(new Date(showDate.getFullYear() - 1, 11, 1));
      }
    } else {
      handleMonthNav(
        new Date(showDate.getFullYear(), showDate.getMonth() - 1, 1)
      );
      setShowDate(new Date(showDate.getFullYear(), showDate.getMonth() - 1, 1));
    }
  };

  const handleMonthNav = (d) => {
    let monthName = d.toLocaleString("default", { month: "long" });

    let yearName = d.getFullYear();

    setSelectedMonth(` ${monthName}` + " " + `${yearName}`);
  };

  const handleDate = (e, d) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedDate1(d);
    setShowDate(d);
    let day = weekdays[d.getDay()];
    let monthshort = d.toLocaleString("default", { month: "short" });
    let date = d.getDate();
    setSelectedDate(`${day}, ${monthshort} ${date}`);
    setVal(moment(d).format("YYYY-MM-DD"));
    handleMonthNav(d);
    setDateErr(false);
  };

  const selectYear = (e) => {
    e.preventDefault();
    setShowYear(!showYear);
    let yearName = showDate.getFullYear();
    scrollToYear(yearName);
  };
  const YearSelected = (e) => {
    e.preventDefault();
    setShowYear(false);
  };

  const scrollToYear = (yearName) => {
    setTimeout(() => {
      const el = document.getElementById(yearName);

      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 1000);
  };

  const setYear = (year) => {
    let month = currentDate.getMonth();
    let date = currentDate.getDate();
    setShowDate(new Date(currentDate.getFullYear() + year, month, date));
    handleMonthNav(new Date(currentDate.getFullYear() + year, month, date));
  };

  const InitialLoad = (d) => {
    let day = weekdays[d.getDay()];
    setShowDate(d);
    let monthshort = d.toLocaleString("default", { month: "short" });

    let date = d.getDate();
    setSelectedDate(`${day}, ${monthshort} ${date}`);
    // setVal(moment(d).format("YYYY-MM-DD"));
    handleMonthNav(d);
  };

  function isValidDate(val) {
    var dateObject = new Date(val);
    return dateObject instanceof Date && !isNaN(dateObject);
  }

  const hidespan = (e) => {
    e.stopPropagation();
    setShowSpan(!showSpan);
  };
  const keyDownExpiry = (event) => {
    const inp = event.target;
    const key = event.keyCode || event.charCode;

    const inputValue = inp.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const caretPosition = inp.selectionStart;

    if (key !== 8 && key !== 46) {
      if (numericValue.length === 4 && caretPosition === 4) {
        inp.value = numericValue + "-";
      } else if (numericValue.length === 6 && caretPosition === 7) {
        inp.value =
          numericValue.slice(0, 4) + "-" + numericValue.slice(4, 6) + "-";
      } else if (numericValue.length > 6 && caretPosition === 10) {
        inp.value =
          numericValue.slice(0, 4) +
          "-" +
          numericValue.slice(4, 6) +
          "-" +
          numericValue.slice(6, 8);
      }
    }
  };
  useEffect(() => {
    InitialLoad(props.todayDate);
    if (props.result && props.result !== "") {
      setSelectedDate1(new Date(props.todayDate));
      setVal(moment(props.todayDate).format("YYYY-MM-DD"));
    } else {
      setSelectedDate1("");
      // setVal("");
    }
  }, [props.todayDate, props.result]);

  return (
    <>
      <Div id="CustomDatePicker">
        <>
          <Div
            id="CustomDatePicker"
            width="260px"
            height="max-content !important"
            position="absolute"
            backgroundColor="white"
            radius=" 4px"
            style={{
              boxShadow: "0px 3.18977px 19.1386px rgba(0, 0, 0, 0.25)",
              position: "absolute",
              zIndex: "100",
              right: props.right,

            }}
            
          >
            {/* /*------------- head--------------*/}
            <Div
              id="CustomDatePicker"
              padding="20px 20px 0px 20px "
              style={{ borderBottom: "1px solid #d9d9d9" }}
            >
              <P
                id="CustomDatePicker"
                textAlign="left"
                color="#2E2E2E"
                weight="500"
                family="Roboto ,Work Sans"
                lheight="13px"
                size="12px"
              >
                Select Date
              </P>
              <Div
                id="CustomDatePicker"
                className="custom-date-calendar"
                dis="flex"
                justifyContent="space-between"
                width="100%"
                backgroundColor="white"
                margin="28px 0px 10px 0px"
              >
                {showSpan && (
                  <Span
                    id="CustomDatePicker"
                    style={{
                      fontSize: "26px",
                      fontFamily: "Roboto ,Work Sans",
                      lineHeight: "32px",
                      marginLeft: "0px",
                      fontWeight: "500",
                    }}
                  >
                    {selectedDate}
                  </Span>
                )}
                {!showSpan && (
                  <DatePickerInput
                    paddingLeft="0px !important"
                    borderRadius="0px"
                    borderTop="none"
                    borderLeft="none"
                    borderRight="none"
                    borderBottom={
                      dateErr ? " 1px solid red " : "1px solid #d9d9d9"
                    }
                    fontSize="20px"
                    fontFamily="Roboto ,Work Sans"
                    lineHeight="26px"
                    fontWeight="500"
                    id="date1"
                    value={val}
                    style={{
                      marginLeft: "0px",

                      width: "60%",
                    }}
                    placeholder="YYYY-MM-DD"
                    onChange={(e) => {
                      setVal(e.target.value);
                      let d = moment(
                        e.target.value,
                        "YYYY-MM-DD",
                        true
                      ).isValid();
                      setDateErr(true);
                      if (e.target.value === "") {
                        setSelectedDate1("");
                        InitialLoad(new Date());
                        setDateErr(false);
                      } else if (d) {
                        let check = moment(e.target.value).isAfter(
                          props.max ? props.max : null
                        )
                          ? true
                          : moment(e.target.value).isBefore(
                              props.min ? props.min : null
                            )
                          ? true
                          : disabledDate === 1
                          ? new Date(e.target.value).getDay() === 0
                            ? true
                            : false
                          : disabledDate === 2
                          ? new Date(e.target.value).getDay() === 0 ||
                            new Date(e.target.value).getDay() === 6
                            ? true
                            : false
                          : false;

                        if (check) {
                          setDateErr(true);
                          if (selectedDate1 !== "") {
                            setSelectedDate1(new Date(selectedDate1));
                            setVal(moment(selectedDate1).format("YYYY-MM-DD"));
                            InitialLoad(new Date(selectedDate1));
                          }
                        } else {
                          setDateErr(false);
                          setSelectedDate1(new Date(e.target.value));
                          InitialLoad(new Date(e.target.value));
                        }
                      }
                    }}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        event.target.value.length > 9
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => keyDownExpiry(e)}
                  />
                )}

                <BiPencil
                  id="CustomDatePicker"
                  style={{
                    fontSize: "24px",
                    fontFamily: "Roboto ,Work Sans",
                    lineHeight: "32px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => hidespan(e)}
                />
              </Div>
            </Div>
            {/* /*------------- head--------------*/}
            {/* /*------------- body--------------*/}
            <Div
              id="CustomDatePicker"
              padding="20px 20px 0px 20px "
              dis="flex"
              justifyContent={!showYear ? "space-between" : "flex-start"}
            >
              <Div
                id="CustomDatePicker"
                dis="flex"
                justifyContent="flex-start"
                style={{ cursor: "pointer" }}
                onClick={selectYear}
              >
                <DayButtons
                  id="CustomDatePickerbuttons"
                  border="none"
                  padding="4px 20px 4px 0px"
                  backgroundColor="white"
                  fontFamily="Roboto ,Work Sans"
                  fontSize="15px"
                  lineHeight="20px"
                  fontWeight="500"
                  style={{}}
                >
                  {selectedMonth}
                </DayButtons>
                <MdArrowDropDown
                  id="CustomDatePicker"
                  style={{ color: "#2E2E2E", fontSize: "18px" }}
                />
              </Div>
              <Div
                id="CustomDatePicker"
                dis={showYear ? "none" : "flex"}
                justifyContent="space-between"
              >
                <BiChevronLeft
                  id="CustomDatePicker"
                  style={{
                    fontSize: "20px",
                    color: "#2e2e2e",
                    cursor: "pointer",
                  }}
                  onClick={MonthBackward}
                />
                <BiChevronRight
                  id="CustomDatePicker"
                  style={{
                    fontSize: "20px",
                    color: "#2e2e2e",
                    cursor: "pointer",
                  }}
                  onClick={MonthForward}
                />
              </Div>
            </Div>
            {/* Calendar */}
            {!showYear && (
              <Div id="CustomDatePicker">
                <Div
                  id="CustomDatePicker"
                  dis="flex"
                  width="100%"
                  justifyContent="space-between"
                  padding="20px 5px 10px 5px"
                >
                  {week.nl.map((weekDay, i) => (
                    <Div
                      id="CustomDatePicker"
                      key={`${weekDay}${i}`}
                      justifyContent="space-between"
                      style={{ flex: "0 0 14.28%" }}
                      textAlign="center"
                    >
                      <small
                        id="CustomDatePicker"
                        style={{
                          fontFamily: "Roboto ,Work Sans",
                          fontWeight: "400",
                          fontSize: "10px",
                          lineHeight: "13px",
                          color: "#2e2e2e",
                        }}
                      >
                        {weekDay}
                      </small>
                    </Div>
                  ))}
                </Div>
                <Div
                  dis="flex"
                  style={{ flexWrap: "wrap", margin: "0px 5px" }}
                  id="CustomDatePicker"
                >
                  <div style={{ width: `${dayOfWeek * 14.28}%` }} />
                  {month.map((day) => {
                    return (
                      <Div
                        id="CustomDatePicker"
                        key={day}
                        dis="flex"
                        justifyContent="center"
                        style={{ flex: "0 0 14.28%" }}
                        textAlign="center"
                      >
                        <DayButtons
                          id="CustomDatePickerbuttons"
                          borderRadius="15px"
                          fontSize="14px"
                          width="30px"
                          height="30px"
                          border={
                            JSON.stringify(day.getDate()) ===
                              JSON.stringify(currentDate.getDate()) &&
                            JSON.stringify(day.getMonth()) ===
                              JSON.stringify(currentDate.getMonth()) &&
                            JSON.stringify(day.getFullYear()) ===
                              JSON.stringify(currentDate.getFullYear())
                              ? "1px solid #2C7BE5"
                              : Object.prototype.toString.call(
                                  selectedDate1
                                ) === "[object Date]"
                              ? JSON.stringify(day.getDate()) ===
                                  JSON.stringify(selectedDate1.getDate()) &&
                                JSON.stringify(day.getMonth()) ===
                                  JSON.stringify(selectedDate1.getMonth()) &&
                                JSON.stringify(day.getFullYear()) ===
                                  JSON.stringify(selectedDate1.getFullYear())
                                ? "1px solid #2C7BE5"
                                : "none"
                              : "none"
                          }
                          backgroundColor={
                            Object.prototype.toString.call(selectedDate1) ===
                            "[object Date]"
                              ? JSON.stringify(day.getDate()) ===
                                  JSON.stringify(selectedDate1.getDate()) &&
                                JSON.stringify(day.getMonth()) ===
                                  JSON.stringify(selectedDate1.getMonth()) &&
                                JSON.stringify(day.getFullYear()) ===
                                  JSON.stringify(selectedDate1.getFullYear())
                                ? "#2C7BE5"
                                : "white"
                              : JSON.stringify(day.getDate()) ===
                                  JSON.stringify(currentDate.getDate()) &&
                                JSON.stringify(day.getMonth()) ===
                                  JSON.stringify(currentDate.getMonth()) &&
                                JSON.stringify(day.getFullYear()) ===
                                  JSON.stringify(currentDate.getFullYear())
                              ? "white"
                              : "white"
                          }
                          color={
                            Object.prototype.toString.call(selectedDate1) ===
                            "[object Date]"
                              ? JSON.stringify(day.getDate()) ===
                                  JSON.stringify(selectedDate1.getDate()) &&
                                JSON.stringify(day.getMonth()) ===
                                  JSON.stringify(selectedDate1.getMonth()) &&
                                JSON.stringify(day.getFullYear()) ===
                                  JSON.stringify(selectedDate1.getFullYear())
                                ? "white"
                                : "#2e2e2e"
                              : JSON.stringify(day.getDate()) ===
                                  JSON.stringify(currentDate.getDate()) &&
                                JSON.stringify(day.getMonth()) ===
                                  JSON.stringify(currentDate.getMonth()) &&
                                JSON.stringify(day.getFullYear()) ===
                                  JSON.stringify(currentDate.getFullYear())
                              ? "#2e2e2e"
                              : "#2e2e2e"
                          }
                          onClick={(e) => handleDate(e, day)}
                          disabled={
                            moment(day).isAfter(maxDate) ||
                            moment(day).isBefore(minDate)
                              ? true
                              : disabledDate === 1
                              ? day.getDay() === 0
                                ? true
                                : false
                              : disabledDate === 2
                              ? day.getDay() === 0 || day.getDay() === 6
                                ? true
                                : false
                              : false
                          }
                        >
                          {day.getDate()}
                        </DayButtons>
                      </Div>
                    );
                  })}
                </Div>
                <Div
                  id="CustomDatePicker"
                  dis="flex"
                  justifyContent="flex-end"
                  width="100%"
                  padding=" 20px 10px 20px 10px"
                >
                  <DayButtons
                    id="CustomDatePicker"
                    border="none"
                    backgroundColor="white"
                    color="#212529"
                    padding="2px 6px"
                    fontFamily="Roboto ,Work Sans"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="20px"
                    cursor="pointer"
                    style={{
                      marginRight: "10px",
                    }}
                    onClick={props.handlepicker}
                  >
                    Cancel
                  </DayButtons>
                  <DayButtons
                    id="CustomDatePicker"
                    border="none"
                    backgroundColor="white"
                    color="#2C7BE5"
                    padding="2px 6px"
                    fontFamily="Roboto ,Work Sans"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="20px"
                    cursor="pointer"
                    onClick={(e) => {
                      if (val === "" || !isValidDate(val)) {
                        setDateErr(true);
                        return false;
                      }

                      props.onResultSelect(selectedDate1);
                      // props.handlepicker(e, false);
                    }}
                  >
                    Ok
                  </DayButtons>
                </Div>
              </Div>
            )}

            <Div id="CustomDatePicker" dis={showYear ? "block" : "none"}>
              <YearBlock
                currentDate={props.todayDate}
                scrollToYear={scrollToYear}
                setYear={setYear}
                YearSelected={YearSelected}
              />
            </Div>

            {/* /*------------- body--------------*/}
          </Div>
        </>
      </Div>
    </>
  );
};

export default CustomCalendar;
