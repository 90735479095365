import { takeLatest } from "redux-saga/effects";
import {
  GetVitals,
  GetPatId,
  SendMsgData,
  getMessagerList,
  getPreviewDownload,
  emptyPreviewDowload,
  GetStatus,
  getReadingsData,
  AddDevices,
  getDeviceList,
  GetSerialCheck,
  GetDeviceConnection,
  GetTokenCheck,
  GetDisconnection,
} from "../../StateManagement/Reducers/PatientDemoState";

import {
  VitalsApi,
  SendMsgWorker,
  MessageList,
  PreviewDownloadApi,
  EmptyDownloadApi,
  PatientStatus,
  ReadingChartData,
  DevicesAdd,
  getDevices,
  serialNoCheckWorker,
  IntegrationWorker,
  TokenDetails,
  DisconnectWorker,
} from "./PatientDemoSagaWorkers";

export function* vitalsDemo() {
  yield takeLatest(GetPatId.type, VitalsApi);
}

export function* MsgSaga() {
  yield takeLatest(SendMsgData.type, SendMsgWorker);
}
export function* MessagerListSaga() {
  yield takeLatest(getMessagerList.type, MessageList);
}

export function* PreviewDownloadSagaM() {
  yield takeLatest(getPreviewDownload.type, PreviewDownloadApi);
}
export function* EmptyDownloadSagaM() {
  yield takeLatest(emptyPreviewDowload.type, EmptyDownloadApi);
}
export function* StatusSaga() {
  yield takeLatest(GetStatus.type, PatientStatus);
}
export function* AddDevicesSaga() {
  yield takeLatest(AddDevices.type, DevicesAdd);
}
export function* Devices() {
  yield takeLatest(getDeviceList.type, getDevices);
}
export function* ReadingChart() {
  yield takeLatest(getReadingsData.type, ReadingChartData);
}
export function* SerialNoCheckWatcher() {
  yield takeLatest(GetSerialCheck.type, serialNoCheckWorker);
}

export function* IntegrationWatcher() {
  yield takeLatest(GetDeviceConnection.type, IntegrationWorker);
}

export function* TokenChecked() {
  yield takeLatest(GetTokenCheck.type, TokenDetails);
}

export function* DisconnectWatcher() {
  yield takeLatest(GetDisconnection.type, DisconnectWorker);
}
