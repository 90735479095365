import React from "react";
import { Container, Div } from "../../StyledComponents";
import LeftNav from "../../LeftNav";
import TopNav from "../../TopNav";
import PatientList from "./PatientList";

const PatientDemo = () => {
  return (
    <Container backgroundColor="#F1F5F8">
      <TopNav />
      <LeftNav />
      <Div
        marginLeft="56px"
        backgroundColor="#F1F5F8"
        className="responsive-right"
      >
        <Div margin="14px 22px 24px 20px">
          <PatientList />
        </Div>
      </Div>
    </Container>
  );
};
export default PatientDemo;
