import styled, { css } from "styled-components";
import { Div, Input } from "../StyledComponents";

export const TextArea = styled.textarea`
  border: ${(p) => p.border && p.border};
  border-radius: 4px;
  padding: 5px 8px 0 8px;
  line-height: 12px;
  min-height: 16px;
  max-height: 150px;
  overflow-y: auto;
  resize: none;
  overflow-y: hidden;
  width: ${(p) => (p.width ? p.width : "100%")};
  outline: none;
  margin-top: 4px;
  box-sizing: border-box;
  &::placeholder {
    color: ${(p) => p.pColor && p.pColor};
    font-family: ${(p) => p.pfontFamily && p.pfontFamily};
    font-size: ${(p) => p.pfontSize && p.pfontSize};
    padding-top: 3px;
  }
  height: ${(p) => p.height && p.height};
  &.planwidth {
    width: 88%;
    @media (max-width: 820px) {
      width: 93%;
    }
  }
`;

export const PaginDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e8ed;
  border-top: none;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${(props) =>
    props.hidePagination &&
    css`
      display: none;
    `}
`;

export const TableDiv = styled.div`
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow-x: ${(p) => p.overflowX && p.overflowX};

  &.responsive-right {
    @media screen and (max-width: 1071px) {
      position: absolute;
    }
  }

  &.TableLayout {
    overflow-x: auto;
    max-width: 100%;
  }

  &.TableLayout > table {
    table-layout: fixed;
    width: 100%;
  }
  &.TableLayout th,
  &.TableLayout td {
    white-space: normal;
    word-wrap: break-word;
    width: 30%;
  }

  @media (min-width: 250px) and (max-width: 1260px) {
    &.TableLayout > table {
      table-layout: auto;
    }
    &.TableLayout th,
    &.TableLayout td {
      word-wrap: break-word;
      overflow-wrap: break-word;
      /* overflow: hidden; */
      white-space: normal;
      text-overflow: clip;
    }
  }
`;

export const DropdownImage = styled.img`
  height: 8px;
  width: 12px;
  position: absolute;
  right: ${(p) => p.right && p.right};
  top: ${(p) => p.top && p.top};
`;

export const SearchIcon = styled.svg`
  z-index: 1;
  width: 18px;
  height: 18px;
  position: absolute;
  top: ${(p) => (p.top ? p.top : "10px")};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  left: ${(p) => (p.left ? p.left : "8px")};
`;

export const Divresponsive = styled(Div)`
  @media (max-width: 820px) {
    width: 100%;
  }
`;
export const MonitorDiv = styled(Div)`
  &.monitordiv {
    width: 88.1%;
    @media (max-width: 820px) {
      width: 93.1%;
    }
  }
  &.meddiv {
    width: 88%;
    @media (max-width: 820px) {
      width: 93%;
    }
  }
`;
export const TreatInput = styled(Input)`
  &.diaginput {
    width: 88%;
    @media (max-width: 820px) {
      width: 93%;
    }
  }
`;
