import { createSlice } from "@reduxjs/toolkit";

export const PatientDemoSlice = createSlice({
  name: "PatientDemo",
  initialState: {
    vitalsData: {},
    MsgRes: {},
    MessageList: [],
    previewdownload: "",
    readingsData: {},
    Device: {},
    DeviceRes: "",
    DeviceList: [],
    serialNoCheck: "",
    Status: "",
    marsoniksource: "",
    IntegrationResponse: "",
    tokenCheck: "",
    patientDemotimer: [],
    FilteredDeviceList: [],
  },
  reducers: {
    GetVitals: (state, action) => {
      state.vitalsData = action.payload;
    },

    GetPatId: (Data) => {
      return Data;
    },

    SendMsgData: (data) => {
      return data;
    },

    getMessagerList: (data) => {
      return data;
    },
    setMessageList: (state, action) => {
      state.MessageList = action.payload;
    },

    MsgResponse: (state, action) => {
      state.MsgRes = action.payload;
    },
    getPreviewDownload: (filename) => {
      return filename;
    },
    setPreviewDownload: (state, action) => {
      state.previewdownload = action.payload;
      state.Loading = false;
    },
    clearPreviewDownload: (state) => {
      state.previewdownload = "";
    },
    emptyPreviewDowload: (data) => {
      return data;
    },
    GetStatus: (state) => {
      return state;
    },
    SetStatus: (state, action) => {
      state.Status = action.payload;
    },
    getReadingsData: (data) => {
      return data;
    },

    setReadingsData: (state, action) => {
      state.readingsData = action.payload;
    },
    AddDevices: (user) => {
      return user;
    },
    DeviceResponse: (state, action) => {
      state.DeviceRes = action.payload;
    },
    getDeviceList: (data) => {
      return data;
    },
    setDeviceList: (state, action) => {
      state.DeviceList = action.payload;
    },
    setFiltDeviceList: (state, action) => {
      state.FilteredDeviceList = action.payload;
    },
    GetSerialCheck: (data) => {
      return data;
    },
    SetSerialCheck: (state, action) => {
      state.serialNoCheck = action.payload;
    },
    setMarsonikSource: (state, action) => {
      state.marsoniksource = action.payload;
    },
    GetDeviceConnection: (data) => {
      return data;
    },
    setIntegrationResponse: (state, action) => {
      state.IntegrationResponse = action.payload;
    },
    GetTokenCheck: (data) => {
      return data;
    },
    SetTokenCheck: (state, action) => {
      state.tokenCheck = action.payload;
    },
    GetDisconnection: (data) => {
      return data;
    },

    SetpatientDemotimer: (state, action) => {
      const { patId, timer } = action.payload;
      state.patientDemotimer = { patId, timer };
    },
  },
});

export const {
  GetVitals,
  GetPatId,
  MsgResponse,
  SendMsgData,
  sendFile,
  setMessageList,
  getMessagerList,
  getPreviewDownload,
  setPreviewDownload,
  clearPreviewDownload,
  emptyPreviewDowload,
  GetStatus,
  SetStatus,
  getReadingsData,
  setReadingsData,
  AddDevices,
  DeviceResponse,
  getDeviceList,
  setDeviceList,
  setFiltDeviceList,
  GetSerialCheck,
  SetSerialCheck,
  setMarsonikSource,
  GetDeviceConnection,
  setIntegrationResponse,
  SetTokenCheck,
  GetTokenCheck,
  GetDisconnection,
  SetpatientDemotimer,
} = PatientDemoSlice.actions;

export default PatientDemoSlice.reducer;
